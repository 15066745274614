import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { routeSubmodules } from "features/supply/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { partyReducerActions } from "store/actions";

import { PartyStatus, PartyView, usePartyReceived, usePartyRejected, usePartyUpdate } from "modules/party/view";

import { ConditionalRender } from "components";

type Props = {
  party: PartyView;
  status: PartyStatus;
};

const Supply: React.FC<Props> = ({ status, party }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const update = usePartyUpdate();
  const rejected = usePartyRejected();
  const received = usePartyReceived();
  const { setPartyView } = partyReducerActions;
  const { party_id, ...arg } = useAppSelector(state => state.partyReducer.view);
  const { actions: actionsFunc } = useRoutePermissions("Ta'minot", routeSubmodules);
  const actions = actionsFunc("Partiyalar");
  const acceptAction = !(status === "open" || status === "rejected" || status === "recieved");

  const onCancel = () => {
    dispatch(
      setPartyView({
        ...arg,
        party_id,
        visible: false
      })
    );
  };

  const onRejected = () => {
    rejected.mutateAsync({ id: party_id! }).then(onCancel);
  };

  const onReceived = () => {
    received.mutateAsync({ id: party_id! }).then(onCancel);
  };

  const onUpdate = () => {
    const { id, agent, delivery_date, payment_date } = party;

    update
      .mutateAsync({
        id,
        status: "ordered",
        agent_id: agent?.id,
        warehouse_products: [],
        payment_date: payment_date || undefined,
        delivery_date: delivery_date || undefined
      })
      .then(onCancel);
  };

  return (
    <div className="flex w-full items-center justify-end gap-3">
      <Button onClick={onCancel} disabled={rejected.isLoading || received.isLoading}>
        {t("partyView.Yopish")}
      </Button>
      <ConditionalRender if={acceptAction && actions?.rejected}>
        <Button className="bg-gray-200" onClick={onRejected} loading={rejected.isLoading} disabled={received.isLoading}>
          {t("partyView.Bekor qilish")}
        </Button>
      </ConditionalRender>
      <ConditionalRender if={acceptAction && actions?.completed}>
        <Button type="primary" onClick={onReceived} loading={received.isLoading} disabled={rejected.isLoading}>
          {t("partyView.Yakunlash")}
        </Button>
      </ConditionalRender>
      <ConditionalRender if={status === "open" && actions?.edit}>
        <Button type="primary" onClick={onUpdate} loading={update.isLoading} disabled={update.isLoading}>
          {t("partyView.Partiya qilish")}
        </Button>
      </ConditionalRender>
    </div>
  );
};

export default Supply;
