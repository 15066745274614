import React, { Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FormInstance } from "antd/lib";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { ITransferringResourcesModel } from "features/warehouse/utils/models/transferringResourcesModel";
import { WarehouseProductModel } from "features/warehouse/utils/models/WarehouseProductModel";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { warehouseTransferActions } from "store/reducers/warehouseTransferReducer";

import { cx } from "modules/common";

import styles from "./selectedResouresModal.module.css";

export interface IProps {
  form: FormInstance;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const SelectedResourcesModal: React.FC<IProps> = ({ form, setVisible, visible }) => {
  const { selectProducts } = useAppSelector(state => state.warehouseTransferReducer);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { setSelectProducts } = warehouseTransferActions;
  const products = form.getFieldValue("products") as ITransferringResourcesModel[];

  const filteredSelectedProducts = selectProducts?.filter(
    el => Number(el?.changed_quantity_value) <= Number(el?.selected_total_value)
  );

  const onDelete = (record: WarehouseProductModel) => {
    dispatch(
      setSelectProducts(
        filteredSelectedProducts?.filter(
          el => !(el?.product?.id === record?.product?.id && el.unit?.id === record?.unit?.id)
        )
      )
    );

    form.setFieldsValue({
      products: products?.filter(el => !(el?.product_id === record?.product?.id && el?.unit_id === record?.unit?.id))
    });
  };

  const columns: ColumnsType<WarehouseProductModel> = [
    {
      title: "Resurs turi va nomi",
      render: (record: WarehouseProductModel) => (
        <div className={cx("resource")}>
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          <span>
            <PopoverShortName title={record?.product?.name[i18n.language]} length={15} />
          </span>
        </div>
      )
    },
    {
      title: "Soni",
      render: (record: WarehouseProductModel) => record.changed_quantity_value
    },
    {
      title: "Birligi",
      render: (record: WarehouseProductModel) => record.unit?.symbol[i18n.language]
    },
    {
      title: "",
      render: (record: WarehouseProductModel) => (
        <div className="cursor-pointer" onClick={() => onDelete(record)}>
          <DeleteIcon />
        </div>
      )
    }
  ];

  const onCancel = () => {
    setVisible(false);
  };

  const footer = (
    <div className="flex items-center justify-end" onClick={onCancel}>
      <Button>Yopish</Button>
    </div>
  );

  return (
    <Modal
      centered
      open={visible}
      footer={footer}
      onCancel={onCancel}
      title="Tanlangan resurslar"
      rootClassName={styles.modal_wrapper}
    >
      <Table
        locale={{
          emptyText: <TableEmpty />
        }}
        pagination={false}
        columns={columns}
        dataSource={filteredSelectedProducts ?? []}
      />
    </Modal>
  );
};

export default SelectedResourcesModal;
