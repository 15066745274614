import { z } from "zod";

import { statusSchema } from "modules/dashboard";

export const integrationTypeSchema = z.enum(["uysot", "sms"]);

export const integrationSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  icon: z.string(),
  status: statusSchema,
  type: integrationTypeSchema,
  key: z.string().nullable(),
  connect: z.boolean(),
  start_date: z.string().nullable()
});

export type Integration = z.infer<typeof integrationSchema>;
export type IntegrationType = z.infer<typeof integrationTypeSchema>;
