import { parseLocaledString } from "modules/common";

import { CreateTransferPayload, CreateTransferState } from "./schema";

export function parseCreateTransferPayload(state: CreateTransferState): CreateTransferPayload {
  const { sender_warehouse_id, warehouses } = state;

  return {
    sender_warehouse_id,
    warehouses: warehouses?.map(
      ({ recieved_warehouse_id, date, description, file_ids, recieved_project_id, products }) => ({
        date,
        file_ids,
        description,
        recieved_project_id,
        recieved_warehouse_id,
        products: products?.map(({ product_id, unit_id, quantity }) => ({
          product_id,
          unit_id,
          quantity: parseLocaledString(quantity || "0")
        }))
      })
    )
  };
}
