import React from "react";
import { useDispatch } from "react-redux";
import { Modal } from "antd";

import { useAppSelector } from "hooks/redux";

import { setCheckedBuildingsClear, setCurrencies, setVisible } from "modules/settings/uysot";

import { UysotLogoIcon } from "components";

import Left from "./left";
import Right from "./right";

import styles from "./index.module.scss";

const Uysot: React.FC = () => {
  const dispatch = useDispatch();
  const { visible, step, id, start_date } = useAppSelector(({ uysot }) => uysot);

  const onCancel = () => {
    dispatch(
      setVisible({
        id,
        step,
        start_date,
        visible: false
      })
    );

    dispatch(setCheckedBuildingsClear());
  };

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      dispatch(setCurrencies([]));
    }
  };

  return (
    <Modal
      centered
      open={visible}
      footer={false}
      width="81.25rem"
      onCancel={onCancel}
      rootClassName={styles.modal}
      afterOpenChange={onAfterOpen}
      title={
        <div className="flex items-center gap-2">
          <div className="box-border flex w-max items-center justify-center rounded-xl border border-solid border-gray-200 p-2.5">
            <UysotLogoIcon />
          </div>
          <span className="text-lg font-semibold text-gray-900">Uysot</span>
        </div>
      }
    >
      <div className="flex h-full flex-1">
        <Left />
        <Right />
      </div>
    </Modal>
  );
};

export default Uysot;
