export const USER = "user";
export const TOKEN = "token";
export const IS_AUTH = "is_auth";
export const ROLE_NAME = "role_name";
export const TABLE = "table";
export const LAYOUT = "layout";
export const IS_PARTY_PROCESS = "is_party_process";
export const PAYMENT_CURRENCY = "payment_currency";
export const ESTIMATE_GROUP = "estimate_group";
export const LOCALE = "locale";
export const SUPPLY_PARTY_COLUMNS = "supply_party_columns";
export const IMPOST_PARTY_COLUMNS = "impost_party_columns";
export const WAREHOUSE_PARTY_COLUMNS = "warehouse_party_columns";
export const PAYMENT_PARTY_COLUMNS = "payment_party_columns";
export const IS_PARTY_GROUP = "is_party_group";
export const IS_CATALOGS_ACTIVE = "is_catalogs_active";
export const IS_STATS_ACTIVE = "is_stats_active";
export const IS_WAREHOUSES_ACTIVE = "is_warehouses_active";
