import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import { errorHandler } from "features/app/utils/helpers/errorHandler";

import { ErrorRes, SuccessRes } from "modules/common";

import { integrationQueryKeys } from "../query-keys";

export function useIntegrationConnect() {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { key: string | null }>(
    async payload => {
      const { data } = await $api.put("integration/connect", payload);

      return data?.data;
    },
    {
      onError: errorHandler,
      onSuccess: () => {
        qc.invalidateQueries([integrationQueryKeys.VIEW]);
      },
      retry: false
    }
  );
}
