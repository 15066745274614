import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button, message } from "antd";
import { useTranslation } from "react-i18next";

import { partyReducerActions } from "store/actions";

import { useAppSelector } from "../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { routeSubmodules } from "../../../../supply/utils/constants/routeSubmodules";
import { ProductOfferModel } from "../../../../supply/utils/models/OfferModel";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { isEmptyArr } from "../../../utils/helpers/isEmptyArr";
import ConditionalRender from "../../conditional-render/ConditionalRender";
import { StatusEnums } from "../../statuses/statusEnums";

const OfferButtons: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const partyActions = actions("Partiyalar");

  const { setPartyCreate } = partyReducerActions;

  const { data: selectOffers } = useAppSelector(state => state?.supplyOfferReducer?.selectOffers);

  const isCreateParty = useMemo(() => !isEmptyArr(selectOffers), [selectOffers]);

  const isCompanyPersonsAccepted = (offers?: ProductOfferModel[]) =>
    offers?.some(item => item.status === StatusEnums.RECIEVED);

  const onOpenOfferPartyModal = () => {
    let isEmptyCompanyPerson = false;

    for (const item of selectOffers) {
      if (!isCompanyPersonsAccepted(item.warehouse_product_offers)) {
        isEmptyCompanyPerson = true;
        break;
      }
    }

    if (!isEmptyCompanyPerson) {
      dispatch(
        setPartyCreate({
          isDiscount: false,
          isGroup: false,
          isVat: false,
          type: "supply",
          visible: true
        })
        // setOfferPartyModal({
        //   visible: true,
        //   selectData: selectOffers
        // })
      );
    } else {
      message.info(t("app.Tasdiqlangan narx mavjud emas"));
    }
  };

  return (
    <ConditionalRender if={isCreateParty && partyActions.create}>
      <Button onClick={onOpenOfferPartyModal}>
        <PlusIcon />
        <span>{t("app.Partiya yaratish")}</span>
      </Button>
    </ConditionalRender>
  );
};

export default OfferButtons;
