import { z } from "zod";

import { productSchema, unitSchema } from "modules/common";

export const admissionHistorySchema = z.object({
  id: z.number(),
  product: productSchema,
  recieved_date: z.string(),
  quantity: z.number(),
  unit: unitSchema
});

export type AdmissionHistory = z.infer<typeof admissionHistorySchema>;
