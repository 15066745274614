import React from "react";
import { useParams } from "react-router";
import { Collapse, Spin } from "antd";
import { CollapseProps } from "antd/lib";
import FolderCard from "features/app/components/folder-card/FolderCard";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { colors } from "features/app/utils/constants/colors";
import { parseParamsId } from "features/app/utils/helpers/parseParamsId";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";
import { useGetWarehouseCategoryView, useGetWarehouseSubCategoryView } from "features/warehouse/service/query";
import { useTranslation } from "react-i18next";

import { cx, isEmptyArr } from "modules/common";

import { ConditionalRender, LoadingIndicator } from "components";

import styles from "./catalogs.module.scss";

type Props = {
  folderPath: (record: any) => string;
  storageKey: string;
};

const Catalogs: React.FC<Props> = ({ folderPath, storageKey }) => {
  const params = useParams();
  const { t, i18n } = useTranslation();
  const { id: category_id } = parseParamsId(params?.id);
  const { data: categories, isLoading } = useGetWarehouseCategoryView(
    storageKey === "collapseWarehouseActive" && !category_id
  );
  const { data: subcategories, isLoading: isSubcategoriesLoading } = useGetWarehouseSubCategoryView(
    storageKey === "collapseWarehouseSubCatalogActive",
    +category_id!
  );

  const collapseActive = localStorage.getItem(storageKey);

  const collapseChildren = (
    <Spin
      spinning={storageKey === "collapseWarehouseActive" && !category_id ? isLoading : isSubcategoriesLoading}
      indicator={LoadingIndicator}
    >
      <div
        className={cx(
          "flex max-h-[250px] min-h-0 flex-wrap gap-4 overflow-y-auto",
          ((!category_id && isLoading) || (category_id && isSubcategoriesLoading)) && "justify-center"
        )}
      >
        <ConditionalRender
          if={!isEmptyArr(storageKey === "collapseWarehouseActive" && !category_id ? categories : subcategories)}
          else={<TableEmpty />}
        >
          {(storageKey === "collapseWarehouseActive" && !category_id ? categories : subcategories)?.map(item => (
            <FolderCard
              key={item.id}
              id={item.id}
              color={item.color}
              count={item.products_count}
              name={item.name[i18n.language as keyof typeof item.name]}
              path={folderPath(item)}
              placeholder={t("warehouse.resurs")}
              sliceTextCount={10}
            />
          ))}
        </ConditionalRender>
      </div>
    </Spin>
  );

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <h4 className="mb-0 ml-0 mr-4 mt-0 text-lg font-medium text-gray-700">{t("warehouse.Katalog")}</h4>,
      children: collapseChildren
    }
  ];

  const onCollapseChange = (e: string | string[]) => {
    if (e.length === 1) {
      localStorage.setItem(storageKey, e[0]);
    } else {
      localStorage.setItem(storageKey, e[1]);
    }
  };

  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <div className="flex w-7 items-center justify-center rounded-lg border border-solid border-gray-100 bg-gray-100 p-1.5">
          <ChevronDownIcon rotate={isActive} stroke={colors.GRAY_800} width={16} />
        </div>
      )}
      items={items}
      onChange={onCollapseChange}
      rootClassName={styles.folder_collapse}
      defaultActiveKey={collapseActive!}
    />
  );
};

export default Catalogs;
