import React from "react";
import { RU } from "features/app/utils/constants/languages";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import { WorkProductTypeEnum } from "features/projects/utils/enums/workProductTypeEnum";
import { ProjectReportProductSpendModel } from "features/projects/utils/models/projectModel";
import { useTranslation } from "react-i18next";

import { cx, isEmptyObj } from "modules/common";

import styles from "./index.module.scss";

type Props = {
  statistics: {
    mechanism_amount: number;
    person_amount: number;
    product_amount: number;
    total_amount: number;
  } | null;
};

const TaskProgressStatistics: React.FC<Props> = ({ statistics }) => {
  const { t } = useTranslation();
  const baseCurrency = getBaseCurrency();

  return (
    <div className="flex h-full w-[70%] items-center justify-between gap-10 rounded-2xl border border-solid border-gray-200 bg-white px-6">
      <div className="flex flex-1 flex-col gap-3 border-b-0 border-l-0 border-r border-t-0 border-solid border-r-gray-200 pr-10">
        <span className="text-base font-normal text-gray-500">{t("works.Umumiy summa")}</span>
        <div className="flex items-center gap-1 text-[28px] font-medium text-gray-700">
          {statistics?.total_amount ? Math.round(Number(statistics?.total_amount))?.toLocaleString(RU) : 0}
          <span className="mt-1 text-[24px] font-medium text-gray-400">{baseCurrency?.symbol}</span>
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-3 border-b-0 border-l-0 border-r border-t-0 border-solid border-r-gray-200 pr-10">
        <span className="flex items-center gap-[10px] text-base font-normal text-gray-500">
          <span className="flex items-center justify-center rounded-[4px] border border-solid border-success-600 px-2 py-2px text-xs font-medium text-success-600">
            {t("works.MAH")}
          </span>
          {t("works.Mahsulot")}
        </span>
        <div className="flex items-center gap-1 text-[28px] font-medium text-gray-700">
          {Math.round(Number(statistics?.product_amount))?.toLocaleString(RU)}
          <span className="mt-1 text-[24px] font-medium text-gray-400">{baseCurrency?.symbol}</span>
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-3 border-b-0 border-l-0 border-r border-t-0 border-solid border-r-gray-200 pr-10">
        <span className="flex items-center gap-[10px] text-base font-normal text-gray-500">
          <span
            className={cx(
              "flex items-center justify-center rounded-[4px] px-2 py-[2px] text-xs font-medium",
              styles.person
            )}
          >
            {t("works.INS")}
          </span>
          {t("works.Inson")}
        </span>
        <div className="flex items-center gap-1 text-[28px] font-medium text-gray-700">
          {Math.round(Number(statistics?.person_amount))?.toLocaleString(RU)}
          <span className="mt-1 text-[24px] font-medium text-gray-400">{baseCurrency?.symbol}</span>
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-3">
        <span className="flex items-center gap-[10px] text-base font-normal text-gray-500">
          <span
            className={cx(
              "flex items-center justify-center rounded-[4px] border border-solid border-orange-600 px-2 py-[2px] text-xs font-medium text-orange-600"
            )}
          >
            {t("works.MEX")}
          </span>
          {t("works.Mexanizm")}
        </span>
        <div className="flex items-center gap-1 text-[28px] font-medium text-gray-700">
          {Math.round(Number(statistics?.mechanism_amount))?.toLocaleString(RU)}
          <span className="mt-1 text-[24px] font-medium text-gray-400">{baseCurrency?.symbol}</span>
        </div>
      </div>
    </div>
  );
};

export default TaskProgressStatistics;
