import React from "react";
import { useDispatch } from "react-redux";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, message } from "antd";
import $api from "features/app/utils/helpers/axiosInstance";
import i18n from "i18next";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/reducers/partyReducer";

import { errorHandler, ErrorRes, SuccessRes } from "modules/common";
import { partyCreateQueryKeys, PartyForm, ProductWithUnit } from "modules/party/create";
import { ProductForm, ProductReqPayload, useLanguages } from "modules/party/create-product";

type Props = {
  form: UseFormReturn<ProductForm>;
  parentForm: UseFormReturn<PartyForm>;
};

const Footer: React.FC<Props> = ({
  form: {
    control,
    handleSubmit,
    formState: { isSubmitting }
  },
  parentForm
}) => {
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const { languages } = useLanguages();
  const { name } = useWatch({ control });
  const { setPartyCreateProduct } = partyReducerActions;
  const { fieldName } = useAppSelector(({ partyReducer }) => partyReducer.createProduct);

  const onCancel = () => {
    dispatch(
      setPartyCreateProduct({
        visible: false,
        name,
        fieldName: ""
      })
    );
  };

  const mutation = useMutation<SuccessRes<ProductWithUnit>, ErrorRes, ProductReqPayload>(
    async payload => {
      const { data } = await $api.post("product/create", payload);

      return data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data?.message?.[i18n.language]);

        await qc.invalidateQueries([partyCreateQueryKeys.PRODUCTS]);

        parentForm.setValue(`${fieldName}.product_id` as never, data?.data?.id as never);
        parentForm.setValue(`${fieldName}.unit_id` as never, data?.data?.unit?.id as never);
        parentForm.setValue(`${fieldName}.unit_name` as never, data?.data?.unit?.symbol?.[i18n.language] as never);

        onCancel();
      }
    }
  );

  const onSubmit = async (values: ProductForm) => {
    await new Promise(onSettled => {
      let payload: ProductReqPayload = { ...values, name: {} };

      languages?.forEach(lang => {
        payload = Object.assign(payload, {
          name: {
            ...payload.name,
            [lang.symbol]: values.name
          }
        });
      });

      if (payload?.subcategory_id) {
        // @ts-ignore
        delete payload?.category_id;
      }

      mutation.mutate(payload, { onSettled });
    });
  };

  return (
    <div className="flex items-center justify-end gap-4">
      <Button onClick={onCancel}>Yopish</Button>
      <Button type="primary" onClick={handleSubmit(onSubmit)} loading={isSubmitting}>
        Mahsulot yaratish
      </Button>
    </div>
  );
};

export default Footer;
