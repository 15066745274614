import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { errorHandler, ErrorRes, SuccessRes } from "modules/common";

import { partyQueryKeys } from "../query-keys";
import { PartyView, PaymentType } from "../schema";

type Payload = {
  order_id: number;
  payment_type_id?: number;
  company_person_id: number;
  thisPaymentType: PaymentType;
};

type Query = {
  party: PartyView;
};

export function useCreatePaymentType() {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, Payload>(
    async payload => {
      const parsePayload = { ...payload };

      // @ts-ignore
      delete parsePayload.thisPaymentType;

      const { data } = await $api.post("company-person-payment-type/create", payload);

      return data;
    },
    {
      onError: errorHandler,
      onSuccess: (_, variables) => {
        qc.invalidateQueries([partyQueryKeys.VIEW, variables?.order_id]);
      }
    }
  );
}
