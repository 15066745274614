export const wareHouseProducts = (lng: string) => {
  return {
    column: [
      {
        id: `product.name.${lng}`,
        name: `product.name.${lng}`,
        title: "Resurs nomi va turi",
        checked: true
      },
      {
        id: `unit.name.${lng}`,
        name: `unit.name.${lng}`,
        title: "Birligi",
        checked: true
      },
      {
        id: "total_quantity",
        name: "total_quantity",
        title: "Soni",
        checked: true
      },
      {
        id: "total_amount",
        name: "total_amount",
        title: "Summa",
        checked: true
      }
    ],
    size: 10
  };
};
