import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { errorHandler, ErrorRes, SuccessRes } from "modules/common";
import { successHandler } from "modules/party/create";
import { PartyUpdateReqPayload } from "modules/party/update";

export function usePartyUpdate() {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, PartyUpdateReqPayload>(
    async payload => {
      const { data } = await $api.put("supply/order-update", payload);

      return data;
    },
    {
      onError: errorHandler,
      onSuccess: successHandler(qc)
    }
  );
}
