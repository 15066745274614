import { CustomFieldSelectModel } from "features/app/utils/models/customFieldSelectModel";

import { PartyForm, PartyFormCustomFieldValue } from "modules/party/create";
import { PartyView } from "modules/party/view";

function generateValue(
  field: PartyView["custom_field_values"][number],
  customFields: PartyView["custom_field_values"]
): PartyFormCustomFieldValue {
  const { type, id } = field.custom_field;

  if (type === "checkbox") return id;
  if (type === "select") return Number(field?.value);
  if (type === "label") {
    const filterFields = customFields?.filter(f => f?.custom_field?.type === "label");

    return filterFields?.map(f => Number(f?.value));
  }

  return field?.value;
}

export function parseCustomFieldsToForm(
  customFields: PartyView["custom_field_values"],
  allCustomFields?: CustomFieldSelectModel[]
): PartyForm["custom_field_values"] {
  return allCustomFields?.map(field => {
    const findField = customFields?.find(({ custom_field }) => custom_field?.id === field?.id);

    if (findField?.id) {
      return {
        custom_field_id: findField?.custom_field?.id,
        value: generateValue(findField, customFields)
      };
    }

    return {
      custom_field_id: field?.id,
      value: null
    };
  });
}
