import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Switch } from "antd";
import DndIcon from "features/app/assets/icons/DndIcon";

import { PartyColumn } from "modules/party/view";

type Props = {
  column: PartyColumn;
  columns: PartyColumn[];
  setColumns: React.Dispatch<React.SetStateAction<PartyColumn[]>>;
};

const ColumnSwitch: React.FC<Props> = ({ column, setColumns }) => {
  const { key, title } = column;

  const { attributes, listeners, setNodeRef, transform, isDragging } = useSortable({
    id: key
  });

  const style: React.CSSProperties = {
    cursor: "move",
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    transform: CSS.Transform.toString(transform)
  };

  const onChange = (e: boolean) => {
    setColumns(oldColumns =>
      oldColumns?.map(col => {
        if (col?.key === column?.key) {
          return {
            ...col,
            checked: e
          };
        }

        return col;
      })
    );
  };

  return (
    <div
      key={key}
      style={style}
      {...listeners}
      {...attributes}
      ref={setNodeRef}
      onClick={e => e.stopPropagation()}
      className="flex items-center justify-between rounded-xl border border-solid border-gray-300 bg-white px-5 py-4"
    >
      <div className="flex items-center gap-4">
        <DndIcon />
        <span className="text-base font-medium text-gray-700">
          {title}
          {/* {t(`partyView.${title}`)} */}
        </span>
      </div>
      <Switch onChange={onChange} checked={column?.checked} />
    </div>
  );
};

export default ColumnSwitch;
