import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import RollerBrushIcon from "features/app/assets/icons/RollerBrushIcon";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { CurrencySelectModel } from "features/app/utils/models/currencySelectModel";
import { TaskProgressDetails, WorkProgressModelById } from "features/projects/utils/models/WorkProgressModel";
import ProductIcon from "features/supply/assets/icons/ProductIcon";
import { WarehouseProductModel } from "features/warehouse/utils/models/WarehouseProductModel";
import { useTranslation } from "react-i18next";

import { isEmptyObj, RU } from "modules/common";

import styles from "../workModalTabs.module.scss";

type Props = {
  data:
    | (WarehouseProductModel & {
        quantity: number;
        amount: number;
        total_amount: number;
        currency: CurrencySelectModel;
        id: number;
      })[]
    | TaskProgressDetails[];
  progress: WorkProgressModelById;
};

const ExpandedRow = ({ data, progress }: Props) => {
  const { i18n } = useTranslation();

  const icons: Record<string, React.ReactNode> = {
    mechanism: <RollerBrushIcon />,
    product: <ProductIcon />
  };

  const warehouseColumns: ColumnsType<
    WarehouseProductModel & {
      quantity: number;
      amount: number;
      total_amount: number;
      currency: CurrencySelectModel;
    }
  > = [
    {
      title: "",
      render: (
        record: WarehouseProductModel & {
          quantity: number;
          amount: number;
          total_amount: number;
          currency: CurrencySelectModel;
        }
      ) => (
        <div className="flex w-max items-center gap-3 py-2 pl-14">
          <div className="flex h-10 w-10 items-center justify-center rounded-[50%] border-2 border-solid border-white bg-gray-100 text-base font-medium text-gray-600">
            {icons[record?.product?.resource?.type as keyof typeof icons] ||
              `${record?.product?.name[i18n.language][0]?.toUpperCase()}${record?.product?.name[i18n.language][1]?.toUpperCase()}`}
          </div>
          <span className="text-sm font-normal text-gray-900">
            <PopoverShortName title={record?.product?.name[i18n.language]} length={20} />
          </span>
        </div>
      ),
      width: "507px"
    },
    {
      title: "",
      render: (
        _value: any,
        record: WarehouseProductModel & {
          quantity: number;
          amount: number;
          total_amount: number;
          currency: CurrencySelectModel;
        }
      ) => (
        <>
          {record?.quantity >= 0 ? (
            <div className="flex items-center gap-1 pl-9">
              <span className="text-sm font-medium text-gray-900">{record?.quantity}</span>
              <span className="text-sm font-medium text-gray-400">{record?.unit?.symbol[i18n.language]}</span>
            </div>
          ) : (
            <span>-</span>
          )}
        </>
      ),
      width: "350px"
    },
    {
      title: "",
      render: (
        _value: any,
        record: WarehouseProductModel & {
          quantity: number;
          amount: number;
          total_amount: number;
          currency: CurrencySelectModel;
        }
      ) => (
        <>
          {record?.amount >= 0 ? (
            <span className="flex items-center justify-self-center pl-8 text-sm font-medium text-gray-900">
              {record?.amount?.toLocaleString(RU)} {record?.currency?.symbol}
            </span>
          ) : (
            <span>-</span>
          )}
        </>
      ),
      width: "280px"
    },
    {
      title: "",
      render: (
        _value: any,
        record: WarehouseProductModel & {
          quantity: number;
          amount: number;
          total_amount: number;
          currency: CurrencySelectModel;
        }
      ) => (
        <>
          {record?.total_amount >= 0 ? (
            <span className="pl-[20px] text-sm font-medium text-gray-900">
              {record?.total_amount?.toLocaleString(RU)} {record?.currency?.symbol}
            </span>
          ) : (
            <span>-</span>
          )}
        </>
      ),
      width: "320px",
      className: styles.total_amount
    },
    {
      title: "",
      render: (value, record, index) => <span />,
      width: "120px"
    }
  ];

  const getName = (record: TaskProgressDetails) => {
    if (!isEmptyObj(record?.company_person)) {
      return `${record?.company_person?.name[0].toUpperCase()}${record?.company_person?.name[1].toUpperCase()}`;
    }
    if (!isEmptyObj(record?.user)) {
      return <CustomAvatar image={record?.user?.image} name={record?.user?.full_name} />;
    }
    if (!isEmptyObj(record?.inventory)) {
      return `${record?.inventory?.name[0].toUpperCase()}${record?.inventory?.name[1].toUpperCase()}`;
    }
    return "";
  };

  const secondColumns: ColumnsType<TaskProgressDetails> = [
    {
      title: "",
      render: (record: TaskProgressDetails) => (
        <div className="flex items-center gap-3 py-2 pl-28">
          <div className="flex h-10 w-10 items-center justify-center rounded-[50%] border-2 border-solid border-white bg-gray-100 text-base font-medium text-gray-600">
            {icons[progress?.product?.resource?.type] || getName(record)}
          </div>
          <span className="text-sm font-normal text-gray-900">
            <PopoverShortName
              title={record?.company_person?.name || record?.user?.full_name || record?.inventory?.name}
              length={20}
            />
          </span>
        </div>
      ),
      width: "507px"
    },
    {
      title: "",
      render: (_value: any, record: TaskProgressDetails) => (
        <>
          {record?.quantity ? (
            <div className="flex items-center gap-1 pl-[75px]">
              <span className="text-sm font-medium text-gray-900">{record?.quantity?.toLocaleString(RU)}</span>
              <span className="text-sm font-medium text-gray-400">{record?.unit?.symbol[i18n.language]}</span>
            </div>
          ) : (
            <span>-</span>
          )}
        </>
      ),
      width: "391px"
    },
    {
      title: "",
      render: (_value: any, record: TaskProgressDetails) => (
        <>
          {record?.amount >= 0 ? (
            <span className="pl-[82px] text-sm font-medium text-gray-900">
              {record?.amount?.toLocaleString(RU)} {record?.currency?.symbol}
            </span>
          ) : (
            <span>-</span>
          )}
        </>
      ),
      width: "296px"
    },
    {
      title: "",
      render: (_value: any, record: TaskProgressDetails) => (
        <>
          {record?.total_amount >= 0 ? (
            <span className="pl-[83px] text-sm font-medium text-gray-900">
              {record?.total_amount?.toLocaleString(RU)} {record?.currency?.symbol}
            </span>
          ) : (
            <span>-</span>
          )}
        </>
      ),
      width: "297px"
    },
    {
      title: "",
      render: (value, record, index) => <span />
    }
  ];

  return (
    <Table
      pagination={false}
      footer={undefined}
      columns={progress?.product?.resource?.type === "product" ? (warehouseColumns as never) : (secondColumns as never)}
      dataSource={data as never}
      locale={{
        emptyText: <TableEmpty />
      }}
      className={styles.expanded_table}
      scroll={{ y: 300 }}
    />
  );
};

export default ExpandedRow;
