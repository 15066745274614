import React, { ReactNode } from "react";

import { Integration, IntegrationType } from "../schema";

import Message from "./message";
import Uysot from "./uysot";

const allIntegrations = ({ integration }: { integration: Integration }) => {
  const all: Record<IntegrationType, ReactNode> = {
    uysot: <Uysot {...integration} />,
    sms: <Message {...integration} />
  };

  return all[integration.type];
};

export default allIntegrations;
