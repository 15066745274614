import React from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { uid } from "uid";

import { supplyOfferActions } from "store/actions";

import { dayjsFormats, localeFormatter, parseLocaledString } from "modules/common";
import { WarehouseProduct } from "modules/party/view";

import { EditIcon } from "components";

type Props = {
  item: WarehouseProduct;
};

const UpdateOffer: React.FC<Props> = ({ item }) => {
  const dispatch = useDispatch();
  const { warehouse_product_offers } = item;
  const { setOfferModal } = supplyOfferActions;

  const onUpdateOffer = () => {
    dispatch(
      setOfferModal({
        visible: true,
        isEdit: true,
        viewPartySwitch: false,
        data: [
          {
            offers: warehouse_product_offers?.map((item, index) => ({
              amount: localeFormatter(String(item?.amount)),
              id: item?.id,
              company_person_id: item?.company_person?.id,
              currency_id: item?.currency?.id,
              name: item.company_person?.name || item.name,
              description: item?.description,
              phone: item?.phone,
              uniqueId: uid(12),
              ordinalNumber: index + 1,
              vat: item?.vat,
              payment_type_id: item?.payment_type?.id
            })) as never,
            amount: parseLocaledString(item?.amount),
            quantity: parseLocaledString(item?.quantity),
            delivery_date: dayjs().format(dayjsFormats.DATE),
            product: item?.product,
            id: item.id,
            unit: item?.unit
          }
        ]
      })
    );
  };

  return (
    <div className="flex items-center gap-2" onClick={onUpdateOffer}>
      <EditIcon />
      Taklifni tahrirlash
    </div>
  );
};

export default UpdateOffer;
