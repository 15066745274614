import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { message, Spin, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { UploadFile as UploadFileType } from "antd/es/upload/interface";
import { UploadChangeParam } from "antd/lib/upload";
import { useUploadFile } from "features/app/service/mutation";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { isEmptyArr } from "modules/common";
import { setAdditionalFiles, setDeleteAdditionalFile } from "modules/warehouse/create-transfer";

import { ConditionalRender, FeatureIcon, LoadingIndicator, TrashIcon, UploadIcon } from "components";

import styles from "./index.module.scss";

const { Dragger } = Upload;

const Files: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const uploadFile = useUploadFile();
  const { id } = useAppSelector(({ warehouseCreateTransfer }) => warehouseCreateTransfer.additional);
  const [files, setFiles] = useState<RcFile[]>();

  const onChange = (e: UploadChangeParam<UploadFileType>) => {
    const newFiles: RcFile[] = [];

    e?.fileList?.forEach(({ size, originFileObj }, index) => {
      if (size! >= 5120000) {
        message.error(`${index + 1}-fayl hajmi 5120 kb dan oshmasligi zarur`);
      } else {
        const formData = new FormData();

        newFiles.push(originFileObj!);

        formData.append("files[]", originFileObj as Blob);

        uploadFile.mutateAsync(formData).then(value => {
          dispatch(
            setAdditionalFiles({
              index,
              value
            })
          );
        });
      }
    });

    setFiles(newFiles);
  };

  const onDelete = (fileIndex: number) => () => {
    dispatch(
      setDeleteAdditionalFile({
        index: fileIndex,
        warehouseId: id
      })
    );

    setFiles(files?.filter((_, i) => i !== fileIndex));
  };

  return (
    <Spin spinning={uploadFile.isLoading} indicator={LoadingIndicator}>
      <div className="flex flex-col gap-1.5">
        <label htmlFor="received_warehouse_files" className="text-sm font-medium text-gray-500">
          Fayllar
        </label>
        <Dragger
          multiple
          name="files"
          onChange={onChange}
          showUploadList={false}
          customRequest={() => null}
          rootClassName={styles.file}
          accept=".jpg, .jpeg, .png, .svg, .doc, .docx, .xlsx, .xls, .txt, .pdf, .csv, .pptx, .ppt, .heic"
        >
          <div className="contents">
            <UploadIcon />
            <p className="text-primary-500">
              <span>{t("warehouse.Faylni yuklash uchun bosing")}</span>
            </p>
          </div>
        </Dragger>
        <ConditionalRender if={!isEmptyArr(files)}>
          <div className="flex flex-col gap-2">
            {files?.map(({ name, size }, index) => (
              <div
                key={index}
                className="box-border flex justify-between rounded-xl border border-solid border-gray-300 px-4 py-2"
              >
                <div className="flex gap-2">
                  <FeatureIcon />
                  <div className="flex flex-col gap-1">
                    <h4 className="m-0 text-sm font-medium text-gray-700">{name}</h4>
                    <span className="text-sm font-normal text-gray-600">{((size || 0) / 1000000).toFixed(2)} MB</span>
                  </div>
                </div>
                <div className="flex cursor-pointer items-center justify-center" onClick={onDelete(index)}>
                  <TrashIcon />
                </div>
              </div>
            ))}
          </div>
        </ConditionalRender>
      </div>
    </Spin>
  );
};

export default Files;
