import { useDispatch } from "react-redux";
import { UseFormReturn } from "react-hook-form";
import i18n from "i18next";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/actions";

import { cx, RU } from "modules/common";

import { Actions, Offers, TableItemWrapper } from "pages/party/view/content/other";

import { ConditionalRender, SettingsIcon } from "components";

import { NewPartyColumnsType, PartyStatus, PartyViewForm } from "../../schema";

type Props = {
  status: PartyStatus;
  form: UseFormReturn<PartyViewForm>;
  actions: { [key: string]: boolean };
};

export const SupplyColumns = ({ form, status, actions }: Props) => {
  const dispatch = useDispatch();
  const { setPartyViewSettingsVisible } = partyReducerActions;
  const { updateProduct, isGroup } = useAppSelector(state => state.partyReducer.view);

  const onOpenSettings = () => {
    dispatch(setPartyViewSettingsVisible(true));
  };

  const allColumns: NewPartyColumnsType = [
    {
      key: 1,
      width: 330,
      minWidth: 330,
      title: "Resurs turi va nomi",
      render: record => (
        <TableItemWrapper form={form} id={record?.id} name="not">
          <div className="flex items-center gap-3">
            <div
              className="box-border flex min-w-max items-center justify-center rounded border border-solid px-2 py-0.5 text-xs font-medium leading-[1.125rem]"
              style={{
                color: record?.product?.resource?.color,
                borderColor: record?.product?.resource?.color
              }}
            >
              {record?.product?.resource?.symbol?.[i18n.language]}
            </div>
            <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray-900">
              {record?.product?.name?.[i18n.language]}
            </span>
          </div>
        </TableItemWrapper>
      ),
      fixed: "left"
    },
    {
      key: 2,
      width: 80,
      minWidth: 80,
      title: "Birligi",
      render: record => (
        <TableItemWrapper id={record?.id} name="unit_id" form={form}>
          {record?.unit?.symbol?.[i18n.language]}
        </TableItemWrapper>
      ),
      active: !!updateProduct
    },
    {
      key: 3,
      width: 100,
      minWidth: 100,
      title: "Soni",
      render: record => (
        <TableItemWrapper id={record?.id} form={form} name="quantity">
          {record?.quantity}
        </TableItemWrapper>
      ),
      active: !!updateProduct
    },
    {
      key: 4,
      width: 100,
      minWidth: 100,
      title: "Qabul soni",
      render: record => (
        <TableItemWrapper form={form} id={record?.id} name="not">
          {record?.recieved_quantity}
        </TableItemWrapper>
      ),
      hidden: !!updateProduct && !(status === "recieved" || status === "partially_recieved")
    },
    {
      key: 5,
      width: 100,
      minWidth: 100,
      title: "Birlik narxi",
      render: record => (
        <TableItemWrapper id={record?.id} form={form} name="amount">
          {record?.amount?.toLocaleString(RU)}
        </TableItemWrapper>
      ),
      active: !!updateProduct
    },
    {
      key: 6,
      width: 85,
      minWidth: 85,
      title: "Valyuta",
      render: record => (
        <TableItemWrapper id={record?.id} form={form} name="currency_id">
          {record?.currency?.symbol}
        </TableItemWrapper>
      ),
      active: !!updateProduct
    },
    {
      key: 7,
      width: 130,
      minWidth: 130,
      title: "Ta'minotchi",
      render: record => (
        <TableItemWrapper id={record?.id} form={form} name="company_person_id">
          {record?.company_person?.name || "--"}
        </TableItemWrapper>
      ),
      active: !!updateProduct,
      hidden: isGroup
    },
    {
      key: 8,
      width: 200,
      minWidth: 200,
      title: "Omborxona",
      render: record => (
        <TableItemWrapper id={record?.id} form={form} name="warehouse_id">
          {record?.warehouse?.name as string}
        </TableItemWrapper>
      ),
      active: !!updateProduct
    },
    {
      key: 9,
      width: 150,
      minWidth: 150,
      title: "Loyiha",
      render: record => (
        <TableItemWrapper id={record?.id} form={form} name="project_id">
          {(record?.project?.name as string) || "--"}
        </TableItemWrapper>
      ),
      active: !!updateProduct
    },
    {
      key: 10,
      width: 85,
      minWidth: 85,
      title: "NDS",
      render: record => (
        <TableItemWrapper id={record?.id} form={form} name="vat">
          {record?.vat?.value ? `${record?.vat?.value}%` : "--"}
        </TableItemWrapper>
      ),
      active: !!updateProduct
    },
    {
      key: 11,
      width: 115,
      minWidth: 115,
      title: "Summa",
      render: record => (
        <TableItemWrapper id={record?.id} form={form} name="total_amount">
          {((record?.amount || 0) * (record?.quantity || 0))?.toLocaleString(RU)}
        </TableItemWrapper>
      ),
      active: !!updateProduct
    },
    {
      key: 12,
      width: 115,
      minWidth: 115,
      title: "Jami",
      render: record => (
        <TableItemWrapper id={record?.id} form={form} name="not">
          {record?.total_amount?.toLocaleString(RU)}
        </TableItemWrapper>
      ),
      active: !!updateProduct
    },
    {
      key: 13,
      width: 130,
      minWidth: 130,
      title: "Takliflar",
      render: record => (
        <TableItemWrapper form={form} id={record?.id} name="not">
          <Offers actions={actions} offers={record?.warehouse_product_offers} />
        </TableItemWrapper>
      ),
      hidden: !!updateProduct
    },
    {
      key: 0,
      width: 70,
      minWidth: 70,
      title: (
        <div
          onClick={onOpenSettings}
          className={cx("mr-1 flex cursor-pointer items-center justify-end", "view-settings")}
        >
          <SettingsIcon />
        </div>
      ),
      render: record => (
        <ConditionalRender if={!(status === "rejected" || status === "recieved")}>
          <TableItemWrapper id={record?.id} form={form} name="check">
            <Actions form={form} item={record} actions={actions as never} />
          </TableItemWrapper>
        </ConditionalRender>
      ),
      active: !!updateProduct,
      fixed: "right"
    }
  ];

  return allColumns;
};
