import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { commentsQueryKeys } from "../query-keys";
import { Comment, ObjectType } from "../schema";

type Query = {
  comments: Comment[];
};

export function useComments({ type, object_id }: { type: ObjectType; object_id: number }) {
  const initial = {
    comments: []
  };

  const { data = initial, ...arg } = useQuery<Query>(
    [commentsQueryKeys.view, object_id, type],
    async () => {
      const { data } = await $api.get(`chat/view?object_id=${object_id}&type=${type}`);

      return {
        comments: data?.data
      };
    },
    {
      enabled: !!object_id && !!type
    }
  );

  return { ...data, ...arg };
}
