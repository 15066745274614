import React from "react";
import { UseFieldArrayAppend } from "react-hook-form";

import { PartyForm } from "modules/party/create";
import { Form } from "modules/party/resource";

import Footer from "pages/party/resource/content/footer";
import Top from "pages/party/resource/content/top";

type Props = {
  append: UseFieldArrayAppend<PartyForm, "warehouse_products">;
  appendCheck: UseFieldArrayAppend<PartyForm, "check_products">;
};

const Index: React.FC<Props> = ({ append, appendCheck }) => (
  <Form>
    {form => (
      <div className="flex h-full flex-col">
        <Top form={form} />
        <Footer form={form} append={append} appendCheck={appendCheck} />
      </div>
    )}
  </Form>
);

export default Index;
