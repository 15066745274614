import React from "react";

const PuzzleIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_14292_47190)">
      <path
        d="M4.99992 2.99992C4.99992 2.07944 5.74611 1.33325 6.66659 1.33325C7.58706 1.33325 8.33325 2.07944 8.33325 2.99992V3.99992H8.99992C9.9318 3.99992 10.3977 3.99992 10.7653 4.15216C11.2553 4.35515 11.6447 4.7445 11.8477 5.23455C11.9999 5.60209 11.9999 6.06804 11.9999 6.99992H12.9999C13.9204 6.99992 14.6666 7.74611 14.6666 8.66659C14.6666 9.58706 13.9204 10.3333 12.9999 10.3333H11.9999V11.4666C11.9999 12.5867 11.9999 13.1467 11.7819 13.5746C11.5902 13.9509 11.2842 14.2569 10.9079 14.4486C10.4801 14.6666 9.92002 14.6666 8.79992 14.6666H8.33325V13.4999C8.33325 12.6715 7.66168 11.9999 6.83325 11.9999C6.00483 11.9999 5.33325 12.6715 5.33325 13.4999V14.6666H4.53325C3.41315 14.6666 2.85309 14.6666 2.42527 14.4486C2.04895 14.2569 1.74299 13.9509 1.55124 13.5746C1.33325 13.1467 1.33325 12.5867 1.33325 11.4666V10.3333H2.33325C3.25373 10.3333 3.99992 9.58706 3.99992 8.66659C3.99992 7.74611 3.25373 6.99992 2.33325 6.99992H1.33325C1.33325 6.06804 1.33325 5.60209 1.48549 5.23455C1.68848 4.7445 2.07783 4.35515 2.56789 4.15216C2.93543 3.99992 3.40137 3.99992 4.33325 3.99992H4.99992V2.99992Z"
        stroke="#344054"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14292_47190">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PuzzleIcon;
