import React from "react";
import { useDispatch } from "react-redux";
import { UseFieldArrayAppend } from "react-hook-form";
import { Drawer } from "antd";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/reducers/partyReducer";

import { PartyForm } from "modules/party/create";

import Content from "pages/party/resource/content";

import styles from "./index.module.scss";

type Props = {
  append: UseFieldArrayAppend<PartyForm, "warehouse_products">;
  appendCheck: UseFieldArrayAppend<PartyForm, "check_products">;
};

const Index: React.FC<Props> = ({ appendCheck, append }) => {
  const dispatch = useDispatch();
  const { setPartyResource } = partyReducerActions;
  const { visible } = useAppSelector(state => state.partyReducer.resource);

  const onClose = () => {
    dispatch(setPartyResource({ visible: false }));
  };

  return (
    <Drawer
      width="70rem"
      destroyOnClose
      open={visible}
      onClose={onClose}
      title="Resurslar"
      rootClassName={styles.resource}
    >
      <Content append={append} appendCheck={appendCheck} />
    </Drawer>
  );
};

export default Index;
