import { FileType } from "../enums/fileType";

const mimeToExtension: { [key: string]: string } = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/pdf": "pdf",
  "image/png": "png",
  "image/jpeg": "jpg",
  "application/vnd.ms-excel": "xls",
  "text/csv": "csv",
  "text/plain": "txt",
  "application/zip": "zip"
};

export const downloadFile = (data: Blob | string, name: string, type?: FileType) => {
  let url = "";
  let fileExtension = "";

  if (data instanceof Blob) {
    url = window.URL.createObjectURL(data);

    const mimeType = data.type;

    fileExtension = fileExtension || mimeToExtension[mimeType] || "bin";
  } else {
    url = data;
  }

  const a = document.createElement("a");

  a.href = url;
  a.download = `${name}.${fileExtension}`;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  window.URL.revokeObjectURL(url);
};
