import React from "react";
import { Dropdown } from "antd";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "features/app/assets/icons/DotsVerticalIcon";
import { WorkModel } from "features/projects/utils/models/workModel";
import { useTranslation } from "react-i18next";

type Props = {
  record: WorkModel;
};

const Actions: React.FC<Props> = ({ record }) => {
  const { t } = useTranslation();

  const items = [
    {
      key: "1",
      label: (
        <div className="flex gap-2">
          <DeleteIcon /> {t("works.O'chirish")}
        </div>
      ),
      permissionKey: "delete",
      isView: true
    }
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <div className="flex cursor-pointer items-center justify-center rounded-lg border border-solid border-gray-200 bg-gray-50 p-2">
        <DotsVerticalIcon />
      </div>
    </Dropdown>
  );
};

export default Actions;
