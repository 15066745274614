import React from "react";
import { useTranslation } from "react-i18next";

import UysotPaymentTypeContent from "./content";

const UysotPaymentType: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 rounded-xl border border-solid border-gray-200 bg-white p-4">
      <h3 className="m-0 text-lg font-semibold text-gray-900">{t("uysot.To'lov turlarini sozlang")}</h3>
      <UysotPaymentTypeContent />
    </div>
  );
};

export default UysotPaymentType;
