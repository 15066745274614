import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { counterpartsQueryKeys } from "features/counterparts/utils/constants/counterpartsQueryKeys";
import { paymentQueryKeys } from "features/payment/utils/constants/paymentQueryKeys";
import i18n from "i18next";

import { errorHandler, ErrorRes, SuccessRes } from "modules/common";

import { queryParamsKeys } from "../../../../features/app/utils/constants/queryParamsKeys";
import $api from "../../../../features/app/utils/helpers/axiosInstance";
import { supplyQueryNames } from "../../../../features/supply/utils/constants/supplyQueryNames";
import { useQueryParams } from "../../../../hooks/useQueryParams";

export function usePartyRejected() {
  const qc = useQueryClient();
  const { severalSearchParams } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);

  return useMutation<SuccessRes, ErrorRes, { id: number }>(
    async body => {
      const { data } = await $api.put("supply/order-rejected", body);

      return data;
    },
    {
      onSuccess: async data => {
        await qc.invalidateQueries([supplyQueryNames.ORDERS, searchParam]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENTS_ORDER_VIEW]);
        await qc.invalidateQueries([counterpartsQueryKeys.ORDER]);

        message.success(data?.message?.[i18n.language]);
      },
      onError: errorHandler
    }
  );
}
