import React from "react";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { CompletedWorkModel } from "features/projects/utils/models/completedWorkModel";
import { ProjectReportProductSpendModel } from "features/projects/utils/models/projectModel";
import { WorkModel } from "features/projects/utils/models/workModel";

import Right from "./right";
import TaskProgressStatistics from "./task-progress-statistics";
import TaskStatistics from "./task-statistics";

type Props = {
  statistics: ProjectReportProductSpendModel | undefined;
  progressId: number;
  work?: WorkModel;
  foundTaskProgress?: CompletedWorkModel | null;
  progressByIdStatistics: {
    mechanism_amount: number;
    person_amount: number;
    product_amount: number;
    total_amount: number;
  } | null;
};

const Statistics: React.FC<Props> = ({ statistics, progressId, work, foundTaskProgress, progressByIdStatistics }) => (
  <div className="flex h-[148px] items-center gap-3">
    <ConditionalRender if={!progressId} else={<TaskProgressStatistics statistics={progressByIdStatistics} />}>
      <TaskStatistics statistics={statistics} />
    </ConditionalRender>
    <Right progressId={progressId} work={work} foundTaskProgress={foundTaskProgress} />
  </div>
);

export default Statistics;
