import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { CounterpartsCompanyPersonModel } from "../../features/counterparts/utils/models/counterpartsCompanyPersonModel";
import { InventoryBodyModel } from "../../features/counterparts/utils/models/counterpartsInventoryBodyModel";
import { PaymentCashMoneyModel } from "../../features/payment/utils/models/paymentCashMoneyModel";

interface CompanyPersonReducerModel {
  createAndUpdateModal: {
    data?: CounterpartsCompanyPersonModel;
    visible: boolean;
    onAfterFunc?: (data: { id: number; name: string; phone: string }) => void;
    initialData?: {
      name?: string;
      phone?: string;
    };
    requestKey?: string[];
    offer_id?: number;
  };
  payment: {
    visible: boolean;
    type: string;
    cash?: PaymentCashMoneyModel;
    paymentType?: PaymentCashMoneyModel;
  };
  inventory: {
    addGroup: boolean;
    modal: {
      visible: boolean;
      data?: Partial<InventoryBodyModel>;
    };
  };
  act: {
    visible: boolean;
    name?: string;
    company_person_id?: number;
  };
  productListModal: {
    visible: boolean;
    company_person_id: number | undefined;
    editingIds: string[];
    company_person_name: string;
  };
  viewType: string;
}

const initialState: CompanyPersonReducerModel = {
  payment: {
    visible: false,
    type: "expense"
  },
  inventory: {
    addGroup: false,
    modal: {
      visible: false
    }
  },
  act: {
    visible: false
  },
  createAndUpdateModal: {
    visible: false,
    data: undefined
  },
  productListModal: {
    visible: false,
    company_person_id: undefined,
    editingIds: [],
    company_person_name: ""
  },
  viewType: "list"
};

const companyPersonSlice = createSlice({
  name: sliceNames.COMPANY_PERSON,
  initialState,
  reducers: {
    setPaymentModal: (
      state,
      action: PayloadAction<{
        visible: boolean;
        type: string;
      }>
    ) => {
      state.payment.visible = action.payload.visible;
      state.payment.type = action.payload.type;
    },
    setPaymentCash: (state, action: PayloadAction<PaymentCashMoneyModel | undefined>) => {
      state.payment.cash = action.payload;
    },
    setPaymentType: (state, action: PayloadAction<PaymentCashMoneyModel | undefined>) => {
      state.payment.paymentType = action.payload;
    },
    setAddGroup: (state, action: PayloadAction<boolean>) => {
      state.inventory.addGroup = action.payload;
    },
    setVisibleInventory: (state, action: PayloadAction<{ visible: boolean; data?: Partial<InventoryBodyModel> }>) => {
      state.inventory.modal.visible = action.payload.visible;
      state.inventory.modal.data = action.payload.data;
    },
    setVisibleAct: (state, action: PayloadAction<{ visible: boolean; name?: string; company_person_id?: number }>) => {
      state.act.visible = action.payload.visible;
      state.act.name = action.payload.name;
      state.act.company_person_id = action.payload.company_person_id;
    },
    setVisibleModal: (
      state,
      action: PayloadAction<{
        visible: boolean;
        data?: CounterpartsCompanyPersonModel;
        onAfterFunc?: (data: { id: number; name: string; phone: string }) => void;
        initialData?: {
          name?: string;
          phone?: string;
        };
        requestKey?: string[];
        offer_id?: number;
      }>
    ) => {
      state.createAndUpdateModal.data = action.payload.data;
      state.createAndUpdateModal.visible = action.payload.visible;
      state.createAndUpdateModal.onAfterFunc = action.payload.onAfterFunc;
      state.createAndUpdateModal.initialData = action.payload.initialData;
      state.createAndUpdateModal.requestKey = action.payload.requestKey;
      state.createAndUpdateModal.offer_id = action.payload.offer_id;
    },
    setProductListModalOpen: (
      state,
      action: PayloadAction<{
        visible: boolean;
        id: number | undefined;
        company_person_name: string;
      }>
    ) => {
      state.productListModal.visible = action.payload.visible;
      state.productListModal.company_person_id = action.payload.id;
      state.productListModal.company_person_name = action.payload.company_person_name;
      state.productListModal.editingIds = [];
    },
    setViewType: (state, action: PayloadAction<string>) => {
      state.viewType = action.payload;
    },
    setEditing: (state, action: PayloadAction<string>) => {
      state.productListModal.editingIds?.push(action.payload);
    }
  }
});

export default companyPersonSlice.reducer;
export const companyPersonActions = companyPersonSlice.actions;
