import { TABLE } from "features/app/utils/constants/localStorageKeys";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";

import { useQueryParams } from "./useQueryParams";

const useGetTableSizeNumber = ({ tableConfigKey }: { tableConfigKey: tableConfigKeys }): number => {
  const { queries } = useQueryParams();
  const { size } = queries();

  const defaultSize = size || JSON.parse(localStorage.getItem(TABLE) || "{}")?.[tableConfigKey];

  return defaultSize || 10;
};

export default useGetTableSizeNumber;
