import { useInfiniteQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import { PaginationType } from "features/app/utils/models/PaginationType";

import { createTransferQueryKeys } from "../query-keys";
import { WarehouseProduct } from "../schema";

type Props = {
  search?: string;
  warehouse_id?: number;
};

export function useWarehouseProducts({ search, warehouse_id }: Props) {
  const { data, ...arg } = useInfiniteQuery<PaginationType<WarehouseProduct[]>>({
    queryKey: [createTransferQueryKeys.PRODUCTS, warehouse_id, search],
    queryFn: async ({ pageParam = 1 }) => {
      let url = `warehouse-product/view?size=50&availability=true&page=${pageParam ?? 1}&warehouse_id=${warehouse_id}`;

      if (search) {
        url += `&search=${search}`;
      }

      const res = await $api.get(url);

      return res.data.data;
    },
    getNextPageParam: (lastPage, page) => {
      if (page.length < lastPage?.data.length) {
        return page.length + 1;
      }
      return undefined;
    },
    retry: false,
    staleTime: 1000 * 3, // 3 second
    enabled: !!warehouse_id,
    refetchOnWindowFocus: false
  });

  return { ...data, ...arg };
}
