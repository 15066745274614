import { z } from "zod";

export const activatingMessagingSystemsSchema = z.object({
  login: z.string(),
  password: z.string(),
  creator: z.string()
});

export type ActivatingMessagingSystems = z.infer<typeof activatingMessagingSystemsSchema>;
export type ActivatingMessagingSystemsForm = ActivatingMessagingSystems;
export type MessageTab = "message" | "template";
