import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

import { parseLocaledString } from "modules/common";
import { getPopupContainer, PartyForm, useVats } from "modules/party/create";

import { Select } from "components";

type Props = {
  index: number;
  isUpdate?: boolean;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, index, isUpdate }) => {
  const { warehouse_products } = useWatch({
    control: form.control
  });
  const { vats, isLoading } = useVats();

  const onChange = (e: number, option: any) => {
    if (option) {
      form.setValue(`warehouse_products.${index}.vat_id`, e);
      form.setValue(
        `warehouse_products.${index}.vat_amount`,
        parseLocaledString(warehouse_products?.[index]?.total || "0") * (Number(option?.props?.name) / 100)
      );
      form.setValue(`warehouse_products.${index}.vat_value`, Number(option?.props?.name));
    } else {
      form.setValue(`warehouse_products.${index}.vat_id`, null);
      form.setValue(`warehouse_products.${index}.vat_amount`, null);
      form.setValue(`warehouse_products.${index}.vat_value`, null);
    }
  };

  return (
    <Select
      suffixIcon={null}
      allowClear={true}
      placeholder="NDS"
      onChange={onChange}
      loading={isLoading}
      control={form.control}
      wrapperClassName="h-full"
      popupMatchSelectWidth={false}
      value={warehouse_products?.[index]?.vat_id}
      name={`warehouse_products.${index}.vat_id`}
      getPopupContainer={getPopupContainer(isUpdate ? "party-left-content-update" : "party-left-content")}
      options={vats?.map(vat => ({
        key: vat?.id,
        value: vat?.id,
        label: <>{vat?.value} %</>,
        props: {
          name: String(vat?.value)
        }
      }))}
    />
  );
};

export default Index;
