import React from "react";
import { Dropdown } from "antd";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import ModalConfirm from "features/app/components/modal-confirm/ModalConfirm";
import UnreadMessageAction from "features/app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "features/app/components/unread-message/UnreadMessageDots";
import { ChatEnum } from "features/app/utils/enums/chatEnum";
import DotPointsIcon from "features/projects/assets/icons/DotPointsIcon";
import EyeIcon from "features/projects/assets/icons/EyeIcon";
import { WorkModel } from "features/projects/utils/models/workModel";
import { warehouseQueryNames } from "features/warehouse/utils/constants/warehouseQueryNames";
import { useTranslation } from "react-i18next";

import { useDispatch } from "store/index";
import { chatActions } from "store/reducers/chatReducer";
import { projectsReducerActions } from "store/reducers/projectsReducer";
import { useQueryParams } from "hooks/useQueryParams";

type Props = {
  record: WorkModel;
};

const Actions: React.FC<Props> = ({ record }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setWork } = projectsReducerActions;
  const { setVisible: setVisibleChat } = chatActions;
  const { generateSearchParam, queries } = useQueryParams();

  const { statistics_type, ...rest } = queries();
  const searchParams = generateSearchParam({
    id: record?.id,
    ...rest
  } as never);

  const onOpenChat = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(
      setVisibleChat({
        visible: true,
        objectId: record?.id,
        type: "tasks",
        dataKeys: ["taskView", searchParams],
        record
      })
    );
  };

  const dropdownRender = (child: React.ReactNode) => (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {child}
    </div>
  );

  const onOpenWorkModal = () => {
    dispatch(
      setWork({
        visible: true,
        id: record?.id
      })
    );
  };

  const items = [
    {
      key: "1",
      label: (
        <div className="flex items-center gap-2" onClick={onOpenWorkModal}>
          <EyeIcon /> {t("works.Ko'rish")}
        </div>
      ),
      permissionName: "view",
      isView: true
    },
    {
      key: "2",
      label: (
        <div className="flex items-center gap-2" onClick={onOpenChat}>
          <UnreadMessageAction count={record?.unread_message_count} />
        </div>
      ),
      permissionName: "chatView",
      isView: true
    },
    {
      key: "3",
      label: (
        <div className="flex items-center gap-2">
          <DotPointsIcon /> {t("works.Resurs sarfi")}
        </div>
      ),
      permissionName: "spentOfResources",
      isView: true
    },
    {
      key: "4",
      label: (
        <ModalConfirm>
          <div className="flex items-center gap-2">
            <DeleteIcon /> {t("works.O'chirish")}
          </div>
        </ModalConfirm>
      ),
      permissionName: "delete",
      isView: true
    }
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]} dropdownRender={dropdownRender}>
      <div className="cursor-pointer" onClick={e => e.stopPropagation()}>
        <UnreadMessageDots count={record?.unread_message_count} />
      </div>
    </Dropdown>
  );
};

export default Actions;
