import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

import { getPopupContainer, useProjects } from "modules/party/create";
import { PartyViewForm } from "modules/party/view";

import { TooltipShortName, TreeSelect } from "components";

import styles from "./items.module.scss";

type Props = {
  form: UseFormReturn<PartyViewForm>;
};

const Project: React.FC<Props> = ({ form }) => {
  const { projects } = useProjects();

  const { product } = useWatch({
    control: form.control
  });

  const onClear = () => {
    form.setValue("product.project_id", null);
  };

  return (
    <TreeSelect
      allowClear={true}
      suffixIcon={null}
      onClear={onClear}
      variant="borderless"
      placeholder="Loyiha"
      control={form.control}
      name="product.project_id"
      value={product?.project_id}
      popupMatchSelectWidth={false}
      className={styles.unit_select}
      treeData={projects?.map(project => ({
        key: project?.id,
        value: project?.id,
        label: (
          <TooltipShortName
            length={18}
            arrow={false}
            title={project?.name}
            getPopupContainer={getPopupContainer("party-left-content")}
          />
        ),
        props: {
          name: project?.name
        },
        children: project?.projects?.map(child => ({
          key: child?.id,
          value: child?.id,
          label: (
            <TooltipShortName
              length={18}
              arrow={false}
              title={child?.name}
              getPopupContainer={getPopupContainer("party-left-content")}
            />
          ),
          props: {
            name: child?.name
          }
        }))
      }))}
    />
  );
};

export default Project;
