import React from "react";
import { useParams } from "react-router-dom";
import { Col, Popover, Row, Spin } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { colors } from "modules/common";

import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { RU } from "../../../../app/utils/constants/languages";
import { dayjsUnits } from "../../../../app/utils/enums/dayjsUnits";
import { useGetDetailedProject, useGetProjectReportProductSpend } from "../../../service/queries";
import { WorkProductTypeEnum } from "../../../utils/enums/workProductTypeEnum";

import styles from "./estimateStatistics.module.scss";

const EstimateStatistics = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { data: projectData } = useGetDetailedProject(Number(params.projectId));
  const { data: statistics, isLoading: isLoadingStatistics } = useGetProjectReportProductSpend(
    Boolean(params?.projectId),
    Number(params?.projectId)
  );

  const diffClassName = (diff: number) => {
    if (diff > 0) {
      return styles.error;
    }
    if (diff < 0) {
      return styles.success;
    }

    return "";
  };

  const diffOperator = (diff: number) => {
    if (diff > 0) {
      return "+";
    }
    if (diff < 0) {
      return "-";
    }

    return "";
  };

  const styleOfAmounts = (first_amount: number, second_amount: number) => {
    if (first_amount <= second_amount) return styles.statistics__success;
    return styles.statistics__errro;
  };

  const popoverContent = (due_date: string, diff: number) => (
    <div className={styles.popover__content}>
      <div className={styles.popover__item}>
        <p>{t("project.Reja")}</p>
        <p>{due_date}</p>
      </div>
      <div className={styles.popover__item}>
        <p>{t("project.Farq")}</p>
        <p className={diffClassName(diff)}>
          {diffOperator(diff)} {Math.abs(diff)} kun
        </p>
      </div>
      <div className={styles.popover__item}>
        <p>{t("project.Fakt")}</p>
        <p>{projectData?.prediction_date}</p>
      </div>
    </div>
  );

  const prediction = () => {
    const diff = dayjs(projectData?.prediction_date, dayjsFormats.DATE).diff(
      dayjs(projectData?.due_date, dayjsFormats.DATE),
      dayjsUnits.DAY
    );

    return (
      <>
        {projectData?.due_date ? (
          <Popover overlayClassName={styles.popover} title={popoverContent(projectData?.due_date, diff)}>
            <span className={diffClassName(diff)}>{projectData?.due_date}</span>
          </Popover>
        ) : (
          `${t("project.Ma'lum emas")}`
        )}
      </>
    );
  };

  return (
    <Spin spinning={isLoadingStatistics} indicator={LoadingIndicator}>
      <Row>
        <Row className={styles.statistics}>
          <Col span={4} className={`${styles.statistics__col_bold} ${styles.statistics__col}`}>
            <p>-</p>
            <p>{t("project.Reja")}</p>
            <p>{t("project.Fakt")}</p>
            <p>{t("project.Bashorat")}</p>
          </Col>
          <Col span={4} className={classNames(styles.statistics__col, styles.statistics__col_bold)}>
            <p>{t("project.Hammasi")}</p>
            <p>
              {Math.round(Number(statistics?.total_amount))?.toLocaleString(RU)} {projectData?.currency?.symbol}
            </p>
            <p className={styleOfAmounts(Number(statistics?.prediction_amount), Number(statistics?.total_amount))}>
              {Math.round(Number(statistics?.spend_amount))?.toLocaleString(RU)} {projectData?.currency?.symbol}
            </p>
            <p className={styleOfAmounts(Number(statistics?.spend_amount), Number(statistics?.total_amount))}>
              {Math.round(Number(statistics?.prediction_amount))?.toLocaleString(RU)} {projectData?.currency?.symbol}
            </p>
          </Col>
          <Col span={4} className={styles.statistics__col}>
            <p className={styles.statistics__title}>
              <span
                className={styles.statistics__circle}
                style={{
                  background: statistics?.data?.filter(item => item.type === WorkProductTypeEnum.PERSON)[0]?.color
                }}
              />
              {t("project.Inson resursi")}
            </p>
            {statistics?.data?.map(
              item =>
                item.type === WorkProductTypeEnum.PERSON && (
                  <React.Fragment key={item?.id}>
                    <p>{Math.round(Number(item?.total_amount))?.toLocaleString(RU)} </p>
                    <p className={styleOfAmounts(Number(item?.spend_amount), Number(item?.total_amount))}>
                      {Math.round(Number(item?.spend_amount))?.toLocaleString(RU)}{" "}
                    </p>
                    <p className={styleOfAmounts(Number(item?.prediction_amount), Number(item?.total_amount))}>
                      {Math.round(Number(item?.prediction_amount))?.toLocaleString(RU)}{" "}
                    </p>
                  </React.Fragment>
                )
            )}
          </Col>
          <Col span={4} className={styles.statistics__col}>
            <p className={styles.statistics__title}>
              <span
                className={styles.statistics__circle}
                style={{
                  background: statistics?.data?.filter(item => item.type === WorkProductTypeEnum.PRODUCT)[0]?.color
                }}
              />{" "}
              {t("project.Mahsulotlar")}
            </p>
            {statistics?.data?.map(
              item =>
                item.type === WorkProductTypeEnum.PRODUCT && (
                  <React.Fragment key={item?.id}>
                    <p>{Math.round(Number(item?.total_amount))?.toLocaleString(RU)} </p>
                    <p className={styleOfAmounts(Number(item?.spend_amount), Number(item?.total_amount))}>
                      {Math.round(Number(item?.spend_amount))?.toLocaleString(RU)}{" "}
                    </p>
                    <p className={styleOfAmounts(Number(item?.prediction_amount), Number(item?.total_amount))}>
                      {Math.round(Number(item?.prediction_amount))?.toLocaleString(RU)}{" "}
                    </p>
                  </React.Fragment>
                )
            )}
          </Col>
          <Col span={4} className={styles.statistics__col}>
            <p className={styles.statistics__title}>
              <span
                className={styles.statistics__circle}
                style={{
                  background: statistics?.data?.filter(item => item.type === WorkProductTypeEnum.MECHANISM)[0]?.color
                }}
              />{" "}
              {t("project.Mexanizmlar")}
            </p>
            {statistics?.data?.map(
              item =>
                item.type === WorkProductTypeEnum.MECHANISM && (
                  <React.Fragment key={item?.id}>
                    <p>{Math.round(Number(item?.total_amount))?.toLocaleString(RU)} </p>
                    <p className={styleOfAmounts(Number(item?.spend_amount), Number(item?.total_amount))}>
                      {Math.round(Number(item?.spend_amount))?.toLocaleString(RU)}{" "}
                    </p>
                    <p className={styleOfAmounts(Number(item?.prediction_amount), Number(item?.total_amount))}>
                      {Math.round(Number(item?.prediction_amount))?.toLocaleString(RU)}{" "}
                    </p>
                  </React.Fragment>
                )
            )}
          </Col>
          <Col span={4} className={styles.statistics__col}>
            <p className={styles.statistics__title}>
              <span
                className={styles.statistics__circle}
                style={{
                  background: colors.GRAY_500
                }}
              />{" "}
              {t("project.Boshqalar")}
            </p>
            {statistics?.data?.map(
              item =>
                !item.type && (
                  <React.Fragment key={item?.id}>
                    <p>{Math.round(Number(item?.total_amount))?.toLocaleString(RU)} </p>
                    <p className={styleOfAmounts(Number(item?.spend_amount), Number(item?.total_amount))}>
                      {Math.round(Number(item?.spend_amount))?.toLocaleString(RU)}{" "}
                    </p>
                    <p className={styleOfAmounts(Number(item?.prediction_amount), Number(item?.total_amount))}>
                      {Math.round(Number(item?.prediction_amount))?.toLocaleString(RU)}{" "}
                    </p>
                  </React.Fragment>
                )
            )}
          </Col>
        </Row>
        <Row>
          <Col className={styles.due_date}>
            <p>{t("project.Muddati")}</p>
            <p className={styles.due_date__value}>
              <ConditionalRender if={projectData?.start_date}>
                <span>{projectData?.start_date}</span>
                <span>{projectData?.start_date ? "-" : ""}</span>
              </ConditionalRender>
              {prediction()}
            </p>
          </Col>
        </Row>
      </Row>
    </Spin>
  );
};

export default EstimateStatistics;
