import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { errorHandler, ErrorRes, SuccessRes } from "modules/common";

import $api from "../../../../features/app/utils/helpers/axiosInstance";
import { impostQueryKeys } from "../../../../features/impost/utils/constants/impostQueryKeys";
import { supplyQueryNames } from "../../../../features/supply/utils/constants/supplyQueryNames";
import { partyQueryKeys } from "../query-keys";

export function useDeleteProducts(notInvalidateParty = true) {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { party_id } = useAppSelector(state => state.partyReducer.view);

  return useMutation<SuccessRes, ErrorRes, number[]>(
    async ids => {
      const queryIds = queryString.stringify({ ids }, { arrayFormat: "bracket" });
      const res = await $api.delete(`supply/warehouse-product-delete?${queryIds}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.WAREHOUSE_PRODUCT]);
        await qc.invalidateQueries([supplyQueryNames.WAREHOUSE_PRODUCT_COUNT]);
        await qc.invalidateQueries([supplyQueryNames.ORDERS]);
        await qc.invalidateQueries([impostQueryKeys.PARTS]);

        if (notInvalidateParty) {
          await qc.invalidateQueries([partyQueryKeys.VIEW, party_id]);
        }

        message.success(res.message[i18n.language]);
      }
    }
  );
}
