import React, { Dispatch, PropsWithChildren, ReactNode, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Drawer } from "antd";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { dayjsFormats } from "modules/common";

import { useQueryParams } from "../../../../hooks/useQueryParams";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import { queryParamsKeys } from "../../utils/constants/queryParamsKeys";
import { parseLocaledString } from "../../utils/helpers/parseLocaledString";

import styles from "./filterDrawer.module.scss";

interface SelectedOptionsModel {
  queryKey: string;
  selectedOptions: string[] | null;
}

interface SelectedValueModel {
  queryKey: string;
  selectedValue: string | null;
}

interface DateValueModel {
  queryKey: string;
  selectedValue: Dayjs | null;
}

type Props = {
  open: boolean;
  height?: number | string;
  filterButton: ReactNode;
  queryStringArr?: string[];
  onAfterClear?: (filter?: string) => void;
  onAfterSubmit?: (filter?: string) => void;
  dateValueArr?: DateValueModel[];
  // eslint-disable-next-line no-unused-vars
  setOpen: (open: boolean) => void;
  selectedValueArr?: SelectedValueModel[];
  selectedOptionsArr?: SelectedOptionsModel[];
  placement?: "top" | "right" | "bottom" | "left";
  setSelectedValueArr?: React.Dispatch<React.SetStateAction<string>>[];
  setDateValue?: Dispatch<SetStateAction<(dayjs.Dayjs | null)[] | null>>[];
  setSelectedOptionsArr?: React.Dispatch<React.SetStateAction<string[] | null | { [key: string]: string }>>[];
  footerContent?: ReactNode;
  className?: string;
  afterOpenChange?: (open: boolean) => void;
};

const FilterDrawer = ({
  open,
  height,
  setOpen,
  children,
  placement,
  className,
  setDateValue,
  filterButton,
  dateValueArr,
  onAfterClear,
  onAfterSubmit,
  queryStringArr,
  afterOpenChange,
  selectedValueArr,
  selectedOptionsArr,
  setSelectedValueArr,
  setSelectedOptionsArr,
  footerContent
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { append } = useQueryParams();

  const onCancel = () => {
    setOpen(false);
  };

  const onClear = () => {
    const queryValue = queryString.parse(window.location.search, {
      arrayFormat: "bracket"
    });

    queryStringArr?.map(item => delete queryValue[item]);
    navigate({
      search: queryString.stringify({ ...queryValue }, { arrayFormat: "bracket" })
    });
    setSelectedOptionsArr?.forEach(item => item([] || {}));
    setSelectedValueArr?.forEach(item => item(""));
    setDateValue?.forEach(item => item([]));

    const { page, tab, statistics_type, type, size, ...rest } = queryValue;
    const filterString = queryString.stringify(rest, { arrayFormat: "bracket" });

    onAfterClear && onAfterClear(filterString);
  };

  const onSubmit = () => {
    setOpen(false);
    append(queryParamsKeys.PAGE, "1");

    const updatedQuery = queryString.parse(window.location.search, {
      arrayFormat: "bracket"
    });

    selectedOptionsArr?.forEach(item => {
      if (item.selectedOptions && item.selectedOptions.length > 0) {
        updatedQuery[item.queryKey] = item.selectedOptions;
      } else {
        delete updatedQuery[item.queryKey];
      }
    });

    selectedValueArr?.forEach(item => {
      if (item.selectedValue && item.selectedValue.length > 0) {
        (updatedQuery[item.queryKey] as unknown) = parseLocaledString(item.selectedValue);
      } else {
        delete updatedQuery[item.queryKey];
      }
    });

    dateValueArr?.forEach(item => {
      if (item.selectedValue) {
        updatedQuery[item.queryKey] = dayjs(item.selectedValue, dayjsFormats.DATE).format(dayjsFormats.DATE);
      } else {
        delete updatedQuery[item.queryKey];
      }
    });

    navigate({
      search: queryString.stringify(updatedQuery, { arrayFormat: "bracket" })
    });

    const { page, tab, statistics_type, type, size, ...rest } = updatedQuery;

    const filterString = queryString.stringify(rest, { arrayFormat: "bracket" });

    onAfterSubmit && onAfterSubmit(filterString);
  };

  const emptyFunc = () => {};

  return (
    <>
      {filterButton}
      <Drawer
        open={open}
        title={false}
        closeIcon={false}
        onClose={onCancel}
        height={height ?? 230}
        placement={placement ?? "top"}
        maskClassName={styles.drawer__wrapper}
        afterOpenChange={afterOpenChange && emptyFunc}
        className={classNames(styles.drawer, className)}
      >
        {children}
        <div className={styles.footer}>
          {footerContent || <span />}
          <div className={styles.actions}>
            <Button onClick={onClear}>
              <DeleteIcon /> {t("Monitoring.Tozalash")}
            </Button>
            <Button type="primary" onClick={onSubmit}>
              {t("Monitoring.Filtrni saqlash")}
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default FilterDrawer;
