import React from "react";
import { useDispatch } from "react-redux";
import { FieldErrors, UseFormHandleSubmit, UseFormSetValue } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, message } from "antd";
import $api from "features/app/utils/helpers/axiosInstance";
import i18next from "i18next";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/actions";

import { errorHandler, ErrorRes, SuccessRes } from "modules/common";
import { PartyForm } from "modules/party/create/form";
import { CompanyPerson, partyQueryKeys } from "modules/party/view";

import { parseReqPayload } from "../helpers";
import { CompanyPersonForm, CompanyPersonReqPayload } from "../schema";

type Props = {
  isSubmitting: boolean;
  setParentValue: UseFormSetValue<PartyForm>;
  handleSubmit: UseFormHandleSubmit<CompanyPersonForm>;
};

const Footer: React.FC<Props> = ({ handleSubmit, isSubmitting, setParentValue }) => {
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const { setPartyCreateCompanyPerson } = partyReducerActions;
  const { fieldName } = useAppSelector(({ partyReducer }) => partyReducer.createCompanyPerson);

  const onCancel = () => {
    dispatch(setPartyCreateCompanyPerson({ visible: false, name: undefined, fieldName }));
  };

  const mutation = useMutation<SuccessRes<CompanyPerson>, ErrorRes, CompanyPersonReqPayload>(
    async payload => {
      const { data } = await $api.post("company-person/create", payload);

      return data;
    },
    {
      onError: errorHandler,
      onSuccess: async ({ message: mesObj, data }) => {
        message.success(mesObj?.[i18next.language]);
        await qc.invalidateQueries([partyQueryKeys.COMPANY_PERSONS]);

        setParentValue(`${fieldName}.company_person_id`, data?.id);
        setParentValue(`${fieldName}.company_person_name`, data?.name);
        onCancel();
      }
    }
  );

  const onSubmit = async (values: CompanyPersonForm) => {
    await new Promise(onSettled => {
      mutation.mutate(parseReqPayload(values), { onSettled });
    });
  };

  const onError = (error: FieldErrors) => {
    console.log("Create company person ERRORS", error);
  };

  return (
    <div className="flex items-center justify-end gap-3">
      <Button onClick={onCancel}>Yopish</Button>
      <Button type="primary" onClick={handleSubmit(onSubmit, onError)} loading={isSubmitting}>
        Saqlash
      </Button>
    </div>
  );
};

export default Footer;
