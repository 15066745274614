import { ReactNode } from "react";
import { z } from "zod";

import { productSchema, unitSchema } from "modules/common";
import { PartyFormProduct } from "modules/party/create/form";

export const agentSchema = z.object({
  id: z.number(),
  full_name: z.string(),
  image: z.string().nullable(),
  type: z.string().optional()
});

export type BaseTableColumns = Array<{
  key: string | number;
  title: ReactNode;
  width?: number;
  render: (record: PartyFormProduct, index: number) => ReactNode;
  hidden?: boolean;
}>;

export const productWithUnitSchema = productSchema.merge(
  z.object({
    unit: unitSchema
  })
);

export type Agent = z.infer<typeof agentSchema>;
export type ProductWithUnit = z.infer<typeof productWithUnitSchema>;
export type TargetType = EventTarget & {
  scrollTop: number;
  offsetHeight: number;
  scrollHeight: number;
};
