import React from "react";

const PaperClipIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.1525 10.8995L12.1369 19.9151C10.0866 21.9654 6.7625 21.9654 4.71225 19.9151C2.662 17.8649 2.662 14.5407 4.71225 12.4905L13.7279 3.47487C15.0947 2.10804 17.3108 2.10804 18.6776 3.47487C20.0444 4.84171 20.0444 7.05778 18.6776 8.42462L10.0156 17.0867C9.33213 17.7701 8.22409 17.7701 7.54068 17.0867C6.85726 16.4033 6.85726 15.2952 7.54068 14.6118L15.1421 7.01041"
      stroke="#475467"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PaperClipIcon;
