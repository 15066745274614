import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import PlusIcon from "features/app/assets/icons/PlusIcon";
import SelectNotContentIcon from "features/app/assets/icons/SelectNotContentIcon";

import { partyReducerActions } from "store/actions";

type Props = {
  search?: string;
  fieldName: `warehouse_products.${number}` | `company_person_group.${number}.products.${number}`;
};

const CompanyPersonEmpty: React.FC<Props> = ({ search: name, fieldName }) => {
  const dispatch = useDispatch();
  const { setPartyCreateCompanyPerson } = partyReducerActions;

  const onAddCompanyPerson = () => {
    dispatch(setPartyCreateCompanyPerson({ visible: true, name, fieldName }));
  };

  return (
    <div className="box-border flex flex-col items-center justify-center gap-3 p-3">
      <SelectNotContentIcon />
      <h4 className="m-0 text-center text-sm font-semibold text-gray-900">Ma'lumot topilmadi</h4>
      <p className="mx-0 mb-3 mt-1 w-[70%] text-center text-xs font-normal text-gray-600">
        <span>
          Kechirasiz, biz siz izlayotgan ma'lumotni topa olmadik.
          <br /> Iltimos, qayta urinib ko'ring.
        </span>
      </p>
      <Button onClick={onAddCompanyPerson} type="primary">
        <PlusIcon color="#fff" /> Ta'minotchi yaratish
      </Button>
    </div>
  );
};

export default CompanyPersonEmpty;
