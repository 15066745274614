import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { useQueryParams } from "hooks/useQueryParams";

import { ProductWithUnit } from "modules/party/create";
import { partyResourceQueryKeys } from "modules/party/resource";

type Query = {
  total: number;
  current_page: number;
  products: ProductWithUnit[];
};

type Props = {
  category_id?: number;
  subcategory_id?: number;
  search?: string;
  page?: number;
};

export function useResourceProducts({ category_id, subcategory_id, search, page }: Props) {
  const initial: Query = {
    products: [],
    current_page: 1,
    total: 0
  };

  const { generateSearchParam } = useQueryParams();
  const searchParams = generateSearchParam({
    category_id,
    subcategory_id,
    search,
    page,
    category_only: !!subcategory_id
  });

  const { data = initial, ...arg } = useQuery<Query>(
    [partyResourceQueryKeys.RESOURCES, searchParams],
    async () => {
      const { data } = await $api.get(`product/select?paginate=true&${searchParams}`);

      return { products: data?.data?.data, total: data?.data?.total, current_page: data?.data?.current_page };
    },
    {
      enabled: !!search || !!category_id || !!subcategory_id
    }
  );

  return { ...data, ...arg };
}
