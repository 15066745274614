import React, { useMemo } from "react";
import { Button, Form, FormInstance, Input, Select, Spin } from "antd";
import { Rule } from "antd/es/form";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import SelectSuffixIcon from "features/app/assets/icons/SelectSuffixIcon";
import { useGetUnitSelect } from "features/app/service/queries";
import { formRules } from "features/app/utils/constants/formRules";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import { useDeleteLaboratory } from "features/warehouse/service/mutation";
import { onQuantityChange } from "features/warehouse/utils/helpers/onQuantityChange";
import {
  LaboratoryDrawerFormModel,
  LaboratoryDrawerProducts
} from "features/warehouse/utils/models/laboratoryDrawerFormModel";
import { useTranslation } from "react-i18next";

import { useSelector } from "store";

import { colors, cx, sliceText } from "modules/common";

import { LoadingIndicator } from "components";

import ProductListSelect from "./ProductSelect";

import styles from "./card.module.scss";

const { Item, useWatch } = Form;

type Props = {
  form: FormInstance<LaboratoryDrawerFormModel>;
  index: number;
  remove: (index: number | number[]) => void;
};

const Card: React.FC<Props> = ({ form, index, remove }) => {
  const { isViewing, isUpdating, id } = useSelector(state => state.laboratoryDrawerReducer);
  const { t, i18n } = useTranslation();
  const productData = useWatch(["products", index], form) as LaboratoryDrawerProducts;
  const products = useWatch("products", form) as LaboratoryDrawerProducts[];

  const {
    unit_symbol,
    total_quantity,
    rowId,
    expense_quantity,
    amount,
    id: product_id,
    income_unit_id,
    expense_unit_id: unitId
  } = productData || {};

  const deleteMutation = useDeleteLaboratory();
  const productId = Number(String(product_id)?.split("-")[0]);

  const { data: units } = useGetUnitSelect(true);

  const filteredUnits = useMemo(() => units?.filter(unit => unit?.id !== unitId), [units, unitId]);

  const onDeleteProduct = () => {
    deleteMutation.mutateAsync({ id: id as number, product_id: productId, income_unit_id }).then(() => {
      form.setFieldsValue({
        products: products?.filter(
          item => !(Number(String(item?.id)?.split("-")[0]) === productId && item.expense_unit_id === unitId)
        )
      });
    });
  };

  const toUnitSymbol = filteredUnits?.find(el => el?.id === income_unit_id)?.symbol[i18n.language];

  return (
    <Spin spinning={isViewing ? deleteMutation.isLoading : false} indicator={LoadingIndicator}>
      <div
        className={cx(
          "flex h-max flex-col gap-3 rounded-2xl border border-solid border-gray-200 bg-white p-4",
          styles.card
        )}
      >
        <div className="flex items-center gap-3 rounded-xl border border-solid border-gray-200 p-3">
          <div className="w-[88%]">
            <ProductListSelect key={rowId!} form={form} index={index} rowId={rowId!} />
          </div>
          <Button
            className={cx(
              "flex cursor-pointer items-center justify-center rounded-xl border border-solid border-gray-200 p-3",
              styles.deleteBtn
            )}
            onClick={() => {
              if (!isViewing) {
                remove(index);
              } else {
                onDeleteProduct();
              }
            }}
            disabled={isUpdating}
          >
            <DeleteIcon />
          </Button>
        </div>
        <div className="flex flex-col items-center gap-2 rounded-[19px] border border-solid border-gray-200 bg-gray-200 p-3">
          <div className="flex w-[480px] items-center justify-between rounded-2xl border border-solid border-gray-200 bg-white px-4 py-3">
            <div className="flex flex-col gap-3">
              <span className="text-sm font-medium text-gray-400">{t("warehouse.Joriy birlik")}</span>
              <span className={cx("text-xl font-medium", unit_symbol ? "text-gray-800" : "text-gray-500")}>
                {unit_symbol || t("warehouse.Birlik")}
              </span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-sm font-medium text-gray-400">{t("warehouse.Soni")}</span>
              <Item
                name={[index, "expense_quantity"]}
                rules={[
                  ...formRules(),
                  {
                    validator: (rule: Rule, value) => {
                      if (Number(value) <= 0) {
                        return Promise.reject("");
                      }
                      if (Number(value) > Number(total_quantity)) {
                        return Promise.reject("");
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input
                  type="number"
                  disabled={isViewing}
                  className={styles.quantity_input}
                  onChange={e =>
                    onQuantityChange(Number(e.target.value), amount!, "multiply", form, [
                      "products",
                      index,
                      "income_quantity"
                    ])
                  }
                  placeholder={t("warehouse.Soni")}
                  onInput={e => {
                    const inputValue = Number(e.currentTarget.value);

                    if (inputValue <= 0) {
                      e.currentTarget.value = "";
                    }
                  }}
                />
              </Item>
            </div>
          </div>

          <div className="flex min-w-[289px] max-w-[480px] items-center justify-center gap-3 self-center rounded-xl border border-solid border-gray-200 bg-white px-3 py-2">
            <span className="text-sm font-medium text-gray-900">{unit_symbol || t("warehouse.Birlik")}</span>
            <span className="flex h-6 w-6 items-center justify-center rounded-[50%] border border-solid border-primary-300 bg-primary-300">
              <ArrowNarrowIcon rotate={180} stroke={colors.PRIMARY_600} />
            </span>
            <Item name={[index, "amount"]}>
              <Input
                type="number"
                disabled={isViewing}
                onChange={e =>
                  onQuantityChange(expense_quantity!, Number(e.target.value), "multiply", form, [
                    "products",
                    index,
                    "income_quantity"
                  ])
                }
                suffix={sliceText(toUnitSymbol!, 25)}
                className={cx(styles.amount_input, "min-w-[150px]")}
                placeholder={t("warehouse.Qiymat")}
                onInput={e => {
                  const inputValue = Number(e.currentTarget.value);

                  if (inputValue <= 0) {
                    e.currentTarget.value = "";
                  }
                }}
              />
            </Item>
          </div>

          <div className="flex w-[480px] items-center justify-between rounded-2xl border border-solid border-gray-200 bg-white px-4 py-3">
            <div className="flex flex-col gap-1">
              <span className="text-sm font-medium text-gray-400">{t("warehouse.O'tuvchi birlik")}</span>
              <Item name={[index, "income_unit_id"]} rules={[...formRules()]}>
                <Select
                  suffixIcon={<SelectSuffixIcon />}
                  className={styles.unit_select}
                  placeholder={t("warehouse.Birlik")}
                  showSearch
                  filterOption={selectFilterOption}
                  disabled={isViewing}
                  popupMatchSelectWidth={false}
                >
                  {filteredUnits?.map(unit => (
                    <Select.Option
                      key={unit?.id}
                      value={unit?.id}
                      props={{
                        name: unit?.name[i18n.language],
                        symbol: unit?.symbol[i18n.language]
                      }}
                    >
                      {unit?.symbol[i18n.language]}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-sm font-medium text-gray-400">{t("warehouse.Soni")}</span>
              <Item name={[index, "income_quantity"]} rules={formRules()}>
                <Input
                  disabled={isViewing}
                  type="number"
                  onChange={e =>
                    onQuantityChange(Number(e.target.value), expense_quantity!, "divide", form, [
                      "products",
                      index,
                      "amount"
                    ])
                  }
                  className={styles.quantity_input}
                  placeholder={t("warehouse.Soni")}
                  onInput={e => {
                    const inputValue = Number(e.currentTarget.value);

                    if (inputValue <= 0) {
                      e.currentTarget.value = "";
                    }
                  }}
                />
              </Item>
            </div>
          </div>
        </div>
        {!isViewing && (
          <div className="flex items-center gap-2">
            <span className="text-base font-medium text-gray-400">{t("warehouse.Mavjud")}:</span>
            <span className="text-base font-semibold text-gray-700">
              {total_quantity ? (
                <span>
                  {total_quantity} {unit_symbol}
                </span>
              ) : (
                0
              )}
            </span>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default Card;
