import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { Spin } from "antd";

import { isEmptyArr } from "modules/common";
import { PartyEstimateForm, usePartySections } from "modules/party/estimate";

import Section from "pages/party/estimate/content/children/section";
import Title from "pages/party/estimate/content/children/title";

import { ConditionalRender, EmptyContent, LoadingIndicator } from "components";

type Props = {
  currencySymbol?: string;
  form: UseFormReturn<PartyEstimateForm>;
};

const Index: React.FC<Props> = ({ form, currencySymbol }) => {
  const { project_id: projectId } = useWatch({ control: form.control });
  const { sections, isLoading } = usePartySections({ projectId });

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className="flex h-full flex-col">
        <Title currency_symbol={currencySymbol} />
        <ConditionalRender
          if={!isEmptyArr(sections)}
          else={
            <div className="box-border flex items-center justify-center p-4">
              <EmptyContent />
            </div>
          }
        >
          <div className="box-border flex h-[84vh] flex-col gap-3 overflow-y-auto p-5">
            {sections?.map(section => <Section key={section?.id} {...section} form={form} />)}
          </div>
        </ConditionalRender>
      </div>
    </Spin>
  );
};

export default Index;
