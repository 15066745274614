import React from "react";
import { Radio, RadioChangeEvent } from "antd";
import { useTranslation } from "react-i18next";

import { MessageTab } from "modules/settings/message";

import styles from "./content.module.scss";

type Props = {
  setCurrentTab: React.Dispatch<React.SetStateAction<MessageTab>>;
  currentTab: string;
};

const Tabs: React.FC<Props> = ({ currentTab, setCurrentTab }) => {
  const changeTabPosition = (e: RadioChangeEvent) => {
    setCurrentTab(e.target.value);
  };

  const { t } = useTranslation();

  return (
    <div className={styles.tab_group}>
      <Radio.Group value={currentTab} onChange={changeTabPosition}>
        <Radio.Button value="message">{t("settings.interface.Sms tizimi")}</Radio.Button>
        <Radio.Button value="template">{t("settings.interface.Shablonlar")}</Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default Tabs;
