import React from "react";
import { useDispatch } from "react-redux";
import { Popover } from "antd";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { warehouseProductsAction } from "store/actions";

import { cx } from "modules/common";
import { PartyView, resourcePercent } from "modules/party/view";

type Props = {
  item: PartyView["warehouse_products"][number];
};

const ResourceAvailability: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setResource } = warehouseProductsAction;
  const { warehouse_total_quantity, quantity, unit } = item;
  const percent = resourcePercent(quantity, warehouse_total_quantity);

  const content = (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between gap-6">
        <span className="text-sm font-normal text-gray-500">{t("impost.Buyurtma qilingan")}</span>
        <div className="flex items-center gap-1 text-sm font-medium text-gray-600">
          <span>{quantity}</span>
          <span className="text-gray-400">{unit?.symbol[i18n.language]}</span>
        </div>
      </div>
      <div className="flex items-center justify-between gap-6">
        <span className="text-sm font-normal text-gray-500">{t("impost.Mavjud")}</span>
        <div className="flex items-center gap-1 text-sm font-medium text-gray-600">
          <span>{warehouse_total_quantity}</span>
          <span className="text-gray-400">{unit?.symbol[i18n.language]}</span>
        </div>
      </div>
    </div>
  );

  const onOpenResource = () => {
    dispatch(
      setResource({
        visible: true,
        product: item as never
      })
    );
  };

  return (
    <div className="flex h-full items-center pl-2.5 text-sm font-medium">
      <Popover arrow={false} placement="top" trigger={["hover"]} content={content}>
        <div
          onClick={onOpenResource}
          className={cx("cursor-pointer whitespace-nowrap rounded-2xl px-3 py-1", {
            "bg-error-50 text-error-500": percent >= 0 && percent <= 30,
            "bg-warning-50 text-warning-500": percent > 30 && percent <= 70,
            "bg-success-50 text-success-500": percent > 70
          })}
        >
          {percent} %
        </div>
      </Popover>
    </div>
  );
};

export default ResourceAvailability;
