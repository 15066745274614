import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { partyCreateQueryKeys } from "modules/party/create";
import { IdAndNameString } from "modules/party/view";

interface Query {
  projects: {
    id: number;
    name: string;
    projects: IdAndNameString[];
  }[];
}

export function useProjects() {
  const initial: Query = {
    projects: []
  };

  const { data = initial, ...arg } = useQuery<Query>([partyCreateQueryKeys.PROJECTS], async () => {
    const { data } = await $api.get("project/select");

    return { projects: data?.data };
  });

  return { ...data, ...arg };
}
