import React from "react";
import { useDispatch } from "react-redux";
import { Dropdown, DropdownProps } from "antd";
import UnreadMessageDots from "features/app/components/unread-message/UnreadMessageDots";
import { routeSubmodules } from "features/impost/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { partyReducerActions } from "store/actions";

import { PartyStatus, usePartyDelete, WarehouseProduct } from "modules/party/view";

import { EditIcon, ModalConfirm, TrashIcon } from "components";

import { BaseItems } from "../base";

import ResourceAvailability from "./resource-availability";

type Props = {
  status: PartyStatus;
  unreadMessageCount: number;
  products: WarehouseProduct[];
};

const Index: React.FC<Props> = ({ unreadMessageCount, products, status }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deleteParty = usePartyDelete();
  const { setPartyView, setPartyUpdateVisible } = partyReducerActions;
  const { party_id, type } = useAppSelector(state => state.partyReducer.view);
  const { actions: actionFunc } = useRoutePermissions("Monitoring", routeSubmodules);
  const actions = actionFunc("Partiyalar");

  const onCancel = () => {
    dispatch(
      setPartyView({
        visible: false,
        party_id,
        type,
        status
      })
    );
  };

  const onDelete = () =>
    deleteParty
      .mutateAsync({
        id: party_id!
      })
      .then(onCancel);

  const onUpdate = () => {
    dispatch(
      setPartyUpdateVisible({
        id: party_id,
        visible: true,
        zIndex: 111119
      })
    );

    onCancel();
  };

  const menu = (): DropdownProps["menu"] => {
    const items = [
      ...BaseItems(),
      {
        key: "4",
        label: (
          <div className="flex items-center gap-2">
            <EditIcon />
            {t("partyView.Tahrirlash")}
          </div>
        ),
        onClick: onUpdate,
        isView: actions?.edit && status !== "recieved" && status !== "rejected"
      },
      {
        key: "5",
        label: <ResourceAvailability products={products} />,
        isView: actions?.resource
      },
      {
        key: "6",
        label: (
          <ModalConfirm title="Partiyani o'chirmoqchimisiz ?" onOk={onDelete}>
            <div className="flex items-center gap-2">
              <TrashIcon />
              {t("partyView.O'chirish")}
            </div>
          </ModalConfirm>
        ),
        isView: actions?.delete
      }
    ];

    return {
      items: items?.filter(item => item?.isView)
    };
  };

  return (
    <Dropdown destroyPopupOnHide arrow={false} placement="bottom" trigger={["click"]} menu={menu()}>
      <div className="flex cursor-pointer items-center justify-center rounded-md border border-solid border-gray-300 bg-gray-50 px-2 py-1.5">
        <UnreadMessageDots count={unreadMessageCount} />
      </div>
    </Dropdown>
  );
};

export default Index;
