import { ReactNode } from "react";
import { Checkbox, Select } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import SelectSuffixIcon from "../../../assets/icons/SelectSuffixIcon";
import { getFirstLetter } from "../../../utils/helpers/getFirstLetter";
import TableEmpty from "../../table-empty/TableEmpty";

import styles from "./filterSelect.module.scss";

const { Option } = Select;

export interface FilterSelectModel {
  id: number | string;
  name: string | { [key: string]: string };
  image?: string;
  icon?: JSX.Element;
}

type Props = {
  selectedOptions: string[] | null;
  setSelectedOptions: (selected: string[] | null) => void;
  multiple?: "multiple" | "tags";
  data?: FilterSelectModel[];
  icon?: ReactNode;
  placeholder?: string;
  showSearch?: boolean;
  isPersons?: boolean;
  isCurrency?: boolean;
  className?: string;
  allowClear?: boolean;
};

const FilterSelect = ({
  data,
  selectedOptions,
  setSelectedOptions,
  multiple,
  icon,
  placeholder,
  showSearch,
  isPersons,
  isCurrency,
  className,
  allowClear
}: Props) => {
  const { i18n } = useTranslation();
  const onChange = (values: string[]) => {
    setSelectedOptions(values);
  };

  return (
    <Select
      mode={multiple}
      maxTagCount={0}
      allowClear={allowClear}
      maxTagPlaceholder={values => (
        <>
          {values?.length === 1 ? (
            <div className={styles.select__tags}>
              {icon}
              <span>{values[0]?.title}</span>
            </div>
          ) : (
            <div className={styles.select__tags}>
              {icon} {placeholder} ({values.length})
            </div>
          )}
        </>
      )}
      rootClassName={styles.root_select}
      className={classNames(styles.select, className, "filter-custom-select")}
      menuItemSelectedIcon={false}
      placeholder={
        <div className={styles.placeholder}>
          {icon} {placeholder}
        </div>
      }
      onChange={onChange}
      showSearch={!!showSearch}
      value={selectedOptions}
      filterOption={(input, option) => (option?.title?.toLowerCase() ?? "").includes(input?.toLowerCase())}
      suffixIcon={<SelectSuffixIcon />}
      notFoundContent={<TableEmpty />}
      optionLabelProp="title"
    >
      {multiple
        ? data?.map(item => (
            <Option
              key={item.id}
              value={item.id.toString()}
              title={typeof item.name === "string" ? item.name : item.name[i18n.language]}
            >
              {isPersons || isCurrency ? (
                <div className={styles.users}>
                  <div className={`${styles.name} ${isCurrency ? styles.currency : ""}`}>
                    {item?.image ? (
                      <img
                        src={item.image}
                        alt={typeof item.name === "string" ? item.name : item.name[i18n.language]}
                      />
                    ) : (
                      <span className={styles.non_image}>
                        {item?.name &&
                          getFirstLetter(typeof item.name === "string" ? item.name : item.name[i18n.language])}
                      </span>
                    )}{" "}
                    {typeof item.name === "string" ? item.name : item.name[i18n.language]}
                  </div>
                  <Checkbox checked={selectedOptions?.includes(item.id.toString())} />
                </div>
              ) : (
                <div className={styles.option}>
                  <div className="flex items-center gap-1.5">
                    {item?.icon}
                    {typeof item.name === "string" ? item.name : item.name[i18n.language]}
                  </div>
                  <Checkbox checked={selectedOptions?.includes(item.id.toString())} />
                </div>
              )}
            </Option>
          ))
        : data?.map(item => (
            <Option
              key={item.id}
              value={item.id.toString()}
              title={typeof item.name === "string" ? item.name : item.name[i18n.language]}
            >
              {isPersons || isCurrency ? (
                <div className={styles.users}>
                  <div className={`${styles.name} ${isCurrency ? styles.currency : ""}`}>
                    <img src={item.image} alt={typeof item.name === "string" ? item.name : item.name[i18n.language]} />{" "}
                    {typeof item.name === "string" ? item.name : item.name[i18n.language]}
                  </div>
                </div>
              ) : (
                <div className={styles.option}>
                  {typeof item.name === "string" ? item.name : item.name[i18n.language]} {item?.icon && item.icon}
                </div>
              )}
            </Option>
          ))}
    </Select>
  );
};

export default FilterSelect;
