import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { AdmissionHistory, useAdmissionHistory } from "modules/party/admission-history";

import { EmptyContent, LoadingIndicator } from "components";

const Left: React.FC = () => {
  const { i18n } = useTranslation();
  const { orderId } = useAppSelector(({ partyReducer }) => partyReducer.admissionHistory);
  const { histories, isLoading } = useAdmissionHistory({ orderId });

  const columns: ColumnsType<AdmissionHistory> = [
    {
      title: "Resurs turi va nomi",
      render: (_, record) => (
        <div className="flex items-center gap-3">
          <div
            className="box-border flex min-w-max items-center justify-center rounded border border-solid px-2 py-0.5 text-xs font-medium leading-[1.125rem]"
            style={{
              color: record?.product?.resource?.color,
              borderColor: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol?.[i18n.language]}
          </div>
          <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray-900">
            {record?.product?.name?.[i18n.language]}
          </span>
        </div>
      )
    },
    {
      title: "Miqdori",
      render: (_, { quantity, unit }) => (
        <div className="flex items-center gap-1">
          <span>{quantity}</span>
          <span className="text-sm font-medium text-gray-400">{unit?.symbol?.[i18n.language]}</span>
        </div>
      )
    },
    {
      title: "Sana",
      dataIndex: "recieved_date"
    }
  ];

  return (
    <div className="box-border flex-1 overflow-auto border border-b-0 border-l-0 border-t-0 border-solid border-gray-200 p-4">
      <Table<AdmissionHistory>
        columns={columns}
        pagination={false}
        dataSource={histories}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <EmptyContent />
        }}
      />
    </div>
  );
};

export default Left;
