import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { ProductSelectModel } from "../../features/app/utils/models/productSelectModel";
import { StatusEnums } from "../../features/supply/utils/enums/statusEnums";
import {
  ConfirmPartyModel,
  OfferErrorModel,
  OfferFormProductModel,
  WarehouseProductOffers
} from "../../features/supply/utils/models/OfferFormModel";
import { OfferModalDataModel, OfferModel } from "../../features/supply/utils/models/OfferModel";
import {
  OfferPartyErrorModel,
  OfferPartyLeftModel,
  OfferPartyRightModel
} from "../../features/supply/utils/models/offerPartyModel";

interface SupplyOfferReducerModel {
  offerModal: {
    visible: boolean;
    data?: OfferModalDataModel[];
    warehouseOffers: WarehouseProductOffers[];
    offerErrors: OfferErrorModel[];
    isParty?: boolean;
    isEdit?: boolean;
    viewPartySwitch?: boolean;
    partyModal: ConfirmPartyModel;
    unitId?: number;
    isCatalog?: boolean;
  };
  selectOffers: {
    data: OfferModel[];
    ids: number[];
  };
  partyModal: {
    search?: string;
    visible: boolean;
    isGrouping?: boolean;
    selectData?: OfferModel[];
    leftData: OfferPartyLeftModel[];
    rightData: OfferPartyRightModel;
    checkData: OfferPartyLeftModel[];
    partyErrors: OfferPartyErrorModel[];
    selectProducts: ProductSelectModel[];
  };
  offerHistory: {
    productId: number;
    unitId?: number;
    visible: boolean;
    productKey?: string;
    isCheckedView?: boolean;
  };
}

const initialState: SupplyOfferReducerModel = {
  offerModal: {
    visible: false,
    warehouseOffers: [],
    offerErrors: [],
    isParty: false,
    isCatalog: false,
    partyModal: {
      visible: false
    }
  },
  selectOffers: {
    data: [],
    ids: []
  },
  partyModal: {
    visible: false,
    selectProducts: [],
    rightData: {},
    leftData: [],
    partyErrors: [],
    isGrouping: false,
    checkData: []
  },
  offerHistory: {
    productId: 0,
    unitId: 0,
    visible: false,
    productKey: "",
    isCheckedView: false
  }
};

const supplyOfferSlice = createSlice({
  name: sliceNames.SUPPLY_OFFER,
  initialState,
  reducers: {
    setOfferModal: (
      state,
      action: PayloadAction<{
        visible: boolean;
        isEdit?: boolean;
        viewPartySwitch?: boolean;
        data?: OfferModalDataModel[];
        isCatalog?: boolean;
      }>
    ) => {
      state.offerModal.data = action.payload.data;
      state.offerModal.isEdit = action.payload.isEdit;
      state.offerModal.visible = action.payload.visible;
      state.offerModal.viewPartySwitch = action.payload.viewPartySwitch;
      state.offerModal.isCatalog = action.payload.isCatalog;

      if (!action.payload.visible) {
        state.offerModal.partyModal = {
          visible: false
        };
      }
    },
    setSelectOffers: (state, action: PayloadAction<OfferModel[]>) => {
      state.selectOffers.data = action.payload;
      state.selectOffers.ids = action.payload?.map(item => item?.id);
    },
    setClearSelectOffers: state => {
      state.selectOffers.data = [];
      state.selectOffers.ids = [];
    },
    setAddItemSelectOffers: (state, action: PayloadAction<OfferModel>) => {
      const newSelectOffers = [...state.selectOffers.data];
      const newSelectOffersIds = [...state.selectOffers.ids];
      const isNotOffer = newSelectOffers?.some(item => item.id === action.payload.id);

      if (!isNotOffer) {
        newSelectOffers.push(action.payload);
        newSelectOffersIds.push(action.payload.id);
      }

      state.selectOffers.data = newSelectOffers;
      state.selectOffers.ids = newSelectOffersIds;
    },
    setOfferPartyModal: (
      state,
      action: PayloadAction<{
        visible: boolean;
        selectData?: OfferModel[];
      }>
    ) => {
      state.partyModal.visible = action.payload.visible;
      state.partyModal.selectData = action.payload.selectData;
    },
    setOfferPartyRightData: (state, action: PayloadAction<OfferPartyRightModel>) => {
      state.partyModal.rightData = action.payload;
    },
    setOfferPartyLeftData: (state, action: PayloadAction<OfferPartyLeftModel[]>) => {
      state.partyModal.leftData = action.payload;
    },
    setOfferPartyRightItemData: (
      state,
      action: PayloadAction<{
        key: keyof OfferPartyRightModel;
        value: number | string | StatusEnums | object | null;
      }>
    ) => {
      state.partyModal.rightData = {
        ...state.partyModal.rightData,
        [action.payload.key]: action.payload.value
      };
    },
    setAddOfferProductLeftData: (state, action: PayloadAction<OfferPartyLeftModel>) => {
      state.partyModal.leftData = [...state.partyModal.leftData, action.payload];
    },
    setDeleteOfferPartProduct: (state, action: PayloadAction<{ unique_id: string }>) => {
      state.partyModal.leftData = state.partyModal.leftData?.filter(
        item => item.unique_id !== action.payload.unique_id
      );
    },
    setOfferPartyLefItemData: (
      state,
      action: PayloadAction<{
        key: keyof OfferPartyLeftModel;
        value: string | number | undefined | { id?: number; name?: string; phone?: string };
        uniqueId: string;
      }>
    ) => {
      const findIndex = state.partyModal.leftData?.findIndex(item => item.unique_id === action.payload.uniqueId);

      const elemData = state.partyModal.leftData[findIndex];

      state.partyModal.leftData[findIndex] = {
        ...elemData,
        [action.payload.key]: action.payload.value
      };
    },
    setOfferPartySelectProducts: (state, action: PayloadAction<ProductSelectModel[]>) => {
      state.partyModal.selectProducts = [...state.partyModal.selectProducts, ...action.payload];
    },
    setClearSelectProducts: state => {
      state.partyModal.selectProducts = [];
    },
    setWarehouseOffers: (state, action: PayloadAction<WarehouseProductOffers[]>) => {
      state.offerModal.warehouseOffers = action.payload;
    },
    setAddOfferItem: (
      state,
      action: PayloadAction<{
        productKey: string;
        data: OfferFormProductModel;
      }>
    ) => {
      const findIndex = state.offerModal.warehouseOffers?.findIndex(
        item => item.productKey === action.payload.productKey
      );

      state.offerModal.warehouseOffers[findIndex].offers = [
        action.payload.data,
        ...(state.offerModal.warehouseOffers[findIndex].offers ?? [])
      ];
    },
    setAddIndexOfferItem: (
      state,
      action: PayloadAction<{
        productKey: string;
        index: number;
        data: OfferFormProductModel;
      }>
    ) => {
      const findIndex = state.offerModal.warehouseOffers?.findIndex(
        item => item.productKey === action.payload.productKey
      );

      const copyOffers = [...(state.offerModal.warehouseOffers[findIndex].offers || [])];

      copyOffers.splice(action.payload.index, 0, action.payload.data);

      state.offerModal.warehouseOffers[findIndex].offers = copyOffers;
    },
    setDeleteOfferItem: (
      state,
      action: PayloadAction<{
        productKey: string;
        uniqueId: string;
      }>
    ) => {
      const initialOffers = state.offerModal.warehouseOffers;
      const findIndex = initialOffers?.findIndex(item => item.productKey === action.payload.productKey);

      state.offerModal.warehouseOffers[findIndex].offers = state.offerModal.warehouseOffers[findIndex].offers?.filter(
        item => item.uniqueId !== action.payload.uniqueId
      );
    },
    setChangeOfferItem: (
      state,
      action: PayloadAction<{
        key: keyof OfferFormProductModel;
        value: never;
        productKey: string;
        uniqueId: string;
      }>
    ) => {
      const findIndex = state.offerModal.warehouseOffers.findIndex(
        item => item.productKey === action.payload.productKey
      );
      const offerFindIndex = state.offerModal.warehouseOffers[findIndex].offers?.findIndex(
        item => item.uniqueId === action.payload.uniqueId
      );

      state.offerModal.warehouseOffers[findIndex].offers[offerFindIndex][action.payload.key] = action.payload.value;
    },
    setOfferModalError: (state, action: PayloadAction<OfferErrorModel[]>) => {
      state.offerModal.offerErrors = action.payload;
    },
    setOfferModalErrorItem: (
      state,
      action: PayloadAction<{
        key: keyof OfferErrorModel;
        action: boolean;
        uniqueId: string;
      }>
    ) => {
      const findErrorIndex = state.offerModal.offerErrors?.findIndex(item => item.uniqueId === action.payload.uniqueId);

      state.offerModal.offerErrors[findErrorIndex] = {
        ...state.offerModal.offerErrors[findErrorIndex],
        [action.payload.key]: action.payload.action
      };
    },
    setOfferPartyModalError: (state, action: PayloadAction<OfferPartyErrorModel[]>) => {
      state.partyModal.partyErrors = action.payload;
    },
    setOfferPartyModalErrorItem: (
      state,
      action: PayloadAction<{
        key: keyof OfferPartyErrorModel;
        action: boolean;
        uniqueId: string;
      }>
    ) => {
      const findErrorIndex = state.partyModal?.partyErrors?.findIndex(
        item => item.uniqueId === action.payload.uniqueId
      );

      state.partyModal.partyErrors[findErrorIndex] = {
        ...state.partyModal.partyErrors[findErrorIndex],
        [action.payload.key]: action.payload.action
      };
    },
    setOfferPartyIsGrouping: (state, action: PayloadAction<boolean>) => {
      state.partyModal.isGrouping = action.payload;
    },
    setCheckData: (state, action: PayloadAction<OfferPartyLeftModel[]>) => {
      state.partyModal.checkData = action.payload;
    },
    setOfferIsParty: (state, action: PayloadAction<boolean>) => {
      state.offerModal.isParty = action.payload;
    },
    setConfirmPartyModalItem: (state, action: PayloadAction<{ key: keyof ConfirmPartyModel; value: never }>) => {
      state.offerModal.partyModal[action.payload.key] = action.payload.value;
    },
    setOfferHistory: (
      state,
      action: PayloadAction<{
        productId: number;
        unitId?: number;
        visible: boolean;
        productKey?: string;
        isCheckedView?: boolean;
      }>
    ) => {
      state.offerHistory = action.payload;
    }
  }
});

export default supplyOfferSlice.reducer;
export const supplyOfferActions = supplyOfferSlice.actions;
