import { ReactNode } from "react";

import { colors } from "modules/common";
import { PartyColumn, PartyStatus, PartyType } from "modules/party/view";

import { BuyingIcon, CheckIcon, ClockCircleIcon, DraftIcon, OrderedIcon, TrashIcon, XCloseIcon } from "components";

type Keys = "supply" | "impost" | "last_supply";

export const processData: {
  [K in Keys]: {
    [k: string | number]: {
      text: string;
      icon?: JSX.Element | null;
      processNumber: number;
      bgColor: string;
      iconContainerBgColor: string;
    };
  };
} = {
  supply: {
    ordered: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Partiya qilingan",
      processNumber: 1,
      bgColor: colors.SUCCESS_100,
      iconContainerBgColor: colors.SUCCESS_500
    },
    recieved: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Partiya qilingan",
      processNumber: 1,
      bgColor: colors.SUCCESS_100,
      iconContainerBgColor: colors.SUCCESS_500
    },
    partially_recieved: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Partiya qilingan",
      processNumber: 1,
      bgColor: colors.SUCCESS_100,
      iconContainerBgColor: colors.SUCCESS_500
    },
    pending: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Partiya qilingan",
      processNumber: 1,
      bgColor: colors.SUCCESS_100,
      iconContainerBgColor: colors.SUCCESS_500
    },
    rejected: {
      icon: <XCloseIcon color={colors.WHITE} width={16} />,
      text: "Bekor qilingan",
      processNumber: 1,
      bgColor: colors.ERROR_100,
      iconContainerBgColor: colors.ERROR_500
    },
    open: {
      icon: null,
      text: "Kutilmoqda",
      processNumber: 1,
      bgColor: colors.GRAY_100,
      iconContainerBgColor: colors.GRAY_600
    },
    buying: {
      icon: null,
      text: "Sotib olinmoqda",
      processNumber: 1,
      bgColor: colors.WARNING_100,
      iconContainerBgColor: colors.WARNING_500
    }
  },
  impost: {
    pending: {
      icon: null,
      text: "Kutilmoqda",
      processNumber: 2,
      bgColor: colors.GRAY_100,
      iconContainerBgColor: colors.GRAY_600
    },
    recieved: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Tasdiqlangan",
      processNumber: 2,
      bgColor: colors.SUCCESS_100,
      iconContainerBgColor: colors.SUCCESS_500
    },
    ordered: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Tasdiqlangan",
      processNumber: 2,
      bgColor: colors.SUCCESS_100,
      iconContainerBgColor: colors.SUCCESS_500
    },
    partially_recieved: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Tasdiqlangan",
      processNumber: 2,
      bgColor: colors.SUCCESS_100,
      iconContainerBgColor: colors.SUCCESS_500
    },
    rejected: {
      icon: <XCloseIcon color={colors.WHITE} width={16} />,
      text: "Bekor qilingan",
      processNumber: 2,
      bgColor: colors.ERROR_100,
      iconContainerBgColor: colors.ERROR_500
    },
    open: {
      icon: null,
      text: "Kutilmoqda",
      processNumber: 2,
      bgColor: colors.GRAY_100,
      iconContainerBgColor: colors.GRAY_600
    },
    buying: {
      icon: null,
      text: "Sotib olinmoqda",
      processNumber: 2,
      bgColor: colors.WARNING_100,
      iconContainerBgColor: colors.WARNING_500
    }
  },
  last_supply: {
    ordered: {
      icon: null,
      text: "Yopish",
      processNumber: 5,
      bgColor: colors.GRAY_100,
      iconContainerBgColor: colors.GRAY_600
    },
    recieved: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Yopilgan",
      processNumber: 5,
      bgColor: colors.SUCCESS_100,
      iconContainerBgColor: colors.SUCCESS_500
    },
    partially_recieved: {
      icon: null,
      text: "Yopish",
      processNumber: 5,
      bgColor: colors.GRAY_100,
      iconContainerBgColor: colors.GRAY_600
    },
    pending: {
      icon: null,
      text: "Yopish",
      processNumber: 5,
      bgColor: colors.GRAY_100,
      iconContainerBgColor: colors.GRAY_600
    },
    rejected: {
      icon: null,
      text: "Yopish",
      processNumber: 5,
      bgColor: colors.GRAY_100,
      iconContainerBgColor: colors.GRAY_600
    },
    open: {
      icon: null,
      text: "Kutilmoqda",
      processNumber: 5,
      bgColor: colors.GRAY_100,
      iconContainerBgColor: colors.GRAY_600
    },
    buying: {
      icon: null,
      text: "Yopish",
      processNumber: 5,
      bgColor: colors.GRAY_100,
      iconContainerBgColor: colors.GRAY_600
    }
  }
};

export const statusData: {
  [K in PartyStatus]: {
    text: string;
    icon: ReactNode;
    className: string;
  };
} = {
  open: {
    text: "Qoralama",
    icon: <DraftIcon />,
    className: "bg-gray-50 text-gray-500"
  },
  pending: {
    text: "Kutilmoqda",
    icon: <ClockCircleIcon width={16} />,
    className: "bg-warning-50 text-warning-500"
  },
  ordered: {
    text: "Partiya qilingan",
    icon: <OrderedIcon />,
    className: "bg-violet-50 text-violet-500"
  },
  recieved: {
    text: "Yopilgan",
    icon: <CheckIcon color="#12B76A" />,
    className: "bg-success-50 text-success-500"
  },
  rejected: {
    text: "Bekor qilingan",
    icon: <XCloseIcon color="#F04438" />,
    className: "bg-error-50 text-error-500"
  },
  partially_recieved: {
    text: "Jarayonda",
    icon: <ClockCircleIcon color="#1E90FF" width={16} />,
    className: "bg-primary-50 text-primary-500"
  },
  deleted: {
    text: "O'chirilgan",
    icon: <TrashIcon />,
    className: "bg-gray-50 text-gray-500"
  },
  buying: {
    text: "Sotib olinmoqda",
    icon: <BuyingIcon />,
    className: "bg-warning-50 text-warning-500"
  }
};

export const defaultColumns: {
  [K in PartyType]: PartyColumn[];
} = {
  supply: [
    {
      key: 1,
      title: "Resurs turi va nomi",
      checked: true,
      order: 0,
      width: 330,
      minWidth: 330
    },
    {
      key: 2,
      title: "Birligi",
      checked: true,
      width: 80,
      minWidth: 80,
      order: 1
    },
    {
      key: 3,
      title: "Soni",
      checked: true,
      width: 100,
      minWidth: 100,
      order: 2
    },
    {
      key: 4,
      title: "Qabul soni",
      checked: false,
      width: 100,
      minWidth: 100,
      order: 3
    },
    {
      key: 5,
      title: "Birlik narxi",
      checked: true,
      width: 100,
      minWidth: 100,
      order: 4
    },
    {
      key: 6,
      title: "Valyuta",
      checked: false,
      width: 85,
      minWidth: 85,
      order: 5
    },
    {
      key: 7,
      title: "Ta'minotchi",
      checked: true,
      width: 130,
      minWidth: 130,
      order: 6
    },
    {
      key: 8,
      title: "Omborxona",
      checked: true,
      width: 200,
      minWidth: 200,
      order: 7
    },
    {
      key: 9,
      title: "Loyiha",
      checked: true,
      width: 150,
      minWidth: 150,
      order: 8
    },
    {
      key: 10,
      title: "NDS",
      checked: false,
      width: 85,
      minWidth: 85,
      order: 9
    },
    {
      key: 11,
      title: "Summa",
      checked: false,
      width: 115,
      minWidth: 115,
      order: 10
    },
    {
      key: 12,
      title: "Jami",
      checked: false,
      width: 115,
      minWidth: 115,
      order: 11
    },
    {
      key: 13,
      title: "Takliflar",
      checked: false,
      width: 130,
      minWidth: 130,
      order: 12
    }
  ],
  impost: [
    {
      key: 1,
      title: "Resurs turi va nomi",
      checked: true,
      width: 330,
      minWidth: 330,
      order: 0
    },
    {
      key: 2,
      title: "Birligi",
      checked: true,
      width: 100,
      minWidth: 100,
      order: 1
    },
    {
      key: 3,
      title: "Soni",
      checked: true,
      width: 100,
      minWidth: 100,
      order: 2
    },
    {
      key: 4,
      title: "Birlik narxi",
      checked: true,
      width: 210,
      minWidth: 210,
      order: 3
    },
    {
      key: 5,
      title: "Valyuta",
      checked: true,
      width: 100,
      minWidth: 100,
      order: 4
    },
    {
      key: 6,
      title: "Ta'minotchi",
      checked: true,
      width: 160,
      minWidth: 160,
      order: 5
    },
    {
      key: 7,
      title: "Omborxona",
      checked: true,
      width: 250,
      minWidth: 250,
      order: 6
    },
    {
      key: 8,
      title: "Loyiha",
      checked: true,
      width: 120,
      minWidth: 120,
      order: 7
    },
    {
      key: 9,
      title: "Mavjudligi",
      checked: true,
      width: 120,
      minWidth: 120,
      order: 8
    },
    {
      key: 10,
      title: "NDS",
      checked: false,
      width: 100,
      minWidth: 100,
      order: 9
    },
    {
      key: 11,
      title: "Summa",
      checked: false,
      width: 150,
      minWidth: 150,
      order: 10
    },
    {
      key: 12,
      title: "Jami",
      checked: false,
      width: 150,
      minWidth: 150,
      order: 11
    },
    {
      key: 13,
      title: "Takliflar",
      checked: false,
      width: 160,
      minWidth: 160,
      order: 12
    }
  ],
  warehouse: [
    {
      key: 1,
      title: "Resurs turi va nomi",
      checked: true,
      width: 330,
      minWidth: 330,
      order: 0
    },
    {
      key: 2,
      title: "Birligi",
      checked: true,
      width: 100,
      minWidth: 100,
      order: 1
    },
    {
      key: 3,
      title: "Soni",
      checked: true,
      width: 110,
      minWidth: 110,
      order: 2
    },
    {
      key: 4,
      title: "Jarayon",
      checked: true,
      width: 250,
      minWidth: 250,
      order: 3
    },
    {
      key: 5,
      title: "Qabul qilish",
      checked: true,
      disabled: true,
      width: 180,
      minWidth: 180,
      order: 4
    },
    {
      key: 6,
      title: "Omborxona",
      checked: true,
      width: 180,
      minWidth: 180,
      order: 5
    },
    {
      key: 7,
      title: "Loyiha",
      checked: true,
      width: 120,
      minWidth: 120,
      order: 6
    }
  ],
  payment: []
};
