import { useQuery } from "@tanstack/react-query";

import $api from "../../../../features/app/utils/helpers/axiosInstance";
import { partyQueryKeys } from "../query-keys";
import { Vat } from "../schema";

type Query = {
  vats: Vat[];
};

export function useVat() {
  const initialData = {
    vats: []
  };

  const { data = initialData, ...arg } = useQuery<Query>([partyQueryKeys.VAT], async () => {
    const { data } = await $api.get("vat/select");

    return { vats: data?.data };
  });

  return { ...data, ...arg };
}
