import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

import { getPopupContainer, PartyForm, useProjects } from "modules/party/create";

import { TooltipShortName, TreeSelect } from "components";

type Props = {
  index: number;
  isUpdate?: boolean;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, index, isUpdate }) => {
  const { warehouse_products } = useWatch({
    control: form.control
  });
  const { isLoading, projects } = useProjects();

  const onClear = () => {
    form.setValue(`warehouse_products.${index}.project_id`, null);
  };

  return (
    <TreeSelect
      allowClear={true}
      suffixIcon={null}
      onClear={onClear}
      loading={isLoading}
      placeholder="Loyiha"
      control={form.control}
      popupMatchSelectWidth={false}
      value={warehouse_products?.[index]?.project_id}
      name={`warehouse_products.${index}.project_id`}
      getPopupContainer={getPopupContainer(isUpdate ? "party-left-content-update" : "party-left-content")}
      treeData={projects?.map(project => ({
        key: project?.id,
        value: project?.id,
        label: (
          <TooltipShortName
            length={18}
            arrow={false}
            title={project?.name}
            getPopupContainer={getPopupContainer("party-left-content")}
          />
        ),
        props: {
          name: project?.name
        },
        children: project?.projects?.map(child => ({
          key: child?.id,
          value: child?.id,
          label: (
            <TooltipShortName
              length={18}
              arrow={false}
              title={child?.name}
              getPopupContainer={getPopupContainer("party-left-content")}
            />
          ),
          props: {
            name: child?.name
          }
        }))
      }))}
    />
  );
};

export default Index;
