import React from "react";
import { UseFormReturn } from "react-hook-form";
import { localeFormatter } from "features/app/utils/helpers/localeFormatter";

import { parseLocaledString } from "modules/common";
import { PartyForm } from "modules/party/create";

import { Input } from "components";

type Props = {
  index: number;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, index }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { quantity, total, vat_value } = form.getValues(`warehouse_products.${index}`);
    const parseValue = parseLocaledString(e.target.value);
    const parseQuantity = parseLocaledString(quantity || "");

    form.setValue(`warehouse_products.${index}.total`, localeFormatter(e.target.value));
    form.setValue(
      `warehouse_products.${index}.vat_amount`,
      parseLocaledString(total || "0") * ((vat_value || 0) / 100)
    );
    form.setValue(`warehouse_products.${index}.amount`, localeFormatter(String(parseValue / parseQuantity)));
  };

  return (
    <Input
      withoutMessage
      onChange={onChange}
      autoComplete="off"
      placeholder="Summa"
      control={form.control}
      errorClassName="party-create-error"
      wrapperClassName="party-create-input"
      name={`warehouse_products.${index}.total`}
    />
  );
};

export default Index;
