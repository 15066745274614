import React, { useMemo } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { Popover } from "antd";

import { useAppSelector } from "hooks/redux";

import { useAuth } from "modules/auth/hooks";
import { isEmptyArr, RU } from "modules/common";
import {
  parseGroupData,
  PartyForm,
  PartyFormProduct,
  PartyPersonGroup,
  totalAmount,
  totalUnits,
  vatAmount
} from "modules/party/create";

import Line from "pages/party/view/content/right/line";

import { ConditionalRender } from "components";

type Props = {
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form }) => {
  const { baseCurrency } = useAuth();
  const {
    isVat: isVatCreate,
    isDiscount: isDiscountCreate,
    isGroup: isGroupCreate
  } = useAppSelector(({ partyReducer }) => partyReducer.create);
  const {
    isVat: isVatUpdate,
    isDiscount: isDiscountUpdate,
    isGroup: isGroupUpdate
  } = useAppSelector(({ partyReducer }) => partyReducer.update);
  const { warehouse_products, company_person_group, parse_discount, discount_currency_id, discount_currency_symbol } =
    useWatch({
      control: form.control
    });

  const products = useMemo(() => {
    if (isGroupCreate || isGroupUpdate) {
      return parseGroupData(company_person_group as PartyPersonGroup) as PartyFormProduct[];
    }

    return warehouse_products as PartyFormProduct[];
  }, [isGroupCreate, isGroupUpdate, company_person_group, warehouse_products]);

  const units = totalUnits(products);
  const totalNotVat = totalAmount(products);
  const total = totalAmount(products, true);
  const vats = vatAmount(products);

  const generateTotalAmount = ({ amount, currencyId }: (typeof total)[number]) => {
    const isDiscount = isDiscountCreate || isDiscountUpdate;
    const thisCurrency = (discount_currency_id || baseCurrency?.id) === currencyId;

    if ((parse_discount || thisCurrency) && isDiscount) {
      return (amount - (parse_discount || 0))?.toLocaleString(RU);
    }

    return amount?.toLocaleString(RU);
  };

  const emptyAmount = <div className="flex items-center gap-2 text-base font-medium text-gray-700">--</div>;

  return (
    <div className="box-border flex flex-col gap-3 overflow-x-hidden rounded-xl bg-gray-100 p-4">
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium text-gray-600">Resurs:</div>
        <Popover
          arrow={false}
          placement="left"
          trigger={["hover"]}
          content={units?.map(([symbol, quantity], index) => `${quantity}  ${symbol} ${units[index + 1] ? "," : ""} `)}
        >
          <div className="flex cursor-pointer items-center gap-1 text-sm font-medium">
            <span className="text-gray-700">{products?.length || 0}</span>
            <span className="text-gray-400">ta</span>
          </div>
        </Popover>
      </div>
      <ConditionalRender if={isVatCreate || isVatUpdate || isDiscountUpdate || isDiscountCreate}>
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-700">Summa:</div>
          <ConditionalRender else={emptyAmount} if={!isEmptyArr(total)}>
            <div className="flex flex-col items-end justify-end gap-1.5 text-sm font-medium">
              {totalNotVat?.map(({ currencySymbol, currencyId, amount }) => (
                <div key={currencyId} className="flex items-center gap-2 text-sm font-medium">
                  <span className="text-gray-700">{amount?.toLocaleString(RU) || 0}</span>
                  <span className="text-gray-400">{currencySymbol}</span>
                </div>
              ))}
            </div>
          </ConditionalRender>
        </div>
      </ConditionalRender>
      <ConditionalRender if={isDiscountCreate || isDiscountUpdate}>
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-600">Chegirma:</div>
          <div className="flex cursor-pointer items-center gap-1 text-sm font-medium">
            <span className="text-gray-700">{(parse_discount || 0)?.toLocaleString(RU)}</span>
            <span className="text-gray-400">{discount_currency_symbol || baseCurrency?.symbol}</span>
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender if={isVatCreate || isVatUpdate}>
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-600">NDS:</div>
          <ConditionalRender if={!isEmptyArr(vats)} else={emptyAmount}>
            <div className="flex flex-col items-end justify-end gap-1.5">
              {vats?.map(vat => (
                <div key={vat?.currencyId} className="flex items-center gap-1 text-sm font-medium">
                  <span className="text-gray-700">{vat?.amount?.toLocaleString(RU) || 0}</span>
                  <span className="text-gray-400">{vat?.currencySymbol}</span>
                </div>
              ))}
            </div>
          </ConditionalRender>
        </div>
      </ConditionalRender>
      <div className="relative flex items-center justify-between">
        <div className="absolute left-[-24px] h-4 w-4 rounded-full bg-white" />
        <Line />
        <div className="absolute right-[-24px] h-4 w-4 rounded-full bg-white" />
      </div>
      <div className="flex justify-between">
        <div className="text-base font-semibold text-gray-700">Jami:</div>
        <ConditionalRender else={emptyAmount} if={!isEmptyArr(total)}>
          <div className="flex flex-col items-end justify-end gap-1.5 text-sm font-medium">
            {total?.map(({ currencySymbol, currencyId, amount }) => (
              <div key={currencyId} className="flex items-center gap-2 text-lg font-semibold">
                <span className="text-gray-700">{generateTotalAmount({ amount, currencyId, currencySymbol })}</span>
                <span className="text-gray-700">{currencySymbol}</span>
              </div>
            ))}
          </div>
        </ConditionalRender>
      </div>
    </div>
  );
};

export default Index;
