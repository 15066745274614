import { useDispatch } from "react-redux";

import { partyReducerActions } from "store/reducers/partyReducer";

import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const OrderPending = ({ record, onNavigatePage }: Props) => {
  const dispatch = useDispatch();
  const { setPartyView } = partyReducerActions;

  const onOpenPartyView = () => {
    dispatch(
      setPartyView({
        visible: true,
        type: "warehouse",
        party_id: record?.order?.id,
        status: "rejected"
      })
    );
  };

  return (
    <div onClick={onOpenPartyView} className={styles.description}>
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        Ta’minot bo’limi <span>P - {record?.order?.id}</span> raqamli mahsulotlar partiyasini yubordi. Partiya kelish
        vaqti {record?.order?.delivery_date}
      </p>
    </div>
  );
};

export default OrderPending;
