import React from "react";
import { useDispatch } from "react-redux";
import { UseFormReturn } from "react-hook-form";
import { TableProps } from "antd";

import { useAppSelector } from "hooks/redux";

import { warehouseOrderActions } from "store/reducers/warehouseOrderReducer";

import { cx } from "modules/common";
import { PartyTable, PartyView, PartyViewForm, WarehouseColumns } from "modules/party/view";

import styles from "pages/party/view/content/items/impost/table/index.module.scss";

type Props = {
  isLoading: boolean;
  groupTable?: boolean;
  form: UseFormReturn<PartyViewForm>;
  products: PartyView["warehouse_products"] | undefined;
};

const WarehouseTable: React.FC<Props> = ({ products, isLoading, groupTable, form }) => {
  const dispatch = useDispatch();
  const { setVisibleAdditional } = warehouseOrderActions;
  const { isAccept } = useAppSelector(({ partyReducer }) => partyReducer.view);

  const onRow: TableProps["onRow"] = data => ({
    onClick: () => {
      if (!isAccept) {
        dispatch(setVisibleAdditional({ visible: true, data: data as never, zIndex: 111119 }));
      }
    }
  });

  return (
    <div className="flex flex-1 flex-col gap-3">
      <PartyTable
        onRow={onRow}
        type="warehouse"
        data={products}
        loading={isLoading}
        rowClassName={cx({
          "cursor-pointer": !isAccept
        })}
        className={cx({
          [styles.group_table]: groupTable
        })}
        columns={WarehouseColumns({ form })}
      />
    </div>
  );
};

export default WarehouseTable;
