import { parseLocaledString } from "modules/common";
import { PartyFormProduct, Total } from "modules/party/create";

export function totalAmount(products: PartyFormProduct[], isVat?: boolean) {
  const total: Total[] = [];

  products?.forEach(item => {
    if (item?.currency_id) {
      const findCurrencyIndex = total?.findIndex(i => i?.currencyId === item?.currency_id);

      if (findCurrencyIndex < 0) {
        total.push({
          amount: parseLocaledString(item?.total || "0") + (isVat ? item?.vat_amount || 0 : 0),
          currencyId: item?.currency_id || 0,
          currencySymbol: item?.currency_symbol || ""
        });
      } else {
        total[findCurrencyIndex] = {
          ...total[findCurrencyIndex],
          amount:
            (total[findCurrencyIndex]?.amount || 0) +
            parseLocaledString(item?.total || "0") +
            (isVat ? item?.vat_amount || 0 : 0)
        };
      }
    }
  });

  return total;
}
