import React from "react";
import { useNavigate } from "react-router-dom";
import { rootPaths } from "routes/root/rootPaths";
import { settingsPaths } from "routes/settings/settingsPaths";

import { Integration } from "../schema";

import Default from "./default";

const Message: React.FC<Integration> = ({ connect }) => {
  const navigate = useNavigate();

  const onConnect = () => {
    navigate(rootPaths.SETTINGS + settingsPaths.MESSAGE);
  };

  const onDisConnect = () => {
    navigate(rootPaths.SETTINGS + settingsPaths.MESSAGE);
  };

  return <Default connect={connect} onConnect={onConnect} onDisConnect={onDisConnect} isLoading={false} />;
};

export default Message;
