import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { PartyEstimateProduct, partyEstimateQueryKeys } from "modules/party/estimate";

type Query = {
  products: PartyEstimateProduct[];
};

export function usePartyProducts({ taskId }: { taskId?: number | string }) {
  const initial: Query = {
    products: []
  };

  const { data = initial, ...arg } = useQuery<Query>(
    [partyEstimateQueryKeys.PRODUCTS, taskId],
    async () => {
      const { data } = await $api.get(`supply/task-product-view?task_id=${taskId}`);

      return { products: data?.data };
    },
    {
      enabled: !!taskId
    }
  );

  return { ...data, ...arg };
}
