import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Input, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { isEmptyArr, sliceText } from "modules/common";
import { TargetType } from "modules/dashboard";
import { setAddWarehouseProduct, useWarehouseProducts, WarehouseProduct } from "modules/warehouse/create-transfer";

import { EmptyContent, LoadingIndicator, SearchLgIcon } from "components";

import styles from "./index.module.scss";

const Search: React.FC<{ index: number }> = ({ index }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [search, setSearch] = useState<string>();
  const { sender_warehouse_id } = useAppSelector(({ warehouseCreateTransfer }) => warehouseCreateTransfer);
  const { pages, fetchNextPage, isLoading } = useWarehouseProducts({ search, warehouse_id: sender_warehouse_id });

  const products = useMemo(() => {
    let result: WarehouseProduct[] = [];

    pages?.forEach(item => {
      result = [...result, ...(item?.data || [])];
    });

    return result;
  }, [pages, search]);

  const onProduct =
    ({ product, unit, total_quantity }: Pick<WarehouseProduct, "total_quantity" | "unit" | "product">) =>
    () => {
      dispatch(
        setAddWarehouseProduct({
          color: product?.resource?.color,
          index,
          name: product?.name?.[i18n.language],
          product_id: product?.id,
          max_quantity: total_quantity,
          quantity: undefined,
          resource: product?.resource?.symbol?.[i18n.language],
          unit_id: unit?.id,
          unit: unit?.symbol?.[i18n.language]
        })
      );
    };

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;

    if (Math.abs(target.scrollTop + target.offsetHeight - target.scrollHeight) < 2) {
      fetchNextPage().then();
    }
  };

  return (
    <Dropdown
      arrow={false}
      trigger={["click"]}
      rootClassName={styles.sections}
      dropdownRender={originNode => (
        <Spin indicator={LoadingIndicator} className="rounded-lg bg-white shadow" spinning={isLoading}>
          {isEmptyArr(products) ? (
            <div className="box-border h-max w-[25rem] rounded-lg border border-solid border-gray-300 bg-white p-3 shadow">
              <EmptyContent />
            </div>
          ) : (
            <div
              onScrollCapture={onPopupScroll}
              className="max-h-96 w-[25rem] overflow-y-auto overflow-x-hidden rounded-lg border border-solid border-gray-300 bg-white shadow"
            >
              {originNode}
            </div>
          )}
        </Spin>
      )}
      menu={{
        items: products?.map(({ product, unit, total_quantity }) => ({
          key: `${product?.id}-${unit?.id}`,
          value: `${product?.id}-${unit?.id}`,
          label: (
            <div className="flex w-full items-center justify-between gap-2 overflow-hidden">
              <div className="flex items-center gap-2">
                <div
                  className="flex items-center justify-center rounded border border-solid px-2 py-0.5 text-xs font-medium"
                  style={{
                    color: product?.resource?.color,
                    borderColor: product?.resource?.color
                  }}
                >
                  {product?.resource?.symbol?.[i18n.language]}
                </div>
                <div className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray-700">
                  {sliceText(product?.name?.[i18n.language], 22)}
                </div>
              </div>
              <div className="text-sm font-medium text-gray-400">{unit?.symbol?.[i18n.language]}</div>
            </div>
          ),
          onClick: onProduct({ product, unit, total_quantity })
        })) as never
      }}
    >
      <div className="h-10">
        <Input
          value={search}
          prefix={<SearchLgIcon />}
          rootClassName={styles.search}
          onChange={e => setSearch(e.target.value)}
          placeholder={t("warehouseTransfer.Resurs qidiruvi")}
        />
      </div>
    </Dropdown>
  );
};

export default Search;
