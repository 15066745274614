import React from "react";

const PencilIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_23005_82833)">
      <path
        d="M15 1.66669L18.3333 5.00002M1.66663 18.3334L2.73029 14.4332C2.79969 14.1788 2.83439 14.0516 2.88765 13.9329C2.93495 13.8276 2.99306 13.7274 3.06106 13.6341C3.13764 13.529 3.23088 13.4358 3.41738 13.2493L12.0286 4.63809C12.1936 4.47308 12.2761 4.39058 12.3712 4.35967C12.4549 4.33248 12.545 4.33248 12.6287 4.35967C12.7239 4.39058 12.8064 4.47308 12.9714 4.63809L15.3619 7.02862C15.5269 7.19362 15.6094 7.27613 15.6403 7.37126C15.6675 7.45495 15.6675 7.54509 15.6403 7.62878C15.6094 7.72391 15.5269 7.80642 15.3619 7.97143L6.75071 16.5826C6.56422 16.7691 6.47097 16.8623 6.36586 16.9389C6.27254 17.0069 6.1724 17.065 6.06706 17.1123C5.94841 17.1656 5.82119 17.2003 5.56674 17.2697L1.66663 18.3334Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_23005_82833">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PencilIcon;
