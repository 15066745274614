import React, { useState } from "react";
import { Dropdown } from "antd";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "features/app/assets/icons/DotsVerticalIcon";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import EditIcon from "features/app/assets/icons/EditIcon";
import SettingsIcon from "features/app/assets/icons/SettingsIcon";
import ModalConfirm from "features/app/components/modal-confirm/ModalConfirm";
import { useTranslation } from "react-i18next";

import { routeSubmodules, useWidget, Widget } from "modules/dashboard";
import { useDeleteWidget } from "modules/dashboard/mutations/use-delete-widget";
import { useRoutePermissions } from "hooks/useRoutePermissions";

type Props = {
  data?: Widget;
  isInTheDrawer: boolean;
};

const DrawerActions: React.FC<Props> = ({ data, isInTheDrawer }) => {
  const {
    widget,
    methods: { setSettings, setWidgetModal }
  } = useWidget();
  const { actions } = useRoutePermissions("Dashboard", routeSubmodules);
  const dashboardActions = actions("Amallar");

  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const mutation = useDeleteWidget();

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onOpenEditWidgetModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    stopPropagation(e);
    setWidgetModal({
      visibleWidgetModal: true,
      isUpdateWidget: true,
      widget: isInTheDrawer ? (widget as never) : data!
    });
    setOpen(false);
  };

  const onOpenWidgetSettingsModal = (e: React.MouseEvent<HTMLDivElement>) => {
    stopPropagation(e);
    setSettings({
      id: isInTheDrawer ? widget!.id! : data!.id!,
      key: isInTheDrawer ? widget!.key! : data!.key!,
      settingsVisible: true,
      settingsProps: isInTheDrawer ? widget!.filter! : data!.filter!
    });
    setOpen(false);
  };

  const onDeleteWidget = () => mutation.mutateAsync({ id: isInTheDrawer ? widget!.id! : data!.id! });

  const items = [
    {
      key: "1",
      label: (
        <div className="flex items-center gap-3" onClick={onOpenEditWidgetModal}>
          <EditIcon /> {t("dashboard.Tahrirlash")}
        </div>
      ),
      isView: true,
      permissionKey: "update"
    },
    {
      key: "2",
      label: (
        <div className="flex items-center gap-3" onClick={onOpenWidgetSettingsModal}>
          <SettingsIcon /> {t("dashboard.Sozlash")}
        </div>
      ),
      isView: true,
      permissionKey: "settings"
    },
    {
      key: "3",
      label: (
        <div className="flex items-center gap-3">
          <DownloadIcon /> {t("dashboard.Yuklash")}
        </div>
      ),
      isView: true,
      permissionKey: "download"
    },
    {
      key: "4",
      label: (
        <ModalConfirm
          cancelText={t("dashboard.Bekor qilish")}
          okText={t("dashboard.O'chirish")}
          title={t("dashboard.Ushbu vidjetni o'chirishni xohlaysizmi")}
          onOk={onDeleteWidget}
          description=""
        >
          <div
            className="flex items-center gap-3"
            onClick={e => {
              setOpen(false);
            }}
          >
            <DeleteIcon /> {t("dashboard.O'chirish")}
          </div>
        </ModalConfirm>
      ),
      isView: true,
      permissionKey: "delete"
    }
  ];

  const onOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    stopPropagation(e);
    setOpen(true);
  };

  return (
    <Dropdown
      onOpenChange={open => setOpen(open)}
      menu={{
        items: items?.filter(el => {
          if (el?.permissionKey)
            return el.isView && dashboardActions[el?.permissionKey as keyof typeof dashboardActions];
          return el.isView;
        })
      }}
      trigger={["click"]}
      open={open}
    >
      <div
        className="flex cursor-pointer items-center justify-center rounded-lg border border-solid border-gray-200 bg-gray-50 p-2"
        onClick={onOpen}
      >
        <DotsVerticalIcon />
      </div>
    </Dropdown>
  );
};

export default DrawerActions;
