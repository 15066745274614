import React from "react";
import { Drawer } from "antd";
import { t } from "i18next";

import { isEmptyObj } from "modules/common";
import { ProcessTableData } from "modules/works";
import { useProcessView } from "modules/works/queries/use-process-view";

import Bottom from "./bottom";
import Top from "./top";

import styles from "./index.module.scss";

type Props = {
  record: ProcessTableData;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProcessHistoryView: React.FC<Props> = ({ visible, setVisible, record }) => {
  const [page, setPage] = React.useState(1);
  const { processView, isLoading } = useProcessView({
    enabled: visible,
    unit_id: record?.unit?.id,
    company_person_id:
      record?.product?.resource?.type === "person" && isEmptyObj(record.user!)
        ? Number(record?.company_person?.id)
        : undefined,
    user_id:
      record?.product?.resource?.type === "person" && isEmptyObj(record.company_person!)
        ? Number(record?.user?.id)
        : undefined,
    inventory_id: record?.product?.resource?.type === "mechanism" ? Number(record?.inventory?.id) : undefined,
    page,
    product_id: record?.product?.id
  });

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <Drawer
      open={visible}
      onClose={onCancel}
      title={t("works.Tarix")}
      className={styles.process_history_view}
      rootClassName={styles.root_process_view}
    >
      <div className="flex flex-col gap-4">
        <Top record={record} />
        <Bottom data={processView} isLoading={isLoading} setPage={setPage} />
      </div>
    </Drawer>
  );
};

export default ProcessHistoryView;
