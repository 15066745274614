import React, { ReactNode } from "react";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import { cx, isEmptyValue } from "modules/common";
import { CompanyWidget, TableDataTypeWidgetKey, useWidget, WidgetNoData, widgetStaticData } from "modules/dashboard";

import { InfoHexagonIcon, TrendDownIcon } from "../icons";
import * as Icons from "../icons";

import DrawerActions from "./drawer/actions/drawer-actions";
import StreamGraph from "./items/stream-graph/stream-graph";
import { Bar, Calendar, Card, Column, Count, Gantt, Histogram, Line, Radar, Table, TableCard } from "./items";

import styles from "./widgets.module.scss";

type Props = {
  item: CompanyWidget;
};

function WidgetWrapper({ item }: Props) {
  const { i18n } = useTranslation();
  const { methods } = useWidget();
  const { setWidgetDrawer } = methods;
  const { isNotDrawer, isNotEmpty } = widgetStaticData[item.key];

  const widgets: Record<string, ReactNode> = {
    card: <Card data={item.data} widgetKey={item.key} />,
    radar: <Radar data={item.data} />,
    calendar: <Calendar item={item} />,
    line: <Line item={item} />,
    count: <Count data={item.data} widgetKey={item.key} />,
    table_card: <TableCard data={item.data} widgetKey={item.key} />,
    column: <Column data={item.data} widgetKey={item.key} />,
    bar: <Bar data={item.data} widgetKey={item.key} widget={item} id={item?.id} name={item?.name} />,
    histogram: <Histogram item={item} />,
    table: <Table<keyof TableDataTypeWidgetKey> data={item?.data} widget={item} />,
    gantt: <Gantt initialData={item?.data} filter={item?.filter} id={item?.id} />,
    stream_graph: <StreamGraph widget={item} />
  };

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onOpenDrawer = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    stopPropagation(e);

    setWidgetDrawer({
      id: item.id,
      visible: true,
      key: item.key,
      props: item.data,
      title: item.name[i18n.language],
      widget: item
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2">
          <div className={cx("cursor-move", "drag_icon_container", styles.drag_icon)}>
            <Icons.DragIcon />
          </div>
          <div className="text-ellipsis text-lg font-medium text-gray-700">{item?.name[i18n.language]}</div>
          <Tooltip placement="top" title={item?.title[i18n.language] || item?.name[i18n.language]}>
            <div className={styles.wrapper__tooltip}>
              <InfoHexagonIcon />
            </div>
          </Tooltip>
        </div>
        <div className="flex items-center gap-3">
          {!isNotDrawer && (
            <>
              <div
                onClick={onOpenDrawer}
                onMouseDown={stopPropagation}
                className={styles.wrapper__action}
                onTouchStart={stopPropagation as never}
              >
                <TrendDownIcon />
              </div>
              <div className={styles.wrapper__line} />
            </>
          )}

          <div className={styles.wrapper__dropdown}>
            <DrawerActions data={item} isInTheDrawer={false} />
          </div>
        </div>
      </div>
      <div className="h-full w-full flex-1">
        {isNotEmpty ? (
          widgets[item.chart_type]
        ) : (
          <>
            {!isEmptyValue(item?.data) && widgets[item.chart_type]}
            {isEmptyValue(item?.data) && <WidgetNoData />}
          </>
        )}
      </div>
    </div>
  );
}

export default WidgetWrapper;
