import React from "react";

const LinkBrokenIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_24380_70542)">
      <path
        d="M6.75 3V1.5M11.25 15V16.5M3 6.75H1.5M15 11.25H16.5M3.68566 3.68566L2.625 2.625M14.3143 14.3143L15.375 15.375M9 13.2426L7.40901 14.8336C6.23744 16.0052 4.33794 16.0052 3.16637 14.8336C1.9948 13.6621 1.9948 11.7626 3.16637 10.591L4.75736 9M13.2426 9L14.8336 7.40901C16.0052 6.23744 16.0052 4.33794 14.8336 3.16637C13.6621 1.9948 11.7626 1.9948 10.591 3.16637L9 4.75736"
        stroke="#F04438"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_24380_70542">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LinkBrokenIcon;
