import { UseFormReturn } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { PartyEstimateForm, partyEstimateQueryKeys, Project } from "modules/party/estimate";

type Query = {
  projects: Project[];
};

export function usePartyProjects({ enabled, form }: { enabled: boolean; form: UseFormReturn<PartyEstimateForm> }) {
  const initial: Query = {
    projects: []
  };

  const { data = initial, ...arg } = useQuery<Query>(
    [partyEstimateQueryKeys.PROJECTS],
    async () => {
      const { data } = await $api.get("supply/project-view");

      return { projects: data?.data };
    },
    {
      enabled,
      onSuccess: ({ projects }) => {
        form.setValue("project_id", projects?.[0]?.id);
        form.setValue("currency_id", projects?.[0]?.currency?.id || 0);
        form.setValue("currency_symbol", projects?.[0]?.currency?.symbol || "");
      }
    }
  );

  return { ...data, ...arg };
}
