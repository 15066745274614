import React from "react";
import { useTranslation } from "react-i18next";

import { User } from "modules/common";

type Props = {
  agent?: User;
  deliveryDate?: string;
  paymentDate?: string;
};

const Top: React.FC<Props> = ({ agent, paymentDate, deliveryDate }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1.5">
        <label className="text-sm font-medium text-gray-600">{t("partyView.Vositachi")}</label>
        <div className="flex rounded-lg border border-solid border-gray-300 bg-gray-100 px-3.5 py-2.5 text-base font-normal text-gray-900">
          {agent?.full_name || t("partyView.Tanlanmagan")}
        </div>
      </div>
      <div className="flex flex-col gap-1.5">
        <label className="text-sm font-medium text-gray-600">{t("partyView.Yetkazish sanasi")}</label>
        <div className="flex rounded-lg border border-solid border-gray-300 bg-gray-100 px-3.5 py-2.5 text-base font-normal text-gray-900">
          {deliveryDate || t("partyView.Tanlanmagan")}
        </div>
      </div>
      <div className="flex flex-col gap-1.5">
        <label className="text-sm font-medium text-gray-600">{t("partyView.To'lash sanasi")}</label>
        <div className="flex rounded-lg border border-solid border-gray-300 bg-gray-100 px-3.5 py-2.5 text-base font-normal text-gray-900">
          {paymentDate || t("partyView.Tanlanmagan")}
        </div>
      </div>
    </div>
  );
};

export default Top;
