import React from "react";
import { useDispatch } from "react-redux";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { dayjsFormats } from "modules/common";
import { setAdditionalItem } from "modules/warehouse/create-transfer";

import { CalendarPlusIcon } from "components";

const Date: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { additional, warehouses } = useAppSelector(({ warehouseCreateTransfer }) => warehouseCreateTransfer);
  const { id } = additional;

  const value = warehouses?.find(({ recieved_warehouse_id }) => recieved_warehouse_id === id)?.date;

  const onDate = (_: Dayjs, dateString: string | string[]) => {
    dispatch(
      setAdditionalItem({
        id,
        key: "date",
        value: dateString as string
      })
    );
  };

  return (
    <div className="flex flex-col gap-1.5">
      <label htmlFor="received_warehouse_date" className="text-sm font-medium text-gray-500">
        {t("warehouseTransfer.Sana")}
      </label>
      <DatePicker
        onChange={onDate}
        format={dayjsFormats.DATE}
        id="received_warehouse_date"
        suffixIcon={<CalendarPlusIcon />}
        placeholder={t("warehouseTransfer.Tanlang")}
        value={value ? dayjs(value, dayjsFormats.DATE) : null}
      />
    </div>
  );
};

export default Date;
