export const queryKeys = {
  PROJECTS: "projects",
  DETAILED_PROJECT_FOLDER: "detailed-project-folder",
  DETAILED_PROJECT: "detailed-project",
  SECTIONS: "sections",
  WORKS: "works",
  COMPLETED_WORKS: "completed-works",
  RESOURCES_WORK: "resources-work",
  WORK_SELECT: "works-select",
  TASK_PRODUCTS: "task-products",
  WORKS_STATISTICS: "work-statistics",
  WORK_PROGRESS: "work-progress",
  WORK_PROGRESS_RESOURCE: "work-progress-resource",
  WORK_PLAN: "work-plan",
  DYNAMIC_ESTIMATE_SECTION: "dynamic-estimate-section",
  DYNAMIC_ESTIMATE_TASK: "dynamic-estimate-task",
  DYNAMIC_ESTIMATE_PRODUCT: "dynamic-estimate-product",
  DYNAMIC_ESTIMATE_TASK_PROGRESS: "dynamic-estimate-task-progress",
  SECTIONS_STATISTICS: "sections-statistics",
  GENERAL_SEARCH_SECTIONS: "general-search-sections",
  GROUP_CUSTOM_FIELD: "group-custom-field",
  DYNAMIC_GROUP_CUSTOM_FIELD: "dynamic-group-custom-field",
  TASK_SELECT: "task-select",
  PROJECT_REPORT_AMOUNT: "project-report-amount",
  PROJECT_REPORT_CASH_FLOW: "project-report-cash-flow",
  PROJECT_REPORT_PRODUCT_SPEND: "project-report-product-spend",
  PROJECT_SECTIONS_SELECT: "project-sections-select",
  WORK_PROGRESS_BY_ID:"work-progress-by-id"
};
