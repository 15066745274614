import { useEffect, useMemo, useState } from "react";
import { TableProps } from "antd";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { LocalStorage } from "features/app/service/LocalStorage";
import {
  IMPOST_PARTY_COLUMNS,
  PAYMENT_PARTY_COLUMNS,
  SUPPLY_PARTY_COLUMNS,
  WAREHOUSE_PARTY_COLUMNS
} from "features/app/utils/constants/localStorageKeys";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { cx } from "modules/common";
import { defaultColumns, NewPartyColumnsType, PartyColumn, PartyType, WarehouseProduct } from "modules/party/view";

import { LoadingIndicator, Table } from "components";

import styles from "./index.module.scss";

type Props = {
  type: PartyType;
  loading?: boolean;
  className?: string;
  rowClassName?: string;
  onRow?: TableProps["onRow"];
  columns: NewPartyColumnsType;
  data: WarehouseProduct[] | undefined;
};

function Index({ type, data, columns, className, loading, onRow, rowClassName }: Props) {
  const { t } = useTranslation();
  const { updateProduct } = useAppSelector(({ partyReducer }) => partyReducer.view);
  const { supply, impost, warehouse, payment } = useAppSelector(
    ({
      partyReducer: {
        view: { settings }
      }
    }) => settings.columns
  );
  const viewSettings = { supply, impost, warehouse, payment };
  const [localColumns, setLocalColumns] = useState<NewPartyColumnsType>([]);

  const isView = (key: string | number) => {
    if (key !== 0) {
      const foundColumn = viewSettings[type]?.find(column => column?.key === Number(key));

      return {
        checked: foundColumn?.checked,
        order: foundColumn ? viewSettings[type].indexOf(foundColumn) : Infinity
      };
    }

    return {
      checked: true,
      order: Infinity
    };
  };

  const localKeys: Record<PartyType, string> = {
    impost: IMPOST_PARTY_COLUMNS,
    payment: PAYMENT_PARTY_COLUMNS,
    supply: SUPPLY_PARTY_COLUMNS,
    warehouse: WAREHOUSE_PARTY_COLUMNS
  };

  const onChangeColumns = (columns: any[]) => {
    const allLocalCols = LocalStorage.get<PartyColumn[]>(localKeys[type] as never);

    LocalStorage.set(
      localKeys[type] as never,
      defaultColumns[type]?.map(defaultCol => {
        const activeCol = columns?.find(a => a?.key === defaultCol?.key);
        const localCol = allLocalCols?.find(l => l?.key === defaultCol?.key);

        return {
          key: activeCol?.key || localCol?.key || defaultCol?.key,
          width: activeCol?.width || localCol?.width || defaultCol?.width,
          order: activeCol?.order ?? localCol?.order ?? defaultCol?.order,
          checked: activeCol?.checked ?? localCol?.checked ?? defaultCol?.checked,
          minWidth: activeCol?.minWidth ?? localCol?.minWidth ?? defaultCol?.minWidth,
          title: typeof activeCol?.title === "string" ? activeCol?.title : (localCol?.title ?? defaultCol?.title)
        };
      })
    );

    setLocalColumns(columns as never);
  };

  const modifiedColumns = useMemo(
    () =>
      localColumns?.map(col => ({
        ...col,
        onCell: (record: WarehouseProduct) => ({
          className: col?.active && record?.id === updateProduct?.id ? styles.active_col : col?.className
        })
      })),
    [localColumns]
  );

  useEffect(() => {
    setLocalColumns(
      columns
        ?.map(col => {
          const { checked, order } = isView(col?.key);
          const lsColumns = LocalStorage.get<Omit<NewPartyColumnsType, "render" | "onCell">>(localKeys[type] as never);
          const thisCol = lsColumns?.find(lsCol => lsCol?.key === col?.key);

          if (col?.key !== 0) {
            return {
              ...col,
              order,
              width: thisCol?.width || col?.width,
              checked: thisCol?.checked || checked,
              title: t(`partyView.${col?.title}`)
            };
          }

          return { ...col, checked, order };
        })
        .filter(col => !col?.hidden && col?.checked)
        .sort((a, b) => a.order - b.order)
    );
  }, [columns]);

  return (
    <Table
      onRow={onRow}
      pagination={false}
      defaultMinWidth={70}
      dataSource={data as never}
      rowClassName={rowClassName}
      onChangeColumns={onChangeColumns}
      columns={modifiedColumns as never}
      rootClassName={cx(styles.table, className)}
      loading={{
        spinning: loading,
        indicator: LoadingIndicator
      }}
      locale={{
        emptyText: <TableEmpty />
      }}
    />
  );
}

export default Index;
