import React from "react";
import { Checkbox } from "antd";
import { downloadFile } from "features/app/utils/helpers/downloadFile";
import { downloadFileData } from "features/app/utils/helpers/downloadFileData";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { PartyView } from "modules/party/view";

import { UploadIcon } from "components";

import Wrapper from "./wrapper";

type Props = {
  item: PartyView["custom_field_values"][number];
};

const Item: React.FC<Props> = ({ item }) => {
  const { value, custom_field_option, file, custom_field } = item;
  const { name, type } = custom_field;

  const { t } = useTranslation();

  return (
    <Wrapper name={name?.[i18n.language]}>
      {(() => {
        if (file) {
          const onDownload = () => {
            const { fileName } = downloadFileData(file?.name);

            downloadFile(file?.name, fileName);
          };

          return (
            <div className="rounded-xl border border-solid border-gray-300 bg-white p-2.5">
              <div className="flex gap-2.5">
                <UploadIcon />
                <div className="flex flex-col gap-1">
                  <span className="text-sm font-medium text-gray-700">{file?.original_name}</span>
                  <span className="text-sm font-normal text-gray-600">{(file?.size * 0.000001).toFixed(2)} MB</span>
                </div>
              </div>
              <div className="flex w-full justify-end">
                <div className="cursor-pointer text-sm font-medium text-primary-500 underline" onClick={onDownload}>
                  {t("partyView.Yuklab olish")}
                </div>
              </div>
            </div>
          );
        }

        if (custom_field_option) {
          return (
            <div className="flex rounded-lg border border-solid border-gray-300 bg-gray-100 px-3.5 py-2.5 text-base font-normal text-gray-900">
              {custom_field_option?.name?.[i18n.language] || t("partyView.Mavjud emas")}
            </div>
          );
        }

        if (type === "checkbox") {
          return <Checkbox disabled checked />;
        }

        return (
          <div className="flex rounded-lg border border-solid border-gray-300 bg-gray-100 px-3.5 py-2.5 text-base font-normal text-gray-900">
            {value || t("partyView.Mavjud emas")}
          </div>
        );
      })()}
    </Wrapper>
  );
};

export default Item;
