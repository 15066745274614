import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import $api from "features/app/utils/helpers/axiosInstance";
import { supplyQueryNames } from "features/supply/utils/constants/supplyQueryNames";
import { useTranslation } from "react-i18next";

import { errorHandler, ErrorRes, SuccessRes } from "modules/common";

import { partyQueryKeys } from "../query-keys";

export function usePartyRefund() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.put(`supply/order-refund?id=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async (res, id) => {
        await qc.invalidateQueries([supplyQueryNames.ORDERS]);
        await qc.invalidateQueries([partyQueryKeys.VIEW, id]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}
