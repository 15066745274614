import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FileViewer {
  url: string;
  isOpen: boolean;
  name: string;
  format: string;
  id?: number;
}

const initialState: FileViewer = {
  isOpen: false,
  url: "",
  format: "",
  name: ""
};

export const fileViewerSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    handleOpen: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        format: string;
        url: string;
        name: string;
        id: number;
      }>
    ) => {
      state.isOpen = action.payload.isOpen;
      state.format = action.payload.format;
      state.url = action.payload.url;
      state.name = action.payload.name;
      state.id = action.payload.id;
    },
    handleClose: state => {
      state.isOpen = false;
      state.format = "";
      state.url = "";
      state.name = "";
    }
  }
});

export const fileViewerActions = fileViewerSlice.actions;
export default fileViewerSlice.reducer;
