import { z } from "zod";

import { productSchema, unitSchema } from "modules/common";

export const createTransferStateSchema = z.object({
  visible: z.boolean(),
  additional: z.object({
    visible: z.boolean(),
    name: z.string().optional(),
    id: z.number() // received warehouse id
  }),
  sender_warehouse_id: z.number().or(z.undefined()),
  errors: z.array(
    z.object({
      parentIndex: z.number(),
      productId: z.number(),
      unitId: z.number()
    })
  ),
  warehouses: z.array(
    z.object({
      date: z.string().optional(),
      description: z.string().optional(),
      recieved_warehouse_id: z.number(),
      name: z.string(), // warehouse name
      recieved_project_id: z.number().optional(),
      file_ids: z.array(z.number()),
      custom_field_values: z.array(
        z.object({
          value: z.string().or(z.number()).or(z.undefined()),
          custom_field_id: z.number()
        })
      ),
      products: z.array(
        z.object({
          product_id: z.number(),
          unit_id: z.number(),
          max_quantity: z.number(),
          quantity: z.string().optional(),
          name: z.string(),
          resource: z.string(),
          color: z.string(),
          unit: z.string()
        })
      )
    })
  )
});

export const createTransferPayloadSchema = createTransferStateSchema
  .omit({
    visible: true,
    additional: true,
    warehouses: true,
    errors: true
  })
  .merge(
    z.object({
      warehouses: z.array(
        z.object({
          date: z.string().optional(),
          file_ids: z.array(z.number()),
          description: z.string().optional(),
          recieved_warehouse_id: z.number(),
          recieved_project_id: z.number().optional(),
          products: z.array(
            z.object({
              product_id: z.number(),
              unit_id: z.number(),
              quantity: z.number()
            })
          )
        })
      )
    })
  );

export const warehouseProductSchema = z.object({
  product: productSchema,
  unit: unitSchema,
  total_amount: z.number(),
  total_quantity: z.number()
});

export type CreateTransferState = z.infer<typeof createTransferStateSchema>;
export type CreateTransferPayload = z.infer<typeof createTransferPayloadSchema>;
export type WarehouseProduct = z.infer<typeof warehouseProductSchema>;
