export function parseToCrumbName(name?: string, isParse?: boolean) {
  if (name) {
    if (isParse)
      return decodeURI(
        name
          ?.split("_")
          ?.join(" ")
          ?.replace(/[^a-zA-Z0-9\s]/g, "")
      );
    return decodeURI(
      name
        ?.split(" ")
        ?.join("_")
        ?.replace(/[^a-zA-Z0-9\s]/g, "")
    );
  }
  return name;
}
