import React from "react";
import { useDispatch } from "react-redux";
import { Drawer } from "antd";
import { useGetSingleProductHistoryView } from "features/warehouse/service/query";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { warehouseProductsAction } from "store/reducers/warehouseProductsReducer";

import Bottom from "./bottom/bottom";
import Top from "./top/top";

import styles from "./income-expense-history-drawer.module.scss";
import { isEmptyObj } from "modules/common";

const IncomeExpenseHistoryDrawer: React.FC = () => {
  const { t } = useTranslation();
  const { visible, product } = useAppSelector(state => state.warehouseProducts?.resource_history);

  const { data, isLoading } = useGetSingleProductHistoryView(
    Number(product?.product?.id),
    Number(product?.unit?.id),
    visible
  );

  const dispatch = useDispatch();
  const { setResourceHistory } = warehouseProductsAction;

  const onClose = () => {
    dispatch(
      setResourceHistory({
        visible: false
      })
    );
  };

  return (
    <Drawer
      open={visible}
      title={t("warehouse.Kirim-chiqim tarixi")}
      placement="right"
      zIndex={200000}
      rootClassName={styles.history_drawer}
      onClose={onClose}
    >
      <div className="flex flex-col gap-6 p-5">
        <Top isLoading={isLoading} stats={!isEmptyObj(data) ? data!.statistics! : undefined} />
        <Bottom isLoading={isLoading} data={!isEmptyObj(data)  ?data!.histories!:undefined} />
      </div>
    </Drawer>
  );
};

export default IncomeExpenseHistoryDrawer;
