import React from "react";
import { useDispatch } from "react-redux";
import { UseFormReturn } from "react-hook-form";
import { Dropdown } from "antd";
import UnreadMessageDots from "features/app/components/unread-message/UnreadMessageDots";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/reducers/partyReducer";

import { PartyForm } from "modules/party/create";
import { PartyView } from "modules/party/view";

import Items from "pages/party/update/title/items";
import Process from "pages/party/view/title/process";
import Statuses from "pages/party/view/title/statutes";

import { ConditionalRender, XCloseIcon } from "components";

type Props = {
  form: UseFormReturn<PartyForm>;
  party: PartyView;
};

const Index: React.FC<Props> = ({ form, party }) => {
  const dispatch = useDispatch();
  const { setPartyUpdate } = partyReducerActions;
  const { isProcess } = useAppSelector(({ partyReducer }) => partyReducer.update);
  const { id, status, confirmation_payment, payment_percent, percent, unread_message_count } = party;

  const onClose = () => {
    dispatch(
      setPartyUpdate({
        id: undefined,
        visible: false,
        isGroup: false,
        isVat: false,
        isProcess,
        isDiscount: false
      })
    );
  };

  return (
    <div className="box-border flex w-full items-center justify-between border border-l-0 border-r-0 border-t-0 border-solid border-gray-300 bg-white p-3.5">
      <div className="flex items-center gap-4">
        <h3 className="m-0 text-lg font-semibold text-gray-900">P - {id}</h3>
        <ConditionalRender if={isProcess} else={<Statuses status={status} />}>
          <Process
            supplyStatus={status}
            warehouseStatus={percent}
            paymentStatus={payment_percent}
            impostStatus={confirmation_payment}
          />
        </ConditionalRender>
      </div>
      <div className="flex items-center gap-2">
        <Dropdown
          arrow={false}
          placement="bottom"
          trigger={["click"]}
          destroyPopupOnHide
          menu={{
            items: Items({ party, form })
          }}
        >
          <div className="mr-6 flex cursor-pointer items-center justify-center rounded-md border border-solid border-gray-300 bg-gray-50 px-2 py-1.5">
            <UnreadMessageDots count={unread_message_count} />
          </div>
        </Dropdown>
        <div
          onClick={onClose}
          className="box-border flex cursor-pointer items-center justify-center rounded-md p-1.5 duration-300 ease-in-out hover:bg-gray-100"
        >
          <XCloseIcon width={20} />
        </div>
      </div>
    </div>
  );
};

export default Index;
