import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { isEmptyObj } from "modules/common";
import { routeSubmodules, tabItems, WorksTabEnum } from "modules/works";

import { Bottom } from "../../bottom";
import { Right } from "../right";

import styles from "./left.module.scss";

const Left: React.FC = () => {
  const { queries, append } = useQueryParams();
  const navigate = useNavigate();
  const { statistics_type } = queries();
  const { t } = useTranslation();
  const { submodules } = useRoutePermissions("Ishlar", routeSubmodules);

  const onChangeTabs = (key: string) => {
    append(queryParamsKeys.STATISTICS_TYPE, key, true);
  };

  useEffect(() => {
    if (!statistics_type) {
      navigate({ search: `${queryParamsKeys.STATISTICS_TYPE}=${WorksTabEnum.LIST_OF_WORKS}` });
    }
  }, [statistics_type, navigate]);

  return (
    <div className={styles.left}>
      <Tabs
        activeKey={statistics_type}
        items={tabItems(<Bottom />)
          ?.map(el => ({
            ...el,
            label: t(`works.${el?.label}`)
          }))
          ?.filter(el => !isEmptyObj(submodules.find(sub => sub.key === el?.permissionKey)))}
        onChange={onChangeTabs}
        tabBarExtraContent={<Right />}
      />
    </div>
  );
};

export default Left;
