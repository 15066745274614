import React from "react";
import { useDispatch } from "react-redux";
import { UseFieldArrayAppend, UseFormReturn, useWatch } from "react-hook-form";
import { Button } from "antd";

import { partyReducerActions } from "store/reducers/partyReducer";

import { cx } from "modules/common";
import { PartyForm } from "modules/party/create";
import { appendToFromProducts, PartyResourceForm } from "modules/party/resource";

type Props = {
  form: UseFormReturn<PartyResourceForm>;
  append: UseFieldArrayAppend<PartyForm, "warehouse_products">;
  appendCheck: UseFieldArrayAppend<PartyForm, "check_products">;
};

const Index: React.FC<Props> = ({ form, append, appendCheck }) => {
  const dispatch = useDispatch();
  const { setPartyResource } = partyReducerActions;
  const { products } = useWatch({ control: form.control });
  const disabled = !(products && products?.length > 0);

  const onClose = () => {
    dispatch(
      setPartyResource({
        visible: false
      })
    );
  };

  const onReset = () => {
    form.setValue("products", []);
  };

  const onAppend = () => {
    appendToFromProducts(products as never, appendCheck, append);

    onClose();
  };

  return (
    <div className="flex items-center justify-between bg-white p-5">
      <div className="text-base font-semibold text-gray-600">Tanlangan mahsulotlar: {products?.length} ta</div>
      <div className="flex items-center gap-3">
        <Button onClick={onClose}>Yopish</Button>
        <Button disabled={disabled} onClick={onReset} className={cx("reject-button")}>
          Qo'shilganlarni bekor qilish
        </Button>
        <Button type="primary" disabled={disabled} onClick={onAppend}>
          Qo'shish
        </Button>
      </div>
    </div>
  );
};

export default Index;
