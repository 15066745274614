import React from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

import { TooltipShortName } from "modules/common";
import { ProcessTableData } from "modules/works";

import styles from "./index.module.scss";

type Props = {
  record: ProcessTableData;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProcessView: React.FC<Props> = ({ record, setVisible, visible }) => {
  const { t, i18n } = useTranslation();

  const onCancel = () => {
    setVisible(false);
  };

  const footer = <Button onClick={onCancel}>{t("works.Yopish")}</Button>;

  return (
    <Modal
      centered
      open={visible}
      title={t("works.Resurs sarfi")}
      className={styles.process_view_modal}
      footer={footer}
      onCancel={onCancel}
    >
      <div className="mb-5 mt-5 flex flex-col items-center gap-3 rounded-xl border border-solid border-gray-200 p-4">
        <div className="flex w-full justify-between">
          <span className="text-sm font-normal text-gray-600">{t("works.Ish nomi")}</span>
          <span className="text-sm font-normal text-gray-700">
            {record?.task?.place} <TooltipShortName title={record?.task?.name} length={15} />
          </span>
        </div>
        <div className="flex w-full justify-between">
          <span className="text-sm font-normal text-gray-600">{t("works.Ish bo'limi")}</span>
          <span className="text-sm font-normal text-gray-700">
            {record?.task?.section?.place} <TooltipShortName title={record?.task?.section?.name} length={15} />
          </span>
        </div>
        <div className="flex w-full justify-between">
          <span className="text-sm font-normal text-gray-600">{t("works.Ish resursi")}</span>
          <div className="flex items-center gap-3">
            <span
              style={{
                borderColor: record?.product?.resource?.color,
                color: record?.product?.resource?.color
              }}
              className="rounded-[4px] border-[1.5px] border-solid px-2 py-2px text-xs font-medium"
            >
              {record?.product?.resource?.symbol[i18n.language]}
            </span>{" "}
            <span className="text-sm font-normal text-gray-700">
              <TooltipShortName title={String(record?.product?.name[i18n.language])} length={20} />
            </span>
          </div>
        </div>
        <div className="flex w-full justify-between">
          <span className="text-sm font-normal text-gray-600">{t("works.Loyiha")}</span>
          <span className="text-sm font-normal text-gray-700">
            <TooltipShortName title={record?.task?.section?.project?.name} length={15} />
          </span>
        </div>
        <div className="flex w-full justify-between">
          <span className="text-sm font-normal text-gray-600">{t("works.Progress")}</span>
          <span className="text-base font-semibold text-primary-500">
            {record?.task_progress_quantity} {record?.task?.unit?.symbol[i18n.language]}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ProcessView;
