import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { Catalog, partyResourceQueryKeys } from "modules/party/resource";

type Query = {
  catalogs: Catalog[];
};

export function useCatalogs({ enabled }: { enabled: boolean }) {
  const initial = {
    catalogs: []
  };

  const { data = initial, ...arg } = useQuery<Query>(
    [partyResourceQueryKeys.CATALOGS],
    async () => {
      const { data } = await $api.get("category/view");

      return { catalogs: data?.data };
    },
    { enabled }
  );

  return { ...data, ...arg };
}
