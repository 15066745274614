import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Drawer, Form, message } from "antd";
import dayjs from "dayjs";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { useGetCustomFieldSelect } from "features/app/service/queries";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { generateCustomFieldsData, passedCustomFieldsData } from "features/payment/utils/helpers/paymentCustomField";
import Header from "features/warehouse/components/transfers/transfer-making-drawer/header/Header";
import { transformReqData } from "features/warehouse/components/transfers/transfer-making-drawer/helpers/transformReqData";
import { ITransferringResourcesModel } from "features/warehouse/utils/models/transferringResourcesModel";
import { WarehouseProductModel } from "features/warehouse/utils/models/WarehouseProductModel";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";

import { transferMakingReducerActions, useSelector } from "store";
import { warehouseTransferActions } from "store/reducers/warehouseTransferReducer";

import { cx, dayjsFormats } from "modules/common";

import { useCreateTransfer, useUpdateTransfer } from "../../../service/mutation";
import { useGetOneTransfer, useGetWarehouseProductsInTransfers } from "../../../service/query";
import { TransferBodyModel } from "../../../utils/models/transferBodyModel";
import SelectedResourcesModal from "../selected-resources-modal/SelectedResourcesModal";

import TransferNoData from "./no-data/TransferNoData";
import SelectedProductsTable from "./selected-products-table/SelectedProductsTable";
import Info from "./info";

import styles from "./transferMakingDrawer.module.scss";

const { useForm, useWatch, Item } = Form;

const TransferMakingDrawer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { queries } = useQueryParams();
  const [form] = useForm<TransferBodyModel>();
  const { size } = queries();

  const [page, setPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>();
  const { setSelectProducts } = warehouseTransferActions;
  const { setDrawerVisible } = transferMakingReducerActions;
  const warehouseId = useWatch("sender_warehouse_id", form);
  const receiverWarehouseId = useWatch("recieved_warehouse_id", form);
  const products = useWatch("products", form) as ITransferringResourcesModel[];
  const { data, visible, id } = useSelector(state => state.transferMakingReducer);
  const { selectProducts } = useAppSelector(state => state.warehouseTransferReducer);
  const [selectedResourcesModalVisible, setSelectProductsModalVisible] = useState<boolean>(false);

  const createTransfer = useCreateTransfer();
  const updateTransfer = useUpdateTransfer(id);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.WAREHOUSE_TRANSFER]);
  const { data: warehouseProducts, isLoading } = useGetWarehouseProductsInTransfers(warehouseId, searchText, page);
  const { data: transfer, isLoading: isLoadingTransfer } = useGetOneTransfer(
    data?.products && data?.products?.length === 0 ? id : undefined
  );

  const onCancel = () => {
    dispatch(
      setDrawerVisible({
        id,
        data,
        visible: false
      })
    );
  };

  const onOk = () => {
    if (warehouseId !== receiverWarehouseId) {
      const condition = products?.every(el => Number(el?.quantity) <= Number(el?.total_quantity));

      form.validateFields().then(() => {
        if (condition) {
          form.submit();
        }
      });
    } else {
      message.warning(t("warehouse.Jo'natuvchi omborxona va qabul qiluvchi omborxona bir xil bo'lmasligi kerak!!!"));
    }
  };

  const onOpenSelectedResourcesModal = () => {
    setSelectProductsModalVisible(true);
  };

  const filteredSelectedProducts = selectProducts?.filter(
    item => item.changed_quantity_value! <= item.selected_total_value!
  );
  const filteredProducts = products?.filter(el => el?.product_id);

  const footer = (
    <div className={cx(styles.transfer_drawer_footer, id ? "justify-end" : "justify-between")}>
      {!id && (
        <Button
          className={styles.selected_ones}
          onClick={onOpenSelectedResourcesModal}
          disabled={filteredSelectedProducts?.length === 0}
        >
          {t("warehouseTransfer.Tanlanganlar resurlar:")} {filteredSelectedProducts?.length}
        </Button>
      )}
      <div className={styles.button_container}>
        <Button onClick={onCancel}>{t("warehouseTransfer.Yopish")}</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={createTransfer.isLoading || updateTransfer.isLoading}
          onClick={onOk}
          disabled={
            !warehouseId || filteredProducts?.length === 0 || createTransfer.isLoading || updateTransfer.isLoading
          }
        >
          {id ? t("warehouseTransfer.Saqlash") : t("warehouseTransfer.O'tkazma qilish")}
        </Button>
      </div>
    </div>
  );

  const onFinish = (values: TransferBodyModel) => {
    const req = transformReqData(values);

    if (id) {
      const updateReq = { ...req };

      delete updateReq.custom_field_values;
      updateTransfer
        .mutateAsync({
          ...updateReq,
          custom_field_values: generateCustomFieldsData(values.custom_field_values)
        } as unknown as TransferBodyModel)
        .then(() => {
          onCancel();
        });
    } else {
      const createReq = { ...req };

      delete createReq.sender_warehouse_id;
      delete createReq.custom_field_values;
      delete createReq.file_ids;

      createTransfer
        .mutateAsync({
          file_ids: values?.file_ids,
          sender_warehouse_id: req?.sender_warehouse_id,
          custom_field_values: generateCustomFieldsData(values.custom_field_values),
          warehouses: [{ ...createReq }]
        } as unknown as TransferBodyModel)
        .then(() => {
          onCancel();
        });
    }
  };

  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      setPage(1);
      form.resetFields();
      setSearchText(undefined);
      dispatch(setSelectProducts([]));
      form.setFieldValue("products", []);
      form.setFieldValue("selected_products", []);

      dispatch(
        setDrawerVisible({
          id: undefined,
          data: undefined,
          visible: false
        })
      );
    }
  };

  useEffect(() => {
    if (id) {
      if (data?.products && data?.products?.length > 0) {
        form.setFieldsValue({
          id,
          recieved_project_id: data?.recieved_project_id,
          recieved_warehouse_id: data?.recieved_warehouse_id,
          sender_warehouse_id: data?.sender_warehouse_id,
          date: data?.date ? (dayjs(data?.date, dayjsFormats.DATE) as never) : null,
          products: data?.products?.map(el => ({
            product_id: `${el?.product?.id}-${el?.unit?.id}` as never,
            unit_id: el?.unit?.id,
            quantity: String(el?.quantity),
            total_quantity: el?.total_quantity,
            unit: el?.unit,
            product: el?.product,
            rowId: Number(el?.product?.id) - Number(el?.unit?.id)
          })),
          selected_products: data?.products?.map(el => ({
            product_id: `${el?.product?.id}-${el?.unit?.id}` as never,
            unit_id: el?.unit?.id,
            quantity: String(el?.quantity),
            total_quantity: el?.total_quantity,
            unit: el?.unit,
            product: el?.product,
            rowId: Number(el?.product?.id) - Number(el?.unit?.id)
          }))
        });

        const selectedOnes =
          data?.products && data?.products?.length > 0
            ? data?.products?.map(item => ({
                ...(item as unknown as WarehouseProductModel),
                changed_quantity_value: Number(item?.quantity),
                selected_total_value: item?.total_quantity,
                rowId: Number(item?.product?.id) - Number(item?.unit?.id)
              }))
            : [];

        dispatch(setSelectProducts(selectedOnes));
      } else {
        form.setFieldsValue({
          id,
          recieved_project_id: data?.recieved_project_id,
          recieved_warehouse_id: data?.recieved_warehouse_id,
          sender_warehouse_id: data?.sender_warehouse_id,
          date: data?.date ? (dayjs(data?.date, dayjsFormats.DATE) as never) : null,
          products: transfer?.warehouse_products?.map(el => ({
            product_id: `${el?.product?.id}-${el?.unit?.id}` as never,
            unit_id: el?.unit?.id,
            quantity: String(el?.initial_quantity),
            total_quantity: el?.warehouse_quantity,
            unit: el?.unit,
            product: el?.product,
            rowId: Number(el?.product?.id) - Number(el?.unit?.id)
          })),
          selected_products: transfer?.warehouse_products?.map(el => ({
            product_id: `${el?.product?.id}-${el?.unit?.id}` as never,
            unit_id: el?.unit?.id,
            quantity: String(el?.initial_quantity),
            total_quantity: el?.warehouse_quantity,
            unit: el?.unit,
            product: el?.product,
            rowId: Number(el?.product?.id) - Number(el?.unit?.id)
          })),
          custom_field_values: passedCustomFieldsData(transfer?.warehouse_transfer?.custom_field_values, customFields)
        });

        const selectedOnes =
          transfer && transfer?.warehouse_products?.length > 0
            ? transfer?.warehouse_products?.map(item => ({
                ...(item as unknown as WarehouseProductModel),
                changed_quantity_value: Number(item?.initial_quantity),
                selected_total_value: item?.warehouse_quantity,
                rowId: Number(item?.product?.id) - Number(item?.unit?.id)
              }))
            : [];

        dispatch(setSelectProducts(selectedOnes));
      }
    }
  }, [id, data, form, dispatch, transfer]);

  useEffect(() => {
    setPage(1);
  }, [size]);

  return (
    <Drawer
      open={visible}
      footer={footer}
      onClose={onCancel}
      afterOpenChange={onAfterOpen}
      rootClassName={styles.transfer_drawer}
      title={id ? `T-${id}` : t("warehouseTransfer.O'tkazma qilish")}
    >
      <Form form={form} layout="vertical" className="h-full w-full" onFinish={onFinish}>
        <div className={styles.body}>
          <div className="flex">
            <Item shouldUpdate name="products" className="hidden" initialValue={[]} />
            <Item shouldUpdate name="selected_products" className="hidden" initialValue={[]} />
            <div className={styles.form_content}>
              <Header form={form} setPage={setPage} />
              <ConditionalRender if={warehouseId}>
                <Item shouldUpdate className="m-0">
                  <SelectedProductsTable
                    form={form}
                    page={page}
                    setPage={setPage}
                    data={warehouseProducts}
                    onPageChange={onPageChange}
                    setSearchText={setSearchText}
                    createTransfer={createTransfer}
                    isLoading={(() => {
                      if (!id) {
                        return isLoading;
                      }

                      return data?.products && data?.products?.length === 0 ? isLoadingTransfer : false;
                    })()}
                  />
                </Item>
              </ConditionalRender>
              <ConditionalRender if={!warehouseId}>
                <div className={styles.no_data_container}>
                  <TransferNoData />
                </div>
              </ConditionalRender>
            </div>
          </div>
          <Info form={form} customFields={customFields} />
          <SelectedResourcesModal
            form={form}
            visible={selectedResourcesModalVisible}
            setVisible={setSelectProductsModalVisible}
          />
        </div>
      </Form>
    </Drawer>
  );
};

export default TransferMakingDrawer;
