import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { defectModalActions } from "store/reducers/defectModalReducer";

import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const WarehouseDefectRejected = ({ record, onNavigatePage }: Props) => {
  const dispatch = useDispatch();
  const { setDefectModal } = defectModalActions;
  const { i18n } = useTranslation();

  const onOpenDefectView = () => {
    dispatch(
      setDefectModal({
        visible: true,
        data: {
          id: record?.warehouse_defect?.id
        } as never
      })
    );
  };

  return (
    <div onClick={onOpenDefectView} className={styles.description}>
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.user?.full_name} yaroqsizga chiqarilga {record?.warehouse_defect?.product?.name[i18n.language]}ni
        tasdiqlamadi.
      </p>
    </div>
  );
};

export default WarehouseDefectRejected;
