import React, { ReactNode } from "react";
import { UseFormReturn } from "react-hook-form";

import { useAppSelector } from "hooks/redux";

import { Form, PartyType, PartyViewForm, usePartyView } from "modules/party/view";

import Footer from "pages/party/view/footer";
import Title from "pages/party/view/title";

import { Impost, Supply, Warehouse } from "./items";

const Index: React.FC = () => {
  const { type, visible, party_id } = useAppSelector(state => state.partyReducer.view);
  const { party } = usePartyView({ enabled: visible, id: party_id });
  const { percent, confirmation_payment, warehouse_products, payment_percent, unread_message_count, status } = party;

  const contents = (form: UseFormReturn<PartyViewForm>): Record<PartyType, ReactNode> => ({
    supply: <Supply form={form} />,
    warehouse: <Warehouse form={form} />,
    impost: <Impost form={form} />,
    payment: <>Payment</>
  });

  return (
    <Form>
      {form => (
        <div className="flex h-full w-full flex-col">
          <Title
            form={form}
            status={status}
            percent={percent}
            products={warehouse_products}
            paymentPercent={payment_percent}
            unreadMessageCount={unread_message_count}
            confirmationPayment={confirmation_payment}
          />
          <div className="flex-1 overflow-hidden">{contents(form)[type] || <></>}</div>
          <Footer form={form} party={party} status={status} confirmationPayment={confirmation_payment} />
        </div>
      )}
    </Form>
  );
};

export default Index;
