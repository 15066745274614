import React, { useState } from "react";
import { UseFieldArrayAppend, UseFieldArrayRemove, UseFormReturn, useWatch } from "react-hook-form";

import { CompanyPersonEmpty, getPopupContainer, PartyForm } from "modules/party/create";
import { useCompanyPersons } from "modules/party/view";

import { Select, TooltipShortName } from "components";

type Props = {
  index: number;
  isUpdate?: boolean;
  parentIndex: number;
  remove: UseFieldArrayRemove;
  form: UseFormReturn<PartyForm>;
  appendGroup: UseFieldArrayAppend<PartyForm>;
};

const Index: React.FC<Props> = ({ form, index, parentIndex, isUpdate, remove, appendGroup }) => {
  const [search, setSearch] = useState<string>();
  const { companyPersons, isLoading } = useCompanyPersons();
  const { company_person_group } = useWatch({ control: form.control });
  const copyProduct = structuredClone(company_person_group?.[parentIndex]?.products?.[index]);

  const onChange = (e: number, option: any) => {
    remove(index);

    const person = { ...copyProduct, company_person_id: e, company_person_name: option?.props?.name } as never;

    if (option) {
      const findIndex = company_person_group?.findIndex(person => person?.id === e);

      if (findIndex! > -1) {
        form.setValue(`company_person_group.${findIndex!}.products`, [
          ...form.getValues(`company_person_group.${findIndex!}.products`),
          person
        ]);
      } else {
        appendGroup({
          id: e,
          name: option?.props?.name,
          currency_id: copyProduct?.currency_id,
          currency_symbol: copyProduct?.currency_symbol,
          products: [person]
        });
      }
    } else {
      const findNotPersonIndex = company_person_group?.findIndex(({ id }) => id === 0);

      if (findNotPersonIndex! > -1) {
        form.setValue(`company_person_group.${findNotPersonIndex!}.products`, [
          ...form.getValues(`company_person_group.${findNotPersonIndex!}.products`),
          person
        ]);
      } else {
        appendGroup({
          id: 0,
          name: "Belgilanmagan",
          currency_id: copyProduct?.currency_id,
          currency_symbol: copyProduct?.currency_symbol,
          products: [person]
        });
      }
    }
  };

  return (
    <Select
      suffixIcon={null}
      allowClear={true}
      loading={isLoading}
      onChange={onChange}
      control={form.control}
      placeholder="Ta'minotchi"
      popupMatchSelectWidth={false}
      onSearch={value => setSearch(value)}
      name={`company_person_group.${parentIndex}.products.${index}.company_person_id`}
      notFoundContent={
        <CompanyPersonEmpty search={search} fieldName={`company_person_group.${parentIndex}.products.${index}`} />
      }
      getPopupContainer={getPopupContainer(isUpdate ? "party-left-content-update" : "party-left-content")}
      options={companyPersons?.map(person => ({
        key: person?.id,
        value: person?.id,
        label: (
          <TooltipShortName
            length={11}
            arrow={false}
            title={person?.name as string}
            getPopupContainer={getPopupContainer("party-left-content")}
          />
        ),
        props: {
          name: person?.name
        }
      }))}
    />
  );
};

export default Index;
