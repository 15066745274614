import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, MenuProps, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { useRoutePermissions } from "../../../../../../hooks/useRoutePermissions";
import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import EditIcon from "../../../../../app/assets/icons/EditIcon";
import FolderIcon from "../../../../../app/assets/icons/FolderIcon";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import { menuColors } from "../../../../../app/utils/constants/menuColors";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { useDeleteInventoryFolder, useUpdateInventoryFolder } from "../../../../service/mutations";
import { routeSubmodules } from "../../../../utils/constants/routeSubmodules";
import { InventoryFolderModel } from "../../../../utils/models/inventoryFolderModel";
import { EditFolderName } from "../edit-folder-name/EditFolderName";

import styles from "./folderCard.module.scss";

const FolderCard = (folder: InventoryFolderModel) => {
  const { t } = useTranslation();
  const { id, name, color, inventories_count } = folder;
  const navigate = useNavigate();
  const { reqQueryParam } = useQueryParams();
  const [isEdit, setIsEdit] = useState(false);
  const updateFolder = useUpdateInventoryFolder();
  const deleteFolder = useDeleteInventoryFolder();
  const { actions } = useRoutePermissions("Inventar", routeSubmodules);
  const folderActions = actions("Inventarlar papkasi");

  const onDelete = () => {
    id && deleteFolder.mutate(id);
  };

  const onEdit = () => {
    setIsEdit(true);
  };

  const onClickCard = () => {
    navigate({
      pathname: `${name}-${id}`,
      search: reqQueryParam(queryParamsKeys.SEARCH, queryParamsKeys.COMPANY_PERSON_IDS_ARR, queryParamsKeys.WORK_STATUS)
    });
  };

  const onFinish = (name: string) => {
    setIsEdit(false);
    return updateFolder.mutateAsync({ id, name, color });
  };

  const onChangeColor = (color: string) => {
    updateFolder.mutate({ color, id, name });
  };

  const menuItems: MenuProps["items"] = useMemo(() => {
    const newItems = [
      {
        key: "1",
        label: (
          <div className={styles.colors}>
            {menuColors.map((color, index) => (
              <span
                key={index}
                className={styles.item}
                onClick={() => onChangeColor(color.background)}
                style={{
                  background: color.border
                }}
              >
                <span
                  style={{
                    background: color.background
                  }}
                ></span>
              </span>
            ))}
          </div>
        )
      }
    ];

    if (folderActions["edit"]) {
      newItems.push({
        key: "2",
        label: (
          <div className={styles.delete} onClick={onEdit}>
            <EditIcon />
            <span>{t("Inventory.Tahrirlash")}</span>
          </div>
        )
      });
    }

    if (folderActions["delete"]) {
      newItems.push({
        key: "3",
        label: (
          <div className={styles.delete} onClick={onDelete}>
            <DeleteIcon />
            <span>{t("Inventory.O'chirish")}</span>
          </div>
        )
      });
    }

    return newItems;
  }, []);

  return (
    <Spin indicator={LoadingIndicator} spinning={updateFolder.isLoading}>
      <Dropdown menu={{ items: menuItems }} trigger={["contextMenu"]}>
        <div className={styles.card} onClick={onClickCard}>
          <div className={styles.card__left}>
            <FolderIcon color={color} />
          </div>
          <div className={styles.card__right}>
            {isEdit ? (
              <EditFolderName
                placeholder={t("products.Papka nomini kiriting")}
                onFinish={onFinish}
                className={styles.name}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
              >
                {name}
              </EditFolderName>
            ) : (
              <p className={styles.name}>{name}</p>
            )}
            <p className={styles.count}>
              {inventories_count} {t("Inventar.inventar")}
            </p>
          </div>
        </div>
      </Dropdown>
    </Spin>
  );
};

export default FolderCard;
