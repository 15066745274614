import React from "react";
import { useParams } from "react-router-dom";

import { useRoutePermissions } from "../../../../hooks/useRoutePermissions";
import { parseParamsId } from "../../../app/utils/helpers/parseParamsId";
import CounterpartsTable from "../../components/counterparts/content/table/CounterpartsTable";
import CounterpartsModal from "../../components/counterparts/modal/CounterpartsModal";
import { useGetOneCompanyFolder } from "../../service/queries";
import { companyPersonSubmodules } from "../../utils/constants/routeSubmodules";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const params = useParams();
  const { id } = parseParamsId(params?.id);
  const { data, isLoading } = useGetOneCompanyFolder(id);

  const { actions } = useRoutePermissions("Kontragent", companyPersonSubmodules);
  const folderActions = actions("Papkalar");

  return (
    <div className={styles.detailed_counterparts}>
      <div className={styles.bottom}>
        <CounterpartsTable
          folderActions={folderActions}
          data={data}
          isLoading={isLoading}
          key="detailed_counterparts_folder"
        />
        <CounterpartsModal />
      </div>
    </div>
  );
};

export default Index;
