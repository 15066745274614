import React from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Modal } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { isEmptyArr } from "modules/common";
import { CreateTransferState, setAdditional, setDeleteWarehouse } from "modules/warehouse/create-transfer";

import { Building02Icon, DotsVerticalIcon, InfoCircleIcon, TrashCircleIcon, TrashIcon } from "components";

import Products from "./products";
import Search from "./search";

import styles from "./index.module.scss";

const { confirm } = Modal;

const Card: React.FC<CreateTransferState["warehouses"][number] & { index: number }> = ({
  name,
  index,
  recieved_warehouse_id
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { products } = useAppSelector(({ warehouseCreateTransfer }) => warehouseCreateTransfer.warehouses[index]);

  const onAdditional = () => {
    dispatch(
      setAdditional({
        id: recieved_warehouse_id,
        visible: true,
        name
      })
    );
  };

  const onDelete = () => {
    if (!isEmptyArr(products)) {
      confirm({
        centered: true,
        destroyOnClose: true,
        wrapClassName: styles.warehouse_confirm,
        title: (
          <span className="text-lg font-semibold text-gray-900">
            {t("warehouseTransfer.Omborxonani o'chirmoqchimisiz")} ?
          </span>
        ),
        icon: <TrashCircleIcon />,
        content: (
          <span className="text-sm font-normal text-gray-600">
            {t("warehouseTransfer.Ushbu omborxonani o'chirishinigz bilan kiritilgan mahsulotlar o'chirilib yuboriladi")}{" "}
            !
          </span>
        ),
        okText: t("warehouseTransfer.O'chirish"),
        okType: "danger",
        cancelText: t("warehouseTransfer.Yopish"),
        onOk() {
          dispatch(setDeleteWarehouse({ index, id: recieved_warehouse_id }));
        }
      });
    } else {
      dispatch(setDeleteWarehouse({ index, id: recieved_warehouse_id }));
    }
  };

  const items = [
    {
      key: 1,
      label: (
        <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
          <InfoCircleIcon />
          {t("warehouseTransfer.Qo'shimcha ma'lumotlar")}
        </div>
      ),
      onClick: onAdditional
    },
    {
      key: 2,
      label: (
        <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
          <TrashIcon />
          {t("warehouseTransfer.O'chirish")}
        </div>
      ),
      onClick: onDelete
    }
  ];

  return (
    <div className="box-border flex w-[19.8rem] flex-col gap-5 overflow-y-auto rounded-2xl border border-solid border-gray-200 bg-white p-4">
      <div className="box-border flex items-center justify-between rounded-xl border border-solid border-gray-200 bg-gray-50 p-3">
        <div className="flex w-[92%] items-center gap-3">
          <Building02Icon />
          <span
            title={name}
            className="w-4/5 overflow-hidden text-ellipsis whitespace-nowrap text-lg font-medium text-gray-600"
          >
            {name}
          </span>
        </div>
        <Dropdown menu={{ items }} placement="bottomCenter" trigger={["click"]}>
          <div className="flex flex-1 cursor-pointer items-center justify-center">
            <DotsVerticalIcon />
          </div>
        </Dropdown>
      </div>
      <div className="flex flex-1 flex-col gap-2">
        <Search index={index} />
        <Products index={index} />
      </div>
    </div>
  );
};

export default Card;
