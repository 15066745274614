import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { setBuildings, setCheckedBuildingsClear, setStep, useIntegrationBuilding } from "modules/settings/uysot";

const UysotBuildingFooter: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mutateAsync, isLoading } = useIntegrationBuilding();
  const { buildings, step } = useAppSelector(({ uysot }) => uysot);

  const onNext = () => {
    dispatch(setStep(step + 1));
    dispatch(setCheckedBuildingsClear());
  };

  const onSave = () => {
    mutateAsync({
      buildings: buildings
        ?.map(({ building_id, status, cash_id, project_id, name }) => ({
          name,
          status,
          cash_id,
          project_id,
          building_id
        }))
        .filter(({ cash_id, project_id }) => cash_id && project_id)
    }).then(() => {
      dispatch(setCheckedBuildingsClear());
      dispatch(setBuildings([]));

      onNext();
    });
  };

  return (
    <div className="box-border flex items-center justify-end gap-2 p-4">
      <Button onClick={onNext} disabled={isLoading}>
        {t("uysot.Keyingisi")}
      </Button>
      <Button type="primary" onClick={onSave} loading={isLoading}>
        {t("uysot.Saqlash")}
      </Button>
    </div>
  );
};

export default UysotBuildingFooter;
