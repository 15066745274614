import { useQuery } from "@tanstack/react-query";

import { useQueryParams } from "hooks/useQueryParams";

import { IdAndName } from "modules/common";

import $api from "../../../../features/app/utils/helpers/axiosInstance";
import { partyQueryKeys } from "../query-keys";

type Query = {
  warehouses: IdAndName[];
};

export function useWarehouses(mine?: boolean) {
  const { generateSearchParam } = useQueryParams();

  const initialData: Query = {
    warehouses: []
  };

  const search = generateSearchParam({ mine });

  const { data = initialData, ...arg } = useQuery<Query>(
    [partyQueryKeys.WAREHOUSES, search],
    async () => {
      const { data } = await $api.get(`warehouse/select${search ? `?${search}` : ""}`);

      return { warehouses: structuredClone(data?.data) };
    },
    {
      staleTime: Infinity
    }
  );

  return { ...data, ...arg };
}
