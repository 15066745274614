import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { admissionHistoryKeys } from "../query-keys";
import { AdmissionHistory } from "../schema";

type Query = {
  histories: AdmissionHistory[];
};

export function useAdmissionHistory({ orderId }: { orderId?: number }) {
  const initial = {
    histories: []
  };

  const { data = initial, ...arg } = useQuery<Query>(
    [admissionHistoryKeys.view, orderId],
    async () => {
      const { data } = await $api.get(`warehouse-product/product-history-view?order_id=${orderId}`);

      return {
        histories: data?.data
      };
    },
    {
      enabled: !!orderId
    }
  );

  return { ...data, ...arg };
}
