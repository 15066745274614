import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Dropdown, Popover, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { DateStatus } from "features/app/components/date-status/DateStatus";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect } from "features/app/service/queries";
import { RU } from "features/app/utils/constants/languages";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { sliceText } from "features/app/utils/helpers/sliceText";
import { UserTableDataModel } from "features/app/utils/models/user/userTableConfigModel";
import { supplyOrdersConfigs } from "features/supply/utils/constants/supplyOrdersConfigs";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { chatActions } from "store/reducers/chatReducer";
import { supplyOfferActions } from "store/reducers/supplyOfferReducer";
import { supplyActions } from "store/reducers/supplyReducer";

import useDebounce from "components/use-debounce/use-debounce";

import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import EditIcon from "../../../../app/assets/icons/EditIcon";
import PlusIcon from "../../../../app/assets/icons/PlusIcon";
import NotUserImage from "../../../../app/components/not-image/NotUserImage";
import OrderSortBy from "../../../../app/components/order-sort-by/OrderSortBy";
import PopoverShortName from "../../../../app/components/popover-short-name/PopoverShortName";
import TableSettings from "../../../../app/components/table-settings/TableSettings";
import UnreadMessageAction from "../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import CalculateIcon from "../../../assets/icons/CalculateIcon";
import ClockFastForwardIcon from "../../../assets/icons/ClockFastForwardIcon";
import { useDeleteOrders } from "../../../service/mutations";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";
import { supplyQueryNames } from "../../../utils/constants/supplyQueryNames";
import { warehouseProductTableDefaultData } from "../../../utils/constants/warehouseProductTableDefaultData";
import { StatusEnums } from "../../../utils/enums/statusEnums";
import { OfferModalDataModel } from "../../../utils/models/OfferModel";
import { ProductModel } from "../../../utils/models/productModel";

import styles from "./orders.module.scss";

type Props = {
  renderColumns: ColumnsType<ProductModel>;
  setColumns: Dispatch<SetStateAction<ColumnsType<ProductModel>>>;
  tableConfigData: UserTableDataModel | undefined;
};

const OrderColumns = ({ renderColumns, setColumns, tableConfigData }: Props) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { i18n, t } = useTranslation();
  const { severalSearchParams } = useQueryParams();
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const { setVisible } = chatActions;
  const deleteOrders = useDeleteOrders();
  const { setOfferModal } = supplyOfferActions;
  const { setPartyModal, setOrderModal } = supplyActions;
  const { setOfferHistory } = supplyOfferActions;

  const { actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const offerActions = actions("Takliflar");
  const partyActions = actions("Partiyalar");
  const ordersActions = actions("Buyurtmalar");

  const changeTableConfig = useChangeTableConfig(tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG, true);

  const { selectOrderProducts: selectProducts } = useAppSelector(state => state.supplyReducer);

  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.WAREHOUSE_PRODUCT]);

  const isClickedRow = useMemo(() => selectProducts?.length > 0, [selectProducts]);

  const sectionName = (record: ProductModel) => {
    const { task, section } = record;

    return (
      <div className={styles.work}>
        <h5>
          {section?.name ? (
            <>
              <span>
                {section?.place}.{task?.place}
              </span>
              <span>
                {section?.name?.length > 16 ? (
                  <Popover title={section?.name} overlayClassName={styles.popover}>
                    {sliceText(section?.name, 16)}
                  </Popover>
                ) : (
                  section?.name
                )}
              </span>
            </>
          ) : (
            "-"
          )}
        </h5>
        {task?.name && (
          <span>
            {task?.name?.length > 16 ? (
              <Popover title={task?.name} overlayClassName={styles.popover}>
                {sliceText(task?.name, 16)}
              </Popover>
            ) : (
              task?.name
            )}
          </span>
        )}
      </div>
    );
  };

  const columns: ColumnsType<ProductModel> = [
    {
      title: `${t("Supply.Resurs turi va nomi")}`,
      render: (record: ProductModel) => {
        const columnWidth = renderColumns?.find(column => column.className === "product")?.width;

        return (
          <div className="resource">
            <div
              style={{
                borderColor: record?.product?.resource?.color,
                color: record?.product?.resource?.color
              }}
            >
              {record?.product?.resource?.symbol?.[i18n.language]}
            </div>
            <PopoverShortName
              title={record?.product?.name[i18n.language]}
              // conflict dan so'ng olindi length argumenti
              length={((columnWidth as number) + 150) / 10}
            />
          </div>
        );
      },
      width: 750,
      fixed: "left",
      className: "product",
      key: `product.name.${i18n.language}`
    },
    {
      width: 450,
      key: "work_name",
      render: sectionName,
      title: `${t("Supply.Ish nomi va bo'limi")}`,
      className: "work_name"
    },
    {
      width: 120,
      key: `unit.name.${i18n.language}`,
      title: `${t("Supply.Birligi")}`,
      render: (record: ProductModel) => record?.unit?.symbol[i18n.language],
      className: "unit"
    },
    {
      width: 120,
      key: "quantity",
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_QUANTITY} title={t("Supply.Soni")} />,
      render: (record: ProductModel) => record?.quantity?.toLocaleString(RU) ?? 0,
      className: "quantity"
    },
    {
      width: 170,
      key: "delivery_date",
      title: (
        <OrderSortBy queryParamKey={queryParamsKeys.SORT_DELIVERY_DATE} title={t("Supply.Kerak bo'lish sanasi")} />
      ),
      render: (record: ProductModel) => <DateStatus status={StatusEnums.OPEN} delivery_date={record.delivery_date} />,
      className: "delivery_date"
    },
    {
      key: "created_at",
      width: 170,
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_CREATED_AT} title={t("Supply.Yaratilgan sana")} />,
      dataIndex: "created_at",
      className: "created_at"
    },
    {
      width: 150,
      key: "warehouse",
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_WAREHOUSE} title={t("Suppply.Omborxona")} />,
      render: (record: ProductModel) => record?.warehouse?.name,
      className: "warehouse"
    },
    {
      width: 150,
      key: "project.name",
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_PROJECT} title={t("Supply.Loyiha")} />,
      render: (record: ProductModel) => (
        <div className={styles.project}>
          <div className={!isClickedRow ? "project_name" : ""}>{record?.project?.name || "-"}</div>
        </div>
      ),
      className: "project"
    },
    {
      width: 150,
      key: "creator",
      title: `${t("Supply.Xodim")}`,
      render: (_, record) =>
        record?.creator?.image ? (
          <Tooltip title={record?.creator?.full_name} className={styles.user} placement="top">
            <img className={styles.user__image} src={record?.creator?.image} alt={record?.creator?.full_name} />
          </Tooltip>
        ) : (
          <NotUserImage name={record?.creator?.full_name} isTooltip />
        ),
      className: "creator.full_name"
    }
  ];

  const onOpenPartyModal = (record?: ProductModel) => () => {
    dispatch(
      setPartyModal({
        visible: true,
        selectProducts: [record!]
      })
    );
  };

  const onOpenOfferModal = (record?: ProductModel) => () => {
    dispatch(
      setOfferModal({
        visible: true,
        viewPartySwitch: true,
        data: (record ? [record] : selectProducts) as OfferModalDataModel[]
      })
    );
  };

  const onOpenChat = (record: ProductModel) => () => {
    dispatch(
      setVisible({
        objectId: record?.id,
        visible: true,
        type: "warehouse_products",
        dataKeys: [supplyQueryNames.WAREHOUSE_PRODUCT_COUNT, severalSearchParams(queryParamsKeys.TAB)],
        record
      })
    );
  };

  const onEdit = (record: ProductModel) => () => {
    dispatch(
      setOrderModal({
        visible: true,
        isEdit: true,
        selectProducts: [record],
        orderId: record?.id
      })
    );
  };

  const onDeleteProducts = (payload?: number[]) => () => {
    const orderIds = payload || selectProducts?.map(item => item.id);

    return deleteOrders.mutateAsync(orderIds);
  };

  const onOpenOffersHistoryModal = (id: number) => {
    dispatch(
      setOfferHistory({
        productId: id,
        visible: true,
        isCheckedView: true
      })
    );
  };

  const actionItems = (record: ProductModel) => {
    const defaultItems = [
      {
        key: "1",
        label: (
          <div className={styles.dropdown_item}>
            <PlusIcon /> {t("Supply.Partiya yaratish")}
          </div>
        ),
        onClick: onOpenPartyModal(record),
        permissionKey: "create_party"
      },
      {
        key: "2",
        label: (
          <div className={styles.dropdown_item}>
            <CalculateIcon />
            {t("Supply.Taklif berish")}
          </div>
        ),
        onClick: onOpenOfferModal(record),
        permissionKey: "create_offer"
      },
      {
        key: "3",
        label: (
          <div className={styles.dropdown_item}>
            <ClockFastForwardIcon />
            {t("Supply.Takliflar tarixi")}
          </div>
        ),
        onClick: () => onOpenOffersHistoryModal(record.product.id),
        permissionKey: "offerHistory"
      },
      {
        key: "4",
        label: (
          <div className={styles.dropdown_item}>
            <EditIcon />
            {t("Supply.Tahrirlash")}
          </div>
        ),
        onClick: onEdit(record),
        permissionKey: "edit"
      },
      {
        key: "5",
        label: (
          <div className={styles.dropdown_item}>
            <UnreadMessageAction count={record?.unread_message_count} />
          </div>
        ),
        onClick: onOpenChat(record),
        permissionKey: "chatView"
      },
      {
        key: "6",
        label: (
          <div className={styles.dropdown_item} onClick={onDeleteProducts([record.id])}>
            <DeleteIcon /> {t("Supply.O'chirish")}
          </div>
        ),
        permissionKey: "delete"
      }
    ];

    return defaultItems.filter(item => {
      if (item.permissionKey) {
        const [first, second] = item.permissionKey.split("_");

        if (second) {
          const checkActions = {
            party: partyActions,
            offer: offerActions
          };

          return first in checkActions[second as keyof typeof checkActions];
        }
        return first in ordersActions;
      }
      return true;
    });
  };

  const saveColumns = () => {
    const columnsToSave = renderColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutateAsync({
      key: tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<ProductModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useEffect(() => {
    if (tableConfigData && customFields) {
      setColumns([
        ...filterColumns({ columns: columns as never, customFields, tableConfig: tableConfigData }),
        {
          title: (
            <TableSettings
              isNeedSize
              defaultData={supplyOrdersConfigs(i18n.language)}
              configKey={tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG}
              locations={[CustomFieldLocationEnum.WAREHOUSE_PRODUCT]}
            />
          ),
          render: (record: ProductModel) => (
            <Dropdown menu={{ items: actionItems(record) }} trigger={["click"]}>
              <UnreadMessageDots count={record?.unread_message_count} />
            </Dropdown>
          ),
          className: "c_p",
          fixed: "right",
          width: 75
        }
      ]);
    }
  }, [tableConfigData, customFields]);

  return { columns: renderColumns, onChangeColumns };
};

export default OrderColumns;
