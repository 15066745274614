export const supplyEndpoints = {
  WAREHOUSE_PRODUCT: "supply/warehouse-product-view",
  WAREHOUSE_PRODUCT_DELETE: "supply/warehouse-product-delete",
  COMPANY_PERSON: "company-person/select",
  AGENTS: "admin/select",
  CREATE_ORDER: "supply/order-create",
  DELETE_ORDER: "supply/order-delete",
  UPDATE_ORDER: "supply/order-update",
  RECIEVED_ORDER: "supply/order-recieved",
  REJECTED_ORDER: "supply/order-rejected",
  ORDERS: "supply/order-view",
  ADDITIONAL: "supply/additional-view",
  PROJECTS: "supply/project-view",
  PROJECTS_SELECT: "project/select",
  CONFIRMATION_ORDERS: "supply/order-confirmation",
  ADDITIONAL_CONFIRM: "supply/additional-confirmation",
  WAREHOUSES: "warehouse/select",
  WAREHOUSE_PRODUCT_CREATE: "supply/warehouse-product-create",
  TASK_PRODUCT_VIEW: "supply/task-product-view",
  PRODUCT_CREATE: "warehouse-product/create",
  PRODUCT_UPDATE: "warehouse-product/update",
  OFFERS: "supply/warehouse-product-offer",
  DELETE_OFFER: "warehouse-product-offer/delete",
  OFFER_RECIEVED: "warehouse-product-offer/recieved",
  OFFER_REJECTED: "warehouse-product-offer/rejected",
  CREATE_OFFER: "warehouse-product-offer/create",
  UPDATE_OFFER: "warehouse-product-offer/update",
  PRODUCT_GENERAL_EDITING: "supply/warehouse-product-all-update",
  PARTY_GENERAL_EDITING: "supply/order-all-update",
  ORDER_PRODUCTS_GENERAL_EDITING: "warehouse-product/all-update",
  OFFER_HISTORY: "warehouse-product-offer/history",
  PARTY_EXCEL: "supply/order-export-excel",
  SUPPLY_PRODUCT_UPDATE: "supply/warehouse-product-update",
  SUPPLY_ORDERS_EXCEL_EXPORT: "supply/warehouse-product-view-export-excel",
  REFUND_ORDER: "supply/order-refund",
  COMPANY_PERSON_PAYMENT_TYPE_CREATE: "company-person-payment-type/create",
  COMPANY_PERSON_PAYMENT_TYPE_DELETE: "company-person-payment-type/delete"
};
