import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import $api from "features/app/utils/helpers/axiosInstance";
import { errorHandler } from "features/app/utils/helpers/errorHandler";
import { useTranslation } from "react-i18next";

import { ErrorRes, SuccessRes } from "modules/common";

import { UysotBuildingPayload } from "../schema";

export function useIntegrationBuilding() {
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, UysotBuildingPayload>(
    async payload => {
      const { data } = await $api.post("integration/uysot-building/create", payload);

      return data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        message.success(res.message[i18n.language]);
      }
    }
  );
}
