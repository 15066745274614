import { UserTableDataModel } from "features/app/utils/models/user/userTableConfigModel";

const processTableConfigData: UserTableDataModel = {
  column: [
    {
      id: "resource_type_and_name",
      name: "resource_type_and_name",
      title: "Resurs turi va nomi",
      checked: true
    },
    {
      id: "created_at",
      name: "created_at",
      title: "Sana",
      checked: true
    },
    {
      id: "quantity",
      name: "quantity",
      title: "Miqdori",
      checked: true
    },
    {
      id: "unit.symbol",
      name: "unit.symbol",
      title: "Birlik",
      checked: true
    },
    {
      id: "unit.amount",
      name: "unit.amount",
      title: "Birlik summasi",
      checked: true
    },
    {
      id: "total_amount",
      name: "total_amount",
      title: "Umumiy summasi",
      checked: true
    }
  ],
  size: 10
};
export default processTableConfigData;
