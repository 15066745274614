import { Drawer, Spin } from "antd";

import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux";
import { projectsReducerActions } from "../../../../../../store/reducers/projectsReducer";
import CloseIcon from "../../../../../app/assets/icons/CloseIcon";
import Comments from "../../../../../app/components/comments/Comments";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import { useGetWork } from "../../../../service/queries";

import WorkModalLeft from "./left/WorkModalLeft";
import Actions from "./actions";

import styles from "./workModal.module.scss";

const WorkModal = () => {
  const { setWork } = projectsReducerActions;
  const dispatch = useAppDispatch();
  const { workModal } = useAppSelector(state => state.projectsReducer);
  const { data: work, isLoading } = useGetWork(workModal.visible, workModal?.id);

  const onCancel = () => {
    dispatch(setWork({ visible: false, id: 0 }));
  };

  const title = (
    <div className={styles.title}>
      <p>{`${work?.task?.place}. ${work?.task?.section?.name}`}</p>
      <div className="flex items-center gap-3">
        <Actions record={work?.task as never} />
        <div className={styles.close_icon} onClick={onCancel}>
          <CloseIcon />
        </div>
      </div>
    </div>
  );

  return (
    <Drawer
      width={1600}
      footer={null}
      open={workModal.visible}
      title={title}
      onClose={onCancel}
      closeIcon={false}
      className={styles.work_modal}
      destroyOnClose={true}
    >
      <Spin spinning={isLoading} indicator={LoadingIndicator}>
        <div className={styles.content}>
          <WorkModalLeft id={workModal?.id} visible={workModal.visible} />
          <div className={styles.right}>
            <Comments type="tasks" objectId={workModal?.id} key={workModal?.id} />
          </div>
        </div>
      </Spin>
    </Drawer>
  );
};

export default WorkModal;
