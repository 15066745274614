import React from "react";
import { Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import { cx } from "modules/common";

import { ConfirmModal, LinkBrokenIcon } from "components";

type Props = {
  connect: boolean;
  isLoading: boolean;
  isDisconnect: boolean;
  onConnect: () => void;
  onDisConnect: () => Promise<void>;
};

const Default: React.FC<Props> = ({ connect, onConnect, onDisConnect, isLoading, isDisconnect }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-2">
      <Button
        onClick={onConnect}
        type={connect ? "default" : "primary"}
        loading={isDisconnect ? false : isLoading}
        className={cx("flex-1 rounded-xl", { "reject-button": connect })}
      >
        {connect ? t("integration.Sozlash") : t("integration.Faollashtirish")}
      </Button>
      <ConfirmModal
        onOk={onDisConnect}
        cancelText={t("integration.Yopish")}
        okText={t("integration.Bekor qilish")}
        title={t("integration.Integratsiyani bekor qilmoqchimisiz ?")}
        description={t("integration.Ushbu jarayon bir necha soniyalar ichida amalga oshadi !")}
      >
        <Tooltip title={t("integration.Integratsiyani bekor qilish")} arrow={false}>
          <div
            className={cx(
              "box-border flex h-full cursor-pointer items-center justify-center rounded-xl border border-solid border-transparent bg-error-100 px-6 py-3 duration-200 ease-in-out hover:border-error-500",
              { hidden: !connect }
            )}
          >
            <LinkBrokenIcon />
          </div>
        </Tooltip>
      </ConfirmModal>
    </div>
  );
};

export default Default;
