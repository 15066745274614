import { parseLocaledString } from "modules/common";
import { PartyFormProduct, PartyReqPayload } from "modules/party/create";

export function parsePayload(products: PartyFormProduct[]): PartyReqPayload["warehouse_products"] {
  return products?.map(item => ({
    id: item?.id,
    task_product_id: item?.task_product_id || undefined,
    vat_id: item?.vat_id || undefined,
    product_id: item?.product_id,
    unit_id: item?.unit_id,
    warehouse_id: item?.warehouse_id,
    project_id: item?.project_id || undefined,
    currency_id: item?.currency_id,
    company_person_id: item?.company_person_id || undefined,
    quantity: parseLocaledString(item?.quantity || "0"),
    amount: parseLocaledString(item?.amount || "0")
  }));
}
