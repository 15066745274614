import React from "react";
import { TFunction } from "i18next";

import { colors } from "modules/common";

import { CheckIcon } from "components";

export function cashProcess(status: number, t: TFunction<"translation", undefined>) {
  if (status === 0) {
    return {
      icon: null,
      borderColor: colors.GRAY_100,
      backgroundColor: colors.GRAY_100,
      textAndIconColor: colors.GRAY_600,
      text: t("partyProcess.To'lanmagan"),
      processNumber: 4
    };
  }

  if (status > 0 && status < 100) {
    return {
      icon: null,
      borderColor: colors.PRIMARY_100,
      backgroundColor: colors.PRIMARY_100,
      textAndIconColor: colors.PRIMARY_600,
      text: `${t("partyProcess.Jarayonda")}- ${status}%`,
      processNumber: 4
    };
  }

  return {
    icon: <CheckIcon color={colors.WHITE} width={16} />,
    borderColor: colors.SUCCESS_100,
    backgroundColor: colors.SUCCESS_100,
    textAndIconColor: colors.SUCCESS_500,
    text: t("partyProcess.To'langan"),
    processNumber: 4
  };
}
