import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sliceNames } from "features/app/utils/constants/sliceNames";

import { UysotReducer } from "./schema";

const initialState: UysotReducer = {
  id: 0,
  step: 1,
  buildings: [],
  currencies: [],
  visible: false,
  start_date: null,
  payment_types: [],
  checked_buildings: []
};

const uysotSlice = createSlice({
  name: sliceNames.UYSOT,
  initialState,
  reducers: {
    // basic
    setVisible: (
      state,
      action: PayloadAction<{ visible: boolean; step: number; id: number; start_date: string | null }>
    ) => {
      state.id = action.payload.id;
      state.step = action.payload.step;
      state.visible = action.payload.visible;
      state.start_date = action.payload.start_date;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },

    // buildings
    setBuildings: (state, action: PayloadAction<UysotReducer["buildings"]>) => {
      state.buildings = action.payload;
    },
    setCheckedBuildings: (state, action: PayloadAction<{ buildings: UysotReducer["buildings"]; check: boolean }>) => {
      const { buildings, check } = action.payload;

      if (!check) {
        state.checked_buildings = state.checked_buildings?.filter(
          ({ building_id }) => !buildings.some(({ building_id: buildingId }) => buildingId === building_id)
        );
      } else {
        const filterBuildings = buildings.filter(
          ({ building_id }) =>
            !state.checked_buildings.some(({ building_id: stateBuildingId }) => stateBuildingId === building_id)
        );

        state.checked_buildings = [...state.checked_buildings, ...filterBuildings];
      }
    },
    setCheckedBuildingItem: (
      state,
      action: PayloadAction<{ building: UysotReducer["buildings"][number]; check: boolean }>
    ) => {
      const { building, check } = action.payload;

      if (check) {
        state.checked_buildings = [...state.checked_buildings, building];
      } else {
        state.checked_buildings = state.checked_buildings?.filter(
          ({ building_id }) => !(building_id === building?.building_id)
        );
      }
    },
    setCheckedBuildingsClear: state => {
      state.checked_buildings = [];
    },
    setChangeBuildings: (state, action: PayloadAction<UysotReducer["buildings"]>) => {
      const newBuildings = [...state.buildings];

      action.payload.forEach(({ building_id, status, cash_id, project_id, cash_name, project_name, name }) => {
        const index = newBuildings.findIndex(({ building_id: buildingId }) => buildingId === building_id);

        if (index > -1) {
          newBuildings[index] = { name, building_id, status, cash_id, project_id, cash_name, project_name };
        } else {
          newBuildings.push({ name, building_id, cash_id, status, project_id, cash_name, project_name });
        }
      });

      state.buildings = newBuildings;
    },
    setClearBuildingsCashAndProject: (
      state,
      action: PayloadAction<Pick<UysotReducer["buildings"][number], "building_id" | "name" | "status">[]>
    ) => {
      const newBuildings = [...state.buildings];

      action.payload.forEach(({ building_id, status, name }) => {
        const index = newBuildings.findIndex(({ building_id: buildingId }) => buildingId === building_id);

        if (index > -1) {
          newBuildings[index] = {
            name,
            building_id,
            status,
            cash_id: undefined,
            project_id: undefined,
            cash_name: "",
            project_name: ""
          };
        } else {
          newBuildings.push({
            name,
            building_id,
            cash_id: undefined,
            status,
            project_id: undefined,
            cash_name: undefined,
            project_name: undefined
          });
        }
      });

      state.buildings = newBuildings;
      state.checked_buildings = [];
    },
    setBuildingItem: <TKey extends keyof UysotReducer["buildings"][number]>(
      state: UysotReducer,
      action: PayloadAction<{
        key: TKey;
        id: number;
        name?: string;
        value: UysotReducer["buildings"][number][TKey];
      }>
    ) => {
      const { id, key, value, name } = action.payload;
      const index = state.buildings.findIndex(({ building_id }) => building_id === id);

      if (index > -1) {
        state.buildings[index][key] = value;
      } else {
        state.buildings[state.buildings.length] = {
          name,
          building_id: id,
          cash_id: undefined,
          cash_name: undefined,
          status: "passive",
          project_id: undefined,
          project_name: undefined,
          [key]: value
        };
      }
    },

    // currencies
    setCurrencies: (state, action: PayloadAction<UysotReducer["currencies"]>) => {
      state.currencies = action.payload;
    },
    setCurrencyItem: <TKey extends keyof UysotReducer["currencies"][number]>(
      state: UysotReducer,
      action: PayloadAction<{
        index: number;
        key: TKey;
        value: UysotReducer["currencies"][number][TKey];
      }>
    ) => {
      state.currencies[action.payload.index][action.payload.key] = action.payload.value;
    },

    // payment types
    setPaymentTypes: (state, action: PayloadAction<UysotReducer["payment_types"]>) => {
      state.payment_types = action.payload;
    },
    setPaymentTypeItem: <TKey extends keyof UysotReducer["payment_types"][number]>(
      state: UysotReducer,
      action: PayloadAction<{
        index: number;
        key: TKey;
        value: UysotReducer["payment_types"][number][TKey];
      }>
    ) => {
      state.payment_types[action.payload.index][action.payload.key] = action.payload.value;
    },

    // date
    setStartDate: (state, action: PayloadAction<string | null>) => {
      state.start_date = action.payload;
    }
  }
});

export const {
  setVisible,
  setStep,
  setBuildings,
  setBuildingItem,
  setChangeBuildings,
  setCheckedBuildingItem,
  setCheckedBuildings,
  setCheckedBuildingsClear,
  setClearBuildingsCashAndProject,
  setCurrencies,
  setCurrencyItem,
  setPaymentTypeItem,
  setPaymentTypes,
  setStartDate
} = uysotSlice.actions;
export default uysotSlice.reducer;
