import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";

import { errorHandler, ErrorRes, SuccessRes } from "modules/common";

import { queryParamsKeys } from "../../../../features/app/utils/constants/queryParamsKeys";
import $api from "../../../../features/app/utils/helpers/axiosInstance";
import { impostQueryKeys } from "../../../../features/impost/utils/constants/impostQueryKeys";
import { supplyQueryNames } from "../../../../features/supply/utils/constants/supplyQueryNames";
import { partyQueryKeys } from "../query-keys";
import { ProductUpdate } from "../schema";

export function useProductUpdate() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { severalSearchParams } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);
  const { party_id } = useAppSelector(state => state.partyReducer.view);

  return useMutation<
    SuccessRes,
    ErrorRes,
    Partial<
      Omit<ProductUpdate, "amount" | "total_amount" | "quantity"> & {
        amount: number;
        quantity: number;
      }
    >
  >(
    async body => {
      const { data } = await $api.put("supply/warehouse-product-update", body);

      return data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([partyQueryKeys.VIEW, party_id]);
        await qc.invalidateQueries([supplyQueryNames.ORDERS, searchParam]);
        await qc.invalidateQueries([impostQueryKeys.PARTS, searchParam]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}
