import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

import { useAppSelector } from "hooks/redux";

import { setCheckedBuildings, UysotBuilding } from "modules/settings/uysot";

type Props = {
  buildings: UysotBuilding["buildings"];
};

const BuildingCheckbox: React.FC<Props> = ({ buildings }) => {
  const dispatch = useDispatch();
  const { checked_buildings } = useAppSelector(({ uysot }) => uysot);

  const onChange = (e: CheckboxChangeEvent) => {
    dispatch(
      setCheckedBuildings({
        buildings: buildings?.map(({ id, cash, project, status, name }) => ({
          name,
          status,
          building_id: id,
          cash_id: cash?.id,
          project_id: project?.id
        })),
        check: e.target.checked
      })
    );
  };

  const checked = useMemo(
    () => buildings?.every(({ id }) => checked_buildings.some(({ building_id }) => id === building_id)),
    [checked_buildings, buildings]
  );

  const indeterminate = useMemo(
    () => buildings?.some(({ id }) => checked_buildings.some(({ building_id }) => id === building_id)),
    [checked_buildings, buildings]
  );

  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      indeterminate={indeterminate && !checked}
      onClick={e => {
        e.stopPropagation();
      }}
    />
  );
};

export default BuildingCheckbox;
