import React, { useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { message, Spin, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { UploadFile as UploadFileType } from "antd/es/upload/interface";
import { UploadChangeParam } from "antd/lib/upload";
import UploadIcon from "features/app/assets/icons/UploadIcon";
import { useUploadFile } from "features/app/service/mutation";
import { CustomFieldSelectModel } from "features/app/utils/models/customFieldSelectModel";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { PartyForm } from "modules/party/create";
import { useFile } from "modules/party/update";

import { ConditionalRender, FeatureIcon, LoadingIndicator, TrashIcon } from "components";

import styles from "./index.module.scss";

type Props = {
  index: number;
  isUpdate?: boolean;
  field: CustomFieldSelectModel;
  form: UseFormReturn<PartyForm>;
};

const { Dragger } = Upload;

const File: React.FC<Props> = ({ form, index, field, isUpdate }) => {
  const { t } = useTranslation();
  const uploadFile = useUploadFile();
  const [file, setFile] = useState<RcFile>();
  const { custom_field_values } = useWatch({ control: form.control });
  const id = custom_field_values?.[index]?.value as number;
  const { file: oldFile } = useFile({ id, enabled: !!isUpdate });

  const onChange = (e: UploadChangeParam<UploadFileType>) => {
    if (e.file.size! >= 5120000) {
      message.error("File hajmi 5120 kb dan oshmasligi zarur");
    } else {
      const formData = new FormData();
      const image = e?.file?.originFileObj;

      setFile(image);
      formData.append("files[]", image as Blob);

      uploadFile.mutateAsync(formData).then(res => {
        form.setValue(`custom_field_values.${index}.value`, res?.[0]);
      });
    }
  };

  const onDelete = () => {
    setFile(undefined);
    form.setValue(`custom_field_values.${index}.value`, null);
  };

  return (
    <Spin spinning={uploadFile.isLoading} indicator={LoadingIndicator}>
      <div className="flex flex-col gap-1.5">
        <label className="text-sm font-medium text-gray-700">{field?.name?.[i18n.language]}</label>
        <Dragger
          name="files"
          maxCount={1}
          multiple={false}
          onChange={onChange}
          showUploadList={false}
          customRequest={() => null}
          rootClassName={styles.file}
          accept=".jpg, .jpeg, .png, .svg, .doc, .docx, .xlsx, .xls, .txt, .pdf, .csv, .pptx, .ppt, .heic"
        >
          <div className="contents">
            <UploadIcon />
            <p>
              <span>{t("products.Yuklash uchun bosing")}</span> {t("products.yoki faylni surib olib keling")}
            </p>
          </div>
        </Dragger>
        <ConditionalRender if={file || (isUpdate && id && oldFile)}>
          <div className="box-border flex justify-between rounded-xl border border-solid border-gray-300 px-4 py-2">
            <div className="flex gap-2">
              <FeatureIcon />
              <div className="flex flex-col gap-1">
                <h4 className="m-0 text-sm font-medium text-gray-700">{file?.name || oldFile?.original_name}</h4>
                <span className="text-sm font-normal text-gray-600">
                  {((file?.size || oldFile?.size || 0) / 1000000).toFixed(2)} MB
                </span>
              </div>
            </div>
            <div className="flex cursor-pointer items-center justify-center" onClick={onDelete}>
              <TrashIcon />
            </div>
          </div>
        </ConditionalRender>
      </div>
    </Spin>
  );
};

export default File;
