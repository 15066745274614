import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Input } from "antd";
import { SearchIcon } from "features/app/assets/icons/SearchIcon";
import { TimeoutModel } from "features/app/utils/models/TimeoutModel";

import { PartyResourceForm } from "modules/party/resource";

type Props = {
  form: UseFormReturn<PartyResourceForm>;
};

const Index: React.FC<Props> = ({ form }) => {
  const [time, setTime] = useState<TimeoutModel>();
  const [search, setSearch] = useState("");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(time);

    setSearch(e?.target?.value);

    setTime(
      setTimeout(() => {
        form.setValue("search", e.target.value);
      }, 500)
    );
  };

  return <Input value={search} prefix={<SearchIcon />} className="w-80" onChange={onChange} placeholder="Qidirish" />;
};

export default Index;
