import React from "react";
import { Form, FormInstance, message, Progress, Spin, Upload, UploadFile } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { useTranslation } from "react-i18next";

import DeleteIcon from "../../assets/icons/DeleteIcon";
import EllipseFileIcon from "../../assets/icons/EllipseFileIcon";
import UploadIcon from "../../assets/icons/UploadIcon";
import XCloseCircleIcon from "../../assets/icons/XCloseCircleIcon";
import { useUploadFile } from "../../service/mutation";
import { cx } from "../../utils/helpers/cx";
import { sliceText } from "../../utils/helpers/sliceText";
import { FileListType } from "../../utils/models/fileListModel";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";

import styles from "./customUpload.module.scss";

type Props = {
  name?: string;
  accept?: string;
  isArray: boolean;
  isImage?: boolean;
  form: FormInstance;
  fileList: FileListType[];
  setFileList: React.Dispatch<React.SetStateAction<FileListType[]>>;
  disabled?: boolean;
  description?: string;
  label?: string;
};

const { Item } = Form;
const { Dragger } = Upload;

const CustomUpload: React.FC<Props> = ({
  name,
  form,
  accept,
  label,
  isArray,
  isImage,
  fileList,
  setFileList,
  disabled,
  description
}) => {
  const { t } = useTranslation();
  const uploadFile = useUploadFile();

  const onChange = (e: UploadChangeParam<UploadFile>) => {
    if (e.file.size! >= 5120000) {
      message.error("File hajmi 5120 kb dan oshmasligi zarur").then();
    } else if (isArray) {
      const images = e?.fileList;
      const formData = new FormData();

      images.forEach(item => {
        formData.append("files[]", item.originFileObj as Blob);
      });

      uploadFile.mutateAsync(formData).then(res => {
        setFileList([
          ...(images?.map((item, index) => ({
            id: res[index],
            file: item.originFileObj!,
            path: window.URL && window.URL.createObjectURL(item.originFileObj!)
          })) ?? [])
        ]);
        form.setFieldValue(name ?? "file_id", res);
      });
    } else {
      const image = e?.file?.originFileObj;
      const formData = new FormData();

      formData.append("files[]", image as Blob);

      uploadFile.mutateAsync(formData).then(res => {
        setFileList([{ id: res[0], file: image! }]);
        form.setFieldValue(name ?? "file_id", res);
      });
    }
  };

  const onDeleteFile = (id: number) => {
    const filterFileList = fileList?.filter(item => item.id !== id);

    form.setFieldValue(
      name ?? "file_id",
      filterFileList.map(item => item.id)
    );

    setFileList(filterFileList);
  };

  return (
    <Item name={name ?? "file_id"} label={label}>
      <Dragger
        name="images"
        multiple={isArray}
        disabled={disabled}
        beforeUpload={() => {
          setFileList([]);
        }}
        onChange={onChange}
        showUploadList={false}
        className={styles.upload}
        rootClassName={cx(disabled && "bg-gray-500")}
        customRequest={() => null}
        style={{ marginBottom: "0.75rem" }}
        fileList={fileList?.map(item => item.file)}
        accept={accept ?? ".jpg, .jpeg, .png, .svg, .cv, .docx, .doc"}
      >
        <div className={cx(styles.upload__info)}>
          <UploadIcon />
          {!description ? (
            <p>
              <span>{t("products.Yuklash uchun bosing")}</span> {t("Inventar.yoki faylni surib keling")}. ({" "}
              {t("Inventar.fayl hajmi 5120 kb dan yuqori bo'lmasligi zarur")} )
            </p>
          ) : (
            <p>{description}</p>
          )}
        </div>
      </Dragger>
      <Spin indicator={LoadingIndicator} style={{ marginTop: "0.75rem" }} spinning={uploadFile.isLoading}>
        {uploadFile.isLoading && <span>{t("Inventar.Yuklanmoqda")}</span>}
        {!isImage ? (
          <div className={styles.upload__files}>
            {fileList?.map(item => (
              <div className={styles.file} key={item.id}>
                <div>
                  <EllipseFileIcon />
                </div>
                <div className={styles.file__right}>
                  <div className={styles.name}>
                    <p>
                      {sliceText(item.file?.name, 45)}
                      <span>{(item.file?.size / 1000000).toFixed(2)} MB</span>
                    </p>
                    <div className={styles.delete} onClick={() => onDeleteFile(item.id)}>
                      <DeleteIcon />
                    </div>
                  </div>
                  <Progress status="active" percent={100} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={cx("upload__content")}>
            {fileList?.map(item => (
              <div className={cx("upload__content__item")} key={item.id}>
                <img className={cx("upload__image")} src={item?.path} alt="" />
                <div className={cx("upload__content__item__close")} onClick={() => onDeleteFile(item.id)}>
                  <XCloseCircleIcon />
                </div>
              </div>
            ))}
          </div>
        )}
      </Spin>
    </Item>
  );
};

export default CustomUpload;
