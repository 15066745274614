import React from "react";

const PercentIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_22278_44954)">
      <path
        d="M7.49935 7.5013H7.50768M12.4993 12.5013H12.5077M13.3327 6.66797L6.66602 13.3346M7.91602 7.5013C7.91602 7.73142 7.72947 7.91797 7.49935 7.91797C7.26923 7.91797 7.08268 7.73142 7.08268 7.5013C7.08268 7.27118 7.26923 7.08464 7.49935 7.08464C7.72947 7.08464 7.91602 7.27118 7.91602 7.5013ZM12.916 12.5013C12.916 12.7314 12.7295 12.918 12.4993 12.918C12.2692 12.918 12.0827 12.7314 12.0827 12.5013C12.0827 12.2712 12.2692 12.0846 12.4993 12.0846C12.7295 12.0846 12.916 12.2712 12.916 12.5013ZM18.3327 10.0013C18.3327 14.6037 14.6017 18.3346 9.99935 18.3346C5.39698 18.3346 1.66602 14.6037 1.66602 10.0013C1.66602 5.39893 5.39698 1.66797 9.99935 1.66797C14.6017 1.66797 18.3327 5.39893 18.3327 10.0013Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_22278_44954">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PercentIcon;
