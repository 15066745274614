export const dashboardQueryKeys = {
  WIDGET_VIEW: "widget-view",
  WIDGET_SELECT: "widget-select",
  COMPANY_WIDGETS_SELECT: "company-widgets-select",
  COMPANY_WIDGETS: "company-widgets",
  COMPANY_PAYMENT_RADAR: "company-payment-radar",
  ONE_COMPANY_WIDGETS: "company-widgets-widgets",
  COMPANY_WAREHOUSE_DEFECT_COUNT: "company-warehouse-defect-count",
  PAYMENT_CARD_DETAILED: "payment-card-detailed",
  PAYMENT_CARD_DETAILED_BOTTOM: "payment-card-detailed-bottom",
  PROJECT_ACTIVITY_CALENDAR: "project-activity-calendar",
  COMPANY_PERSON_CARD_TABLE: "company-person-card-table",
  PROJECT_COLUMN_PROJECTS: "project-column-projects",
  PROJECT_COLUMN_SECTIONS: "project-column-sections",
  PROJECT_COLUMN_TASKS: "project-column-tasks",
  PROJECT_COLUMN_PRODUCT: "project-column-product",
  PROJECT_PER_AREA_PRICE: "project-per-area-price",
  PROJECT_SALE_PRICE: "project-sale-price",
  PROJECT_BY_CUSTOM_FIELD_PROJECTS: "project-by-custom-field-projects",
  TABLE_WIDGET_DATA: "table-widget-data",
  PRODUCT_ANALYZE: "product-analyze",
  WAREHOUSE_AMOUNT_DATA: "warehouse-amount-data",
  PROJECT_BY_RESOURCE_DATA_VIEW: "project-by-resource-data-view",
  PROJECT_BY_RESOURCE_DATA_TAB_COUNT: "project-by-resource-data-tab-count",
  PROJECT_BY_RESOURCE_DETAIL_DATA_VIEW: "project-by-resource-data-view",
  PROJECT_BY_RESOURCE_OTHERS_DETAIL_DATA_VIEW: "project-by-resource-others-data-view",
  PRODUCT_ALL: "dashboard-product-all",
  WAREHOUSE_DEFECT_BY_ID_VIEW: "warehouse-defect-view-by-id"
};
