import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Spin } from "antd";
import ActModal from "features/counterparts/components/detailed-counterparts/content/modals/act/ActModal";

import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { companyPersonActions } from "store/reducers/companyPersonReducer";

import PlusIcon from "../../../app/assets/icons/PlusIcon";
import ConditionalRender from "../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import NotData from "../../../app/components/not-data/NotData";
import { isEmptyArr } from "../../../app/utils/helpers/isEmptyArr";
import FolderPlusIcon from "../../../settings/assets/icons/FolderPlusIcon";
import CounterpartsFolders from "../../components/counterparts/content/folders/CounterpartsFolders";
import CounterpartsStatistics from "../../components/counterparts/content/statistics/CounterpartsStatistics";
import CounterpartsTable from "../../components/counterparts/content/table/CounterpartsTable";
import CounterpartsModal from "../../components/counterparts/modal/CounterpartsModal";
import {
  useGetCompanyPersonAmountStatistics,
  useGetCompanyPersonFolders,
  useGetCompanyPersons
} from "../../service/queries";
import { companyPersonSubmodules } from "../../utils/constants/routeSubmodules";

import emptyImage from "../../assets/images/counterparts.png";

import styles from "./index.module.scss";

type Props = {
  isCounterpartsPage?: boolean;
};

const Index: React.FC<Props> = ({ isCounterpartsPage = true }) => {
  const dispatch = useDispatch();
  const { searchParams } = useQueryParams();
  const { setVisibleModal } = companyPersonActions;
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const { actions } = useRoutePermissions("Kontragent", companyPersonSubmodules);
  const folderActions = actions("Papkalar");
  const routeCompanyPersonActions = actions("Kontragentlar");

  const { data: statistics, isLoading: isLoadingStatistics } = useGetCompanyPersonAmountStatistics();
  const { data: persons, isLoading: isLoadingPersons } = useGetCompanyPersons(!isCounterpartsPage);
  const { data: folders, isLoading: isLoadingFolders } = useGetCompanyPersonFolders();

  const onCreateFolder = () => {
    setIsCreating(true);
  };

  const onOpenModal = () => {
    dispatch(
      setVisibleModal({
        visible: true
      })
    );
  };

  const isEmptyTable = () => isEmptyArr(folders) && isEmptyArr(persons?.data);

  return (
    <div>
      <Spin indicator={LoadingIndicator} spinning={isLoadingFolders || isLoadingPersons || isLoadingStatistics}>
        <div
          className={styles.counterparts}
          style={{
            padding: isCounterpartsPage ? "1.25rem 1rem" : "1.25rem 0"
          }}
        >
          <ConditionalRender if={isCounterpartsPage}>
            <CounterpartsStatistics data={statistics} />
          </ConditionalRender>
          <ConditionalRender
            if={
              ((!isEmptyTable() || isCreating) && routeCompanyPersonActions?.view && isCounterpartsPage) || searchParams
            }
          >
            <CounterpartsFolders
              folders={folders}
              persons={persons?.data}
              isCreating={isCreating}
              isLoading={false}
              setIsCreating={setIsCreating}
              folderActions={folderActions}
            />
          </ConditionalRender>
          <ConditionalRender if={(!isEmptyTable() && routeCompanyPersonActions?.view) || searchParams}>
            <CounterpartsTable
              data={persons}
              isLoading={isLoadingPersons}
              folderActions={folderActions}
              isCounterpartsPage={isCounterpartsPage}
              key="counterparts_itself"
            />
          </ConditionalRender>
        </div>
        <ConditionalRender if={!isCreating && !searchParams && isEmptyArr(folders) && isEmptyArr(persons?.data)}>
          <Spin spinning={isLoadingFolders} indicator={LoadingIndicator}>
            <NotData
              name={{ text: "Kontragentlar mavjud emas" }}
              containerClassName={styles.empty__container}
              img={{
                src: emptyImage,
                className: styles.empty_image
              }}
              text={{
                text: "Bu yerda kontragentlarni qo’shish orqali kontragentlaringizni boshqaring"
              }}
              defaultButton={{
                text: "Papka yaratish",
                onClick: onCreateFolder,
                icon: <FolderPlusIcon />
              }}
              primaryButton={{
                icon: <PlusIcon />,
                onClick: onOpenModal,
                text: "Kontragent qo'shish",
                className: styles.primary__button
              }}
            />
          </Spin>
        </ConditionalRender>
        <CounterpartsModal />
        <ActModal />
      </Spin>
    </div>
  );
};

export default Index;
