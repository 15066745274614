import React, { useEffect, useState } from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import { Collapse } from "antd";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";

import { useAppSelector } from "hooks/redux";

import { useAuth } from "modules/auth/hooks";
import { colors, isEmptyArr } from "modules/common";

import { DatePicker, Textarea } from "components";

import { CompanyPersonForm } from "../../schema";

import Balance from "./balance";
import Folder from "./folder";
import Type from "./type";

import styles from "./index.module.scss";

type Props = {
  control: Control<CompanyPersonForm>;
  setValue: UseFormSetValue<CompanyPersonForm>;
};

const Additional: React.FC<Props> = ({ control, setValue }) => {
  const { baseCurrency } = useAuth();
  const { balances } = useWatch({ control });
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const { visible } = useAppSelector(({ partyReducer }) => partyReducer.createCompanyPerson);

  const onChange = (e: string | string[]) => {
    setActiveKey(e as string[]);

    if (typeof e === "object" && !isEmptyArr(e) && !balances) {
      setValue("balances", [
        {
          start_amount: undefined,
          currency_id: baseCurrency?.id
        }
      ]);
    } else {
      setValue("balances", undefined);
    }
  };

  useEffect(() => {
    if (!visible) {
      setActiveKey([]);
    }
  }, [visible]);

  return (
    <Collapse
      bordered={false}
      onChange={onChange}
      activeKey={activeKey}
      expandIconPosition="right"
      rootClassName={styles.collapse}
      expandIcon={({ isActive }) => <ChevronDownIcon rotate={isActive} stroke={colors.PRIMARY} />}
      items={[
        {
          key: "1",
          label: <div className="flex items-center text-sm font-medium text-primary-500">Qo'shimcha ma'lumotlar</div>,
          children: (
            <div className="flex flex-col gap-4">
              <Type control={control} />
              <Folder control={control} />
              <Balance control={control} setValue={setValue} />
              <DatePicker
                name="start_date"
                control={control}
                label="Balans sanasi"
                placeholder="Balans sanasini tanlang"
              />
              <Textarea name="description" control={control} label="Izoh" placeholder="Izoh kiriting" />
            </div>
          )
        }
      ]}
    />
  );
};

export default Additional;
