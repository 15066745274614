import React from "react";
import { FieldArrayWithId, UseFieldArrayRemove } from "react-hook-form";
import { Spin, Tooltip } from "antd";

import { cx, isEmptyArr } from "modules/common";
import { PartyForm } from "modules/party/create";
import { useDeleteProducts } from "modules/party/view";

import { LoadingIndicator, TrashIcon } from "components";

type Props = {
  index: number;
  isUpdate?: boolean;
  remove: UseFieldArrayRemove;
  fields: FieldArrayWithId<PartyForm, "company_person_group", "group_id">[];
};

const DeletePerson: React.FC<Props> = ({ fields, isUpdate, index, remove }) => {
  const deleteProducts = useDeleteProducts();

  const onDelete = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    const ids = fields?.[index]?.products?.filter(({ id }) => id)?.map(({ id }) => id!);

    if (isUpdate && !isEmptyArr(ids)) {
      deleteProducts.mutateAsync(ids).then(() => {
        remove(index);
      });
    } else {
      remove(index);
    }
  };

  return (
    <Spin spinning={deleteProducts.isLoading} indicator={LoadingIndicator}>
      <Tooltip arrow={false} title="O'chirish" placement="top">
        <div
          onClick={e => onDelete(e, index)}
          className={cx("flex cursor-pointer items-center justify-center", "warehouse-group-delete")}
        >
          <TrashIcon />
        </div>
      </Tooltip>
    </Spin>
  );
};

export default DeletePerson;
