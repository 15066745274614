import React from "react";
import { Spin } from "antd";

import { Form } from "modules/party/update";
import { PartyView } from "modules/party/view";

import Left from "pages/party/create/content/left";
import Right from "pages/party/create/content/right";
import Footer from "pages/party/create/footer";
import Title from "pages/party/update/title";

import { LoadingIndicator } from "components";

type Props = {
  party: PartyView;
  isLoading: boolean;
};

const Index: React.FC<Props> = ({ isLoading, party }) => (
  <Spin spinning={isLoading} indicator={LoadingIndicator}>
    <Form key={party?.id} party={party}>
      {form => (
        <>
          <div className="flex h-full flex-col">
            <Title form={form} party={party} />
            <div className="flex flex-1 overflow-hidden">
              <Left isUpdate form={form} />
              <Right isUpdate form={form} />
            </div>
            <Footer isUpdate form={form} status={party?.status} />
          </div>
        </>
      )}
    </Form>
  </Spin>
);

export default Index;
