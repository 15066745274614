import React from "react";
import { useDispatch } from "react-redux";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { setAdditionalItem } from "modules/warehouse/create-transfer";

const Description: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { additional, warehouses } = useAppSelector(({ warehouseCreateTransfer }) => warehouseCreateTransfer);
  const { id } = additional;

  const value = warehouses?.find(({ recieved_warehouse_id }) => recieved_warehouse_id === id)?.description;

  const onDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      setAdditionalItem({
        id,
        key: "description",
        value: e.target.value
      })
    );
  };

  return (
    <div className="flex flex-col gap-1.5">
      <label htmlFor="received_warehouse_date" className="text-sm font-medium text-gray-500">
        {t("warehouseTransfer.Izoh")}
      </label>
      <Input.TextArea
        placeholder={t("warehouseTransfer.Kiriting")}
        onChange={onDescription}
        id="received_warehouse_date"
        value={value}
      />
    </div>
  );
};

export default Description;
