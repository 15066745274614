import WorksTabEnums from "./worksTabEnums";

const worksStatsInfoData: Record<
  WorksTabEnums,
  {
    name: string;
    labels: {
      name: string;
      key: string;
      extraText?: string;
      percent?: string;
    }[];
  }
> = {
  [WorksTabEnums.LIST_OF_WORKS]: {
    name: "Ishlar ro'yxati",
    labels: [
      {
        name: "Ochiq",
        key: "open",
        percent: "open_percent"
      },
      {
        name: "Jarayonda",
        key: "in_progress",
        percent: "in_progress_percent"
      },
      {
        name: "Yakunlangan",
        key: "finished",
        percent: "finished_percent"
      },
      {
        name: "Yopilgan",
        key: "closed",
        percent: "closed_percent"
      }
    ]
  },
  [WorksTabEnums.PROGRESSES]: {
    name: "Bajarilgan ishlar",
    labels: [
      {
        name: "Barchasi",
        key: "total_progress_works",
        extraText: "ta"
      },
      {
        name: "Tasdiqlangan",
        key: "total_progress",
        extraText: "ta"
      },
      {
        name: "Rad etilgan",
        key: "average_work_progress",
        extraText: "ta"
      },
      {
        name: "Kutilmoqda",
        key: "total_progress_sections",
        extraText: "ta"
      }
    ]
  },
  [WorksTabEnums.SPENT_OF_RESOURCES]: {
    name: "Resurslar sarfi",
    labels: [
      {
        name: "Jami resurs",
        key: "total_resource_amount"
      },
      {
        key: "person_resource_spent_amount",
        name: "Inson resursi"
      },
      {
        name: "Mexanizm resurs sarfi",
        key: "mechanism_resource_spent_amount"
      },
      {
        name: "Mahsulot resurs sarfi",
        key: "product_resource_spent_amount"
      }
    ]
  },
  [WorksTabEnums.PROCESS]:{
    name:"Jarayon",
    labels:[
      {
        name: "Jami resurs",
        key: "total_resource_amount"
      },
      {
        key: "person_resource_spent_amount",
        name: "Inson resursi"
      },
      {
        name: "Mexanizm resurs sarfi",
        key: "mechanism_resource_spent_amount"
      },
      {
        name: "Mahsulot resurs sarfi",
        key: "product_resource_spent_amount"
      }
    ]
  }
};

export default worksStatsInfoData;
