import React from "react";
import i18n from "i18next";

import { getPopupContainer } from "modules/party/create";
import { ProductFormControl } from "modules/party/create-product";
import { useResources } from "modules/party/resource";

import { Select } from "components";

type Props = {
  isUpdate?: boolean;
  control: ProductFormControl;
};

const Resource: React.FC<Props> = ({ control, isUpdate }) => {
  const { resources } = useResources();

  return (
    <Select
      withoutMessage
      showSearch={false}
      name="resource_id"
      control={control}
      label="Resurs turi"
      placeholder="Mahsulot resursini tanlang"
      getPopupContainer={getPopupContainer(isUpdate ? "update-product" : "create-product")}
      options={resources?.map(resource => ({
        key: resource?.id,
        value: resource?.id,
        label: resource?.name?.[i18n.language]
      }))}
    />
  );
};

export default Resource;
