import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";
import dayjs, { Dayjs } from "dayjs";
import CustomFieldIcon from "features/app/assets/icons/CustomFieldIcon";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";
import { useChangeTableConfig } from "features/app/service/mutation";
import { colors } from "features/app/utils/constants/colors";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { CustomFieldTypesEnum } from "features/app/utils/enums/customFieldTypesEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import ToggleRightIcon from "features/payment/assets/icons/ToggleRightIcon";
import { useExportPaymentExcel } from "features/payment/service/mutation";
import { generatePaymentReqColumns } from "features/payment/utils/helpers/generatePaymentReqColumns";
import ArrowSquareDownIcon from "features/settings/assets/icons/ArrowSquareDownIcon";
import TagIcon from "features/settings/assets/icons/TagIcon";
import { paymentIncomeOrExpenseTableDefaultData } from "features/supply/utils/constants/paymentIncomeOrExpenseTableDefaultData";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { ConditionalRender, UyqurRectangleIcon, UysotRectangleIcon } from "components";

import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import FilterDateRange from "../../../../../app/components/filter-drawer/filter-date-range/FilterDateRange";
import FilterSelect from "../../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FilterDrawer from "../../../../../app/components/filter-drawer/FilterDrawer";
import FiltersCount from "../../../../../app/components/filter-drawer/filters-count/FiltersCount";
import {
  useGetCompanyPersons,
  useGetCustomFieldSelect,
  useGetTableConfigs,
  useGetUsersSelect,
  useProjectSelect
} from "../../../../../app/service/queries";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { PaymentStatusEnums } from "../../../../../app/utils/enums/paymentStatusEnums";
import { generateExcelPayload } from "../../../../../app/utils/helpers/generateExcelPayload";
import ZapIcon from "../../../../../projects/assets/icons/ZapIcon";
import { useGetPaymentTypeSelect } from "../../../../../settings/service/queries";
import FilterLinesIcon from "../../../../../warehouse/assets/icons/FilterLinesIcon";
import BuildingIcon from "../../../../assets/icons/BuildingIcon";
import CreditCardDownloadIcon from "../../../../assets/icons/CreditCardDownloadIcon";
import CreditCardIcon from "../../../../assets/icons/CreditCardIcon";
import CreditCardUploadIcon from "../../../../assets/icons/CreditCardUploadIcon";
import RefreshCwIcon from "../../../../assets/icons/RefreshCwIcon";
import SmallArrowNarrowDownLeftIcon from "../../../../assets/icons/SmallArrowNarrowDownLeftIcon";
import SmallArrowNarrowUpRightIcon from "../../../../assets/icons/SmallArrowNarrowUpRightIcon";
import SwitchHorizontalIcon from "../../../../assets/icons/SwitchHorizontalIcon";
import UsersIcon from "../../../../assets/icons/UsersIcon";
import UserSquareIcon from "../../../../assets/icons/UserSquareIcon";
import WalletIcon from "../../../../assets/icons/WalletIcon";
import { useGetFinancialSelect } from "../../../../service/queries";

import styles from "./paymentFilterDrawer.module.scss";

const queryStringArr = [
  queryParamsKeys.TYPE,
  queryParamsKeys.PAYMENT_TYPE_IDS,
  queryParamsKeys.PROJECT_IDS,
  queryParamsKeys.INCOME_FINANCIAL_IDS,
  queryParamsKeys.EXPENSE_FINANCIAL_IDS,
  queryParamsKeys.USER_IDS,
  queryParamsKeys.COMPANY_PERSON_IDS,
  queryParamsKeys.STATUSES,
  queryParamsKeys.MAX_DATE,
  queryParamsKeys.MIN_DATE,
  queryParamsKeys.FILTER_TYPE,
  queryParamsKeys.CUSTOM_FIELDS,
  queryParamsKeys.INTEGRATION_PAYMENTS
];

type Props = {
  isCounterParts?: boolean;
};

const PaymentFilterDrawerPayment: React.FC<Props> = ({ isCounterParts }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { queries, necessaryQueries } = useQueryParams();
  const { min_date, max_date } = queries();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [typesSelected, setTypesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {})[queryParamsKeys.TYPE] as string[] | null
  );
  const [paymentTypesSelected, setPaymentTypesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PAYMENT_TYPE_IDS] as string[] | null
  );

  const [projectsSelected, setProjectsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );

  const [financialSelected, setFinancialSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.INCOME_FINANCIAL_IDS] as string[] | null
  );

  const [financialExpenseSelected, setFinancialExpenseSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.EXPENSE_FINANCIAL_IDS] as string[] | null
  );

  const [companyPersonsSelected, setCompanyPersonsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.COMPANY_PERSON_IDS] as string[] | null
  );

  const [usersSelected, setUsersSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.USER_IDS] as string[] | null
  );

  const [statusesSelected, setStatusesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.STATUSES] as string[] | null
  );

  const [integrationsSelected, setIntegrationsSelected] = useState<string[] | null>([]);

  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.PAYMENT]);
  const changeTableConfiguration = useChangeTableConfig(
    isCounterParts ? tableConfigKeys.COUNTERPARTS_INCOME_OR_EXPENSE : tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE,
    true,
    true
  );

  const [customFieldStates, setCustomFieldStates] = useState<{ [key: string]: string[] | null }>({});

  const selectableCustomFields = customFields?.filter(
    customField => customField?.type === "select" || customField?.type === "label"
  );

  const handleCustomFieldChange = (id: string, value: string[] | null) => {
    setCustomFieldStates(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const aggregatedCustomFieldsSelected = useMemo(() => Object.values(customFieldStates).flat(), [customFieldStates]);

  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([
    min_date ? dayjs(min_date, dayjsFormats.DATE) : null,
    max_date ? dayjs(max_date, dayjsFormats.DATE) : null
  ]);

  console.log(dateValue);
  
  const { data: paymentTypes } = useGetPaymentTypeSelect({ enabled: open });
  const { data: projects } = useProjectSelect({ enabled: open });
  const { data: financialIncome } = useGetFinancialSelect(open, PaymentTypeEnum.INCOME);
  const { data: financialExpense } = useGetFinancialSelect(open, PaymentTypeEnum.EXPENSE);
  const { data: adminsSelect } = useGetUsersSelect(open);
  const { data: companyPersonsSelect } = useGetCompanyPersons(open);
  const exportFile = useExportPaymentExcel();
  const { data: tableConfigData, isFetching } = useGetTableConfigs(
    isCounterParts ? tableConfigKeys.COUNTERPARTS_INCOME_OR_EXPENSE : tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE,
    paymentIncomeOrExpenseTableDefaultData
  );

  const admins = useMemo(() => adminsSelect?.map(item => ({ name: item?.full_name, ...item })), [adminsSelect]);

  const companyPersons = useMemo(() => companyPersonsSelect?.map(item => ({ ...item })), [companyPersonsSelect]);

  const paymentTypeData = useMemo(
    () => [
      {
        id: PaymentTypeEnum.INCOME,
        name: `${t("Counterparts.Kirim")}`,
        icon: <SmallArrowNarrowDownLeftIcon />
      },
      {
        id: PaymentTypeEnum.EXPENSE,
        name: `${t("Counterparts.Chiqim")}`,
        icon: <SmallArrowNarrowUpRightIcon />
      },
      {
        id: PaymentTypeEnum.TRANSFER,
        name: `${t("Counterparts.O'tkazma")}`,
        icon: <SwitchHorizontalIcon status={PaymentStatusEnums.ACTIVE} />
      },
      {
        id: PaymentTypeEnum.EXCHANGE,
        name: `${t("Counterparts.Konvertatsiya")}`,
        icon: <RefreshCwIcon />
      }
    ],
    []
  );

  const statusesData = useMemo(
    () => [
      {
        id: PaymentStatusEnums.PASSIVE,
        name: `${t("Counterparts.Qoralama")}`
      },
      {
        id: PaymentStatusEnums.ACTIVE,
        name: `${t("Counterparts.Tasdiqlangan")}`
      },
      {
        id: PaymentStatusEnums.CANCEL,
        name: `${t("Counterparts.Rad etilgan")}`
      }
    ],
    []
  );

  const onOpenFilter = () => {
    setOpen(true);
  };

  const onExportFile = () => {
    const otherParamsData = necessaryQueries(
      queryParamsKeys.SEARCH,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.CASH_IDS,
      queryParamsKeys.SORT_DATE,
      queryParamsKeys.SORT_AMOUNT,
      queryParamsKeys.SORT_CASH_NAME,
      queryParamsKeys.SORT_PROJECT_NAME,
      queryParamsKeys.SORT_FINANCIAL_NAME,
      queryParamsKeys.COUNTERPARTS_DETAILED_SEARCH,
      queryParamsKeys.INTEGRATION_PAYMENTS
    );

    const filteredColumns = generatePaymentReqColumns(
      filterColumns({
        customFields,
        tableConfig: tableConfigData
      })
    );

    const data = filteredColumns
      ?.map(value => ({
        key: String(value?.id),
        name: value?.title
      }))
      ?.filter(item => typeof item?.name !== "object");

    exportFile.mutate(
      generateExcelPayload({
        ...otherParamsData,
        columns: data,
        user_ids: usersSelected,
        project_ids: projectsSelected,
        financial_ids: [...(financialSelected || []), ...(financialExpenseSelected || [])],
        payment_type_ids: paymentTypesSelected,
        company_person_ids: companyPersonsSelected,
        filter_type: typesSelected,
        statuses: statusesSelected,
        integration_payments: integrationsSelected,
        min_date: dateValue?.[0] ? dayjs(dateValue[0], dayjsFormats.DATE).format(dayjsFormats.DATE) : undefined,
        max_date: dateValue?.[1] ? dayjs(dateValue[1], dayjsFormats.DATE).format(dayjsFormats.DATE) : undefined
      })
    );
  };

  const onAfterSubmit = (filter?: string) => {
    changeTableConfiguration.mutate({
      filter: filter!,
      key: isCounterParts ? tableConfigKeys.COUNTERPARTS_INCOME_OR_EXPENSE : tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE
    });
  };

  const extraContent = (
    <Button className={styles.downloadBtn} onClick={onExportFile} loading={exportFile.isLoading}>
      <DownloadIcon />
      {t("Counterparts.Yuklab olish")}
    </Button>
  );

  const customFieldIcons: Record<string, React.ReactNode> = {
    [CustomFieldTypesEnum.SELECT]: <ArrowSquareDownIcon />,
    [CustomFieldTypesEnum.LABEL]: <TagIcon />
  };

  const searchParams = queryString.parse(location.search, { arrayFormat: "bracket" });
  const fieldValues = searchParams[queryParamsKeys.CUSTOM_FIELDS];

  useEffect(() => {
    if (selectableCustomFields && open) {
      const initialCustomFieldStates: { [key: string]: string[] | null } = {};

      selectableCustomFields.forEach(field => {
        if (fieldValues) {
          const valuesArray = Array.isArray(fieldValues) ? fieldValues : [fieldValues];

          initialCustomFieldStates[field.id] = valuesArray.filter(value =>
            field?.custom_field_options?.some(option => String(option.id) === value)
          ) as string[];
        } else {
          initialCustomFieldStates[field.id] = [];
        }
      });

      setCustomFieldStates(initialCustomFieldStates);
    }
  }, [open]);

  useEffect(() => {
    if (tableConfigData?.filter && tableConfigData?.filter?.length > 0) {
      const searchParams = new URLSearchParams(window.location.search);

      const filterParams = new URLSearchParams(tableConfigData.filter);

      filterParams.forEach((value, key) => {
        const existingValues = searchParams.getAll(key);

        if (!existingValues.includes(value)) {
          searchParams.append(key, value);
        }
      });

      const newSearchParams = searchParams.toString();

      navigate({
        search: `?${newSearchParams}`
      });
    }
  }, [isFetching, navigate, isCounterParts]);

  const parseQueryParams = (key: string) =>
    queryString.parse(window.location.search, { arrayFormat: "bracket" })[key] as string[] | null;
  const parseSingleQueryParams = (key: string) =>
    queryString.parse(window.location.search, { arrayFormat: "bracket" })[key] as string;

  useEffect(() => {
    setTypesSelected(parseQueryParams(queryParamsKeys.FILTER_TYPE));
    setPaymentTypesSelected(parseQueryParams(queryParamsKeys.PAYMENT_TYPE_IDS));
    setFinancialSelected(parseQueryParams(queryParamsKeys.INCOME_FINANCIAL_IDS));
    setFinancialExpenseSelected(parseQueryParams(queryParamsKeys.EXPENSE_FINANCIAL_IDS));
    setUsersSelected(parseQueryParams(queryParamsKeys.USER_IDS));
    setProjectsSelected(parseQueryParams(queryParamsKeys.PROJECT_IDS));
    setStatusesSelected(parseQueryParams(queryParamsKeys.STATUSES));
    setCompanyPersonsSelected(parseQueryParams(queryParamsKeys.COMPANY_PERSON_IDS));
    setDateValue([
      parseSingleQueryParams(queryParamsKeys.MIN_DATE) as never,
      parseSingleQueryParams(queryParamsKeys.MAX_DATE) as never
    ]);
    setIntegrationsSelected(parseQueryParams(queryParamsKeys.INTEGRATION_PAYMENTS));
  }, [window.location.search, isCounterParts]);

  return (
    <FilterDrawer
      onAfterSubmit={onAfterSubmit}
      onAfterClear={onAfterSubmit}
      open={open}
      setOpen={setOpen}
      footerContent={extraContent}
      filterButton={
        <Button onClick={onOpenFilter}>
          <FilterLinesIcon />
          {t("Counterparts.Filter")}
          <FiltersCount queryStringArr={queryStringArr} />
        </Button>
      }
      height="max-content"
      queryStringArr={queryStringArr}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.FILTER_TYPE,
          selectedOptions: typesSelected
        },
        {
          queryKey: queryParamsKeys.PAYMENT_TYPE_IDS,
          selectedOptions: paymentTypesSelected
        },
        {
          queryKey: queryParamsKeys.INCOME_FINANCIAL_IDS,
          selectedOptions: financialSelected
        },
        {
          queryKey: queryParamsKeys.EXPENSE_FINANCIAL_IDS,
          selectedOptions: financialExpenseSelected
        },
        {
          queryKey: queryParamsKeys.COMPANY_PERSON_IDS,
          selectedOptions: companyPersonsSelected
        },
        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectsSelected
        },

        {
          queryKey: queryParamsKeys.USER_IDS,
          selectedOptions: usersSelected
        },
        {
          queryKey: queryParamsKeys.STATUSES,
          selectedOptions: statusesSelected
        },
        {
          queryKey: queryParamsKeys.CUSTOM_FIELDS,
          selectedOptions: aggregatedCustomFieldsSelected as string[]
        },
        {
          queryKey: queryParamsKeys.INTEGRATION_PAYMENTS,
          selectedOptions: integrationsSelected
        }
      ]}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_DATE,
          selectedValue: dateValue && dateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_DATE,
          selectedValue: dateValue && dateValue[1]
        }
      ]}
      setDateValue={[setDateValue]}
      setSelectedOptionsArr={
        [
          setTypesSelected,
          setPaymentTypesSelected,
          setFinancialSelected,
          setFinancialExpenseSelected,
          setCompanyPersonsSelected,
          setProjectsSelected,
          setUsersSelected,
          setStatusesSelected,
          setCustomFieldStates,
          setIntegrationsSelected
        ] as never
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2 text-base font-medium text-gray-800">
            <FilterLinesIcon stroke={colors.GRAY_800} /> {t("Counterparts.Umumiy filterlar")}
          </div>
          <div className={styles.content}>
            <FilterSelect
              allowClear
              selectedOptions={typesSelected}
              setSelectedOptions={setTypesSelected}
              data={paymentTypeData}
              icon={<CreditCardIcon />}
              placeholder={t("Counterparts.Manbaa")}
            />
            <FilterSelect
              selectedOptions={paymentTypesSelected}
              setSelectedOptions={setPaymentTypesSelected}
              data={paymentTypes}
              multiple="multiple"
              icon={<WalletIcon />}
              placeholder={t("Counterparts.To’lov turlari")}
              showSearch
            />
            <FilterTreeSelect
              selectedOptions={projectsSelected}
              setSelectedOptions={setProjectsSelected}
              data={projects}
              multiple={true}
              icon={<BuildingIcon />}
              placeholder={t("Counterparts.Loyihalar")}
              showSearch
            />
            <FilterSelect
              selectedOptions={financialSelected}
              setSelectedOptions={setFinancialSelected}
              data={financialIncome}
              multiple="multiple"
              icon={<CreditCardDownloadIcon />}
              placeholder={t("Counterparts.Kirim turlari")}
              showSearch
            />
            <FilterSelect
              selectedOptions={financialExpenseSelected}
              setSelectedOptions={setFinancialExpenseSelected}
              data={financialExpense}
              multiple="multiple"
              icon={<CreditCardUploadIcon />}
              placeholder={t("Counterparts.Chiqim turlari")}
              showSearch
            />
            <FilterSelect
              showSearch
              selectedOptions={companyPersonsSelected}
              setSelectedOptions={setCompanyPersonsSelected}
              data={companyPersons}
              multiple="multiple"
              icon={<UserSquareIcon />}
              placeholder={t("Counterparts.Kontragentlar")}
            />
            <FilterSelect
              selectedOptions={usersSelected}
              setSelectedOptions={setUsersSelected}
              data={admins}
              multiple="multiple"
              icon={<UsersIcon />}
              placeholder={t("Counterparts.Xodimlar")}
              className={styles.admins__select}
              showSearch
            />
            <FilterSelect
              showSearch
              icon={<ZapIcon />}
              data={statusesData}
              multiple="multiple"
              selectedOptions={statusesSelected}
              placeholder={t("Counterparts.Holati")}
              setSelectedOptions={setStatusesSelected}
            />
            <FilterDateRange value={dateValue} setValue={setDateValue} />
            <FilterSelect
              multiple="multiple"
              placeholder={t("Counterparts.To'lovlar")}
              selectedOptions={integrationsSelected}
              setSelectedOptions={setIntegrationsSelected}
              icon={<ToggleRightIcon />}
              data={[
                {
                  id: "uysot",
                  name: "Uysot",
                  icon: <UysotRectangleIcon />
                },
                {
                  id: "uyqur",
                  name: "Uyqur",
                  icon: <UyqurRectangleIcon />
                }
              ]}
            />
          </div>
        </div>
        <ConditionalRender if={selectableCustomFields?.length && selectableCustomFields?.length > 0}>
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-2 text-base font-medium text-gray-800">
              <CustomFieldIcon stroke={colors.GRAY_800} width={20} /> {t("Counterparts.O'zgaruvchi filterlar")}
            </div>
            <div className={styles.content}>
              {selectableCustomFields?.map(el => (
                <FilterSelect
                  key={el.id}
                  selectedOptions={customFieldStates[el.id]}
                  setSelectedOptions={value => handleCustomFieldChange(String(el.id), value)}
                  data={el.custom_field_options as never}
                  multiple="multiple"
                  icon={customFieldIcons[el.type]}
                  placeholder={el.name[i18n.language]}
                  showSearch
                  allowClear={false}
                />
              ))}
            </div>
          </div>
        </ConditionalRender>
      </div>
    </FilterDrawer>
  );
};

export default PaymentFilterDrawerPayment;
