import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { PartyResourceForm } from "modules/party/resource";

type Props = {
  children: (form: UseFormReturn<PartyResourceForm>) => React.ReactNode;
};

const Index: React.FC<Props> = ({ children }) => {
  const form = useForm<PartyResourceForm>({
    defaultValues: {
      products: []
    }
  });

  return <form className="h-full">{children(form)}</form>;
};

export default Index;
