import { WarehouseProduct } from "../schema";

type Total = {
  amount: number;
  currencyId: number;
  currencySymbol: string;
};

export function totalAmount(products: WarehouseProduct[]) {
  const total: Total[] = [];

  products?.forEach(item => {
    const findCurrencyIndex = total?.findIndex(i => i?.currencyId === item?.currency?.id);

    if (findCurrencyIndex < 0) {
      total.push({
        amount: item?.total_amount,
        currencyId: item?.currency?.id,
        currencySymbol: item?.currency?.symbol
      });
    } else {
      total[findCurrencyIndex] = {
        ...total[findCurrencyIndex],
        amount: total[findCurrencyIndex]?.amount + item?.total_amount
      };
    }
  });

  return total;
}
