import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import { PaginationType } from "features/app/utils/models/PaginationType";

import { dashboardQueryKeys, VatBalanceViewData } from "..";

interface Query {
  vatBalanceViewData: PaginationType<VatBalanceViewData[]>;
}

interface Params {
  id: number;
  page: number;
  enabled: boolean;
}

export function useVatBalanceViewData({ page, id, enabled }: Params) {
  const initialValue: Query = {
    vatBalanceViewData: { data: [], current_page: 1, total: 0 }
  };

  const { data = initialValue, ...arg } = useQuery<Query>(
    [dashboardQueryKeys.ONE_COMPANY_WIDGETS, id, page],
    async () => {
      const { data: baseData } = await $api.get(`company-widget/vat-balance/view?id=${id}&page=${page}&size=10`);

      // const data = paymentCardDetailedSchema.parse(baseData);

      return { vatBalanceViewData: baseData?.data };
    },
    { enabled: enabled && !!id }
  );

  return { ...data, ...arg };
}
