import React from "react";

const TruckIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6666 5.83337H13.6143C13.8182 5.83337 13.9201 5.83337 14.016 5.8564C14.101 5.87681 14.1823 5.91048 14.2569 5.95617C14.341 6.00771 14.413 6.07977 14.5572 6.2239L17.9428 9.60952C18.0869 9.75364 18.159 9.82571 18.2105 9.9098C18.2562 9.98436 18.2899 10.0657 18.3103 10.1507C18.3333 10.2466 18.3333 10.3485 18.3333 10.5523V12.9167C18.3333 13.305 18.3333 13.4991 18.2699 13.6523C18.1853 13.8565 18.0231 14.0187 17.8189 14.1033C17.6657 14.1667 17.4716 14.1667 17.0833 14.1667M12.9166 14.1667H11.6666M11.6666 14.1667V6.00004C11.6666 5.06662 11.6666 4.59991 11.485 4.24339C11.3252 3.92979 11.0702 3.67482 10.7566 3.51503C10.4001 3.33337 9.93338 3.33337 8.99996 3.33337H4.33329C3.39987 3.33337 2.93316 3.33337 2.57664 3.51503C2.26304 3.67482 2.00807 3.92979 1.84828 4.24339C1.66663 4.59991 1.66663 5.06662 1.66663 6.00004V12.5C1.66663 13.4205 2.41282 14.1667 3.33329 14.1667M11.6666 14.1667H8.33329M8.33329 14.1667C8.33329 15.5474 7.214 16.6667 5.83329 16.6667C4.45258 16.6667 3.33329 15.5474 3.33329 14.1667M8.33329 14.1667C8.33329 12.786 7.214 11.6667 5.83329 11.6667C4.45258 11.6667 3.33329 12.786 3.33329 14.1667M17.0833 14.5834C17.0833 15.734 16.1506 16.6667 15 16.6667C13.8494 16.6667 12.9166 15.734 12.9166 14.5834C12.9166 13.4328 13.8494 12.5 15 12.5C16.1506 12.5 17.0833 13.4328 17.0833 14.5834Z"
      stroke="#344054"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TruckIcon;
