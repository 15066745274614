import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import Pagination from "features/app/components/pagination/Pagination";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import TableSettings from "features/app/components/table-settings/TableSettings";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { parseParamsId } from "features/app/utils/helpers/parseParamsId";
import WorkModal from "features/projects/components/detailed-project/work/modal/WorkModal";
import { WorkModel } from "features/projects/utils/models/workModel";
import { useTranslation } from "react-i18next";

import { useDispatch } from "store";
import { projectsReducerActions } from "store/reducers/projectsReducer";

import { RU } from "modules/common";
import { listOfWorksTableConfig } from "modules/works";
import { useTaskView } from "modules/works/queries/use-task-view";

import { LoadingIndicator, Table } from "components";

import useDebounce from "../../../../../components/use-debounce/use-debounce";
import WorkStatuses from "../../statuses";

import { Actions } from "./actions";
import { DatePopover, WorkQuantityPopover } from "./popovers";
import Users from "./users";

import styles from "./list-of-works.module.scss";

const ListOfWorksTable: React.FC = () => {
  const { t, i18n } = useTranslation();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [tableColumns, setColumns] = useState<ColumnsType<WorkModel>>([]);
  const params = useParams();
  const { id: projectId } = parseParamsId(params?.projectId);

  const { taskView, isLoading } = useTaskView({ enabled: true, projectId: Number(projectId) });
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.LIST_OF_WORKS, listOfWorksTableConfig);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.SECTION]);
  const changeTableConfig = useChangeTableConfig(tableConfigKeys.LIST_OF_WORKS, true, false);

  const { setWork } = projectsReducerActions;
  const dispatch = useDispatch();

  const getUsersData = (record: WorkModel) => {
    if (
      record?.users &&
      record?.users?.length > 0 &&
      (record?.company_persons?.length === 0 || !record?.company_persons)
    ) {
      return record?.users?.map(el => ({
        id: el?.id,
        name: el?.full_name,
        image: el?.image
      }));
    }
    if (
      record?.company_persons &&
      record?.company_persons?.length > 0 &&
      (record?.users?.length === 0 || !record?.users)
    ) {
      return record?.company_persons?.map(el => ({
        id: el?.id,
        name: el?.name,
        image: ""
      }));
    }
    return [
      ...record.users.map(el => ({
        id: el?.id,
        name: el?.full_name,
        image: el?.image
      })),
      ...record.company_persons.map(el => ({
        id: el?.id,
        name: el?.name,
        image: ""
      }))
    ];
  };

  const columns: ColumnsType<WorkModel> = [
    {
      title: t("works.Ish nomi va bo'limi"),
      render: (_, record: WorkModel) => (
        <div className="flex flex-col">
          <div className="flex gap-1 text-sm font-medium text-gray-900">
            <span>{record?.place}</span>{" "}
            <span>
              <PopoverShortName title={record?.name} length={100} />
            </span>
          </div>
          <span className="text-xs font-normal text-gray-900">{record?.section?.name}</span>
        </div>
      ),
      width: 550,
      key: "work_name_and_section",
      className: "work_name_and_section",
      fixed: "left"
    },
    {
      title: t("works.Loyiha"),
      render: (_, record: WorkModel) => (
        <div className="text-ellipsis whitespace-nowrap text-sm font-normal text-gray-800">
          {record?.section?.project?.name}
        </div>
      ),
      width: 150,
      key: "work_section_project_name",
      className: "work_section_project_name"
    },
    {
      title: t("works.Ish tugash vaqti"),
      render: (_, record: WorkModel) => (
        <DatePopover
          plan_date={record?.due_date}
          fact_date={record?.completed_date}
          status={record?.status}
          diffDate={record?.difference_day}
        />
      ),
      width: 150,
      key: "due_date",
      className: "due_date"
    },
    {
      title: t("works.Ish jarayoni"),
      render: (_, record: WorkModel) => (
        <div className={styles.percent}>
          <p>{record?.task_percent}%</p>
          <div className={styles.percent__progress}>
            <div style={{ width: `${Number(record?.task_percent) <= 100 ? record?.task_percent : 100}%` }} />
            <div />
          </div>
        </div>
      ),
      width: 350,
      key: "work_progress",
      className: "work_progress"
    },
    {
      title: t("works.Ish hajmi"),
      render: (_, record: WorkModel) => (
        <WorkQuantityPopover
          quantity={Number(record?.task_progress_quantity)}
          done_quantity={Number(record?.quantity)}
          unit_symbol={record?.unit?.symbol[i18n.language]}
        >
          <div className="flex cursor-pointer items-center gap-1">
            <span className="text-sm font-normal text-gray-700">
              {Number.isInteger(record?.quantity)
                ? record?.quantity?.toLocaleString(RU)
                : Number(record?.quantity?.toFixed(2))?.toLocaleString(RU)}
            </span>
            <span className="text-sm font-normal text-gray-400">{record?.unit?.symbol[i18n.language]}</span>
          </div>
        </WorkQuantityPopover>
      ),
      width: 150,
      key: "work_task_progress_quantity",
      className: "work_task_progress_quantity"
    },
    {
      title: t("works.Holati"),
      render: (_, record: WorkModel) => <WorkStatuses status={record?.status} />,
      width: 100,
      key: "status",
      className: "status"
    },
    {
      title: <div className="w-full text-center">{t("works.Ma'sul xodim")}</div>,
      render: (_, record: WorkModel) => <Users users={getUsersData(record)} />,
      width: 200,
      key: "creator",
      className: "creator"
    }
  ];

  const footer = () => (
    <Pagination
      paginationProps={{
        current: taskView?.current_page,
        total: taskView?.total
      }}
    />
  );

  const onRow = (record: WorkModel) => ({
    onClick: () => {
      dispatch(
        setWork({
          visible: true,
          id: record.id
        })
      );
    }
  });

  const saveColumns = () => {
    const columnsToSave = tableColumns.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutate({
      key: tableConfigKeys.LIST_OF_WORKS,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<WorkModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useEffect(() => {
    if (tableConfig && customFields) {
      setColumns([
        ...filterColumns({ columns: columns as never, tableConfig, customFields }),
        {
          title: (
            <TableSettings
              defaultData={listOfWorksTableConfig}
              configKey={tableConfigKeys.LIST_OF_WORKS}
              locations={[CustomFieldLocationEnum.SECTION]}
              isNeedSize
            />
          ),
          render: (_, record: WorkModel) => <Actions record={record} />,
          width: 70,
          className: "list_of_works_action_dots",
          fixed: "right"
        }
      ]);
    }
  }, [tableConfig, customFields]);

  return (
    <div>
      <div className={styles.top}>
        <Table
          onChangeColumns={onChangeColumns}
          onRow={onRow}
          footer={footer}
          columns={tableColumns}
          pagination={false}
          dataSource={taskView?.data}
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{
            spinning: isLoading,
            indicator: LoadingIndicator
          }}
          rootClassName={styles.list_of_works_table}
          rowClassName={styles.list_of_works_table_row}
          scroll={{ y: "calc(100vh - 19.75rem)" }}
        />
      </div>
      <WorkModal />
    </div>
  );
};

export default ListOfWorksTable;
