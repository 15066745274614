import React from "react";
import { UseFormReturn } from "react-hook-form";

import { getPopupContainer, PartyForm } from "modules/party/create";
import { useWarehouses } from "modules/party/view";

import { Select, TooltipShortName } from "components";

type Props = {
  index: number;
  isUpdate?: boolean;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, index, isUpdate }) => {
  const { warehouses, isLoading } = useWarehouses();

  return (
    <Select
      withoutMessage
      suffixIcon={null}
      loading={isLoading}
      placeholder="Omborxona"
      control={form.control}
      wrapperClassName="h-full"
      popupMatchSelectWidth={false}
      errorClassName="party-create-error"
      name={`warehouse_products.${index}.warehouse_id`}
      getPopupContainer={getPopupContainer(isUpdate ? "party-left-content-update" : "party-left-content")}
      options={warehouses?.map(warehouse => ({
        key: warehouse?.id,
        value: warehouse?.id,
        label: (
          <TooltipShortName
            length={18}
            arrow={false}
            title={warehouse?.name as string}
            getPopupContainer={getPopupContainer("party-left-content")}
          />
        ),
        props: {
          name: warehouse?.name
        }
      }))}
    />
  );
};

export default Index;
