export function equalObjects<T extends object, D extends object>(obj1: T, obj2: D) {
  let isEqual = false;
  const firstObjKeys = Object.keys(obj1);

  for (const key of firstObjKeys) {
    if (obj2[key as never] === obj1[key as never] || (!obj2[key as never] && !obj1[key as never])) {
      isEqual = true;
    } else {
      isEqual = false;
      break;
    }
  }

  return isEqual;
}
