import { useQuery } from "@tanstack/react-query";

import { Currency } from "modules/common";

import $api from "../../../../features/app/utils/helpers/axiosInstance";
import { partyQueryKeys } from "../query-keys";

type Query = {
  currencies: Currency[];
};

export function useCurrencies() {
  const initialData = {
    currencies: []
  };

  const { data = initialData, ...arg } = useQuery<Query>(
    [partyQueryKeys.CURRENCIES],
    async () => {
      const { data } = await $api.get("company-currency/select");

      return { currencies: structuredClone(data?.data) };
    },
    {
      staleTime: Infinity
    }
  );

  return { ...data, ...arg };
}
