import React, { useMemo } from "react";
import { UseFieldArrayAppend, UseFieldArrayRemove, UseFormReturn, useWatch } from "react-hook-form";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

import { ProductWithUnit } from "modules/party/create";
import { PartyResourceForm } from "modules/party/resource";

type Props = {
  record: ProductWithUnit;
  remove: UseFieldArrayRemove;
  form: UseFormReturn<PartyResourceForm>;
  append: UseFieldArrayAppend<PartyResourceForm, "products">;
};

const CheckResource: React.FC<Props> = ({ remove, append, record, form }) => {
  const { products } = useWatch({ control: form.control });

  const onChange = (e: CheckboxChangeEvent) => {
    if (e.target?.checked) {
      append(record);
    } else {
      const findIndex = products?.findIndex(p => p?.id === record?.id);

      remove(findIndex);
    }
  };

  const checked = useMemo(() => products?.some(p => p?.id === record.id), [products]);

  return <Checkbox checked={checked} onChange={onChange} />;
};

export default CheckResource;
