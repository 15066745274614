import { UseFieldArrayAppend } from "react-hook-form";
import i18n from "i18next";
import { uid } from "uid";

import { PartyForm } from "modules/party/create";
import { PartyResourceForm } from "modules/party/resource";

export function appendToFromProducts(
  products: PartyResourceForm["products"],
  append: UseFieldArrayAppend<PartyForm, "check_products">,
  appendCheck: UseFieldArrayAppend<PartyForm, "warehouse_products">
) {
  products?.forEach(p => {
    append({
      code: p?.code,
      id: p?.id,
      name: p?.name,
      resource: p?.resource,
      unit: p?.unit
    });

    appendCheck({
      task_product_id: undefined,
      product_id: p?.id,
      unit_id: p?.unit?.id,
      unit_name: p?.unit?.symbol?.[i18n.language] || "",
      quantity: "0",
      amount: "0",
      // @ts-ignore
      warehouse_id: undefined,
      project_id: undefined,
      company_person_id: undefined,
      company_person_name: undefined,
      vat_id: undefined,
      vat_value: undefined,
      vat_amount: undefined,
      // @ts-ignore
      currency_id: undefined,
      // @ts-ignore
      currency_symbol: undefined,
      total: "0",
      rowId: uid()
    });
  });
}
