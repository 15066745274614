import { useSelector } from "store";

import { IAuth } from "../slice";

import { useLogout } from "./use-logout";

interface IReturn extends IAuth {
  isAuthenticated: boolean;
  methods: { logout(): void };
}

export const useAuth = (): IReturn => {
  const { logout } = useLogout();

  const user = useSelector(({ auth }) => auth.user)!;
  const isFetched = useSelector(({ auth }) => auth.isFetched);
  const permissions = useSelector(({ auth }) => auth.permissions);
  const accessToken = useSelector(({ auth }) => auth.accessToken);
  const currencies = useSelector(({ auth }) => auth.currencies);
  const baseCurrency = useSelector(({ auth }) => auth.baseCurrency);

  const methods = { logout };

  return { user, currencies, permissions, isFetched, isAuthenticated: !!user, accessToken, methods, baseCurrency };
};
