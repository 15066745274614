import React from "react";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import { handleLeftOrWentDays } from "features/app/utils/helpers/handleLeftOrWentDays";

import { cx, dayjsFormats } from "modules/common";

import styles from "./dateStatusWithPercent.module.scss";

type Props = {
  date: string;
  percent: number;
  className?: string;
  isTooltip?: boolean;
};

const DateStatusWithPercent: React.FC<Props> = ({ date, percent, className, isTooltip = true }) => {
  const dateStatusClass = () => {
    const diffDate = dayjs().diff(dayjs(date, dayjsFormats.DATE), "day");

    if (percent < 100) {
      if (diffDate >= 0) {
        return styles.delivery_date__error;
      }
      if (diffDate <= 3 && diffDate >= 1) {
        return styles.delivery__error_text;
      }
      if (diffDate > 3 && diffDate <= 7) {
        return styles.delivery_date__warning;
      }
      return styles.delivery__default_text;
    }

    return styles.delivery_date__success;
  };

  if (date && percent < 100) {
    if (isTooltip) {
      return (
        <Tooltip arrow={false} title={handleLeftOrWentDays(date)}>
          <div className={cx(dateStatusClass(), { [className || ""]: className })}>{date}</div>
        </Tooltip>
      );
    }
    return <div className={cx(dateStatusClass(), { [className || ""]: className })}>{date}</div>;
  }

  if (date && (percent === 100 || percent > 100)) {
    return <div className={cx(dateStatusClass(), { [className || ""]: className })}>{date}</div>;
  }

  return "-";
};

export default DateStatusWithPercent;
