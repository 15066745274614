import React, { useMemo, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { SelectProps } from "antd";
import { TimeoutModel } from "features/app/utils/models/TimeoutModel";
import i18n from "i18next";

import { useAppSelector } from "hooks/redux";

import { cx } from "modules/common";
import {
  generateProducts,
  generateSelectProducts,
  getPopupContainer,
  PartyForm,
  ProductEmpty,
  ProductWithUnit,
  TargetType,
  useProducts
} from "modules/party/create";

import { Select, TooltipShortName } from "components";

type Props = {
  index: number;
  isUpdate?: boolean;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, index, isUpdate }) => {
  const [search, setSearch] = useState("");
  const [time, setTime] = useState<TimeoutModel>();
  const { check_products } = useWatch({ control: form.control });
  const { pages, isLoading, fetchNextPage } = useProducts({ search });
  const { selectOrderProducts } = useAppSelector(({ supplyReducer }) => supplyReducer);
  const { selectOffers } = useAppSelector(({ supplyOfferReducer }) => supplyOfferReducer);

  const onChange = (e: number, props: any) => {
    form.setValue(`warehouse_products.${index}.product_id`, e);
    form.setValue(`warehouse_products.${index}.unit_id`, props?.props?.unit_id);
    form.setValue(`warehouse_products.${index}.unit_name`, props?.props?.unit_name);
  };

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;
    const scrollMath = target.scrollTop + target.offsetHeight;
    const { scrollHeight } = target;
    const resultScroll = Math.abs(scrollMath - scrollHeight);

    if (resultScroll <= 2 && resultScroll >= 0) {
      fetchNextPage();
    }
  };

  const products = useMemo(() => {
    let allProducts: ProductWithUnit[] = [];

    pages?.forEach(page => {
      allProducts = [
        ...allProducts,
        ...generateSelectProducts(page?.data, selectOrderProducts),
        ...generateSelectProducts(page?.data, selectOffers?.data as never),
        ...generateProducts(page?.data || [], check_products as ProductWithUnit[] | undefined)
      ];
    });

    return allProducts;
  }, [pages, search]);

  const onSearch: SelectProps["onSearch"] = value => {
    clearTimeout(time);

    setTime(
      setTimeout(() => {
        setSearch(value);
      }, 300)
    );
  };

  return (
    <div className="flex h-full items-center">
      <Select
        withoutMessage
        suffixIcon={null}
        loading={isLoading}
        onChange={onChange}
        onSearch={onSearch}
        control={form.control}
        wrapperClassName="h-full"
        autoClearSearchValue={false}
        popupMatchSelectWidth={false}
        onPopupScroll={onPopupScroll}
        errorClassName="party-create-error"
        name={`warehouse_products.${index}.product_id`}
        notFoundContent={<ProductEmpty name={search} isLoading={isLoading} fieldName={`warehouse_products.${index}`} />}
        getPopupContainer={getPopupContainer(isUpdate ? "party-left-content-update" : "party-left-content")}
        placeholder={
          <div className="flex items-center gap-2">
            <div className={cx("resource")}>
              <div className="border border-solid border-error-500 text-error-500">--</div>
            </div>
            <div className="text-sm font-normal text-gray-400">Resurs nomi</div>
          </div>
        }
        options={products?.map(product => ({
          key: product?.id,
          value: product?.id,
          label: (
            <div className="flex items-center gap-2">
              <div className={cx("resource")}>
                <div
                  className="border border-solid"
                  style={{
                    borderColor: product?.resource?.color,
                    color: product?.resource?.color
                  }}
                >
                  {product?.resource?.symbol?.[i18n.language]}
                </div>
              </div>
              <div className="text-sm font-medium text-gray-900">
                <TooltipShortName
                  length={33}
                  arrow={false}
                  title={product?.name?.[i18n.language]}
                  getPopupContainer={getPopupContainer("party-left-content")}
                />
              </div>
            </div>
          ),
          props: {
            name: product?.name?.[i18n.language],
            unit_id: product?.unit?.id,
            unit_name: product?.unit?.symbol?.[i18n.language]
          }
        }))}
      />
    </div>
  );
};

export default Index;
