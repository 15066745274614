import { isEmptyArr } from "modules/common";
import { PartyForm, PartyReqPayload } from "modules/party/create";

export function parseCustomFields(
  customFields: PartyForm["custom_field_values"]
): PartyReqPayload["custom_field_values"] {
  const result: PartyReqPayload["custom_field_values"] = [];

  customFields?.forEach(field => {
    if (field?.value) {
      const { value, custom_field_id } = field;

      if (typeof value === "boolean") {
        result.push({
          custom_field_id,
          value: custom_field_id
        });
      } else if (typeof value === "object" && !isEmptyArr(value)) {
        result.push({
          custom_field_id,
          value: value?.join(",")
        });
      } else {
        result.push({
          custom_field_id,
          value: value as string | number
        });
      }
    }
  });

  return result;
}
