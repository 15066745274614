import React from "react";
import { Drawer } from "antd";

import { useAppSelector } from "hooks/redux";

import { cx } from "modules/common";

import Content from "pages/party/create/content";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const { visible } = useAppSelector(({ partyReducer }) => partyReducer.create);
  const { visible: estimate } = useAppSelector(({ partyReducer }) => partyReducer.estimate);
  const { visible: resource } = useAppSelector(({ partyReducer }) => partyReducer.resource);

  return (
    <Drawer
      width="100vw"
      open={visible}
      footer={false}
      destroyOnClose
      rootClassName={cx(styles.create, {
        [styles.create_transform]: estimate || resource
      })}
    >
      <Content />
    </Drawer>
  );
};

export default Index;
