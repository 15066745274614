import { z } from "zod";

import { partyFormReqPayload } from "modules/party/create";

export const partyUpdateReqPayload = partyFormReqPayload.merge(
  z.object({
    id: z.number()
  })
);

export type PartyUpdateReqPayload = z.infer<typeof partyUpdateReqPayload>;
