import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Badge, Dropdown, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { ItemType } from "antd/lib/menu/interface";
import classNames from "classnames";
import { PaymentSourceElement } from "features/payment/components/bottom/income-expense-view/PaymentSourceElement";
import { useTranslation } from "react-i18next";

import { chatActions } from "../../../../../../store/reducers/chatReducer";
import DotsVerticalIcon from "../../../../../app/assets/icons/DotsVerticalIcon";
import CustomAvatar from "../../../../../app/components/custom-avatar/CustomAvatar";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import MessageIcon from "../../../../../inventory/assets/icons/MessageIcon";
import { useGetIncomeOrExpenseDelete } from "../../../../service/queries";
import { paymentQueryKeys } from "../../../../utils/constants/paymentQueryKeys";
import { PaymentTypeIcon } from "../../../../utils/helpers/paymentTypeIcon";
import { PaymentDeleteArchive } from "../../../../utils/models/paymentDeleteArchive";

import styles from "../deleteArchive.module.scss";

type Props = {
  type: PaymentTypeEnum;
};

const IncomeOrExpenseTable = ({ type }: Props) => {
  const { t } = useTranslation();
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const { setVisible: setVisibleChat } = chatActions;
  const [page, setPage] = useState<number>(1);

  const { data: incomeExpenseDeleteArchive, isLoading } = useGetIncomeOrExpenseDelete(page, true, type);

  const actionItems = (record: PaymentDeleteArchive) =>
    [
      {
        key: "2",
        label: (
          <>
            <MessageIcon /> Izohlar <Badge count={record?.unread_message_count} />
          </>
        ),
        className: styles.dropdown_item,
        onClick: e => {
          e.domEvent.stopPropagation();

          dispatch(
            setVisibleChat({
              visible: true,
              type: "payments",
              objectId: record?.id,
              dataKeys: [paymentQueryKeys.EXPECTED_PAYMENT_TEMPLATE_DELETED, page as unknown as string, type],
              record
            })
          );
        }
      }
    ] as ItemType[];

  const columns: ColumnsType<PaymentDeleteArchive> = [
    {
      title: t("payment.Turi"),
      render: (record: PaymentDeleteArchive) => PaymentTypeIcon(record.type, record?.status, t),
      width: 80
    },
    {
      title: t("payment.O’chirilgan sana"),
      render: (record: PaymentDeleteArchive) => record?.deleted_date
    },
    {
      title: t("payment.Umumiy summa"),
      render: (record: PaymentDeleteArchive) => record?.amount?.toLocaleString(RU)
    },
    {
      title: t("payment.Qoldiq summa"),
      render: (record: PaymentDeleteArchive) => record?.debt_amount?.toLocaleString(RU)
    },
    {
      title: t("payment.To’landi (foizda)"),
      render: (record: PaymentDeleteArchive) =>
        ((record?.amount / (record?.paid_amount === 0 ? 1 : record?.paid_amount)) * 100).toLocaleString(RU)
    },
    {
      title: t("payment.Manbaa"),
      render: (record: PaymentDeleteArchive) => <PaymentSourceElement record={record as never} />
    },
    {
      title: t("payment.Loyiha"),
      render: (record: PaymentDeleteArchive) => record.project?.name
    },
    {
      title: t("payment.Xodim"),
      render: (record: PaymentDeleteArchive) => (
        <div className={styles.actions}>
          <CustomAvatar image={record?.creator?.image} name={record?.creator?.full_name} />
        </div>
      ),
      width: 100
    },
    {
      title: "",
      render: (record: PaymentDeleteArchive) => (
        <Dropdown
          menu={{ items: actionItems(record) }}
          trigger={["click"]}
          overlayStyle={{ zIndex: 1000 }}
          placement="bottom"
        >
          <div
            onClick={e => e.stopPropagation()}
            className={classNames("d_f ai_c jc_c c_p", {
              hasBadge: !!record?.unread_message_count
            })}
          >
            <DotsVerticalIcon />
          </div>
        </Dropdown>
      ),
      width: 70
    }
  ];

  return (
    <>
      <Table<PaymentDeleteArchive>
        columns={columns}
        pagination={false}
        dataSource={incomeExpenseDeleteArchive?.data}
        rowKey={record => record.id}
        rowClassName={() => styles.row}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
        className={styles.table}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            pageSize: 10,
            total: incomeExpenseDeleteArchive?.total,
            current: incomeExpenseDeleteArchive?.current_page,
            onChange: page => {
              setPage(page);
            }
          }}
        />
      </div>
    </>
  );
};

export default IncomeOrExpenseTable;
