import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface InitialState {
  visible: boolean;
  title:string
}

export const initialState: InitialState = {
  visible: false,
  title:""
}

const activatingMessagingSystemsSlice = createSlice({
  name:'activatingMessagingSystems',
  initialState,
  reducers:{
    setOpenModal: (state, action: PayloadAction<{visible:boolean,title:string}>) => {
      state.visible = action.payload.visible;
      state.title = action.payload.title;
    }
  }
})

export default activatingMessagingSystemsSlice.reducer;
export const activatingMessagingSystemsActions = activatingMessagingSystemsSlice.actions;
