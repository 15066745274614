import React from "react";
import { useDispatch } from "react-redux";
import { UseFieldArrayAppend, UseFormReturn } from "react-hook-form";
import { Drawer } from "antd";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/reducers/partyReducer";

import { PartyForm } from "modules/party/create";
import { Wrapper } from "modules/party/estimate";

import Content from "pages/party/estimate/content";

import styles from "./index.module.scss";

type Props = {
  isUpdate?: boolean;
  parentForm: UseFormReturn<PartyForm>;
  append: UseFieldArrayAppend<PartyForm, "warehouse_products">;
  appendCheck: UseFieldArrayAppend<PartyForm, "check_products">;
  groupAppend: UseFieldArrayAppend<PartyForm, "company_person_group">;
};

const Index: React.FC<Props> = ({ appendCheck, append, isUpdate, groupAppend, parentForm }) => {
  const dispatch = useDispatch();
  const { setPartyEstimate } = partyReducerActions;
  const { visible } = useAppSelector(state => state.partyReducer.estimate);

  const onClose = () => {
    dispatch(
      setPartyEstimate({
        visible: false
      })
    );
  };

  return (
    <Drawer width="70rem" destroyOnClose open={visible} title={null} onClose={onClose} rootClassName={styles.drawer}>
      <Wrapper>
        {form => (
          <Content
            form={form}
            append={append}
            isUpdate={isUpdate}
            parentForm={parentForm}
            appendCheck={appendCheck}
            groupAppend={groupAppend}
          />
        )}
      </Wrapper>
    </Drawer>
  );
};

export default Index;
