import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

import { getPopupContainer, PartyForm, useProjects } from "modules/party/create";

import { TooltipShortName, TreeSelect } from "components";

type Props = {
  index: number;
  isUpdate?: boolean;
  parentIndex: number;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, index, parentIndex, isUpdate }) => {
  const { company_person_group } = useWatch({
    control: form.control
  });
  const { isLoading, projects } = useProjects();

  const onClear = () => {
    form.setValue(`company_person_group.${parentIndex}.products.${index}.project_id`, null);
  };

  return (
    <TreeSelect
      allowClear={true}
      suffixIcon={null}
      onClear={onClear}
      loading={isLoading}
      placeholder="Loyiha"
      control={form.control}
      popupMatchSelectWidth={false}
      name={`company_person_group.${parentIndex}.products.${index}.project_id`}
      value={company_person_group?.[parentIndex]?.products?.[index]?.project_id}
      getPopupContainer={getPopupContainer(isUpdate ? "party-left-content-update" : "party-left-content")}
      treeData={projects?.map(project => ({
        key: project?.id,
        value: project?.id,
        label: <TooltipShortName arrow={false} title={project?.name} zIndex={200497} length={12} />,
        props: {
          name: project?.name
        },
        children: project?.projects?.map(child => ({
          key: child?.id,
          value: child?.id,
          label: <TooltipShortName arrow={false} title={child?.name} zIndex={200497} length={12} />,
          props: {
            name: child?.name
          }
        }))
      }))}
    />
  );
};

export default Index;
