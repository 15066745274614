import React from "react";
import { UseFormReturn } from "react-hook-form";
import { UserSelectModel } from "features/app/utils/models/user/userSelectModel";
import { CompanyPersonModel } from "features/projects/utils/models/workModel";

import { AppendedEmployeesFormType } from "modules/append-employees";

import CompanyPersonsTabContent from "./company-persons-tab-content";
import UsersTabContent from "./users-tab-content";

type Props = {
  users: UserSelectModel[];
  company_persons: CompanyPersonModel[];
  search: string;
  form: UseFormReturn<AppendedEmployeesFormType>;
  tab: "counterparts" | "users";
};

const TabContent: React.FC<Props> = ({ search, users, company_persons, form, tab }) => (
  <div className="flex h-[350px] w-[94%] flex-col items-center gap-5 overflow-y-auto px-[10px]">
    {tab === "users" ? (
      <UsersTabContent
        users={users}
        search={search}
        form={form}
      />
    ) : (
      <CompanyPersonsTabContent
        company_persons={company_persons}
        search={search}
        form={form}
      />
    )}
  </div>
);

export default TabContent;
