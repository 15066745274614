import { equalObjects, parseLocaledString } from "modules/common";
import { PartyForm, PartyReqProduct } from "modules/party/create";
import { WarehouseProduct } from "modules/party/view";

import { clearEmptyValueInObj } from "./clear-empty-value-in-obj";

export function dirtyValues({
  products,
  values,
  isGroup
}: {
  isGroup: boolean;
  products: WarehouseProduct[];
  values: PartyForm["company_person_group"] | PartyForm["warehouse_products"];
}): PartyReqProduct[] {
  let result: PartyReqProduct[] = [];

  if (isGroup) {
    (values as PartyForm["company_person_group"])?.forEach(group => {
      result = [...result, ...dirtyValues({ isGroup: false, products, values: group?.products })];
    });
  } else {
    (values as PartyForm["warehouse_products"])?.forEach(
      ({
        id,
        task_product_id,
        vat_id,
        product_id,
        warehouse_id,
        unit_id,
        project_id,
        currency_id,
        company_person_id,
        quantity,
        amount
      }) => {
        if (id) {
          const thisProduct = products?.find(product => product?.id === id);
          const equal = equalObjects(
            {
              id,
              vat_id,
              product_id,
              warehouse_id,
              unit_id,
              company_person_id,
              quantity: parseLocaledString(quantity),
              amount: parseLocaledString(amount),
              project_id
            },
            {
              id: thisProduct?.id,
              vat_id: thisProduct?.vat?.id,
              product_id: thisProduct?.product?.id,
              warehouse_id: thisProduct?.warehouse?.id,
              unit_id: thisProduct?.unit?.id,
              company_person_id: thisProduct?.company_person?.id,
              quantity: thisProduct?.quantity,
              amount: thisProduct?.amount,
              project_id: thisProduct?.project?.id
            }
          );

          if (!equal) {
            result.push(
              clearEmptyValueInObj({
                id,
                vat_id,
                unit_id,
                project_id,
                product_id,
                currency_id,
                warehouse_id,
                task_product_id,
                company_person_id,
                quantity: parseLocaledString(quantity || "0"),
                amount: parseLocaledString(amount || "0")
              }) as never
            );
          }
        } else {
          result.push(
            clearEmptyValueInObj({
              vat_id,
              unit_id,
              project_id,
              product_id,
              currency_id,
              warehouse_id,
              task_product_id,
              company_person_id,
              quantity: parseLocaledString(quantity || "0"),
              amount: parseLocaledString(amount || "0")
            }) as never
          );
        }
      }
    );
  }

  return result;
}
