import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Empty, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { isEmptyArr } from "modules/common";
import {
  ArrowNarrowRightIcon,
  setPaymentTypes,
  usePaymentTypes,
  useUysotPaymentTypes,
  UysotPaymentType
} from "modules/settings/uysot";

import { LoadingIndicator } from "components";

import UysotPaymentTypeFooter from "./footer";
import PaymentTypeStatus from "./status";
import UyqurPaymentType from "./uyqur-payment-type";

import styles from "./index.module.scss";

const UysotPaymentTypeContent: React.FC = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { step } = useAppSelector(({ uysot }) => uysot);
  const { paymentTypes, isLoading, isFetching } = useUysotPaymentTypes();
  const { paymentTypes: uyqurPaymentTypes } = usePaymentTypes({ enabled: true });

  const columns: ColumnsType<UysotPaymentType> = [
    {
      key: 1,
      title: "Uysot",
      render: (_, { name }) => (
        <div className="flex w-full justify-between">
          <span>{name[i18n.language]}</span>
          <ArrowNarrowRightIcon />
        </div>
      ),
      width: 300
    },
    {
      key: 2,
      title: "Uyqur",
      render: (_, __, index) => <UyqurPaymentType index={index} />
    },
    {
      key: 3,
      title: t("uysot.Faollashtirish"),
      render: (_, __, index) => <PaymentTypeStatus index={index} />
    }
  ];

  useEffect(() => {
    if (!isEmptyArr(paymentTypes) && step === 2) {
      dispatch(
        setPaymentTypes(
          paymentTypes?.map(({ type, status, payment_type }) => {
            const uyqurPaymentType = uyqurPaymentTypes?.find(({ name }) => type.toUpperCase() === name.toUpperCase());

            return {
              type,
              status,
              payment_type_id: payment_type?.id || uyqurPaymentType?.id
            };
          })
        )
      );
    }
  }, [step, paymentTypes]);

  return (
    <div className="flex flex-col gap-4">
      <Table<UysotPaymentType>
        rowKey="type"
        columns={columns}
        pagination={false}
        dataSource={paymentTypes}
        rootClassName={styles.payment_type}
        scroll={{
          y: "35rem",
          x: undefined
        }}
        loading={{
          indicator: LoadingIndicator,
          spinning: isLoading || isFetching
        }}
        locale={{
          emptyText: <Empty description={t("uysot.Ma'lumotlar mavjud emas")} />
        }}
      />
      <UysotPaymentTypeFooter />
    </div>
  );
};

export default UysotPaymentTypeContent;
