import React from "react";
import { UseFormReturn } from "react-hook-form";

import { useAppSelector } from "hooks/redux";

import { PartyViewForm, usePartyView } from "modules/party/view";

import { Settings } from "../../other/settings";
import Right from "../../right";

import Wrapper from "./wrapper";

type Props = {
  form: UseFormReturn<PartyViewForm>;
};

const Index: React.FC<Props> = ({ form }) => {
  const { party_id, visible, status, isLoading: isStateLoading } = useAppSelector(state => state.partyReducer.view);
  const { party, isLoading } = usePartyView({ enabled: visible, id: party_id });

  return (
    <div className="flex h-full">
      <div className="box-border h-full flex-1 overflow-auto p-5">
        <Wrapper
          form={form}
          status={status}
          products={party?.warehouse_products}
          isLoading={isLoading || !!isStateLoading}
          personPaymentTypes={party?.company_person_payment_types}
        />
        <Settings type="supply" />
      </div>
      <Right
        agent={party?.agent}
        paymentDate={party.payment_date}
        deliveryDate={party?.delivery_date}
        products={party?.warehouse_products}
        customFields={party?.custom_field_values}
      />
    </div>
  );
};

export default Index;
