import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import $api from "features/app/utils/helpers/axiosInstance";
import { PaginationType } from "features/app/utils/models/PaginationType";

import { useQueryParams } from "hooks/useQueryParams";

import { ProcessTableData } from "modules/works/schema";
import useGetTableSize from "hooks/useGetTableSize";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";

interface Query {
  processView: PaginationType<ProcessTableData[]>;
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  company_person_id?: number;
  user_id?: number;
  inventory_id?: number;
  unit_id?: number;
  product_id?: number;
  page?: number;
}

export function useProcessView({
  enabled = false,
  unit_id,
  user_id,
  inventory_id,
  company_person_id,
  page,
  product_id
}: Params) {
  const initialValue: Query = { processView: { data: [], total: 0, current_page: 1 } };
  const { queries, severalSearchParams, generateSearchParam } = useQueryParams();
  const { statistics_type, group_by } = queries();
  const queryParams = generateSearchParam({
    company_person_id,
    user_id,
    inventory_id,
    unit_id,
    page,
    product_id
  });

  let url = useGetTableSize({
    endpoint: "work/task-progress-detail-view",
    tableConfigKey: tableConfigKeys.PROCESS_DATA
  });

  const searchParams =
    company_person_id || user_id || inventory_id || unit_id || product_id
      ? severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.GROUP_BY, queryParamsKeys.PAGE)
      : severalSearchParams(queryParamsKeys.STATISTICS_TYPE);

  if (!searchParams && queryParams && queryParams?.length > 0) {
    url += `&${queryParams}`;
  }

  if (searchParams && searchParams?.length && !queryParams) {
    url += `&${searchParams}`;
  }

  if (searchParams && queryParams.length > 0 && queryParams && queryParams?.length > 0) {
    url += `&${searchParams}&${queryParams}`;
  }

  const { data = initialValue, ...args } = useQuery<Query>(
    ["processView", searchParams, queryParams],
    async () => {
      const {
        data: { data }
      } = await $api.get(url);

      return { processView: data };
    },
    { enabled: enabled && statistics_type === "task_progress_detail" }
  );

  return { ...data, ...args };
}
