import React from "react";
import { UseFormReturn } from "react-hook-form";
import { CustomFieldSelectModel } from "features/app/utils/models/customFieldSelectModel";
import i18n from "i18next";

import { getPopupContainer, PartyForm } from "modules/party/create";

import { Select as BaseSelect } from "components";

type Props = {
  index: number;
  isUpdate?: boolean;
  field: CustomFieldSelectModel;
  form: UseFormReturn<PartyForm>;
};

const Label: React.FC<Props> = ({ form, field, index, isUpdate }) => {
  const { custom_field_options, name } = field;

  return (
    <BaseSelect
      withoutMessage
      mode="multiple"
      placeholder="Tanlang"
      control={form.control}
      label={name?.[i18n.language]}
      name={`custom_field_values.${index}.value`}
      getPopupContainer={getPopupContainer(isUpdate ? "party-right-content-update" : undefined)}
      options={custom_field_options?.map(option => ({
        key: option?.id,
        value: option?.id,
        label: option?.name?.[i18n.language],
        props: {
          name: option?.name?.[i18n.language]
        }
      }))}
    />
  );
};

export default Label;
