import { useState } from "react";
import { Button, Modal, Tabs } from "antd";
import ProductSpentWidgetDetailDrawer from "features/projects/components/product-spent-widget-detail-drawer/product-spent-widget-detail-drawer";
import { CompletedWorkModel } from "features/projects/utils/models/completedWorkModel";
import { useTranslation } from "react-i18next";

import { isEmptyObj } from "modules/common";

import {
  useGetProjectReportProductSpend,
  useGetWorkProgress,
  useGetWorkProgressById
} from "../../../../../service/queries";
import { tabKeys } from "../../../../../utils/constants/tabkKeys";
import { WorkModel } from "../../../../../utils/models/workModel";

import ResourceTable from "./table/ResourceTable";
import Statistics from "./statistics";

import styles from "./workModalTabs.module.scss";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  work?: WorkModel;
  progressId?: number;
  foundTaskProgress?: CompletedWorkModel | null;
};

const WorkModalTabs = ({ open, setOpen, work, progressId, foundTaskProgress }: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState<string>("all");
  const { data: progresses, isLoading } = useGetWorkProgress(tab, page, open, work?.id, progressId);
  const { data: progressesById, isLoading: isProgressesByIdLoading } = useGetWorkProgressById(open, progressId);
  const { data: statistics } = useGetProjectReportProductSpend(
    open,
    undefined,
    undefined,
    undefined,
    work?.id,
    progressId
  );

  const onCancel = () => {
    setOpen(false);
    setTab("all");
  };

  const onTabChange = (key: string) => {
    setTab(key);
  };

  const footer = () => (
    <div className={styles.footer}>
      <Button onClick={onCancel}>Yopish</Button>
    </div>
  );

  const items = [
    {
      key: tabKeys.ALL,
      label: "Barchasi",
      children: (
        <ResourceTable
          progressesById={progressesById ? progressesById.task_products! : []}
          resources={progresses}
          isLoading={isLoading}
          isProgressByIdLoading={isProgressesByIdLoading}
          setPage={setPage}
          tab={tab}
          progressId={progressId!}
        />
      )
    },
    {
      key: tabKeys.PRODUCTS,
      label: "Mahsulotlar",
      children: (
        <ResourceTable
          progressesById={progressesById ? progressesById.task_products! : []}
          resources={progresses}
          isLoading={isLoading}
          isProgressByIdLoading={isProgressesByIdLoading}
          setPage={setPage}
          tab={tab}
          progressId={progressId!}
        />
      )
    },
    {
      key: tabKeys.HUNAN_RESOURCE,
      label: "Inson resursi",
      children: (
        <ResourceTable
          progressesById={progressesById ? progressesById.task_products! : []}
          resources={progresses}
          isLoading={isLoading}
          isProgressByIdLoading={isProgressesByIdLoading}
          setPage={setPage}
          tab={tab}
          progressId={progressId!}
        />
      )
    },
    {
      key: tabKeys.MECHANISMS,
      label: "Mexanizmlar",
      children: (
        <ResourceTable
          progressesById={progressesById ? progressesById.task_products! : []}
          resources={progresses}
          isLoading={isLoading}
          isProgressByIdLoading={isProgressesByIdLoading}
          setPage={setPage}
          tab={tab}
          progressId={progressId!}
        />
      )
    },
    {
      ...(!progressId
        ? {
            key: tabKeys.ANOTHER,
            label: "Boshqalar",
            children: (
              <ResourceTable
                progressesById={progressesById ? progressesById.task_products! : []}
                isLoading={isLoading}
                isProgressByIdLoading={isProgressesByIdLoading}
                resources={progresses}
                setPage={setPage}
                tab={tab}
                progressId={progressId!}
              />
            )
          }
        : {})
    }
  ];

  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      title={t("works.Resurs sarfi")}
      footer={footer()}
      className={styles.work_modal}
      width={1800}
      destroyOnClose
    >
      <div className="flex h-full flex-col gap-4">
        <Statistics
          progressByIdStatistics={progressesById ? progressesById.statistics! : null}
          statistics={statistics}
          progressId={progressId!}
          work={work}
          foundTaskProgress={progressesById?.task_progress}
        />
        <Tabs items={items?.filter(el => !isEmptyObj(el)) as never} onChange={onTabChange} activeKey={tab} />
      </div>
      <ProductSpentWidgetDetailDrawer />
    </Modal>
  );
};

export default WorkModalTabs;
