import React, { useMemo } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { localeFormatter } from "features/app/utils/helpers/localeFormatter";

import { useAppSelector } from "hooks/redux";

import { useAuth } from "modules/auth/hooks";
import { parseLocaledString } from "modules/common";
import {
  getPopupContainer,
  parseGroupData,
  PartyForm,
  PartyFormProduct,
  PartyPersonGroup,
  totalAmount
} from "modules/party/create";
import { useCurrencies } from "modules/party/view";

import { ConditionalRender, Input, Select } from "components";

type Props = {
  isUpdate?: boolean;
  form: UseFormReturn<PartyForm>;
};

const Discount: React.FC<Props> = ({ form, isUpdate }) => {
  const { baseCurrency } = useAuth();
  const { currencies } = useCurrencies();
  const { isDiscount: isDiscountCreate, isGroup: isGroupCreate } = useAppSelector(
    ({ partyReducer }) => partyReducer.create
  );
  const { isDiscount: isDiscountUpdate, isGroup: isGroupUpdate } = useAppSelector(
    ({ partyReducer }) => partyReducer.update
  );
  const { warehouse_products, company_person_group, discount_currency_id } = useWatch({ control: form.control });

  const products = useMemo(() => {
    if (isGroupCreate || isGroupUpdate) {
      return parseGroupData(company_person_group as PartyPersonGroup) as PartyFormProduct[];
    }

    return warehouse_products as PartyFormProduct[];
  }, [isGroupCreate, isGroupUpdate, company_person_group, warehouse_products]);

  const total = totalAmount(products);

  const onChangeDiscount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    form.setValue("discount", localeFormatter(value, true));

    if (value.includes("%")) {
      const discount = parseLocaledString(value?.replace("%", "") || "0");
      const totalAmount =
        total?.find(({ currencyId }) => currencyId === (discount_currency_id || baseCurrency?.id))?.amount || 0;

      form.setValue("parse_discount", (discount * totalAmount) / 100);
    } else {
      form.setValue("parse_discount", parseLocaledString(value || "0"));
    }
  };

  const onChangeDiscountCurrency = (e: number, option: any) => {
    form.setValue("discount_currency_id", e);
    form.setValue("discount_currency_symbol", option?.props?.name);
  };

  return (
    <ConditionalRender if={isDiscountUpdate || isDiscountCreate}>
      <div className="flex flex-col gap-1.5">
        <div className="flex items-center justify-between">
          <label className="text-sm font-medium text-gray-700">Chegirma</label>
        </div>
        <div className="flex items-center gap-2">
          <Input
            name="discount"
            autoComplete="off"
            control={form.control}
            wrapperClassName="flex-1"
            onChange={onChangeDiscount}
            placeholder="Chegirmani kiriting"
          />
          <Select
            label={null}
            withoutMessage
            withLabel={false}
            placeholder="UZS"
            showSearch={false}
            control={form.control}
            wrapperClassName="w-24"
            name="discount_currency_id"
            getPopupContainer={getPopupContainer(isUpdate ? "party-right-content-update" : undefined)}
            onChange={onChangeDiscountCurrency}
            options={currencies?.map(currency => ({
              key: currency?.id,
              value: currency?.id,
              label: currency?.symbol,
              props: {
                name: currency?.symbol
              }
            }))}
          />
        </div>
      </div>
    </ConditionalRender>
  );
};

export default Discount;
