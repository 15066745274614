import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Modal, Table, TableColumnsType, TableProps } from "antd";

import { LoadingIndicator } from "../../../../../components";
import { useAppSelector } from "../../../../../hooks/redux";
import { cx, RU } from "../../../../../modules/common";
import { rootPaths } from "../../../../../routes/root/rootPaths";
import { paymentReducerActions } from "../../../../../store/reducers/paymentReducer";
import ArrowNarrowIcon from "../../../../app/assets/icons/ArrowNarrowIcon";
import PlusCircleIcon from "../../../../app/assets/icons/PlusCircleIcon";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import Pagination from "../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { PaymentTypeEnum } from "../../../../app/utils/constants/paymentTypeEnum";
import { PaymentModel } from "../../../../app/utils/models/payment/paymentModel";
import { useOrderDeleteArchive } from "../../../service/queries";
import { paymentQueryKeys } from "../../../utils/constants/paymentQueryKeys";
import { PaymentTypeIcon } from "../../../utils/helpers/paymentTypeIcon";
import { PaymentSourceElement } from "../income-expense-view/PaymentSourceElement";

import styles from "./order-delete-archive.module.scss";

type Props = {
  actions?: Record<string, any>;
};

const OrderDeleteArchive: React.FC<Props> = ({ actions }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [page, setPage] = useState(1);
  const { setOrderDeleteArchive, setIncomeOrExpenseViewVisible, setPaymentOrderModal } = paymentReducerActions;
  const { orderId, visible, order } = useAppSelector(state => state.paymentReducer.orderDeleteArchive);
  const { data, isLoading } = useOrderDeleteArchive(orderId, page);

  const paymentActions = actions || {};

  const columns: TableColumnsType<PaymentModel> = [
    {
      title: "Turi",
      render: (record: PaymentModel) => PaymentTypeIcon(record.type, record?.status)
    },
    {
      title: "Manba",
      render: (record: PaymentModel) =>
        PaymentSourceElement({
          isViewKey: true,
          record
        })
    },
    {
      title: "To'lov summasi",
      render: record => `${record?.amount?.toLocaleString(RU)}  ${record?.currency?.symbol}`
    },
    {
      title: "To'lov sanasi",
      dataIndex: "date"
    },
    {
      title: "",
      render: () => (
        <div className={cx("flex items-center justify-end opacity-0 transition-all ease-in-out", styles.view)}>
          <ArrowNarrowIcon rotate={180} />
        </div>
      )
    }
  ];

  const onOpenPayment = () => {
    dispatch(
      setPaymentOrderModal({
        order,
        visible: true,
        isOrderDelete: true,
        type: PaymentTypeEnum.EXPENSE,
        queryKey: [paymentQueryKeys.ORDER_DELETE_ARCHIVE, orderId, page]
      })
    );
  };

  const footer = () => (
    <div className="flex items-center p-2">
      <ConditionalRender if={paymentActions?.create && pathname.includes(rootPaths.PAYMENT)}>
        <div
          onClick={onOpenPayment}
          className="flex cursor-pointer items-center gap-2 rounded-xl px-4 py-2 text-sm text-primary-500 duration-300 ease-in-out hover:bg-primary-100"
        >
          <PlusCircleIcon fill />
          Xarajat qo'shish
        </div>
      </ConditionalRender>
      <Pagination
        paginationProps={{
          total: data?.total,
          onChange: page => {
            setPage(page);
          }
        }}
      />
    </div>
  );

  const onCancel = () => {
    dispatch(
      setOrderDeleteArchive({
        visible: false,
        orderId
      })
    );
  };

  const onAfterOpen = (visible: boolean) => {
    if (!visible) {
      dispatch(
        setOrderDeleteArchive({
          visible: false,
          orderId: 0
        })
      );
    }
  };

  const onRow: TableProps["onRow"] = (record: PaymentModel) => ({
    onClick: () => {
      dispatch(
        setIncomeOrExpenseViewVisible({
          record,
          visible: true,
          isNotDelete: !paymentActions?.deleteOrders
        })
      );
    }
  });

  return (
    <Modal
      centered
      width={800}
      zIndex={11115}
      open={visible}
      footer={false}
      onCancel={onCancel}
      title="To'lovlar tarixi"
      afterOpenChange={onAfterOpen}
    >
      <Table
        onRow={onRow}
        footer={footer}
        columns={columns}
        pagination={false}
        dataSource={data?.data}
        rowClassName={cx("cursor-pointer", styles.row)}
        className={cx("footer_table")}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty description="To'lovlar mavjud emas" />
        }}
      />
    </Modal>
  );
};

export default OrderDeleteArchive;
