import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Select } from "antd";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";

import { PartyForm } from "modules/party/create";
import { useWarehouses } from "modules/party/view";

type Props = { form: UseFormReturn<PartyForm> } & (
  | {
      type: "initial";
    }
  | {
      type: "group";
      parentIndex: number;
    }
);

const Index: React.FC<Props> = props => {
  const { type, form } = props;
  const { warehouses, isLoading } = useWarehouses();

  const onChange = (e: number) => {
    if (type === "initial") {
      const oldValue = [...(form.getValues("warehouse_products") || [])];

      form.setValue("warehouse_products", [...(oldValue?.map(item => ({ ...item, warehouse_id: e })) || [])]);
    }

    if (props.type === "group") {
      const oldValue = form.getValues(`company_person_group.${props.parentIndex}`);

      form.setValue(`company_person_group.${props.parentIndex}`, {
        ...oldValue,
        products: [
          ...(oldValue.products?.map(product => ({
            ...product,
            warehouse_id: e
          })) || [])
        ]
      });
    }
  };

  return (
    <Select
      loading={isLoading}
      onChange={onChange}
      placeholder="Omborxona"
      popupMatchSelectWidth={false}
      labelRender={() => "Omborxona"}
      suffixIcon={<ChevronDownIcon rotate width={18} />}
      options={warehouses?.map(warehouse => ({
        key: warehouse?.id,
        value: warehouse?.id,
        label: warehouse?.name,
        props: {
          name: warehouse?.name
        }
      }))}
    />
  );
};

export default Index;
