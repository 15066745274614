import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { createCompanyPersonQueryKeys } from "../query-keys";
import { CompanyPersonSelect } from "../schema";

type Query = {
  companyPersons: CompanyPersonSelect[];
};

type Props = {
  search: string;
};

export function useCompanyPersonSelect({ search }: Props) {
  const initial = {
    companyPersons: []
  };

  const { data = initial, ...arg } = useQuery<Query>(
    [createCompanyPersonQueryKeys.PERSONS_SELECT, search],
    async () => {
      const { data } = await $api.get(`person/select?search=${search}`);

      return { companyPersons: data?.data };
    },
    { enabled: !!search }
  );

  return { ...data, ...arg };
}
