import React from "react";

const ChevronDownIcon: React.FC<{
  rotate?: boolean;
  stroke?: string;
  width?: number;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  cursor?: string;
}> = ({ rotate, stroke, width, onClick, cursor }) => (
  <svg
    style={{
      transition: "0.25s ease-in-out",
      transform: `rotate(${rotate ? "180deg" : "90deg"})`,
      cursor: cursor!
    }}
    width={width || "24"}
    height={width || "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      d="M18 15L12 9L6 15"
      stroke={stroke || "#475467"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronDownIcon;
