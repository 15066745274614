import React from "react";
import { useTranslation } from "react-i18next";

import BuildingSettings from "./settings";

const UysotBuildingTitle: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="sticky top-0 box-border flex items-center justify-between p-4 pb-0">
      <h3 className="m-0 text-lg font-semibold text-gray-900">{t("uysot.Loyihalarni sozlang")}</h3>
      <BuildingSettings />
    </div>
  );
};

export default UysotBuildingTitle;
