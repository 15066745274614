import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Collapse, DatePicker, Form, Modal, Select, Switch } from "antd";
import { Rule } from "antd/es/form";
import dayjs from "dayjs";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { useGetCurrenySelect } from "features/app/service/queries";
import { dayjsFormats } from "features/app/utils/constants/dayjsFormats";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import { parseParamsId } from "features/app/utils/helpers/parseParamsId";
import { useExportCounterpartsActExcel } from "features/counterparts/service/mutation";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

import { useAppSelector } from "hooks/redux";

import { companyPersonActions } from "store/reducers/companyPersonReducer";

import { colors } from "modules/common";

import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import ChevroDownIcon from "../../../../../../projects/assets/icons/ChevroDownIcon";
import PrinterIcon from "../../../../../assets/icons/PrinterIcon";
import { useGetCompanyPersonAct, useGetOneCompanyPerson } from "../../../../../service/queries";
import ActPrint from "../act-print/ActPrint";

import ActViewTable from "./ActViewTable";

import styles from "./actModal.module.scss";

const { Item } = Form;
const { Option } = Select;

const ActModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { second_id } = useParams();
  const { name, id } = parseParamsId(second_id);
  const { setVisibleAct } = companyPersonActions;
  const {
    visible,
    name: company_person_name,
    company_person_id
  } = useAppSelector(state => state.companyPersonReducer.act);

  const exportExcel = useExportCounterpartsActExcel(name || company_person_name!);
  const baseCurrency = getBaseCurrency();
  const printComponentRef = useRef<HTMLDivElement | null>(null);
  const minDate = Form.useWatch("min_date", form);
  const maxDate = Form.useWatch("max_date", form);
  const currencyId = Form.useWatch("currency_id", form);
  const mutual = Form.useWatch("mutual", form);

  const { data: oneCompanyPerson } = useGetOneCompanyPerson(Number(id));
  const { data: currencies } = useGetCurrenySelect();
  const { data, isLoading } = useGetCompanyPersonAct({
    enabled: visible,
    company_person_id: +id! || company_person_id!,
    max_date: maxDate ? dayjs(maxDate).format(dayjsFormats.DATE) : undefined,
    currency_id: currencyId || baseCurrency?.id,
    min_date: minDate ? dayjs(minDate).format(dayjsFormats.DATE) : undefined
  });

  const onBeforePrint = () => {};

  useEffect(() => {
    form.setFieldValue("total_amount", data?.balances);
  }, [data, form]);

  const onAfterPrint = () => {};

  const reactToPrintContent = useCallback(() => printComponentRef.current, [printComponentRef]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Act sverka",
    onBeforePrint,
    onAfterPrint,
    removeAfterPrint: false
  });

  const onExportExcel = () =>
    exportExcel.mutate({
      multiple: mutual,
      company_person_id: +id! || company_person_id!,
      max_date: maxDate ? dayjs(maxDate).format(dayjsFormats.DATE) : undefined,
      min_date: minDate ? dayjs(minDate).format(dayjsFormats.DATE) : undefined
    });

  const footer = (
    <div className="flex w-full items-center justify-between">
      <Button icon={<DownloadIcon />} loading={exportExcel.isLoading} className="mr-3" onClick={onExportExcel}>
        {t("act.Excel")}
      </Button>
      <Button onClick={handlePrint}>
        <PrinterIcon /> {t("act.Chop etish")}
      </Button>
    </div>
  );

  const onCancel = () => {
    dispatch(
      setVisibleAct({
        visible: false,
        name: undefined,
        company_person_id: undefined
      })
    );
  };

  const onOpenAfter = (visible: boolean) => {
    if (!visible) {
      form.resetFields();
    } else {
      form.setFieldValue("currency_id", baseCurrency?.id);
    }
  };

  const startDateRule: Rule[] = [
    {
      validator: (_, value) => {
        const startDateValue = dayjs(value);
        const endDate = form.getFieldValue("end_date");

        if (endDate) {
          const endDateValue = dayjs(endDate);
          const diff = endDateValue.diff(startDateValue, "day", true);

          return diff >= 0 ? Promise.resolve() : Promise.reject();
        }
        return Promise.resolve();
      }
    }
  ];

  const endDateRule: Rule[] = [
    {
      validator: (_, value) => {
        const endDateValue = dayjs(value);
        const startDate = form.getFieldValue("start_date");

        if (startDate) {
          const startDateValue = dayjs(startDate);
          const diff = startDateValue.diff(endDateValue, "day", true);

          return diff > 0 ? Promise.reject() : Promise.resolve();
        }
        return Promise.resolve();
      }
    }
  ];

  const title = (
    <div className="flex items-center justify-between">
      <div className={styles.title}>
        <h3>ACT SVERKA</h3>
        <span>{name || company_person_name}</span>
      </div>
    </div>
  );

  return (
    <Modal
      centered
      title={title}
      open={visible}
      footer={footer}
      onCancel={onCancel}
      rootClassName={styles.act}
      afterOpenChange={onOpenAfter}
    >
      <Form form={form} layout="vertical">
        <div className={styles.top}>
          <Item name="min_date" label="Sana" rules={startDateRule}>
            <DatePicker placeholder="kun.oy.yil" format={dayjsFormats.DATE} />
          </Item>
          <Item name="max_date" label="Tugash sanasi" rules={endDateRule}>
            <DatePicker placeholder="kun.oy.yil" format={dayjsFormats.DATE} />
          </Item>
          <Item name="currency_id" label="Valyuta">
            <Select placeholder="Barchasi" suffixIcon={<SelectSuffixIcon />}>
              {currencies?.map(item => (
                <Option value={item.id} key={item.id}>
                  {item.symbol}
                </Option>
              ))}
            </Select>
          </Item>
        </div>
        <div className="flex gap-4">
          <ActViewTable data={data} isLoading={isLoading} />
          <ConditionalRender if={mutual}>
            <ActViewTable mutual={true} data={data} isLoading={isLoading} />
          </ConditionalRender>
        </div>
        <Collapse
          bordered={false}
          expandIconPosition="right"
          rootClassName={styles.collapse}
          expandIcon={({ isActive }) => <ChevroDownIcon active={isActive} stroke={colors.PRIMARY} />}
          items={[
            {
              label: (
                <div className="text-base font-medium text-primary-500">
                  <span>Qo'shimcha sozlamalar</span>
                </div>
              ),
              children: (
                <div className={styles.extra_content}>
                  {/* <div className={styles.extra_content__item}> */}
                  {/*  <div className={styles.extra_content__left}> */}
                  {/*    <h4>To‘liq manba ma’lumotlari</h4> */}
                  {/*    <p>Bunda Mexanizm, Ish, Partiya, To’lov ma’lumotlari kengaytirilgan shaklda bo’ladi.</p> */}
                  {/*  </div> */}
                  {/*  <Item name="all_document" valuePropName="switch"> */}
                  {/*    <Switch /> */}
                  {/*  </Item> */}
                  {/* </div> */}
                  <div className={styles.extra_content__item}>
                    <div className={styles.extra_content__left}>
                      <h4>Ikki tamonlama ma’lumotlar</h4>
                      <p>Bunda aktsverka ma’lumotlari ikki tomonlama shaklda bo’ladi</p>
                    </div>
                    <Item name="mutual" valuePropName="switch">
                      <Switch />
                    </Item>
                  </div>
                </div>
              )
            }
          ]}
        />
      </Form>
      <ActPrint
        key="1"
        data={data}
        mutual={mutual}
        minDate={minDate}
        maxDate={maxDate}
        ref={printComponentRef}
        person={oneCompanyPerson?.person}
        name={name || company_person_name}
      />
    </Modal>
  );
};

export default ActModal;
