import React, { useMemo } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

import { isEmptyArr } from "modules/common";
import { ProductWithUnit } from "modules/party/create";
import { PartyResourceForm } from "modules/party/resource";

type Props = {
  tableProducts: ProductWithUnit[];
  form: UseFormReturn<PartyResourceForm>;
};

const CheckAll: React.FC<Props> = ({ form, tableProducts = [] }) => {
  const { products } = useWatch({ control: form.control });

  const onChange = (e: CheckboxChangeEvent) => {
    const oldProducts = form.getValues("products");

    if (e.target?.checked) {
      form.setValue("products", [
        ...(oldProducts || []),
        ...(tableProducts?.filter(p => !oldProducts?.some(old => old?.id === p?.id)) || [])
      ]);
    } else {
      form.setValue("products", [
        ...(oldProducts?.filter(p => !tableProducts?.some(product => product?.id === p?.id)) || [])
      ]);
    }
  };

  const checked = useMemo(
    () => !isEmptyArr(tableProducts) && tableProducts?.every(p => products?.some(t => t?.id === p?.id)),
    [products, tableProducts]
  );

  const indeterminate = useMemo(
    () => products?.some(p => tableProducts?.some(t => t.id === p?.id)) && !checked,
    [products, tableProducts]
  );

  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      defaultChecked={false}
      indeterminate={indeterminate}
      disabled={isEmptyArr(tableProducts)}
    />
  );
};

export default CheckAll;
