import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { useQueryParams } from "hooks/useQueryParams";

import { dashboardQueryKeys, WarehouseDefect } from "..";
import { warehouseEndPoints } from "features/warehouse/utils/constants/warehouseEndPoints";

interface Query {
  companyWarehouseDefectViewById: Partial<WarehouseDefect>;
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  warehouse_defect_id: number | null;
}

export function useWarehouseDefectByIdView({ enabled = false, warehouse_defect_id }: Params) {
  const initialValue: Query = { companyWarehouseDefectViewById: {} };

  const { generateSearchParam } = useQueryParams();
  const searchParams = generateSearchParam({
    warehouse_defect_id
  } as never);

  const { data = initialValue, ...args } = useQuery<Query>(
    [dashboardQueryKeys.WAREHOUSE_DEFECT_BY_ID_VIEW, searchParams],
    async () => {
      const {
        data: { data }
      } = await $api.get(`${warehouseEndPoints.WAREHOUSE_DEFECT}?${searchParams}`);

      return { companyWarehouseDefectViewById: data };
    },
    { enabled }
  );

  return { ...data, ...args };
}
