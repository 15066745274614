import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { createCompanyPersonQueryKeys } from "../query-keys";
import { CompanyPersonFolder } from "../schema";

type Query = {
  folders: CompanyPersonFolder[];
};

export function useCompanyPersonFolders() {
  const initial = {
    folders: []
  };

  const { data = initial, ...arg } = useQuery<Query>([createCompanyPersonQueryKeys.PERSON_FOLDERS], async () => {
    const { data } = await $api.get("company-person-folder/select");

    return { folders: data?.data };
  });

  return { ...data, ...arg };
}
