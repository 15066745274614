import { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider } from "antd";
import ErrorBoundary from "features/app/components/error-boundary/ErrorBoundary";
import { antdConfigProvider } from "features/app/utils/constants/antdProviderProps";
import { PersistGate } from "redux-persist/integration/react";
import RootRouter from "routes/root/RootRouter";

import { persistor, store } from "store";

import { error } from "services";

import { Auth } from "modules/auth/containers";

import { NetworkMessage } from "components";
import FileViewer from "components/file-viewer/file-viewer";

import Loading from "./features/app/components/loading/Loading";

import "./styles/main.scss";
import "./styles/table.css";
import "./styles/common.scss";
import "./styles/tailwind.css";
import "react-resizable/css/styles.css";

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false }, mutations: { onError: error as any } },
  queryCache: new QueryCache({ onError: error as any })
});

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      setTimeout(() => {
        // eslint-disable-next-line no-console
        console.clear();

        // eslint-disable-next-line no-console
        console.log("%cUYQUR", "color: #1e90ff; font-size: 40px");

        // eslint-disable-next-line no-console
        console.log("%cOgohlantirish!", "color: red; font-size: 30px");

        // eslint-disable-next-line no-console
        console.log(
          "%cUshbu siz ko'rib turgan brauzer xususiyati dasturchilar uchun mo'ljallangan.Agar kimdir sizga UYQUR funksiyasini yoqish yoki kimningdir akkauntini “buzish” uchun bu yerga biror ma'lumotni nusxalash va joylashtirishni aytgan bo‘lsa, ular firibgarlardir. Ushbu amallarni bajarish orqali siz ularga UYQUR akkauntingizga kirish huquqini berasiz. Agarda ushbu holatga tushsangiz zudlik bilan UYQUR qo'llab quvvatlash xizmatiga yoki adminlarga murojat qiling !",
          "font-size: 16px; display: inline"
        );

        // eslint-disable-next-line no-console
        console.log(
          "%cAdmin: %chttps://t.me/uyqur_manager",
          "font-size: 16px; font-weight: bold",
          "font-size: 16px; color: #1e90ff; text-decoration: underline"
        );
      }, 1000);
    }
  }, []);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
              <ConfigProvider {...antdConfigProvider}>
                <Auth>
                  <Suspense fallback={<Loading />}>
                    <RootRouter />
                    <FileViewer />
                    <NetworkMessage />
                  </Suspense>
                  <ReactQueryDevtools initialIsOpen={false} />
                </Auth>
              </ConfigProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
