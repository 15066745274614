import { Button } from "antd";
import dayjs, { Dayjs } from "dayjs";
import queryString from "query-string";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "../../../../../hooks/useQueryParams";
import MarkIcon from "../../../../app/assets/icons/MarkIcon";
import FilterDrawer from "../../../../app/components/filter-drawer/FilterDrawer";
import FilterDateRange from "../../../../app/components/filter-drawer/filter-date-range/FilterDateRange";
import FilterPricesRange from "../../../../app/components/filter-drawer/filter-prices-range/FilterPricesRange";
import FilterSelect from "../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FiltersCount from "../../../../app/components/filter-drawer/filters-count/FiltersCount";
import {
  useGetCompanyPersons,
  useGetCurrenciesSelect,
  useGetTableConfigs,
  useGetUsersSelect,
  useProjectSelect
} from "../../../../app/service/queries";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { PaymentTypeEnum } from "../../../../app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import BankNoteIcon from "../../../../payment/assets/icons/BankNoteIcon";
import BuildingIcon from "../../../../payment/assets/icons/BuildingIcon";
import UsersIcon from "../../../../payment/assets/icons/UsersIcon";
import { useGetFinancialSelect } from "../../../../payment/service/queries";
import { PaymentStatusEnums } from "../../../../app/utils/enums/paymentStatusEnums";
import UserIcon from "../../../../projects/assets/icons/UserIcon";
import ZapIcon from "../../../../projects/assets/icons/ZapIcon";
import { useGetPaymentTypeSelect } from "../../../../settings/service/queries";
import FilterLinesIcon from "../../../../warehouse/assets/icons/FilterLinesIcon";

import styles from "./filterDrawer.module.scss";
import { UseMutationResult } from "@tanstack/react-query";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { inventoryPaymentOrderTableDefaultData } from "features/impost/utils/constants/impostTableDefaultData";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { generateExcelPayload } from "features/app/utils/helpers/generateExcelPayload";
import { parseLocaledString } from "features/app/utils/helpers/parseLocaledString";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";

const queryStringArr = [
  queryParamsKeys.CURRENCY_ID,
  queryParamsKeys.MIN_AMOUNT,
  queryParamsKeys.MAX_AMOUNT,
  queryParamsKeys.MIN_DATE,
  queryParamsKeys.MAX_DATE,
  queryParamsKeys.STATUSES,
  queryParamsKeys.PROJECT_IDS,
  queryParamsKeys.PAYMENT_TYPE_IDS,
  queryParamsKeys.COMPANY_PERSON_IDS,
  queryParamsKeys.CREATORS_IDS,
  queryParamsKeys.FINANCIAL_IDS
];

type Props = {
  exportExcelMutate?: UseMutationResult<any, unknown, any, unknown>;
};

const FilterDrawerPayments: React.FC<Props> = ({ exportExcelMutate }) => {
  const { t } = useTranslation();
  const { queries, necessaryQueries } = useQueryParams();
  const { min_date, max_date } = queries();
  const [open, setOpen] = useState(false);
  const [currenciesSelected, setCurrenciesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.CURRENCY_ID] as string[] | null
  );
  const [minAmountValue, setMinAmountValue] = useState<string>(
    queryString.parse(location.search)[queryParamsKeys.MIN_AMOUNT] as string
  );
  const [maxAmountValue, setMaxAmountValue] = useState<string>(
    queryString.parse(location.search)[queryParamsKeys.MAX_AMOUNT] as string
  );
  const [statusesSelected, setStatusesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.STATUSES] as string[] | null
  );
  const [projectsSelected, setProjectsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );
  const [paymentTypesSelected, setPaymentTypesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PAYMENT_TYPE_IDS] as string[] | null
  );
  const [counterpartsSelected, setCounterpartsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.COMPANY_PERSON_IDS] as string[] | null
  );
  const [usersSelected, setUsersSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.CREATORS_IDS] as string[] | null
  );
  const [financialSelected, setFinancialSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.FINANCIAL_IDS] as string[] | null
  );
  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([
    queries()[queryParamsKeys.MIN_DATE] ? dayjs(queries()[queryParamsKeys.MIN_DATE], dayjsFormats.DATE) : null,
    queries()[queryParamsKeys.MAX_DATE] ? dayjs(queries()[queryParamsKeys.MAX_DATE], dayjsFormats.DATE) : null
  ]);
  const { data: paymentTypes } = useGetPaymentTypeSelect({ enabled: open });
  const { data: projects } = useProjectSelect({ enabled: open });
  const { data: currenciesSelect } = useGetCurrenciesSelect(open);
  const { data: adminsSelect } = useGetUsersSelect(open);
  const { data: counterpartsSelect } = useGetCompanyPersons();
  const { data: financialExpense } = useGetFinancialSelect(open, PaymentTypeEnum.EXPENSE);
  const { data: tableConfig } = useGetTableConfigs(
    tableConfigKeys.MONITORING_PAYMENTS,
    inventoryPaymentOrderTableDefaultData
  );

  const admins = useMemo(() => {
    const result = adminsSelect?.map(item => {
      return { name: item?.full_name, ...item };
    });

    return result;
  }, [adminsSelect]);

  const counterparts = useMemo(() => {
    const result = counterpartsSelect?.map(item => {
      return { ...item };
    });

    return result;
  }, [counterpartsSelect]);

  const currencies = useMemo(() => {
    const result = currenciesSelect?.map(item => {
      return { name: item?.symbol, id: item.id, image: item.icon };
    });

    return result;
  }, [currenciesSelect]);

  const onOpenFilter = () => {
    setOpen(true);
  };

  const statusesData = useMemo(() => {
    return [
      {
        id: PaymentStatusEnums.PASSIVE,
        name: `${t("Monitoring.Kutilmoqda")}`
      },
      {
        id: PaymentStatusEnums.ACTIVE,
        name: `${t("Monitoring.Tasdiqlangan")}`
      },
      {
        id: PaymentStatusEnums.CANCEL,
        name: `${t("Monitoring.Rad etilgan")}`
      }
    ];
  }, []);

  const handleExcelExport = () => {
    const data = filterColumns({
      tableConfig
    })
      ?.map(value => ({
        key: String(value?.id),
        name: value?.title
      }))
      ?.filter(item => typeof item?.name !== "object");

    const otherParamsData = necessaryQueries(queryParamsKeys.SEARCH);
    const reqBody = {
      ...otherParamsData,
      columns: data,
      company_person_ids: counterpartsSelected,
      project_ids: projectsSelected,
      currency_id: currenciesSelected!?.length > 0 ? currenciesSelected![0] : null,
      creator_ids: usersSelected,
      statuses: statusesSelected,
      payment_type_ids: paymentTypesSelected,
      min_amount: Number(parseLocaledString(minAmountValue)),
      max_amount: Number(parseLocaledString(maxAmountValue)),
      min_date,
      max_date
    };

    if (data) {
      exportExcelMutate?.mutate(generateExcelPayload(reqBody));
    }
  };

  const footerContent = (
    <Button onClick={handleExcelExport} className={styles.downloadBtn} loading={exportExcelMutate?.isLoading}>
      <DownloadIcon /> {t("Monitoring.Yuklab olish")}
    </Button>
  );

  return (
    <FilterDrawer
      open={open}
      setOpen={setOpen}
      filterButton={
        <Button onClick={onOpenFilter}>
          <FilterLinesIcon /> {t("Monitoring.Filter")}
          {<FiltersCount queryStringArr={queryStringArr} />}
        </Button>
      }
      footerContent={footerContent}
      height="max-content"
      queryStringArr={queryStringArr}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.COMPANY_PERSON_IDS,
          selectedOptions: counterpartsSelected
        },
        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectsSelected
        },
        {
          queryKey: queryParamsKeys.CURRENCY_ID,
          selectedOptions: currenciesSelected
        },
        {
          queryKey: queryParamsKeys.CREATORS_IDS,
          selectedOptions: usersSelected
        },
        {
          queryKey: queryParamsKeys.STATUSES,
          selectedOptions: statusesSelected
        },
        {
          queryKey: queryParamsKeys.PAYMENT_TYPE_IDS,
          selectedOptions: paymentTypesSelected
        },
        {
          queryKey: queryParamsKeys.FINANCIAL_IDS,
          selectedOptions: financialSelected
        }
      ]}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_DATE,
          selectedValue: dateValue && dateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_DATE,
          selectedValue: dateValue && dateValue[1]
        }
      ]}
      setDateValue={[setDateValue]}
      selectedValueArr={[
        {
          queryKey: queryParamsKeys.MIN_AMOUNT,
          selectedValue: minAmountValue
        },
        {
          queryKey: queryParamsKeys.MAX_AMOUNT,
          selectedValue: maxAmountValue
        }
      ]}
      setSelectedValueArr={[setMinAmountValue, setMaxAmountValue]}
      setSelectedOptionsArr={
        [
          setCounterpartsSelected,
          setProjectsSelected,
          setCurrenciesSelected,
          setUsersSelected,
          setStatusesSelected,
          setPaymentTypesSelected,
          setFinancialSelected
        ] as never
      }
    >
      <div className={styles.content}>
        <FilterSelect
          selectedOptions={counterpartsSelected}
          setSelectedOptions={setCounterpartsSelected}
          data={counterparts}
          multiple="multiple"
          icon={<UsersIcon />}
          placeholder={t("MainLayoutLinks.Kontragent")}
          showSearch
        />
        <FilterTreeSelect
          selectedOptions={projectsSelected}
          setSelectedOptions={setProjectsSelected}
          data={projects}
          multiple={true}
          icon={<BuildingIcon />}
          placeholder={t("Monitoring.Loyihalar")}
          showSearch
        />
        <FilterSelect
          selectedOptions={financialSelected}
          setSelectedOptions={setFinancialSelected}
          data={financialExpense}
          multiple="multiple"
          icon={<MarkIcon />}
          placeholder={t("Monitoring.Chiqim turlari")}
          showSearch
        />
        <FilterSelect
          selectedOptions={currenciesSelected}
          setSelectedOptions={setCurrenciesSelected}
          data={currencies}
          icon={<BankNoteIcon />}
          placeholder={t("Moliya.Valyuta")}
          isCurrency
        />
        <FilterSelect
          selectedOptions={usersSelected}
          setSelectedOptions={setUsersSelected}
          data={admins}
          multiple="multiple"
          icon={<UserIcon />}
          placeholder={t("Xodimlar.Xodimlar")}
          isPersons
          className={styles.admins__select}
          showSearch
        />
        <FilterSelect
          selectedOptions={paymentTypesSelected}
          setSelectedOptions={setPaymentTypesSelected}
          data={paymentTypes}
          multiple="multiple"
          icon={<ZapIcon />}
          placeholder={t("Monitoring.To’lov turi")}
          showSearch
        />
        <FilterSelect
          selectedOptions={statusesSelected}
          setSelectedOptions={setStatusesSelected}
          data={statusesData}
          multiple="multiple"
          icon={<ZapIcon />}
          placeholder={t("Monitoring.Tasdiq holati")}
          showSearch
        />
        <FilterPricesRange
          startPriceValue={minAmountValue}
          endPriceValue={maxAmountValue}
          setStartPriceValue={setMinAmountValue}
          setEndPriceValue={setMaxAmountValue}
          placeholder={t("Monitoring.Umumiy summa")}
        />
        <FilterDateRange value={dateValue} setValue={setDateValue} />
      </div>
    </FilterDrawer>
  );
};

export default FilterDrawerPayments;
