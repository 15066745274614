import React from "react";
import { Checkbox as BaseCheckbox } from "antd";
import { CheckboxProps as BaseCheckboxProps } from "antd/lib/checkbox";

import { cx } from "modules/common";

import Wrapper, { BaseProps } from "./wrapper";

interface SwitchProps extends BaseProps, BaseCheckboxProps {
  name: string;
  loading?: boolean;
  placeholder: string;
  type?: "number" | "text";
}

const Checkbox: React.FC<SwitchProps> = ({
  label,
  rules,
  control,
  disabled,
  onChange,
  withLabel,
  name = "",
  labelClassName,
  errorClassName,
  withoutMessage,
  loading = false,
  wrapperClassName,
  ...args
}) => {
  label = label || (withLabel ? args.placeholder : null);

  return (
    <Wrapper {...{ control, name, label, labelClassName, disabled, withoutMessage }}>
      {field => (
        <BaseCheckbox
          {...field}
          {...args}
          id={name}
          checked={field?.value || false}
          className={cx("checkbox", args.className)}
          disabled={disabled || field.disabled || loading}
        />
      )}
    </Wrapper>
  );
};

export default Checkbox;
