import React from "react";
import { Col, Row } from "antd";

import { ConditionalRender } from "components";

type Props = {
  currency_symbol?: string;
};

const Title: React.FC<Props> = ({ currency_symbol }) => (
  <Row className="border border-l-0 border-r-0 border-t-0 border-solid border-gray-300 bg-white px-11 py-3">
    <Col span={12} className="text-sm font-medium text-gray-400">
      Ishlar va resurslar
    </Col>
    <Col span={3} className="text-sm font-medium text-gray-400">
      Sana
    </Col>
    <Col span={3} className="text-sm font-medium text-gray-400">
      Hajmi
    </Col>
    <Col span={3} className="text-sm font-medium text-gray-400">
      Birligi
    </Col>
    <Col span={3} className="text-sm font-medium text-gray-400">
      <span>Narxi </span>
      <ConditionalRender if={currency_symbol}>({currency_symbol})</ConditionalRender>
    </Col>
  </Row>
);

export default Title;
