import { Avatar, Tooltip } from "antd";

import { cx } from "modules/common";

import { getFirstLetter } from "../../utils/helpers/getFirstLetter";

interface IProps {
  name?: string;
  image?: string;
}

const CustomAvatar = ({ image, name = "" }: IProps) => (
  <Tooltip arrow={false} title={`${name}`}>
    <Avatar src={image} className={cx("avatarFull")}>
      {getFirstLetter(name)}
    </Avatar>
  </Tooltip>
);

export default CustomAvatar;
