import React from "react";

const PhoneCallIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_23005_82830)">
      <path
        d="M11.708 5.00002C12.522 5.15882 13.27 5.5569 13.8564 6.1433C14.4428 6.7297 14.8409 7.47774 14.9997 8.29169M11.708 1.66669C13.3991 1.85455 14.976 2.61183 16.1799 3.81419C17.3838 5.01655 18.143 6.59253 18.333 8.28335M8.52216 11.5526C7.52084 10.5513 6.73019 9.41906 6.15019 8.21104C6.1003 8.10714 6.07536 8.05518 6.05619 7.98944C5.98809 7.75581 6.03701 7.46893 6.17868 7.27107C6.21855 7.21539 6.26618 7.16776 6.36144 7.0725C6.65278 6.78117 6.79845 6.6355 6.89369 6.48901C7.25285 5.93661 7.25285 5.22446 6.89369 4.67205C6.79845 4.52557 6.65278 4.3799 6.36144 4.08856L6.19905 3.92617C5.75618 3.4833 5.53475 3.26186 5.29693 3.14158C4.82396 2.90235 4.26541 2.90235 3.79244 3.14158C3.55462 3.26186 3.33319 3.4833 2.89032 3.92617L2.75896 4.05753C2.3176 4.49888 2.09693 4.71956 1.92839 5.01958C1.74137 5.35251 1.6069 5.86958 1.60804 6.25143C1.60906 6.59556 1.67582 6.83074 1.80932 7.30111C2.5268 9.82894 3.88053 12.2142 5.87051 14.2042C7.86049 16.1942 10.2458 17.5479 12.7736 18.2654C13.244 18.3989 13.4792 18.4657 13.8233 18.4667C14.2052 18.4678 14.7222 18.3334 15.0552 18.1464C15.3552 17.9778 15.5759 17.7571 16.0172 17.3158L16.1486 17.1844C16.5914 16.7415 16.8129 16.5201 16.9332 16.2823C17.1724 15.8093 17.1724 15.2508 16.9332 14.7778C16.8129 14.54 16.5914 14.3186 16.1486 13.8757L15.9862 13.7133C15.6948 13.422 15.5492 13.2763 15.4027 13.1811C14.8503 12.8219 14.1381 12.8219 13.5857 13.1811C13.4392 13.2763 13.2936 13.422 13.0022 13.7133C12.907 13.8086 12.8593 13.8562 12.8037 13.8961C12.6058 14.0377 12.3189 14.0866 12.0853 14.0185C12.0196 13.9994 11.9676 13.9744 11.8637 13.9245C10.6557 13.3446 9.52347 12.5539 8.52216 11.5526Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_23005_82830">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PhoneCallIcon;
