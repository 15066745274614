import React from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "antd";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { useDownloadFile } from "features/app/service/mutation";
import { colors } from "features/app/utils/constants/colors";
import XCloseIcon from "features/warehouse/assets/icons/XCloseIcon";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { fileViewerActions } from "store/reducers/file-viewer";

import styles from "./file-view.module.scss";

const FileViewer: React.FC = () => {
  const { format, isOpen, name, url, id } = useAppSelector(state => state.fileViewerReducer);
  const { handleClose: close } = fileViewerActions;
  const dispatch = useDispatch();
  const downloadFile = useDownloadFile();

  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(close());
  };

  const downloadFunc: React.MouseEventHandler<HTMLElement> = e => {
    e.stopPropagation();
    downloadFile.mutate({
      id: id!,
      name
    });
  };

  const getFileViewerContent = () => {
    if (!url) return null;

    switch (format) {
      case "pdf":
        return (
          <iframe
            title="view file iframe"
            height="100%"
            width="100%"
            src={`https://docs.google.com/gview?url=${url}&embedded=true`}
          />
        );
      case "doc":
      case "docx":
      case "ppt":
      case "pptx":
      case "xls":
      case "xlsx":
        return (
          <iframe
            title="view file iframe"
            height="100%"
            width="100%"
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
          />
        );
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
      case "bmp":
      case "svg":
        return <img src={url} alt={name} className={styles.image} />;
      case "txt":
        return <iframe title="view file iframe" height="100%" width="100%" src={url} />;
      default:
        return <p>{t("fileViewer.File_format_not_supported")}</p>;
    }
  };

  return (
    <Modal
      rootClassName={styles.file_viewer_modal}
      zIndex={30000}
      centered
      onCancel={handleClose}
      open={isOpen}
      footer={null}
      key={url}
      title={
        <div className={styles.container_header}>
          <p onClick={e => e.stopPropagation()}>{name}</p>
          <div className={styles.container_header_right}>
            <Button
              loading={downloadFile.isLoading}
              className={styles.container_header_right_button}
              type="primary"
              onClick={downloadFunc}
            >
              <DownloadIcon stroke={colors.WHITE} /> {t("fileViewer.Yuklab_olish")}
            </Button>
            <div className={styles.close_icon_container} onClick={handleClose}>
              <XCloseIcon width={24} />
            </div>
          </div>
        </div>
      }
      closeIcon={null}
    >
      <div onClick={e => e.stopPropagation()} className={styles.container_file}>
        {getFileViewerContent()}
      </div>
    </Modal>
  );
};

export default FileViewer;
