import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import $api from "features/app/utils/helpers/axiosInstance";
import { errorHandler } from "features/app/utils/helpers/errorHandler";
import { useTranslation } from "react-i18next";

import { ErrorRes, SuccessRes } from "modules/common";
import { integrationQueryKeys } from "modules/settings/integration";

import { UysotUpdatePayload } from "../schema";

export function useIntegrationUpdate() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, UysotUpdatePayload>(
    async payload => {
      const { data } = await $api.put("integration/update", payload);

      return data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        message.success(res.message[i18n.language]);

        qc.invalidateQueries([integrationQueryKeys.VIEW]);
      }
    }
  );
}
