import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { errorHandler, ErrorRes, SuccessRes } from "modules/common";

import $api from "../../../../features/app/utils/helpers/axiosInstance";
import { partyQueryKeys } from "../query-keys";

export function useOfferReceived() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { party_id } = useAppSelector(state => state.partyReducer.view);

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.put("warehouse-product-offer/recieved", { id });

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([partyQueryKeys.VIEW, party_id]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}
