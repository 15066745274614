import React, { ReactNode } from "react";
import { UseFormReturn } from "react-hook-form";
import { Col, Collapse, Row } from "antd";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import i18n from "i18next";

import { RU } from "modules/common";
import { PartyEstimateForm, PartySection, TaskCheck } from "modules/party/estimate";

import Products from "pages/party/estimate/content/children/products";

import styles from "./index.module.scss";

const TaskItemWrapper = ({ children }: { children: ReactNode }) => (
  <span className="text-base font-normal text-gray-500">{children}</span>
);

const Section: React.FC<PartySection & { form: UseFormReturn<PartyEstimateForm> }> = ({ place, name, tasks, form }) => (
  <div className="box-border flex flex-col gap-4 rounded-xl border border-solid border-gray-300 bg-white p-4">
    <div className="flex items-center text-2xl font-semibold text-gray-700">
      {place || 0}. {name || ""}
    </div>
    <Collapse
      expandIcon={() => null}
      rootClassName={styles.section}
      items={tasks?.map(task => ({
        key: task?.id,
        label: (
          <Row>
            <Col span={12}>
              <div className="flex items-center gap-2">
                <TaskCheck taskId={task?.id} form={form} />
                <div className="flex items-center text-base font-medium text-gray-600">
                  {place || 0}.{task?.place} <PopoverShortName arrow={false} title={task?.name || ""} length={38} />
                </div>
              </div>
            </Col>
            <Col span={3}>
              <TaskItemWrapper>{task?.due_date || "--"}</TaskItemWrapper>
            </Col>
            <Col span={3}>
              <TaskItemWrapper>{task?.quantity}</TaskItemWrapper>
            </Col>
            <Col span={3}>
              <TaskItemWrapper>{task?.unit?.symbol?.[i18n.language]}</TaskItemWrapper>
            </Col>
            <Col span={3}>
              <TaskItemWrapper>{task?.amount?.toLocaleString(RU)}</TaskItemWrapper>
            </Col>
          </Row>
        ),
        children: <Products taskId={task?.id} form={form} />
      }))}
    />
  </div>
);

export default Section;
