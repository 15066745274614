import React from "react";
import { Control, ControllerRenderProps, FieldValues, useController, UseControllerProps } from "react-hook-form";

import { cx } from "../../features/app/utils/helpers/cx";
import ConditionalRender from "../conditional-render";

import cls from "./wrapper.module.scss";

export interface BaseProps {
  control: Control<any>;
  label?: React.ReactNode;
  withLabel?: boolean;
  withoutMessage?: boolean;
  wrapperClassName?: string;
  labelClassName?: string;
  errorClassName?: string;
  rules?: UseControllerProps["rules"];
}

interface WrapperProps extends BaseProps {
  name: string;
  disabled?: boolean;
  children: (field: ControllerRenderProps<FieldValues, any>, error: string) => React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({
  name,
  rules,
  control,
  children,
  label = "",
  labelClassName,
  wrapperClassName,
  withoutMessage = false,
  errorClassName = cls.error
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    rules
  });

  const error = fieldState.error?.message || "";

  return (
    <div
      className={cx("flex w-full flex-col gap-1.5", cls.wrapper, wrapperClassName, {
        [errorClassName]: fieldState.isValidating
      })}
    >
      <ConditionalRender if={label}>
        <label htmlFor={name} className={cx("text-sm font-medium text-gray-700", labelClassName)}>
          {label}
        </label>
      </ConditionalRender>
      {children(field, error)}
      {!withoutMessage && error && <div className="text-sm text-error-500">{error}</div>}
    </div>
  );
};

export default React.memo(Wrapper);
