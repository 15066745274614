export const paymentQueryKeys = {
  PAYMENT_VIEW: "payment-view",
  PAYMENT_STATISTICS: "payment-statistics",
  CASH_MONEY_SELECT: "cash-money-select",
  CASH_MONEY_PAYMENT_TYPE_SELECT: "payment-type-select",
  PROJECT_SELECT: "project-select",
  EXPECTED_PAYMENT_TEMPLATE: "expected-payment-template",
  EXPECTED_PAYMENT_TEMPLATE_DELETED: "expected-payment-template-deleted",
  ONE_EXPECTED_PAYMENT_TEMPLATE: "one-expected-payment-template",
  EXPECTED_PAYMENTS: "expected-payments",
  ACCURATE_CASH_MONEY_SELECT: "accurate-cash-money-select",
  CASH_MONEY_PAYMENTS: "cash-money-payments",
  CASH_MONEY_PROJECTS: "cash-money-projects",
  TRANSFER_VIEW: "transfer-view",
  PAYMENTS_ORDER_VIEW: "payments-order-view",
  PAYMENT_ORDER_VIEW: "payment-order-view",
  PROJECTS_SELECT: "projects-select",
  PAYMENT_ORDER_ADDITIONAL: "payment-order-additional",
  PAYMENT_ORDER_ADDITIONAL_REJECT: "payment-order-additional-reject",
  PAYMENT_WORK_VIEW: "payment-work-view",
  PAYMENT_INVENTORY_VIEW: "payment-inventory-view",
  INVENTORY_SELECT: "inventory-select",
  INCOME_EXPENSE_DELETE_ARCHIVE: "income-expense-delete-archive",
  FINANCIAL_SELECT: "payment-financial-select",
  CASH_MONEY_PAYMENT_TYPES: "cash-money-payment-types",
  ARCHIVE_PAYMENT_TEMPLATE: "ARCHIVE_PAYMENT_TEMPLATE",
  CALENDAR: "calendar",
  CALENDAR_HISTORY_ORDER: "calendar-history-order",
  CALENDAR_HISTORY_PAYMENT: "calendar-history-payment",
  CALENDAR_HISTORY_STATISTICS: "calendar_history_statistics",
  ONE_EXPECTED_PAYMENT: "one-expected-payment",
  ONE_COMPANY_PERSON: "one-company-person",
  PROJECT_VIEW: "project-view",
  ORDER_DELETE_ARCHIVE: "order-delete-archive",
  ONE_PAYMENT_VIEW: "one-payment-view",
  ORDERS: "payment-orders-select"
};
