import { UserTableDataModel } from "features/app/utils/models/user/userTableConfigModel";

const listOfWorksTableConfig: UserTableDataModel = {
  column: [
    {
      id: "work_name_and_section",
      name: "work_name_and_section",
      title: "Ish nomi va bo'limi",
      checked: true
    },
    {
      id: "work_section_project_name",
      name: "work_section_project_name",
      title: "Loyiha",
      checked: true
    },
    {
      id: "due_date",
      name: "due_date",
      title: "Ish tugash vaqti",
      checked: true
    },
    {
      id: "work_progress",
      name: "work_progress",
      title: "Ish jarayoni",
      checked: true
    },
    {
      id: "work_task_progress_quantity",
      name: "work_task_progress_quantity",
      title: "Ish hajmi  ",
      checked: true
    },
    {
      id: "status",
      name: "status",
      title: "Holati",
      checked: true
    },
    {
      id: "creator",
      name: "creator",
      title: "Ma'sul xodim",
      checked: true
    }
  ],
  size: 10
};

export default listOfWorksTableConfig;
