import { ProductModel } from "features/supply/utils/models/productModel";

import { isEmptyArr } from "modules/common";

import { ProductWithUnit } from "../schema";

export function generateSelectProducts(data: ProductWithUnit[], products: ProductModel[]): ProductWithUnit[] {
  if (!isEmptyArr(products)) {
    const result: ProductWithUnit[] = [];

    products?.forEach(({ product: { id: productId, resource, name, code }, unit }) => {
      if (!data?.some(({ id }) => id === productId)) {
        result.push({
          name,
          id: productId,
          code: code || "",
          unit: unit as never,
          resource: resource as never
        });
      }
    });

    return result;
  }

  return [];
}
