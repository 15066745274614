import React, { useState } from "react";
import { FieldErrors, useForm, UseFormReturn, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, message, Modal } from "antd";
import { useGetLanguages } from "features/app/service/queries";
import $api from "features/app/utils/helpers/axiosInstance";
import i18n from "i18next";
import { uid } from "uid";

import { errorHandler, ErrorRes, SuccessRes } from "modules/common";
import {
  CreateProductForm,
  createProductFormSchema,
  PartyResourceForm,
  partyResourceQueryKeys,
  useResources
} from "modules/party/resource";
import { useUnits } from "modules/party/view";

import { Input, Select } from "components";

type Props = {
  parentForm: UseFormReturn<PartyResourceForm>;
};

const Index: React.FC<Props> = ({ parentForm }) => {
  const qc = useQueryClient();
  const [open, setOpen] = useState(false);
  const { units, isLoading } = useUnits();
  const { data: languages } = useGetLanguages();
  const { resources, isLoading: isLoadingResource } = useResources();
  const { catalog, sub_catalog } = useWatch({ control: parentForm.control });

  const form = useForm<CreateProductForm>({
    resolver: zodResolver(createProductFormSchema),
    defaultValues: {
      name: undefined,
      unit_id: undefined,
      resource_id: undefined
    }
  });

  const onCancel = () => {
    setOpen(false);

    form.reset({
      name: undefined,
      // @ts-ignore
      unit_id: null,
      code: undefined,
      // @ts-ignore
      resource_id: null
    });
  };

  const createProduct = useMutation<
    SuccessRes,
    ErrorRes,
    Omit<CreateProductForm, "name"> & { name: Record<string, string> }
  >(
    async payload => {
      const { data } = await $api.post("product/create", payload);

      return data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);

        // const searchParams = generateSearchParam({
        //   category_id: catalog?.id,
        //   subcategory_id: sub_catalog?.id,
        //   search,
        //   page: 1,
        //   category_only: !!sub_catalog
        // });

        await qc.invalidateQueries([partyResourceQueryKeys.RESOURCES]);

        onCancel();
      }
    }
  );

  const onOpen = () => {
    setOpen(true);
  };

  const onSubmit = async (values: CreateProductForm) => {
    let reqData = {
      ...values,
      name: {},
      category_id: sub_catalog?.id ? undefined : catalog?.id,
      subcategory_id: sub_catalog?.id
    };

    languages?.forEach(lang => {
      reqData = Object.assign(reqData, {
        name: {
          ...reqData.name,
          [lang.symbol]: values.name
        }
      });
    });

    await createProduct.mutateAsync(reqData);
  };

  const onError = (errors: FieldErrors) => {
    console.log("Create product ERRORS", errors);

    return null;
  };

  return (
    <>
      <Button onClick={onOpen}>Mahsulot qo'shish</Button>
      <Modal
        centered
        open={open}
        okText="Saqlash"
        onCancel={onCancel}
        cancelText="Yopish"
        title="Mahsulot qo'shish"
        okButtonProps={{
          loading: form.formState.isSubmitting
        }}
        onOk={form.handleSubmit(onSubmit, onError)}
      >
        <form className="flex flex-col gap-4">
          <Input withoutMessage name="name" control={form.control} placeholder="Nomi" label="Nomi" />
          <Select
            key={uid()}
            withoutMessage
            name="unit_id"
            label="Birligi"
            loading={isLoading}
            placeholder="Birligi"
            control={form.control}
            rootClassName="z-[200500]"
            popupMatchSelectWidth={false}
            options={units?.map(unit => ({
              key: unit?.id,
              value: unit?.id,
              label: unit?.symbol?.[i18n.language],
              props: {
                name: unit?.symbol?.[i18n.language]
              }
            }))}
          />
          <Select
            key={uid()}
            withoutMessage
            label="Resursi"
            name="resource_id"
            placeholder="Resursi"
            control={form.control}
            rootClassName="z-[200500]"
            loading={isLoadingResource}
            options={resources?.map(resource => ({
              key: resource?.id,
              value: resource?.id,
              label: resource?.name?.[i18n.language],
              props: {
                name: resource?.name?.[i18n.language]
              }
            }))}
          />
          <Input withoutMessage name="code" control={form.control} placeholder="Mahsulot kodi" label="Mahsulot kodi" />
        </form>
      </Modal>
    </>
  );
};

export default Index;
