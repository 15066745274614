import React from "react";
import { useDispatch } from "react-redux";
import { Drawer } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { Footer, setClearAllData, setVisible } from "modules/warehouse/create-transfer";

import Content from "./content";

import styles from "./index.module.scss";

const CreateTransfer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    visible,
    additional: { visible: additionalVisible }
  } = useAppSelector(({ warehouseCreateTransfer }) => warehouseCreateTransfer);

  const onClose = () => {
    dispatch(setVisible(false));
  };

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      dispatch(setClearAllData());
    }
  };

  return (
    <Drawer
      destroyOnClose
      open={visible}
      onClose={onClose}
      rootClassName={styles.drawer}
      afterOpenChange={onAfterOpen}
      width={additionalVisible ? 1762 : 1362}
      title={t("warehouseTransfer.O'tkazma qilish")}
    >
      <div className="flex h-full w-full flex-col overflow-hidden bg-gray-50">
        <Content />
        <Footer />
      </div>
    </Drawer>
  );
};

export default CreateTransfer;
