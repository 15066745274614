import React from "react";
import { useDispatch } from "react-redux";
import { UseFormReturn } from "react-hook-form";
import { Popover } from "antd";
import { colors } from "features/app/utils/constants/colors";
import i18n from "i18next";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/reducers/partyReducer";

import { cx, localeFormatter } from "modules/common";
import { NewPartyColumnsType, PartyViewForm } from "modules/party/view";
import PopoverContent from "modules/party/view/components/columns/warehouse/popover-content";

import { TableItemWrapper } from "pages/party/view/content/other";

import { ArrowNarrowRightIcon, ConditionalRender, Input, SettingsIcon } from "components";

export const WarehouseColumns = ({ form }: { form: UseFormReturn<PartyViewForm> }) => {
  const dispatch = useDispatch();
  const { setPartyViewSettingsVisible } = partyReducerActions;
  const { isAccept } = useAppSelector(({ partyReducer }) => partyReducer.view);

  const onOpenSettings = () => {
    dispatch(setPartyViewSettingsVisible(true));
  };

  const quantityPercent = (quantity: number, receivedQuantity: number) => {
    if (receivedQuantity > quantity) {
      return 100;
    }

    return ((receivedQuantity / quantity) * 100)?.toFixed(0) || 0;
  };

  const quantityPercentWidth = (quantity: number, receivedQuantity: number) => {
    if (receivedQuantity > quantity) {
      return (quantity / receivedQuantity) * 100;
    }

    return (receivedQuantity / quantity) * 100;
  };

  const receivedQuantityPercent = (quantity: number, receivedQuantity: number) =>
    (((receivedQuantity - quantity) / quantity) * 100).toFixed(0);

  const onChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;

    form.setValue(`warehouse_products.${index}.quantity`, localeFormatter(value));
  };

  const allColumns: NewPartyColumnsType = [
    {
      key: 1,
      width: 330,
      minWidth: 330,
      title: "Resurs turi va nomi",
      render: record => (
        <TableItemWrapper form={form} id={record?.id} name="not">
          <div className="flex items-center gap-3">
            <div
              className="h-max min-w-max rounded border border-solid px-2 py-0.5"
              style={{
                color: record?.product?.resource?.color,
                borderColor: record?.product?.resource?.color
              }}
            >
              {record?.product?.resource?.symbol?.[i18n.language]}
            </div>
            <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray-900">
              {record?.product?.name?.[i18n.language]}
            </span>
          </div>
        </TableItemWrapper>
      ),
      fixed: "left"
    },
    {
      key: 2,
      width: 100,
      minWidth: 100,
      title: "Birligi",
      render: record => (
        <TableItemWrapper form={form} id={record?.id} name="unit_id">
          {record?.unit?.symbol?.[i18n.language]}
        </TableItemWrapper>
      ),
      active: true
    },
    {
      key: 3,
      width: 110,
      minWidth: 110,
      title: "Soni",
      render: record => (
        <TableItemWrapper form={form} id={record?.id} name="quantity">
          {record?.quantity}
        </TableItemWrapper>
      ),
      active: true
    },
    {
      key: 4,
      width: 250,
      minWidth: 250,
      title: "Jarayon",
      render: ({ quantity, recieved_quantity, id, unit: { symbol } }) => (
        <TableItemWrapper form={form} id={id} name="progress">
          <Popover
            arrow={false}
            title={
              <PopoverContent
                quantity={quantity}
                symbol={symbol?.[i18n.language]}
                receivedQuantity={recieved_quantity}
              />
            }
          >
            <div className="flex flex-col gap-1">
              <p className="m-0 text-xs font-normal text-gray-600">
                {quantityPercent(quantity, recieved_quantity)}%{" "}
                {recieved_quantity > quantity && (
                  <span className="text-success-500">+{receivedQuantityPercent(quantity, recieved_quantity)}</span>
                )}
              </p>
              <div
                className="relative flex w-full overflow-hidden rounded-3xl bg-gray-200"
                style={{
                  background: recieved_quantity >= quantity ? colors.SUCCESS_500 : colors.GRAY_100
                }}
              >
                <div
                  className="absolute h-1.5 bg-primary-500"
                  style={{ width: `${quantityPercentWidth(quantity, recieved_quantity)}%` }}
                />
                <div className="h-1.5" />
              </div>
            </div>
          </Popover>
        </TableItemWrapper>
      )
    },
    {
      key: 5,
      width: 180,
      minWidth: 180,
      title: "Qabul qilish",
      render: (_, __, index) => (
        <Input
          withoutMessage
          withLabel={false}
          placeholder="Soni"
          control={form.control}
          rootClassName="warehouse-quantity"
          onChange={e => onChangeQuantity(e, index)}
          name={`warehouse_products.${index}.quantity`}
        />
      ),
      hidden: !isAccept,
      className: cx({ "warehouse-quantity-cell": isAccept })
    },
    {
      key: 6,
      width: 180,
      minWidth: 180,
      title: "Omborxona",
      render: record => (
        <TableItemWrapper form={form} id={record?.id} name="warehouse_id">
          {record?.warehouse?.name as string}
        </TableItemWrapper>
      ),
      active: true
    },
    {
      key: 7,
      width: 120,
      minWidth: 120,
      title: "Loyiha",
      render: record => (
        <TableItemWrapper form={form} id={record?.id} name="project_id">
          {record?.project?.name || "--"}
        </TableItemWrapper>
      ),
      active: true
    },
    {
      key: 0,
      width: 70,
      minWidth: 70,
      title: (
        <div
          onClick={onOpenSettings}
          className={cx("mr-1 flex cursor-pointer items-center justify-end", "view-settings")}
        >
          <SettingsIcon />
        </div>
      ),
      render: record => (
        <ConditionalRender if={!isAccept}>
          <TableItemWrapper form={form} id={record?.id} name="check">
            <div className="flex w-full items-center justify-end">
              <ArrowNarrowRightIcon />
            </div>
          </TableItemWrapper>
        </ConditionalRender>
      ),
      fixed: "right"
    }
  ];

  return allColumns;
};
