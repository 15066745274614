import React from "react";
import classNames from "classnames";

import { useQueryParams } from "hooks/useQueryParams";

import PaymentCalendarContent from "./content/PaymentCalendarContent";
import PaymentCalendarFloadButtons from "./PaymentCalendarFloadButtons";

import styles from "./paymentCalendar.module.scss";

interface IProps {
  isCounterPart?: boolean;
}

const PaymentCalendar: React.FC<IProps> = ({ isCounterPart }) => {
  const { queries } = useQueryParams();
  const { fullscreen } = queries();

  return (
    <div
      className={classNames(styles.calendar, {
        [styles.calendar__fullscreen]: fullscreen
      })}
    >
      <PaymentCalendarContent isCounterPart={isCounterPart} />
      <PaymentCalendarFloadButtons />
    </div>
  );
};

export default PaymentCalendar;
