import { Permission } from "modules/auth";

import { RouteModel } from "../../features/app/utils/models/RouteModel";
import { DetailedProject } from "../../features/projects/pages/detailed-project/DetailedProject";
import { ProjectGantt } from "../../features/projects/pages/detailed-project/pages/estimate/pages/gantt/ProjectGantt";
import { ProjectPlan } from "../../features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";
import { ProjectEstimate } from "../../features/projects/pages/detailed-project/pages/estimate/ProjectEstimate";
import { ProjectWork } from "../../features/projects/pages/detailed-project/pages/work/ProjectWork";
import { DetailedProjectFolder } from "../../features/projects/pages/detailed-project-folder/DetailedProjectFolder";
import { Projects } from "../../features/projects/pages/projects/Projects";
import { Works } from "../../pages/works";

import { projectsPaths } from "./projectsPaths";

export const allProjectsRoutes: RouteModel[] = [
  {
    element: Projects,
    path: projectsPaths.INDEX,
    isExact: true,
    permissionName: "Loyihalar"
  },
  {
    element: DetailedProjectFolder,
    path: projectsPaths.DETAILED_FOLDER,
    isExact: true,
    permissionName: "Papkalar"
  },
  {
    element: DetailedProject,
    path: projectsPaths.DETAILED_PROJECT.INDEX,
    permissionName: "Smetani ko'rish",
    permissionModuleName: "Smeta",
    isExact: false,
    children: [
      {
        element: ProjectEstimate,
        path: projectsPaths.DETAILED_PROJECT.ESTIMATE.INDEX,
        isExact: false,
        permissionName: "Smetani ko'rish",
        children: [
          {
            element: ProjectPlan,
            index: true,
            isExact: false,
            permissionName: "Smetani ko'rish"
          },
          {
            element: ProjectGantt,
            path: projectsPaths.DETAILED_PROJECT.ESTIMATE.GANTT,
            isExact: true,
            permissionName: "Gant chart"
          }
        ]
      },
      {
        element: Works,
        path: projectsPaths.DETAILED_PROJECT.WORK,
        isExact: true,
        permissionModuleName: "Smeta",
        permissionName: "Ishlar"
      }
    ]
  }
];

export const projectsRoutes = ({ permissions }: { permissions: Permission[] }): RouteModel[] => {
  const routes: RouteModel[] = [];

  const projectPermissions = permissions?.find(item => item.name.uz === "Loyiha");

  const pushRoute = (route: RouteModel) => {
    if (route?.permissionModuleName) {
      const findSubmodule = projectPermissions?.submodules?.find(item => item.name.uz === route.permissionModuleName);
      const findAction = findSubmodule?.actions?.some(item => item.name?.uz === route.permissionName);

      if (findAction) {
        routes.push(route);
      }
    } else {
      const findSubmodule = projectPermissions?.submodules?.some(item => item.name.uz === route.permissionName);

      if (findSubmodule) {
        routes.push(route);
      }
    }
  };

  allProjectsRoutes?.forEach(route => {
    if (route.children) {
      pushRoute(route);

      route.children?.forEach(childRoute => {
        pushRoute(childRoute);

        if (childRoute?.children) {
          childRoute?.children?.forEach(item => {
            pushRoute(item);
          });
        }
      });
    } else {
      pushRoute(route);
    }
  });

  return routes;
};
