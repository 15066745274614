import React from "react";
import { useDispatch } from "react-redux";
import { FieldErrors, UseFormReturn } from "react-hook-form";
import { Spin } from "antd";

import { partyReducerActions } from "store/actions";

import { colors, parseLocaledString } from "modules/common";
import { PartyViewForm, useProductUpdate } from "modules/party/view";

import { CheckCircleIcon, LoadingIndicator } from "components";

type Props = {
  form: UseFormReturn<PartyViewForm>;
};

const Check: React.FC<Props> = ({ form }) => {
  const dispatch = useDispatch();
  const update = useProductUpdate();
  const { setPartyViewUpdateProduct } = partyReducerActions;

  const onSubmit = async ({ product }: PartyViewForm) => {
    await new Promise(onSettled => {
      // @ts-ignore
      delete product?.total_amount;

      update
        .mutateAsync(
          {
            ...product,
            amount: parseLocaledString(String(product?.amount)),
            quantity: parseLocaledString(String(product?.quantity))
          },
          { onSettled }
        )
        .then(() => {
          dispatch(setPartyViewUpdateProduct(undefined));
          form.reset({
            product: undefined,
            warehouse_products: undefined
          });
        });
    });
  };

  const onError = (error: FieldErrors) => {
    console.log("Update product ERRORS", error);
    console.log("Update product VALUES", form.getValues());
  };

  return (
    <Spin spinning={form.formState.isSubmitting} indicator={LoadingIndicator}>
      <div className="flex w-full items-center justify-center">
        <div
          onClick={form.handleSubmit(onSubmit, onError)}
          className="box-border flex w-max cursor-pointer items-center justify-end rounded-lg border border-solid border-primary-600 bg-primary-500 px-2 py-1.5"
        >
          <CheckCircleIcon stroke={colors.WHITE} />
        </div>
      </div>
    </Spin>
  );
};

export default Check;
