import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Collapse, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { cx, isEmptyArr } from "modules/common";
import { ChevronDownIcon, setBuildings, useUysotBuildings, UysotReducer } from "modules/settings/uysot";

import { LoadingIndicator, UysotLogoIcon } from "components";

import BuildingCheckbox from "./checkbox";
import BuildingContentChildren from "./children";
import BuildingStatus from "./status";

import styles from "./index.module.scss";

const UysotBuildingContent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { step } = useAppSelector(({ uysot }) => uysot);
  const { buildings, isLoading, isFetching } = useUysotBuildings();

  useEffect(() => {
    if (!isEmptyArr(buildings) && step === 3) {
      const filterBuildings: UysotReducer["buildings"] = [];

      buildings.forEach(({ buildings: houses }) => {
        houses.forEach(({ cash, id, project, status, name }) => {
          if (cash?.id && project?.id) {
            filterBuildings.push({
              name,
              status,
              building_id: id,
              cash_id: cash?.id,
              project_id: project?.id,
              cash_name: cash?.name as string,
              project_name: project?.name as string
            });
          }
        });
      });

      dispatch(setBuildings(filterBuildings));
    }
  }, [buildings]);

  return (
    <Spin indicator={LoadingIndicator} spinning={isLoading || isFetching}>
      <div className="box-border max-h-[36rem] min-h-60 overflow-y-auto px-4">
        <div className="flex flex-col gap-3">
          {buildings?.map(({ id, name, buildings: houses }) => (
            <Collapse
              key={id}
              expandIconPosition="right"
              rootClassName={styles.collapse}
              expandIcon={({ isActive }) => (
                <div className="flex items-center gap-2">
                  <span
                    className={cx("duration-200 ease-in-out")}
                    style={{
                      transform: `rotate(${isActive ? 0 : -180}deg)`
                    }}
                  >
                    <ChevronDownIcon />
                  </span>
                  <BuildingStatus buildings={houses} />
                </div>
              )}
              items={[
                {
                  key: id,
                  label: (
                    <div className="flex items-center gap-4">
                      <BuildingCheckbox buildings={houses} />
                      <UysotLogoIcon />
                      <div className="flex flex-col">
                        <span className="text-base font-semibold text-gray-700">{name}</span>
                        <span className="text-xs font-medium text-gray-400">
                          {houses?.length || 0} {t("uysot.ta bino")}
                        </span>
                      </div>
                    </div>
                  ),
                  children: <BuildingContentChildren buildings={houses} />
                }
              ]}
            />
          ))}
        </div>
      </div>
    </Spin>
  );
};

export default UysotBuildingContent;
