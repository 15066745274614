import React from "react";
import { useDispatch } from "react-redux";
import { UseFieldArrayAppend, UseFormReturn, useWatch } from "react-hook-form";
import { Button } from "antd";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/reducers/partyReducer";

import { cx } from "modules/common";
import { PartyForm } from "modules/party/create";
import { appendedProducts, parseAppendedProducts, PartyEstimateForm } from "modules/party/estimate";

type Props = {
  parentForm: UseFormReturn<PartyForm>;
  form: UseFormReturn<PartyEstimateForm>;
  append: UseFieldArrayAppend<PartyForm, "warehouse_products">;
  appendCheck: UseFieldArrayAppend<PartyForm, "check_products">;
  groupAppend: UseFieldArrayAppend<PartyForm, "company_person_group">;
};

const Index: React.FC<Props> = ({ form, appendCheck, append, groupAppend, parentForm }) => {
  const dispatch = useDispatch();
  const { setPartyEstimate } = partyReducerActions;
  const { products, project_id, currency_id, currency_symbol } = useWatch({ control: form.control });
  const disabled = !(products?.length && products?.length > 0);
  const { isGroup: isGroupCreate } = useAppSelector(({ partyReducer }) => partyReducer.create);
  const { isGroup: isGroupUpdate } = useAppSelector(({ partyReducer }) => partyReducer.update);

  const onClose = () => {
    dispatch(
      setPartyEstimate({
        visible: false
      })
    );
  };

  const onReset = () => {
    form.reset({
      project_id,
      products: []
    });
  };

  const onAppend = () => {
    if (isGroupCreate || isGroupUpdate) {
      const personGroup = parentForm.getValues("company_person_group");
      const notPersonGroupIndex = personGroup?.findIndex(({ id }) => !id);

      if (typeof notPersonGroupIndex !== "undefined" && notPersonGroupIndex > -1) {
        parentForm.setValue(`company_person_group.${notPersonGroupIndex}.products`, [
          ...(personGroup?.[notPersonGroupIndex]?.products || []),
          ...((parseAppendedProducts(
            products as never,
            appendCheck,
            currency_id,
            currency_symbol,
            project_id
          ) as never) || [])
        ]);
      } else {
        groupAppend({
          id: 0,
          name: "Belgilanmagan",
          products: [
            ...((parseAppendedProducts(
              products as never,
              appendCheck,
              currency_id,
              currency_symbol,
              project_id
            ) as never) || [])
          ]
        });
      }
    } else {
      appendedProducts(products as never, appendCheck, append, currency_id, currency_symbol, project_id);
    }

    onClose();
  };

  return (
    <div className="sticky bottom-0 flex justify-between border border-b-0 border-l-0 border-r-0 border-solid border-gray-300 bg-white p-4">
      <div className="flex items-center text-base font-semibold text-gray-600">
        Tanlangan mahsulotlar: {products?.length} ta
      </div>
      <div className="flex items-center gap-2">
        <Button onClick={onClose}>Yopish</Button>
        <Button onClick={onReset} disabled={disabled} className={cx("reject-button")}>
          Qo'shilganlarni bekor qilish
        </Button>
        <Button type="primary" disabled={disabled} onClick={onAppend}>
          Qo'shish
        </Button>
      </div>
    </div>
  );
};

export default Index;
