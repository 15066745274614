import React from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, message } from "antd";
import $api from "features/app/utils/helpers/axiosInstance";
import { warehouseQueryNames } from "features/warehouse/utils/constants/warehouseQueryNames";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { errorHandler, ErrorRes, isEmptyArr, parseLocaledString, SuccessRes } from "modules/common";

import { parseCreateTransferPayload } from "../helpers";
import { CreateTransferPayload } from "../schema";
import { setVisible } from "../slice";

const Footer: React.FC = () => {
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const state = useAppSelector(({ warehouseCreateTransfer }) => warehouseCreateTransfer);
  const { warehouses, errors, sender_warehouse_id } = state;

  const onClose = () => {
    dispatch(setVisible(false));
  };

  const { mutate, isLoading } = useMutation<SuccessRes, ErrorRes, CreateTransferPayload>(
    async payload => {
      const { data } = await $api.post("warehouse-transfer/create", payload);

      return data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        message.success(res.message?.[i18n.language]);

        await qc.invalidateQueries([warehouseQueryNames.TRANSFER_VIEW]);

        onClose();
      }
    }
  );

  const onSubmit = () => {
    mutate(parseCreateTransferPayload(state));
  };

  const disabled =
    !sender_warehouse_id ||
    !isEmptyArr(errors) ||
    isEmptyArr(warehouses) ||
    warehouses?.some(
      ({ products }) =>
        isEmptyArr(products) || !products?.every(({ quantity }) => quantity && parseLocaledString(quantity || "0") > 0)
    );

  return (
    <div className="flex items-center justify-end gap-3 border border-solid border-gray-200 bg-white px-4 py-5">
      <Button onClick={onClose} disabled={isLoading}>
        {t("warehouseTransfer.Yopish")}
      </Button>
      <Button type="primary" onClick={onSubmit} disabled={disabled} loading={isLoading}>
        {t("warehouseTransfer.O'tkazma qilish")}
      </Button>
    </div>
  );
};

export default Footer;
