import React from "react";
import DefectView from "features/inventory/components/defects/bottom/defect-view/DefectView";

import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const InventoryDefectRecieved = ({ record, onNavigatePage, setOpen, open }: Props) => (
  <div className={styles.description} onClick={() => setOpen(true)}>
    <h3>{notificationTypesTitleValue[record?.type]}</h3>
    <p>
      {record?.user?.full_name} {record?.inventory_defect?.inventory?.code} raqamli{" "}
      {record?.inventory_defect?.inventory?.name} yaroqsizligini tasdiqladi
    </p>
    <DefectView
      open={open}
      setOpen={setOpen}
      record={
        {
          id: record?.inventory_defect?.id
        } as never
      }
      setId={() => {}}
    />
  </div>
);

export default InventoryDefectRecieved;
