import React from "react";
import BaseAutoComplete, { AutoCompleteProps as BaseAutoCompleteProps } from "antd/lib/auto-complete";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";

import { cx } from "modules/common";

import Wrapper, { BaseProps } from "./wrapper";

import styles from "./wrapper.module.scss";

interface Props extends BaseProps, BaseAutoCompleteProps {
  name: string;
  searchableKey?: string;
}

const AutoComplete: React.FC<Props> = ({
  name,
  label,
  control,
  onClear,
  onChange,
  suffixIcon,
  placeholder,
  rootClassName,
  labelClassName,
  withoutMessage,
  errorClassName,
  notFoundContent,
  wrapperClassName,
  showSearch = true,
  searchableKey = "label",
  ...args
}) => (
  <Wrapper {...{ control, name, label, withoutMessage, labelClassName, errorClassName, wrapperClassName }}>
    {(field, error) => (
      <BaseAutoComplete
        {...field}
        {...args}
        id={name}
        value={field.value}
        showSearch={showSearch}
        notFoundContent={notFoundContent}
        placeholder={placeholder || label}
        status={error ? "error" : undefined}
        onChange={onChange || field.onChange}
        rootClassName={cx(rootClassName, styles.select)}
        suffixIcon={typeof suffixIcon !== "undefined" ? suffixIcon : <ChevronDownIcon rotate={true} />}
      />
    )}
  </Wrapper>
);

export default AutoComplete;
