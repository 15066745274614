import React from "react";
import { FieldErrors, useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { ActivatingMessagingSystemsForm, activatingMessagingSystemsSchema } from "../schema";

import Creator from "./creator";
import Login from "./login";
import Password from "./password";

type Props = {
  children: (props: UseFormReturn<ActivatingMessagingSystemsForm>) => React.ReactNode;
};

const ActivatingMessagingSystems: React.FC<Props> = ({ children }) => {
  const formInstance = useForm<ActivatingMessagingSystemsForm>({
    resolver: zodResolver(activatingMessagingSystemsSchema)
  });

  const onSubmit = (values: ActivatingMessagingSystemsForm) => {
    console.log("values:", values);
  };

  const onError = (error: FieldErrors) => {
    console.log(error);
  };

  return (
    <form className="flex w-full flex-col gap-5" onSubmit={formInstance.handleSubmit(onSubmit, onError)}>
      <Login form={formInstance} />
      <Password form={formInstance} />
      <Creator form={formInstance} />
      {children(formInstance)}
    </form>
  );
};

export default ActivatingMessagingSystems;
