import React, { useEffect, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Form, Input, message, Spin, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import ChatMessage from "features/app/components/chat/ChatMessage";
import EmptyMessage from "features/app/components/chat/EmptyMessage";
import { useCreateChat, useUploadFile } from "features/app/service/mutation";

import { isEmptyArr } from "modules/common";

import { LoadingIndicator, PaperClipIcon, SendMessageIcon } from "components";

import { useComments } from "../queries";
import { commentsQueryKeys } from "../query-keys";
import { CommentPayload, ObjectType } from "../schema";

import styles from "./index.module.scss";

type Props = {
  object_id: number;
  type: ObjectType;
};

const Index: React.FC<Props> = ({ type, object_id }) => {
  const qc = useQueryClient();
  const [form] = Form.useForm();
  const ref = useRef<HTMLDivElement>(null);
  const createChat = useCreateChat();
  const uploadFile = useUploadFile();
  const { comments, isLoading } = useComments({ type, object_id });

  const onCreateChat = (reqData: CommentPayload) => {
    if (reqData.message || reqData.file_ids) {
      createChat.mutateAsync(reqData).then(() => {
        qc.invalidateQueries([commentsQueryKeys.view, object_id, type]);
        form.resetFields();
      });
    } else {
      message.info("Xabar yoki fayl kiritilmagan");
    }
  };

  const onChangeFile = (e: UploadChangeParam<UploadFile>) => {
    if (e.file.size! >= 5120000) {
      message.error("File hajmi 5120 kb dan oshmasligi zarur");
    } else {
      const file = e?.file?.originFileObj;

      form.setFieldsValue({ file });
      form.submit();
    }
  };

  const onFinish = (fields: { message: string; files: UploadFile[] }) => {
    const reqData: CommentPayload = {
      type,
      object_id,
      message: undefined,
      file_ids: undefined
    };

    if (fields.files?.length > 0) {
      delete reqData.message;
      const formData = new FormData();

      fields.files?.forEach(file => {
        formData.append("files[]", file.originFileObj as Blob);
      });

      uploadFile.mutateAsync(formData).then(res => {
        reqData.file_ids = res;
        onCreateChat(reqData);
      });
    } else {
      delete reqData.file_ids;
      reqData.message = fields.message;
      onCreateChat(reqData);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  useEffect(() => {
    ref.current?.scrollTo({ top: ref.current?.scrollHeight });
  }, [object_id]);

  return (
    <div className={styles.comments}>
      <div className={`${styles.top} ${isEmptyArr(comments) ? styles.empty_comments : ""}`} ref={ref}>
        <Spin spinning={isLoading || createChat.isLoading || uploadFile.isLoading} indicator={LoadingIndicator}>
          {!isEmptyArr(comments) ? (
            comments?.map((item, index, arr) => {
              if (index === 0) {
                return <ChatMessage {...item} key={index} />;
              }

              return <ChatMessage {...item} key={index} prevDate={arr[index - 1]?.created_at} />;
            })
          ) : (
            <div className="flex h-full items-center justify-center">
              <EmptyMessage />
            </div>
          )}
        </Spin>
      </div>
      <div className={styles.bottom}>
        <Form form={form} layout="inline" onFinish={onFinish}>
          <Form.Item name="message">
            <Input placeholder="Izoh kiriting... " autoFocus disabled={createChat.isLoading || uploadFile.isLoading} />
          </Form.Item>
          <Form.Item name="files" valuePropName="fileList" getValueFromEvent={normFile}>
            <Upload multiple name="files" showUploadList={false} onChange={onChangeFile} customRequest={() => null}>
              <Button>
                <PaperClipIcon />
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={() => form.submit()}>
              <SendMessageIcon />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Index;
