import React from "react";

const CircleBoxIcon = () => (
  <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="5" width="56" height="56" rx="28" fill="#D1E9FF" />
    <rect x="5" y="5" width="56" height="56" rx="28" stroke="#EFF8FF" strokeWidth="10" />
    <path
      d="M42.9166 27.4906L33 32.9999M33 32.9999L23.0833 27.4906M33 32.9999L33 44.0833M43.5 37.7349V28.265C43.5 27.8652 43.5 27.6653 43.4411 27.4871C43.389 27.3294 43.3038 27.1846 43.1913 27.0625C43.064 26.9244 42.8893 26.8273 42.5399 26.6332L33.9065 21.8369C33.5757 21.6531 33.4102 21.5612 33.235 21.5251C33.08 21.4932 32.92 21.4932 32.765 21.5251C32.5898 21.5612 32.4243 21.6531 32.0935 21.8369L23.4601 26.6332C23.1107 26.8273 22.936 26.9244 22.8087 27.0625C22.6962 27.1846 22.611 27.3294 22.5589 27.4871C22.5 27.6653 22.5 27.8652 22.5 28.265V37.7349C22.5 38.1347 22.5 38.3345 22.5589 38.5128C22.611 38.6705 22.6962 38.8153 22.8087 38.9374C22.936 39.0755 23.1107 39.1725 23.4601 39.3667L32.0935 44.163C32.4243 44.3468 32.5898 44.4387 32.765 44.4747C32.92 44.5066 33.08 44.5066 33.235 44.4747C33.4102 44.4387 33.5757 44.3468 33.9065 44.163L42.5399 39.3667C42.8893 39.1725 43.064 39.0755 43.1913 38.9374C43.3038 38.8153 43.389 38.6705 43.4411 38.5128C43.5 38.3345 43.5 38.1347 43.5 37.7349Z"
      stroke="#1E90FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.25 30.0833L27.75 24.25"
      stroke="#1E90FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircleBoxIcon;
