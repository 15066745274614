import IntegrationIcon from "features/app/assets/icons/IntegrationIcon";
import BuildingIcon from "features/payment/assets/icons/BuildingIcon";
import FileCheckIcon from "features/payment/assets/icons/FileCheckIcon";

import { colors } from "modules/common";

import { projectsPaths } from "../../../../../routes/projects/projectsPaths";
import { rootPaths } from "../../../../../routes/root/rootPaths";
import { settingsPaths } from "../../../../../routes/settings/settingsPaths";
import CounterpartsIcon from "../../../assets/icons/CounterpartsIcon";
import ImpostIcon from "../../../assets/icons/ImpostIcon";
import InventoryIcon from "../../../assets/icons/InventoryIcon";
import MainPaymentIcon from "../../../assets/icons/MainPaymentIcon";
import MainWarehouseIcon from "../../../assets/icons/MainWarehouseIcon";
import ProjectIcon from "../../../assets/icons/ProjectIcon";
import SettingsBackUpIcon from "../../../assets/icons/SettingsBackUpIcon";
import SettingsCashIcon from "../../../assets/icons/SettingsCashIcon";
import SettingsEmployeesIcon from "../../../assets/icons/SettingsEmployeesIcon";
import SettingsFinanceIcon from "../../../assets/icons/SettingsFinanceIcon";
import SettingsFinancialIcon from "../../../assets/icons/SettingsFinancialIcon";
import SettingsIcon from "../../../assets/icons/SettingsIcon";
import SettingsIntegrationIcon from "../../../assets/icons/SettingsIntegrationIcon";
import SettingsInterfaceIcon from "../../../assets/icons/SettingsInterfaceIcon";
import SettingsProductsIcon from "../../../assets/icons/SettingsProductsIcon";
import SettingsWarehouseIcon from "../../../assets/icons/SettingsWarehouseIcon";
import SupplyIcon from "../../../assets/icons/SupplyIcon";
import UserIcon from "../../../assets/icons/UserIcon";

export const breadcrumbIcons = {
  [rootPaths.PROJECTS]: <ProjectIcon />,
  [rootPaths.PROJECTS + projectsPaths.DETAILED_FOLDER]: <ProjectIcon />,
  [rootPaths.PAYMENT]: <MainPaymentIcon />,
  [rootPaths.COUNTERPARTS]: <CounterpartsIcon />,
  [rootPaths.DETAILED_COUNTERPART]: <CounterpartsIcon />,
  [rootPaths.DETAILED_FOLDER_COUNTERPARTS]: <UserIcon />,
  [rootPaths.DETAILED_COUNTERPARTS]: <UserIcon />,
  [rootPaths.WAREHOUSE]: <MainWarehouseIcon />,
  [rootPaths.SUPPLY]: <SupplyIcon />,
  [rootPaths.INVENTORY]: <InventoryIcon />,
  [rootPaths.IMPOST]: <ImpostIcon />,
  [rootPaths.DETAILED_INVENTORY]: <InventoryIcon />,
  [rootPaths.SETTINGS + settingsPaths.PRODUCTS]: <SettingsProductsIcon />,
  [rootPaths.SETTINGS + settingsPaths.ADMINS]: <SettingsEmployeesIcon />,
  [rootPaths.SETTINGS + settingsPaths.ADMINS_ROLES]: <SettingsEmployeesIcon />,
  [rootPaths.SETTINGS + settingsPaths.INTERFACE]: <SettingsInterfaceIcon />,
  [rootPaths.SETTINGS + settingsPaths.CASH]: <SettingsCashIcon />,
  [rootPaths.SETTINGS + settingsPaths.WAREHOUSE]: <SettingsWarehouseIcon />,
  [rootPaths.SETTINGS + settingsPaths.FINANCIAL]: <SettingsFinancialIcon />,
  [rootPaths.SETTINGS + settingsPaths.INTEGRATION]: <SettingsIntegrationIcon />,
  [rootPaths.SETTINGS + settingsPaths.FINANCE]: <SettingsFinanceIcon />,
  [rootPaths.SETTINGS + settingsPaths.BACK_UP]: <SettingsBackUpIcon />,
  [rootPaths.SETTINGS + settingsPaths.SUBCATEGORY]: <SettingsProductsIcon />,
  [rootPaths.SETTINGS + settingsPaths.DETAILED_SUBCATEGORY]: <SettingsProductsIcon />,
  [rootPaths.SETTINGS + settingsPaths.DETAILED_CASH]: <SettingsCashIcon />,
  [rootPaths.SETTINGS + settingsPaths.DETAILED_WAREHOUSE]: <SettingsWarehouseIcon />,
  [rootPaths.SETTINGS + settingsPaths.INTEGRATION]: <IntegrationIcon />,
  [rootPaths.SETTINGS]: <SettingsIcon />,
  [rootPaths.DASHBOARD]: <BuildingIcon />,
  [rootPaths.WORKS]: <FileCheckIcon stroke={colors.GRAY_500} />
};
