import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Drawer, Dropdown, Spin, Switch } from "antd";
import classNames from "classnames";
import OrderedIcon from "features/app/assets/icons/OrderedIcon";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import { PageKeys, StatusEnums } from "features/app/components/statuses/statusEnums";
import { LocalStorage } from "features/app/service/LocalStorage";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";
import { paymentQueryKeys } from "features/payment/utils/constants/paymentQueryKeys";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";
import { PaymentOrderDetailModel } from "features/payment/utils/models/paymentOrderDetailModel";
import PartyProcess from "features/supply/components/parts/party-process/party-process";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

import { useAppSelector } from "hooks/redux";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { chatActions } from "store/reducers/chatReducer";
import { paymentReducerActions } from "store/reducers/paymentReducer";
import { supplyActions } from "store/reducers/supplyReducer";

import { colors, cx } from "modules/common";
import { usePartyReceived, usePartyRejected } from "modules/party/view";

import { ConditionalRender } from "components";

import CloseIcon from "../../../../app/assets/icons/CloseIcon";
import Statuses from "../../../../app/components/statuses/Statuses";
import UnreadMessageAction from "../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import PrinterIcon from "../../../../counterparts/assets/icons/PrinterIcon";
import ClockFastForwardIcon from "../../../../supply/assets/icons/ClockFastForwardIcon";
import CreditCardUploadIcon from "../../../assets/icons/CreditCardUploadIcon";
import { useGetPaymentOrder } from "../../../service/queries";
import OrderPayment from "../order-payment/OrderPayment";
import OrderPrint from "../order-print/OrderPrint";

import OrderViewBottom from "./bottom/OrderViewBottom";
import OrderViewTop from "./top/OrderViewTop";

import styles from "./orderView.module.scss";

type Props = {
  isCounterpart?: boolean;
};

const OrderView: React.FC<Props> = ({ isCounterpart }) => {
  const { t } = useTranslation();
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const rejected = usePartyRejected();
  const received = usePartyReceived();
  const { setVisible } = chatActions;
  const { setIsPartyProcess } = supplyActions;
  const partyProcess = LocalStorage.get("is_party_process");
  const printComponentRef = useRef<HTMLDivElement | null>(null);
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const paymentActions = actions("Buyurtmalarga to'lovlar");
  const { isPartyProcess } = useAppSelector(state => state.supplyReducer.partyModal);
  const { visible: open, orderId } = useAppSelector(state => state.paymentReducer.orderView);
  const { data: paymentOrderDetail, isLoading, isFetching } = useGetPaymentOrder(orderId, isCounterpart, open);
  const { setOrderView, setOrderDeleteArchive, setPaymentOrderModal } = paymentReducerActions;

  const onCancel = () => {
    dispatch(
      setOrderView({
        orderId,
        visible: false
      })
    );
  };

  const onOpenChat = () => {
    dispatch(
      setVisible({
        visible: true,
        objectId: paymentOrderDetail?.order?.id,
        type: "orders",
        zIndex: 20000,
        onAfterOpen: () => {
          if (paymentOrderDetail?.order?.unread_message_count && paymentOrderDetail?.order?.unread_message_count > 0) {
            qc.setQueryData([paymentQueryKeys.PAYMENT_ORDER_VIEW], (data: any) => {
              const initialData = data as PaymentOrderDetailModel;

              return {
                ...initialData,
                order: { ...initialData.order, unread_message_count: 0 }
              };
            });
          }
        }
      })
    );
  };

  const emptyFunc = () => {};

  const reactToPrintContent = useCallback(() => printComponentRef.current, [printComponentRef]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `P-${orderId}`,
    onBeforePrint: emptyFunc,
    onAfterPrint: emptyFunc,
    removeAfterPrint: true
  });

  const onSwitchChange = (value: boolean) => {
    dispatch(setIsPartyProcess({ isPartyProcess: value }));
    if (value) {
      LocalStorage.set("is_party_process", value);
    } else {
      LocalStorage.remove("is_party_process");
    }
  };

  const onOpenOrderDeleteArchive = () => {
    dispatch(
      setOrderDeleteArchive({
        orderId,
        visible: true,
        order: paymentOrderDetail?.order
      })
    );
  };

  const onOpenExpensePayment = () => {
    dispatch(
      setPaymentOrderModal({
        visible: true,
        isOrderDelete: true,
        type: PaymentTypeEnum.EXPENSE,
        order: paymentOrderDetail?.order,
        queryKey: [paymentQueryKeys.PAYMENT_ORDER_VIEW, orderId, Boolean(isCounterpart)]
      })
    );
  };

  const allItems = [
    {
      key: "1",
      label: (
        <div className={styles.dropdown_item}>
          <UnreadMessageAction count={paymentOrderDetail?.order?.unread_message_count} />
        </div>
      ),
      onClick: () => onOpenChat(),
      permissionKey: "chatView"
    },
    {
      key: "2",
      label: (
        <div className={styles.dropdown_item}>
          <PrinterIcon /> {t("payment.Chop etish")}
        </div>
      ),
      onClick: handlePrint,
      isView: !!orderId
    },
    {
      key: "3",
      label: (
        <div className={styles.dropdown_item}>
          <CreditCardUploadIcon isNotArrow />
          Xarajat qo'shish
        </div>
      ),
      onClick: onOpenExpensePayment,
      permissionKey: "create"
    },
    {
      key: "4",
      label: (
        <div className={styles.dropdown_item}>
          <ClockFastForwardIcon />
          To'lovlar tarixi
        </div>
      ),
      onClick: onOpenOrderDeleteArchive,
      isView: paymentActions?.orderHistory
    },
    {
      key: "5",
      label: (
        <div className={styles.dropdown_item}>
          <OrderedIcon stroke={colors.GRAY_600} size={20} /> Partiya jarayoni{" "}
          <Switch value={isPartyProcess} defaultValue={Boolean(partyProcess)} onChange={onSwitchChange} />
        </div>
      )
    }
  ];

  const title = () => (
    <div className={styles.title}>
      <div className={styles.title__info}>
        <h2>P-{paymentOrderDetail?.order?.id}</h2>
        {isPartyProcess && paymentOrderDetail && paymentOrderDetail?.order && (
          <PartyProcess
            supply_status={paymentOrderDetail.order.status!}
            warehouse_status={paymentOrderDetail.order.percent!}
            payment_status={paymentOrderDetail.order.payment_percent!}
            monitoring_status={paymentOrderDetail.order.confirmation_payment!}
          />
        )}
        {!isPartyProcess && (
          <Statuses statusKey={paymentOrderDetail?.order?.status as StatusEnums} pageKey={PageKeys.PARTY} />
        )}
      </div>
      <div className={styles.title__right}>
        <span>
          <Dropdown
            trigger={["click"]}
            placement="bottom"
            menu={{
              items: allItems?.filter(item => {
                if (item.permissionKey) {
                  return paymentActions[item.permissionKey as never];
                }
                return true;
              })
            }}
          >
            <div className="flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg border border-solid border-gray-300 bg-gray-50 p-0">
              <UnreadMessageDots
                className={classNames({
                  hasBadge: !!paymentOrderDetail?.order?.unread_message_count
                })}
              />
            </div>
          </Dropdown>
        </span>
        <div onClick={onCancel} className={styles.close_icon}>
          <CloseIcon />
        </div>
      </div>
    </div>
  );

  const onRejected = () => {
    rejected.mutateAsync({ id: orderId }).then(onCancel);
  };

  const onReceived = () => {
    received.mutateAsync({ id: orderId }).then(onCancel);
  };

  const footer = () => {
    const status = paymentOrderDetail?.order?.status;
    const acceptAction = !(status === "open" || status === "rejected" || status === "recieved");

    return (
      <div className={cx(styles.footer, { "opacity-0": isLoading || isFetching })}>
        <Button onClick={onCancel} disabled={rejected.isLoading || received.isLoading}>
          {t("payment.Yopish")}
        </Button>
        <ConditionalRender if={acceptAction && paymentActions?.rejected}>
          <Button
            onClick={onRejected}
            className="bg-gray-200"
            loading={rejected.isLoading}
            disabled={received.isLoading}
          >
            {t("partyView.Bekor qilish")}
          </Button>
        </ConditionalRender>
        <ConditionalRender if={acceptAction && paymentActions?.completed}>
          <Button type="primary" onClick={onReceived} loading={received.isLoading} disabled={rejected.isLoading}>
            {t("partyView.Yakunlash")}
          </Button>
        </ConditionalRender>
      </div>
    );
  };

  return (
    <>
      <Drawer
        open={open}
        width={1570}
        zIndex={11113}
        title={title()}
        footer={footer()}
        closeIcon={false}
        onClose={onCancel}
        className={styles.order_view}
      >
        <div className={styles.content}>
          <Spin spinning={isLoading || isFetching} indicator={LoadingIndicator}>
            <OrderViewTop
              order={paymentOrderDetail?.order}
              warehouse_products={paymentOrderDetail?.warehouse_products}
            />
            <OrderViewBottom
              isLoading={isLoading}
              paymentOrderDetail={paymentOrderDetail}
              warehouseProducts={paymentOrderDetail?.warehouse_products}
            />
          </Spin>
        </div>
        <OrderPayment />
      </Drawer>
      <OrderPrint
        ref={printComponentRef}
        data={{
          oneData: paymentOrderDetail?.order,
          id: paymentOrderDetail?.order?.id as number,
          data: paymentOrderDetail?.warehouse_products as never
        }}
      />
    </>
  );
};

export default OrderView;
