import React from "react";
import { useDispatch } from "react-redux";
import { Button, DatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { dayjsFormats } from "modules/common";
import { CalendarPlusIcon, setStartDate, setVisible, useIntegrationUpdate } from "modules/settings/uysot";

import styles from "./index.module.scss";

const UysotDateContent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mutateAsync, isLoading } = useIntegrationUpdate();
  const { start_date, id, step } = useAppSelector(({ uysot }) => uysot);

  const onChange: DatePickerProps["onChange"] = (_, dateString) => {
    dispatch(setStartDate((dateString as string) || null));
  };

  const onSave = () => {
    mutateAsync({
      id,
      start_date: start_date || null
    }).then(() => {
      dispatch(
        setVisible({
          id,
          step,
          start_date,
          visible: false
        })
      );
    });
  };

  return (
    <div className="flex items-end justify-between">
      <div className="flex flex-col gap-1.5">
        <label className="text-sm font-medium text-gray-700">{t("uysot.Sana")}</label>
        <DatePicker
          allowClear={false}
          onChange={onChange}
          format={dayjsFormats.DATE}
          rootClassName={styles.date}
          placeholder={t("uysot.Sana")}
          suffixIcon={<CalendarPlusIcon />}
          value={start_date ? dayjs(start_date, dayjsFormats.DATE) : dayjs()}
        />
      </div>
      <div className="flex items-center justify-end gap-4">
        <Button type="primary" onClick={onSave} loading={isLoading}>
          {t("uysot.Saqlash")}
        </Button>
      </div>
    </div>
  );
};

export default UysotDateContent;
