import React from "react";
import { UseFormReturn } from "react-hook-form";
import { localeFormatter } from "features/app/utils/helpers/localeFormatter";

import { parseLocaledString } from "modules/common";
import { PartyForm } from "modules/party/create";

import { Input } from "components";

type Props = {
  index: number;
  parentIndex: number;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, index, parentIndex }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parseValue = parseLocaledString(e.target.value);
    const quantity = parseLocaledString(
      form.getValues(`company_person_group.${parentIndex}.products.${index}.quantity`) || ""
    );
    const vat_value = form.getValues(`company_person_group.${parentIndex}.products.${index}.vat_value`);

    form.setValue(`company_person_group.${parentIndex}.products.${index}.total`, localeFormatter(e.target.value));
    form.setValue(
      `company_person_group.${parentIndex}.products.${index}.amount`,
      localeFormatter(String(parseValue / quantity))
    );
    form.setValue(
      `company_person_group.${parentIndex}.products.${index}.vat_amount`,
      parseLocaledString(String(parseValue) || "0") * ((vat_value || 0) / 100)
    );
  };

  return (
    <Input
      withoutMessage
      onChange={onChange}
      autoComplete="off"
      control={form.control}
      placeholder="Umumiy narxi"
      errorClassName="party-create-error"
      wrapperClassName="party-create-input"
      name={`company_person_group.${parentIndex}.products.${index}.total`}
    />
  );
};

export default Index;
