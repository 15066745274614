import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Drawer, Dropdown, Form, Switch } from "antd";
import { ItemType } from "antd/lib/menu/interface";
import dayjs from "dayjs";
import GitBranchIcon from "features/app/assets/icons/GitBranchIcon";
import OrderedIcon from "features/app/assets/icons/OrderedIcon";
import { LocalStorage } from "features/app/service/LocalStorage";
import ClockFastForwardIcon from "features/supply/assets/icons/ClockFastForwardIcon";
import PartyProcess from "features/supply/components/parts/party-process/party-process";
import { routeSubmodules } from "features/warehouse/utils/constants/routeSubmodules";
import { WarehouseOrderModel } from "features/warehouse/utils/models/warehouseOrderModel";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

import { useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { supplyActions } from "store/reducers/supplyReducer";

import { colors, cx } from "modules/common";

import { chatActions } from "../../../../../store/reducers/chatReducer";
import { warehouseOrderActions } from "../../../../../store/reducers/warehouseOrderReducer";
import { PageKeys } from "../../../../app/components/statuses/statusEnums";
import Statuses from "../../../../app/components/statuses/Statuses";
import UnreadMessageAction from "../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import { useGetCustomFieldSelect } from "../../../../app/service/queries";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import { ChatEnum } from "../../../../app/utils/enums/chatEnum";
import { CustomFieldLocationEnum } from "../../../../app/utils/enums/customFieldLocationEnum";
import { CustomFieldTypesEnum } from "../../../../app/utils/enums/customFieldTypesEnum";
import { ImpostStatusEnums } from "../../../../app/utils/enums/impostStatusEnums";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import { parseLocaledString } from "../../../../app/utils/helpers/parseLocaledString";
import PrinterIcon from "../../../../counterparts/assets/icons/PrinterIcon";
import { StatusEnums } from "../../../../supply/utils/enums/statusEnums";
import XCloseIcon from "../../../assets/icons/XCloseIcon";
import { useRecievedOrder } from "../../../service/mutation";
import { useGetOneOrder } from "../../../service/query";
import { OrderEnum } from "../../../utils/constants/OrderEnum";
import { warehouseQueryNames } from "../../../utils/constants/warehouseQueryNames";
import { OrderRecievedReqData } from "../../../utils/models/orderRecievedReqModel";

import Left from "./left/Left";
import PrintWarehouseParty from "./print/PrintWarehouseParty";
import Right from "./right/Right";

import styles from "./index.module.scss";

const { useWatch } = Form;

const Index: React.FC = () => {
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const partyActions = actions("Partiyalar");
  const { t } = useTranslation();
  const qc = useQueryClient();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { remove, severalSearchParams } = useQueryParams();
  const printComponentRef = useRef<HTMLDivElement | null>(null);
  const [openDropdown, setOpenDropdown] = useState(false);

  const warehouseProducts: { id: number; quantity: string }[] = useWatch("warehouse_products", form);

  const { visible, data: order } = useAppSelector(state => state.warehouseOrderReducer.view);
  const { setVisibleAdditional } = warehouseOrderActions;

  const { setIsPartyProcess } = supplyActions;
  const { isPartyProcess } = useAppSelector(state => state.supplyReducer.partyModal);
  const partyProcess = LocalStorage.get("is_party_process");

  const isAccept = useAppSelector(state => state.warehouseOrderReducer.accept.visible);

  const { data, isLoading } = useGetOneOrder(order?.id, visible);

  const { setVisible } = chatActions;
  const receivedOrder = useRecievedOrder();
  const { setVisibleViewOrder, setIsAccept } = warehouseOrderActions;
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.ORDER]);

  const acceptLength = () =>
    warehouseProducts?.reduce((accumulator: number, currentValue) => {
      if (currentValue?.quantity !== "0" && currentValue?.quantity !== "") {
        accumulator++;
      }
      return accumulator;
    }, 0);

  const onOpenAfter = (open: boolean) => {
    if (open) {
      form.setFieldsValue({
        company_person_id: order?.company_person?.id,
        agent_id: order?.agent?.id,
        currency_id: order?.currency?.id,
        delivery_date: order?.delivery_date ? dayjs(order?.delivery_date, dayjsFormats.DATE) : null,
        payment_date: order?.payment_date ? dayjs(order?.payment_date, dayjsFormats.DATE) : null
      });

      customFields?.forEach((custom, index) => {
        const currentProduct = order?.custom_field_values?.filter(item => item.custom_field?.id === custom.id);

        if (!isEmptyArr(currentProduct)) {
          const currentCustom = { ...currentProduct?.[0] };

          if (currentCustom?.custom_field?.type === CustomFieldTypesEnum.DATE) {
            const value = dayjs(currentCustom?.value, dayjsFormats.DATE);

            form.setFieldValue(["custom_field_values", index, "value"], value);
          } else if (currentCustom?.custom_field?.type === CustomFieldTypesEnum.SELECT) {
            const optionId = currentCustom?.custom_field_option?.id;

            form.setFieldValue(["custom_field_values", index, "custom_field_option_id"], optionId);
          } else {
            const value = currentCustom?.value;

            form.setFieldValue(["custom_field_values", index, "value"], value);
          }
        }
      });
    } else {
      form.resetFields();
    }
  };

  const onCancel = () => {
    dispatch(
      setVisibleViewOrder({
        data: order,
        visible: false
      })
    );

    dispatch(setIsAccept(false));
    remove(queryParamsKeys.PRODUCT_SEARCH);
  };

  const onOk = () => form.submit();

  const onFinish = (fields: OrderRecievedReqData) => {
    const reqData = {
      id: order!.id,
      warehouse_products: fields?.warehouse_products?.map(item => ({
        id: item?.id,
        quantity: parseLocaledString(String(item?.quantity))
      }))
    };

    receivedOrder.mutateAsync(reqData).then(onCancel);
  };

  const onOpenChat = () => {
    dispatch(
      setVisible({
        visible: true,
        type: ChatEnum.ORDERS,
        objectId: order?.id,
        // dataKeys: [warehouseQueryNames.WAREHOUSE_ORDERS],
        onAfterOpen: () => {
          if (order?.unread_message_count && order?.unread_message_count > 0) {
            qc.setQueryData(
              [warehouseQueryNames.WAREHOUSE_ORDERS, severalSearchParams(queryParamsKeys.TAB)],
              ({ data, current_page, total }: any) => {
                const initialData = data as WarehouseOrderModel[];

                return {
                  total,
                  current_page,
                  data: initialData?.map(item => {
                    if (item?.id === order?.id) {
                      return {
                        ...item,
                        unread_message_count: 0
                      };
                    }
                    return item;
                  })
                };
              }
            );
          }
        }
      })
    );
  };

  const reactToPrintContent = useCallback(() => printComponentRef.current, [printComponentRef]);

  const emptyFunc = () => {};

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `P-${order?.id}`,
    onBeforePrint: emptyFunc,
    onAfterPrint: emptyFunc,
    removeAfterPrint: true
  });

  const onSwitchChange = (value: boolean) => {
    dispatch(setIsPartyProcess({ isPartyProcess: value }));
    setOpenDropdown(true);
    if (value) {
      LocalStorage.set("is_party_process", value);
    } else {
      LocalStorage.remove("is_party_process");
    }
  };

  const isGroupPerson: boolean = useWatch("isGroupPerson", form);

  const onGroupingCompanyPerson = () => {
    form.setFieldValue("isGroupPerson", !isGroupPerson);
  };

  const onOpenAdditionalModal = () => {
    dispatch(
      setVisibleAdditional({
        visible: true,
        order_id: order?.id
      })
    );
    setOpenDropdown(false);
  };

  const onOpenDropdown = (open: boolean) => {
    setOpenDropdown(open);
  };

  const dropdownItems = () => [
    {
      key: "1",
      label: (
        <div className={styles.dropdown_item}>
          <UnreadMessageAction count={order?.unread_message_count} />
        </div>
      ),
      onClick: () => onOpenChat()
    },
    {
      key: "grouping",
      label: (
        <div className={styles.dropdown_item}>
          <GitBranchIcon /> {isGroupPerson ? t("warehouse.Guruhdan chiqarish") : t("warehouse.Guruhlash")}
        </div>
      ),
      onClick: onGroupingCompanyPerson
    },
    {
      key: "2",
      label: (
        <div className={styles.dropdown_item}>
          <PrinterIcon /> {t("warehouse.Chop etish")}
        </div>
      ),
      onClick: handlePrint
    },
    {
      key: "3",
      label: (
        <label
          htmlFor="party_process"
          className={cx(styles.dropdown_item, "cursor-pointer")}
          onClick={e => e.stopPropagation()}
        >
          <OrderedIcon stroke={colors.GRAY_600} size={20} /> {t("warehouse.Partiya jarayoni")}{" "}
          <Switch
            id="party_process"
            value={isPartyProcess}
            defaultValue={Boolean(partyProcess)}
            onChange={onSwitchChange}
          />
        </label>
      )
    },
    {
      ...(partyActions?.acceptanceView && {
        key: "4",
        label: (
          <div className={styles.dropdown_item} onClick={onOpenAdditionalModal}>
            <ClockFastForwardIcon /> {t("warehouse.Qabul tarixini ko'rish")}
          </div>
        )
      })
    }
  ];

  const title = (
    <div className={styles.title}>
      <div className={styles.title__left}>
        <h2>P-{order?.id}</h2>
        {isPartyProcess && (
          <PartyProcess
            monitoring_status={order?.confirmation_payment as unknown as StatusEnums}
            payment_status={order?.payment_percent as number}
            supply_status={order?.status as unknown as StatusEnums}
            warehouse_status={order?.percent as number}
          />
        )}
        {!isPartyProcess && <Statuses statusKey={order?.status as unknown as StatusEnums} pageKey={PageKeys.PARTY} />}
      </div>
      <div className={styles.title__right}>
        <span>
          <Dropdown
            onOpenChange={onOpenDropdown}
            trigger={["click"]}
            open={openDropdown}
            placement="bottom"
            menu={{ items: dropdownItems() as ItemType[] }}
          >
            <Button>
              <UnreadMessageDots count={order?.unread_message_count} />
            </Button>
          </Dropdown>
        </span>
        <span onClick={onCancel}>
          <XCloseIcon />
        </span>
      </div>
    </div>
  );

  const footer = (
    <div className={styles.footer}>
      <Button onClick={onCancel}>{t("orderModal.Yopish")}</Button>
      {!(
        order?.status === OrderEnum.REJECTED ||
        order?.status === OrderEnum.RECIEVED ||
        order?.status === OrderEnum.PENDING ||
        order?.status === OrderEnum.OPEN
      ) &&
        order?.confirmation_payment !== ImpostStatusEnums.REJECTED && (
          <Button
            type="primary"
            onClick={onOk}
            disabled={!isAccept || acceptLength() === 0}
            className={styles.footer__submit}
            loading={receivedOrder.isLoading}
          >
            {isAccept && acceptLength() > 0 && <span className={styles.product_length}>{acceptLength()}</span>}
            {t("orderModal.Qabul qilish")}
          </Button>
        )}
    </div>
  );

  return (
    <Drawer
      title={title}
      open={visible}
      footer={footer}
      closeIcon={null}
      afterOpenChange={onOpenAfter}
      rootClassName={styles.party_modal}
    >
      <Form className="h-full" form={form} onFinish={onFinish} layout="vertical">
        <div className={cx(styles.party_modal__content, "h-full")}>
          <Left form={form} data={data} isLoading={isLoading} />
          <Right form={form} />
        </div>
      </Form>
      <PrintWarehouseParty data={data} partyId={order?.id} ref={printComponentRef} />
    </Drawer>
  );
};

export default Index;
