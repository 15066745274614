import React from "react";
import { useDispatch } from "react-redux";
import { Drawer } from "antd";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/actions";

import { cx } from "modules/common";

import Content from "./content";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const dispatch = useDispatch();
  const { setPartyViewUpdateProduct } = partyReducerActions;
  const {
    visible,
    settings: { visible: visibleSettings }
  } = useAppSelector(state => state.partyReducer.view);

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      dispatch(setPartyViewUpdateProduct(undefined));
    }
  };

  return (
    <Drawer
      width="100vw"
      footer={null}
      open={visible}
      destroyOnClose
      rootClassName={cx(styles.drawer, {
        [styles.drawer_transform]: visibleSettings
      })}
      afterOpenChange={onAfterOpen}
    >
      <Content />
    </Drawer>
  );
};

export default Index;
