import React from "react";
import { useDispatch } from "react-redux";
import { Button, Spin } from "antd";
import PlusIcon from "features/app/assets/icons/PlusIcon";
import SelectNotContentIcon from "features/app/assets/icons/SelectNotContentIcon";

import { partyReducerActions } from "store/reducers/partyReducer";

import { LoadingIndicator } from "components";

type Props = {
  name: string;
  fieldName: string;
  isLoading: boolean;
};

const Empty: React.FC<Props> = ({ name, fieldName, isLoading }) => {
  const dispatch = useDispatch();
  const { setPartyCreateProduct } = partyReducerActions;

  const onAddProduct = () => {
    dispatch(
      setPartyCreateProduct({
        visible: true,
        name,
        fieldName
      })
    );
  };

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className="box-border flex flex-col items-center justify-center gap-3 p-3">
        <SelectNotContentIcon />
        <h4 className="m-0 text-center text-sm font-semibold text-gray-900">Ma'lumot topilmadi</h4>
        <p className="mx-0 mb-3 mt-1 w-[70%] text-center text-xs font-normal text-gray-600">
          <span>
            Kechirasiz, biz siz izlayotgan ma'lumotni topa olmadik.
            <br /> Iltimos, qayta urinib ko'ring.
          </span>
        </p>
        <Button onClick={onAddProduct} type="primary">
          <PlusIcon color="#fff" /> Mahsulot yaratish
        </Button>
      </div>
    </Spin>
  );
};

export default Empty;
