import React from "react";

import { Left } from "./left";

const Top: React.FC = () => (
  <div>
    <Left />
  </div>
);

export default Top;
