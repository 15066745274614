import { z } from "zod";
import {
  companyPersonSchema,
  currencySchema,
  customFieldValueSchema,
  inventoryDetailSchema,
  productSchema,
  unitSchema,
  userSchema
} from "modules/common";

export const processTableSchema = z.object({
  id: z.number().nullable(),
  quantity: z.number().nullable(),
  created_date: z.string().nullable(),
  total_amount: z.number().nullable(),
  currency: currencySchema,
  product: productSchema.nullable().optional(),
  unit: unitSchema.nullable(),
  user: userSchema.nullable().optional(),
  company_person: companyPersonSchema.nullable().optional(),
  inventory: inventoryDetailSchema,
  amount: z.number().nullable(),
  place: z.number().nullable().optional(),
  task: z
    .object({
      id: z.number(),
      name: z.string(),
      place: z.number(),
      unit: unitSchema.nullable().optional(),
      section: z.object({
        id: z.string(),
        name: z.string(),
        place: z.number(),
        project_id: z.number(),
        project: z.object({
          id: z.number(),
          name: z.string()
        }),
        custom_field_values: customFieldValueSchema
      })
    })
    .nullable()
    .optional(),
  task_progress_quantity: z.number().nullable().optional()
});

export type ProcessTableData = z.infer<typeof processTableSchema>;
