import React from "react";
import { useDispatch } from "react-redux";
import { Button, Drawer } from "antd";
import { useTranslation } from "react-i18next";

import { isEmptyObj } from "modules/common";
import { useWarehouseDefectByIdView } from "modules/dashboard/queries/use-warehouse-defect-by-id-view";

import { useAppSelector } from "../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { RootState } from "../../../../../store";
import { defectModalActions } from "../../../../../store/reducers/defectModalReducer";
import { defectViewActions } from "../../../../../store/reducers/defectViewReducer";
import EditIcon from "../../../../app/assets/icons/EditIcon";
import { RU } from "../../../../app/utils/constants/languages";
import { getFirstLetter } from "../../../../app/utils/helpers/getFirstLetter";
import FlipBackwardIcon from "../../../assets/icons/FlipBackwardIcon";
import { useDefectRejected } from "../../../service/mutation";
import { DefectStatus } from "../../../utils/constants/DefectStatus";
import { defectStatusIcons } from "../../../utils/constants/DefectStatusIcons";
import { defectTypes } from "../../../utils/constants/DefectType";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";

import styles from "./defectView.module.scss";

const DefectView: React.FC = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { setDefectView } = defectViewActions;
  const defectRejected = useDefectRejected();
  const { setDefectModal } = defectModalActions;
  const { data, visible } = useAppSelector((state: RootState) => state.defectViewReducer);
  const { companyWarehouseDefectViewById } = useWarehouseDefectByIdView({
    enabled: visible,
    warehouse_defect_id: data ? data?.id : null
  });

  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const defectActions = actions("Yaroqsiz mahsulotlar");

  const onAfterOpen = (visible: boolean) => {
    if (!visible) {
      setDefectView({
        visible: false,
        data: undefined
      });
    }
  };

  const onClose = () => {
    dispatch(
      setDefectView({
        visible: false,
        data: companyWarehouseDefectViewById as never
      })
    );
  };

  const onDefectRejected = () => {
    data && defectRejected.mutateAsync(data.id).then(onClose);
  };

  const onEditDefect = () => {
    dispatch(
      setDefectView({
        visible: false,
        data
      })
    );

    !isEmptyObj(companyWarehouseDefectViewById) &&
      dispatch(
        setDefectModal({
          visible: true,
          data: {
            id: companyWarehouseDefectViewById?.id,
            product: companyWarehouseDefectViewById?.product,
            resource: companyWarehouseDefectViewById?.product?.resource,
            total_quantity: companyWarehouseDefectViewById?.quantity,
            project: companyWarehouseDefectViewById?.project,
            total_amount: companyWarehouseDefectViewById?.amount,
            unit: companyWarehouseDefectViewById?.unit,
            warehouse: companyWarehouseDefectViewById?.warehouse,
            type: companyWarehouseDefectViewById?.type,
            confirmation_users: companyWarehouseDefectViewById?.confirmation_users as any
          }
        })
      );
  };

  const title = (
    <div className="resource">
      <div
        style={{
          borderColor: companyWarehouseDefectViewById?.product?.resource?.color,
          color: companyWarehouseDefectViewById?.product?.resource?.color
        }}
      >
        {companyWarehouseDefectViewById?.product?.resource?.symbol[i18n.language]}
      </div>
      <span>{companyWarehouseDefectViewById?.product?.name[i18n.language]}</span>
    </div>
  );

  const footer = companyWarehouseDefectViewById && companyWarehouseDefectViewById?.status === DefectStatus.OPEN && (
    <div className={styles.footer}>
      {defectActions.edit && (
        <Button onClick={onEditDefect}>
          <EditIcon />
          Tahrirlash
        </Button>
      )}
      {defectActions.rejected && (
        <Button onClick={onDefectRejected} loading={defectRejected.isLoading}>
          <FlipBackwardIcon />
          Orqaga qaytarish
        </Button>
      )}
    </div>
  );

  return (
    <Drawer
      open={visible}
      onClose={onClose}
      title={title}
      afterOpenChange={onAfterOpen}
      footer={footer}
      width="54.4rem"
    >
      <div className={styles.defect_view}>
        <div className={styles.top}>
          {data && (
            <>
              <div className={styles.item}>
                <p>Yaroqsizlik turi</p>
                <div className={styles.status}>
                  {defectTypes[companyWarehouseDefectViewById.type as unknown as keyof typeof defectTypes]}
                </div>
              </div>
              <div className={styles.item}>
                <p>Miqdori</p>
                <h4>{companyWarehouseDefectViewById.quantity?.toLocaleString(RU)}</h4>
              </div>
              <div className={styles.item}>
                <p>Omborxona</p>
                <h4>{companyWarehouseDefectViewById?.warehouse?.name}</h4>
              </div>
              <div className={styles.item}>
                <p>Loyiha</p>
                <h4>{companyWarehouseDefectViewById?.project?.name}</h4>
              </div>
              <div className={styles.item}>
                <p>Yaratilgan sana</p>
                <h4>{companyWarehouseDefectViewById?.created_at}</h4>
              </div>
            </>
          )}
        </div>
        <div className={styles.bottom}>
          {companyWarehouseDefectViewById &&
            companyWarehouseDefectViewById?.confirmation_users?.map(item => (
              <div className={styles.user} key={item.id}>
                <div className={styles.user__left}>
                  {item?.user.image ? (
                    <img alt="" src={item?.user.image} />
                  ) : (
                    <div className={styles.user__left__name}>{getFirstLetter(item?.user.full_name)}</div>
                  )}
                  <span>{item?.user.full_name}</span>
                </div>
                <div className={styles.user__right}>
                  {defectStatusIcons[item.status as unknown as keyof typeof defectStatusIcons]}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Drawer>
  );
};

export default DefectView;
