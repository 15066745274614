import { z } from "zod";

import { productWithUnitSchema } from "modules/party/create";

export const partyResourceFormSchema = z.object({
  search: z.string().optional(),
  catalog: z
    .object({
      id: z.number(),
      name: z.record(z.string())
    })
    .optional(),
  sub_catalog: z
    .object({
      id: z.number(),
      name: z.record(z.string())
    })
    .optional(),
  products: z.array(productWithUnitSchema).optional()
});

export const catalogSchema = z.object({
  id: z.number(),
  name: z.record(z.string()),
  products_count: z.number(),
  status: z.string(),
  color: z.string()
});

export const subCatalogSchema = catalogSchema.merge(
  z.object({
    category_id: z.number()
  })
);

export const createProductFormSchema = z.object({
  name: z.string().min(3),
  code: z.string().min(3),
  unit_id: z.number(),
  resource_id: z.number(),
  category_id: z.number().optional(),
  subcategory_id: z.number().optional()
});

export type PartyResourceForm = z.infer<typeof partyResourceFormSchema>;
export type Catalog = z.infer<typeof catalogSchema>;
export type SubCatalog = z.infer<typeof subCatalogSchema>;
export type CreateProductForm = z.infer<typeof createProductFormSchema>;
