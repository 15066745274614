import React, { SetStateAction } from "react";
import { Dropdown } from "antd";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import ModalConfirm from "features/app/components/modal-confirm/ModalConfirm";
import UnreadMessageAction from "features/app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "features/app/components/unread-message/UnreadMessageDots";
import DotPointsIcon from "features/projects/assets/icons/DotPointsIcon";
import EyeIcon from "features/projects/assets/icons/EyeIcon";
import { CompletedWorkModel } from "features/projects/utils/models/completedWorkModel";
import { useTranslation } from "react-i18next";

type Props = {
  record: CompletedWorkModel;
  setProgressId: React.Dispatch<SetStateAction<number>>;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  setFoundTaskProgress: React.Dispatch<SetStateAction<CompletedWorkModel | null>>;
};

const Actions: React.FC<Props> = ({ record, setOpen, setProgressId, setFoundTaskProgress }) => {
  const { t } = useTranslation();

  const items = [
    {
      key: "1",
      label: (
        <div
          className="flex items-center gap-2"
          onClick={() => {
            setOpen(true);
            setProgressId(record?.id);
            setFoundTaskProgress(record);
          }}
        >
          <EyeIcon /> {t("works.Ko'rish")}
        </div>
      ),
      permissionName: "view",
      isView: true
    },
    {
      key: "2",
      label: <UnreadMessageAction count={record?.unread_message_count} />,
      permissionName: "chatView",
      isView: true
    },
    {
      key: "3",
      label: (
        <div className="flex items-center gap-2">
          <DotPointsIcon /> {t("works.Resurs sarfi")}
        </div>
      ),
      permissionName: "spentOfResources",
      isView: true
    },
    {
      key: "4",
      label: (
        <ModalConfirm>
          <div className="flex items-center gap-2">
            <DeleteIcon /> {t("works.O'chirish")}
          </div>
        </ModalConfirm>
      ),
      permissionName: "delete",
      isView: true
    }
  ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      dropdownRender={(child: React.ReactNode) => <div onClick={e => e.stopPropagation()}>{child}</div>}
    >
      <div className="cursor-pointer" onClick={e => e.stopPropagation()}>
        <UnreadMessageDots count={record?.unread_message_count} />
      </div>
    </Dropdown>
  );
};

export default Actions;
