import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { uysotQueryKeys } from "../query-keys";

type Query = {
  cashes: {
    id: number;
    name: string;
    mine: boolean;
  }[];
};

export function useCashes() {
  const initialData = {
    cashes: []
  };

  const { data = initialData, ...arg } = useQuery<Query>(
    [uysotQueryKeys.CASHES],
    async () => {
      const { data } = await $api.get("cash/select");

      return { cashes: structuredClone(data?.data) };
    },
    {
      staleTime: Infinity
    }
  );

  return { ...data, ...arg };
}
