import { number, z } from "zod";

import { currencySchema, productSchema, statusSchema,unitSchema } from "modules/common";

export const partyEstimateProductSchema = z.object({
  id: z.number(),
  place: z.number(),
  priority: z.string(),
  status: statusSchema,
  amount: z.number(),
  quantity: z.number(),
  code: z.string().nullable(),
  total_amount: z.number(),
  product: productSchema,
  unit: unitSchema,
  total_ordered_quantity: z.number()
});

export const partySectionSchema = z.object({
  id: z.number(),
  name: z.string(),
  place: z.number(),
  code: z.string().nullable(),
  total_amount: z.number(),
  tasks: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      place: z.number(),
      is_title: z.boolean(),
      priority: z.number(),
      status: statusSchema,
      amount: z.number(),
      quantity: z.number(),
      code: z.string().nullable(),
      start_date: z.string().nullable(),
      due_date: z.string().nullable(),
      total_amount: z.number(),
      unit: unitSchema
    })
  )
});

export const projectSchema = z.object({
  id: z.number(),
  name: z.string(),
  task_products_count: z.string(),
  currency: currencySchema
});

export const partyEstimateFormSchema = z.object({
  currency_id: z.number(),
  currency_symbol: z.string(),
  project_id: z.number().or(z.string()),
  products: z.array(
    partyEstimateProductSchema.merge(
      z.object({
        taskId: number().or(z.string()).nullable()
      })
    )
  )
});

export type Project = z.infer<typeof projectSchema>;
export type PartyEstimateForm = z.infer<typeof partyEstimateFormSchema>;
export type PartyEstimateProduct = z.infer<typeof partyEstimateProductSchema>;
export type PartySection = z.infer<typeof partySectionSchema>;
