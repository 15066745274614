import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Switch } from "antd";

import { useAppSelector } from "hooks/redux";

import { setBuildingItem, UysotStatus } from "modules/settings/uysot";

type Props = {
  id: number;
  name?: string;
  status: UysotStatus;
};

const HouseStatus: React.FC<Props> = ({ status, id, name }) => {
  const dispatch = useDispatch();
  const { buildings } = useAppSelector(({ uysot }) => uysot);

  const onChange = (e: boolean) => {
    dispatch(
      setBuildingItem({
        id,
        name,
        key: "status",
        value: e ? "active" : "passive"
      })
    );
  };

  const checked = useMemo(
    () => buildings?.some(({ building_id, status }) => building_id === id && status === "active"),
    [id, buildings]
  );

  return <Switch onChange={onChange} defaultChecked={status === "active"} checked={checked} />;
};

export default HouseStatus;
