import React from "react";
import RollerBrushIcon from "features/app/assets/icons/RollerBrushIcon";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import ProductIcon from "features/supply/assets/icons/ProductIcon";
import { useTranslation } from "react-i18next";

import { isEmptyObj } from "modules/common";
import { ProcessTableData } from "modules/works";

import { TooltipShortName } from "components";

type Props = {
  record: ProcessTableData;
};

export const icons: Record<string, React.ReactNode> = {
  mechanism: <RollerBrushIcon />,
  product: <ProductIcon />
};

const Top: React.FC<Props> = ({ record }) => {
  const { i18n } = useTranslation();

  const getName = (record: ProcessTableData) => {
    if (!isEmptyObj(record.company_person!)) {
      return `${record.company_person?.name![0].toUpperCase()}${record.company_person?.name![1].toUpperCase()}`;
    }
    if (!isEmptyObj(record.user!)) {
      return <CustomAvatar image={record.user?.image as string} name={record?.user?.full_name} />;
    }
    if (!isEmptyObj(record?.inventory)) {
      return `${record.inventory?.name![0].toUpperCase()}${record.inventory?.name![1].toUpperCase()}`;
    }
    return "";
  };

  return (
    <div className="flex items-center justify-between rounded-2xl border border-solid border-gray-200 bg-white px-4 py-3">
      <>
        {record?.product?.resource?.type === "product" ? (
          <div className="flex items-center gap-3">
            <div className="flex h-10 w-10 items-center justify-center rounded-[50%] border-2 border-solid border-white bg-gray-100 text-base font-medium text-gray-600">
              {icons[record?.product?.resource?.type as keyof typeof icons] ||
                `${record?.product?.name[i18n.language][0]?.toUpperCase()}${record?.product?.name[i18n.language][1]?.toUpperCase()}`}
            </div>
            <span className="text-sm font-normal text-gray-900">
              <TooltipShortName
                title={record?.product?.name[i18n.language]}
                length={20}
                className="text-sm font-medium text-gray-900"
              />
            </span>
          </div>
        ) : (
          <div className="flex items-center gap-3">
            <div className="flex h-10 w-10 items-center justify-center rounded-[50%] border-2 border-solid border-white bg-gray-100 text-base font-medium text-gray-600">
              {icons[record?.product?.resource?.type as string] || getName(record)}
            </div>
            <span className="text-sm font-normal text-gray-900">
              <TooltipShortName
                title={
                  (record?.company_person?.name as string) ||
                  (record?.user?.full_name as string) ||
                  (record?.inventory?.name as string)
                }
                length={20}
                className="text-sm font-medium text-gray-900"
              />
            </span>
          </div>
        )}
      </>
      <span className="text-sm font-medium text-gray-400">{record?.unit?.symbol[i18n.language]}</span>
    </div>
  );
};

export default Top;
