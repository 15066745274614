import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";
import React from "react";
import DefectView from "features/inventory/components/defects/bottom/defect-view/DefectView";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const InventoryDefectRejected = ({ record, setOpen, open }: Props) => {
  return (
    <div onClick={() => setOpen(true)} className={styles.description}>
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.user?.full_name} ${record?.inventory_defect?.inventory?.code} raqamli{" "}
        {record?.inventory_defect?.inventory?.name} yaroqsizligini tasdiqlamadi
      </p>
      <DefectView
        open={open}
        setOpen={setOpen}
        record={
          {
            id: record?.inventory_defect?.id
          } as never
        }
        setId={() => {}}
      />
    </div>
  );
};

export default InventoryDefectRejected;
