import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { colors, cx } from "modules/common";
import { setAdditional } from "modules/warehouse/create-transfer";

import { XCloseIcon } from "components";

import Date from "./date";
import Description from "./description";
import Files from "./files";
import Project from "./project";

import styles from "./index.module.scss";

const Right: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { additional, warehouses } = useAppSelector(({ warehouseCreateTransfer }) => warehouseCreateTransfer);
  const { id, visible, name } = additional;

  const productsCount =
    warehouses?.find(({ recieved_warehouse_id }) => recieved_warehouse_id === id)?.products?.length || 0;

  const onClose = () => {
    dispatch(
      setAdditional({
        id,
        visible: false,
        name
      })
    );
  };

  return (
    <div className={cx(styles.right, { [styles.right_active]: visible })}>
      <div className="flex flex-col">
        <div className="flex items-center gap-4 border border-l-0 border-r-0 border-t-0 border-solid border-gray-200 px-6 py-4">
          <div
            onClick={onClose}
            className="box-border flex cursor-pointer items-center justify-center rounded-md p-1 duration-200 ease-in-out hover:bg-gray-100"
          >
            <XCloseIcon width={20} color={colors.GRAY_400} />
          </div>
          <span className="text-lg font-semibold text-gray-900">{name}</span>
        </div>
        <div className="box-border flex flex-col gap-4 p-4">
          <Project />
          <Date />
          <Description />
          <Files />
        </div>
      </div>
      <div className="box-border p-4">
        <div className="flex items-center justify-between gap-4 rounded-xl border border-solid border-gray-300 px-6 py-4">
          <span className="text-base font-medium text-gray-600">{t("warehouseTransfer.Jami resurslar")}:</span>
          <span className="text-base font-medium text-primary-500">
            {productsCount} {t("warehouseTransfer.ta")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Right;
