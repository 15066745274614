import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { partyEstimateQueryKeys, PartySection } from "modules/party/estimate";

type Query = {
  sections: PartySection[];
};

export function usePartySections({ projectId }: { projectId?: number | string }) {
  const initial: Query = {
    sections: []
  };

  const { data = initial, ...arg } = useQuery<Query>(
    [partyEstimateQueryKeys.SECTIONS, projectId],
    async () => {
      const { data } = await $api.get(`supply/project-view?id=${projectId}`);

      return { sections: data?.data };
    },
    {
      enabled: !!projectId
    }
  );

  return { ...data, ...arg };
}
