import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { integrationQueryKeys } from "../query-keys";
import { Integration } from "../schema";

type Query = {
  integrations: Integration[];
};

export function useIntegrationsView() {
  const initial = {
    integrations: []
  };

  const { data = initial, ...arg } = useQuery<Query>([integrationQueryKeys.VIEW], async () => {
    const { data } = await $api.get("integration/view");

    return { integrations: data?.data };
  });

  return { ...data, ...arg };
}
