import React from "react";
import { useDispatch } from "react-redux";
import { Modal, Select } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { filterOption, isEmptyArr } from "modules/common";
import { useWarehouses } from "modules/party/view";
import { ChevronDownIcon } from "modules/settings/uysot";
import { setSenderWarehouse } from "modules/warehouse/create-transfer";

import { EmptyContent, TrashCircleIcon } from "components";

import styles from "./index.module.scss";

const { confirm } = Modal;

const SenderWarehouse: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { warehouses } = useWarehouses(true);
  const { sender_warehouse_id, warehouses: stateWarehouses } = useAppSelector(
    ({ warehouseCreateTransfer }) => warehouseCreateTransfer
  );

  const onChangeSenderWarehouse = (value: number) => {
    const someWarehouseProducts = stateWarehouses?.some(({ products }) => !isEmptyArr(products));

    if (sender_warehouse_id && someWarehouseProducts) {
      confirm({
        centered: true,
        destroyOnClose: true,
        wrapClassName: styles.warehouse_confirm,
        title: (
          <span className="text-lg font-semibold text-gray-900">
            {t("warehouseTransfer.Omborxonani o'zgartirmoqchimisiz")} ?
          </span>
        ),
        icon: <TrashCircleIcon />,
        content: (
          <span className="text-sm font-normal text-gray-600">
            {t(
              "warehouseTranfer.Ushbu omborxonani o'zgartirishingiz bilan kiritilgan ma'lumotlar o'chirilib yuboriladi"
            )}{" "}
            !
          </span>
        ),
        okText: t("warehouseTransfer.O'zgartirish"),
        okType: "danger",
        cancelText: t("warehouseTransfer.Yopish"),
        onOk() {
          dispatch(setSenderWarehouse(value));
        }
      });
    } else {
      dispatch(setSenderWarehouse(value));
    }
  };

  return (
    <div className="flex flex-1 flex-col gap-1.5">
      <label htmlFor="sender_warehouse_id" className="text-sm font-medium text-gray-500">
        {t("warehouseTransfer.Omborxonada")}
      </label>
      <Select
        showSearch
        id="sender_warehouse_id"
        filterOption={filterOption}
        value={sender_warehouse_id}
        popupMatchSelectWidth={false}
        suffixIcon={<ChevronDownIcon />}
        notFoundContent={<EmptyContent />}
        onChange={onChangeSenderWarehouse}
        placeholder={t("warehouseTransfer.Tanlang")}
        options={warehouses?.map(({ id, name }) => ({
          value: id,
          label: name as string,
          props: {
            name
          }
        }))}
      />
    </div>
  );
};

export default SenderWarehouse;
