import React from "react";

const ToggleRightIcon = () => (
  <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 11H6C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1H16M16 11C18.7614 11 21 8.76142 21 6C21 3.23858 18.7614 1 16 1M16 11C13.2386 11 11 8.76142 11 6C11 3.23858 13.2386 1 16 1"
      stroke="#475467"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ToggleRightIcon;
