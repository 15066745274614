import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

import { ActivatingMessagingSystemsForm } from "../schema";

type Props = {
  form: UseFormReturn<ActivatingMessagingSystemsForm>;
};

const Password: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-[6px]">
      <span className="text-sm font-medium text-gray-700">{t("works.Maxfiy kalit")}</span>
      <Controller
        render={({ field }) => <Input placeholder={t("works.Maxsus maxfiy kalitni kiriting")} {...field} />}
        name="password"
        control={form.control}
      />
    </div>
  );
};

export default Password;
