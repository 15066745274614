import React from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "antd";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import EditIcon from "features/app/assets/icons/EditIcon";
import ModalConfirm from "features/app/components/modal-confirm/ModalConfirm";
import { useDeleteTransfer } from "features/warehouse/service/mutation";
import { routeSubmodules } from "features/warehouse/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { transferMakingReducerActions } from "store/reducers/transferMakingReducer";

import { chatActions } from "../../../../../store/reducers/chatReducer";
import { transferActions } from "../../../../../store/reducers/transferReducer";
import UnreadMessageAction from "../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import { ChatEnum } from "../../../../app/utils/enums/chatEnum";
import EyeIcon from "../../../../projects/assets/icons/EyeIcon";
import { warehouseQueryNames } from "../../../utils/constants/warehouseQueryNames";
import { TransferViewModel } from "../../../utils/models/transferViewModel";

import styles from "./actionDots.module.scss";

type Props = {
  record: TransferViewModel;
};

const ActionDots = ({ record }: Props) => {
  const { searchParams } = useQueryParams();
  const dispatch = useDispatch();
  const { setVisibleView } = transferActions;
  const { setVisible: setVisibleChat } = chatActions;
  const { t } = useTranslation();
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const transferPermissionActions = actions("O'tkazmalar");
  const deleteMutation = useDeleteTransfer();
  const { setDrawerVisible } = transferMakingReducerActions;

  const onOpenChat = (_: React.MouseEvent<HTMLElement>, record: TransferViewModel) => {
    dispatch(
      setVisibleChat({
        visible: true,
        objectId: record?.id,
        type: ChatEnum.WAREHOUSE_TRANSFERS,
        dataKeys: [warehouseQueryNames.TRANSFER_VIEW, searchParams],
        record
      })
    );
  };

  const onOpenTransfersView = (_: React.MouseEvent<HTMLElement>, record?: TransferViewModel) => {
    dispatch(
      setVisibleView({
        data: record,
        visible: true
      })
    );
  };

  const onOpenTransferDrawer = () => {
    dispatch(
      setDrawerVisible({
        visible: true,
        id: record?.id,
        data: {
          sender_warehouse_id: record?.sender_warehouse?.id as number,
          recieved_warehouse_id: record?.recieved_warehouse?.id as number,
          recieved_project_id: record?.project?.id as number,
          id: record?.id,
          date: record?.date as string,
          file_ids: [],
          products: []
        }
      })
    );
  };

  const onClickDropdown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const dropdownRender = (child: React.ReactNode) => (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {child}
    </div>
  );

  const onDeleteTransfer = () => deleteMutation.mutateAsync(record?.id);

  const resultItems = () => {
    const items = [
      {
        key: "0",
        label: (
          <div className={styles.dropdown__item} onClick={e => onOpenTransfersView(e, record)}>
            <EyeIcon />
            {t("warehouse.Ko'rish")}
          </div>
        ),
        style: {
          padding: 0
        },
        permissionKey: "view",
        isView: true
      },
      {
        key: "4",
        label: (
          <div className={styles.dropdown__item}>
            <EditIcon /> {t("warehouse.Tahrirlash")}
          </div>
        ),
        onClick: onOpenTransferDrawer,
        isView: record?.status !== "rejected",
        permissionKey: "edit",
        style: {
          padding: 0
        }
      },
      {
        key: "1",
        label: (
          <div className={styles.dropdown__item} onClick={e => onOpenChat(e, record)}>
            <UnreadMessageAction count={record?.unread_message_count} />
          </div>
        ),
        style: {
          padding: 0
        },
        permissionKey: "chatView",
        isView: true
      },
      {
        key: "3",
        label: (
          <ModalConfirm onOk={onDeleteTransfer}>
            <div className={styles.dropdown__item}>
              <DeleteIcon />
              {t("warehouse.O'chirish")}
            </div>
          </ModalConfirm>
        ),
        isView: record?.status === "pending",
        permissionKey: "delete",
        style: {
          padding: 0
        }
      }
    ];

    return {
      items: items?.filter(el => {
        if (el?.permissionKey)
          return el?.isView && transferPermissionActions[el.permissionKey as keyof typeof transferPermissionActions];
        return el?.isView;
      })
    };
  };

  return (
    <Dropdown dropdownRender={dropdownRender} menu={resultItems()} trigger={["click"]}>
      <UnreadMessageDots count={record?.unread_message_count} onClick={onClickDropdown} />
    </Dropdown>
  );
};

export default ActionDots;
