import React from "react";

import { Form } from "modules/party/create";

import Left from "pages/party/create/content/left";
import Right from "pages/party/create/content/right";
import Footer from "pages/party/create/footer";
import Title from "pages/party/create/title";

const Index: React.FC = () => (
  <Form>
    {form => (
      <>
        <div className="flex h-full flex-col">
          <Title form={form} />
          <div className="flex flex-1 overflow-hidden">
            <Left form={form} />
            <Right form={form} />
          </div>
          <Footer form={form} />
        </div>
      </>
    )}
  </Form>
);

export default Index;
