import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { message, Modal, Select } from "antd";
import ProjectSelect from "features/app/components/project-select/project-select";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { cx, isEmptyArr } from "modules/common";
import {
  ChevronDownIcon,
  LinkIcon,
  setChangeBuildings,
  setCheckedBuildingsClear,
  useCashes
} from "modules/settings/uysot";

import RemoveHouses from "./remove";

type IdAndName = {
  id?: number;
  name?: string;
};

const BuildingSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cashes } = useCashes();
  const [open, setOpen] = useState(false);
  const [cash, setCash] = useState<IdAndName>();
  const [project, setProject] = useState<IdAndName>();
  const { checked_buildings, buildings } = useAppSelector(({ uysot }) => uysot);

  const onOpen = () => {
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onAfterOpen = (visible: boolean) => {
    if (!visible) {
      setCash(undefined);
      setProject(undefined);
    }
  };

  const onSave = () => {
    if (!project?.id) {
      message.warning(t("uysot.Loyiha tanlanishi zarur !"));
    } else if (!cash?.id) {
      message.warning(t("uysot.Kassa tanlanishi zarur !"));
    } else {
      dispatch(
        setChangeBuildings(
          checked_buildings.map(({ building_id, status, name }) => {
            const newStatus =
              buildings?.find(({ building_id: buildingId }) => buildingId === building_id)?.status || status;

            return {
              name,
              building_id,
              cash_id: cash?.id,
              status: newStatus,
              cash_name: cash?.name,
              project_id: project?.id,
              project_name: project?.name
            };
          })
        )
      );

      dispatch(setCheckedBuildingsClear());

      onCancel();
    }
  };

  return (
    <>
      <div className="flex items-center gap-2">
        <RemoveHouses />
        <div
          onClick={onOpen}
          className={cx(
            "box-border flex cursor-pointer items-center justify-center gap-2 rounded-xl border border-solid border-transparent bg-primary-100 px-4 py-1.5 text-base font-semibold text-primary-500 duration-200 ease-in-out hover:border-primary-500",
            { "pointer-events-none opacity-0": isEmptyArr(checked_buildings) }
          )}
        >
          <LinkIcon />
          {t("uysot.Biriktirish")}
        </div>
      </div>
      <Modal
        centered
        open={open}
        onOk={onSave}
        destroyOnClose
        okText={t("uysot.Saqlash")}
        title={t("uysot.Biriktirish")}
        cancelText={t("uysot.Yopish")}
        onCancel={onCancel}
        afterOpenChange={onAfterOpen}
      >
        <div className="flex flex-col gap-5 pb-4">
          <div className="flex flex-col gap-1.5">
            <label className="text-sm font-medium text-gray-700">{t("uysot.Loyiha")}</label>
            <ProjectSelect
              mode="state"
              allowClear={false}
              value={project?.id}
              placeholder={t("uysot.Loyiha")}
              isCustomLabel={false}
              suffixIcon={<ChevronDownIcon />}
              rootClassName="w-full"
              onChange={(e: number, option: any) => {
                setProject({
                  id: e,
                  name: option?.[0]
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <label className="text-sm font-medium text-gray-700">{t("uysot.Kassa")}</label>
            <Select
              value={cash?.id}
              placeholder={t("uysot.Kassa")}
              className="w-full"
              suffixIcon={<ChevronDownIcon />}
              onChange={(e, option: any) => {
                setCash({ id: e, name: option?.label });
              }}
              options={cashes?.map(({ id, name }) => ({
                key: id,
                value: id,
                label: name,
                props: {
                  name
                }
              }))}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BuildingSettings;
