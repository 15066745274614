import { Popover } from "antd";
import { RU } from "features/app/utils/constants/languages";
import { cx } from "features/app/utils/helpers/cx";

import { useAppSelector } from "hooks/redux";

import { DynamicEstimateEnum } from "../../../../utils/enums/dynamicEstimateEnum";
import { diffFactAmount, diffFeatureAmount } from "../../../../utils/helpers/diffDynamicEstimate";
import {
  DynamicEstimateProductModel,
  DynamicEstimateSectionModel,
  DynamicEstimateTaskModel
} from "../../../../utils/models/dynamicEstimateModel";

import styles from "./diffPopover.module.scss";

type Props = {
  record: DynamicEstimateProductModel | DynamicEstimateTaskModel | DynamicEstimateSectionModel;
  classNames: string;
  isFeature: boolean;
  taskStatus?: DynamicEstimateEnum;
  isPopover?: boolean;
  isBordered?: boolean;
  getPopoverContainer?: () => HTMLElement;
};

const DiffPopoverTotalAmount = ({
  record,
  classNames,
  isFeature,
  isPopover = false,
  isBordered,
  getPopoverContainer
}: Props) => {
  const currency = useAppSelector(state => state.projectsReducer.projectCurrency);

  const totalClassType = (isPopover: boolean) => {
    if (isFeature) {
      if (record?.total_amount > record?.prediction_total_amount) {
        return isPopover ? "success_amount" : "success";
      }
      if (record?.total_amount < record?.prediction_total_amount) {
        return isPopover ? "error_amount" : "error";
      }
      return isPopover ? "default_amount" : "default";
    }

    if (record?.total_amount > record?.spend_total_amount) {
      return isPopover ? "success_amount" : "success";
    }
    if (record?.total_amount < record?.spend_total_amount) {
      return isPopover ? "error_amount" : "error";
    }
    return isPopover ? "default_amount" : "default";
  };

  const borderTotalClassType = () => {
    if (isFeature) {
      if (record?.total_amount > record?.prediction_total_amount) {
        return "success_border";
      }

      return "error_border";
    }

    return "";
  };

  const title = (record: DynamicEstimateProductModel | DynamicEstimateTaskModel | DynamicEstimateSectionModel) => (
    <div className={styles.popover__inner}>
      <div className={styles.popover__inner__item}>
        <p>Plan</p>
        <p>
          {record?.total_amount?.toLocaleString(RU) ?? 0} {currency?.symbol}
        </p>
      </div>
      <div className={styles.popover__inner__item}>
        <p>Fakt</p>
        <p>
          {record?.spend_total_amount?.toLocaleString(RU)}
          {/* {isFeature */}
          {/*  ? (record?.spend_total_amount + record?.expected_total_amount)?.toLocaleString(RU) */}
          {/*  : record?.spend_total_amount?.toLocaleString(RU)}{" "} */}
          {currency?.symbol}
        </p>
      </div>
      {isFeature && (
        <div className={styles.popover__inner__item}>
          <p>Bashorat</p>
          <p>
            {record?.prediction_total_amount?.toLocaleString(RU)}
            {/* {record?.total_amount < record?.spend_total_amount + record?.expected_total_amount */}
            {/*  ? (record?.spend_total_amount + record?.expected_total_amount)?.toLocaleString(RU) */}
            {/*  : record?.spend_total_amount?.toLocaleString(RU)}{" "} */}
            {currency?.symbol}
          </p>
        </div>
      )}
      <div className={styles.popover__inner__item}>
        <p>Tafovut</p>
        <p className={styles[totalClassType(true)]}>
          {isFeature
            ? Math.abs(
                diffFeatureAmount(record?.total_amount, record?.expected_total_amount, record?.spend_total_amount)
              )?.toLocaleString(RU)
            : Math.abs(diffFactAmount(record?.total_amount, record?.spend_total_amount))?.toLocaleString(RU)}{" "}
          {currency?.symbol}
        </p>
      </div>
    </div>
  );

  return (
    <Popover getPopupContainer={getPopoverContainer} title={title(record)} overlayClassName={styles.diff__popover}>
      <p
        className={cx(classNames, styles[totalClassType(isPopover)], { [styles[borderTotalClassType()]]: isBordered })}
      >
        {isFeature
          ? (record?.spend_total_amount + record?.expected_total_amount).toLocaleString(RU)
          : record?.spend_total_amount?.toLocaleString(RU)}
      </p>
    </Popover>
  );
};

export default DiffPopoverTotalAmount;
