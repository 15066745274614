import { UserTableDataModel } from "../../../app/utils/models/user/userTableConfigModel";

export const inventoryTableDefaultDataFunc = (lang: string) => {
  const inventoryTableDefaultData: UserTableDataModel = {
    column: [
      {
        id: "name",
        name: "name",
        title: "Resurs turi va nomi",
        checked: true
      },
      {
        id: "code",
        name: "code",
        title: "Inventar kod",
        checked: true
      },
      {
        id: "amount",
        name: "amount",
        title: "Tan narxi",
        checked: true
      },
      {
        id: "service_amount",
        name: "service_amount",
        title: "Narxi",
        checked: true
      },
      {
        id: `service_amount_unit.symbol`,
        name: `service_amount_unit.symbol`,
        title: "Birligi",
        checked: true
      }
    ],
    size: 10
  };

  return inventoryTableDefaultData;
};
export const inventoryExportExcelTableDefaultDataFunc = (lang: string) => {
  const inventoryTableDefaultData: UserTableDataModel = {
    column: [
      {
        id: "name",
        name: "name",
        title: "Resurs turi va nomi",
        checked: true
      },
      {
        id: "code",
        name: "code",
        title: "Inventar kod",
        checked: true
      },
      {
        id: "amount",
        name: "amount",
        title: "Tan narxi",
        checked: true
      },
      {
        id: "service_amount",
        name: "service_amount",
        title: "Narxi",
        checked: true
      },
      {
        id: `service_amount_unit.symbol`,
        name: `service_amount_unit.symbol`,
        title: "Birligi",
        checked: true
      }
    ],
    size: 10
  };

  return inventoryTableDefaultData;
};

export const inventoryDefectsTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "inventory.name",
      name: "inventory.name",
      title: "Resurs turi va nomi",
      checked: true
    },
    {
      id: "inventory.code",
      name: "inventory.code",
      title: "Inventar kod",
      checked: true
    },
    {
      id: "company_person.name",
      name: "company_person.name",
      title: "Kontragent",
      checked: true
    },
    {
      id: "created_at_inventory",
      name: "created_at_inventory",
      title: "Kiritilgan sana",
      checked: true
    },
    {
      id: `created_at_defect`,
      name: `created_at_defect`,
      title: "Yaroqsizga chiqarildi",
      checked: true
    },
    {
      id: "type",
      name: "type",
      title: "Yaroqsizlik turi",
      checked: true
    }
  ],
  size: 10
};

export const inventoryRequestsTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "created_at",
      name: "created_at",
      title: "So'rov tushdi",
      checked: true
    },
    {
      id: "inventory.name",
      name: "inventory.name",
      title: "Inventar nomi",
      checked: true
    },
    {
      id: "inventory.code",
      name: "inventory.code",
      title: "Inventar kodi",
      checked: true
    },
    {
      id: "project.name",
      name: "project.name",
      title: "Loyiha",
      checked: true
    },
    {
      id: "creator.full_name",
      name: "creator.full_name",
      title: "Xodim",
      checked: true
    },
    {
      id: "due_date",
      name: "due_date",
      title: "Muddat",
      checked: true
    },
    {
      id: "status",
      name: "status",
      title: "Status",
      checked: true
    }
  ],
  size: 10
};
