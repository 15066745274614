import React from "react";
import { UseFormReturn } from "react-hook-form";
import { CustomFieldSelectModel } from "features/app/utils/models/customFieldSelectModel";
import i18n from "i18next";

import { PartyForm } from "modules/party/create";

import { DatePicker } from "components";

type Props = {
  index: number;
  field: CustomFieldSelectModel;
  form: UseFormReturn<PartyForm>;
};

const Date: React.FC<Props> = ({ form, index, field }) => {
  const { name } = field;

  return (
    <DatePicker
      allowClear
      withoutMessage
      placeholder="Sana"
      control={form.control}
      onChange={(_, date) => {
        form.setValue(`custom_field_values.${index}.value`, date || undefined);
      }}
      label={name?.[i18n.language]}
      name={`custom_field_values.${index}.value`}
    />
  );
};

export default Date;
