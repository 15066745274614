import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { Form, FormInstance, Input, Popover, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import { colors } from "features/app/utils/constants/colors";
import { RU } from "features/app/utils/constants/languages";
import { UnitModel } from "features/app/utils/constants/unitModel";
import { localeFormatter } from "features/app/utils/helpers/localeFormatter";
import { pieceRemoveNaN } from "features/app/utils/helpers/pieceRemoveNaN";
import { GetComponentProps } from "features/app/utils/models/getComponentPropsType";
import { routeSubmodules } from "features/warehouse/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { warehouseOrderActions } from "store/reducers/warehouseOrderReducer";

import PopoverShortName from "../../../../../app/components/popover-short-name/PopoverShortName";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { OrderEnum } from "../../../../utils/constants/OrderEnum";
import { OneOrderModel } from "../../../../utils/models/oneOrderModel";

import styles from "./left.module.scss";

const { Item } = Form;

type Props = {
  data: OneOrderModel[] | undefined;
  isLoading: boolean;
  form: FormInstance;
  popoverContent: (quantity: number, receivedQuantity: number) => ReactNode;
};

const LeftTable: React.FC<Props> = ({ data, isLoading, form, popoverContent }) => {
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const partyActions = actions("Partiyalar");
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { setVisibleAdditional } = warehouseOrderActions;

  const isAccept = useAppSelector(state => state.warehouseOrderReducer.accept.visible);

  const { data: order } = useAppSelector(state => state.warehouseOrderReducer.view);

  const checkStatus =
    order?.status === OrderEnum.REJECTED || order?.status === OrderEnum.RECIEVED || order?.status === OrderEnum.PENDING;

  const onChangeAcceptQuantity = (e: React.ChangeEvent<HTMLInputElement>, index: number, unit?: UnitModel) => {
    const removeNanValue = pieceRemoveNaN(e.currentTarget.value, unit?.is_piece);

    form.setFieldValue(["warehouse_products", index, "quantity"], localeFormatter(removeNanValue));
  };

  const quantityPercentBackground = (quantity: number, receivedQuantity: number) => {
    if (receivedQuantity > quantity) {
      return (quantity / receivedQuantity) * 100;
    }

    return (receivedQuantity / quantity) * 100;
  };

  const quantityPercent = (quantity: number, receivedQuantity: number) => {
    if (receivedQuantity > quantity) {
      return 100;
    }

    return ((receivedQuantity / quantity) * 100)?.toFixed(0) || 0;
  };

  const receivedQuantityPercent = (quantity: number, receivedQuantity: number) =>
    (((receivedQuantity - quantity) / quantity) * 100).toFixed(0);

  const columns: ColumnsType<OneOrderModel> = [
    {
      title: t("orderModal.Resurs turi va nomi"),
      render: (record: OneOrderModel) => (
        <div className="resource">
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product.resource?.symbol[i18n.language]}
          </div>
          <PopoverShortName title={record?.product?.name[i18n.language]} length={35} />
        </div>
      ),
      width: 400,
      fixed: "left"
    },
    {
      title: t("orderModal.Birligi"),
      render: (record: OneOrderModel) => record.unit?.symbol[i18n.language]
      // width: 100,
    },
    {
      title: t("orderModal.Soni"),
      dataIndex: "quantity",
      render: (quantity: number) => quantity?.toLocaleString(RU)
      // width: 150,
    },
    {
      title: t("orderModal.Jarayon"),
      render: (_, record) => (
        <Popover title={popoverContent(record.quantity, record.recieved_quantity)} overlayClassName={styles.popover}>
          <div className={styles.percent}>
            <p>
              {quantityPercent(record?.quantity, record?.recieved_quantity)}%{" "}
              {record?.recieved_quantity > record?.quantity && (
                <span>+{receivedQuantityPercent(record?.quantity, record?.recieved_quantity)}</span>
              )}
            </p>
            <div
              className={styles.percent__progress}
              style={{
                background: record?.recieved_quantity > record?.quantity ? colors.SUCCESS_500 : colors.GRAY_100
              }}
            >
              <div
                style={{
                  width: `${quantityPercentBackground(record?.quantity, record?.recieved_quantity)}%`
                }}
              />
              <div />
            </div>
          </div>
        </Popover>
      ),
      width: 300
    },
    {
      title: t("orderModal.Qabul qilish"),
      render: (_, record: OneOrderModel, index: number) => (
        <>
          <Item name={["warehouse_products", index, "id"]} className="hidden" initialValue={record?.id} />
          <Item
            name={["warehouse_products", index, "quantity"]}
            className={styles.quantity}
            initialValue={
              record?.recieved_quantity > record?.quantity ? 0 : record?.quantity - record?.recieved_quantity
            }
          >
            <Input onChange={e => onChangeAcceptQuantity(e, index, record?.unit)} />
          </Item>
        </>
      ),
      hidden: !isAccept,
      width: 150
    },
    {
      title: t("orderModal.Omborxona"),
      render: (record: OneOrderModel) => record.warehouse?.name
    },
    {
      title: t("orderModal.Loyiha"),
      render: (record: OneOrderModel) => record.project?.name
    }
  ];

  const onRow: GetComponentProps<OneOrderModel> | undefined = record => ({
    onClick: () => {
      if ((!isAccept || checkStatus) && partyActions?.acceptanceView) {
        dispatch(setVisibleAdditional({ visible: true, data: record }));
      }
    }
  });

  return (
    <Table<OneOrderModel>
      onRow={onRow}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={row => row.id}
      className={styles.table}
      rowClassName={() => (!isAccept || checkStatus ? styles.on_row : "")}
      locale={{
        emptyText: <TableEmpty />
      }}
      loading={{
        spinning: isLoading,
        indicator: LoadingIndicator
      }}
    />
  );
};

export default LeftTable;
