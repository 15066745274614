import React, { useContext, useState } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { colors } from "features/app/utils/constants/colors";
import { PaymentSourceElement } from "features/payment/components/bottom/income-expense-view/PaymentSourceElement";

import CustomAvatar from "../../../../../app/components/custom-avatar/CustomAvatar";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { PaymentContext } from "../../../../hooks/PaymentContext";
import { useGetArchivePaymentTemplate } from "../../../../service/queries";
import { ExpectedPaymentTemplateModel } from "../../../../utils/models/expectedPaymentTemplateModel";

import styles from "../../../bottom/tables/exected-payment-template/expectedPaymentTemplateTable.module.scss";

type Props = {
  type: string;
};

const TemplateTable: React.FC<Props> = ({ type }) => {
  const { setContext } = useContext(PaymentContext);
  const [page, setPage] = useState(1);
  const { data, isLoading } = useGetArchivePaymentTemplate({ type, page });

  const paidAmountPercent = (paidAmount: number, amount: number) => {
    const percentAmount = (paidAmount / amount) * 100;
    const percent = percentAmount || 0;
    const percentFixed = percent?.toFixed(2);
    let color = colors.GRAY_700;

    if (percent <= 10) {
      color = colors.ERROR_500;
    }
    if (percent >= 99) {
      color = colors.SUCCESS_500;
    }

    return (
      <span
        style={{
          color
        }}
      >
        {percent ? percentFixed : 0} %
      </span>
    );
  };

  const columns: ColumnsType<ExpectedPaymentTemplateModel> = [
    {
      title: "Umumiy summa",
      render: (record: ExpectedPaymentTemplateModel) => (
        <span>
          {record.amount?.toLocaleString()} {record?.currency?.symbol}
        </span>
      )
    },
    {
      title: "Qoldiq summa",
      render: (record: ExpectedPaymentTemplateModel) => (
        <span>
          {record.debt_amount?.toLocaleString()} {record?.currency?.symbol}
        </span>
      )
    },
    {
      title: "Jarayon (foizda)",
      render: (_, record) => paidAmountPercent(record.paid_amount, record.amount)
    },
    {
      title: "Manba",
      render: (record: ExpectedPaymentTemplateModel) => <PaymentSourceElement record={record as never} />
    },
    {
      title: "Loyiha",
      render: (record: ExpectedPaymentTemplateModel) => record.project?.name
    },
    {
      title: "Xodim",
      align: "right",
      render: (_, record) => <CustomAvatar name={record?.creator?.full_name} image={record?.creator?.image} />
    }
  ];

  return (
    <div className={styles.pending_payments}>
      <Table<ExpectedPaymentTemplateModel>
        columns={columns}
        pagination={false}
        className={styles.table}
        dataSource={data?.data}
        rowKey={row => row.id}
        onRow={record => ({
          onClick: () =>
            setContext({
              infoModalData: {
                visible: true,
                id: record.id,
                creatorId: record?.creator?.id
              }
            })
        })}
        locale={{
          emptyText: <TableEmpty />
        }}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            pageSize: 10,
            total: data?.total,
            current: data?.current_page,
            onChange: page => {
              setPage(page);
            }
          }}
        />
      </div>
    </div>
  );
};

export default TemplateTable;
