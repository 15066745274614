import React from "react";

const BuildingIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.25033 9.16667H3.83366C3.36695 9.16667 3.13359 9.16667 2.95533 9.25749C2.79853 9.33739 2.67105 9.46487 2.59115 9.62167C2.50033 9.79993 2.50033 10.0333 2.50033 10.5V17.5M13.7503 9.16667H16.167C16.6337 9.16667 16.8671 9.16667 17.0453 9.25749C17.2021 9.33739 17.3296 9.46487 17.4095 9.62167C17.5003 9.79993 17.5003 10.0333 17.5003 10.5V17.5M13.7503 17.5V5.16667C13.7503 4.23325 13.7503 3.76654 13.5687 3.41002C13.4089 3.09641 13.1539 2.84144 12.8403 2.68166C12.4838 2.5 12.0171 2.5 11.0837 2.5H8.91699C7.98357 2.5 7.51686 2.5 7.16034 2.68166C6.84674 2.84144 6.59177 3.09641 6.43198 3.41002C6.25033 3.76654 6.25033 4.23325 6.25033 5.16667V17.5M18.3337 17.5H1.66699M9.16699 5.83333H10.8337M9.16699 9.16667H10.8337M9.16699 12.5H10.8337"
      stroke="#475467"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BuildingIcon;
