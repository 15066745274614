import React from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import { supplyOfferActions } from "store/actions";

import { dayjsFormats, parseLocaledString } from "modules/common";
import { WarehouseProduct } from "modules/party/view";

import { CalculatorIcon } from "components";
import {useTranslation} from "react-i18next";

type Props = {
  item: WarehouseProduct;
};

const CreateOffer: React.FC<Props> = ({ item }) => {
  const dispatch = useDispatch();
  const { setOfferModal } = supplyOfferActions;
  const { t } = useTranslation()

  const onCreateOffer = () => {
    dispatch(
      setOfferModal({
        visible: true,
        isEdit: false,
        viewPartySwitch: false,
        data: [
          {
            offers: [],
            amount: parseLocaledString(item?.amount),
            quantity: parseLocaledString(item?.quantity),
            delivery_date: dayjs().format(dayjsFormats.DATE),
            product: item?.product,
            id: item.id,
            unit: item?.unit
          }
        ]
      })
    );
  };

  return (
    <div className="flex items-center gap-2" onClick={onCreateOffer}>
      <CalculatorIcon />
      {t("partyView.Taklif berish")}
    </div>
  );
};

export default CreateOffer;
