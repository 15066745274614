import React from "react";
import { UseFormReturn } from "react-hook-form";

import { useAppSelector } from "hooks/redux";

import { PartyStatus, PartyView, PartyViewForm } from "modules/party/view";

import Group from "../group";

import Table from "./table";

type Props = {
  isLoading: boolean;
  status: PartyStatus;
  form: UseFormReturn<PartyViewForm>;
  products: PartyView["warehouse_products"] | undefined;
};

const Wrapper: React.FC<Props> = ({ isLoading, products, status, form }) => {
  const { isGroup } = useAppSelector(state => state.partyReducer.view);

  if (isGroup) {
    return <Group form={form} status={status} products={products} isLoading={isLoading} />;
  }

  return <Table form={form} status={status} isLoading={isLoading} products={products} />;
};

export default Wrapper;
