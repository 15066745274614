import React from "react";
import { Switch } from "antd";
import SupplyIcon from "features/app/assets/icons/SupplyIcon";
import CoinsStackedIcon from "features/payment/assets/icons/CoinsStackedIcon";
import { useTranslation } from "react-i18next";

import { templatesModalActions, useDispatch } from "store";

import { colors, cx } from "modules/common";

import styles from "./content.module.scss";

const Templates: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setOpenModal } = templatesModalActions;

  const onOpenTemplatesModal = (title: string) => {
    dispatch(
      setOpenModal({
        visible: true,
        title
      })
    );
  };

  return (
    <div className="flex items-center gap-3">
      <div
        className={cx(
          styles.messaging_card,
          "flex h-[76px] w-[373px] cursor-pointer items-center justify-between rounded-2xl border border-solid border-gray-200 p-4"
        )}
        onClick={() => onOpenTemplatesModal(t("settings.Kassa"))}
      >
        <div className={cx("flex items-center justify-center gap-4")}>
          <span
            className={cx(
              "flex items-center justify-center rounded-xl border border-solid p-[10px]",
              styles.messaging_card__icon_container
            )}
          >
            <CoinsStackedIcon stroke="rgba(135, 91, 247, 1)" width={28} height={28} />
          </span>
          <div className="flex flex-col gap-2">
            <span className="text-base font-semibold text-gray-700">Kassa</span>
            <span className="text-sm font-medium text-gray-400">3 shablon</span>
          </div>
        </div>
        <div>
          <Switch />
        </div>
      </div>
      <div
        className={cx(
          styles.messaging_card,
          "flex h-[76px] w-[373px] cursor-pointer items-center justify-between rounded-2xl border border-solid border-gray-200 p-4"
        )}
        onClick={() => onOpenTemplatesModal(t("settings.Ta'minot"))}
      >
        <div className={cx("flex items-center justify-center gap-4")}>
          <span
            className={cx(
              "flex items-center justify-center rounded-xl border border-solid border-success-100 bg-success-100 p-[10px]"
            )}
          >
            <SupplyIcon stroke={colors.SUCCESS_500} />
          </span>
          <div className="flex flex-col gap-2">
            <span className="text-base font-semibold text-gray-700">Ta'minot</span>
            <span className="text-sm font-medium text-gray-400">12 shablon</span>
          </div>
        </div>
        <div>
          <Switch />
        </div>
      </div>
    </div>
  );
};

export default Templates;
