import React from "react";
import { UseFormReturn } from "react-hook-form";

import { useAppSelector } from "hooks/redux";

import { PartyStatus, PartyView, PartyViewForm } from "modules/party/view";

import Group from "../group";

import Table from "./table";

type Props = {
  isLoading: boolean;
  status: PartyStatus;
  form: UseFormReturn<PartyViewForm>;
  products: PartyView["warehouse_products"] | undefined;
  personPaymentTypes: PartyView["company_person_payment_types"];
};

const Wrapper: React.FC<Props> = ({ isLoading, products, status, form, personPaymentTypes }) => {
  const { isGroup } = useAppSelector(state => state.partyReducer.view);

  if (isGroup) {
    return (
      <Group
        form={form}
        status={status}
        products={products}
        isLoading={isLoading}
        personPaymentTypes={personPaymentTypes}
      />
    );
  }

  return <Table form={form} isLoading={isLoading} products={products} status={status} />;
};

export default Wrapper;
