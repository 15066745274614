import React from "react";
import { Spin } from "antd";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { cx, RU } from "modules/common";
import { worksStatsInfoData, WorksTabEnum } from "modules/works";

import { LoadingIndicator } from "components";

import styles from "./info.module.scss";

type Props = {
  statistics: Record<string, number>;
  isLoading: boolean;
};

const Info: React.FC<Props> = ({ statistics, isLoading }) => {
  const { t } = useTranslation();
  const { queries } = useQueryParams();
  const { statistics_type } = queries();
  const data = worksStatsInfoData;
  const currency = getBaseCurrency();

  const amountExtraContent = (amount?: number, extraText?: string) => {
    const extraContent: Record<string, React.ReactNode> = {
      [WorksTabEnum.LIST_OF_WORKS]: (
        <span className="flex items-center justify-center rounded-2xl border border-solid border-primary-50 bg-primary-50 px-2 py-0.5 text-sm font-medium text-primary-500">
          {amount}%
        </span>
      ),
      [WorksTabEnum.PROGRESSES]: <span className="mb-2 text-sm font-semibold text-gray-400">{extraText}</span>,
      [WorksTabEnum.SPENT_OF_RESOURCES]: (
        <span className="mb-2 text-sm font-semibold text-gray-400">{currency?.symbol}</span>
      ),
      [WorksTabEnum.PROCESS]: <span className="mb-2 text-sm font-semibold text-gray-400">{currency?.symbol}</span>
    };

    return extraContent;
  };

  const amountContent = (key: string, extraText?: string, percent?: string) => {
    const percentage = statistics?.[percent!];

    return (
      <div
        className={cx({
          "flex items-center gap-3": statistics_type === WorksTabEnum.LIST_OF_WORKS,
          "flex items-end gap-1":
            statistics_type === WorksTabEnum.PROGRESSES ||
            statistics_type === WorksTabEnum.SPENT_OF_RESOURCES ||
            statistics_type === WorksTabEnum.PROCESS
        })}
      >
        <span className="text-[1.75rem] font-medium text-gray-700">{statistics?.[key]?.toLocaleString(RU)}</span>
        {amountExtraContent(percentage, extraText)[statistics_type]}
      </div>
    );
  };

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className="box-border flex items-center rounded-xl border border-solid border-gray-200 bg-white px-5 py-6 shadow">
        <ConditionalRender
          if={!isLoading}
          else={
            <div className="flex h-full w-full items-center justify-center">
              <TableEmpty />
            </div>
          }
        >
          {data[statistics_type as keyof typeof data]?.labels?.map(el => (
            <div key={el?.key} className={cx("flex w-full flex-1 flex-col gap-3", styles.amount)}>
              <span className="text-base font-normal text-gray-700">{t(`works.${el?.name}`)}</span>
              {amountContent(el?.key, el?.extraText, el?.percent)}
            </div>
          ))}
        </ConditionalRender>
      </div>
    </Spin>
  );
};

export default Info;
