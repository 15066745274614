import i18n from "i18next";

import { WarehouseProduct } from "../schema";

export function totalUnits(products: WarehouseProduct[]) {
  return Object.entries(
    products?.reduce<Record<string, number>>((prev, current) => {
      // const findUnit = unitData?.find(unit => unit?.id === current?.unit_id);
      //
      // if (findUnit?.name[i18n.language]) {
      //   prev[findUnit?.name[i18n.language]] =
      //     (prev[findUnit?.name[i18n.language]] ?? 0) + parseLocaledString(String(current?.quantity ?? 0));
      // }
      // return prev;
      const quantity = current?.quantity;
      const symbol = current?.unit?.symbol[i18n.language];

      return { ...prev, [symbol]: (Number(prev[symbol]) || 0) + quantity };
    }, {}) || {}
  );
}
