import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Popover, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { warehouseProductsAction } from "store/actions";

import { cx } from "modules/common";
import { resourcePercent, WarehouseProduct } from "modules/party/view";

import { PackageSearchIcon } from "components";

import Percent from "./percent";

import styles from "./index.module.scss";

type Props = {
  products: WarehouseProduct[];
};

const Index: React.FC<Props> = ({ products }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { setResource } = warehouseProductsAction;

  const columns: ColumnsType<WarehouseProduct> = [
    {
      title: t("impost.Resurs turi va nomi"),
      render: (_, record) => (
        <div className="flex items-center gap-2">
          <div
            className="flex items-center rounded border border-solid px-2 py-0.5 text-xs font-medium"
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol?.[i18n.language]}
          </div>
          <span>{record?.product?.name?.[i18n.language]}</span>
        </div>
      )
    },
    {
      title: t("impost.Birligi"),
      render: (_, record) => record?.unit?.symbol?.[i18n.language]
    },
    {
      title: t("impost.Mavjud"),
      dataIndex: "warehouse_total_quantity"
    },
    {
      title: t("impost.Buyurtma qilingan"),
      dataIndex: "quantity"
    },
    {
      title: t("impost.Foiz"),
      render: (_, record) => {
        const percent = resourcePercent(record?.quantity, record?.warehouse_total_quantity);

        return (
          <Popover
            arrow={false}
            zIndex={11117}
            placement="top"
            content={
              <Percent
                quantity={record?.quantity}
                symbol={record?.unit?.symbol?.[i18n.language]}
                warehouseTotalQuantity={record?.warehouse_total_quantity}
              />
            }
          >
            <div
              className={cx("flex cursor-pointer items-center justify-center rounded-2xl px-3 py-1", {
                "bg-error-50 text-error-500": percent >= 0 && percent <= 30,
                "bg-warning-50 text-warning-500": percent > 30 && percent <= 70,
                "bg-success-50 text-success-500": percent > 70
              })}
            >
              {percent} %
            </div>
          </Popover>
        );
      }
    }
  ];

  const onOpen = () => setOpen(true);

  const onCancel = () => {
    setOpen(false);
  };

  const onRow = (record: WarehouseProduct) => ({
    onClick: () => {
      dispatch(
        setResource({
          visible: true,
          product: record as never
        })
      );
    }
  });

  return (
    <>
      <div className="flex items-center gap-2" onClick={onOpen}>
        <PackageSearchIcon />
        {t("partyView.Resurs mavjudligi")}
      </div>
      <Modal
        centered
        open={open}
        footer={null}
        zIndex={11115}
        onCancel={onCancel}
        rootClassName={styles.modal}
        title={t("impost.Resurs mavjudligi")}
      >
        <Table
          rowKey="id"
          onRow={onRow}
          className="mt-7"
          columns={columns}
          pagination={false}
          dataSource={products}
          rowClassName="cursor-pointer"
        />
      </Modal>
    </>
  );
};

export default Index;
