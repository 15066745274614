export function clearEmptyValueInObj(obj: object) {
  const result = {};

  for (const key in obj) {
    const type = typeof obj[key as keyof typeof obj];
    
    if (type !== "undefined" || obj[key as never]) {
      result[key as never] = obj[key as never];
    }
  }

  return result;
}
