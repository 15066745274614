import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import i18n from "i18next";

import { Language } from "./schema";

type Query = {
  languages: Language[];
};

export function useLanguages() {
  const initial = {
    languages: []
  };

  const { data = initial, ...arg } = useQuery<Query>(
    ["all-languages"],
    async () => {
      const { data } = await $api.get("language/select");

      return { languages: data?.data };
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      select({ languages }) {
        return {
          languages: languages?.sort(a => {
            if (a.symbol !== i18n.language) return 1;
            return -1;
          })
        };
      }
    }
  );

  return { ...data, ...arg };
}
