import { useState } from "react";
import { FormInstance } from "antd";
import { useTranslation } from "react-i18next";

import ChevroDownIcon from "../../../projects/assets/icons/ChevroDownIcon";
import { CustomFieldSelectModel } from "../../utils/models/customFieldSelectModel";
import CustomFieldContents from "../custom-field-contents/CustomFieldContents";

import styles from "./modalContentCustomFields.module.scss";

type Props = {
  customFields: CustomFieldSelectModel[] | undefined;
  form?: FormInstance;
  visible?: boolean;
  disabled?: boolean;
  isView?: boolean;
};

const ModalContentCustomFields = ({ customFields, form, visible, disabled, isView }: Props) => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation();
  const onToggleActive = () => {
    setActive(prev => !prev);
  };

  return (
    <div className={styles.modal_content_custom_fields}>
      <div className={styles.title} onClick={onToggleActive}>
        <ChevroDownIcon active={active} /> {t("partyModal.O'zgaruvchilarni ko'rsatish")}
      </div>
      {active &&
        customFields?.map((item, index) =>
          CustomFieldContents[item.type]({
            customField: item,
            index,
            form,
            visible,
            disabled,
            isView
          })
        )}
    </div>
  );
};

export default ModalContentCustomFields;
