import React, { useMemo, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { Checkbox, Spin } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import $api from "features/app/utils/helpers/axiosInstance";

import { isEmptyArr } from "modules/common";
import {
  generateCheckProducts,
  PartyEstimateForm,
  PartyEstimateProduct,
  partyEstimateQueryKeys
} from "modules/party/estimate";

import { ConditionalRender, LoadingIndicator } from "components";

type Props = {
  taskId: number | string;
  form: UseFormReturn<PartyEstimateForm>;
};

type Query = {
  products: PartyEstimateProduct[];
};

const Index: React.FC<Props> = ({ form, taskId }) => {
  const qc = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { products } = useWatch({ control: form.control });
  const allProducts = qc.getQueryData<{
    products: PartyEstimateProduct[];
  }>([partyEstimateQueryKeys.PRODUCTS, taskId]);

  const onChange = async (e: CheckboxChangeEvent) => {
    const { products } = form.getValues();
    const qcData = qc.getQueryData<Query>([partyEstimateQueryKeys.PRODUCTS, taskId]);

    if (e.target.checked) {
      if (!isEmptyArr(qcData?.products)) {
        form.setValue("products", generateCheckProducts(products, taskId, qcData?.products || []));
      } else {
        setLoading(true);

        const { products: newProducts } = await qc.fetchQuery<Query>({
          queryKey: [partyEstimateQueryKeys.PRODUCTS, taskId],
          queryFn: async () => {
            const { data } = await $api.get(`supply/task-product-view?task_id=${taskId}`);

            setLoading(false);
            return { products: data?.data };
          }
        });

        form.setValue("products", generateCheckProducts(products, taskId, newProducts || []));
      }
    } else {
      form.setValue("products", [...(products?.filter(p => p?.taskId !== taskId) || [])]);
    }
  };

  const indeterminate = useMemo(() => products?.some(c => c?.taskId === taskId), [products, taskId]);

  const checked = useMemo(
    () => allProducts?.products?.every(c => products?.some(p => p?.id === c?.id)),
    [products, allProducts]
  );

  const thisTaskProducts = products?.filter(({ taskId: task_id }) => task_id === taskId);

  return (
    <Spin spinning={loading} indicator={LoadingIndicator}>
      <div className="flex items-center gap-2">
        <Checkbox
          checked={checked}
          onChange={onChange}
          onClick={e => e.stopPropagation()}
          indeterminate={checked ? false : indeterminate}
        />
        <ConditionalRender if={thisTaskProducts && thisTaskProducts?.length > 0}>
          <span>({thisTaskProducts?.length} ta)</span>
        </ConditionalRender>
      </div>
    </Spin>
  );
};

export default Index;
