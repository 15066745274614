import React from "react";
import { Tooltip } from "antd";
import { TooltipProps } from "antd/lib/tooltip";

import { sliceText } from "modules/common";

type Props = TooltipProps & {
  title?: string;
  length?: number;
  className?: string;
};

export const TooltipShortName: React.FC<Props> = ({ title = "", length = 10, className }) =>
  title?.length > length ? (
    <Tooltip title={title}>
      <div className={className}>{sliceText(title, length)}</div>
    </Tooltip>
  ) : (
    <div className={className}>{title}</div>
  );
