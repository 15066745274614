import React, { CSSProperties, ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Form, Input, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { MenuProps } from "antd/lib";
import dayjs from "dayjs";
import ProjectReportIcon from "features/app/assets/icons/ProjectReportIcon";
import { ProjectModel } from "features/projects/utils/models/projectModel";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { projectReportModalActions } from "store/reducers/projectReportModalReducer";

import { useAuth } from "modules/auth/hooks";

import DeleteIcon from "../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../app/assets/icons/DotsVerticalIcon";
import FolderIcon from "../../../app/assets/icons/FolderIcon";
import PlusCircleIcon from "../../../app/assets/icons/PlusCircleIcon";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../app/components/modal-confirm/ModalConfirm";
import TableEmpty from "../../../app/components/table-empty/TableEmpty";
import { dayjsFormats } from "../../../app/utils/constants/dayjsFormats";
import { formRules } from "../../../app/utils/constants/formRules";
import { RU } from "../../../app/utils/constants/languages";
import { menuColors } from "../../../app/utils/constants/menuColors";
import EyeIcon from "../../assets/icons/EyeIcon";
import { useCreateProjectFolder, useDeleteProjectFolder, useUpdateProjectFolder } from "../../service/mutations";
import { ProjectCardFormModel } from "../../utils/models/ProjectCardFormModel";
import { ProjectFolderModel } from "../../utils/models/projectFolderModel";
import { ProjectsViewModel } from "../../utils/models/projectsViewModel";
import { ProjectProgress } from "../project-progress/ProjectProgress";

import styles from "./projectCardsTable.module.scss";

const ProjectCardsTable: React.FC<{
  data: ProjectsViewModel | undefined;
  isLoading: boolean;
  isCreating: boolean;
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
  onCreateFolder: () => void;
  folderActions: {
    [key: string]: boolean;
  };
  isProjectsPage: boolean;
}> = ({ data, isLoading, isCreating, setIsCreating, onCreateFolder, folderActions, isProjectsPage }) => {
  const [form] = Form.useForm<{ projects: ProjectCardFormModel[] }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { currencies } = useAuth();
  const createProjectFolder = useCreateProjectFolder();
  const updateProjectFolder = useUpdateProjectFolder();
  const deleteProjectFolder = useDeleteProjectFolder();
  const dispatch = useDispatch();
  const { setVisible } = projectReportModalActions;
  const basicCurrency = currencies?.find(item => item.type === "base")?.symbol;

  const [projectCards, setProjectCards] = useState(data?.project_folders);

  useLayoutEffect(() => {
    setProjectCards(data?.project_folders);
  }, [data]);

  useEffect(() => {
    if (isCreating) {
      const newCardValue = {
        id: uid(10),
        name: "",
        isCreating: true,
        projects_count: 0,
        color: "#53B1FD",
        percent: 0,
        prediction_date: "",
        due_date: "",
        spend_amount: 0,
        prediction_amount: 0,
        total_amount: 0,
        start_date: ""
      };

      setProjectCards(oldValues => {
        const newAllCardValues = [...(oldValues ?? []), newCardValue];

        form.setFieldValue(
          "projects",
          newAllCardValues?.map(item => ({
            id: item.id,
            name: item.name,
            changed: false,
            creating: Boolean(item.isCreating)
          }))
        );
        return newAllCardValues;
      });
    }
  }, [isCreating]);

  const stopPropagation = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const dropdownRender = (originNode: ReactNode) => <div onClick={stopPropagation}>{originNode}</div>;

  const onChangeColor = (color: string, id: number | string) => {
    updateProjectFolder.mutateAsync({ color, id }).then(() => {
      setProjectCards(old =>
        old?.map(item => ({
          ...item,
          color: item.id === id ? color : item.color
        }))
      );
    });
  };

  const onDelete = (id: number | string) => {
    const oldProjects: ProjectCardFormModel[] = form.getFieldValue("projects");

    setProjectCards(old => old?.filter(item => item?.id !== id));
    form.setFieldValue(
      "projects",
      oldProjects?.filter(item => item.id !== id)
    );
    return deleteProjectFolder.mutateAsync(id!);
  };

  const onClickColumn = (id: number | string, name: string) => {
    !isCreating &&
      navigate(`${name}-${id!.toString()}`, {
        state: { path: location.pathname }
      });
  };

  const dropdownItems = (record: Partial<ProjectFolderModel>): MenuProps["items"] => [
    {
      key: "0",
      label: (
        <div className={styles.colors}>
          {menuColors.map((color, index) => (
            <span
              key={index}
              className={styles.item}
              onClick={() => onChangeColor(color.background, record.id!)}
              style={{
                background: color.border
              }}
            >
              <span
                style={{
                  background: color.background
                }}
              />
            </span>
          ))}
        </div>
      )
    },
    {
      key: "1",
      label: (
        <div className={styles.update}>
          <EyeIcon /> <span>{t("project.Ko'rish")}</span>
        </div>
      ),
      onClick: () => onClickColumn(record.id!, record.name!)
    },
    {
      key: "3",
      label: (
        <ModalConfirm onOk={() => onDelete(record.id!)}>
          <div className={styles.delete}>
            <DeleteIcon />
            <span>{t("project.O'chirish")}</span>
          </div>
        </ModalConfirm>
      )
    },
    {
      key: "5",
      label: (
        <div
          className={styles.delete}
          onClick={() =>
            dispatch(
              setVisible({
                data: record as unknown as ProjectModel,
                visible: true
              })
            )
          }
        >
          <ProjectReportIcon />
          <span>{t("project.Loyiha hisoboti")}</span>
        </div>
      )
    }
  ];

  const onCreateOrUpdateCard = () => {
    const oldProjects: ProjectCardFormModel[] = form.getFieldValue("projects");
    const changedValue = oldProjects?.find((item: ProjectCardFormModel) => item?.changed);

    setIsCreating(false);

    if (changedValue?.name) {
      typeof changedValue?.id === "number"
        ? updateProjectFolder
            .mutateAsync({
              id: changedValue.id,
              name: changedValue.name
            })
            .then(({ data }) => {
              form.setFieldValue(
                "projects",
                oldProjects?.map(item => ({
                  id: changedValue.id === item.id ? data.id : item.id,
                  name: item.name,
                  changed: false,
                  creating: false
                }))
              );
              setProjectCards(old =>
                old?.map(item => {
                  if (item.id === changedValue.id) {
                    return {
                      ...item,
                      id: data.id!,
                      name: changedValue.name,
                      isCreating: false,
                      isEditing: false
                    };
                  }
                  return { ...item, isCreating: false };
                })
              );
            })
        : createProjectFolder.mutateAsync({ name: changedValue.name }).then(({ data }) => {
            form.setFieldValue(
              "projects",
              oldProjects?.map(item => ({
                id: changedValue.id === item.id ? data.id : item.id,
                name: item.name,
                changed: false,
                creating: false
              }))
            );
            setProjectCards(old =>
              old?.map(item => {
                if (item.id === changedValue.id) {
                  return {
                    ...item,
                    id: data.id!,
                    name: changedValue.name,
                    isCreating: false,
                    isEditing: false
                  };
                }
                return { ...item, isCreating: false };
              })
            );
          });
    } else {
      form.setFieldValue(
        "projects",
        oldProjects?.filter(item => !item.creating)
      );
      setProjectCards(oldValue => oldValue?.filter(item => !item.isCreating));
    }
  };

  const onBlur = () => {
    onCreateOrUpdateCard();
    setIsCreating(false);
  };

  const onChangeProjectName = (e: React.ChangeEvent<HTMLInputElement>, index: number, id: string | number) => {
    const newProjects = form.getFieldValue("projects");

    newProjects[index] = {
      changed: true,
      name: e.currentTarget.value,
      id
    };

    form.setFieldValue("projects", newProjects);
  };

  const dateColor = (due_date?: string, prediction_date?: string): CSSProperties => {
    if (due_date && prediction_date) {
      const diff = dayjs(prediction_date, dayjsFormats.DATE).diff(dayjs(due_date, dayjsFormats.DATE), "day");

      if (diff > 0)
        return {
          color: "#F04438"
        };
      if (diff < 0)
        return {
          color: "#12B76A"
        };
      return {
        color: "#344054"
      };
    }
    return {
      color: "#344054"
    };
  };

  const columns: ColumnsType<Partial<ProjectFolderModel>> = [
    {
      title: `${t("project.Loyiha nomi")}`,
      render: (_, record, index) => (
        <Form
          form={form}
          onFinish={onCreateOrUpdateCard}
          onBlur={onBlur}
          className={styles.form}
          onClick={stopPropagation}
        >
          <div className={styles.name}>
            <FolderIcon color={record?.color} />
            <Form.Item
              name={["projects", index, "name"]}
              initialValue={record?.name}
              className={styles.name__item}
              rules={formRules()!}
            >
              <Input
                placeholder="Papka nomi"
                autoFocus={record?.isCreating || record?.isEditing}
                onChange={e => onChangeProjectName(e, index, record.id!)}
              />
            </Form.Item>
            <Form.Item name={["projects", index, "id"]} initialValue={record?.id} className="d_n" />
            <Form.Item name={["projects", index, "changed"]} initialValue={false} className="d_n" />
            <Form.Item name={["projects", index, "creating"]} initialValue={record?.isCreating} className="d_n" />
            <Form.Item name={["projects", index, "editing"]} initialValue={record?.isCreating} className="d_n" />
          </div>
        </Form>
      ),
      width: "18.6rem"
    },
    {
      title: (
        <div className={styles.table__header}>
          <h4>{t("project.Muddat")}</h4>
          <span>
            ( {t("project.Reja")} | {t("project.Fakt")} )
          </span>
        </div>
      ),
      render: (_, record) => (
        <div className={styles.dates}>
          <div className={styles.dates__item}>{record?.due_date ?? "-"}</div>
          <span className={styles.amounts__line} />
          <div className={styles.dates__item} style={dateColor(record.due_date, record.prediction_date)}>
            {record.prediction_date ?? "-"}
          </div>
        </div>
      )
    },
    {
      title: (
        <div className={styles.table__header}>
          <h4>{t("project.Summa")}</h4>
          <span>
            ( {t("project.Reja")} | {t("project.Fakt")} | {t("project.Bashorat")} )
          </span>
        </div>
      ),
      render: (_, record) => (
        <div className={styles.amounts}>
          <div className={styles.amounts__item}>
            <span>{record.total_amount?.toLocaleString(RU)}</span>
            <span>{basicCurrency}</span>
          </div>
          <span className={styles.amounts__line} />
          <div className={styles.amounts__item}>
            <span>{record.spend_amount?.toLocaleString(RU)}</span>
            <span>{basicCurrency}</span>
          </div>
          <span className={styles.amounts__line} />
          <div className={styles.amounts__item}>
            <span>{Number(record?.prediction_amount).toLocaleString(RU)}</span>
            <span>{basicCurrency}</span>
          </div>
        </div>
      )
    },
    {
      title: `${t("project.Jarayon")}`,
      render: (_, record) => (
        <div className={styles.progress}>
          <ProjectProgress percent={record?.percent} />
        </div>
      )
    },
    {
      title: `${t("project.Loyihalar soni")}`,
      dataIndex: "projects_count",
      render: (_, record) => (
        <div className={styles.project_count}>
          {!record?.isCreating && Number(record?.projects_count) > 0 ? `${record?.projects_count}  ta` : "-"}
        </div>
      ),
      align: "center"
    },
    {
      title: "",
      render: (_, record) =>
        !record?.isCreating && (
          <Dropdown
            menu={{
              items: dropdownItems(record!)
            }}
            dropdownRender={dropdownRender}
            trigger={["click"]}
          >
            <div className="c_p three_point__content" onClick={stopPropagation}>
              <DotsVerticalIcon />
            </div>
          </Dropdown>
        ),
      className: "three_point",
      align: "center"
    }
  ];

  return (
    <Table
      bordered
      dataSource={projectCards}
      columns={columns}
      pagination={false}
      className={`${styles.table} footer_table`}
      rowKey={row => row.id!}
      onRow={record => ({
        onClick: () => onClickColumn(record.id!, record.name!)
      })}
      loading={{
        spinning:
          isLoading || updateProjectFolder.isLoading || deleteProjectFolder.isLoading || createProjectFolder.isLoading,
        indicator: LoadingIndicator
      }}
      locale={{
        emptyText: <TableEmpty />
      }}
      rowClassName="c_p"
      footer={
        isProjectsPage
          ? () => (
              <>
                {folderActions.create && (
                  <div className={styles.add_project} onClick={onCreateFolder}>
                    <PlusCircleIcon fill /> {t("project.Papka qo'shish")}
                  </div>
                )}
              </>
            )
          : undefined
      }
    />
  );
};

export default ProjectCardsTable;
