import React, { useMemo } from "react";
import { Popover } from "antd";

import { cx, getFirstName, isEmptyArr } from "modules/common";
import { offerStatusClass, PartyView } from "modules/party/view";

import { ConditionalRender } from "components";

import Content from "./content";
import CounterpartsModal from "features/counterparts/components/counterparts/modal/CounterpartsModal";

type Props = {
  actions: { [key: string]: boolean };
  offers: PartyView["warehouse_products"][number]["warehouse_product_offers"] | undefined;
};

const Index: React.FC<Props> = ({ offers, actions }) => {
  const { data, count } = useMemo(() => {
    const viewOffers = offers?.slice(0, 3);

    return {
      data: viewOffers,
      count: (offers?.length || 0) - (viewOffers?.length || 0)
    };
  }, [offers]);

  return (
    <div className="flex items-center">
      <ConditionalRender if={!isEmptyArr(data)} else="--">
        <Popover
          arrow={false}
          placement="top"
          trigger={["click"]}
          content={<Content offers={offers} actions={actions} />}
        >
          <div className="flex cursor-pointer items-center">
            {data?.map(offer => (
              <div
                key={offer?.id}
                className={cx(
                  offerStatusClass(offer?.status),
                  "flex h-9 w-9 items-center justify-center rounded-full text-sm font-medium"
                )}
              >
                {getFirstName(offer?.name || offer?.company_person?.name)}
              </div>
            ))}
            <ConditionalRender if={count > 0}>
              <div className="flex h-9 w-9 items-center justify-center rounded-full bg-gray-200 text-sm font-medium text-gray-600">
                +{count}
              </div>
            </ConditionalRender>
          </div>
        </Popover>
      </ConditionalRender>
      <CounterpartsModal/>
    </div>
  );
};

export default Index;
