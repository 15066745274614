import React from "react";

const PrinterIcon: React.FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_23001_70086)">
      <path
        d="M13.5 5.25V3.9C13.5 3.05992 13.5 2.63988 13.3365 2.31901C13.1927 2.03677 12.9632 1.8073 12.681 1.66349C12.3601 1.5 11.9401 1.5 11.1 1.5H6.9C6.05992 1.5 5.63988 1.5 5.31901 1.66349C5.03677 1.8073 4.8073 2.03677 4.66349 2.31901C4.5 2.63988 4.5 3.05992 4.5 3.9V5.25M4.5 13.5C3.80252 13.5 3.45378 13.5 3.16766 13.4233C2.3912 13.2153 1.78472 12.6088 1.57667 11.8323C1.5 11.5462 1.5 11.1975 1.5 10.5V8.85C1.5 7.58988 1.5 6.95982 1.74524 6.47852C1.96095 6.05516 2.30516 5.71095 2.72852 5.49524C3.20982 5.25 3.83988 5.25 5.1 5.25H12.9C14.1601 5.25 14.7902 5.25 15.2715 5.49524C15.6948 5.71095 16.039 6.05516 16.2548 6.47852C16.5 6.95982 16.5 7.58988 16.5 8.85V10.5C16.5 11.1975 16.5 11.5462 16.4233 11.8323C16.2153 12.6088 15.6088 13.2153 14.8323 13.4233C14.5462 13.5 14.1975 13.5 13.5 13.5M11.25 7.875H13.5M6.9 16.5H11.1C11.9401 16.5 12.3601 16.5 12.681 16.3365C12.9632 16.1927 13.1927 15.9632 13.3365 15.681C13.5 15.3601 13.5 14.9401 13.5 14.1V12.9C13.5 12.0599 13.5 11.6399 13.3365 11.319C13.1927 11.0368 12.9632 10.8073 12.681 10.6635C12.3601 10.5 11.9401 10.5 11.1 10.5H6.9C6.05992 10.5 5.63988 10.5 5.31901 10.6635C5.03677 10.8073 4.8073 11.0368 4.66349 11.319C4.5 11.6399 4.5 12.0599 4.5 12.9V14.1C4.5 14.9401 4.5 15.3601 4.66349 15.681C4.8073 15.9632 5.03677 16.1927 5.31901 16.3365C5.63988 16.5 6.05992 16.5 6.9 16.5Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_23001_70086">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PrinterIcon;
