import React from "react";
import { useTranslation } from "react-i18next";
import { GroupingIcon } from "modules/works";
import { colors } from "modules/common";
import { Dropdown, Switch } from "antd";
import { useQueryParams } from "hooks/useQueryParams";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import DotsVerticalIcon from "features/app/assets/icons/DotsVerticalIcon";

import styles from "./right.module.scss";

const Actions: React.FC = () => {
  const { t } = useTranslation();
  const { queries, append, remove } = useQueryParams();
  const { group_by } = queries();

  const onSwitchChange = (value: boolean) => {
    if (value) {
      append(queryParamsKeys.GROUP_BY, String(value));
    } else {
      remove(queryParamsKeys.GROUP_BY);
    }
  };

  const dropdownRender = (child: React.ReactNode) => (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {child}
    </div>
  );

  const items = [
    {
      key: "1",
      label: (
        <label
          htmlFor="grouping"
          className="flex cursor-pointer items-center justify-between"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
            <GroupingIcon stroke={colors.GRAY_500} width={15} height={15} /> {t("works.Guruhlash")}
          </div>
          <Switch id="grouping" onChange={onSwitchChange} checked={!!(group_by && group_by === "true")} />
        </label>
      )
    },
    {
      key: "2",
      label: (
        <div className="flex items-center gap-2">
          <DownloadIcon stroke={colors.GRAY_500} size={15} /> {t("works.Hisobotni yuklab olish")}
        </div>
      )
    }
  ];

  return (
    <Dropdown
      menu={{ items }}
      overlayClassName={styles.right_actions_dropdown}
      trigger={["click"]}
      dropdownRender={dropdownRender}
    >
      <div className="flex cursor-pointer items-center justify-center rounded-lg border border-solid border-gray-300 px-4 py-[10px]">
        <DotsVerticalIcon />
      </div>
    </Dropdown>
  );
};

export default Actions;
