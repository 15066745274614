import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { cx, isEmptyArr } from "modules/common";
import { setClearBuildingsCashAndProject } from "modules/settings/uysot";

import { LinkBrokenIcon } from "components";

const RemoveHouses: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { checked_buildings, buildings } = useAppSelector(({ uysot }) => uysot);

  const onRemove = () => {
    dispatch(
      setClearBuildingsCashAndProject(
        checked_buildings.map(({ building_id, status, name }) => {
          const newStatus =
            buildings?.find(({ building_id: buildingId }) => buildingId === building_id)?.status || status;

          return {
            name,
            building_id,
            status: newStatus
          };
        })
      )
    );
  };

  return (
    <div
      onClick={onRemove}
      className={cx(
        "box-border flex cursor-pointer items-center justify-center gap-2 rounded-xl border border-solid border-transparent bg-error-100 px-4 py-1.5 text-base font-semibold text-error-500 duration-200 ease-in-out hover:border-error-500",
        { "pointer-events-none opacity-0": isEmptyArr(checked_buildings) }
      )}
    >
      <LinkBrokenIcon />
      {t("uysot.Uzish")}
    </div>
  );
};

export default RemoveHouses;
