import React from "react";

import { useQueryParams } from "hooks/useQueryParams";

import { WorksTabEnum } from "modules/works/constants";

import ListOfWorksTable from "./list-of-works/list-of-works-table";
import ProgressesTable from "./progresses/progresses-table";
import SpentOfResourcesTable from "./spent-of-resources/spent-of-resources-table";
import { ProcessTable } from "./process";

const Tables: React.FC = () => {
  const { queries } = useQueryParams();
  const { statistics_type } = queries();
  const content: Record<WorksTabEnum, React.ReactNode> = {
    [WorksTabEnum.LIST_OF_WORKS]: <ListOfWorksTable />,
    [WorksTabEnum.PROGRESSES]: <ProgressesTable />,
    [WorksTabEnum.SPENT_OF_RESOURCES]: <SpentOfResourcesTable />,
    [WorksTabEnum.PROCESS]:<ProcessTable/>
  };

  return <div>{content[statistics_type as keyof typeof content]}</div>;
};

export default Tables;
