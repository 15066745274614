import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { partyCreateQueryKeys } from "modules/party/create";
import { Vat } from "modules/party/view";

interface Query {
  vats: Vat[];
}

export function useVats() {
  const initial: Query = {
    vats: []
  };

  const { data = initial, ...arg } = useQuery<Query>([partyCreateQueryKeys.VATS], async () => {
    const { data } = await $api.get("vat/select");

    return { vats: data?.data };
  });

  return { ...data, ...arg };
}
