import { z } from "zod";

export const companyPersonFormSchema = z.object({
  name: z.string().min(1),
  tin: z.string(),
  longitude: z.number().optional(),
  latitude: z.number().optional(),
  phone: z.string().min(19).max(19),
  type: z.string().optional(),
  description: z.string().optional(),
  company_person_folder_id: z.number().optional(),
  start_date: z.string().optional(),
  balances: z
    .array(
      z.object({
        currency_id: z.number().optional(),
        start_amount: z.string().optional()
      })
    )
    .optional()
});

export const companyPersonSelectSchema = z.object({
  id: z.number(),
  name: z.string(),
  tin: z.number(),
  phone: z.string(),
  address: z.string().nullable(),
  longitude: z.number().nullable(),
  latitude: z.number().nullable(),
  district: z.number().nullable(),
  region: z.number().nullable()
});

export const companyPersonFolderSchema = z.object({
  id: z.number(),
  name: z.string(),
  color: z.string(),
  company_persons_count: z.number()
});

export const companyPersonReqPayloadSchema = companyPersonFormSchema.omit({ balances: true }).merge(
  z.object({
    balances: z
      .array(
        z.object({
          currency_id: z.number().optional(),
          start_amount: z.number().optional()
        })
      )
      .optional()
  })
);

export type CompanyPersonForm = z.infer<typeof companyPersonFormSchema>;
export type CompanyPersonSelect = z.infer<typeof companyPersonSelectSchema>;
export type CompanyPersonFolder = z.infer<typeof companyPersonFolderSchema>;
export type CompanyPersonReqPayload = z.infer<typeof companyPersonReqPayloadSchema>;
