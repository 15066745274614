import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Input } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { cx, localeFormatter } from "modules/common";
import {
  CreateTransferState,
  setDeleteWarehouseProduct,
  setQuantityWarehouseProduct
} from "modules/warehouse/create-transfer";

import { DotsVerticalIcon, TooltipShortName, TrashIcon } from "components";

import styles from "./index.module.scss";

const Item: React.FC<
  CreateTransferState["warehouses"][number]["products"][number] & { parentIndex: number; index: number }
> = ({ color, name, resource, unit, parentIndex, index, product_id, unit_id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { errors, warehouses } = useAppSelector(({ warehouseCreateTransfer }) => warehouseCreateTransfer);
  const { quantity } = warehouses[parentIndex].products[index];

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    dispatch(
      setQuantityWarehouseProduct({
        index,
        parentIndex,
        symbol: unit,
        value: localeFormatter(value || "0")
      })
    );
  };

  const isError = useMemo(
    () =>
      errors?.some(err => err?.parentIndex === parentIndex && err?.productId === product_id && err?.unitId === unit_id),
    [errors, parentIndex, product_id, unit_id]
  );

  return (
    <div
      className={cx(
        "box-border flex flex-col gap-2 rounded-lg border border-solid border-gray-200 bg-gray-50 p-3",
        styles.item
      )}
    >
      <div className="flex w-full justify-between overflow-hidden">
        <div className="flex gap-2">
          <div
            className="flex h-max items-center justify-center rounded border border-solid px-2 py-0.5 text-xs font-medium"
            style={{
              color,
              borderColor: color
            }}
          >
            {resource}
          </div>
          <div className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray-700">
            <TooltipShortName title={name} arrow={false} length={26} className="whitespace-normal" />
          </div>
        </div>
        <Dropdown
          trigger={["click"]}
          menu={{
            items: [
              {
                key: 1,
                label: (
                  <div className="flex items-center gap-2">
                    <TrashIcon />
                    <span className="text-sm font-medium text-gray-700">{t("warehouseTransfer.O'chirish")}</span>
                  </div>
                ),
                onClick: () => {
                  dispatch(setDeleteWarehouseProduct({ parentIndex, index }));
                }
              }
            ]
          }}
        >
          <div className={cx("flex cursor-pointer justify-end opacity-0 duration-300 ease-in-out", styles.action)}>
            <DotsVerticalIcon />
          </div>
        </Dropdown>
      </div>
      <Input
        suffix={unit}
        value={quantity}
        onChange={onChange}
        status={isError ? "error" : undefined}
        placeholder={t("warehouseTransfer.Kiriting")}
      />
    </div>
  );
};

export default Item;
