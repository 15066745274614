import React from "react";

import { PartyView } from "modules/party/view";

type Props = {
  id?: number;
  agent?: PartyView["agent"];
  deliveryDate?: string;
  paymentDate?: string;
};

const Card: React.FC<Props> = ({ id, agent, paymentDate, deliveryDate }) => (
  <div className="flex w-full justify-end">
    <div className="border-[1.5px] border-solid border-gray-500">
      <div className="box-border w-full border-b-[1.5px] border-l-0 border-r-0 border-t-0 border-solid border-gray-500 p-2 text-center">
        P - {id}
      </div>
      <div className="flex gap-6">
        <div className="w-32 p-2">Vositachi</div>
        <div className="box-border flex-1 border-b-[1.5px] border-l-0 border-r-0 border-t-0 border-solid border-gray-500 p-2 pr-6">
          {agent?.full_name || "Mavjud emas"}
        </div>
      </div>
      <div className="flex gap-6">
        <div className="w-32 p-2">Yetkazish sanasi</div>
        <div className="box-border flex-1 border-b-[1.5px] border-l-0 border-r-0 border-t-0 border-solid border-gray-500 p-2 pr-6">
          {deliveryDate || "Mavjud emas"}
        </div>
      </div>
      <div className="flex gap-6">
        <div className="w-32 p-2">To'lash sanasi</div>
        <div className="box-border flex-1 p-2 pr-6">{paymentDate || "Mavjud emas"}</div>
      </div>
    </div>
  </div>
);

export default Card;
