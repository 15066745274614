type RouteSubmodules = [
  {
    key: "Buyurtmalar";
    actions: [
      {
        key: "Buyurtmalarni ko'rish";
        view: true;
      },
      {
        key: "Buyurtma yaratish";
        create: true;
      },
      {
        key: "Buyurtmani o'chirish";
        delete: true;
      },
      {
        key: "Buyurtmani yangilash";
        edit: true;
      },
      {
        key: "Takliflar tarixini ko'rish";
        offerHistory: true;
      },
      {
        key: "Buyurtmalarda chat";
        chatView: true;
      },
      {
        key: "Buyurtmalarni yuklab olish";
        upload: true;
      }
    ];
  },
  {
    key: "Partiyalar";
    actions: [
      {
        key: "Partiyalar jadvalini ko'rish";
        view: true;
      },
      {
        key: "Partiyani tasdiqlash yoki rad etish";
        check: true;
      },
      {
        key: "Partiyani o'zgartirish";
        edit: true;
      },
      {
        key: "Partiyani yaratish";
        create: true;
      },
      {
        key: "Partiyani bekor qilish";
        rejected: true;
      },
      {
        key: "Partiyani yakunlash";
        completed: true;
      },
      {
        key: "Partiyani o'chirish";
        delete: true;
      },
      {
        key: "Partiyani ochish";
        open: true;
      },
      {
        key: "Partiya to'lovini o'chirish";
        deleteOrders: true;
      },
      {
        key: "Partiya to'lovlar tarixi";
        orderHistory: true;
      },
      {
        key: "Partiyalarda chat";
        chatView: true;
      },
      {
        key: "Partiyalarni printdan chiqarish";
        print: true;
      },
      {
        key: "Partiyalarni yuklab olish";
        upload: true;
      }
    ];
  },
  {
    key: "Smeta buyurtmalari";
    actions: [
      {
        key: "Partiya yaratish";
        create: true;
      },
      {
        key: "Smeta buyurtmalari ro'yxati";
        view: true;
      }
    ];
  },
  {
    key: "Takliflar";
    actions: [
      {
        key: "Ko'rish";
        view: true;
      },
      {
        key: "Bekor qilish";
        rejected: true;
      },
      {
        key: "Tasdiqlash";
        recieved: true;
      },
      {
        key: "O'chirish";
        delete: true;
      },
      {
        create: true;
        key: "Taklif yaratish";
      },
      {
        edit: true;
        key: "Taklifni tahrirlash";
      },
      {
        add_resource: true;
        key: "Resurs qo'shish";
      },
      {
        key: "Takliflarda chat";
        chatView: true;
      },
      {
        key: "Takliflar tarixini ko'rish";
        offerHistory: true;
      }
    ];
  }
];
export const routeSubmodules: RouteSubmodules = [
  {
    key: "Buyurtmalar",
    actions: [
      {
        key: "Buyurtmalarni ko'rish",
        view: true
      },
      {
        key: "Buyurtma yaratish",
        create: true
      },
      {
        key: "Buyurtmani o'chirish",
        delete: true
      },
      {
        key: "Buyurtmani yangilash",
        edit: true
      },
      {
        key: "Takliflar tarixini ko'rish",
        offerHistory: true
      },
      {
        key: "Buyurtmalarda chat",
        chatView: true
      },
      {
        key: "Buyurtmalarni yuklab olish",
        upload: true
      }
    ]
  },
  {
    key: "Partiyalar",
    actions: [
      {
        key: "Partiyalar jadvalini ko'rish",
        view: true
      },
      {
        key: "Partiyani tasdiqlash yoki rad etish",
        check: true
      },
      {
        key: "Partiyani o'zgartirish",
        edit: true
      },
      {
        key: "Partiyani yaratish",
        create: true
      },
      {
        key: "Partiyani bekor qilish",
        rejected: true
      },
      {
        key: "Partiyani yakunlash",
        completed: true
      },
      {
        key: "Partiyani o'chirish",
        delete: true
      },
      {
        key: "Partiyani ochish",
        open: true
      },
      {
        key: "Partiya to'lovini o'chirish",
        deleteOrders: true
      },
      {
        key: "Partiya to'lovlar tarixi",
        orderHistory: true
      },
      {
        key: "Partiyalarda chat",
        chatView: true
      },
      {
        key: "Partiyalarni printdan chiqarish",
        print: true
      },
      {
        key: "Partiyalarni yuklab olish",
        upload: true
      },
      {
        key: "Buyurtmani tahrirlash",
        updateProduct: true
      },
      {
        key: "Buyurtmani o'chirish",
        deleteProduct: true
      },
      {
        key: "Taklif yaratish",
        createOffer: true
      },
      {
        key: "Taklifni tahrirlash",
        updateOffer: true
      },
      {
        key: "Taklifni bekor qilish",
        rejectedOffer: true
      },
      {
        key: "Taklifni tasdiqlash",
        receivedOffer: true
      },
      {
        key: "Qabul tarixini ko'rish",
        admissionHistory: true
      }
    ]
  },
  {
    key: "Smeta buyurtmalari",
    actions: [
      {
        key: "Partiya yaratish",
        create: true
      },
      {
        key: "Smeta buyurtmalari ro'yxati",
        view: true
      }
    ]
  },
  {
    key: "Takliflar",
    actions: [
      {
        key: "Ko'rish",
        view: true
      },
      {
        key: "Bekor qilish",
        rejected: true
      },
      {
        key: "Tasdiqlash",
        recieved: true
      },
      {
        key: "O'chirish",
        delete: true
      },
      {
        create: true,
        key: "Taklif yaratish"
      },
      {
        edit: true,
        key: "Taklifni tahrirlash"
      },
      {
        add_resource: true,
        key: "Resurs qo'shish"
      },
      {
        key: "Takliflarda chat",
        chatView: true
      },
      {
        key: "Takliflar tarixini ko'rish",
        offerHistory: true
      }
    ]
  }
];
