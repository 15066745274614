import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  name?: string;
};

const Wrapper: React.FC<Props> = ({ children, name }) => (
  <div className="flex flex-col gap-1.5">
    <label className="text-sm font-medium text-gray-600">{name}</label>
    {children}
  </div>
);

export default Wrapper;
