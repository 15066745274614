import React, { useEffect, useState } from "react";
import { DatePicker, Form, FormInstance, Input } from "antd";
import dayjs from "dayjs";
import CustomUpload from "features/app/components/custom-upload/CustomUpload";
import ModalContentCustomFields from "features/app/components/modal-content-custom-fields/ModalContentCustomFields";
import ProjectSelect from "features/app/components/project-select/project-select";
import { CustomFieldSelectModel } from "features/app/utils/models/customFieldSelectModel";
import { FileListType } from "features/app/utils/models/fileListModel";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { dayjsFormats, isEmptyArr } from "modules/common";

import { CalendarPlusIcon, ConditionalRender, SelectSuffixIcon } from "components";

import styles from "./index.module.scss";

const { Item } = Form;

type Props = {
  form: FormInstance;
  customFields?: CustomFieldSelectModel[];
};

const Info: React.FC<Props> = ({ form, customFields }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileListType[]>([]);
  const { id, visible } = useAppSelector(state => state.transferMakingReducer);

  useEffect(() => {
    if (!visible) {
      setFiles([]);
    }
  }, [visible]);

  return (
    <div className={styles.info}>
      <ProjectSelect
        allowClear
        name="recieved_project_id"
        label={t("warehouse.Loyiha")}
        suffixIcon={<SelectSuffixIcon />}
      />
      <Item name="date" label={t("warehouse.Sana")} rootClassName={styles.datePicker}>
        <DatePicker
          format={dayjsFormats.DATE}
          suffixIcon={<CalendarPlusIcon />}
          placeholder={t("warehouse.Sana")}
          defaultValue={!id ? dayjs(dayjs(), dayjsFormats.DATE) : form.getFieldValue("date")}
        />
      </Item>
      <Item name="description" label={t("warehouse.Izoh")}>
        <Input.TextArea placeholder={t("warehouse.Izoh")} />
      </Item>
      <CustomUpload label="Fayllar" form={form} name="file_ids" isArray fileList={files} setFileList={setFiles} />
      <ConditionalRender if={!isEmptyArr(customFields)}>
        <ModalContentCustomFields
          form={form}
          isView={false}
          disabled={false}
          visible={visible}
          customFields={customFields}
        />
      </ConditionalRender>
    </div>
  );
};

export default Info;
