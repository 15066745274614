import React, { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { Collapse, Spin } from "antd";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { cx, isEmptyArr, RU } from "modules/common";
import { GroupData, PartyStatus, PartyView, PartyViewForm, totalAmount } from "modules/party/view";

import { ConditionalRender, LoadingIndicator, SelectSuffixIcon } from "components";

import ImpostTable from "../impost/table";
import SupplyTable from "../supply/table";
import WarehouseTable from "../warehouse/table";

import PaymentType from "./payment-type";

import styles from "./index.module.scss";

type Props = {
  isLoading: boolean;
  status: PartyStatus;
  form: UseFormReturn<PartyViewForm>;
  products: PartyView["warehouse_products"] | undefined;
  personPaymentTypes: PartyView["company_person_payment_types"];
};

const Index: React.FC<Props> = ({ products, isLoading, status, form, personPaymentTypes }) => {
  const data = GroupData(products);
  const { type } = useAppSelector(state => state.partyReducer.view);
  const { t } = useTranslation();

  const GroupTable = useMemo(() => {
    switch (type) {
      case "supply":
        return SupplyTable;
      case "impost":
        return ImpostTable;
      case "warehouse":
        return WarehouseTable;
      default:
        return SupplyTable;
    }
  }, [type]);

  return (
    <div className="flex-1">
      <ConditionalRender
        if={!isEmptyArr(data)}
        else={
          <Spin spinning={isLoading} indicator={LoadingIndicator}>
            <div className="flex w-full items-center justify-center">
              <TableEmpty />
            </div>
          </Spin>
        }
      >
        <Collapse
          rootClassName={styles.group}
          items={data?.map(item => {
            const total = totalAmount(item?.products);

            return {
              defaultOpen: true,
              key: item?.id || 0,
              label: (
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center gap-4">
                    <div
                      className={cx(
                        "flex items-center justify-center rounded border border-solid px-2 py-0.5 text-xs font-medium",
                        { "border-gray-600 text-gray-600": item?.id },
                        { "border-warning-500 text-warning-500": !item?.id }
                      )}
                    >
                      {item?.name}
                    </div>
                    <PaymentType personPaymentTypes={personPaymentTypes} id={item?.id} />
                  </div>
                  <div className="flex items-center gap-2">
                    <label className="text-base font-medium text-gray-400">{t("partyView.Jami")}:</label>
                    {total?.map((price, index) => (
                      <div
                        key={price?.currencyId}
                        className="flex items-center gap-2 text-base font-medium text-gray-600"
                      >
                        <span>{price?.amount?.toLocaleString(RU) || 0}</span>
                        <span>{price?.currencySymbol}</span>
                        <ConditionalRender if={total[index + 1]?.currencyId}>
                          <span>,</span>
                        </ConditionalRender>
                      </div>
                    ))}
                  </div>
                </div>
              ),
              children: (
                <GroupTable
                  form={form}
                  status={status}
                  groupTable={true}
                  key={item?.id || 0}
                  isLoading={isLoading}
                  products={item?.products}
                />
              )
            };
          })}
          defaultActiveKey={data?.map(item => item?.id || 0) || []}
          expandIcon={({ isActive }) => <SelectSuffixIcon placement={isActive ? "top" : "bottom"} />}
        />
      </ConditionalRender>
    </div>
  );
};

export default Index;
