import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  quantity?: number;
  warehouseTotalQuantity?: number;
  symbol?: string;
};

const Percent: React.FC<Props> = ({ quantity, warehouseTotalQuantity, symbol }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between gap-6">
        <span className="text-sm font-normal text-gray-500">{t("impost.Buyurtma qilingan")}</span>
        <div className="flex items-center gap-1 text-sm font-medium text-gray-600">
          <span>{quantity}</span>
          <span className="text-gray-400">{symbol}</span>
        </div>
      </div>
      <div className="flex items-center justify-between gap-6">
        <span className="text-sm font-normal text-gray-500">{t("impost.Mavjud")}</span>
        <div className="flex items-center gap-1 text-sm font-medium text-gray-600">
          <span>{warehouseTotalQuantity}</span>
          <span className="text-gray-400">{symbol}</span>
        </div>
      </div>
    </div>
  );
};

export default Percent;
