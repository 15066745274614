import React from "react";
import { Control, useWatch } from "react-hook-form";

import { isEmptyArr, RU } from "modules/common";
import { PartyForm, totalAmount } from "modules/party/create";

import { ConditionalRender } from "components";

type Props = {
  index: number;
  control: Control<PartyForm>;
};

const TotalGroup: React.FC<Props> = ({ index, control }) => {
  const { company_person_group } = useWatch({ control });
  const total = totalAmount(company_person_group?.[index]?.products as never[], true);

  return (
    <ConditionalRender if={!isEmptyArr(total)}>
      <label className="text-base font-medium text-gray-400">Jami:</label>
      {total?.map((price, index) => (
        <div key={price?.currencyId} className="flex items-center gap-2 text-base font-medium text-gray-600">
          <span>{price?.amount?.toLocaleString(RU) || 0}</span>
          <span>{price?.currencySymbol}</span>
          <ConditionalRender if={total[index + 1]?.currencyId}>
            <span>,</span>
          </ConditionalRender>
        </div>
      ))}
    </ConditionalRender>
  );
};

export default TotalGroup;
