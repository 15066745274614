import React from "react";
import { DatePicker as BaseDatePicker } from "antd";
import { PickerProps } from "antd/es/date-picker/generatePicker";
import dayjs, { Dayjs } from "dayjs";

import { cx, dayjsFormats } from "modules/common";

import { CalendarPlusIcon } from "../icons";

import Wrapper, { BaseProps } from "./wrapper";

type BaseDatePickerProps = PickerProps<Dayjs>;

interface DatePickerProps extends BaseProps, Omit<BaseDatePickerProps, "picker"> {
  name: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  name,
  label,
  control,
  className,
  allowClear,
  suffixIcon,
  labelClassName,
  ...args
}: DatePickerProps) => {
  label = label || args.placeholder;

  return (
    <Wrapper {...{ control, name, label, labelClassName }}>
      {({ value, onChange }, error) => (
        <BaseDatePicker
          id={name}
          name={name}
          allowClear={allowClear}
          format={dayjsFormats.DATE}
          className={cx("field", className)}
          status={error ? "error" : undefined}
          value={value ? dayjs(value, dayjsFormats.DATE) : undefined}
          onChange={value => onChange((value as Dayjs).format(dayjsFormats.DATE))}
          suffixIcon={suffixIcon || <CalendarPlusIcon />}
          {...args}
        />
      )}
    </Wrapper>
  );
};

export default DatePicker;
