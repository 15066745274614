import { z } from "zod";

export const objectTypeSchema = z.enum([
  "warehouse_products",
  "task_products",
  "payments",
  "warehouse_defects",
  "task_progresses",
  "warehouse_transfers",
  "orders",
  "tasks",
  "inventory_defects",
  "laboratories"
]);

export const commentPayloadSchema = z.object({
  object_id: z.number().optional(),
  message: z.string().optional(),
  type: objectTypeSchema,
  file_ids: z.array(z.number()).optional()
});

export const commentSchema = z.object({
  created_at: z.string(),
  files: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      original_name: z.string(),
      size: z.number()
    })
  ),
  message: z.string(),
  user: z.object({
    id: z.number(),
    full_name: z.string(),
    image: z.string()
  }),
  prevDate: z.string().optional()
});

export type ObjectType = z.infer<typeof objectTypeSchema>;
export type CommentPayload = z.infer<typeof commentPayloadSchema>;
export type Comment = z.infer<typeof commentSchema>;
