import React from "react";
import { UseFormReturn } from "react-hook-form";
import { CustomFieldSelectModel } from "features/app/utils/models/customFieldSelectModel";

import { PartyForm } from "modules/party/create";
import Checkbox from "modules/party/create/form/right/custom-fields/checkbox";
import Date from "modules/party/create/form/right/custom-fields/date";
import File from "modules/party/create/form/right/custom-fields/file";
import Label from "modules/party/create/form/right/custom-fields/label";
import Select from "modules/party/create/form/right/custom-fields/select";
import Text from "modules/party/create/form/right/custom-fields/text";

type Props = {
  index: number;
  isUpdate?: boolean;
  field: CustomFieldSelectModel;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ field, form, index, isUpdate }) => {
  const { type } = field;

  switch (type) {
    case "select":
      return <Select index={index} field={field} form={form} isUpdate={isUpdate} />;
    case "label":
      return <Label index={index} field={field} form={form} isUpdate={isUpdate} />;
    case "date":
      return <Date index={index} field={field} form={form} />;
    case "checkbox":
      return <Checkbox index={index} field={field} form={form} />;
    case "file":
      return <File index={index} field={field} form={form} isUpdate={isUpdate} />;
    default:
      return <Text index={index} field={field} form={form} />;
  }
};

export default Index;
