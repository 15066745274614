import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TemplatesModalReducerInitialState {
  visible: boolean;
  title: string;
}

const initialState: TemplatesModalReducerInitialState = {
  visible: false,
  title: ""
};

const templatesModalSlice = createSlice({
  name: "templatesModal",
  initialState,
  reducers: {
    setOpenModal: (state, action: PayloadAction<{ visible: boolean; title: string }>) => {
      state.visible = action.payload.visible;
      state.title = action.payload.title;
    }
  }
});

export default templatesModalSlice.reducer;
export const templatesModalActions = templatesModalSlice.actions;
