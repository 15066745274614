import React, { KeyboardEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import IncomeExpenseView from "features/payment/components/bottom/income-expense-view/IncomeExpenseView";
import OrderDeleteArchive from "features/payment/components/bottom/order-delete-archive/order-delete-archive";
import PartyModal from "features/supply/components/parts/party-modal/PartyModal";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { appReducerActions } from "store/reducers/appReducer";
import { partyReducerActions } from "store/reducers/partyReducer";
import { supplyOfferActions } from "store/reducers/supplyOfferReducer";
import { supplyActions } from "store/reducers/supplyReducer";

import { PartyCreate, PartyUpdate, PartyView } from "pages";
import AdmissionHistory from "pages/party/admission-history";

import GeneralEditing from "../app/components/general-editing/GeneralEditing";
import { generalEditKeys } from "../app/utils/constants/generalEditData";
import { queryParamsKeys } from "../app/utils/constants/queryParamsKeys";

import OfferHistory from "./components/offers/offer-history/OfferHistory";
import OfferModal from "./components/offers/offer-modal/OfferModal";
import Offers from "./components/offers/table/Offers";
import OrderModal from "./components/orders/order-modal/OrderModal";
import Orders from "./components/orders/table/Orders";
import Parties from "./components/parts/table/Parts";
import { useOrderProductsGeneralEditing, usePartyGeneralEditing, useProductGeneralEditing } from "./service/mutations";
import { useGetSupplyProductsCount } from "./service/queries";
import { routeSubmodules } from "./utils/constants/routeSubmodules";
import { tabKeys } from "./utils/constants/tabKeys";
import { warehouseProductTableDefaultData } from "./utils/constants/warehouseProductTableDefaultData";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { queries, append } = useQueryParams();
  const { data: tableConfigData } = useGetTableConfigs(
    tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG,
    warehouseProductTableDefaultData
  );
  const { data: products, isLoading } = useGetSupplyProductsCount();
  const generalProductUpdate = useProductGeneralEditing();
  const generalPartyUpdate = usePartyGeneralEditing();
  const generalOrderProductUpdate = useOrderProductsGeneralEditing();
  const { submodules, actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const { generalEdit } = useAppSelector(state => state.appReducer);
  const { setSelectParties, setSelectOrderProducts, setOrderModal } = supplyActions;
  const { setGeneralEdit } = appReducerActions;
  const { setSelectOffers } = supplyOfferActions;
  const partyActions = actions("Partiyalar");
  const { setPartyCreateVisible } = partyReducerActions;

  const onChangeTabs = (key: string) => {
    const changeEditKeyFuncs = {
      [tabKeys.OFFERS]: () => {
        dispatch(
          setGeneralEdit({
            key: generalEditKeys.WAREHOUSE_PRODUCTS,
            customFieldKeys: [],
            isView: true
          })
        );
      },
      [tabKeys.ORDERS]: () => {
        dispatch(
          setGeneralEdit({
            key: generalEditKeys.ORDER_PRODUCTS,
            customFieldKeys: [],
            isView: true
          })
        );
      },
      [tabKeys.PARTIES]: () => {
        dispatch(
          setGeneralEdit({
            key: generalEditKeys.ORDERS,
            customFieldKeys: [],
            isView: true
          })
        );
      }
    };

    changeEditKeyFuncs?.[key]();

    append(queryParamsKeys.TAB, key, true);
  };

  const generalUpdateMutations = {
    [generalEditKeys.WAREHOUSE_PRODUCTS]: {
      mutation: generalProductUpdate,
      dispatchFunc: setSelectOffers,
      isDelete: false
    },
    [generalEditKeys.ORDERS]: {
      isDelete: false,
      mutation: generalPartyUpdate,
      dispatchFunc: setSelectParties
    },
    [generalEditKeys.ORDER_PRODUCTS]: {
      isDelete: true,
      mutation: generalOrderProductUpdate,
      dispatchFunc: setSelectOrderProducts
    }
  };

  const items = [
    {
      key: tabKeys.ORDERS,
      label: (
        <p className={styles.orders}>
          <span>{t("Supply.Buyurtmalar")}</span>
          {products?.total && products?.total > 0 ? <span className={styles.orders_count}>{products?.total}</span> : ""}
        </p>
      ),
      children: <Orders isLoadingProducts={isLoading} products={products} tableConfigData={tableConfigData} />,
      permissionKey: "Buyurtmalar"
    },
    {
      key: tabKeys.OFFERS,
      label: `${t("Supply.Takliflar")}`,
      children: <Offers />,
      permissionKey: "Takliflar"
    },
    {
      key: tabKeys.PARTIES,
      label: `${t("Supply.Partiyalar")}`,
      children: <Parties />,
      permissionKey: "Partiyalar"
    }
  ];

  const supplyItems = (): TabsProps["items"] => {
    const newItems: TabsProps["items"] = [];

    items.forEach(item => {
      const findItem = submodules?.find(submodule => submodule.key === item.permissionKey);

      if (findItem && findItem?.key) {
        newItems.push(item);
      }
    });

    return newItems;
  };

  const onOpenOrder = () => {
    dispatch(
      setOrderModal({
        visible: true,
        selectProducts: []
      })
    );
  };

  const onOpenParty = () => {
    dispatch(
      setPartyCreateVisible({
        visible: true,
        type: "supply"
      })
    );
  };

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.altKey && (event.key === "π" || event.key === "p" || event.key === "з")) {
        onOpenParty();
      }

      if (event.altKey && (event.key === "∫" || event.key === "b" || event.key === "и")) {
        onOpenOrder();
      }
    };

    // @ts-ignore
    window.addEventListener("keydown", onKeyDown);

    return () => {
      // @ts-ignore
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <div className={styles.supply}>
      <div className={styles.tabs}>
        <Tabs items={supplyItems()} onChange={onChangeTabs} activeKey={queries()?.[queryParamsKeys.TAB]} />
        <OfferModal invalidateKey={queries()?.[queryParamsKeys.TAB] === tabKeys.PARTIES ? "party" : undefined} />
        <OrderModal />
        <OfferHistory />
        <PartyView />
        <PartyCreate />
        <PartyUpdate />
        <AdmissionHistory />
        <PartyModal actions={actions as never} />
        <GeneralEditing {...generalUpdateMutations[generalEdit.key as keyof typeof generalUpdateMutations]} />
        <OrderDeleteArchive actions={partyActions} />
        <IncomeExpenseView />
      </div>
    </div>
  );
};

export default Index;
