import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import queryString from "query-string";

import { partyUpdateQueryKeys } from "modules/party/update";

type Query = {
  file?: {
    id: number;
    name: string;
    original_name: string;
    size: number;
  };
};

type Props = {
  enabled: boolean;
} & (
  | {
      id: string | number;
      isArray?: false;
    }
  | {
      id: string[] | number[];
      isArray: true;
    }
);

export function useFile({ id, enabled, isArray }: Props) {
  const initial: Query = {
    file: undefined
  };

  const { data = initial, ...arg } = useQuery(
    [partyUpdateQueryKeys.FILE_READ, id],
    async () => {
      const { data } = await $api.get(
        !isArray
          ? `file/view?ids[]=${id}`
          : `file/view?${queryString.stringify(
              {
                ids: id
              },
              { arrayFormat: "bracket" }
            )}`
      );

      return { file: data?.data?.[0] };
    },
    {
      enabled: enabled && !!id
    }
  );

  return { ...data, ...arg };
}
