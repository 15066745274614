import React from "react";

import Card from "./card/card";

type Props = {
  isEditable: boolean;
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
};

const Content: React.FC<Props> = ({ isEditable, setIsEditable }) => {
  const fields = [
    {
      uz: "Nimadir",
      ru: "Nimadir",
      en: "Nimadir"
    },
    {
      uz: "Nimadir",
      ru: "Nimadir",
      en: "Nimadir"
    },
    {
      uz: "Nimadir",
      ru: "Nimadir",
      en: "Nimadir"
    },
    {
      uz: "Nimadir",
      ru: "Nimadir",
      en: "Nimadir"
    }
  ];

  return (
    <div>
      <Card
        text="hello world"
        title=""
        fields={fields}
        updateTemplate={(value: string) => {}}
        isLoading={false}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
      />
    </div>
  );
};

export default Content;
