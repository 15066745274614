import React from "react";
import { Control } from "react-hook-form";

import { getPopupContainer } from "modules/party/create/helpers";

import { Select } from "components";

import { useCompanyPersonFolders } from "../../queries";
import { CompanyPersonForm } from "../../schema";

type Props = {
  control: Control<CompanyPersonForm>;
};

const Folder: React.FC<Props> = ({ control }) => {
  const { folders } = useCompanyPersonFolders();

  return (
    <Select
      label="Papka"
      control={control}
      placeholder="Papkani tanlang"
      name="company_person_folder_id"
      getPopupContainer={getPopupContainer("create-company-person")}
      options={folders?.map(folder => ({
        key: folder?.id,
        value: folder?.id,
        label: folder?.name,
        props: {
          name: folder?.name
        }
      }))}
    />
  );
};

export default Folder;
