import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import $api from "features/app/utils/helpers/axiosInstance";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { WorkModel } from "features/projects/utils/models/workModel";

import useGetTableSize from "hooks/useGetTableSize";
import { useQueryParams } from "hooks/useQueryParams";

interface Query {
  taskView: PaginationType<WorkModel[]>;
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  projectId: number;
}

export function useTaskView({ enabled = false, projectId }: Params) {
  const initialValue: Query = { taskView: { data: [], total: 0, current_page: 1 } };

  let url = useGetTableSize({
    endpoint: "work/task-view",
    tableConfigKey: tableConfigKeys.LIST_OF_WORKS
  });

  const { queries, severalSearchParams } = useQueryParams();
  const { statistics_type } = queries();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE);

  if (projectId && !searchParams) {
    url += `&project_id=${projectId}`;
  }

  if (searchParams && searchParams?.length && !projectId) {
    url += `&${searchParams}`;
  }
  if (searchParams && searchParams?.length > 0 && projectId) {
    url += `&project_id=${projectId}&${searchParams}`;
  }

  const { data = initialValue, ...args } = useQuery<Query>(
    ["taskView", searchParams, projectId],
    async () => {
      const {
        data: { data }
      } = await $api.get(url);

      return { taskView: data };
    },
    { enabled: enabled && statistics_type === "task" }
  );

  return { ...data, ...args };
}
