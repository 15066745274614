import React from "react";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

const Header: React.FC = () => {
  const { t } = useTranslation();
  const { queries } = useQueryParams();
  const { messaging } = queries();

  return (
    <div className="flex flex-col gap-3 border-b border-l-0 border-r-0 border-t-0 border-solid border-b-gray-200 pb-5">
      <span className="text-3xl font-semibold text-gray-900">
        {!messaging ? t("settings.interface.Integratsiya") : t("settings.interface.Sms tizimi")}
      </span>
      <span className="text-base font-medium text-gray-600">
        {!messaging
          ? t("settings.Bu yerda integratsiyalarni ko'rishingiz mumkin")
          : t("settings.Bu yerda Sms tizimini ko'rishingiz mumkin")}
      </span>
    </div>
  );
};

export default Header;
