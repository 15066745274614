import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";

interface QueryParamsReturn {
  append(key: string, value: string | number, removeAll?: boolean, removeKeys?: string[]): void;
  queries(): { [p: string]: string };
  reqQueryParam(...keys: string[]): string;
  searchParams: string;
  remove(key: string): void;
  clearQuery(): void;
  appends: (
    data: {
      key: string;
      value: string | number | boolean | undefined;
    }[],
    removeAll?: boolean
  ) => void;
  severalRemove: (...keys: string[]) => void;
  severalQueries: (...keys: string[]) => {
    [key: string]: any;
  };
  necessaryQueries: (...keys: string[]) => {
    [key: string]: any;
  };
  severalSearchParams: (...keys: string[]) => string;
  generateSearchParam: <T extends object>(obj: T, requiredParams?: { [TParamKey in keyof T]?: boolean }) => string;
}

export function useQueryParams(): QueryParamsReturn {
  const location = useLocation();
  const navigate = useNavigate();

  const remove = (key: string) => {
    const urlSearch = new URLSearchParams(location.search);

    urlSearch.delete(key);

    navigate({
      search: urlSearch.toString()
    });
  };

  const append = (key: string, value: string | number, removeAll?: boolean, removeKeys?: string[]) => {
    const urlSearch = new URLSearchParams(removeAll ? "" : location.search);

    if (value) {
      urlSearch.set(key, String(value));

      if (!removeAll && removeKeys) {
        removeKeys?.forEach(item => {
          urlSearch.delete(item);
        });
      }
    } else {
      urlSearch.delete(key);
    }
    navigate({
      search: urlSearch.toString()
    });
  };

  const appends = (data: { key: string; value: string | number | boolean | undefined }[], removeAll?: boolean) => {
    const urlSearch = new URLSearchParams(removeAll ? "" : location.search);

    data?.forEach(item => {
      if (item.value) {
        urlSearch.set(item.key, String(item.value));
      } else {
        urlSearch.delete(item.key);
      }
    });

    navigate({
      search: urlSearch.toString()
    });
  };

  const queries = () => {
    const queryParams = new URLSearchParams(location.search);
    const queryParamObject: { [key: string]: string } = {};

    for (const [key, value] of queryParams.entries()) {
      queryParamObject[key] = value;
    }

    return queryParamObject;
  };

  const severalQueries = (...keys: string[]) => {
    const queryParams = queryString.parse(location.search, {
      arrayFormat: "bracket"
    });
    const queryParamObject: { [key: string]: any } = {};

    for (const key in queryParams) {
      const value = queryParams[key];
      const isSomeKey = keys?.some(item => item !== value);

      if (isSomeKey) {
        queryParamObject[key] = value;
      }
    }

    return queryParamObject;
  };

  const necessaryQueries = (...keys: string[]) => {
    const queryParams = queryString.parse(location.search, {
      arrayFormat: "bracket"
    });
    const queryParamObject: { [key: string]: any } = {};

    for (const key in queryParams) {
      const value = queryParams[key];
      const isSomeKey = keys?.some(item => item === key);

      if (isSomeKey) {
        queryParamObject[key] = value;
      }
    }

    return queryParamObject;
  };

  const reqQueryParam = (...keys: string[]) => {
    const queryParamObject = queryString.parse(location.search);
    const result: Record<string, string | string[]> = {};

    keys?.forEach(key => {
      if (queryParamObject[key]) {
        if (key === queryParamsKeys.INCOME_FINANCIAL_IDS_ARR || key === queryParamsKeys.EXPENSE_FINANCIAL_IDS_ARR) {
          const existingValue = result[queryParamsKeys.FINANCIAL_IDS_ARR];
          const newValue = Array.isArray(queryParamObject[key]) ? queryParamObject[key] : [queryParamObject[key]];

          result[queryParamsKeys.FINANCIAL_IDS_ARR] = existingValue
            ? ([...(Array.isArray(existingValue) ? existingValue : [existingValue]), ...newValue] as string | string[])
            : (newValue as string | string[]);
        } else if (key === queryParamsKeys.COUNTERPARTS_DETAILED_SEARCH) {
          result[queryParamsKeys.SEARCH] = queryParamObject[queryParamsKeys.COUNTERPARTS_DETAILED_SEARCH] as string;
        } else {
          result[key] = queryParamObject[key] as string | string[];
        }
      }
    });

    return queryString.stringify(result);
  };

  const severalRemove = (...keys: string[]) => {
    const urlSearch = new URLSearchParams(location.search);

    keys?.forEach(item => {
      urlSearch.delete(item);
    });

    navigate({
      search: urlSearch.toString()
    });
  };

  const clearQuery = () => {
    navigate({
      search: undefined
    });
  };

  const severalSearchParams = (...keys: string[]) => {
    const urlSearch = new URLSearchParams(location.search);

    keys?.forEach(key => {
      if (urlSearch.has(key)) {
        urlSearch.delete(key);
      }
    });

    return urlSearch.toString();
  };

  const generateSearchParam = <T extends object>(obj: T, requiredParams?: { [TParamKey in keyof T]?: boolean }) => {
    const newParams = new URLSearchParams();

    for (const [key, value] of Object.entries(obj)) {
      if (value) {
        if (typeof value === "object" && Array.isArray(value)) {
          (value as Array<any>).forEach(item => {
            newParams.append(`${key}`, String(item));
          });
        } else {
          newParams.append(key, String(value));
        }
      }

      if (requiredParams?.[key as keyof typeof requiredParams]) {
        newParams.append(key, value || null);
      }
    }

    return newParams.toString();
  };

  return {
    append,
    queries,
    remove,
    appends,
    clearQuery,
    reqQueryParam,
    severalRemove,
    severalQueries,
    necessaryQueries,
    severalSearchParams,
    generateSearchParam,
    searchParams: location.search
  };
}
