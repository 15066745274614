import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useQueryParams } from "hooks/useQueryParams";

import { appReducerActions } from "store/actions";

import { setVisible } from "modules/settings/uysot";

import UysotModal from "pages/settings/uysot";

import { useIntegrationConnect } from "../mutations";
import { Integration } from "../schema";

import Default from "./default";

const Uysot: React.FC<Integration> = ({ connect, id, start_date }) => {
  const dispatch = useDispatch();
  const { queries, remove } = useQueryParams();
  const { key } = queries();
  const { setIsConfettiExplosion } = appReducerActions;
  const [disconnect, setDisconnect] = useState(false);
  const { mutateAsync, isLoading } = useIntegrationConnect();

  const onConnect = () => {
    if (connect) {
      dispatch(
        setVisible({
          id,
          step: 1,
          start_date,
          visible: true
        })
      );
    } else {
      const a = document.createElement("a");

      a.href = `${import.meta.env.VITE_UYSOT_BASE_URL}/integration/uyqur?extra_id=1&redirect_url=${window.location.href}`;

      a.click();
      a.remove();
    }
  };

  const onDisConnect = () => {
    setDisconnect(true);

    return mutateAsync({ key: null }).then(() => {
      setDisconnect(false);
    });
  };

  useEffect(() => {
    if (key) {
      mutateAsync({ key }).then(() => {
        dispatch(
          setVisible({
            id,
            step: 1,
            start_date,
            visible: true
          })
        );

        remove("key");

        dispatch(setIsConfettiExplosion(true));

        setTimeout(() => {
          dispatch(setIsConfettiExplosion(false));
        }, 4000);
      });
    }
  }, []);

  return (
    <>
      <Default
        connect={connect}
        onConnect={onConnect}
        isLoading={isLoading}
        isDisconnect={disconnect}
        onDisConnect={onDisConnect}
      />
      <UysotModal />
    </>
  );
};

export default Uysot;
