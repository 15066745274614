import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UseMutationResult } from "@tanstack/react-query";
import { Button } from "antd";
import CustomFieldIcon from "features/app/assets/icons/CustomFieldIcon";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { CustomFieldTypesEnum } from "features/app/utils/enums/customFieldTypesEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { generateExcelPayload } from "features/app/utils/helpers/generateExcelPayload";
import { parseLocaledString } from "features/app/utils/helpers/parseLocaledString";
import {
  companyPersonTableDefaultData,
  companyPersonTableDefaultDataExportExcel
} from "features/counterparts/utils/constants/tableDefaultData";
import ArrowSquareDownIcon from "features/settings/assets/icons/ArrowSquareDownIcon";
import TagIcon from "features/settings/assets/icons/TagIcon";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { colors } from "modules/common";

import { ConditionalRender } from "components";

import ScalesIcon from "../../../app/assets/icons/ScalesIcon";
import FilterPricesRange from "../../../app/components/filter-drawer/filter-prices-range/FilterPricesRange";
import FilterSelect from "../../../app/components/filter-drawer/filter-select/FilterSelect";
import FilterDrawer from "../../../app/components/filter-drawer/FilterDrawer";
import FiltersCount from "../../../app/components/filter-drawer/filters-count/FiltersCount";
import { queryParamsKeys } from "../../../app/utils/constants/queryParamsKeys";
import ZapIcon from "../../../projects/assets/icons/ZapIcon";
import FilterLinesIcon from "../../../warehouse/assets/icons/FilterLinesIcon";
import { CompanyPersonEnum } from "../../utils/enum/companyPersonEnums";

import styles from "./filterDrawer.module.scss";

const queryStringArr = [
  queryParamsKeys.TYPES,
  queryParamsKeys.BALANCE_TOTAL_AMOUNT_TYPE,
  queryParamsKeys.MIN_TOTAL_AMOUNT,
  queryParamsKeys.MAX_TOTAL_AMOUNT,
  queryParamsKeys.CUSTOM_FIELDS,
  queryParamsKeys.SEARCH
];

export interface Props {
  customFieldEnum?: CustomFieldLocationEnum;
  tableKey?: tableConfigKeys;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportExcelMutate?: UseMutationResult<any, unknown, any, unknown>;
}

const FilterDrawerCounterparts: React.FC<Props> = ({ customFieldEnum, exportExcelMutate, tableKey }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { queries, necessaryQueries } = useQueryParams();
  const { balance_total_amount_type } = queries();
  const [balanceTotalAmountSelected, setBalanceTotalAmountSelected] = useState<string[] | null>(
    queryString.parse(location.search, {})[queryParamsKeys.BALANCE_TOTAL_AMOUNT_TYPE] as string[] | null
  );
  const [typesSelected, setTypesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.TYPES] as string[] | null
  );
  const [startPriceValue, setStartPriceValue] = useState<string>(
    queryString.parse(location.search)[queryParamsKeys.MIN_TOTAL_AMOUNT] as string
  );
  const [endPriceValue, setEndPriceValue] = useState<string>(
    queryString.parse(location.search)[queryParamsKeys.MAX_TOTAL_AMOUNT] as string
  );

  const { data: customFields } = useGetCustomFieldSelect(customFieldEnum ? [customFieldEnum] : []);
  const { data: tableConfigData, isFetching } = useGetTableConfigs(tableKey, companyPersonTableDefaultData);
  const changeTableConfiguration = useChangeTableConfig(tableKey!, true, false);

  const [customFieldStates, setCustomFieldStates] = useState<{ [key: string]: string[] | null }>({});

  const selectableCustomFields = customFields?.filter(
    customField => customField?.type === "select" || customField?.type === "label"
  );

  const handleCustomFieldChange = (id: string, value: string[] | null) => {
    setCustomFieldStates(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const aggregatedCustomFieldsSelected = useMemo(() => Object.values(customFieldStates).flat(), [customFieldStates]);

  const onOpenFilter = () => {
    setOpen(true);
  };

  const typesData = useMemo(
    () => [
      {
        id: "worker",
        name: `${t("Counterparts.Ishchi")}`
      },
      {
        id: "supplier",
        name: `${t("Counterparts.Ta'minotchi")}`
      }
    ],
    []
  );

  const balanceTotalAmountTypeData = useMemo(
    () => [
      {
        id: CompanyPersonEnum.DEBITOR,
        name: `${t("Counterparts.Debitor")}`
      },
      {
        id: CompanyPersonEnum.CREDITOR,
        name: `${t("Counterparts.Kreditor")}`
      }
    ],
    []
  );

  const generatingExcelColumns = useMemo(() => {
    if (!balanceTotalAmountSelected || (balanceTotalAmountSelected && balanceTotalAmountSelected?.length === 0)) {
      return {
        ...companyPersonTableDefaultDataExportExcel,
        column: [
          ...companyPersonTableDefaultDataExportExcel.column,
          {
            id: "credit_amount",
            name: "credit_amount",
            title: "Kreditor",
            checked: true
          }
        ]
      };
    }
    return {
      ...companyPersonTableDefaultDataExportExcel,
      column: companyPersonTableDefaultDataExportExcel?.column?.map(col => {
        if (
          balanceTotalAmountTypeData &&
          (balanceTotalAmountSelected as unknown as string) === "creditor" &&
          col?.id === "debt_amount"
        ) {
          return {
            id: "credit_amount",
            name: "credit_amount",
            title: "Kreditor",
            checked: true
          };
        }
        return col;
      })
    };
  }, [balanceTotalAmountSelected]);

  const onExcelExport = () => {
    const data = filterColumns({
      customFields,
      tableConfig: generatingExcelColumns
    })
      ?.map(value => ({
        key: String(value?.id),
        name: value?.title
      }))
      ?.filter(item => typeof item?.name !== "object");

    const otherParamsData = necessaryQueries(queryParamsKeys.SEARCH);

    const reqBody = {
      columns: data,
      types: typesSelected,
      balance_total_amount_type: (() => {
        if (Array.isArray(balanceTotalAmountSelected)) return balanceTotalAmountSelected?.[0];

        return balanceTotalAmountSelected || undefined;
      })(),
      min_total_amount: Number(parseLocaledString(startPriceValue)),
      max_total_amount: Number(parseLocaledString(endPriceValue)),
      ...otherParamsData
    };

    if (data) {
      exportExcelMutate?.mutate(generateExcelPayload(reqBody));
    }
  };

  const footerContent = tableKey ? (
    <>
      <Button onClick={onExcelExport} className={styles.downloadBtn} loading={exportExcelMutate?.isLoading}>
        <DownloadIcon /> {t("Counterparts.Yuklab olish")}
      </Button>
    </>
  ) : (
    <span />
  );

  useEffect(() => {
    if (balance_total_amount_type) {
      setBalanceTotalAmountSelected([balance_total_amount_type]);
    }
  }, [balance_total_amount_type]);

  const customFieldIcons: Record<string, React.ReactNode> = {
    [CustomFieldTypesEnum.SELECT]: <ArrowSquareDownIcon />,
    [CustomFieldTypesEnum.LABEL]: <TagIcon />
  };

  const searchParams = queryString.parse(location.search, { arrayFormat: "bracket" });
  const fieldValues = searchParams[queryParamsKeys.CUSTOM_FIELDS];

  const onAfterSubmit = (filter?: string) => {
    changeTableConfiguration.mutate({
      filter: filter!,
      key: tableKey!
    });
  };

  useEffect(() => {
    if (selectableCustomFields && open) {
      const initialCustomFieldStates: { [key: string]: string[] | null } = {};

      selectableCustomFields.forEach(field => {
        if (fieldValues) {
          const valuesArray = Array.isArray(fieldValues) ? fieldValues : [fieldValues];

          initialCustomFieldStates[field.id] = valuesArray.filter(value =>
            field?.custom_field_options?.some(option => String(option.id) === value)
          ) as string[];
        } else {
          initialCustomFieldStates[field.id] = [];
        }
      });

      setCustomFieldStates(initialCustomFieldStates);
    }
  }, [open]);

  useEffect(() => {
    if (tableConfigData?.filter && tableConfigData?.filter?.length > 0) {
      const searchParams = new URLSearchParams();

      const filterParams = new URLSearchParams(tableConfigData?.filter);

      filterParams.forEach((value, key) => {
        searchParams.append(key, value);
      });

      const newSearchParams = searchParams.toString();

      navigate({
        search: `?${newSearchParams}`
      });
    }
  }, [tableConfigData?.filter, navigate]);

  const parseQueryParams = (key: string) =>
    queryString.parse(window.location.search, { arrayFormat: "bracket" })[key] as string[] | null;

  const parseSingleQueryParams = (key: string) =>
    queryString.parse(window.location.search, { arrayFormat: "bracket" })[key] as string;

  useEffect(() => {
    setTypesSelected(parseQueryParams(queryParamsKeys.TYPES));
    setBalanceTotalAmountSelected(parseQueryParams(queryParamsKeys.BALANCE_TOTAL_AMOUNT_TYPE));
    setStartPriceValue(parseSingleQueryParams(queryParamsKeys.MIN_TOTAL_AMOUNT));
    setEndPriceValue(parseSingleQueryParams(queryParamsKeys.MAX_TOTAL_AMOUNT));
  }, [window.location.search]);

  return (
    <FilterDrawer
      onAfterSubmit={onAfterSubmit}
      onAfterClear={onAfterSubmit}
      open={open}
      setOpen={setOpen}
      filterButton={
        <Button onClick={onOpenFilter}>
          <FilterLinesIcon /> {t("Counterparts.Filter")}
          <FiltersCount queryStringArr={queryStringArr} />
        </Button>
      }
      footerContent={footerContent}
      height="max-content"
      queryStringArr={queryStringArr}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.BALANCE_TOTAL_AMOUNT_TYPE,
          selectedOptions: balanceTotalAmountSelected
        },

        {
          queryKey: queryParamsKeys.TYPES,
          selectedOptions: typesSelected
        },
        {
          queryKey: queryParamsKeys.CUSTOM_FIELDS,
          selectedOptions: aggregatedCustomFieldsSelected as string[]
        }
      ]}
      selectedValueArr={[
        {
          queryKey: queryParamsKeys.MIN_TOTAL_AMOUNT,
          selectedValue: startPriceValue
        },
        {
          queryKey: queryParamsKeys.MAX_TOTAL_AMOUNT,
          selectedValue: endPriceValue
        }
      ]}
      setSelectedOptionsArr={[setTypesSelected, setBalanceTotalAmountSelected, setCustomFieldStates] as never}
      setSelectedValueArr={[setStartPriceValue, setEndPriceValue]}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 text-base font-medium text-gray-800">
            <FilterLinesIcon stroke={colors.GRAY_800} /> {t("Counterparts.Umumiy filterlar")}
          </div>
          <div className={styles.content}>
            <FilterSelect
              showSearch
              data={typesData}
              icon={<ZapIcon />}
              multiple="multiple"
              placeholder={t("Counterparts.Kontragent turi")}
              selectedOptions={typesSelected}
              setSelectedOptions={setTypesSelected}
            />
            <FilterSelect
              allowClear
              icon={<ScalesIcon />}
              data={balanceTotalAmountTypeData}
              placeholder={t("Counterparts.Kreditor")}
              selectedOptions={balanceTotalAmountSelected}
              setSelectedOptions={setBalanceTotalAmountSelected}
            />
            <FilterPricesRange
              placeholder={t("Counterparts.Umumiy summa")}
              endPriceValue={endPriceValue}
              startPriceValue={startPriceValue}
              setEndPriceValue={setEndPriceValue}
              setStartPriceValue={setStartPriceValue}
            />
          </div>
        </div>
        <ConditionalRender if={selectableCustomFields && selectableCustomFields?.length > 0}>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 text-base font-medium text-gray-800">
              <CustomFieldIcon stroke={colors.GRAY_800} width={20} /> {t("Counterparts.O'zgaruvchi filterlar")}
            </div>
            <div className={styles.content}>
              {selectableCustomFields?.map(el => (
                <FilterSelect
                  key={el.id}
                  selectedOptions={customFieldStates[el.id]}
                  setSelectedOptions={value => handleCustomFieldChange(String(el.id), value)}
                  data={el.custom_field_options as never}
                  multiple="multiple"
                  icon={customFieldIcons[el.type]}
                  placeholder={el.name[i18n.language]}
                  showSearch
                />
              ))}
            </div>
          </div>
        </ConditionalRender>
      </div>
    </FilterDrawer>
  );
};

export default FilterDrawerCounterparts;
