import React, { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dropdown } from "antd";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { colors } from "features/app/utils/constants/colors";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { partyReducerActions } from "store/actions";
import { paymentReducerActions } from "store/reducers/paymentReducer";

import DeleteIcon from "../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../app/assets/icons/DotsVerticalIcon";
import OrderedIcon from "../../../app/assets/icons/OrderedIcon";
import SettingsIcon from "../../../app/assets/icons/SettingsIcon";
import { tableConfigKeys } from "../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../app/utils/enums/customFieldLocationEnum";
import FilterDrawerParts from "../../../supply/components/parts/filter-drawer/FilterDrawerParts";
import FileHistoryIcon from "../../assets/icons/FileHistoryIcon";
import RefreshIcon from "../../assets/icons/RefreshIcon";
import SwitchHorizontalIcon from "../../assets/icons/SwitchHorizontalIcon";
import { usePaymentOrderExportExcel } from "../../service/mutation";
import { PaymentTabKeys } from "../../utils/constants/paymentTabKeys";

import DeleteArchive from "./delete-archive/DeleteArchive";
import ExchangeDrawer from "./exchange-drawer/exchange-drawer";
import { CalendarFilterDrawer } from "./filter-drawer/calendar/CalendarFilterDrawer";
import PaymentFilterDrawer from "./filter-drawer/payment/PaymentFilterDrawer";
import WorkDrawer from "./filter-drawer/work/WorkDrawer";
import ReportPaymentModal from "./report-payment-modal/ReportPaymentModal";
import PaymentSearch from "./search/PaymentSearch";
import SettingDropdown from "./setting-dropdown/SettingDropdown";
import PaymentTransferCheckModal from "./transfer-check-modal/PaymentTransferCheckModal";
import Transfer from "./transfer-drawer/transfer";

import styles from "./paymentTop.module.scss";

const PaymentTop: React.FC = () => {
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const specificActions = actions("Maxsus imkoniyatlar");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { queries } = useQueryParams();
  const { statistics_type } = queries();
  const { setPartyCreate } = partyReducerActions;
  const { setReportPayment, setTransfer, setTransferCheck, setExchange } = paymentReducerActions;
  const paymentOrderExportExcel = usePaymentOrderExportExcel();

  const [openSetting, setOpenSetting] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [visibleDeleteArchive, setVisibleDeleteArchive] = useState<boolean>(false);

  const onOpenTransferDrawer = () => {
    dispatch(
      setTransfer({
        visible: true
      })
    );

    setOpenDropdown(false);
  };

  const onOpenTransferCheckModal = () => {
    dispatch(
      setTransferCheck({
        visible: true
      })
    );

    setOpenDropdown(false);
  };

  const onOpenExchangeDrawer = () => {
    dispatch(
      setExchange({
        visible: true
      })
    );

    setOpenDropdown(false);
  };

  const onOpenDeleteArchive = () => {
    setVisibleDeleteArchive(true);
    setOpenDropdown(false);
  };

  const onOpenPartyModal = () => {
    dispatch(
      setPartyCreate({
        visible: true,
        type: "payment",
        isGroup: false,
        isDiscount: false,
        isVat: false
      })
      // setPartyModal({
      //   visible: true,
      //   party_id,
      //   isView,
      //   isEditing: !isView
      // })
    );
    setOpenDropdown(false);
  };

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onOpenReportExcel = () => {
    dispatch(setReportPayment(true));
  };

  const actionItems = () => {
    const items = [
      {
        key: "1",
        label: (
          <div className={styles.dropdown_item}>
            <SwitchHorizontalIcon stroke={colors.GRAY_500} /> {t("payment.O'tkazma qilish")}
          </div>
        ),
        onClick: onOpenTransferDrawer,
        permissionKey: "transfer",
        isView: true
      },
      {
        key: "2",
        label: (
          <div className={styles.dropdown_item}>
            <FileHistoryIcon stroke={colors.GRAY_500} /> {t("payment.Kutilayotgan o'tkazmalar")}
          </div>
        ),
        onClick: onOpenTransferCheckModal,
        permissionKey: "viewPendingTransfers",
        isView: true
      },
      {
        key: "3",
        label: (
          <div className={styles.dropdown_item}>
            <RefreshIcon stroke={colors.GRAY_500} /> {t("payment.Konvertatsiya qilish")}
          </div>
        ),
        onClick: onOpenExchangeDrawer,
        permissionKey: "exchange",
        isView: true
      },
      {
        key: "4",
        label: (
          <div className={styles.dropdown_item}>
            <OrderedIcon size={20} stroke={colors.GRAY_500} /> {t("payment.Partiya yaratish")}
          </div>
        ),
        onClick: onOpenPartyModal,
        permissionKey: "createParty",
        isView: true
      },
      {
        key: "5",
        label: (
          <div className={styles.dropdown_item}>
            <DownloadIcon stroke={colors.GRAY_500} />
            {t("payment.Hisobotni yuklab olish")}
          </div>
        ),
        onClick: onOpenReportExcel,
        permissionKey: "downloadReport",
        isView: true
      },
      {
        key: "6",
        label: (
          <div className={styles.dropdown_item}>
            <DeleteIcon stroke={colors.GRAY_500} /> {t("payment.To’lovlar arxivi")}
          </div>
        ),
        onClick: onOpenDeleteArchive,
        permissionKey: "paymentHistory",
        isView: true
      },
      {
        key: "7",
        label: (
          <SettingDropdown setOpen={setOpenSetting} open={openSetting}>
            <div className={styles.dropdown_item} onClick={stopPropagation}>
              <SettingsIcon size={20} stroke={colors.GRAY_500} /> {t("payment.Sozlamalar")}
            </div>
          </SettingDropdown>
        ),
        isView: true
      }
    ];

    return items.filter(item => {
      if (item?.permissionKey) {
        return specificActions[item.permissionKey as keyof typeof specificActions] && item?.isView;
      }
      return item?.isView;
    });
  };

  const onOpenChangeDropdown = (open: boolean) => {
    if (!openSetting) {
      setOpenDropdown(open);
    }
  };

  const filterContent = useMemo(() => {
    const allFilterContent = {
      [PaymentTabKeys.CALENDAR]: <CalendarFilterDrawer />,
      [PaymentTabKeys.INCOME_EXPENSE]: <PaymentFilterDrawer isCounterParts={false} />,
      [PaymentTabKeys.ORDER]: (
        <FilterDrawerParts
          exportExcelMutate={paymentOrderExportExcel}
          tableKey={tableConfigKeys.PAYMENT_ORDER_CONFIG}
          customFieldEnum={CustomFieldLocationEnum.ORDER}
        />
      ),
      [PaymentTabKeys.WORK]: <WorkDrawer />
    };

    return statistics_type ? (
      allFilterContent[statistics_type as keyof typeof allFilterContent]
    ) : (
      <PaymentFilterDrawer isCounterParts={false} />
    );
  }, [paymentOrderExportExcel, statistics_type]);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.altKey && (event.key === "†" || event.key === "t")) {
        onOpenTransferDrawer();
      }

      if (event.altKey && (event.key === "˚" || event.key === "k")) {
        onOpenExchangeDrawer();
      }
    };

    // @ts-ignore
    window.addEventListener("keydown", onKeyDown);

    return () => {
      // @ts-ignore
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <div className={styles.payment_top}>
      <div className={styles.payment_top__right}>
        <PaymentSearch />
        {filterContent}
        <Dropdown
          menu={{
            items: actionItems()
          }}
          trigger={["click"]}
          open={openDropdown}
          mouseLeaveDelay={0.2}
          mouseEnterDelay={1000}
          onOpenChange={onOpenChangeDropdown}
        >
          <Button>
            <DotsVerticalIcon />
          </Button>
        </Dropdown>
        <ReportPaymentModal />
        <DeleteArchive open={visibleDeleteArchive} setOpen={setVisibleDeleteArchive} />
        <Transfer />
        <PaymentTransferCheckModal />
        <ExchangeDrawer />
      </div>
    </div>
  );
};

export default PaymentTop;
