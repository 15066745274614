import React from "react";
import {
  FieldArrayWithId,
  useFieldArray,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  UseFormReturn,
  useWatch
} from "react-hook-form";
import { Table } from "antd";
import PlusCircleIcon from "features/app/assets/icons/PlusCircleIcon";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { localeFormatter } from "features/app/utils/helpers/localeFormatter";
import { uid } from "uid";

import { useAppSelector } from "hooks/redux";

import { parseLocaledString } from "modules/common";
import {
  AllCompanyPersonsUpdate,
  AllCurrenciesUpdate,
  AllProjectsUpdate,
  AllVatsUpdate,
  AllWarehousesUpdate,
  GroupAmount,
  GroupCompanyPerson,
  GroupCurrency,
  GroupDelete,
  GroupProduct,
  GroupProject,
  GroupQuantity,
  GroupTotalAmount,
  GroupUnit,
  GroupVat,
  GroupWarehouse,
  PartyForm,
  PartyFormProduct
} from "modules/party/create";

import { ConditionalRender } from "components";

import styles from "./index.module.scss";

type Props = {
  isUpdate?: boolean;
  parentIndex: number;
  remove: UseFieldArrayRemove;
  form: UseFormReturn<PartyForm>;
  append: UseFieldArrayAppend<PartyForm, "company_person_group">;
  update: UseFieldArrayUpdate<PartyForm, "company_person_group">;
  fields: FieldArrayWithId<PartyForm, "company_person_group", "group_id">[];
};

const Index: React.FC<Props> = ({
  form,
  update,
  fields,
  isUpdate,
  parentIndex,
  append: appendGroup,
  remove: removeGroup
}) => {
  const { company_person_group } = useWatch({ control: form.control });
  const { isVat: isVatCreate } = useAppSelector(({ partyReducer }) => partyReducer.create);
  const { isVat: isVatUpdate } = useAppSelector(({ partyReducer }) => partyReducer.update);

  const {
    remove,
    append,
    fields: productFields
  } = useFieldArray({
    keyName: "unique",
    control: form.control,
    name: `company_person_group.${parentIndex}.products`
  });

  const onAddProduct = () => {
    const thisPerson = { id: company_person_group?.[parentIndex]?.id, name: company_person_group?.[parentIndex]?.name };
    const products = [...(company_person_group?.[parentIndex]?.products || [])];
    const lastProduct = { ...products?.[products.length - 1] } as PartyFormProduct;

    append({
      // @ts-ignore
      product_id: null,
      // @ts-ignore
      unit_id: null,
      unit_name: "",
      quantity: "",
      amount: "",
      project_id: lastProduct?.project_id,
      warehouse_id: lastProduct?.warehouse_id,
      company_person_id: lastProduct?.company_person_id || thisPerson?.id || undefined,
      company_person_name: lastProduct?.company_person_name || thisPerson?.name,
      currency_id: lastProduct?.currency_id,
      currency_symbol: lastProduct?.currency_symbol,
      vat_id: lastProduct?.vat_id,
      vat_value: lastProduct?.vat_value,
      vat_amount: undefined,
      total: "",
      rowId: uid()
    });
  };

  return (
    <Table
      locale={{
        emptyText: <TableEmpty />
      }}
      rowKey="unique"
      pagination={false}
      rootClassName={styles.table}
      columns={[
        {
          key: 1,
          title: "Resurs turi va nomi",
          render: (_, __, index) => (
            <GroupProduct isUpdate={isUpdate} parentIndex={parentIndex} form={form} index={index} />
          ),
          width: 330
        },
        {
          key: 2,
          title: "Birligi",
          render: (_, __, index) => (
            <GroupUnit isUpdate={isUpdate} parentIndex={parentIndex} index={index} form={form} />
          )
        },
        {
          key: 3,
          title: "Soni",
          render: (_, __, index) => <GroupQuantity parentIndex={parentIndex} index={index} form={form} />,
          width: 100
        },
        {
          key: 4,
          title: "Birlik narxi",
          render: (_, __, index) => <GroupAmount parentIndex={parentIndex} index={index} form={form} />,
          width: 100
        },
        {
          key: 8,
          title: <AllCurrenciesUpdate type="group" form={form} parentIndex={parentIndex} />,
          render: (_, __, index) => (
            <GroupCurrency isUpdate={isUpdate} parentIndex={parentIndex} form={form} index={index} />
          )
        },
        {
          key: 7,
          title: (
            <AllCompanyPersonsUpdate
              type="group"
              form={form}
              update={update}
              fields={fields}
              remove={removeGroup}
              append={appendGroup}
              appendProduct={append}
              parentIndex={parentIndex}
            />
          ),
          render: (_, __, index) => (
            <GroupCompanyPerson
              form={form}
              index={index}
              remove={remove}
              isUpdate={isUpdate}
              appendGroup={appendGroup}
              parentIndex={parentIndex}
            />
          )
        },
        {
          key: 5,
          title: <AllWarehousesUpdate form={form} type="group" parentIndex={parentIndex} />,
          render: (_, __, index) => (
            <GroupWarehouse isUpdate={isUpdate} parentIndex={parentIndex} form={form} index={index} />
          )
        },
        {
          key: 6,
          title: <AllProjectsUpdate type="group" form={form} parentIndex={parentIndex} />,
          render: (_, __, index) => (
            <GroupProject isUpdate={isUpdate} parentIndex={parentIndex} form={form} index={index} />
          )
        },
        {
          key: 11,
          title: <AllVatsUpdate type="group" form={form} parentIndex={parentIndex} />,
          render: (_, __, index) => (
            <GroupVat isUpdate={isUpdate} parentIndex={parentIndex} index={index} form={form} />
          ),
          hidden: !(isVatCreate || isVatUpdate)
        },
        {
          key: 9,
          title: "Summa",
          render: (_, __, index) => <GroupTotalAmount parentIndex={parentIndex} index={index} form={form} />
        },
        {
          key: 12,
          width: 120,
          title: "NDS summa",
          render: (_, __, index) => (
            <div className="box-border flex h-full w-full items-center bg-white p-3.5 text-sm font-medium">
              <ConditionalRender if={company_person_group?.[parentIndex]?.products?.[index]?.vat_id} else="--">
                {localeFormatter(
                  String(company_person_group?.[parentIndex]?.products?.[index]?.vat_amount?.toFixed(2))
                )}
              </ConditionalRender>
            </div>
          ),
          hidden: !(isVatCreate || isVatUpdate)
        },
        {
          key: 10,
          title: "Jami",
          render: (_, __, index) => (
            <div className="box-border flex h-full w-full items-center bg-white p-3.5 text-sm font-medium">
              <ConditionalRender if={company_person_group?.[parentIndex]?.products?.[index]?.vat_id} else="--">
                {localeFormatter(
                  String(
                    (
                      (company_person_group?.[parentIndex]?.products?.[index]?.vat_amount || 0) +
                      parseLocaledString(company_person_group?.[parentIndex]?.products?.[index]?.total || "0")
                    )?.toFixed(2)
                  )
                )}
              </ConditionalRender>
            </div>
          ),
          hidden: !(isVatCreate || isVatUpdate)
        },
        {
          key: 11,
          title: "",
          render: (_, __, index) => (
            <GroupDelete
              index={index}
              remove={remove}
              isUpdate={isUpdate}
              control={form.control}
              parentIndex={parentIndex}
            />
          )
        }
      ]}
      dataSource={productFields as PartyFormProduct[]}
      footer={() => (
        <div
          onClick={onAddProduct}
          className="flex w-max cursor-pointer items-center gap-2 text-sm font-medium text-primary-500"
        >
          <PlusCircleIcon fill />
          Resurs qo'shish
        </div>
      )}
    />
  );
};

export default Index;
