import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { uysotQueryKeys } from "../query-keys";
import { UysotCurrency } from "../schema";

type Query = {
  currencies: UysotCurrency[];
};

export function useUysotCurrencies() {
  const initial = {
    currencies: []
  };

  const { data = initial, ...arg } = useQuery<Query>([uysotQueryKeys.CURRENCY], async () => {
    const { data } = await $api.get("integration/uysot-currency/view");

    return { currencies: data?.data };
  });

  return { ...data, ...arg };
}
