import React from "react";
import dayjs from "dayjs";

import { cx, dayjsFormats, RU, TooltipShortName } from "modules/common";
import { Card as CardType, WidgetKeys, widgetStaticData } from "modules/dashboard";

import { ArrowUpIcon } from "../../icons";
import { WidgetCustomTooltip } from "../tooltip";
import { useTranslation } from "react-i18next";

type Props = {
  data: CardType;
  widgetKey: WidgetKeys;
};

const Card: React.FC<Props> = ({ data, widgetKey }) => {
  const { labels } = widgetStaticData[widgetKey];
  const { t } = useTranslation();
  const {
    difference_percentage: percent,
    total,
    total_expense,
    total_income,
    currency,
    max_date,
    min_date,
    total_min_date
  } = data;

  const percentIcon = () => {
    if (percent && percent > 0) return <ArrowUpIcon isRotate width={14} />;
    if (percent && percent < 0) return <ArrowUpIcon width={14} />;

    return null;
  };

  const customTooltip = () => (
    <div className="flex flex-col gap-2">
      <div
        className={cx("flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid pl-2", {
          "border-l-success-500": percent && percent > 0,
          "border-l-error-500": percent && percent < 0,
          "border-l-white": percent === 0
        })}
      >
        <span className="text-xs font-medium text-white">
          {dayjs(max_date, dayjsFormats.DATE).format(dayjsFormats.DATE_WITH_SHORT_MONTH_NAME)}
        </span>
        <span className="text-sm font-semibold text-white">
          {total.toLocaleString(RU)} {currency?.symbol}
        </span>
        {(percent || percent === 0) && (
          <div
            className={cx(
              "flex w-max items-center gap-1 rounded-2xl border-white bg-white px-2 py-1 text-sm font-medium",
              {
                "text-success-500": percent && percent > 0,
                "text-error-500": percent && percent < 0,
                "text-gray-700": percent === 0
              }
            )}
          >
            {percentIcon()}
            <span>{percent}%</span>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <span className="text-[0.625rem] font-medium leading-[1.125rem] text-gray-300">
          {t("dashboard.Solishtirildi")}
        </span>
        <div className={cx("flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid pl-2")}>
          <span className="text-xs font-medium text-gray-300">
            {dayjs(min_date, dayjsFormats.DATE).format(dayjsFormats.DATE_WITH_SHORT_MONTH_NAME)}
          </span>
          <span className="text-sm font-semibold text-gray-300">
            {total_min_date?.toLocaleString(RU)} {currency?.symbol}
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-3">
      <div className="flex cursor-pointer flex-col gap-3 rounded-xl border border-solid border-transparent bg-gray-50 px-4 py-3">
        <div className="flex items-center gap-2">
          <h5 className="m-0 text-base font-medium text-gray-700">{t(`dashboard.${labels[0]}`)}</h5>
          <WidgetCustomTooltip isToolTip content={customTooltip()}>
            {(percent || percent === 0) && (
              <div className="flex items-center gap-2">
                <div
                  className={cx("flex items-center justify-center gap-1 rounded-2xl px-2 py-1 text-sm font-medium", {
                    "bg-success-50 text-success-500": percent && percent > 0,
                    "bg-error-50 text-error-500": percent && percent < 0,
                    "bg-gray-200 text-gray-700": percent === 0
                  })}
                >
                  {percentIcon()}
                  <span>{percent}%</span>
                </div>
                <span className="text-sm font-normal text-gray-400">{t(`dashboard.${labels[3]}`)}</span>
              </div>
            )}
          </WidgetCustomTooltip>
        </div>
        <h2 className="m-0 flex items-end gap-1 text-4xl font-medium text-gray-700">
          <TooltipShortName title={total?.toLocaleString(RU)} length={40} />
          <span className="text-xl font-medium text-gray-400">{currency?.symbol}</span>
        </h2>
      </div>
      <div className="flex gap-3">
        <div className="box-border flex w-1/2 gap-2.5 rounded-xl bg-success-50 px-4 py-3">
          <div className="h-full w-1.5 rounded-md bg-success-500" />
          <div className="flex flex-col gap-3">
            <h5 className="m-0 text-base font-medium text-gray-700">{t(`dashboard.${labels[1]}`)}</h5>
            <h2 className="m-0 flex items-end gap-1 text-xl font-medium text-success-500">
              <TooltipShortName title={total_income?.toLocaleString(RU)} length={40} />
              <span className="mb-[0.15rem] text-sm font-medium text-gray-400">{currency?.symbol}</span>
            </h2>
          </div>
        </div>
        <div className="box-border flex w-1/2 gap-2.5 rounded-xl bg-error-50 px-4 py-3">
          <div className="h-full w-1.5 rounded-md bg-error-500" />
          <div className="flex flex-col gap-3">
            <h5 className="m-0 text-base font-medium text-gray-700">{t(`dashboard.${labels[2]}`)}</h5>
            <h2 className="m-0 flex items-end gap-1 text-xl font-medium text-error-500">
              <TooltipShortName title={Math.abs(total_expense)?.toLocaleString(RU)} length={40} />
              <span className="mb-[0.15rem] text-sm font-medium text-gray-400">{currency?.symbol}</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
