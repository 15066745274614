import React, { useState } from "react";
import { UseMutationResult } from "@tanstack/react-query";
import { Button, DatePicker, Form, Modal } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import { dayjsFormats, ErrorRes, SuccessRes } from "modules/common";

import { CalendarPlusIcon } from "../icons";

import styles from "./index.module.scss";

type Props = {
  title?: string;
  isNotLabel?: boolean;
  label?: string;
  placeholder?: [string, string];
  okText?: string;
  cancelText?: string;
  children?: React.ReactNode;
  visible?: boolean;
  getPopupContainer?: () => HTMLElement;
  mutation?: UseMutationResult<any, ErrorRes, any>;
  setVisible?: (visible: boolean) => void;
  onAfterClose?: () => void;
  payload?: { [key: string]: string | number | undefined };
};

const DownloadReportModal: React.FC<Props> = ({
  mutation,
  title,
  isNotLabel,
  label,
  okText,
  cancelText,
  placeholder,
  children,
  getPopupContainer,
  visible = false,
  setVisible,
  onAfterClose,
  payload
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [open, setOpen] = useState<boolean>(visible);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onOpening = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    setOpen(true);
    e.preventDefault();
    e.stopPropagation();
  };

  const onSubmit = (fields: { dates: [Dayjs | null | undefined, Dayjs | null | undefined] }) => {
    const { dates } = fields!;

    setIsLoading(true);

    const min_date = dates && dates[0] ? dayjs(dates[0], dayjsFormats.DATE).format(dayjsFormats.DATE) : null;
    const max_date = dates && dates[1] ? dayjs(dates[1], dayjsFormats.DATE).format(dayjsFormats.DATE) : null;

    if (payload && Object.keys(payload)?.length > 0) {
      mutation &&
        mutation.mutateAsync({ ...payload, min_date, max_date }).finally(() => {
          setOpen(false);
          setIsLoading(false);

          onAfterClose && onAfterClose();
        });
    } else {
      mutation &&
        mutation.mutateAsync({ min_date, max_date }).finally(() => {
          setOpen(false);
          setIsLoading(false);

          onAfterClose && onAfterClose();
        });
    }
  };

  const getFormItemLabel = () => {
    if (isNotLabel) return "";
    if (label) return label;
    return t("downloadReportModal.Muddat");
  };

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <>
      {children && <div onClick={onOpening}>{children}</div>}
      <Modal
        open={open}
        title={title || t("downloadReportModal.Hisobot yuklab olish")}
        className={styles.download_report_modal}
        getContainer={getPopupContainer}
        footer={null}
        afterOpenChange={open => {
          form.resetFields();
          setVisible && setVisible(open);
        }}
        onCancel={onCancel}
      >
        <Form form={form} className={styles.download_report_modal__form} onFinish={onSubmit} layout="vertical">
          <Form.Item name="dates" label={getFormItemLabel()}>
            <DatePicker.RangePicker
              suffixIcon={<CalendarPlusIcon />}
              placeholder={
                placeholder || [t("downloadReportModal.Boshlanish sanasi"), t("downloadReportModal.Tugash sanasi")]
              }
            />
          </Form.Item>
          <div className="mt-8 flex items-center justify-end gap-3">
            <Button onClick={onCancel}>{cancelText || t("downloadReportModal.Yopish")}</Button>
            <Button loading={isLoading} type="primary" htmlType="submit">
              {okText || t("downloadReportModal.Tasdiqlash")}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default DownloadReportModal;
