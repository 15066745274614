import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Dropdown, Form, Modal, Spin, Switch } from "antd";
import { ItemType } from "antd/lib/menu/interface";
import classNames from "classnames";
import dayjs from "dayjs";
import OrderedIcon from "features/app/assets/icons/OrderedIcon";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import { PageKeys } from "features/app/components/statuses/statusEnums";
import { LocalStorage } from "features/app/service/LocalStorage";
import { useGetCustomFieldSelect, useGetUnitSelect } from "features/app/service/queries";
import { colors } from "features/app/utils/constants/colors";
import { dayjsFormats } from "features/app/utils/constants/dayjsFormats";
import { ChatEnum } from "features/app/utils/enums/chatEnum";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { isEmptyArr } from "features/app/utils/helpers/isEmptyArr";
import { localeFormatter } from "features/app/utils/helpers/localeFormatter";
import { parseLocaledString } from "features/app/utils/helpers/parseLocaledString";
import { SubmoduleKey } from "features/app/utils/models/permissionModel";
import { ProductSelectModel } from "features/app/utils/models/productSelectModel";
import { useGetCompanyPersonSelect } from "features/counterparts/service/queries";
import { paymentQueryKeys } from "features/payment/utils/constants/paymentQueryKeys";
import CornerUpLeftIcon from "features/projects/assets/icons/CornerUpLeftIcon";
import { useCreateOrder, useUpdateOrder } from "features/warehouse/service/mutation";
import { useGetWarehouseSelect } from "features/warehouse/service/query";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { rootPaths } from "routes/root/rootPaths";
import { uid } from "uid";

import { useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";

import { chatActions } from "store/reducers/chatReducer";
import { supplyOfferActions } from "store/reducers/supplyOfferReducer";
import { supplyActions } from "store/reducers/supplyReducer";

import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import EditIcon from "../../../../app/assets/icons/EditIcon";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import ModalConfirm from "../../../../app/components/modal-confirm/ModalConfirm";
import ProductMarketPriceModal from "../../../../app/components/product-market-price-modal/ProductMarketPriceModal";
import Statuses from "../../../../app/components/statuses/Statuses";
import UnreadMessageAction from "../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import PrinterIcon from "../../../../counterparts/assets/icons/PrinterIcon";
import FileQuestionIcon from "../../../../payment/assets/icons/FileQuestionIcon";
import XCloseIcon from "../../../../warehouse/assets/icons/XCloseIcon";
import ResourceInfoDrawer from "../../../../warehouse/components/warehouse/resource-info-drawer/ResourceInfoDrawer";
import CalculateIcon from "../../../assets/icons/CalculateIcon";
import GitBranchIcon from "../../../assets/icons/GitBranchIcon";
import {
  useCreateParty,
  useDeleteOrders,
  useDeletePart,
  useGetWarehouseProjects,
  useRefundPart,
  useUpdateParty
} from "../../../service/mutations";
import { useGetSupplyOrder } from "../../../service/queries";
import { supplyQueryNames } from "../../../utils/constants/supplyQueryNames";
import { StatusEnums } from "../../../utils/enums/statusEnums";
import { generateCustomFieldData, passedCustomFieldData } from "../../../utils/helpers/generateCustomFieldData";
import {
  formationSelectProducts,
  formationWarehouseProducts,
  formationWarehouseProductsDrawer,
  generatePersonGroupData,
  generateReqPersonGroupProducts,
  importEstimatePersonProductData,
  importEstimateProductData,
  importPersonProductData,
  importProductData,
  passedReqData
} from "../../../utils/helpers/generateProductsData";
import { PartyFormModel, PartyFormOfferModel, PartyFormProductsModel } from "../../../utils/models/partyModalFormModel";
import { PartyReqModel } from "../../../utils/models/partyReqModel";
import PartyEstimate from "../party-estimate/PartyEstimate";
import PartyProcess from "../party-process/party-process";
import ProductDrawer from "../product-drawer/ProductDrawer";

import ConfirmModal from "./confirm-modal/ConfirmModal";
import PartyModalLeft from "./left/PartyModalLeft";
import PartyPrint from "./party-print/PartyPrint";
import ResourceAvailabilityModal from "./resource-availability/ResourceAvailabilityModal";
import PartyModalRight from "./right/PartyModalRight";

import styles from "./partyModal.module.scss";

type Props = {
  isPayment?: boolean;
  isWarehouse?: boolean;
  customFooter?: ReactNode;
  actions?: (key: SubmoduleKey) => { [key: string]: boolean };
};

const { useWatch, useForm } = Form;

const PartyModal: React.FC<Props> = ({ isWarehouse, customFooter, isPayment, actions }) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(true);
  const qc = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { setVisible } = chatActions;
  const deleteProduct = useDeleteOrders();
  const { searchParams } = useQueryParams();
  const { setOfferModal } = supplyOfferActions;
  const { setPartyModal, setOrderTableView, setProductDrawerData, setSelectOrderProducts, setIsPartyProcess } =
    supplyActions;
  const { visible, party_id, isView, isEditing, selectProducts, isPartyProcess, zIndex } = useAppSelector(
    state => state.supplyReducer.partyModal
  );

  const { data } = useAppSelector(state => state.warehouseOrderReducer.view);

  const partyProcess = LocalStorage.get("is_party_process");

  const status = useWatch("status", form);
  const personGroup = useWatch("person_group", form);
  // const groupProducts = useWatch("group_products", form);
  const isGroupPerson = useWatch("is_group_person", form);
  const warehouseProducts = useWatch("warehouse_products", form);
  const printComponentRef = useRef<HTMLDivElement | null>(null);

  const { data: units } = useGetUnitSelect();
  const getProjects = useGetWarehouseProjects();
  const partyActions = actions ? actions("Partiyalar") : {};
  const { data: companyPersons } = useGetCompanyPersonSelect();
  const { data: warehouses } = useGetWarehouseSelect(true, visible);
  const { data: oneOrder, isLoading: isLoadingOrder } = useGetSupplyOrder(party_id);
  const { products } = useAppSelector(state => state.supplyReducer.productDrawerData);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.ORDER]);
  const { products: estimateProducts } = useAppSelector(state => state.supplyReducer.estimateData);

  const deleteParty = useDeletePart();
  const refundParty = useRefundPart();
  const createParty = useCreateParty();
  const updateParty = useUpdateParty();
  const createWarehouseParty = useCreateOrder();
  const updateWarehouseParty = useUpdateOrder();

  const emptyFunc = () => {};

  const reactToPrintContent = useCallback(() => printComponentRef.current, [printComponentRef]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `P-${party_id}`,
    onBeforePrint: emptyFunc,
    onAfterPrint: emptyFunc,
    removeAfterPrint: true
  });

  useEffect(() => {
    if (!isEmptyArr(selectProducts)) {
      form.setFieldsValue({
        warehouse_products: formationWarehouseProductsDrawer(selectProducts),
        select_products: formationSelectProducts(selectProducts),
        is_table: !isEmptyArr(selectProducts)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectProducts?.length, form]);

  useEffect(() => {
    if (party_id) {
      const generateProductData = formationWarehouseProducts(oneOrder?.warehouse_products);

      const personGroupData = generatePersonGroupData(generateProductData?.products, companyPersons);

      form.setFieldsValue({
        is_group_person: true,
        status: oneOrder?.status,
        agent_id: oneOrder?.agent?.id,
        offers: generateProductData.offers,
        select_products: formationSelectProducts(oneOrder?.warehouse_products),
        payment_date: oneOrder?.payment_date ? dayjs(oneOrder?.payment_date, dayjsFormats.DATE) : null,
        delivery_date: oneOrder?.delivery_date ? dayjs(oneOrder?.delivery_date, dayjsFormats.DATE) : null
      });
      passedCustomFieldData(form, oneOrder?.custom_field_values, customFields);
      setLoading(true);
      setTimeout(() => {
        form.setFieldsValue({
          person_group: personGroupData,
          person_keys: personGroupData?.map(item => String(item?.id))
        });
        setLoading(false);
      }, 200);
    }
  }, [oneOrder, party_id, form, companyPersons, customFields]);

  const onOpenAfter = (open: boolean) => {
    if (!open) {
      form.resetFields();
      dispatch(setOrderTableView(false));

      // severalRemove(queryParamsKeys.PROJECT_IDS_ARR, queryParamsKeys.WAREHOUSE_IDS_ARR);

      dispatch(
        setProductDrawerData({
          ids: [],
          products: []
        })
      );
    }
  };

  const onCancel = () => {
    dispatch(
      setPartyModal({
        visible: false,
        updateProduct: {
          condition: false
        }
      })
    );

    form.setFieldValue("status", StatusEnums.ORDERED);
  };

  const onOk = () => {
    form.setFieldValue("status", StatusEnums.ORDERED);

    form.submit();
  };

  const onAfterFinish = () => {
    if (isPayment) {
      qc.invalidateQueries([paymentQueryKeys.PAYMENTS_ORDER_VIEW, searchParams]).then();
    }

    onCancel();
    dispatch(setSelectOrderProducts([]));
  };

  const onFinish = (fields: PartyFormModel) => {
    const reqPassedProducts = fields?.search ? fields?.cache_products : fields?.warehouse_products;

    const fieldWarehouseProducts = passedReqData(reqPassedProducts);
    // const fieldGroupProducts = passedReqData(generateReqGroupProducts(fields));
    const fieldPersonGroupProducts = passedReqData(generateReqPersonGroupProducts(fields?.person_group));

    const partStatus = () => {
      if (oneOrder) {
        if (oneOrder.status === StatusEnums.OPEN || status === StatusEnums.OPEN) {
          return status;
        }

        return oneOrder?.status;
      }

      return status;
    };

    const reqData: PartyReqModel = {
      id: party_id,
      status: partStatus(),
      agent_id: fields.agent_id,
      delivery_date: fields.delivery_date ? dayjs(fields.delivery_date).format(dayjsFormats.DATE) : undefined,
      payment_date: fields.payment_date ? dayjs(fields.payment_date).format(dayjsFormats.DATE) : undefined,
      warehouse_products: [
        // ...(fieldGroupProducts ?? []),
        ...(fieldWarehouseProducts ?? []),
        ...(fieldPersonGroupProducts ?? [])
      ],
      custom_field_values: generateCustomFieldData(fields?.custom_field_values, customFields)
    };

    if (isWarehouse) {
      if (party_id) {
        updateWarehouseParty.mutateAsync(reqData).then(onAfterFinish);
      } else {
        // @ts-ignore
        delete reqData.status;
        createWarehouseParty.mutateAsync(reqData).then(onAfterFinish);
      }
    } else if (party_id) {
      updateParty.mutateAsync(reqData).then(onAfterFinish);
    } else {
      createParty.mutateAsync(reqData).then(onAfterFinish);
    }
  };

  const onOpenChat = () => {
    dispatch(
      setVisible({
        visible: true,
        type: ChatEnum.ORDERS,
        objectId: party_id,
        dataKeys: [supplyQueryNames.ORDER_DETAIL, supplyQueryNames.ORDERS]
      })
    );
  };

  const onDeleteParty = () => deleteParty.mutateAsync(party_id!).then(() => onCancel());
  const onRefundParty = () => refundParty.mutateAsync(party_id!).then(() => onCancel());

  const onIsEditing = () => {
    dispatch(
      setPartyModal({
        visible: true,
        party_id,
        isEditing: true,
        isView: false
      })
    );
  };

  // const onGrouping = () => {
  //   const allWarehouseProducts = form.getFieldValue("warehouse_products") as PartyFormProductsModel[];
  //   const oldGroupProducts = form.getFieldValue("group_products") as PartyGroupProductsModel[];
  //
  //   const filteredArray = filterDuplicateProducts(allWarehouseProducts);
  //
  //   form.setFieldsValue({
  //     group_products: [...generateGroupProducts(filteredArray, allWarehouseProducts), ...(oldGroupProducts ?? [])],
  //     warehouse_products: generateWarehouseProducts(filteredArray, allWarehouseProducts)
  //   });
  // };

  // const onUnGrouping = () => {
  //   const oldGroupProducts = form.getFieldValue("group_products");
  //   const oldWarehouseProducts = form.getFieldValue("warehouse_products");
  //
  //   form.setFieldsValue({
  //     warehouse_products: [...(oldWarehouseProducts ?? []), ...unGroupProducts(oldGroupProducts)],
  //     group_products: []
  //   });
  // };

  const onCreateOffer = () => {
    const passedProducts = [
      ...(warehouseProducts || []),
      ...generateReqPersonGroupProducts(personGroup)
      // ...generateReqGroupProducts(groupProducts)
    ];

    const selectProducts = form.getFieldValue("select_products") as ProductSelectModel[];
    const deliveryDate = form.getFieldValue("delivery_date");
    const offers = form.getFieldValue("offers") as PartyFormOfferModel[];

    const findProduct = (productId: number) =>
      selectProducts.find(product => product.id === productId) as ProductSelectModel;

    const findUnit = (unitId: number) => units?.find(unit => unit?.id === unitId);

    dispatch(
      setOfferModal({
        isEdit: true,
        visible: true,
        viewPartySwitch: false,
        // @ts-ignore
        data: passedProducts?.map(item => {
          const returnProduct = findProduct(item?.product_id);
          const filterOffers = offers?.filter(i => i.product_row_id === item?.row_id);

          return {
            amount: parseLocaledString(item?.amount),
            quantity: parseLocaledString(item?.quantity),
            delivery_date: dayjs(deliveryDate, dayjsFormats.DATE).format(dayjsFormats.DATE),
            product: {
              id: returnProduct.id!,
              name: returnProduct?.name,
              resource: returnProduct?.resource
            },
            id: item.id!,
            unit: findUnit(item?.unit_id),
            offers: filterOffers?.map((offer, index) => ({
              id: offer?.id,
              uniqueId: uid(12),
              phone: offer?.phone,
              ordinalNumber: index + 1,
              description: offer?.description,
              currency_id: offer?.currency?.id,
              company_person_id: offer?.company_person?.id,
              amount: localeFormatter(String(offer?.amount)),
              name: offer.company_person ? offer.company_person?.name : offer.name
            }))
          };
        })
      })
    );
  };

  const onSwitchChange = (value: boolean) => {
    dispatch(setIsPartyProcess({ isPartyProcess: value }));
    if (value) {
      LocalStorage.set("is_party_process", value);
    } else {
      LocalStorage.remove("is_party_process");
    }
  };

  const dropdownItems = (): ItemType[] => {
    // const isGroup = isEmptyArr(groupProducts?.[0]?.children);

    const allItems: {
      key: string;
      label: ReactNode;
      isView?: boolean;
      onClick?: () => void;
      permissionKey?: string;
    }[] = [
      {
        key: "1",
        label: (
          <div className={styles.dropdown_item}>
            <GitBranchIcon />
            {/* {isGroup ? t("partyModal.Guruhlash") : t("partyModal.Guruhdan chiqarish")} */}
          </div>
        ),
        // vaqtinchalikka o'chirildi sababi party modalni qottirib qo'yishi mumkin deb o'yladim
        // onClick: isGroup ? onGrouping : onUnGrouping,
        isView: false
      },
      {
        key: "2",
        label: (
          <div className={styles.dropdown_item}>
            <PrinterIcon /> {t("partyModal.Chop etish")}
          </div>
        ),
        onClick: handlePrint,
        isView: !!party_id && !isView
      },
      {
        key: "3",
        label: (
          <div className={styles.dropdown_item}>
            <UnreadMessageAction count={oneOrder?.unread_message_count} />
          </div>
        ),
        onClick: onOpenChat,
        isView: !!party_id
      },
      {
        key: "6",
        label: (
          <div className={styles.dropdown_item}>
            <EditIcon /> {t("partyModal.Tahrirlash")}
          </div>
        ),
        onClick: onIsEditing,
        permissionKey: "edit",
        isView:
          (oneOrder?.status === StatusEnums.OPEN ||
            oneOrder?.status === StatusEnums.ORDERED ||
            oneOrder?.status === StatusEnums.PENDING ||
            oneOrder?.status === StatusEnums.PARTIALLY_RECIEVED) &&
          !isView
      },
      {
        key: "7",
        label: (
          <div className={styles.dropdown_item}>
            <CalculateIcon /> {t("partyModal.Taklif berish")}
          </div>
        ),
        onClick: onCreateOffer,
        isView:
          !(oneOrder?.status === StatusEnums.RECIEVED || oneOrder?.status === StatusEnums.REJECTED) &&
          !isView &&
          !customFooter
      },
      {
        key: "8",
        label: <ResourceAvailabilityModal oneOrder={oneOrder} />,
        isView: pathname.includes(rootPaths.IMPOST) && !isView
      },
      {
        key: "9",
        label: (
          <ModalConfirm title="Ushbu partiyani o'chirmoqchimisiz ?" onOk={onDeleteParty} onAfterClose={onCancel}>
            <div className={styles.dropdown_item}>
              <DeleteIcon /> {t("partyModal.O'chirish")}
            </div>
          </ModalConfirm>
        ),
        onClick: () => {},
        permissionKey: "delete",
        isView: Boolean(oneOrder?.id) && !isView
      },
      {
        key: "10",
        label: (
          <div className={styles.dropdown_item} onClick={onRefundParty}>
            <CornerUpLeftIcon />
            {t("partyModal. Partiyani ochish")}
          </div>
        ),
        permissionKey: "open",
        isView: oneOrder?.status === StatusEnums.RECIEVED
      },
      {
        key: "11",
        label: (
          <div className={styles.dropdown_item}>
            <OrderedIcon stroke={colors.GRAY_600} size={20} /> {t("partyModal.Partiya jarayoni")}{" "}
            <Switch value={isPartyProcess} defaultValue={Boolean(partyProcess)} onChange={onSwitchChange} />
          </div>
        ),
        isView: true
      }
    ];

    return allItems?.filter(item => {
      if (item.permissionKey) {
        return partyActions[item.permissionKey] && item.isView;
      }

      return item.isView;
    });
  };

  const buttonActiveClass = (initialClass?: string) => {
    // past dagi shart da isEmptyArr(groupProducts) bu ham bor edi lekin hozircha kerak emas
    const isEmptyData = isEmptyArr(warehouseProducts) && isEmptyArr(personGroup);

    return classNames(initialClass && styles[initialClass], {
      [styles.button__disabled]: isEmptyData,
      [styles.button__active]: !isEmptyData
    });
  };

  const title = (
    <div className={styles.title}>
      <div className={styles.title__left}>
        {party_id ? <h2>P - {party_id}</h2> : <h4>{t("partyModal.Partiya yaratish")}</h4>}
        {party_id && isPartyProcess && (
          <PartyProcess
            monitoring_status={(oneOrder || data)?.confirmation_payment as unknown as StatusEnums}
            payment_status={(oneOrder || data)?.payment_percent as number}
            supply_status={(oneOrder || data)?.status as unknown as StatusEnums}
            warehouse_status={(oneOrder || data)?.percent as number}
          />
        )}
        {party_id && !isPartyProcess && oneOrder && <Statuses pageKey={PageKeys.PARTY} statusKey={oneOrder?.status} />}
      </div>
      <div className={styles.title__right}>
        <ConditionalRender if={!isEmptyArr(dropdownItems())}>
          <span className={buttonActiveClass()}>
            <Dropdown trigger={["click"]} placement="bottom" menu={{ items: dropdownItems() }}>
              <Button>
                <UnreadMessageDots count={oneOrder?.unread_message_count} />
              </Button>
            </Dropdown>
          </span>
        </ConditionalRender>
        <span onClick={onCancel}>
          <XCloseIcon />
        </span>
      </div>
    </div>
  );

  const onClickDraft = () => {
    form.setFieldValue("status", StatusEnums.OPEN);

    form.submit();
  };

  const defaultFooterText = {
    [StatusEnums.PENDING]: isWarehouse ? t("partyModal.Saqlash") : t("partyModal.Yakunlash"),
    [StatusEnums.OPEN]: t("partyModal.Partiya qilish")
  };

  const isRecievedOrRejected =
    oneOrder?.status === StatusEnums.REJECTED ||
    oneOrder?.status === StatusEnums.RECIEVED ||
    oneOrder?.status === StatusEnums.OPEN;

  const defaultFooterRight = (
    <>
      {!isView && (
        <Button
          type="primary"
          onClick={onOk}
          className={buttonActiveClass()}
          loading={
            status === StatusEnums.ORDERED &&
            (createParty.isLoading ||
              updateParty.isLoading ||
              createWarehouseParty.isLoading ||
              updateWarehouseParty.isLoading)
          }
        >
          {defaultFooterText[oneOrder?.status as keyof typeof defaultFooterText]
            ? t(`partyModal.${defaultFooterText[oneOrder?.status as keyof typeof defaultFooterText]}`)
            : t("partyModal.Saqlash")}
        </Button>
      )}
      {party_id && isView && (
        <>
          {!isRecievedOrRejected && partyActions?.rejected && (
            <ConfirmModal id={party_id} title="rejected">
              <Button className={styles.footer__reject}>{t("partyModal.Bekor qilish")}</Button>
            </ConfirmModal>
          )}
          {!isRecievedOrRejected && partyActions?.completed && (
            <ConfirmModal id={party_id} oneOrder={oneOrder} title="recieved">
              <Button type="primary">{t("partyModal.Yakunlash")}</Button>
            </ConfirmModal>
          )}
        </>
      )}
    </>
  );

  const footer = (
    <div className={styles.footer}>
      {isView || isWarehouse ? (
        <div />
      ) : (
        <div>
          <Button
            loading={status === StatusEnums.OPEN && (createParty.isLoading || updateParty.isLoading)}
            onClick={onClickDraft}
            value={StatusEnums.OPEN}
            className={buttonActiveClass("footer__left")}
          >
            <FileQuestionIcon />
            {t("partyModal.Qoralama")}
          </Button>
        </div>
      )}
      <div className={styles.footer__right}>
        <Button onClick={onCancel}>{t("partyModal.Yopish")}</Button>
        {isEditing ? defaultFooterRight : customFooter || defaultFooterRight}
      </div>
    </div>
  );

  const onImportProduct = () => {
    const oldProducts: PartyFormProductsModel[] = form.getFieldValue("warehouse_products") ?? [];
    const oldSelectProducts = form.getFieldValue("select_products") ?? [];
    const oldPersonGroup = form.getFieldValue("person_group") ?? [];

    if (isGroupPerson) {
      form.setFieldsValue(importPersonProductData(oldProducts, oldSelectProducts, products, oldPersonGroup));
    } else {
      form.setFieldsValue(importProductData(oldProducts, oldSelectProducts, products));
    }

    form.setFieldValue("is_table", true);
  };

  const onImportEstimateProduct = () =>
    new Promise(resolve => {
      const oldProducts = form.getFieldValue("warehouse_products") ?? [];
      const oldSelectProducts = form.getFieldValue("select_products") ?? [];
      const oldPersonGroup = form.getFieldValue("person_group") ?? [];

      getProjects.mutateAsync(warehouses?.[0]?.id).then(({ data }) => {
        if (isGroupPerson) {
          form.setFieldsValue(
            importEstimatePersonProductData(
              oldProducts,
              oldSelectProducts,
              estimateProducts,
              warehouses,
              data,
              oldPersonGroup
            )
          );
        } else {
          form.setFieldsValue(
            importEstimateProductData(oldProducts, oldSelectProducts, estimateProducts, warehouses, data)
          );
        }

        form.setFieldValue("is_table", true);

        resolve(true);
      });
    });

  const onClearProduct = (isTaskProduct: boolean) => () => {
    const oldProducts = form.getFieldValue("warehouse_products") ?? [];

    form.setFieldsValue({
      warehouse_products: oldProducts?.filter((item: PartyFormProductsModel) =>
        isTaskProduct ? !item.task_product_id : item.task_product_id
      )
    });
  };

  useEffect(() => {
    dispatch(setIsPartyProcess({ isPartyProcess: Boolean(partyProcess) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyProcess]);

  return (
    <Modal
      centered
      onOk={onOk}
      title={title}
      open={visible}
      footer={footer}
      okText={t("partyModal.Saqlash")}
      zIndex={zIndex}
      closeIcon={null}
      cancelText={t("partyModal.Yopish")}
      afterOpenChange={onOpenAfter}
      className={styles.party_modal}
    >
      <Spin
        spinning={(isLoadingOrder || deleteProduct.isLoading || loading) && !!party_id}
        indicator={LoadingIndicator}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className={styles.party_modal__content}>
            <PartyModalLeft form={form} isWarehouse={isWarehouse} deleteProduct={deleteProduct} />
            <PartyModalRight form={form} />
          </div>
          <PartyEstimate
            importAction={emptyFunc}
            isLoading={getProjects.isLoading}
            clearAction={onClearProduct(true)}
            importAsyncAction={onImportEstimateProduct}
          />
          <ProductDrawer importAction={onImportProduct} clearAction={onClearProduct(false)} />
        </Form>
      </Spin>
      <PartyPrint form={form} partyId={party_id} oneOrder={oneOrder} ref={printComponentRef} />
      <ResourceInfoDrawer />
      <ProductMarketPriceModal />
    </Modal>
  );
};

export default PartyModal;
