import React from "react";

import { useAppSelector } from "hooks/redux";

import { isEmptyArr } from "modules/common";

import Card from "./card";
import Empty from "./empty";

const Warehouses: React.FC = () => {
  const { warehouses } = useAppSelector(({ warehouseCreateTransfer }) => warehouseCreateTransfer);

  if (isEmptyArr(warehouses)) {
    return (
      <div className="flex h-full flex-1 items-center justify-center">
        <Empty />
      </div>
    );
  }

  return (
    <div className="flex h-full w-max flex-nowrap gap-4">
      {warehouses?.map((item, index) => <Card key={item?.recieved_warehouse_id} {...item} index={index} />)}
    </div>
  );
};

export default Warehouses;
