import React from "react";
import { useTranslation } from "react-i18next";

import { cashProcess, ConfirmationPaymentStatus, PartyStatus, processData, warehouseProcess } from "modules/party/view";

import styles from "./index.module.scss";

type Props = {
  impostStatus: ConfirmationPaymentStatus;
  supplyStatus: PartyStatus;
  warehouseStatus: number;
  paymentStatus: number;
};

const Index: React.FC<Props> = ({ warehouseStatus, paymentStatus, supplyStatus, impostStatus }) => {
  const { t } = useTranslation();
  const warehouse = warehouseProcess(warehouseStatus,t);
  const cash = cashProcess(paymentStatus,t);

  return (
    <div className="flex items-center justify-center">
      <div
        className={styles.card}
        style={{
          borderColor: processData?.supply?.[supplyStatus]?.bgColor,
          backgroundColor: processData?.supply?.[supplyStatus]?.bgColor
        }}
      >
        <div
          className={styles.icon_container}
          style={{
            borderColor: processData?.supply?.[supplyStatus]?.iconContainerBgColor,
            backgroundColor: processData?.supply?.[supplyStatus]?.iconContainerBgColor
          }}
        >
          {processData?.supply?.[supplyStatus]?.icon || processData?.supply?.[supplyStatus]?.processNumber}
        </div>
        <span className={styles.title}>{t("partyProcess.Ta'minot")}</span>
        <span
          className={styles.status_container}
          style={{
            color: processData?.supply?.[supplyStatus]?.iconContainerBgColor
          }}
        >
          {t(`partyProcess.${processData?.supply?.[supplyStatus]?.text}`)}
        </span>
      </div>
      <span
        className={styles.line}
        style={{
          borderColor: processData?.supply?.[supplyStatus]?.iconContainerBgColor,
          backgroundColor: processData?.supply?.[supplyStatus]?.iconContainerBgColor
        }}
      />
      <div
        className={styles.card}
        style={{
          borderColor: processData.impost[impostStatus]?.bgColor,
          backgroundColor: processData.impost[impostStatus]?.bgColor
        }}
      >
        <div
          className={styles.icon_container}
          style={{
            borderColor: processData.impost[impostStatus]?.iconContainerBgColor,
            backgroundColor: processData.impost[impostStatus]?.iconContainerBgColor
          }}
        >
          {processData.impost[impostStatus]?.icon || processData.impost[impostStatus]?.processNumber}
        </div>
        <span className={styles.title}>{t("partyProcess.Monitoring")}</span>
        <span
          className={styles.status_container}
          style={{
            color: processData.impost[impostStatus]?.iconContainerBgColor
          }}
        >
          {t(`partyProcess.${processData.impost[impostStatus]?.text}`)}
        </span>
      </div>
      <span
        className={styles.line}
        style={{
          borderColor: processData.impost[impostStatus]?.iconContainerBgColor,
          backgroundColor: processData.impost[impostStatus]?.iconContainerBgColor
        }}
      />
      <div
        className={styles.card}
        style={{
          borderColor: warehouse.borderColor,
          backgroundColor: warehouse.backgroundColor
        }}
      >
        <div
          className={styles.icon_container}
          style={{
            borderColor: warehouse.textAndIconColor,
            backgroundColor: warehouse.textAndIconColor
          }}
        >
          {warehouse.icon || warehouse.processNumber}
        </div>
        <span className={styles.title}>{t("partyProcess.Omborxona")}</span>
        <span
          className={styles.status_container}
          style={{
            color: warehouse.textAndIconColor
          }}
        >
          {warehouse.text}
        </span>
      </div>
      <span
        className={styles.line}
        style={{
          borderColor: warehouse.textAndIconColor,
          backgroundColor: warehouse.textAndIconColor
        }}
      />
      <div
        className={styles.card}
        style={{
          borderColor: cash.borderColor,
          backgroundColor: cash.backgroundColor
        }}
      >
        <div
          className={styles.icon_container}
          style={{
            borderColor: cash.textAndIconColor,
            backgroundColor: cash.textAndIconColor
          }}
        >
          {cash.icon || cash.processNumber}
        </div>
        <span className={styles.title}>{t("partyProcess.Kassa")}</span>
        <span
          className={styles.status_container}
          style={{
            color: cash.textAndIconColor
          }}
        >
          {cash.text}
        </span>
      </div>
      <span
        className={styles.line}
        style={{
          borderColor: cash.textAndIconColor,
          backgroundColor: cash.textAndIconColor
        }}
      />
      <div
        className={styles.card}
        style={{
          borderColor: processData?.last_supply?.[supplyStatus]?.bgColor,
          backgroundColor: processData?.last_supply?.[supplyStatus]?.bgColor
        }}
      >
        <div
          className={styles.icon_container}
          style={{
            borderColor: processData?.last_supply?.[supplyStatus]?.iconContainerBgColor,
            backgroundColor: processData?.last_supply?.[supplyStatus]?.iconContainerBgColor
          }}
        >
          {processData?.last_supply?.[supplyStatus]?.icon || processData?.last_supply?.[supplyStatus]?.processNumber}
        </div>
        <span className={styles.title}>{t("partyProcess.Ta'minot")}</span>
        <span
          className={styles.status_container}
          style={{
            color: processData?.last_supply?.[supplyStatus]?.iconContainerBgColor
          }}
        >
          {t(`partyProcess.${processData?.last_supply?.[supplyStatus]?.text}`)}
        </span>
      </div>
    </div>
  );
};

export default Index;
