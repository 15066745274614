import React from "react";

const ArrowUpIcon: React.FC<{ stroke?: string; rotate?: number }> = ({ stroke = "#12B76A", rotate = 0 }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: `rotate(${rotate}deg)`
    }}
  >
    <path
      d="M6 2.5L6 9.5M6 9.5L9.5 6M6 9.5L2.5 6"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowUpIcon;
