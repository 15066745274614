import React from "react";

const UyqurRectangleIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 5.45455C0 2.44208 2.38781 0 5.33333 0H18.6667C21.6122 0 24 2.44208 24 5.45455V18.5455C24 21.5579 21.6122 24 18.6667 24H5.33333C2.38781 24 0 21.5579 0 18.5455V5.45455Z"
      fill="#F2F4F7"
    />
    <rect x="4" y="4" width="16" height="16" fill="#1E90FF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 7.58984H7V20.0058H9.5V20.0052H11.9998V10.1492H9.5V7.58984ZM14.5001 12.7086H12.0001V20.0046H14.5001V20.004H16.9999V15.268H14.5001V12.7086Z"
      fill="white"
    />
  </svg>
);

export default UyqurRectangleIcon;
