import React, { useEffect } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

import { useAppSelector } from "hooks/redux";

import { PartyResourceForm } from "modules/party/resource";

import Catalogs from "pages/party/resource/content/top/catalogs";
import Resources from "pages/party/resource/content/top/resources";
import SubCatalogs from "pages/party/resource/content/top/sub-catalogs";
import Title from "pages/party/resource/content/top/title";

import { ConditionalRender } from "components";

type Props = {
  form: UseFormReturn<PartyResourceForm>;
};

const Index: React.FC<Props> = ({ form }) => {
  const { visible } = useAppSelector(state => state.partyReducer.resource);
  const { search, catalog, sub_catalog } = useWatch({ control: form.control });

  useEffect(() => {
    form.reset({
      products: [],
      sub_catalog: undefined,
      catalog: undefined,
      search: undefined
    });
  }, [visible]);

  return (
    <div className="box-border flex flex-1 flex-col gap-4 px-7 py-5">
      <Title form={form} />
      <ConditionalRender if={!search && !catalog && !sub_catalog}>
        <Catalogs form={form} />
      </ConditionalRender>
      <ConditionalRender if={!search && catalog && !sub_catalog}>
        <SubCatalogs form={form} />
      </ConditionalRender>
      <ConditionalRender if={search || sub_catalog || catalog}>
        <Resources form={form} />
      </ConditionalRender>
    </div>
  );
};

export default Index;
