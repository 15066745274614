import { useMemo } from "react";
import BuildingIcon from "features/payment/assets/icons/BuildingIcon";
import FileCheckIcon from "features/payment/assets/icons/FileCheckIcon";
import { useTranslation } from "react-i18next";
import { settingsRoutes } from "routes/settings/settingsRoutes";

import { useAuth } from "modules/auth/hooks";
import { colors } from "modules/common";

import { rootPaths } from "../../../../routes/root/rootPaths";
import { settingsPaths } from "../../../../routes/settings/settingsPaths";
import CounterpartsIcon from "../../assets/icons/CounterpartsIcon";
import ImpostIcon from "../../assets/icons/ImpostIcon";
import InventoryIcon from "../../assets/icons/InventoryIcon";
import MainPaymentIcon from "../../assets/icons/MainPaymentIcon";
import MainWarehouseIcon from "../../assets/icons/MainWarehouseIcon";
import ProjectIcon from "../../assets/icons/ProjectIcon";
import SettingsIcon from "../../assets/icons/SettingsIcon";
import SupplyIcon from "../../assets/icons/SupplyIcon";
import { RootLinkModel } from "../../utils/models/RootLinkModel";
import index from "features/impost";

const RootLinks = () => {
  const { i18n } = useTranslation();
  const { permissions } = useAuth();
  const initialSettingsLink = useMemo(() => {
    const permission = permissions?.find(item => item?.name?.uz === "Sozlamalar");

    const route =
      settingsRoutes({ permissions }).find(item => item.permissionName === permission?.submodules?.[0]?.name?.uz)
        ?.path || settingsPaths.PRODUCTS;

    return route;
  }, [permissions]);

  const data: {
    top: RootLinkModel[];
    bottom: RootLinkModel[];
  } = {
    top: [
      {
        name: "Loyiha",
        link: rootPaths.PROJECTS,
        icon: <ProjectIcon />
      },
      {
        name: "Ta'minot",
        link: rootPaths.SUPPLY,
        icon: <SupplyIcon />
      },
      {
        name: "Ishlar",
        link: rootPaths.WORKS,
        icon: <FileCheckIcon stroke={colors.GRAY_500} />
      },
      {
        name: "Kassa",
        link: rootPaths.PAYMENT,
        icon: <MainPaymentIcon />
      },

      {
        name: "Omborxona",
        link: rootPaths.WAREHOUSE,
        icon: <MainWarehouseIcon />
      },
      {
        name: "Kontragent",
        link: rootPaths.COUNTERPARTS,
        icon: <CounterpartsIcon />
      },
      {
        name: "Monitoring",
        link: rootPaths.IMPOST,
        icon: <ImpostIcon />
      },
      {
        name: "Inventar",
        link: rootPaths.INVENTORY,
        icon: <InventoryIcon />
      },
      {
        name: "Dashboard",
        link: rootPaths.DASHBOARD,
        icon: <BuildingIcon />
      }
    ],
    bottom: [
      {
        name: "Sozlamalar",
        link: `${rootPaths.SETTINGS}${initialSettingsLink}`,
        icon: <SettingsIcon />
      }
    ]
  };

  const filterData: {
    top: RootLinkModel[];
    bottom: RootLinkModel[];
  } = {
    top: [],
    bottom: []
  };

  // permissions?.forEach(item => {
  //   const pageName = item?.name[i18n.language];
  //   const isNotSettingsPage = pageName !== "Sozlamalar";
  //
  //   const findData = isNotSettingsPage ? data?.top?.find(e => e.name?.includes(pageName)) : data?.bottom[0];
  //
  //   if (findData?.name) {
  //     isNotSettingsPage ? filterData.top.push(findData) : filterData.bottom.push(findData);
  //   }
  // });
  data?.top.forEach((el, index) => {
    const foundOne = permissions?.find(item => item?.name?.uz === el?.name && item?.name?.uz !== "Sozlamalar");

    if (foundOne) {
      filterData?.top.push(data.top[index]);
    }
  });
  data?.bottom?.forEach((item, index) => {
    const foundOne = permissions?.find(el => el?.name?.uz === "Sozlamalar");

    if (foundOne) {
      filterData?.bottom.push(data.bottom[index]);
    }
  });

  return filterData;
};

export default RootLinks;
