import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { PartyViewForm, partyViewFormSchema } from "modules/party/view";

type Props = {
  children: (form: UseFormReturn<PartyViewForm>) => React.ReactNode;
};

const Wrapper: React.FC<Props> = ({ children }) => {
  const form = useForm<PartyViewForm>({
    resolver: zodResolver(partyViewFormSchema),
    defaultValues: {
      warehouse_products: [],
      product: undefined
    }
  });

  return <form className="h-full w-full">{children(form)}</form>;
};

export default Wrapper;
