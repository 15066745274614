import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PartyViewForm, useVat } from "modules/party/view";

import { Select } from "components";

import styles from "./items.module.scss";

type Props = {
  form: UseFormReturn<PartyViewForm>;
};

const Vat: React.FC<Props> = ({ form }) => {
  const { vats } = useVat();
  const { t } = useTranslation();

  return (
    <Select
      allowClear
      withoutMessage
      withLabel={false}
      suffixIcon={null}
      variant="borderless"
      control={form.control}
      name="product.vat_id"
      className={styles.unit_select}
      placeholder={t("partyView.Tanlang")}
      options={vats?.map(vat => ({
        key: vat?.id,
        value: vat?.id,
        label: `${vat?.value} %`,
        props: {
          name: vat?.value
        }
      }))}
    />
    // <Item name="vat_id" className="m-0 h-full w-full" initialValue={updateProduct?.vat?.id}>
    //   <Select
    //     showSearch
    //     allowClear
    //     suffixIcon={null}
    //     variant="borderless"
    //     placeholder={t("partyView.Tanlang")}
    //     filterOption={filterOption}
    //     popupMatchSelectWidth={false}
    //     className={styles.unit_select}
    //   >
    //     {vats?.map(vat => (
    //       <Option key={vat?.id} value={vat?.id} props={{ name: vat?.value }}>
    //         {vat?.value} %
    //       </Option>
    //     ))}
    //   </Select>
    // </Item>
  );
};

export default Vat;
