import { useQuery } from "@tanstack/react-query";

import { Unit } from "modules/common";

import $api from "../../../../features/app/utils/helpers/axiosInstance";
import { partyQueryKeys } from "../query-keys";

type Query = {
  units: Unit[];
};

export function useUnits() {
  const initialData: Query = {
    units: []
  };

  const { data = initialData, ...arg } = useQuery<Query>(
    [partyQueryKeys.UNITS],
    async () => {
      const { data } = await $api.get("unit/select");

      return { units: structuredClone(data?.data) };
    },
    {
      staleTime: Infinity
    }
  );

  return { ...data, ...arg };
}
