export const rootPaths = {
  AUTH: "/",
  PROJECTS: "/projects",
  PROJECT_FOLDER: "/projects/folder",
  SETTINGS: "/settings",
  SUPPLY: "/supply",
  PAYMENT: "/payment",
  DETAILED_PAYMENT: "/payment/:id",
  WAREHOUSE: "/warehouse",
  REPORT: "/report",
  MANUAL: "/manual",
  COUNTERPARTS: "/counterparts",
  DETAILED_FOLDER_COUNTERPARTS: "/counterparts/:id",
  DETAILED_COUNTERPARTS: "/detailed-counterpart/:second_id",
  FOLDER_DETAIL_COUNTERPARTS: "/counterparts/:id/:second_id",
  DETAILED_COUNTERPART: "/detailed-counterpart",
  HELP: "/help",
  INVENTORY: "/inventory",
  DETAILED_INVENTORY: "/inventory/:id",
  IMPOST: "/impost",
  DASHBOARD: "/dashboard",
  WORKS: "/works",
  DETAILED_WAREHOUSE_CATALOG: "/warehouse/:id",
  SUB_WAREHOUSE_CATALOGS: "warehouse/:id/:second_id"
};
