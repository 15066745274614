import React from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Switch } from "antd";
import DotsVerticalIcon from "features/app/assets/icons/DotsVerticalIcon";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import SettingsProductsIcon from "features/app/assets/icons/SettingsProductsIcon";
import { LocalStorage } from "features/app/service/LocalStorage";
// import * as ZapIcon from "features/payment/assets/icons/BuildingIcon";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { warehouseProductsAction } from "store/reducers/warehouseProductsReducer";

import { colors } from "modules/common";

const RightActions: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    // isStatsActive,
    isCatalogsActive
  } = useAppSelector(state => state.warehouseProducts.actions);
  const { setCatalogsActive, setStatsActive } = warehouseProductsAction;

  // const onMakeStatsActive = (checked: boolean) => {
  //   dispatch(setStatsActive(checked));
  //   if (checked) {
  //     LocalStorage.set("is_stats_active", checked);
  //   } else {
  //     LocalStorage.remove("is_stats_active");
  //   }
  // };

  const onMakeCatalogsActive = (checked: boolean) => {
    dispatch(setCatalogsActive(checked));
    if (checked) {
      LocalStorage.set("is_catalogs_active", checked);
    } else {
      LocalStorage.remove("is_catalogs_active");
    }
  };

  const items = [
    // {
    //   key: "1",
    //   label: (
    //     <label
    //       htmlFor="stats"
    //       className="flex w-[400px] cursor-pointer items-center justify-between"
    //       onClick={e => e.stopPropagation()}
    //     >
    //       <div className="flex items-center gap-2">
    //         {ZapIcon.default({ stroke: colors.GRAY_500, width: 20, height: 20 })}{" "}
    //         <span className="text-sm font-semibold text-gray-900">{t("warehouse.Resurslar statistikasi")}</span>
    //       </div>
    //       <Switch id="stats" checked={isStatsActive} onChange={onMakeStatsActive} />
    //     </label>
    //   )
    // },
    {
      key: "2",
      label: (
        <label
          htmlFor="catalogs"
          className="flex w-[400px] cursor-pointer items-center justify-between"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex items-center gap-2">
            <SettingsProductsIcon width={20} height={20} stroke={colors.GRAY_500} />{" "}
            <span className="text-sm font-semibold text-gray-900">{t("warehouse.Katalog")}</span>
          </div>
          <Switch id="catalogs" checked={isCatalogsActive} onChange={onMakeCatalogsActive} />
        </label>
      )
    }

    // {
    //   key: "3",
    //   label: (
    //     <div className="flex w-[400px] items-center gap-2">
    //       <DownloadIcon size={20} stroke={colors.GRAY_500} />{" "}
    //       <span className="text-sm font-semibold text-gray-900">{t("warehouse.Hisobotni yuklab olish")}</span>
    //     </div>
    //   )
    // }
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <div className="mr-4 flex cursor-pointer items-center justify-center rounded-lg border border-solid border-gray-300 bg-white px-4 py-[10px]">
        <DotsVerticalIcon />
      </div>
    </Dropdown>
  );
};

export default RightActions;
