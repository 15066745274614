import React from "react";

const CoinsStackedIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8337 4.16667C10.8337 5.08714 8.78163 5.83333 6.25033 5.83333C3.71902 5.83333 1.66699 5.08714 1.66699 4.16667M10.8337 4.16667C10.8337 3.24619 8.78163 2.5 6.25033 2.5C3.71902 2.5 1.66699 3.24619 1.66699 4.16667M10.8337 4.16667V7.88096C9.81574 8.18665 9.16699 8.64911 9.16699 9.16667M1.66699 4.16667V14.1667C1.66699 15.0871 3.71902 15.8333 6.25033 15.8333C7.35835 15.8333 8.37454 15.6904 9.16699 15.4524V9.16667M1.66699 7.5C1.66699 8.42047 3.71902 9.16667 6.25033 9.16667C7.35835 9.16667 8.37454 9.02369 9.16699 8.78571M1.66699 10.8333C1.66699 11.7538 3.71902 12.5 6.25033 12.5C7.35835 12.5 8.37454 12.357 9.16699 12.119M18.3337 9.16667C18.3337 10.0871 16.2816 10.8333 13.7503 10.8333C11.219 10.8333 9.16699 10.0871 9.16699 9.16667M18.3337 9.16667C18.3337 8.24619 16.2816 7.5 13.7503 7.5C11.219 7.5 9.16699 8.24619 9.16699 9.16667M18.3337 9.16667V15.8333C18.3337 16.7538 16.2816 17.5 13.7503 17.5C11.219 17.5 9.16699 16.7538 9.16699 15.8333V9.16667M18.3337 12.5C18.3337 13.4205 16.2816 14.1667 13.7503 14.1667C11.219 14.1667 9.16699 13.4205 9.16699 12.5"
      stroke="#475467"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CoinsStackedIcon;
