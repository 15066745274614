import React from "react";

import { CircleArrowLeftIcon } from "components";

import ReceivedWarehouse from "./received-warehouse";
import SenderWarehouse from "./sender-warehouse";

const Top: React.FC = () => (
  <div className="box-border flex items-end gap-4 rounded-2xl border border-solid border-gray-200 bg-white p-4">
    <SenderWarehouse />
    <span className="mb-1">
      <CircleArrowLeftIcon />
    </span>
    <ReceivedWarehouse />
  </div>
);

export default Top;
