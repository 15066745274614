import { WarehouseProductOfferStatus } from "../schema";

export function offerStatusClass(status: WarehouseProductOfferStatus) {
  if (status === "rejected") {
    return "bg-error-500 text-white";
  }

  if (status === "recieved") {
    return "bg-success-500 text-white";
  }

  return "bg-gray-200 text-gray-600";
}
