import React, { ReactNode, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Dropdown, Spin } from "antd";
import { ItemType } from "antd/es/menu/interface";

import { LoadingIndicator } from "../../../../../../../components";
import { useAppSelector } from "../../../../../../../hooks/redux";
import ConditionalRender from "../../../../../../app/components/conditional-render/ConditionalRender";
import { RU } from "../../../../../../app/utils/constants/languages";
import { cx } from "../../../../../../app/utils/helpers/cx";
import { useGetCurrencies, useGetPaymentTypeSelect } from "../../../../../../settings/service/queries";
import { useCreateCompanyPersonPaymentType } from "../../../../../service/mutations";
import { supplyQueryNames } from "../../../../../utils/constants/supplyQueryNames";
import { generateTotalAmount } from "../../../../../utils/helpers/generateTotalAmount";
import { OrderDetailModel } from "../../../../../utils/models/orderModel";
import { PartyFormProductsModel } from "../../../../../utils/models/partyModalFormModel";

import styles from "./partyPersonGroup.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  name?: string;
  id?: string | number;
  isWarehouse?: boolean;
  data: PartyFormProductsModel[];
};

const PersonGroupHeader: React.FC<Props> = ({ id, name, isWarehouse, data }) => {
  const { t } = useTranslation();
  const qc = useQueryClient();
  const { data: currencies } = useGetCurrencies();
  const { data: paymentTypes } = useGetPaymentTypeSelect();
  const createPaymentType = useCreateCompanyPersonPaymentType();
  const { party_id, isView } = useAppSelector(state => state.supplyReducer.partyModal);
  const oneOrder = qc.getQueryData<OrderDetailModel>([supplyQueryNames.ORDER_DETAIL, party_id]);

  const getPaymentTypeValue = useMemo(() => {
    if (oneOrder && id) {
      return oneOrder?.company_person_payment_types?.find(item => item?.company_person_id === id)?.payment_type;
    }

    return undefined;
  }, [id, oneOrder]);

  const onChangePayment = (e: number) => () => {
    createPaymentType.mutate({
      payment_type_id: e === getPaymentTypeValue?.id ? undefined : e,
      company_person_id: Number(id),
      order_id: party_id!
    });
  };

  const onClickSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const items: ItemType[] =
    paymentTypes?.map(item => ({
      key: item?.id,
      label: (
        <div
          style={{
            background: `${item?.color}20`,
            color: item?.color
          }}
          className="flex h-5 w-max items-center gap-2 rounded-xl px-2 py-0.5 text-xs font-medium"
        >
          {item?.name}
        </div>
      ),
      onClick: onChangePayment(item?.id)
    })) || [];

  const dropdownRender = (originNode: ReactNode) => (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {originNode}
    </div>
  );

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={styles.label}>
      <div className="flex items-center gap-3">
        <div className={cx(styles.label__left)}>{name}</div>
        <ConditionalRender if={id && !isView}>
          <Spin spinning={createPaymentType.isLoading} indicator={LoadingIndicator}>
            <Dropdown
              menu={{
                items,
                selectable: true,
                selectedKeys: getPaymentTypeValue ? [String(getPaymentTypeValue?.id)] : []
              }}
              trigger={["click"]}
              dropdownRender={dropdownRender}
            >
              {getPaymentTypeValue ? (
                <div
                  onClick={stopPropagation}
                  style={{
                    background: `${getPaymentTypeValue?.color}20`,
                    color: getPaymentTypeValue?.color
                  }}
                  className="flex h-5 w-max items-center gap-2 rounded-xl px-2 py-0.5 text-xs font-medium"
                >
                  {getPaymentTypeValue?.name}
                </div>
              ) : (
                <div
                  onClick={stopPropagation}
                  className={cx(
                    "person-select",
                    "rounded-[4px] border border-dashed border-gray-300 px-3 py-1 text-xs font-medium text-gray-300"
                  )}
                >
                  {t("partyModal.To'lov turi")}
                </div>
              )}
            </Dropdown>
          </Spin>
        </ConditionalRender>
      </div>
      <div className={styles.total}>
        <span>Jami: </span>
        {isWarehouse ? (
          <div />
        ) : (
          <div>
            {currencies?.data?.map(currency => {
              const amount = generateTotalAmount(data)?.[String(currency?.id)];

              return amount >= 0 ? (
                <>
                  <p>
                    <span>{amount?.toLocaleString(RU)}</span>
                    <span>{currency?.symbol}</span>
                  </p>
                  <p>,</p>
                </>
              ) : null;
            })}
          </div>
        )}
      </div>
      {/* {titleRight(item)} */}
    </div>
  );
};

export default PersonGroupHeader;
