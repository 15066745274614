import { useInfiniteQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import { PaginationType } from "features/app/utils/models/PaginationType";

import { partyCreateQueryKeys, ProductWithUnit } from "modules/party/create";

type Props = {
  search?: string;
};

export function useProducts({ search }: Props) {
  const { data, ...arg } = useInfiniteQuery<PaginationType<ProductWithUnit[]>>({
    queryKey: [partyCreateQueryKeys.PRODUCTS, search],
    queryFn: async ({ pageParam = 1 }) => {
      let url = `product/select?paginate=true&size=50&page=${pageParam ?? 1}`;

      if (search) {
        url += `&search=${search}`;
      }

      const res = await $api.get(url);

      return res.data.data;
    },
    getNextPageParam: (lastPage, page) => {
      if (page.length < lastPage?.data.length) {
        return page.length + 1;
      }
      return undefined;
    },
    retry: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  });

  return { ...data, ...arg };
}
