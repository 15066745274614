import React from "react";
import { Popover, PopoverProps } from "antd";

import styles from "./popoverShortName.module.scss";

type Props = {
  title?: string;
  length?: number;
  getPopoverContainer?: () => HTMLElement;
};

const PopoverShortName: React.FC<Props & PopoverProps> = ({ title, length = 10, getPopoverContainer, ...arg }) => (
  <>
    {title && title.length > length ? (
      <Popover getPopupContainer={getPopoverContainer} title={title} overlayClassName={styles.popover} {...arg}>
        {title.substring(0, length)}...
      </Popover>
    ) : (
      title
    )}
  </>
);

export default PopoverShortName;
