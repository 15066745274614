import React, { ChangeEvent, useEffect } from "react";
import { Col, Form, Input, Row, Select } from "antd";
import { Rule } from "antd/es/form";
import { FormInstance } from "antd/lib";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";

import { useAppSelector } from "../../../../../../hooks/redux";
import { cx } from "../../../../../../modules/common";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import ProjectSelect from "../../../../../app/components/project-select/project-select";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { formRules } from "../../../../../app/utils/constants/formRules";
import { RU } from "../../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import { getBaseCurrency } from "../../../../../app/utils/helpers/baseCurrency";
import { localeFormatter } from "../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import { useGetCompanyPerson } from "../../../../../counterparts/service/queries";
import { numberRound } from "../../../../../supply/utils/helpers/numberRound";
import { useGetFinancialSelect } from "../../../../service/queries";
import { PaymentOrderModel } from "../../../../utils/models/paymentOrderModel";

import Additional from "./additional/additional";

import styles from "./thirdStep.module.scss";

const { Item } = Form;
const { Option } = Select;

type Props = {
  order?: PaymentOrderModel;
  form: FormInstance;
  step: number;
};

const ThirdStep: React.FC<Props> = ({ order, form, step }) => {
  const { t } = useTranslation();
  const { currencies } = useAuth();
  const { currency, isOrderDelete } = useAppSelector(state => state.paymentReducer.paymentOrderModal);
  const { data: financials, isLoading } = useGetFinancialSelect(!!isOrderDelete, PaymentTypeEnum.EXPENSE);

  const baseCurrency = getBaseCurrency();
  const activeCurrency = currencies?.find(item => item.id === currency?.id);
  const isCurrencyAmount = baseCurrency?.id !== currency?.id;

  const { data: getCompanyPerson } = useGetCompanyPerson(Number(order?.company_person?.id));

  const debtAmount = () => {
    let totalDebtAmount: number | string = 0;

    if (order?.currency?.id === currency?.id) {
      totalDebtAmount = Number(order?.debt_amount)?.toLocaleString(RU);
    } else {
      const orderCurrencyAmount = Number(currencies?.find(item => item?.id === order?.currency?.id)?.amount);

      if (orderCurrencyAmount > Number(activeCurrency?.amount))
        totalDebtAmount = (Number(order?.debt_amount) * orderCurrencyAmount)?.toLocaleString(RU);
      else
        totalDebtAmount = numberRound(Number(order?.debt_amount) / Number(activeCurrency?.amount), 100)?.toLocaleString(
          RU
        );
    }

    return totalDebtAmount;
  };

  const acceptedAmountRules: Rule[] | undefined = [
    {
      validator(_, value: string) {
        const parseValue = parseLocaledString(value);
        let newDebtAmount = 0;

        if (!isOrderDelete) {
          newDebtAmount = Number(currency?.amount);

          if (parseValue > newDebtAmount) {
            return Promise.reject(
              `Kiritilgan miqdor ${newDebtAmount?.toLocaleString(RU)} ${currency?.symbol} dan kichik bo'lishi kerak`
            );
          }
        }

        if (parseValue > 0 && value) {
          return Promise.resolve();
        }

        return Promise.reject();
      }
    }
  ];

  const onChangeCurrencyAmount = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("currency_amount", localeFormatter(e.currentTarget.value));
  };

  const onChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("amount", localeFormatter(e.currentTarget.value));
    form.setFieldValue("old_amount", localeFormatter(e.currentTarget.value));
  };

  useEffect(() => {
    if (step === 2 && isCurrencyAmount) {
      form.setFieldValue("currency_amount", localeFormatter(String(activeCurrency?.amount)));
    }

    if (!isOrderDelete) {
      form.setFieldsValue({
        amount: debtAmount(),
        old_amount: debtAmount()
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, isCurrencyAmount, currency]);

  return (
    <div className={styles.payment_create}>
      <div className={styles.form}>
        <ConditionalRender if={order && order?.company_person}>
          <div className="mb-5 flex items-center gap-2">
            <span className="text-sm font-normal text-gray-600">{t("payment.Ta'minotchi")}</span>
            <span className="text-base font-semibold text-gray-700">{order?.company_person?.name}</span>
          </div>
        </ConditionalRender>
        <ProjectSelect form={form} label={t("payment.Loyiha")} suffixIcon={<SelectSuffixIcon />} />
        <Row gutter={16} className={styles.form__block}>
          <Col span={isCurrencyAmount ? 14 : 24}>
            <Item name="old_amount" className="hidden" />
            <Item name="amount" label={t("payment.Summa")} rules={acceptedAmountRules}>
              <Input placeholder={t("payment.Summa")} onChange={onChangeAmount} suffix={currency?.symbol} />
            </Item>
          </Col>
          {isCurrencyAmount && (
            <Col span={10}>
              <Item name="currency_amount" label={t("payment.Valyuta kursi")} rules={formRules()}>
                <Input
                  placeholder={t("payment.Summa")}
                  onChange={onChangeCurrencyAmount}
                  prefix={`${currency?.symbol} = `}
                  suffix={baseCurrency?.symbol}
                />
              </Item>
            </Col>
          )}
        </Row>
        {order?.company_person?.id && getCompanyPerson?.balances && getCompanyPerson?.balances?.length > 0 && (
          <div className="my-4 flex items-center gap-2">
            {t("payment.Balans")}:{" "}
            <div className="flex items-center gap-2">
              {getCompanyPerson?.balances?.map((item, i) => (
                <span
                  key={item.id}
                  className={cx(
                    { "text-error-500": item?.amount < 0, "bg-error-50": item?.amount < 0 },
                    { "text-success-500": item?.amount > 0, "bg-success-50": item?.amount > 0 },
                    { "text-gray-700": item?.amount === 0, "bg-gray-50": item?.amount === 0 },
                    "rounded-lg px-2 py-1 font-medium"
                  )}
                >
                  {item?.amount?.toLocaleString(RU)} {item?.currency?.symbol}
                </span>
              ))}
            </div>
          </div>
        )}
        <ConditionalRender if={isOrderDelete}>
          <Item name="financial_id" label={t("payment.Xarajat")} className="mt-6" rules={formRules()}>
            <Select
              loading={isLoading}
              placeholder={t("payment.Tanlang")}
              notFoundContent={<TableEmpty />}
              suffixIcon={<SelectSuffixIcon />}
            >
              {financials?.map(finance => (
                <Option
                  key={finance?.id}
                  value={finance?.id}
                  props={{
                    name: finance?.name
                  }}
                >
                  {finance?.name}
                </Option>
              ))}
            </Select>
          </Item>
        </ConditionalRender>
        <Additional form={form} />
      </div>
    </div>
  );
};

export default ThirdStep;
