import { UserTableDataModel } from "../../../app/utils/models/user/userTableConfigModel";

export const paymentOrderTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "id",
      name: "id",
      title: "Partiya",
      checked: true
    },
    {
      id: "new_payment_date",
      name: "new_payment_date",
      title: "To’lov sanasi",
      checked: true
    },
    {
      id: "new_ordered_date",
      name: "new_ordered_date",
      title: "Buyurtma  sanasi",
      checked: false
    },
    {
      id: "delivery_date",
      name: "delivery_date",
      title: "Yetkazilish sanasi",
      checked: true
    },
    {
      id: "agent.full_name",
      name: "agent.full_name",
      title: "Vositachi",
      checked: true
    },
    {
      id: "percent",
      name: "percent",
      title: "Jarayon",
      checked: true
    },
    {
      id: "expense",
      name: "expense",
      title: "Xarajat summa",
      checked: false
    },
    {
      id: "amount",
      name: "amount",
      title: "Umumiy summa",
      checked: true
    },
    {
      id: "status",
      name: "status",
      title: "Holati",
      checked: true
    },
    {
      id: "payment_percent",
      name: "payment_percent",
      title: "To'lov holati",
      checked: true
    },
    {
      id: "creator.full_name",
      name: "creator.full_name",
      title: "Xodim",
      checked: true
    }
  ],
  size: 10
};
