import React, { useState } from "react";
import { Button, Drawer as AntDrawer } from "antd";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { routeSubmodules } from "modules/dashboard";
import { useCompanyWidgetsSelect } from "modules/dashboard/queries/use-company-widgets-select";

import * as Icons from "../icons";
import ViewModal from "../view-modal/view-modal";

import CompanyList from "./company-list";
import RecommendedList from "./recommended-list";

import styles from "./drawer.module.scss";

const Drawer: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const { companyWidgets, isLoading } = useCompanyWidgetsSelect({ enabled: visible });
  const { t } = useTranslation();
  const { actions } = useRoutePermissions("Dashboard", routeSubmodules);
  const dashboardActions = actions("Amallar");
  console.log(dashboardActions);

  const onOpenDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={onOpenDrawer}>
        <Icons.WidgetIcon />
        <span className="text-sm">{t("dashboard.Vidjetlar")}</span>
      </Button>
      <AntDrawer
        title={t("dashboard.Vidjetlar")}
        open={visible}
        onClose={onClose}
        width={384}
        footer={dashboardActions.create ? <ViewModal /> : undefined}
      >
        <div className="flex flex-col gap-5">
          <RecommendedList />
          <div className="flex flex-col gap-3">
            <p className={styles.title}>{t("dashboard.Mening vidjetlarim")}</p>
            <CompanyList isCompany data={companyWidgets} isLoading={isLoading} />
          </div>
        </div>
      </AntDrawer>
    </>
  );
};

export default Drawer;
