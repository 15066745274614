import React from "react";
import { useDispatch } from "react-redux";
import { UseFormReturn, useWatch } from "react-hook-form";
import { Dropdown, DropdownProps, Switch, SwitchProps } from "antd";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/reducers/partyReducer";

import { groupData, parseGroupData, PartyForm, PartyFormProduct, PartyPersonGroup } from "modules/party/create";

import {
  AddCardIcon,
  DotsVerticalIcon,
  GitBranchIcon,
  LayoutAltIcon,
  PercentIcon,
  SaleIcon,
  XCloseIcon
} from "components";

type Props = {
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form }) => {
  const dispatch = useDispatch();
  const { warehouse_products, company_person_group } = useWatch({
    control: form.control
  });
  const { isGroup, isVat, isDiscount } = useAppSelector(state => state.partyReducer.create);
  const {
    setPartyCreateGroup,
    setPartyCreateVat,
    setPartyCreate,
    setPartyEstimate,
    setPartyResource,
    setPartyCreateDiscount
  } = partyReducerActions;

  const onGroup: SwitchProps["onChange"] = (checked, event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(setPartyCreateGroup(checked));

    if (!isGroup) {
      form.setValue("company_person_group", groupData(warehouse_products as PartyFormProduct[]));
      form.unregister("warehouse_products");
    } else {
      form.setValue("warehouse_products", parseGroupData(company_person_group as PartyPersonGroup));
      form.unregister("company_person_group");
    }
  };

  const onVat: SwitchProps["onChange"] = (checked, event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(setPartyCreateVat(checked));
  };

  const onDiscount: SwitchProps["onChange"] = (checked, event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!checked) {
      form.setValue("discount", undefined);
    }

    dispatch(setPartyCreateDiscount(checked));
  };

  const onEstimate = () => {
    dispatch(
      setPartyEstimate({
        visible: true
      })
    );
  };

  const onResources = () => {
    dispatch(
      setPartyResource({
        visible: true
      })
    );
  };

  const menu: DropdownProps["menu"] = {
    items: [
      {
        key: "1",
        label: (
          <label htmlFor="is_party_group" className="flex cursor-pointer items-center justify-between gap-9">
            <div className="flex items-center gap-2">
              <GitBranchIcon />
              Guruhlash
            </div>
            <Switch id="is_party_group" onChange={onGroup} checked={isGroup} />
          </label>
        )
      },
      {
        key: "2",
        label: (
          <label htmlFor="is_party_vat" className="flex cursor-pointer items-center justify-between gap-9">
            <div className="flex items-center gap-2">
              <SaleIcon />
              NDS
            </div>
            <Switch id="is_party_vat" onChange={onVat} checked={isVat} />
          </label>
        )
      },
      {
        key: "3",
        label: (
          <label htmlFor="is_party_discount" className="flex cursor-pointer items-center justify-between gap-9">
            <div className="flex items-center gap-2">
              <PercentIcon />
              Chegirma
            </div>
            <Switch id="is_party_discount" onChange={onDiscount} checked={isDiscount} />
          </label>
        )
      },
      {
        key: "4",
        label: (
          <div className="flex items-center gap-2">
            <LayoutAltIcon />
            <span>Smeta resurslari</span>
          </div>
        ),
        onClick: onEstimate
      },
      {
        key: "5",
        label: (
          <div className="flex items-center gap-2">
            <AddCardIcon />
            <span>Mahsulotlar katalogi</span>
          </div>
        ),
        onClick: onResources
      }
    ]
  };

  const onClose = () => {
    dispatch(
      setPartyCreate({
        visible: false,
        isVat: false,
        isGroup: false,
        type: "supply",
        isDiscount: false
      })
    );

    form.reset({
      company_person_group: [],
      warehouse_products: [],
      agent_id: undefined,
      status: "ordered",
      custom_field_values: [],
      payment_date: undefined,
      delivery_date: undefined,
      discount: undefined
    });
  };

  return (
    <div className="box-border flex w-full items-center justify-between border border-l-0 border-r-0 border-t-0 border-solid border-gray-300 bg-white p-3.5">
      <h2 className="m-0 text-lg font-semibold text-gray-900">Partiya yaratish</h2>
      <div className="flex items-center gap-6">
        <Dropdown destroyPopupOnHide arrow={false} placement="bottom" trigger={["click"]} menu={menu}>
          <div className="flex cursor-pointer items-center justify-center rounded-md border border-solid border-gray-300 bg-gray-50 px-2 py-1.5">
            <DotsVerticalIcon />
          </div>
        </Dropdown>
        <div
          onClick={onClose}
          className="box-border flex cursor-pointer items-center justify-center rounded-md p-1.5 duration-300 ease-in-out hover:bg-gray-100"
        >
          <XCloseIcon width={20} />
        </div>
      </div>
    </div>
  );
};

export default Index;
