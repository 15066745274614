import React from "react";
import { useTranslation } from "react-i18next";

import { cx } from "modules/common";
import { ConnectButton, Integration, integrationIcon } from "modules/settings/integration";

const Uysot: React.FC<Integration> = integration => {
  const { t } = useTranslation();
  const { description, icon, name, connect, type } = integration;

  return (
    <>
      <div className="box-border flex min-w-[21rem] flex-col justify-between gap-4 rounded-xl border border-solid border-gray-200 p-4 duration-200 ease-in-out">
        <div className="flex items-center justify-between">
          <div className="box-border flex w-max items-center justify-center rounded-xl border border-solid border-gray-200 p-2.5">
            <img src={icon} alt={name} className="h-9 w-9 object-cover" />
          </div>
          <div
            className={cx(
              "flex items-center justify-center gap-1.5 rounded-2xl border border-solid border-success-50 bg-success-50 px-2.5 py-2px",
              { hidden: !connect }
            )}
          >
            <span className="h-1.5 w-1.5 rounded-[50%] border border-solid border-success-500 bg-success-500" />
            <span className="text-sm font-medium text-success-600">{t("integration.Faol")}</span>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <h4 className="m-0 text-base font-semibold text-gray-700">
            {name} {integrationIcon[type]}
          </h4>
          <p className="m-0 text-sm font-medium text-gray-400">
            {description || t("integration.Ma'ruf description kiritmagan")}
          </p>
        </div>
        <ConnectButton integration={integration} />
      </div>
    </>
  );
};

export default Uysot;
