import { useDispatch } from "react-redux";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { setStep, useIntegrationCurrency } from "modules/settings/uysot";

const UysotCurrencyFooter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mutateAsync, isLoading } = useIntegrationCurrency();
  const { step, currencies } = useAppSelector(({ uysot }) => uysot);

  const onNext = () => {
    dispatch(setStep(step + 1));
  };

  const onSave = () => {
    mutateAsync({
      currencies
    }).then(() => {
      dispatch(setStep(step + 1));
    });
  };

  return (
    <div className="flex items-center justify-end gap-4">
      <Button onClick={onNext} disabled={isLoading}>
        {t("uysot.Keyingisi")}
      </Button>
      <Button type="primary" onClick={onSave} loading={isLoading}>
        {t("uysot.Saqlash")}
      </Button>
    </div>
  );
};

export default UysotCurrencyFooter;
