import React, { ReactNode } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { PartyEstimateForm } from "modules/party/estimate";

type Props = {
  children: (form: UseFormReturn<PartyEstimateForm>) => ReactNode;
};

const Index: React.FC<Props> = ({ children }) => {
  const form = useForm<PartyEstimateForm>({
    defaultValues: {
      project_id: undefined,
      products: []
    }
  });

  return (
    <form
      className="h-full"
      onSubmit={form.handleSubmit(
        () => {},
        errors => {
          console.log(errors);
        }
      )}
    >
      {children(form)}
    </form>
  );
};

export default Index;
