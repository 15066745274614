import React from "react";
import { Popover } from "antd";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { WorkStatusEnum } from "features/projects/utils/enums/workStatusEnum";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

type Props = {
  plan_date: string;
  fact_date: string;
  status: WorkStatusEnum;
  diffDate: number;
};

const DatePopover: React.FC<Props> = ({ plan_date, fact_date, status, diffDate }) => {
  const { t } = useTranslation();

  const dateStatusClass = () => {
    if (diffDate > 0) return styles.delivery_date__error;
    if (diffDate < 0) return styles.delivery_date__success;
    return styles.delivery__default_text;
  };

  const dateDiffCharacter = () => {
    if (diffDate < 0) return "-";
    if (diffDate > 0) return "+";
    return " ";
  };

  const content = (
    <div className="flex w-[320px] flex-col gap-5 rounded-lg border border-white bg-white px-3 py-2">
      <ConditionalRender if={plan_date}>
        <div className="flex items-center justify-between">
          <span>{t("works.Reja")}</span>
          <div className="flex items-center gap-3">
            <span className="text-sm font-medium text-gray-700">{plan_date}</span>
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender if={fact_date}>
        <div className="flex items-center justify-between">
          <span>{t("works.Fakt")}</span>
          <div className="flex items-center gap-3">
            <span className="text-sm font-medium text-gray-700">{fact_date}</span>
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender if={diffDate}>
        <div className="flex items-center justify-between">
          <span>{t("works.Farq")}</span>
          <div className="flex items-center gap-3">
            <span className={dateStatusClass()}>
              {dateDiffCharacter()}
              {Math.abs(diffDate)} <span>{t("works.kun")}</span>
            </span>
          </div>
        </div>
      </ConditionalRender>
    </div>
  );

  return (
    <Popover arrow={false} content={content}>
      <span className={dateStatusClass()}>{plan_date}</span>
    </Popover>
  );
};

export default DatePopover;
