import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import Pagination from "features/app/components/pagination/Pagination";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";
import { useTranslation } from "react-i18next";

import { cx, RU } from "modules/common";

import styles from "./spent-of-resources-table.module.scss";

const SpentOfResourcesTable: React.FC = () => {
  const { t, i18n } = useTranslation();

  const columns: ColumnsType<any> = [
    {
      title: t("works.Resures turi va nomi"),
      render: (_, record) => (
        <div className={cx("resource")}>
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product.resource?.symbol[i18n.language]}
          </div>
          <PopoverShortName title={record?.product?.name[i18n.language]} length={20} />
        </div>
      ),
      width: 850
    },
    {
      title: t("works.Sana"),
      dataIndex: "date",
      width: 300
    },
    {
      title: t("works.Miqdori"),
      render: (_, record) => (
        <div className="text-sm font-normal text-gray-700">{record?.amount?.toLocaleString(RU)}</div>
      ),
      width: 250
    },
    {
      title: t("works.Birlik"),
      render: (_, record) => (
        <div className="text-sm font-normal text-gray-700">{record?.unit?.symbol[i18n.language]}</div>
      )
    },
    {
      title: t("works.Birlik summasi"),
      render: (_, record) => (
        <div className="flex items-center gap-1">
          <span className="text-sm font-normal text-gray-700">{record?.currency_amount?.toLocaleString(RU)}</span>
          <span className="text-sm font-normal text-gray-400">{record?.unit?.symbol[i18n.language]}</span>
        </div>
      ),
      width: 350
    },
    {
      title: t("works.Umumiy summasi"),
      render: (_, record) => (
        <div className="text-sm font-normal text-gray-700">
          {record?.total_amount} {record?.currency?.symbol}
        </div>
      ),
      width: 250
    },
    {
      title: "",
      render: () => (
        <div className={styles.arrow}>
          <ChevronDownIcon rotate />
        </div>
      ),
      width: 70
    }
  ];

  const footer = () => (
    <Pagination
      paginationProps={{
        current: 1,
        total: 0
      }}
    />
  );

  return (
    <div>
      <div className={styles.top}>
        <Table
          footer={footer}
          dataSource={[]}
          columns={columns}
          pagination={false}
          rootClassName={styles.spent_of_resources_table}
          locale={{
            emptyText: <TableEmpty />
          }}
          rowClassName={styles.spent_of_resources_table_row}
        />
      </div>
    </div>
  );
};

export default SpentOfResourcesTable;
