import React, { useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Select } from "antd";
import { useChangeTableConfig } from "features/app/service/mutation";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import PaymentCalendarSelect from "features/payment/components/bottom/info/left/PaymentCalendarSelect";
import { PaymentFilterKeys, paymentFilterNames } from "features/payment/utils/enums/paymentFilterKeys";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useAuth } from "modules/auth/hooks";

import { useQueryParams } from "../../../../../../../hooks/useQueryParams";
import { useRoutePermissions } from "../../../../../../../hooks/useRoutePermissions";
import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import { LocalStorage } from "../../../../../../app/service/LocalStorage";
import { useGetCashSelect } from "../../../../../../app/service/queries";
import { RU } from "../../../../../../app/utils/constants/languages";
import { queryParamsKeys } from "../../../../../../app/utils/constants/queryParamsKeys";
import { getBaseCurrency } from "../../../../../../app/utils/helpers/baseCurrency";
import CurrencySegmented from "../../../../../../payment/components/bottom/info/left/CurrencySegmented";
import { PaymentTabKeys } from "../../../../../../payment/utils/constants/paymentTabKeys";
import { counterpartsData } from "../../../../../utils/constants/counterpartsData";
import { companyPersonBalanceSubmodules } from "../../../../../utils/constants/routeSubmodules";

import styles from "./infoLeft.module.scss";

const { Option } = Select;

type Props = {
  statistics: any;
  defaultTabKey?: string;
};

const InfoLeft: React.FC<Props> = ({ statistics, defaultTabKey }) => {
  const { t } = useTranslation();
  const data = counterpartsData;
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { append, remove, queries: allQuery } = useQueryParams();
  const changeTableConfig = useChangeTableConfig(tableConfigKeys.COUNTERPARTS_INCOME_OR_EXPENSE, true, true);
  const {
    statistics_type: tabKey,
    currency_id: currencyId,
    payment_type: paymentType,
    type: expectedPaymentType,
    filter_type: filterType,
    check_currency: checkCurrency
  } = allQuery();
  const queries = queryString.parse(location.search, { arrayFormat: "bracket" });
  const cashIds = queries[queryParamsKeys.CASH_IDS] as string[];
  const { currencies } = useAuth();
  const baseCurrency = getBaseCurrency();
  const secondCurrency = getBaseCurrency("second");

  const { submodules } = useRoutePermissions("Kontragent balansi", companyPersonBalanceSubmodules);
  const { data: cashes, isLoading } = useGetCashSelect(tabKey === PaymentTabKeys.INCOME_EXPENSE);

  const currencyData = useMemo(
    () =>
      currencies?.find(item => {
        if (currencyId) return item.id === Number(currencyId);
        return item.type === "base";
      }),
    [searchParams]
  );

  const calendarFilterTypeValue = () => {
    if (filterType) return filterType as PaymentFilterKeys;

    return PaymentFilterKeys.ALL;
  };

  const onChangeCalendarFilter = (e: PaymentFilterKeys) => {
    if (e === PaymentFilterKeys.ALL) {
      remove(queryParamsKeys.FILTER_TYPE);
    } else {
      append(queryParamsKeys.FILTER_TYPE, e);
    }
  };

  const onChangeCash = (value: number[]) => {
    const queryValue = queryString.parse(location.search, {
      arrayFormat: "bracket"
    });

    delete queryValue.cash_ids;

    if (value?.length > 0) {
      navigate({
        search: queryString.stringify({ ...queryValue, cash_ids: value }, { arrayFormat: "bracket" })
      });
      changeTableConfig.mutate({
        key: tableConfigKeys.COUNTERPARTS_INCOME_OR_EXPENSE,
        filter: queryString.stringify({ ...queryValue, cash_ids: value }, { arrayFormat: "bracket" })
      });
    } else {
      navigate({
        search: queryString.stringify(queryValue, { arrayFormat: "bracket" })
      });
      changeTableConfig.mutate({
        key: tableConfigKeys.COUNTERPARTS_INCOME_OR_EXPENSE,
        filter: queryString.stringify(queryValue, { arrayFormat: "bracket" })
      });
    }
  };

  const onChangePaymentType = (value: string) => {
    const queryValue = queryString.parse(location.search);

    delete queryValue.cash_ids;
    delete queryValue.payment_type;

    navigate({
      search: `${queryString.stringify(queryValue, { arrayFormat: "bracket" })}&${
        value ? `${queryParamsKeys.PAYMENT_TYPE}=${value}` : ""
      }`
    });
  };

  const cashIdsSelectValue = cashIds?.map(item => Number(item)) ?? [];

  const filterSelect = {
    [PaymentTabKeys.INCOME_EXPENSE]: (
      <Select
        allowClear
        mode="multiple"
        showSearch={false}
        loading={isLoading}
        variant="borderless"
        onChange={onChangeCash}
        value={cashIdsSelectValue}
        style={{
          minWidth: "10.6rem"
        }}
        placeholder={t("Counterparts.Barcha kassalar")}
        popupMatchSelectWidth={false}
        suffixIcon={<SelectSuffixIcon />}
      >
        {cashes?.map(item => (
          <Option key={item.id} value={item.id}>
            {item?.name}
          </Option>
        ))}
      </Select>
    ),
    [PaymentTabKeys.PAYMENT]: (
      <Select
        allowClear
        value={paymentType}
        loading={isLoading}
        variant="borderless"
        placeholder={t("Counterparts.Tanlash")}
        style={{
          minWidth: "6rem"
        }}
        popupMatchSelectWidth={false}
        onChange={onChangePaymentType}
        suffixIcon={<SelectSuffixIcon />}
      >
        <Option value="payment">{t("Counterparts.To’lovlar bo’yicha")}</Option>
        <Option value="template">{t("Counterparts.Guruhlangan holda")}</Option>
      </Select>
    ),
    [PaymentTabKeys.ORDER]: null,
    [PaymentTabKeys.CALENDAR]: (
      <Select
        variant="borderless"
        placeholder="Tanlang"
        className={styles.select}
        popupMatchSelectWidth={false}
        value={calendarFilterTypeValue()}
        onChange={onChangeCalendarFilter}
        suffixIcon={<SelectSuffixIcon />}
        defaultValue={PaymentFilterKeys.ALL}
      >
        <Option value={PaymentFilterKeys.ORDER}>{paymentFilterNames[PaymentFilterKeys.ORDER]}</Option>
        <Option value={PaymentFilterKeys.EXPECTED_PAYMENT}>
          {paymentFilterNames[PaymentFilterKeys.EXPECTED_PAYMENT]}
        </Option>
        <Option value={PaymentFilterKeys.ALL}>{paymentFilterNames[PaymentFilterKeys.ALL]}</Option>
      </Select>
    )
  };

  const dataKey = () => {
    if (expectedPaymentType) return `${tabKey}-${expectedPaymentType}`;
    return tabKey;
  };

  const defaultDataKey = useMemo(() => {
    // @ts-ignore
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [key, type] = submodules[0]?.tabKey?.split("&");
    const keyValue = key?.split("=")?.[1];
    const typeValue = type?.split("=")?.[1];

    return typeValue ? `${keyValue}-${typeValue}` : keyValue;
  }, [submodules]);

  const amountContent = (key: string) => {
    const paymentCurrency = LocalStorage.get("payment_currency");
    const checkCurrencyValue = checkCurrency || paymentCurrency;

    if (checkCurrencyValue && tabKey === PaymentTabKeys.INCOME_EXPENSE) {
      if (checkCurrencyValue === PaymentFilterKeys.ALL) {
        return (
          <div className="flex flex-wrap items-center gap-4">
            {statistics?.currencies?.map((item: any) => (
              <div key={item?.id} className="flex items-end gap-1.5">
                <div className={styles.amount__sum}>{item?.statistics?.[key]?.toLocaleString(RU) ?? 0}</div>
                <h5>{item?.symbol}</h5>
              </div>
            ))}
          </div>
        );
      }

      if (checkCurrencyValue !== String(baseCurrency?.id)) {
        return (
          <div className={styles.amount__item}>
            {statistics?.[`second_${key}`]?.toLocaleString(RU) ?? 0}
            <h5>{secondCurrency?.symbol}</h5>
          </div>
        );
      }
    }

    return (
      <div className={styles.amount__item}>
        {statistics?.[key]?.toLocaleString(RU) ?? 0}
        <h5>{currencyData?.symbol}</h5>
      </div>
    );
  };

  return (
    <div className={styles.left}>
      <div className={styles.top}>
        <div className={styles.name}>
          <h2>
            {data[dataKey() as keyof typeof data]?.name
              ? (t(`Counterparts.${data[dataKey() as keyof typeof data]?.name}`) ??
                t(`Counterparts.${data[defaultDataKey as keyof typeof data]?.name}`))
              : t("Counterparts.Kassa")}
          </h2>
          <span>{filterSelect[(tabKey ?? defaultDataKey) as keyof typeof filterSelect]}</span>
        </div>
        <div className={styles.filter}>
          {tabKey === PaymentTabKeys.CALENDAR && <PaymentCalendarSelect />}
          <span>
            <CurrencySegmented />
          </span>
        </div>
      </div>
      <div className={styles.bottom}>
        {data[(dataKey() ?? defaultDataKey) as keyof typeof data]?.label?.map(item => (
          <div className={styles.amount} key={uid()}>
            <p>{t(`Counterparts.${item?.name}`)}</p>
            {amountContent(item?.key)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoLeft;
