import { PartyEstimateForm, PartyEstimateProduct } from "modules/party/estimate";

export function generateCheckProducts(
  products: PartyEstimateForm["products"],
  taskId: number | string,
  qcProducts?: PartyEstimateProduct[]
) {
  const result: PartyEstimateForm["products"] = [...products];

  qcProducts?.forEach(p => {
    result.push({
      ...p,
      taskId
    });
  });

  return result;
}
