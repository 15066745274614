import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { isEmptyArr } from "modules/common";

import { uysotQueryKeys } from "../query-keys";
import { UysotBuilding } from "../schema";

type Query = {
  buildings: UysotBuilding[];
};

export function useUysotBuildings() {
  const initial = {
    buildings: []
  };

  const { data = initial, ...arg } = useQuery<Query>(
    [uysotQueryKeys.BUILDING],
    async () => {
      const { data } = await $api.get("integration/uysot-building/view");

      return { buildings: data?.data };
    },
    {
      select: ({ buildings }) => ({ buildings: buildings.filter(({ buildings }) => !isEmptyArr(buildings)) })
    }
  );

  return { ...data, ...arg };
}
