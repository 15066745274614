export const supplyOrdersConfigs = (lng: string) => ({
  column: [
    {
      id: `product.name.${lng}`,
      name: `product.name.${lng}`,
      title: "Resurs nomi va turi",
      checked: true
    },
    {
      id: "work_name",
      name: "work_name",
      title: "Ish nomi va bo'limi",
      checked: false
    },
    {
      id: `unit.name.${lng}`,
      name: `unit.name.${lng}`,
      title: "Birligi",
      checked: true
    },
    {
      id: "quantity",
      name: "quantity",
      title: "Soni",
      checked: true
    },
    {
      id: "delivery_date",
      name: "delivery_date",
      title: "Kerak bo’lish sanasi",
      checked: true
    },
    {
      id: "created_at",
      name: "created_at",
      title: "Yaratilgan sana",
      checked: false
    },
    {
      id: "warehouse.name",
      name: "warehouse.name",
      title: "Omborxona",
      checked: true
    },
    {
      id: "status",
      name: "status",
      title: "Holati",
      checked: true
    },
    {
      id: "project.name",
      name: "project.name",
      title: "Loyiha",
      checked: true
    },
    {
      id: "creator.full_name",
      name: "creator.full_name",
      title: "Xodim",
      checked: true
    }
  ],
  size: 10
});
