import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import Pagination from "features/app/components/pagination/Pagination";
import { PageKeys } from "features/app/components/statuses/statusEnums";
import Statuses from "features/app/components/statuses/Statuses";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { useTranslation } from "react-i18next";

import { RU } from "modules/common";
import { VatBalanceViewData } from "modules/dashboard";

import { LoadingIndicator } from "components";

type Props = {
  data: PaginationType<VatBalanceViewData[]>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
};

const VatBalanceTable: React.FC<Props> = ({ data, setPage, isLoading }) => {
  const { t } = useTranslation();

  const columns: ColumnsType<VatBalanceViewData> = [
    {
      title: t("dashboard.Partiya"),
      render: (__: any, record) => <span>P-{record?.id}</span>,
      width: 800
    },
    {
      title: t("dashboard.Holati"),
      render: (__: any, record: VatBalanceViewData) => (
        <Statuses statusKey={record?.status as never} pageKey={PageKeys.PARTY} />
      ),
      width: 300
    },
    {
      title: t("dashboard.To'lov summasi"),
      render: (__: any, record: VatBalanceViewData) => (
        <div className="text-sm font-medium text-gray-700">
          {record?.vat_amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </div>
      ),
      width: 300
    },
    {
      title: t("dashboard.Sana"),
      dataIndex: "ordered_date",
      width: 200
    }
  ];

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const footer = () => (
    <div className="p-2">
      <Pagination
        paginationProps={{
          current: data?.current_page,
          total: data?.total,
          onChange: onPageChange
        }}
      />
    </div>
  );

  return (
    <Table
      className="footer_table"
      dataSource={data?.data}
      pagination={false}
      columns={columns}
      footer={footer}
      loading={{
        spinning: isLoading,
        indicator: LoadingIndicator
      }}
      locale={{
        emptyText: <TableEmpty />
      }}
    />
  );
};

export default VatBalanceTable;
