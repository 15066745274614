import { ColumnConfigModel } from "features/app/utils/models/user/userTableConfigModel";

export const generateInventoryExcelReqData = (columns: ColumnConfigModel[]): ColumnConfigModel[] => {
  const updatedColumns = [...columns];
  let i = 0;

  while (i < updatedColumns.length) {
    if (updatedColumns[i].id === "amount") {
      updatedColumns.splice(i + 1, 0, {
        id: "amount_currency.symbol",
        name: "amount_currency.symbol",
        title: "Narx valyutasi",
        checked: true
      });
      i += 1;
    }
    if (updatedColumns[i].id === "service_amount") {
      updatedColumns.splice(i + 1, 0, {
        id: "service_amount.symbol",
        name: "service_amount.symbol",
        title: "Tan narxi valyutasi",
        checked: true
      });
    }
    i += 1;
  }

  return updatedColumns;
};
