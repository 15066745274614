import { WarehouseProduct } from "modules/party/view";

type Vat = {
  amount: number;
  currencyId: number;
  currencySymbol: string;
};

export function totalVats(products: WarehouseProduct[]): Vat[] {
  const total: Vat[] = [];
  const someVat = products?.some(({ vat }) => vat?.id);

  if (someVat) {
    products?.forEach(item => {
      const newTotal = parseFloat((item?.amount * item?.quantity).toFixed(2));
      const findCurrencyIndex = total?.findIndex(i => i?.currencyId === item?.currency?.id);

      if (findCurrencyIndex < 0) {
        total.push({
          amount: newTotal * ((item?.vat?.value || 0) / 100),
          currencyId: item?.currency?.id,
          currencySymbol: item?.currency?.symbol
        });
      } else {
        total[findCurrencyIndex] = {
          ...total[findCurrencyIndex],
          amount: total[findCurrencyIndex]?.amount + newTotal * ((item?.vat?.value || 0) / 100)
        };
      }
    });
  }

  return total;
}
