import React from "react";
import { useDispatch } from "react-redux";
import { Select } from "antd";

import { useAppSelector } from "hooks/redux";

import { ChevronDownIcon, setCurrencyItem, useCurrencies } from "modules/settings/uysot";

type Props = {
  index: number;
};

const UyqurCurrency: React.FC<Props> = ({ index }) => {
  const dispatch = useDispatch();
  const { currencies } = useCurrencies();
  const { currencies: state } = useAppSelector(({ uysot }) => uysot);
  const thisCurrency = state?.[index];

  const onChange = (value: number) => {
    dispatch(
      setCurrencyItem({
        index,
        value,
        key: "currency_id"
      })
    );
  };

  return (
    <Select
      placeholder="UZS"
      onChange={onChange}
      suffixIcon={<ChevronDownIcon />}
      value={thisCurrency?.currency_id}
      disabled={!!thisCurrency?.currency_id}
      labelRender={({ label }) => label || thisCurrency?.ccy}
      options={currencies
        ?.filter(({ id }) => !state.some(({ currency_id }) => id === currency_id))
        .map(({ id, symbol }) => ({
          value: id,
          label: symbol
        }))}
    />
  );
};

export default UyqurCurrency;
