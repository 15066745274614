import React, { useMemo } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { partyReducerActions } from "store/actions";

import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { supplyOfferActions } from "../../../../../store/reducers/supplyOfferReducer";
import { supplyActions } from "../../../../../store/reducers/supplyReducer";
import CopyIcon from "../../../../payment/assets/icons/CopyIcon";
import CalculateIcon from "../../../../supply/assets/icons/CalculateIcon";
import { useDeleteOrders } from "../../../../supply/service/mutations";
import { routeSubmodules } from "../../../../supply/utils/constants/routeSubmodules";
import { OfferModalDataModel } from "../../../../supply/utils/models/OfferModel";
import { ProductModel } from "../../../../supply/utils/models/productModel";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import PlusIcon from "../../../assets/icons/PlusIcon";
import ConditionalRender from "../../conditional-render/ConditionalRender";
import ModalConfirm from "../../modal-confirm/ModalConfirm";

const OrderProductsButtons: React.FC = () => {
  const { t } = useTranslation();
  const { actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const offerActions = actions("Takliflar");
  const partyActions = actions("Partiyalar");
  const ordersActions = actions("Buyurtmalar");

  const deleteOrders = useDeleteOrders();
  const dispatch = useAppDispatch();

  const { setOrderModal, setSelectOrderProducts: setSelectProducts } = supplyActions;
  const { setOfferModal } = supplyOfferActions;
  const { setPartyCreate } = partyReducerActions;

  const { selectOrderProducts: selectProducts } = useAppSelector(state => state.supplyReducer);

  const isClickedRow = useMemo(() => selectProducts?.length > 0, [selectProducts]);

  const onOpenOrderModal = () => {
    dispatch(
      setOrderModal({
        visible: true,
        selectProducts
      })
    );
  };

  const onOpenPartyModal = () => {
    dispatch(
      setPartyCreate({
        isDiscount: false,
        isGroup: false,
        isVat: false,
        type: "supply",
        visible: true
      })
      // setPartyModal({
      //   visible: true,
      //   isEditing: true,
      //   selectProducts
      // })
    );
  };

  const onDeleteProducts = (payload?: number[]) => () => {
    const orderIds = payload || selectProducts?.map(item => item.id);

    return deleteOrders.mutateAsync(orderIds);
  };

  const onAfterDeleteProducts = () => {
    dispatch(setSelectProducts([]));
  };

  const onOpenOfferModal = (record?: ProductModel) => () => {
    dispatch(
      setOfferModal({
        visible: true,
        viewPartySwitch: true,
        data: (record ? [record] : selectProducts) as OfferModalDataModel[]
      })
    );
  };

  return (
    <>
      <ConditionalRender if={ordersActions.delete}>
        <ModalConfirm
          onOk={onDeleteProducts()}
          title={t("app.Buyurtmalarni o'chirish")}
          onAfterClose={onAfterDeleteProducts}
        >
          <Button datatype="delete">
            <DeleteIcon /> {t("app.O'chirish")}
          </Button>
        </ModalConfirm>
      </ConditionalRender>
      <ConditionalRender if={offerActions.create}>
        <Button onClick={onOpenOfferModal()}>
          <CalculateIcon />
          {t("app.Taklif berish")}
        </Button>
      </ConditionalRender>
      <ConditionalRender if={partyActions.create}>
        <Button onClick={onOpenPartyModal}>
          <PlusIcon />
          {t("app.Partiya yaratish")}
        </Button>
      </ConditionalRender>
      <ConditionalRender if={ordersActions.create}>
        <Button onClick={onOpenOrderModal}>
          {isClickedRow ? <CopyIcon /> : <PlusIcon />}
          {isClickedRow ? t("app.Nusxa olish") : t("app.Buyurtma berish")}
        </Button>
      </ConditionalRender>
    </>
  );
};

export default OrderProductsButtons;
