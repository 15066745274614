import React from "react";
import { useDispatch } from "react-redux";
import { Popover, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import Pagination from "features/app/components/pagination/Pagination";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { colors } from "features/app/utils/constants/colors";
import { PaginationType } from "features/app/utils/models/PaginationType";
import DiffPopoverAmount from "features/projects/components/detailed-project/dynamic-estimate/diff-popover/DiffPopoverAmount";
import DiffPopoverQuantity from "features/projects/components/detailed-project/dynamic-estimate/diff-popover/DiffPopoverQuantity";
import DiffPopoverTotalAmount from "features/projects/components/detailed-project/dynamic-estimate/diff-popover/DiffPopoverTotalAmount";
import { WorkProgressModel } from "features/projects/utils/models/WorkProgressModel";
import { useTranslation } from "react-i18next";

import { productSpentWidgetDetailDrawerActions } from "store";

import { useAuth } from "modules/auth/hooks";
import { cx, isEmptyObj, useAmountFixer } from "modules/common";

import styles from "../workModalTabs.module.scss";

type Props = {
  resources: PaginationType<WorkProgressModel[]>;
  tab: string;
  isLoading: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const AllProgressesTable: React.FC<Props> = ({ tab, isLoading, resources, setPage }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const { currencies } = useAuth();
  const baseCurrency = currencies?.find(item => item.type === "base");
  const amountFixer = useAmountFixer;

  const { setOpenDetailDrawer } = productSpentWidgetDetailDrawerActions;
  const columns: ColumnsType<WorkProgressModel> = [
    {
      ...(tab !== "another"
        ? {
            title: t("works.Nomi"),
            render: (record: WorkProgressModel) => (
              <div className="resource">
                <div
                  style={{
                    borderColor: record?.product?.resource?.color,
                    color: record?.product?.resource?.color
                  }}
                >
                  {record?.product?.resource?.symbol[i18n.language]}
                </div>
                {record?.product?.name[i18n.language]?.length > 50 ? (
                  <Popover title={record?.product?.name[i18n.language]} overlayClassName={styles.popover}>
                    <span className={styles.bold}>{record?.product?.name[i18n.language]?.substring(0, 40)}...</span>
                  </Popover>
                ) : (
                  <span className={styles.bold}>{record?.product?.name[i18n.language]}</span>
                )}
              </div>
            ),
            width: 400
          }
        : {
            title: `${t("works.Ish nomi va bo'limi")}`,
            render: (record: WorkProgressModel) => (
              <div className="flex flex-col gap-1">
                <span className="text-sm font-medium text-gray-900">
                  {record?.section?.place}.{record?.place} {record?.name}
                </span>
                <span className="text-xs font-normal text-gray-900">{record?.section?.name}</span>
              </div>
            ),
            width: 400
          })
    },
    {
      title: `${t("works.Birligi")}`,
      render: record => record?.unit?.symbol[i18n.language],
      width: 120,
      align: "center"
    },
    {
      title: (
        <div className="flex flex-col items-center gap-1">
          {t("project.Hajmi")}
          <span className="text-xs font-medium text-gray-500">
            ({t("works.Reja")} | {t("works.Fakt")})
          </span>
        </div>
      ),
      render: (record: WorkProgressModel) => (
        <div className={cx(styles.amounts)}>
          <span className={styles.amounts__item}>
            {amountFixer(String(record?.quantity))} {baseCurrency?.symbol}
          </span>
          <span className={styles.amounts__line} />
          <span className={styles.amounts__item}>
            <DiffPopoverQuantity record={record as never} classNames="text-sm font-medium" isFeature={false} />
            {baseCurrency?.symbol}
          </span>
        </div>
      ),
      width: 350,
      align: "center"
    },
    {
      title: (
        <div className="flex flex-col items-center gap-1">
          {t("works.Birlik summasi")}
          <span className="text-xs font-medium text-gray-500">
            ({t("works.Reja")} | {t("works.Fakt")})
          </span>
        </div>
      ),
      render: (record: WorkProgressModel) => (
        <div className={cx(styles.amounts)}>
          <span className={styles.amounts__item}>
            {amountFixer(String(record?.amount))} {baseCurrency?.symbol}
          </span>
          <span className={styles.amounts__line} />
          <div className={styles.amounts__item}>
            <DiffPopoverAmount record={record as never} classNames="text-sm font-medium" isFeature={false} />
            {baseCurrency?.symbol}
          </div>
        </div>
      ),
      width: 350,
      align: "center"
    },
    {
      title: (
        <div className="flex flex-col items-center gap-1">
          {t("works.Umumiy summasi")}
          <span className="text-xs font-medium text-gray-500">
            ({t("works.Reja")} | {t("works.Fakt")})
          </span>
        </div>
      ),
      render: (record: WorkProgressModel) => (
        <div className={cx(styles.amounts)}>
          <span className={styles.amounts__item}>
            {amountFixer(String(record?.total_amount))} {baseCurrency?.symbol}
          </span>
          <span className={styles.amounts__line} />

          <div className={styles.amounts__item}>
            <DiffPopoverTotalAmount record={record as never} classNames="text-sm font-medium" isFeature={false} />{" "}
            {baseCurrency?.symbol}
          </div>
        </div>
      ),
      width: 350,
      align: "center"
    },
    {
      ...(tab === "another"
        ? {
            title: `${t("works.Loyiha")}`,
            render: (record: WorkProgressModel) => (
              <div className="text-sm font-normal text-gray-800">{record?.section?.project?.name}</div>
            ),
            width: 250,
            align: "center"
          }
        : {})
    },
    {
      title: "",
      render: (record: WorkProgressModel) => (
        <div className={styles.arrow}>
          <ArrowNarrowIcon rotate={180} stroke={colors.GRAY_500} />
        </div>
      ),
      width: 50
    }
  ];

  const onRow = (record: WorkProgressModel) => ({
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();

      dispatch(
        setOpenDetailDrawer({
          visible: true,
          props: { type: tab, product: record?.product },
          title: "Tarix",
          id: record.id
        })
      );
    }
  });

  const rowKey = (record: WorkProgressModel) => record.id;

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const footer = () => (
    <div className="p-2">
      <Pagination
        paginationProps={{
          current: resources?.current_page,
          total: resources?.total,
          onChange: onPageChange
        }}
      />
    </div>
  );

  return (
    <Table<WorkProgressModel>
      bordered
      dataSource={resources?.data}
      columns={columns?.filter(el => !isEmptyObj(el))}
      pagination={false}
      rowKey={rowKey}
      rowClassName={() => styles.row}
      onRow={onRow}
      locale={{
        emptyText: <TableEmpty />
      }}
      footer={footer}
      loading={{ spinning: isLoading, indicator: LoadingIndicator }}
      className={cx(styles.table, "footer_table")}
      rootClassName={cx(styles.root_table)}
      scroll={{ y: 410 }}
    />
  );
};

export default AllProgressesTable;
