import React from "react";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  UseFormReturn
} from "react-hook-form";
import { Select } from "antd";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";

import { PartyForm } from "modules/party/create";
import { useCompanyPersons } from "modules/party/view";

type Props = { form: UseFormReturn<PartyForm> } & (
  | {
      type: "initial";
    }
  | {
      type: "group";
      parentIndex: number;
      remove: UseFieldArrayRemove;
      append: UseFieldArrayAppend<PartyForm, "company_person_group">;
      update: UseFieldArrayUpdate<PartyForm, "company_person_group">;
      fields: FieldArrayWithId<PartyForm, "company_person_group", "group_id">[];
      appendProduct: UseFieldArrayAppend<PartyForm, `company_person_group.${number}.products`>;
    }
);

const Index: React.FC<Props> = props => {
  const { type, form } = props;
  const { companyPersons, isLoading } = useCompanyPersons();

  const onChange = (e: number, option: any) => {
    const company_person_name = option?.props?.name || "";

    if (type === "initial") {
      const oldValue = form.getValues("warehouse_products");

      form.setValue(
        "warehouse_products",
        oldValue?.map(item => ({ ...item, company_person_id: e, company_person_name }))
      );
    }

    if (props.type === "group") {
      const oldValue = form.getValues(`company_person_group.${props.parentIndex}`);
      const updateGroupIndex = props?.fields?.findIndex(({ id }) => id === e);

      if (updateGroupIndex > -1) {
        props.update(updateGroupIndex, {
          id: e,
          name: company_person_name,
          products: [
            ...(props.fields?.[updateGroupIndex]?.products || []),
            ...(oldValue.products?.map(product => ({
              ...product,
              company_person_id: e,
              company_person_name
            })) || [])
          ]
        });

        props.remove(props.parentIndex);
      } else {
        // ✅
        props.remove(props.parentIndex);

        props.append({
          id: e,
          name: company_person_name,
          products: [
            ...(oldValue.products?.map(product => ({
              ...product,
              company_person_id: e,
              company_person_name
            })) || [])
          ]
        });
      }
    }
  };

  return (
    <Select
      loading={isLoading}
      onChange={onChange}
      placeholder="Ta'minotchi"
      popupMatchSelectWidth={false}
      labelRender={() => "Ta'minotchi"}
      suffixIcon={<ChevronDownIcon rotate width={18} />}
      options={companyPersons?.map(person => ({
        key: person?.id,
        value: person?.id,
        label: person?.name,
        props: {
          name: person?.name
        }
      }))}
    />
  );
};

export default Index;
