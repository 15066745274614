import React from "react";

const FolderIcon: React.FC<{ fill?: string }> = ({ fill = "#53B1FD" }) => (
  <svg width="62" height="48" viewBox="0 0 62 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.882812 3.59508V44.3465C0.882812 46.0883 2.29476 47.5002 4.03649 47.5002H57.9644C59.7062 47.5002 61.1181 46.0883 61.1181 44.3465V10.0265C61.1181 8.28473 59.7062 6.87278 57.9644 6.87278H29.6596C28.4336 6.87278 27.3231 6.1572 26.6818 5.11226C25.5131 3.20815 23.4057 0.441406 21.0269 0.441406H4.03156C2.28983 0.441406 0.882812 1.85335 0.882812 3.59508Z"
      fill={fill}
    />
  </svg>
);

export default FolderIcon;
