import { ColumnsType } from "antd/es/table";
import i18next from "i18next";

import { PaymentOrderModel } from "../../../payment/utils/models/paymentOrderModel";
import CustomColumnRender from "../../components/cutom-column-render/CustomColumnRender";
import OrderSortByField from "../../components/order-sort-by/OrderSortByField";
import { customFieldColumnSize } from "../constants/customFieldColumnSize";
import { queryParamsKeys } from "../constants/queryParamsKeys";
import { CustomFieldSelectModel } from "../models/customFieldSelectModel";
import { ColumnConfigModel, UserTableDataModel } from "../models/user/userTableConfigModel";

export const filterColumns = ({
  columns,
  tableConfig,
  customFields
}: {
  columns?: ColumnsType;
  tableConfig?: UserTableDataModel;
  customFields?: CustomFieldSelectModel[];
}): ColumnConfigModel[] => {
  const resultFC = (data?: ColumnsType) => {
    if (data) {
      return data?.reduce(
        (p, c) => {
          let place: undefined | number = 0;
          let width = 250;

          const isTheSameClass = tableConfig?.width_data?.find(value => value?.className === c.className);

          if (isTheSameClass) {
            width = isTheSameClass.width;
          }
          const checked = tableConfig?.column?.some(value => {
            if (value?.id === c?.key && value?.checked) {
              place = value?.place;
            }
            return !c?.key || (value?.id === c?.key && value?.checked);
          });

          if (checked) {
            if (isTheSameClass) {
              p.push({ ...c, place, width } as any);
            } else {
              p.push({ ...c, place } as any);
            }
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return p?.sort((a: any, b: any) => a?.place - b?.place);
        },
        [] as typeof columns & { place?: number }[]
      );
    }
    return (
      tableConfig?.column
        ?.filter(item => item?.checked)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ?.sort((a: any, b: any) => a?.place - b?.place) || []
    );
  };

  if (customFields) {
    const customFieldsCopy: ColumnsType = customFields?.map(customField => ({
      title: (
        <OrderSortByField
          queryParamKey={queryParamsKeys.SORT_CUSTOM_FIELDS}
          title={customField?.name[i18next.language]}
          fieldId={customField?.id}
        />
      ),
      render: (__: any, record: PaymentOrderModel) => <CustomColumnRender customField={customField} record={record} />,
      width: customFieldColumnSize[customField?.type],
      key: customField?.id,
      id: customField?.id,
      className: customField?.name[i18next.language]
    }));

    // @ts-expect-error
    return [...resultFC(columns), ...resultFC(customFieldsCopy)];
    // .reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // (p: any, c: any) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   p.some((item: any) => (item?.id || item?.key) === (c?.id || c?.key))
    //     ? p
    //     : [...p, c],
    // []
    // );
  }

  // @ts-expect-error
  return resultFC(columns);
};
