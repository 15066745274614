import React, { useEffect } from "react";
import { UseFieldArrayAppend, UseFormReturn, useWatch } from "react-hook-form";
import { Spin, Tabs, TabsProps } from "antd";

import { useAppSelector } from "hooks/redux";

import { isEmptyArr } from "modules/common";
import { getPopupContainer, PartyForm } from "modules/party/create";
import { PartyEstimateForm, usePartyProjects } from "modules/party/estimate";

import Children from "pages/party/estimate/content/children";
import Footer from "pages/party/estimate/content/footer";

import { ConditionalRender, DotsVerticalIcon, EmptyContent, LoadingIndicator } from "components";

import styles from "./index.module.scss";

type Props = {
  isUpdate?: boolean;
  parentForm: UseFormReturn<PartyForm>;
  form: UseFormReturn<PartyEstimateForm>;
  append: UseFieldArrayAppend<PartyForm, "warehouse_products">;
  appendCheck: UseFieldArrayAppend<PartyForm, "check_products">;
  groupAppend: UseFieldArrayAppend<PartyForm, "company_person_group">;
};

const Index: React.FC<Props> = ({ appendCheck, append, form, isUpdate, groupAppend, parentForm }) => {
  const { visible: enabled } = useAppSelector(({ partyReducer }) => partyReducer.estimate);
  const { projects, isLoading } = usePartyProjects({ enabled, form });
  const { project_id } = useWatch({ control: form.control });

  const items: TabsProps["items"] = projects?.map(project => ({
    key: String(project?.id),
    label: (
      <div className="flex items-center gap-2">
        <span>{project?.name}</span>
        <ConditionalRender if={project?.task_products_count}>
          <div className="flex items-center rounded-2xl bg-gray-100 px-2 py-0.5">{project?.task_products_count}</div>
        </ConditionalRender>
      </div>
    ),
    children: <Children form={form} currencySymbol={project?.currency?.symbol} />
  }));

  const onChange: TabsProps["onChange"] = (e: string) => {
    const currency = projects?.find(project => project?.id === Number(e))?.currency;

    form.setValue("project_id", e);
    form.setValue("currency_id", currency?.id || 0);
    form.setValue("currency_symbol", currency?.symbol || "");
  };

  useEffect(() => {
    form.reset({
      products: [],
      project_id: 0,
      currency_id: 0,
      currency_symbol: ""
    });
  }, [enabled]);

  return (
    <div id={isUpdate ? "estimate_update" : "estimate"} className="flex h-full flex-col">
      <div className="flex-1">
        <ConditionalRender
          if={!isEmptyArr(projects)}
          else={
            <div className="flex h-full flex-1 items-center justify-center">
              <Spin spinning={isLoading} indicator={LoadingIndicator}>
                <EmptyContent />
              </Spin>
            </div>
          }
        >
          <Spin spinning={isLoading} indicator={LoadingIndicator}>
            <Tabs
              items={items}
              onChange={onChange}
              rootClassName={styles.tabs}
              activeKey={String(project_id)}
              more={{
                trigger: ["click"],
                placement: "bottomLeft",
                getPopupContainer: getPopupContainer(isUpdate ? "estimate_update" : "estimate")
              }}
              moreIcon={<DotsVerticalIcon />}
            />
            <ConditionalRender if={!project_id}>
              <Spin spinning indicator={LoadingIndicator}>
                <div className="box-border flex items-center justify-center p-4">
                  <EmptyContent />
                </div>
              </Spin>
            </ConditionalRender>
          </Spin>
        </ConditionalRender>
      </div>
      <Footer form={form} groupAppend={groupAppend} parentForm={parentForm} append={append} appendCheck={appendCheck} />
    </div>
  );
};

export default Index;
