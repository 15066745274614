import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { UseMutationResult } from "@tanstack/react-query";
import { Button } from "antd";
import CustomFieldIcon from "features/app/assets/icons/CustomFieldIcon";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { CustomFieldTypesEnum } from "features/app/utils/enums/customFieldTypesEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { generateExcelPayload } from "features/app/utils/helpers/generateExcelPayload";
import { inventoryExportExcelTableDefaultDataFunc } from "features/inventory/utils/constants/inventoryTableDefaultData";
import { generateInventoryExcelReqData } from "features/inventory/utils/helpers/generateInventoryExcelReqData";
import ArrowSquareDownIcon from "features/settings/assets/icons/ArrowSquareDownIcon";
import TagIcon from "features/settings/assets/icons/TagIcon";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { colors } from "modules/common";

import { ConditionalRender } from "components";

import FilterSelect from "../../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FilterDrawer from "../../../../../app/components/filter-drawer/FilterDrawer";
import FiltersCount from "../../../../../app/components/filter-drawer/filters-count/FiltersCount";
import { useGetCompanyPersons, useGetCustomFieldSelect, useGetTableConfigs } from "../../../../../app/service/queries";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import UsersIcon from "../../../../../payment/assets/icons/UsersIcon";
import ZapIcon from "../../../../../projects/assets/icons/ZapIcon";
import FilterLinesIcon from "../../../../../warehouse/assets/icons/FilterLinesIcon";

import styles from "./filterDrawer.module.scss";

const queryStringArr = [queryParamsKeys.COMPANY_PERSON_IDS, queryParamsKeys.WORK_STATUS, queryParamsKeys.CUSTOM_FIELDS];

type Props = {
  tableKey?: tableConfigKeys;
  customFieldEnum?: CustomFieldLocationEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportExcelMutate?: UseMutationResult<any, unknown, any, unknown>;
};

const FilterDrawerInventories: React.FC<Props> = ({ exportExcelMutate, customFieldEnum, tableKey }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { necessaryQueries } = useQueryParams();
  const [open, setOpen] = useState(false);
  const [companyPersonsSelected, setCompanyPersonsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.COMPANY_PERSON_IDS] as string[] | null
  );

  const tableConfigData = inventoryExportExcelTableDefaultDataFunc(i18n.language);
  const { data: customFields } = useGetCustomFieldSelect(customFieldEnum ? [customFieldEnum] : []);
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.INVENTORY, tableConfigData);
  const [customFieldStates, setCustomFieldStates] = useState<{ [key: string]: string[] | null }>({});

  const selectableCustomFields = customFields?.filter(
    customField => customField?.type === "select" || customField?.type === "label"
  );

  const handleCustomFieldChange = (id: string, value: string[] | null) => {
    setCustomFieldStates(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const aggregatedCustomFieldsSelected = useMemo(() => Object.values(customFieldStates).flat(), [customFieldStates]);

  const [inventoryStatusSelected, setInventoryStatusSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.WORK_STATUS] as string[] | null
  );
  const { data: counterpartsSelect } = useGetCompanyPersons();

  const counterparts = useMemo(() => counterpartsSelect?.map(item => ({ ...item })), [counterpartsSelect]);

  const inventoryStatusData = useMemo(
    () => [
      {
        id: "working",
        name: `${t("Inventar.Ishlanmoqda")}`
      },
      {
        id: "not_working",
        name: `${t("Inventar.Bosh")}`
      },
      {
        id: "null",
        name: `${t("Inventar.Hammasi")}`
      }
    ],
    []
  );

  const onOpenFilter = () => {
    setOpen(true);
  };

  const handleExcelExport = () => {
    const filteredColumns = generateInventoryExcelReqData(
      filterColumns({
        customFields,
        tableConfig
      })
    );

    const data = filteredColumns
      ?.map(value => ({
        key: String(value?.id),
        name: value?.title
      }))
      ?.filter(item => typeof item?.name !== "object");

    const otherParamsData = necessaryQueries(queryParamsKeys.SEARCH);

    const reqBody = {
      ...otherParamsData,
      columns: data,
      company_person_ids: companyPersonsSelected,
      work_status: inventoryStatusSelected
    };

    if (data) {
      exportExcelMutate?.mutate(generateExcelPayload(reqBody));
    }
  };

  const footerContent = (
    <Button onClick={handleExcelExport} className={styles.downloadBtn} loading={exportExcelMutate?.isLoading}>
      <DownloadIcon /> {t("Inventory.Yuklab olish")}
    </Button>
  );

  const customFieldIcons: Record<string, React.ReactNode> = {
    [CustomFieldTypesEnum.SELECT]: <ArrowSquareDownIcon />,
    [CustomFieldTypesEnum.LABEL]: <TagIcon />
  };

  const searchParams = queryString.parse(location.search, { arrayFormat: "bracket" });
  const fieldValues = searchParams[queryParamsKeys.CUSTOM_FIELDS];

  useEffect(() => {
    if (selectableCustomFields && open) {
      const initialCustomFieldStates: { [key: string]: string[] | null } = {};

      selectableCustomFields.forEach(field => {
        if (fieldValues) {
          const valuesArray = Array.isArray(fieldValues) ? fieldValues : [fieldValues];

          initialCustomFieldStates[field.id] = valuesArray.filter(value =>
            field?.custom_field_options?.some(option => String(option.id) === value)
          ) as string[];
        } else {
          initialCustomFieldStates[field.id] = [];
        }
      });

      setCustomFieldStates(initialCustomFieldStates);
    }
  }, [open]);

  return (
    <FilterDrawer
      open={open}
      setOpen={setOpen}
      filterButton={
        <Button className={styles.filter} onClick={onOpenFilter}>
          <FilterLinesIcon /> {t("Monitoring.Filter")}
          <FiltersCount queryStringArr={queryStringArr} />
        </Button>
      }
      footerContent={footerContent}
      height="max-content"
      queryStringArr={queryStringArr}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.COMPANY_PERSON_IDS,
          selectedOptions: companyPersonsSelected
        },
        {
          queryKey: queryParamsKeys.WORK_STATUS,
          selectedOptions: inventoryStatusSelected
        },
        {
          queryKey: queryParamsKeys.CUSTOM_FIELDS,
          selectedOptions: aggregatedCustomFieldsSelected as string[]
        }
      ]}
      setSelectedOptionsArr={[setCompanyPersonsSelected, setInventoryStatusSelected, setCustomFieldStates] as never}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 text-base font-medium text-gray-800">
            <FilterLinesIcon stroke={colors.GRAY_800} /> {t("inventory.Umumiy filterlar")}
          </div>
          <div className={styles.content}>
            <FilterSelect
              selectedOptions={companyPersonsSelected}
              setSelectedOptions={setCompanyPersonsSelected}
              data={counterparts}
              multiple="multiple"
              icon={<UsersIcon />}
              placeholder={t("MainLayoutLinks.Kontragent")}
              showSearch
            />
            <FilterSelect
              selectedOptions={inventoryStatusSelected}
              setSelectedOptions={setInventoryStatusSelected}
              data={inventoryStatusData}
              // multiple="tags"
              icon={<ZapIcon />}
              placeholder={t("Xodimlar.Holati")}
              // showSearch
            />
          </div>
        </div>
        <ConditionalRender if={selectableCustomFields?.length && selectableCustomFields?.length > 0}>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 text-base font-medium text-gray-800">
              <CustomFieldIcon stroke={colors.GRAY_800} width={20} /> {t("inventory.O'zgaruvchi filterlar")}
            </div>
            <div className={styles.content}>
              {selectableCustomFields?.map(el => (
                <FilterSelect
                  key={el.id}
                  selectedOptions={customFieldStates[el.id]}
                  setSelectedOptions={value => handleCustomFieldChange(String(el.id), value)}
                  data={el.custom_field_options as never}
                  multiple="multiple"
                  icon={customFieldIcons[el.type]}
                  placeholder={el.name[i18n.language]}
                  showSearch
                />
              ))}
            </div>
          </div>
        </ConditionalRender>
      </div>
    </FilterDrawer>
  );
};

export default FilterDrawerInventories;
