import React from "react";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";

type Props = {
  children: React.ReactNode;
  quantity: number;
  done_quantity: number;
  unit_symbol: string;
};

const WorkQuantity: React.FC<Props> = ({ children, done_quantity, quantity, unit_symbol }) => {
  const { t } = useTranslation();

  const content = (
    <div className="flex w-[320px] flex-col gap-5 rounded-lg border border-white bg-white px-3 py-2">
      <div className="flex items-center justify-between">
        <span>{t("works.Ish hajmi")}</span>
        <div className="flex items-center gap-3">
          <span className="text-sm font-medium text-gray-700">{done_quantity}</span>
          <span className="text-sm font-medium text-gray-400">{unit_symbol}</span>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <span>{t("works.Bajarilgan ish hajmi")}</span>
        <div className="flex items-center gap-3">
          <span className="text-sm font-medium text-gray-700">{quantity}</span>
          <span className="text-sm font-medium text-gray-400">{unit_symbol}</span>
        </div>
      </div>
    </div>
  );

  return (
    <Popover arrow={false} content={content}>
      {children}
    </Popover>
  );
};

export default WorkQuantity;
