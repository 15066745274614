import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { uysotQueryKeys } from "../query-keys";
import { UysotPaymentType } from "../schema";

type Query = {
  paymentTypes: UysotPaymentType[];
};

export function useUysotPaymentTypes() {
  const initial = {
    paymentTypes: []
  };

  const { data = initial, ...arg } = useQuery<Query>([uysotQueryKeys.PAYMENT_TYPE], async () => {
    const { data } = await $api.get("integration/uysot-payment-type/view");

    return { paymentTypes: data?.data };
  });

  return { ...data, ...arg };
}
