import React, { forwardRef } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { partyQueryKeys, PartyView } from "modules/party/view";

import Card from "./card";
import Table from "./table";

import styles from "./index.module.scss";

const Index = forwardRef<HTMLDivElement | null, { id?: number }>(({ id }, ref) => {
  const qc = useQueryClient();
  const data = qc.getQueryData<{ party: PartyView }>([partyQueryKeys.VIEW, id]);

  return (
    <div ref={ref}>
      <div className={styles.print}>
        <div className="text-center text-base font-bold text-black">P -{id}</div>
        <div className="flex flex-col gap-12">
          <Table products={data?.party?.warehouse_products} />
          <Card
            id={id}
            agent={data?.party?.agent}
            paymentDate={data?.party?.payment_date}
            deliveryDate={data?.party?.delivery_date}
          />
        </div>
      </div>
    </div>
  );
});

export default Index;
