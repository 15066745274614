import React from "react";
import { Table } from "antd";
import { FormInstance } from "antd/lib";
import { ColumnsType } from "antd/lib/table";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import TableSettings from "features/app/components/table-settings/TableSettings";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { RU } from "features/app/utils/constants/languages";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { PaginationType } from "features/app/utils/models/PaginationType";
import QuantityInput from "features/warehouse/components/transfers/transfer-making-drawer/selected-products-table/QuantityInput";
import { transferMakingDrawerTableDefaultData } from "features/warehouse/utils/constants/transferMakingDrawerTableDefaultData";
import { TransferBodyModel } from "features/warehouse/utils/models/transferBodyModel";
import { WarehouseProductModel } from "features/warehouse/utils/models/WarehouseProductModel";
import { useTranslation } from "react-i18next";

import { cx } from "modules/common";

import styles from "./selectedProductsTable.module.scss";

type Props = {
  isLoading: boolean;
  data: PaginationType<WarehouseProductModel[]> | undefined;
  form: FormInstance<TransferBodyModel>;
};
const CreateTable: React.FC<Props> = ({ data, isLoading, form }) => {
  const { i18n, t } = useTranslation();
  const { data: tableConfigData } = useGetTableConfigs(
    tableConfigKeys.TRANSFER_MAKING_TABLE,
    transferMakingDrawerTableDefaultData
  );
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.TRANSFER_MAKING_TABLE]);

  const columns: ColumnsType<WarehouseProductModel> = [
    {
      title: t("warehouse.Resurs turi va nomi"),
      render: (record: WarehouseProductModel) => (
        <div className={cx("resource")}>
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          <span>
            <PopoverShortName title={record?.product?.name[i18n.language]} length={65} />
          </span>
        </div>
      ),
      width: "45rem",
      key: "resource_name_and_type"
    },
    {
      title: t("warehouse.Miqdori"),
      render: (record: WarehouseProductModel) => record?.total_quantity?.toLocaleString(RU),
      align: "center",
      key: "total_quantity",
      width: "5.625rem"
    },
    {
      title: t("warehouse.O'tkazma miqdori"),
      render: (_, record) => (
        <QuantityInput<"create">
          key={`${record?.product?.id}/${record?.unit?.id}`}
          form={form}
          record={record}
          uniqueId={`${record?.product?.id}-${record?.unit?.id}`}
          action="create"
        />
      ),
      align: "center",
      key: "transfer_quantity",
      width: "9.25rem"
    },
    {
      title: t("warehouse.Birligi"),
      render: (record: WarehouseProductModel) => record?.unit?.symbol[i18n.language],
      align: "center",
      key: "unit.symbol",
      width: "8.125rem"
    }
  ];

  const tableColumns = () => [
    ...filterColumns({
      columns: columns as never,
      tableConfig: tableConfigData,
      customFields
    }),
    {
      title: (
        <TableSettings
          isNeedSize={true}
          configKey={tableConfigKeys.TRANSFER_MAKING_TABLE}
          defaultData={transferMakingDrawerTableDefaultData}
          locations={[CustomFieldLocationEnum.TRANSFER_MAKING_TABLE]}
        />
      ),
      width: "3.25rem"
    }
  ];

  return (
    <Table<WarehouseProductModel>
      pagination={false}
      columns={tableColumns()}
      dataSource={data?.data || []}
      // scroll={{ y: "64.6vh", x: undefined }}
      className={cx("footer_table", styles.table)}
      locale={{
        emptyText: <TableEmpty />
      }}
      loading={{
        spinning: isLoading,
        indicator: LoadingIndicator
      }}
    />
  );
};

export default CreateTable;
