import React from "react";
import { UseFormReturn } from "react-hook-form";

import { getPopupContainer, PartyForm } from "modules/party/create";
import { useCurrencies } from "modules/party/view";

import { Select } from "components";

type Props = {
  index: number;
  isUpdate?: boolean;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, index, isUpdate }) => {
  const { currencies, isLoading } = useCurrencies();

  const onChange = (e: number, option: any) => {
    form.setValue(`warehouse_products.${index}.currency_id`, e);
    form.setValue(`warehouse_products.${index}.currency_symbol`, option.props?.name);
  };

  return (
    <Select
      withoutMessage
      suffixIcon={null}
      loading={isLoading}
      onChange={onChange}
      placeholder="Valyuta"
      control={form.control}
      wrapperClassName="h-full"
      popupMatchSelectWidth={false}
      errorClassName="party-create-error"
      name={`warehouse_products.${index}.currency_id`}
      getPopupContainer={getPopupContainer(isUpdate ? "party-left-content-update" : "party-left-content")}
      options={currencies?.map(currency => ({
        key: currency?.id,
        value: currency?.id,
        label: currency?.symbol,
        props: {
          name: currency?.symbol
        }
      }))}
    />
  );
};

export default Index;
