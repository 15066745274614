import React from "react";

interface IProps {
  size?: number;
  stroke?: string;
}

const OrderedIcon: React.FC<IProps> = ({ size = 16, stroke = "#7A5AF8" }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.33398 4.66602H10.8922C11.0552 4.66602 11.1367 4.66602 11.2135 4.68444C11.2815 4.70077 11.3465 4.7277 11.4062 4.76426C11.4735 4.80548 11.5311 4.86313 11.6464 4.97843L14.3549 7.68693C14.4702 7.80223 14.5279 7.85988 14.5691 7.92716C14.6056 7.98681 14.6326 8.05184 14.6489 8.11986C14.6673 8.19659 14.6673 8.27812 14.6673 8.44118V10.3327C14.6673 10.6433 14.6673 10.7986 14.6166 10.9211C14.5489 11.0845 14.4191 11.2143 14.2558 11.2819C14.1333 11.3327 13.9779 11.3327 13.6673 11.3327M10.334 11.3327H9.33398M9.33398 11.3327V4.79935C9.33398 4.05261 9.33398 3.67924 9.18866 3.39403C9.06083 3.14315 8.85685 2.93917 8.60597 2.81134C8.32076 2.66602 7.94739 2.66602 7.20065 2.66602H3.46732C2.72058 2.66602 2.34721 2.66602 2.062 2.81134C1.81111 2.93917 1.60714 3.14315 1.47931 3.39403C1.33398 3.67924 1.33398 4.05261 1.33398 4.79935V9.99935C1.33398 10.7357 1.93094 11.3327 2.66732 11.3327M9.33398 11.3327H6.66732M6.66732 11.3327C6.66732 12.4373 5.77189 13.3327 4.66732 13.3327C3.56275 13.3327 2.66732 12.4373 2.66732 11.3327M6.66732 11.3327C6.66732 10.2281 5.77189 9.33268 4.66732 9.33268C3.56275 9.33268 2.66732 10.2281 2.66732 11.3327M13.6673 11.666C13.6673 12.5865 12.9211 13.3327 12.0007 13.3327C11.0802 13.3327 10.334 12.5865 10.334 11.666C10.334 10.7455 11.0802 9.99935 12.0007 9.99935C12.9211 9.99935 13.6673 10.7455 13.6673 11.666Z"
      stroke={stroke}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default OrderedIcon;
