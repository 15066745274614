import React from "react";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { cx, isEmptyArr, RU } from "modules/common";
import { totalAmount, totalUnits, totalVats, WarehouseProduct } from "modules/party/view";

import Line from "pages/party/view/content/right/line";

import { ConditionalRender } from "components";

type Props = {
  products: WarehouseProduct[];
};

const TotalInfo: React.FC<Props> = ({ products }) => {
  const { t } = useTranslation();
  const vats = totalVats(products);
  const units = totalUnits(products);
  const total = totalAmount(products);
  const { type } = useAppSelector(({ partyReducer }) => partyReducer.view);

  const generateTotalAmount = ({ amount, currencyId }: (typeof total)[number]) => {
    const vat = vats?.find(v => v?.currencyId === currencyId);

    if (vat) {
      return (amount - (vat?.amount || 0))?.toLocaleString(RU);
    }

    return amount?.toLocaleString(RU);
  };

  return (
    <div className="box-border flex flex-col gap-3 overflow-x-hidden rounded-xl bg-gray-100 p-4">
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium text-gray-600">{t("partyView.Resurs")}:</div>
        <Popover
          arrow={false}
          placement="left"
          trigger={["hover"]}
          content={units?.map(([symbol, quantity], index) => `${quantity}  ${symbol} ${units[index + 1] ? "," : ""} `)}
        >
          <div className="flex cursor-pointer items-center gap-1 text-sm font-medium">
            <span className="text-gray-700">{products?.length || 0}</span>
            <span className="text-gray-400">ta</span>
          </div>
        </Popover>
      </div>
      <ConditionalRender if={!isEmptyArr(vats)}>
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-700">{t("partyView.Summa")}:</div>
          <div className="flex flex-col items-end justify-end gap-1.5 text-sm font-medium">
            {total?.map(({ currencySymbol, currencyId, amount }) => (
              <div key={currencyId} className="flex items-center gap-2 text-sm font-medium">
                <span className="text-gray-700">{generateTotalAmount({ currencyId, amount } as never) || 0}</span>
                <span className="text-gray-400">{currencySymbol}</span>
              </div>
            ))}
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender if={!isEmptyArr(vats)}>
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-600">{t("partyView.NDS")}:</div>
          <div className="flex flex-col items-end justify-end gap-1.5">
            {vats?.map(vat => (
              <div key={vat?.currencyId} className="flex items-center gap-1 text-sm font-medium">
                <span className="text-gray-700">{vat?.amount?.toLocaleString(RU) || 0}</span>
                <span className="text-gray-400">{vat?.currencySymbol}</span>
              </div>
            ))}
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender if={type !== "warehouse"}>
        <div className="relative flex items-center justify-between">
          <div className="absolute left-[-24px] h-4 w-4 rounded-full bg-white" />
          <Line />
          <div className="absolute right-[-24px] h-4 w-4 rounded-full bg-white" />
        </div>
        <div className={cx("flex justify-between", { "items-center": total?.length === 1 })}>
          <div className="text-base font-semibold text-gray-700">{t("partyView.Jami")}:</div>
          <div className="flex flex-col items-end justify-end gap-1 text-sm font-medium">
            {total?.map(price => (
              <div key={price?.currencyId} className="flex items-center gap-2 text-lg font-semibold">
                <span className="text-gray-700">{price?.amount?.toLocaleString(RU) || 0}</span>
                <span className="text-gray-700">{price?.currencySymbol}</span>
              </div>
            ))}
          </div>
        </div>
      </ConditionalRender>
    </div>
  );
};

export default TotalInfo;
