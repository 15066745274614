import { z } from "zod";

import { currencySchema, idAndNameSchema } from "modules/common";

export const uysotStatus = z.enum(["active", "passive"]);

export const paymentTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
  color: z.string()
});

export const uysotCurrency = z.object({
  ccy: z.string(),
  currency: currencySchema.nullable(),
  status: uysotStatus
});

export const uysotPaymentType = z.object({
  type: z.string(),
  name: z.record(z.string()),
  payment_type: paymentTypeSchema.nullable(),
  status: uysotStatus
});

export const uysotBuilding = z.object({
  id: z.number(),
  name: z.string(),
  buildings: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      status: uysotStatus,
      project: idAndNameSchema,
      cash: idAndNameSchema
    })
  )
});

export const uysotCurrencyPayload = z.object({
  currencies: z.array(
    z.object({
      currency_id: z.number().optional(),
      ccy: z.string(),
      status: z.enum(["active", "passive"])
    })
  )
});

export const uysotPaymentTypePayload = z.object({
  payment_types: z.array(
    z.object({
      type: z.string(),
      payment_type_id: z.number().optional(),
      status: z.enum(["active", "passive"])
    })
  )
});

export const uysotUpdatePayload = z.object({
  id: z.number(),
  start_date: z.string().nullable()
});

export const uysotBuildingPayload = z.object({
  buildings: z.array(
    z.object({
      cash_id: z.number().optional(),
      project_id: z.number().optional(),
      building_id: z.number(),
      status: uysotStatus
    })
  )
});

export type UysotStatus = z.infer<typeof uysotStatus>;
export type UysotCurrency = z.infer<typeof uysotCurrency>;
export type UysotBuilding = z.infer<typeof uysotBuilding>;
export type PaymentType = z.infer<typeof paymentTypeSchema>;
export type UysotPaymentType = z.infer<typeof uysotPaymentType>;
export type UysotUpdatePayload = z.infer<typeof uysotUpdatePayload>;
export type UysotCurrencyPayload = z.infer<typeof uysotCurrencyPayload>;
export type UysotBuildingPayload = z.infer<typeof uysotBuildingPayload>;
export type UysotPaymentTypePayload = z.infer<typeof uysotPaymentTypePayload>;

export type UysotReducer = {
  id: number;
  step: number;
  visible: boolean;
  start_date: string | null;
  currencies: {
    ccy: string;
    currency_id?: number;
    status: "active" | "passive";
  }[];
  buildings: {
    name?: string;
    cash_id?: number;
    cash_name?: string;
    project_id?: number;
    building_id: number;
    project_name?: string;
    status: "active" | "passive";
  }[];
  checked_buildings: {
    name?: string;
    cash_id?: number;
    project_id?: number;
    building_id: number;
    status: "active" | "passive";
  }[];
  payment_types: {
    type: string;
    payment_type_id?: number;
    status: "active" | "passive";
  }[];
};