import React, { ReactNode, useMemo } from "react";
import { Dropdown, Spin } from "antd";
import { ItemType } from "antd/es/menu/interface";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { cx } from "modules/common";
import { PartyView, PaymentType as IPaymentType, useCreatePaymentType, usePaymentTypes } from "modules/party/view";

import { LoadingIndicator } from "components";

type Props = {
  id?: number;
  personPaymentTypes: PartyView["company_person_payment_types"];
};

const PaymentType: React.FC<Props> = ({ personPaymentTypes, id }) => {
  const { t } = useTranslation();
  const { isGroup, party_id } = useAppSelector(({ partyReducer }) => partyReducer.view);
  const { paymentTypes } = usePaymentTypes({ enabled: !!isGroup });
  const create = useCreatePaymentType();

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const value = useMemo(
    () => personPaymentTypes?.find(({ company_person_id }) => company_person_id === id)?.payment_type,
    [personPaymentTypes, id]
  );

  const dropdownRender = (originNode: ReactNode) => (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {originNode}
    </div>
  );

  const onChangePayment = (e: number, paymentType: IPaymentType) => () => {
    create.mutate({
      payment_type_id: e === value?.id ? undefined : e,
      company_person_id: id || 0,
      order_id: party_id || 0,
      thisPaymentType: paymentType
    });
  };

  const items: ItemType[] =
    paymentTypes?.map(item => ({
      key: item?.id,
      label: (
        <div
          style={{
            background: `${item?.color}20`,
            color: item?.color
          }}
          className="flex h-5 w-max items-center gap-2 rounded-xl px-2 py-0.5 text-xs font-medium"
        >
          {item?.name}
        </div>
      ),
      onClick: onChangePayment(item?.id, item)
    })) || [];

  return (
    <Spin spinning={create.isLoading} indicator={LoadingIndicator}>
      <Dropdown
        menu={{
          items,
          selectable: true,
          selectedKeys: value ? [String(value?.id)] : []
        }}
        trigger={["click"]}
        dropdownRender={dropdownRender}
      >
        {value ? (
          <div
            onClick={stopPropagation}
            style={{
              background: `${value?.color}20`,
              color: value?.color
            }}
            className="flex h-5 w-max items-center gap-2 rounded-xl px-2 py-0.5 text-xs font-medium"
          >
            {value?.name}
          </div>
        ) : (
          <div
            onClick={stopPropagation}
            className={cx(
              "person-select",
              "rounded-[4px] border border-dashed border-gray-300 px-2.5 py-0.5 text-xs font-normal text-gray-300"
            )}
          >
            {t("partyModal.To'lov turi")}
          </div>
        )}
      </Dropdown>
    </Spin>
  );
};

export default PaymentType;
