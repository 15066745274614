import React from "react";

const UysotLogoIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 5.45455C0 2.44208 2.38781 0 5.33333 0H18.6667C21.6122 0 24 2.44208 24 5.45455V18.5455C24 21.5579 21.6122 24 18.6667 24H5.33333C2.38781 24 0 21.5579 0 18.5455V5.45455Z"
      fill="#F2F4F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.64062 17.6654C5.64062 16.9284 6.24085 16.3308 6.98129 16.3308H16.8128C17.5532 16.3308 18.1535 16.9284 18.1535 17.6654C18.1535 18.4025 17.5532 19.0001 16.8128 19.0001H6.98129C6.24085 19.0001 5.64062 18.4025 5.64062 17.6654Z"
      fill="#00A389"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0572 4.3952C11.5779 3.86827 12.4221 3.86827 12.9428 4.3952L19.6095 11.1417C20.1302 11.6687 20.1302 12.523 19.6095 13.0499C19.0888 13.5769 18.2446 13.5769 17.7239 13.0499L12 7.25751L6.27614 13.0499C5.75544 13.5769 4.91123 13.5769 4.39052 13.0499C3.86983 12.523 3.86983 11.6687 4.39052 11.1417L11.0572 4.3952Z"
      fill="#00A389"
    />
    <path
      d="M14.0428 12.7739C14.0428 13.9187 13.1155 14.8468 11.9718 14.8468C10.8281 14.8468 9.90088 13.9187 9.90088 12.7739C9.90088 11.629 10.8281 10.7009 11.9718 10.7009C13.1155 10.7009 14.0428 11.629 14.0428 12.7739Z"
      fill="#FFAB55"
    />
  </svg>
);

export default UysotLogoIcon;
