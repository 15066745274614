import React from "react";

import { useQueryParams } from "hooks/useQueryParams";

import { WorksTabEnum } from "modules/works/constants";

import ListOfWorksFilterDrawer from "./list-of-works-filter-drawer/list-of-works-filter-drawer";
import ProcessFilterDrawer from "./process-filter-drawer/process-filter-drawer";
import ProgressesFilterDrawer from "./progresses-filter-drawer/progresses-filter-drawer";
import SpentOfResourcesFilterDrawer from "./spent-of-resources-filter-drawer/spent-of-resources-filter-drawer";

const FilterDrawers: React.FC = () => {
  const { queries } = useQueryParams();

  const { statistics_type } = queries();

  const content: Record<WorksTabEnum, React.ReactNode> = {
    [WorksTabEnum.LIST_OF_WORKS]: <ListOfWorksFilterDrawer />,
    [WorksTabEnum.PROGRESSES]: <ProgressesFilterDrawer />,
    [WorksTabEnum.SPENT_OF_RESOURCES]: <SpentOfResourcesFilterDrawer />,
    [WorksTabEnum.PROCESS]: <ProcessFilterDrawer />
  };

  return <>{content[statistics_type as keyof typeof content]}</>;
};

export default FilterDrawers;
