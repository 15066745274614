import React from "react";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

import { useGenerateTemplate } from "../../../supply/service/mutations";
import DownloadIcon from "../../assets/icons/DownloadIcon";
import { CustomFieldLocationEnum } from "../../utils/enums/customFieldLocationEnum";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";

export type Props = {
  location: CustomFieldLocationEnum;
  object_id: number;
  styles?: Record<string, string>;
};

const TemplateDownload: React.FC<Props> = ({ location, object_id }) => {
  const generateTemplateMutation = useGenerateTemplate();
  const { t } = useTranslation();

  const onDownloadPartyTemplate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    generateTemplateMutation.mutateAsync({
      object_id,
      location
    });
  };

  return (
    <Spin spinning={generateTemplateMutation.isLoading} indicator={LoadingIndicator}>
      <div className="flex items-center gap-2" onClick={e => onDownloadPartyTemplate(e)}>
        <DownloadIcon /> {t("TemplateDownload.Yuklab olish")}
      </div>
    </Spin>
  );
};

export default TemplateDownload;
