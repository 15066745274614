import React from "react";
import { UseFormReturn } from "react-hook-form";

import { getPopupContainer, PartyForm } from "modules/party/create";
import { useWarehouses } from "modules/party/view";

import { Select, TooltipShortName } from "components";

type Props = {
  index: number;
  parentIndex: number;
  isUpdate?: boolean;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, index, parentIndex, isUpdate }) => {
  const { warehouses, isLoading } = useWarehouses();

  return (
    <Select
      withoutMessage
      suffixIcon={null}
      loading={isLoading}
      placeholder="Omborxona"
      control={form.control}
      popupMatchSelectWidth={false}
      errorClassName="party-create-error"
      wrapperClassName="party-create-input"
      name={`company_person_group.${parentIndex}.products.${index}.warehouse_id`}
      getPopupContainer={getPopupContainer(isUpdate ? "party-left-content-update" : "party-left-content")}
      options={warehouses?.map(warehouse => ({
        key: warehouse?.id,
        value: warehouse?.id,
        label: <TooltipShortName arrow={false} zIndex={200497} title={warehouse?.name as string} length={12} />,
        props: {
          name: warehouse?.name
        }
      }))}
    />
  );
};

export default Index;
