import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalStorage } from "features/app/service/LocalStorage";
import {
  IMPOST_PARTY_COLUMNS,
  SUPPLY_PARTY_COLUMNS,
  WAREHOUSE_PARTY_COLUMNS
} from "features/app/utils/constants/localStorageKeys";
import { sliceNames } from "features/app/utils/constants/sliceNames";

import { defaultColumns, PartyColumn, PartyType, WarehouseProduct } from "modules/party/view";

import { PartyReducerModel } from "../types/PartyReducerModel";

const initialState: PartyReducerModel = {
  create: {
    visible: false,
    type: "supply",
    isGroup: false,
    isDiscount: false,
    isVat: false
  },
  estimate: {
    visible: false
  },
  resource: {
    visible: false
  },
  update: {
    id: undefined,
    visible: false,
    isDiscount: false,
    isGroup: false,
    isVat: false,
    isProcess: Boolean(LocalStorage.get("is_party_process"))
  },
  view: {
    visible: false,
    type: "supply",
    status: "ordered",
    isLoading: false,
    isGroup: Boolean(LocalStorage.get("is_party_group")),
    isProcess: Boolean(LocalStorage.get("is_party_process")),
    settings: {
      visible: false,
      columns: {
        impost: LocalStorage.get(IMPOST_PARTY_COLUMNS) || defaultColumns?.impost,
        supply: LocalStorage.get(SUPPLY_PARTY_COLUMNS) || defaultColumns?.supply,
        warehouse: LocalStorage.get(WAREHOUSE_PARTY_COLUMNS) || defaultColumns?.warehouse,
        payment: []
      }
    }
  },
  createProduct: {
    visible: false,
    fieldName: ""
  },
  createCompanyPerson: {
    visible: false,
    name: undefined,
    fieldName: `warehouse_products.${0}`
  },
  admissionHistory: {
    visible: false
  }
};

const partyReducer = createSlice({
  name: sliceNames.PARTY,
  initialState,
  reducers: {
    /// PARTY VIEW ACTIONS
    setPartyView: (state, action: PayloadAction<Omit<PartyReducerModel["view"], "settings">>) => {
      state.view = { ...state.view, ...action.payload };
    },
    setPartyViewGroup: (state, action: PayloadAction<boolean>) => {
      LocalStorage.set("is_party_group", action.payload);
      state.view.isGroup = action.payload;
    },
    setPartyViewProcess: (state, action: PayloadAction<boolean>) => {
      LocalStorage.set("is_party_process", action.payload);
      state.view.isProcess = action.payload;
    },
    setPartyViewUpdateProduct: (state, action: PayloadAction<WarehouseProduct | undefined>) => {
      state.view.updateProduct = action.payload;
    },
    setPartyViewSettings: (state, action: PayloadAction<PartyReducerModel["view"]["settings"]>) => {
      state.view.settings = action.payload;
    },
    setPartyViewSettingsVisible: (state, action: PayloadAction<boolean>) => {
      state.view.settings.visible = action.payload;
    },
    setPartyViewSettingsColumns: (
      state,
      action: PayloadAction<{
        type: keyof PartyReducerModel["view"]["settings"]["columns"];
        columns: PartyColumn[];
      }>
    ) => {
      state.view.settings.columns[action.payload.type] = action.payload.columns;
    },
    setPartyViewAccept: (state, action: PayloadAction<boolean>) => {
      state.view.isAccept = action.payload;
    },
    setPartyViewIsLoading: (state, action: PayloadAction<boolean>) => {
      state.view.isLoading = action.payload;
    },

    /// PARTY CREATE ACTIONS
    setPartyCreate: (state, action: PayloadAction<PartyReducerModel["create"]>) => {
      state.create = action.payload;
    },
    setPartyCreateVisible: (state, action: PayloadAction<{ visible: boolean; type?: PartyType }>) => {
      state.create.visible = action.payload.visible;

      if (action.payload.type) {
        state.create.type = action.payload.type;
      }
    },
    setPartyCreateVat: (state, action: PayloadAction<boolean>) => {
      state.create.isVat = action.payload;
    },
    setPartyCreateDiscount: (state, action: PayloadAction<boolean>) => {
      state.create.isDiscount = action.payload;
    },
    setPartyCreateGroup: (state, action: PayloadAction<boolean>) => {
      state.create.isGroup = action.payload;
    },

    // PARTY ESTIMATE ACTIONS
    setPartyEstimate: (state, action: PayloadAction<PartyReducerModel["estimate"]>) => {
      state.estimate = action.payload;
    },

    // PARTY RESOURCE ACTIONS
    setPartyResource: (state, action: PayloadAction<PartyReducerModel["resource"]>) => {
      state.resource = action.payload;
    },

    /// PARTY UPDATE ACTIONS
    setPartyUpdate: (state, action: PayloadAction<PartyReducerModel["update"]>) => {
      state.update = action.payload;
    },
    setPartyUpdateVisible: (state, action: PayloadAction<{ visible: boolean; id?: number; zIndex?: number }>) => {
      state.update.id = action.payload.id;
      state.update.zIndex = action.payload.zIndex;
      state.update.visible = action.payload.visible;
    },
    setPartyUpdateVat: (state, action: PayloadAction<boolean>) => {
      state.update.isVat = action.payload;
    },
    setPartyUpdateDiscount: (state, action: PayloadAction<boolean>) => {
      state.update.isDiscount = action.payload;
    },
    setPartyUpdateGroup: (state, action: PayloadAction<boolean>) => {
      state.update.isGroup = action.payload;
    },
    setPartyUpdateProcess: (state, action: PayloadAction<boolean>) => {
      LocalStorage.set("is_party_process", action.payload);
      state.update.isProcess = action.payload;
    },

    // PARTY CREATE PRODUCT MODAL
    setPartyCreateProduct: (state, action: PayloadAction<PartyReducerModel["createProduct"]>) => {
      state.createProduct = action.payload;
    },

    // PARTY CREATE COMPANY PERSON MODAL
    setPartyCreateCompanyPerson: (state, action: PayloadAction<PartyReducerModel["createCompanyPerson"]>) => {
      state.createCompanyPerson = action.payload;
    },

    // PARTY ADMISSION HISTORY MODAL
    setAdmissionHistoryModal: (state, action: PayloadAction<PartyReducerModel["admissionHistory"]>) => {
      state.admissionHistory = action.payload;
    }
  }
});

export default partyReducer.reducer;
export const partyReducerActions = partyReducer.actions;
