import React from "react";

const RollerBrushIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      {...props}
      width={props?.widths || "22"}
      height={props?.height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4167 4.125C17.4167 4.55093 17.4167 4.7639 17.3814 4.941C17.2368 5.66826 16.6683 6.23678 15.941 6.38144C15.7639 6.41667 15.5509 6.41667 15.125 6.41667H5.04167C4.61573 6.41667 4.40277 6.41667 4.22567 6.38144C3.4984 6.23678 2.92989 5.66826 2.78523 4.941C2.75 4.7639 2.75 4.55093 2.75 4.125C2.75 3.69906 2.75 3.4861 2.78523 3.309C2.92989 2.58173 3.4984 2.01322 4.22567 1.86856C4.40277 1.83333 4.61573 1.83333 5.04167 1.83333H15.125C15.5509 1.83333 15.7639 1.83333 15.941 1.86856C16.6683 2.01322 17.2368 2.58173 17.3814 3.309C17.4167 3.4861 17.4167 3.69906 17.4167 4.125ZM17.4167 4.125C18.2709 4.125 18.698 4.125 19.0349 4.26455C19.4841 4.45063 19.841 4.80753 20.0271 5.25675C20.1667 5.59366 20.1667 6.02077 20.1667 6.875V7.15C20.1667 8.17676 20.1667 8.69014 19.9668 9.08231C19.7911 9.42728 19.5106 9.70774 19.1656 9.88351C18.7735 10.0833 18.2601 10.0833 17.2333 10.0833H13.9333C12.9066 10.0833 12.3932 10.0833 12.001 10.2832C11.6561 10.4589 11.3756 10.7394 11.1998 11.0843C11 11.4765 11 11.9899 11 13.0167V13.75M10.6333 20.1667H11.3667C11.88 20.1667 12.1367 20.1667 12.3328 20.0668C12.5053 19.9789 12.6455 19.8386 12.7334 19.6662C12.8333 19.4701 12.8333 19.2134 12.8333 18.7V15.2167C12.8333 14.7033 12.8333 14.4466 12.7334 14.2505C12.6455 14.078 12.5053 13.9378 12.3328 13.8499C12.1367 13.75 11.88 13.75 11.3667 13.75H10.6333C10.12 13.75 9.86326 13.75 9.66718 13.8499C9.49469 13.9378 9.35446 14.078 9.26658 14.2505C9.16667 14.4466 9.16667 14.7033 9.16667 15.2167V18.7C9.16667 19.2134 9.16667 19.4701 9.26658 19.6662C9.35446 19.8386 9.49469 19.9789 9.66718 20.0668C9.86326 20.1667 10.12 20.1667 10.6333 20.1667Z"
        stroke={props?.stroke || "#475467"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RollerBrushIcon;
