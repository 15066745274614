import React from "react";
import PaymentSearch from "features/payment/components/top/search/PaymentSearch";

import { useQueryParams } from "hooks/useQueryParams";

import WorksTabEnums from "modules/works/constants/worksTabEnums";

import Actions from "./actions";
import { FilterDrawers } from "./filter-drawers";

const Right: React.FC = () => {
  const { queries } = useQueryParams();
  const { statistics_type } = queries();

  return (
    <div className="box-border flex items-center gap-3 px-4 py-2">
      <PaymentSearch />
      <FilterDrawers />
      {statistics_type === WorksTabEnums.PROCESS && <Actions />}
    </div>
  );
};

export default Right;
