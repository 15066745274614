import React from "react";
import { Collapse } from "antd";
import { useTranslation } from "react-i18next";

import { PartyView } from "modules/party/view";

import { SelectSuffixIcon } from "components";

import Item from "./item";

import styles from "./index.module.scss";

type Props = {
  customFields: PartyView["custom_field_values"];
};

const Index: React.FC<Props> = ({ customFields }) => {
  const { t } = useTranslation();

  return (
    <Collapse
      ghost
      expandIcon={({ isActive }) => <SelectSuffixIcon placement={isActive ? "bottom" : "right"} />}
      rootClassName={styles.collapse}
      items={[
        {
          key: "custom-fields",
          label: t("partyView.O'zgaruvchilar"),
          children: (
            <div className="flex flex-col gap-4">{customFields?.map(item => <Item key={item?.id} item={item} />)}</div>
          )
        }
      ]}
    />
  );
};

export default Index;
