import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { UseMutationResult } from "@tanstack/react-query";
import { Button } from "antd";
import dayjs, { Dayjs } from "dayjs";
import CustomFieldIcon from "features/app/assets/icons/CustomFieldIcon";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";
import { colors } from "features/app/utils/constants/colors";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { CustomFieldTypesEnum } from "features/app/utils/enums/customFieldTypesEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { generateExcelPayload } from "features/app/utils/helpers/generateExcelPayload";
import ArrowSquareDownIcon from "features/settings/assets/icons/ArrowSquareDownIcon";
import TagIcon from "features/settings/assets/icons/TagIcon";
import { warehouseOrdersTableDefaultDataFunc } from "features/supply/utils/constants/warehouseProductTableDefaultData";
import { routeSubmodules } from "features/warehouse/utils/constants/routeSubmodules";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { ConditionalRender } from "components";

import { useQueryParams } from "../../../../../hooks/useQueryParams";
import MainWarehouseIcon from "../../../../app/assets/icons/MainWarehouseIcon";
import FilterDateRange from "../../../../app/components/filter-drawer/filter-date-range/FilterDateRange";
import FilterSelect from "../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FilterDrawer from "../../../../app/components/filter-drawer/FilterDrawer";
import FiltersCount from "../../../../app/components/filter-drawer/filters-count/FiltersCount";
import { StatusEnums } from "../../../../app/components/statuses/statusEnums";
import { useGetCustomFieldSelect, useGetTableConfigs, useGetUsersSelect, useProjectSelect } from "../../../../app/service/queries";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import BuildingIcon from "../../../../payment/assets/icons/BuildingIcon";
import UserIcon from "../../../../projects/assets/icons/UserIcon";
import ZapIcon from "../../../../projects/assets/icons/ZapIcon";
import FilterLinesIcon from "../../../assets/icons/FilterLinesIcon";
import { useGetWarehouseSelect } from "../../../service/query";

import styles from "./filterDrawer.module.scss";

const queryStringArr = [
  queryParamsKeys.WAREHOUSE_IDS,
  queryParamsKeys.PROJECT_IDS,
  queryParamsKeys.MIN_DELIVERY_DATE,
  queryParamsKeys.MAX_DELIVERY_DATE,
  queryParamsKeys.STATUSES,
  queryParamsKeys.CREATORS_IDS,
  queryParamsKeys.CUSTOM_FIELDS
];

export interface Props {
  customFieldEnum?: CustomFieldLocationEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportExcelMutate?: UseMutationResult<any, unknown, any, unknown>;
}

const FilterDrawerWarehouseProduct: React.FC<Props> = ({ customFieldEnum, exportExcelMutate }) => {
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const productActions = actions("Buyurtmalar");
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const { data: customFields } = useGetCustomFieldSelect(customFieldEnum ? [customFieldEnum] : []);
  const tableConfigData = warehouseOrdersTableDefaultDataFunc(i18n.language);

  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG, tableConfigData);

  const { queries, necessaryQueries } = useQueryParams();
  const { min_delivery_date, max_delivery_date } = queries();
  const [open, setOpen] = useState(false);
  const [warehousesSelected, setWarehousesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.WAREHOUSE_IDS] as string[] | null
  );
  const [projectsSelected, setProjectsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );
  const [usersSelected, setUsersSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.CREATORS_IDS] as string[] | null
  );
  const [statusesSelected, setStatusesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.STATUSES] as string[] | null
  );
  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([
    queries()[queryParamsKeys.MIN_DELIVERY_DATE]
      ? dayjs(queries()[queryParamsKeys.MIN_DELIVERY_DATE], dayjsFormats.DATE)
      : null,
    queries()[queryParamsKeys.MAX_DELIVERY_DATE]
      ? dayjs(queries()[queryParamsKeys.MAX_DELIVERY_DATE], dayjsFormats.DATE)
      : null
  ]);
  const { data: projects } = useProjectSelect({ enabled: open });
  const { data: warehouses } = useGetWarehouseSelect(false, open);
  const { data: adminsSelect } = useGetUsersSelect(open);

  const [customFieldStates, setCustomFieldStates] = useState<{ [key: string]: string[] | null }>({});

  const selectableCustomFields = customFields?.filter(
    customField => customField?.type === "select" || customField?.type === "label"
  );

  const handleCustomFieldChange = (id: string, value: string[] | null) => {
    setCustomFieldStates(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const aggregatedCustomFieldsSelected = useMemo(() => Object.values(customFieldStates).flat(), [customFieldStates]);

  const admins = useMemo(() => {
    const result = adminsSelect?.map(item => ({ name: item?.full_name, ...item }));

    return result;
  }, [adminsSelect]);

  const onOpenFilter = () => {
    setOpen(true);
  };

  const orderTypeData = useMemo(
    () => [
      {
        id: StatusEnums.OPEN,
        name: `${t("warehouse.Yangi")}`
      },
      {
        id: StatusEnums.PENDING,
        name: `${t("warehouse.Kutilmoqda")}`
      },
      {
        id: StatusEnums.ORDERED,
        name: `${t("warehouse.Partiya qilingan")}`
      },
      {
        id: StatusEnums.RECIEVED,
        name: `${t("warehouse.Qabul qilingan")}`
      },
      {
        id: StatusEnums.REJECTED,
        name: `${t("warehouse.Bekor qilingan")}`
      },
      {
        id: StatusEnums.PASSIVE,
        name: `${t("warehouse.Qoralama")}`
      }
    ],
    []
  );

  const handleExcelExport = () => {
    const data = filterColumns({
      customFields,
      tableConfig
    })
      ?.map(value => ({
        key: String(value?.id),
        name: value?.title
      }))
      ?.filter(item => typeof item?.name !== "object");

    const otherParamsData = necessaryQueries(queryParamsKeys.SEARCH);

    const reqBody = {
      columns: data,
      project_ids: projectsSelected,
      warehouse_ids: warehousesSelected,
      statuses: statusesSelected,
      creator_ids: usersSelected,
      min_delivery_date,
      max_delivery_date,
      ...otherParamsData
    };

    if (data) {
      exportExcelMutate?.mutate(generateExcelPayload(reqBody));
    }
  };

  const footerContent = productActions?.upload ? (
    <Button onClick={handleExcelExport} className={styles.downloadBtn} loading={exportExcelMutate?.isLoading}>
      <DownloadIcon /> {t("warehouse.Yuklab olish")}
    </Button>
  ) : (
    <span />
  );

  const customFieldIcons: Record<string, React.ReactNode> = {
    [CustomFieldTypesEnum.SELECT]: <ArrowSquareDownIcon />,
    [CustomFieldTypesEnum.LABEL]: <TagIcon />
  };

  const searchParams = queryString.parse(location.search, { arrayFormat: "bracket" });
  const fieldValues = searchParams[queryParamsKeys.CUSTOM_FIELDS];

  useEffect(() => {
    if (selectableCustomFields && open) {
      const initialCustomFieldStates: { [key: string]: string[] | null } = {};

      selectableCustomFields.forEach(field => {
        if (fieldValues) {
          const valuesArray = Array.isArray(fieldValues) ? fieldValues : [fieldValues];

          initialCustomFieldStates[field.id] = valuesArray.filter(value =>
            field?.custom_field_options?.some(option => String(option.id) === value)
          ) as string[];
        } else {
          initialCustomFieldStates[field.id] = [];
        }
      });

      setCustomFieldStates(initialCustomFieldStates);
    }
  }, [open]);

  return (
    <FilterDrawer
      open={open}
      setOpen={setOpen}
      filterButton={
        <Button onClick={onOpenFilter}>
          <FilterLinesIcon />
          {t("warehouse.Filter")}
          <FiltersCount queryStringArr={queryStringArr} />
        </Button>
      }
      footerContent={footerContent}
      height="max-content"
      queryStringArr={queryStringArr}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.WAREHOUSE_IDS,
          selectedOptions: warehousesSelected
        },
        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectsSelected
        },
        {
          queryKey: queryParamsKeys.CREATORS_IDS,
          selectedOptions: usersSelected
        },
        {
          queryKey: queryParamsKeys.STATUSES,
          selectedOptions: statusesSelected
        },
        {
          queryKey: queryParamsKeys.CUSTOM_FIELDS,
          selectedOptions: aggregatedCustomFieldsSelected as string[]
        }
      ]}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_DELIVERY_DATE,
          selectedValue: dateValue && dateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_DELIVERY_DATE,
          selectedValue: dateValue && dateValue[1]
        }
      ]}
      setDateValue={[setDateValue]}
      setSelectedOptionsArr={
        [
          setWarehousesSelected,
          setProjectsSelected,
          setUsersSelected,
          setStatusesSelected,
          setCustomFieldStates
        ] as never
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 text-base font-medium text-gray-800">
            <FilterLinesIcon stroke={colors.GRAY_800} /> {t("warehouse.Umumiy filterlar")}
          </div>
          <div className={styles.content}>
            <FilterTreeSelect
              selectedOptions={projectsSelected}
              setSelectedOptions={setProjectsSelected}
              data={projects}
              multiple={true}
              icon={<BuildingIcon />}
              placeholder={t("warehouse.Loyihalar")}
              showSearch
            />
            <FilterSelect
              selectedOptions={warehousesSelected}
              setSelectedOptions={setWarehousesSelected}
              data={warehouses}
              multiple="multiple"
              icon={<MainWarehouseIcon />}
              placeholder={t("warehouse.Qabul omborxonalar")}
              showSearch
            />
            <FilterSelect
              selectedOptions={statusesSelected}
              setSelectedOptions={setStatusesSelected}
              data={orderTypeData}
              multiple="multiple"
              icon={<ZapIcon />}
              placeholder={t("warehouse.Status")}
              showSearch
            />
            <FilterSelect
              selectedOptions={usersSelected}
              setSelectedOptions={setUsersSelected}
              data={admins}
              multiple="multiple"
              icon={<UserIcon />}
              placeholder="Xodim"
              isPersons
              showSearch
            />
            <FilterDateRange value={dateValue} setValue={setDateValue} />
          </div>
        </div>
        <ConditionalRender if={selectableCustomFields?.length && selectableCustomFields?.length > 0}>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 text-base font-medium text-gray-800">
              <CustomFieldIcon stroke={colors.GRAY_800} width={20} /> {t("warehouse.O'zgaruvchi filterlar")}
            </div>
            <div className={styles.content}>
              {selectableCustomFields?.map(el => (
                <FilterSelect
                  key={el.id}
                  selectedOptions={customFieldStates[el.id]}
                  setSelectedOptions={value => handleCustomFieldChange(String(el.id), value)}
                  data={el.custom_field_options as never}
                  multiple="multiple"
                  icon={customFieldIcons[el.type]}
                  placeholder={el.name[i18n.language]}
                  showSearch
                />
              ))}
            </div>
          </div>
        </ConditionalRender>
      </div>
    </FilterDrawer>
  );
};

export default FilterDrawerWarehouseProduct;
