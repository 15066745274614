/* eslint-disable react-refresh/only-export-components */
import { UZ } from "features/app/utils/constants/languages";

import { Permission } from "modules/auth";

import { customLazy } from "../../features/app/components/custom-lazy/CustomLazy";
import { RouteModel } from "../../features/app/utils/models/RouteModel";
import { ProjectsRouter } from "../projects/ProjectsRouter";
import SettingsRouter from "../settings/SettingsRouter";

import { rootPaths } from "./rootPaths";

const Works = customLazy(() => import("../../pages/works/works"));
const Impost = customLazy(() => import("../../features/impost/Index"));
const Supply = customLazy(() => import("../../features/supply/Index"));
const Payment = customLazy(() => import("../../features/payment/Index"));
const Warehouse = customLazy(() => import("../../features/warehouse/Index"));
const Inventory = customLazy(() => import("../../features/inventory/Index"));
const Dashboard = customLazy(() => import("../../pages/dashboard/dashboard"));
const Counterparts = customLazy(() => import("../../features/counterparts/pages/counterparts/Index"));
const DetailedFolderCounterParts = customLazy(
  () => import("../../features/counterparts/pages/detailed-folder-counterparts/Index")
);
const DetailedCounterParts = customLazy(() => import("../../features/counterparts/pages/detailed-counterparts/Index"));
const DetailedInventory = customLazy(
  () => import("../../features/inventory/pages/detailed-inventory/DetailedInventory")
);
const DetailedWarehouseCatalog = customLazy(
  () => import("../../features/warehouse/components/warehouse/detailed-catalogs")
);
const SubWarehouseCatalogs = customLazy(() => import("../../features/warehouse/components/warehouse/sub-catalogs"));

export const allRootRoutes: RouteModel[] = [
  {
    element: ProjectsRouter,
    isExact: false,
    path: rootPaths.PROJECTS,
    permissionName: "Loyiha"
  },
  {
    element: Payment,
    isExact: true,
    path: rootPaths.PAYMENT,
    permissionName: "Kassa"
  },
  {
    element: SettingsRouter,
    isExact: false,
    path: rootPaths.SETTINGS,
    permissionName: "Sozlamalar"
  },
  {
    element: Counterparts,
    isExact: true,
    path: rootPaths.COUNTERPARTS,
    permissionName: "Kontragent"
  },
  {
    element: Works,
    isExact: true,
    path: rootPaths.WORKS,
    breadcrumb: "Ishlar",
    permissionName: "Ishlar"
  },
  {
    element: DetailedFolderCounterParts,
    isExact: true,
    path: rootPaths.DETAILED_FOLDER_COUNTERPARTS,
    permissionName: "Papkalar" as any,
    isDetailed: true,
    permissionModuleName: "Kontragent" as any
  },
  {
    element: DetailedCounterParts,
    isExact: true,
    path: rootPaths.DETAILED_COUNTERPARTS,
    permissionName: "Kontragent balansi"
    // isDetailed: true,
  },
  {
    element: DetailedCounterParts,
    isExact: true,
    path: rootPaths.DETAILED_COUNTERPART,
    permissionName: "Kontragent balansi"
    // isDetailed: true,
  },
  {
    element: DetailedCounterParts,
    isExact: true,
    path: rootPaths.FOLDER_DETAIL_COUNTERPARTS,
    permissionName: "Kontragent balansi"
    // isDetailed: true,
  },
  {
    element: Warehouse,
    isExact: true,
    path: rootPaths.WAREHOUSE,
    permissionName: "Omborxona"
  },
  {
    element: Supply,
    isExact: true,
    path: rootPaths.SUPPLY,
    permissionName: "Ta'minot"
  },
  {
    element: Inventory,
    isExact: true,
    path: rootPaths.INVENTORY,
    permissionName: "Inventar"
  },
  {
    element: DetailedInventory,
    isExact: true,
    path: rootPaths.DETAILED_INVENTORY,
    permissionName: "Inventarlar papkasi",
    isDetailed: true,
    permissionModuleName: "Inventar"
  },
  {
    element: Impost,
    isExact: true,
    path: rootPaths.IMPOST,
    permissionName: "Monitoring",
    breadcrumb: "Monitoring"
  },
  {
    element: Dashboard,
    isExact: true,
    path: rootPaths.DASHBOARD,
    permissionName: "Dashboard",
    breadcrumb: "Dashboard"
  }
];

export const rootRoutes = ({ permissions }: { permissions: Permission[] }): RouteModel[] => {
  const routes: RouteModel[] = allRootRoutes?.filter(route => {
    if (route.isDetailed) {
      const findPermission = permissions?.find(item => item.name[UZ] === route.permissionModuleName);
      const findSubModulePermission = findPermission?.submodules?.find(item => item.name[UZ] === route.permissionName);

      if (findSubModulePermission?.name[UZ]) return true;
    } else {
      const isSomePermission = permissions?.some(item => item.name[UZ] === route.permissionName);

      if (isSomePermission) return true;
    }

    return false;
  });

  routes?.push({
    element: DetailedWarehouseCatalog,
    isExact: true,
    path: rootPaths.DETAILED_WAREHOUSE_CATALOG,
    permissionName: "Omborxona",
    permissionModuleName: "Omborxona kataloglari"
  });
  routes?.push({
    element: SubWarehouseCatalogs,
    isExact: true,
    path: rootPaths.SUB_WAREHOUSE_CATALOGS,
    permissionName: "Omborxona",
    permissionModuleName: "Omborxona sub kataloglari"
  });

  return routes;
};
