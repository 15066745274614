import React from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

import { activatingMessagingSystemsActions, useDispatch, useSelector } from "store";

import { ActivatingMessagingSystems } from "modules/settings/message";

import styles from "./activating-messaging-systems.module.scss";

const ActivatingMessagingSystemsModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { visible, title } = useSelector(state => state.activatingMessagingSystemsReducer);
  const { setOpenModal } = activatingMessagingSystemsActions;

  const onCancel = () => {
    dispatch(
      setOpenModal({
        visible: false,
        title: ""
      })
    );
  };

  return (
    <Modal
      centered
      footer={null}
      closeIcon={null}
      className={styles.messaging_system_modal}
      title={
        <div className="flex flex-col items-center justify-center gap-3">
          <span className="text-center text-3xl font-semibold text-gray-900">{title}</span>
          <span className="text-center text-base font-normal text-gray-600">
            {t("works.Integratsiya qilish bilan ikki dastur ma'lumotlarini o'zaro almashing")}
          </span>
        </div>
      }
      open={visible}
      onCancel={onCancel}
    >
      <div className="mt-8 flex w-full flex-col items-center justify-center gap-3">
        <ActivatingMessagingSystems.ActivatingMessagingSystemsForm key={title}>
          {form => (
            <Button type="primary" htmlType="submit" loading={form.formState.isSubmitting}>
              {t("works.Faollashtirish")}
            </Button>
          )}
        </ActivatingMessagingSystems.ActivatingMessagingSystemsForm>
        <Button className="w-full border-0 shadow-none" onClick={onCancel}>
          {t("works.Yopish")}
        </Button>
      </div>
    </Modal>
  );
};

export default ActivatingMessagingSystemsModal;
