import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, FormInstance } from "antd";
import { useTranslation } from "react-i18next";

import { ConditionalRender } from "components";

import { useAppSelector } from "../../../../../../../hooks/redux";
import { companyPersonActions } from "../../../../../../../store/reducers/companyPersonReducer";
import PlusIcon from "../../../../../../app/assets/icons/PlusIcon";
import { PaymentTypeEnum } from "../../../../../../app/utils/constants/paymentTypeEnum";
import { useGetCompanyPersonSelect } from "../../../../../../counterparts/service/queries";
import { useGetFinancial } from "../../../../../../settings/service/queries";
import NotFoundSelectIcon from "../../../../../assets/icons/NotFoundSelectIcon";
import { PaymentContext } from "../../../../../hooks/PaymentContext";
import { useGetInventorySelect } from "../../../../../service/queries";
import { INCOME_EXPENSE_TYPE } from "../../../../../utils/enums/incomeExpenseTypeEnums";

import FinancialItem from "./financial/FinancialItem";
import IncomeExpenseTypeItem from "./type/IncomeExpenseTypeItem";
import IncomeExpenseTypeSelectItem from "./type-select/IncomeExpenseTypeSelectItem";
import Party from "./party";

import styles from "../thirdStep.module.scss";

type Props = {
  step: number;
  projectId: number;
  form: FormInstance;
  incomeExpenseType: INCOME_EXPENSE_TYPE;
};

const ResourceType: React.FC<Props> = ({ step, projectId, incomeExpenseType, form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { setVisibleModal } = companyPersonActions;
  const { setContext } = useContext(PaymentContext);
  const { type, companyPersonId, defaultData } = useAppSelector(state => state.paymentReducer.income_expense_modal);
  const { data: inventories, isLoading: isLoadingInventories } = useGetInventorySelect(2, true);
  const { data: companyPerson, isLoading: isLoadingCompanyPerson } = useGetCompanyPersonSelect();
  const { data: financial, isLoading: isLoadingFinancial } = useGetFinancial(
    step === 2 && (!companyPersonId || !defaultData?.company_person?.id)
  );

  const incomeExpenseTypeData = {
    financial_id: financial?.data[type as keyof typeof financial.data]?.map(item => ({ ...item, tin: 0 })),
    company_person_id: companyPerson?.map(item => ({
      ...item,
      name: item?.name,
      tin: item?.person?.tin
    })),
    inventory_id: inventories?.map(item => ({ ...item, tin: 0 }))
  };

  const IncomeExpenseTypeNotFound: {
    [key in INCOME_EXPENSE_TYPE]: { onClick: () => void; name: string };
  } = {
    [INCOME_EXPENSE_TYPE.inventory_id]: {
      onClick: () => {
        setContext({
          financialModal: {
            open: true,
            type: String(type),
            title: type === PaymentTypeEnum.INCOME ? "Daromat" : "Xarajat",
            oneFinancial: {
              name: search
            }
          }
        });
      },
      name: type === PaymentTypeEnum.INCOME ? "Daromat" : "Xarajat"
    },
    [INCOME_EXPENSE_TYPE.financial_id]: {
      onClick: () => {
        setContext({
          financialModal: {
            open: true,
            type: String(type),
            title: type === PaymentTypeEnum.INCOME ? "Daromat" : "Xarajat",
            oneFinancial: {
              name: search
            }
          }
        });
      },
      name: type === PaymentTypeEnum.INCOME ? "Daromat" : "Xarajat"
    },
    [INCOME_EXPENSE_TYPE.company_person_id]: {
      onClick: () => {
        dispatch(
          setVisibleModal({
            visible: true,
            initialData: {
              name: search
            },
            onAfterFunc: () => {}
          })
        );
      },
      name: "Ta'minotchi"
    }
  };

  const notFoundContent = () => (
    <div className={styles.not_found}>
      <span>
        <NotFoundSelectIcon />
      </span>
      <h4>
        {IncomeExpenseTypeNotFound[incomeExpenseType].name} {t("payment.topilmadi")}
      </h4>
      <p>
        {t("payment.Kechirasiz, siz izlayotgan")} {t(`payemnt.${IncomeExpenseTypeNotFound[incomeExpenseType].name}ni`)}{" "}
        <br />
        {t("payment.topa olmadik.")} {t("payment.Iltimos, qayta urinib ko'ring.")}
      </p>
      <Button type="primary" onClick={IncomeExpenseTypeNotFound[incomeExpenseType].onClick}>
        <PlusIcon /> {t(`payment.${IncomeExpenseTypeNotFound[incomeExpenseType].name} yaratish`)}
      </Button>
    </div>
  );

  return (
    <>
      <IncomeExpenseTypeItem />
      <IncomeExpenseTypeSelectItem
        search={search}
        setSearch={setSearch}
        projectId={projectId}
        NotFoundContent={notFoundContent}
        incomeExpenseType={incomeExpenseType}
        isLoadingFinancial={isLoadingFinancial}
        isLoadingInventories={isLoadingInventories}
        isLoadingCompanyPerson={isLoadingCompanyPerson}
        incomeExpenseTypeData={incomeExpenseTypeData as never}
      />
      <FinancialItem
        search={search}
        projectId={projectId}
        setSearch={setSearch}
        NotFoundContent={notFoundContent}
        incomeExpenseType={incomeExpenseType}
        isLoadingInventories={isLoadingInventories}
        incomeExpenseTypeData={incomeExpenseTypeData as never}
      />
      <ConditionalRender
        if={incomeExpenseType === INCOME_EXPENSE_TYPE.company_person_id && type === PaymentTypeEnum.EXPENSE}
      >
        <Party form={form} />
      </ConditionalRender>
    </>
  );
};

export default ResourceType;
