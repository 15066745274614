import React from "react";

import Content from "./content";
import Header from "./header";

const Index: React.FC = () => (
  <div className="flex flex-col gap-8">
    <Header />
    <Content />
  </div>
);

export default Index;
