import React from "react";
import { useDispatch } from "react-redux";
import { UseFormReturn, useWatch } from "react-hook-form";
import { Button } from "antd";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/reducers/partyReducer";

import { cx, isEmptyArr } from "modules/common";
import { PartyForm } from "modules/party/create";
import { PartyStatus } from "modules/party/view";

import { ConditionalRender, FileQuestionsIcon } from "components";

import styles from "./index.module.scss";

type Props = {
  form: UseFormReturn<PartyForm>;
} & (
  | {
      isUpdate: true;
      status: PartyStatus;
    }
  | {
      isUpdate?: false;
    }
);

const Index: React.FC<Props> = ({ form, isUpdate, ...props }) => {
  const dispatch = useDispatch();
  const { setPartyCreate, setPartyUpdate } = partyReducerActions;
  const { isGroup, type } = useAppSelector(({ partyReducer }) => partyReducer.create);
  const { isProcess } = useAppSelector(({ partyReducer }) => partyReducer.update);
  const { status, warehouse_products, company_person_group } = useWatch({ control: form.control });
  const isConfirm = !isGroup ? !isEmptyArr(warehouse_products) : !isEmptyArr(company_person_group);

  const onClose = () => {
    if (isUpdate) {
      dispatch(
        setPartyUpdate({
          id: undefined,
          visible: false,
          isVat: false,
          isGroup: false,
          isDiscount: false,
          isProcess
        })
      );
    } else {
      dispatch(
        setPartyCreate({
          visible: false,
          isVat: false,
          isGroup: false,
          type: "supply",
          isDiscount: false
        })
      );
    }
  };

  const onChangeStatus = (status: PartyStatus) => () => {
    form.setValue("status", status);
  };

  return (
    <div
      className={cx(
        "flex items-center justify-between border border-b-0 border-l-0 border-r-0 border-solid border-gray-300 bg-white p-5"
      )}
    >
      <ConditionalRender if={isConfirm && type !== "warehouse"} else={<span />}>
        <Button
          htmlType="submit"
          onClick={onChangeStatus("open")}
          loading={status === "open" && form.formState.isSubmitting}
          className={cx("border-none bg-gray-200 text-base font-semibold text-gray-600", styles.open)}
        >
          <FileQuestionsIcon />
          Qoralama
        </Button>
      </ConditionalRender>
      <div className="flex items-center gap-2.5">
        <Button onClick={onClose}>Yopish</Button>
        <ConditionalRender if={isConfirm}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={onChangeStatus("ordered")}
            loading={status === "ordered" && form.formState.isSubmitting}
          >
            {(() => {
              if (isUpdate && "status" in props) {
                return props?.status === "open" ? "Partiya qilish" : "Saqlash";
              }

              return "Partiya qilish";
            })()}
          </Button>
        </ConditionalRender>
      </div>
    </div>
  );
};

export default Index;
