import React, { useMemo } from "react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { Checkbox, Spin } from "antd";
import NotUserImage from "features/app/components/not-image/NotUserImage";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { useGetCompanyPersons } from "features/projects/pages/detailed-project/pages/estimate/pages/gantt/services/queries";
import { CompanyPersonModel } from "features/projects/utils/models/workModel";

import { AppendedEmployeesFormType } from "modules/append-employees";

import { LoadingIndicator } from "components";

import styles from "./inde.module.scss";

type Props = {
  company_persons: CompanyPersonModel[];
  search: string;
  form: UseFormReturn<AppendedEmployeesFormType>;
};

const UsersTabContent: React.FC<Props> = ({ company_persons, search, form }) => {
  const { data: companyPersonsSelect, isLoading } = useGetCompanyPersons();

  const selectedIds = useWatch({
    control: form.control,
    name: "company_person_ids",
    defaultValue: company_persons?.map(el => el?.id)
  });

  const generateUserData: {
    id: number;
    name: string;
    image: string | null;
  }[] = useMemo(() => {
    if (search) {
      return companyPersonsSelect
        ? companyPersonsSelect
            ?.filter(el => el?.name?.toLowerCase().includes(search.toLowerCase()))
            ?.map(item => ({
              id: item.id,
              name: item.name,
              image: ""
            }))
        : [];
    }
    return companyPersonsSelect
      ? companyPersonsSelect?.map(item => ({
          id: item.id,
          name: item.name,
          image: ""
        }))
      : [];
  }, [companyPersonsSelect, search]);

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator} wrapperClassName={styles.spinner}>
      <div className="flex h-full w-full flex-col items-center gap-5">
        {generateUserData && generateUserData?.length > 0 ? (
          generateUserData?.map((item, index) => (
            <div className="flex w-full items-center justify-between" key={item?.id}>
              <div className="flex items-center gap-[10px]">
                {item?.image ? (
                  <img src={item?.image as string} alt={item?.name?.[0]} className={styles.avatar} loading="lazy" />
                ) : (
                  <NotUserImage name={item?.name} />
                )}
                <span className="text-sm font-normal text-gray-900">
                  <PopoverShortName title={item?.name} length={35} />
                </span>
              </div>
              <Controller
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={selectedIds.includes(item.id)}
                    onChange={e => {
                      const newValue = e.target.checked
                        ? [...selectedIds, item.id]
                        : selectedIds.filter(id => id !== item.id);

                      field.onChange(newValue);
                    }}
                  />
                )}
                name="company_person_ids"
                control={form.control}
              />
            </div>
          ))
        ) : (
          <div className="flex h-full w-full items-center justify-center">
            <TableEmpty />
          </div>
        )}
      </div>
    </Spin>
  );
};

export default UsersTabContent;
