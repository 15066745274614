import React from "react";
import { useDispatch } from "react-redux";
import { Modal, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { filterOption, isEmptyArr } from "modules/common";
import { useWarehouses } from "modules/party/view";
import { ChevronDownIcon } from "modules/settings/uysot";
import { setWarehouses } from "modules/warehouse/create-transfer";

import { EmptyContent, TrashCircleIcon } from "components";

import styles from "./index.module.scss";

const { confirm } = Modal;

const ReceivedWarehouse: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { warehouses } = useWarehouses();
  const { sender_warehouse_id, warehouses: stateWarehouses } = useAppSelector(
    ({ warehouseCreateTransfer }) => warehouseCreateTransfer
  );

  const onAddWarehouses = (value: number[], option: DefaultOptionType[]) => {
    dispatch(
      setWarehouses(
        option?.map(({ label }, index) => ({
          id: value[index],
          name: label as string
        }))
      )
    );
  };

  const onChangeReceivedWarehouses = (value: number[], option: DefaultOptionType[]) => {
    let removeWarehouseId = 0;

    const isAdd = stateWarehouses?.every(({ recieved_warehouse_id }) => {
      const isCondition = value?.some(id => id === recieved_warehouse_id);

      if (!isCondition) {
        removeWarehouseId = recieved_warehouse_id;
      }

      return isCondition;
    });

    if (isAdd) {
      onAddWarehouses(value, option);
    } else {
      const removeWarehouseProducts = stateWarehouses?.find(
        ({ recieved_warehouse_id }) => removeWarehouseId === recieved_warehouse_id
      )?.products;

      if (!isEmptyArr(removeWarehouseProducts)) {
        confirm({
          centered: true,
          destroyOnClose: true,
          wrapClassName: styles.warehouse_confirm,
          title: (
            <span className="text-lg font-semibold text-gray-900">
              {t("warehouseTransfer.Omborxonani o'chirmoqchimisiz")} ?
            </span>
          ),
          icon: <TrashCircleIcon />,
          content: (
            <span className="text-sm font-normal text-gray-600">
              {t(
                "warehouseTransfer.Ushbu omborxonani o'chirishinigz bilan kiritilgan mahsulotlar o'chirilib yuboriladi"
              )}{" "}
              !
            </span>
          ),
          okText: t("warehouseTransfer.O'chirish"),
          okType: "danger",
          cancelText: t("warehouseTransfer.Yopish"),
          onOk() {
            onAddWarehouses(value, option);
          }
        });
      } else {
        onAddWarehouses(value, option);
      }
    }
  };

  return (
    <div className="flex flex-1 flex-col gap-1.5">
      <label htmlFor="warehouses" className="text-sm font-medium text-gray-500">
        {t("warehouseTransfer.Omborxonalarga")}
      </label>
      <Select
        showSearch
        id="warehouses"
        mode="multiple"
        maxTagCount={3}
        maxTagTextLength={18}
        filterOption={filterOption}
        popupMatchSelectWidth={false}
        disabled={!sender_warehouse_id}
        suffixIcon={<ChevronDownIcon />}
        notFoundContent={<EmptyContent />}
        placeholder={t("warehouseTransfer.Tanlang")}
        value={stateWarehouses?.map(({ recieved_warehouse_id }) => recieved_warehouse_id)}
        rootClassName={styles.received_warehouse}
        onChange={onChangeReceivedWarehouses as never}
        options={warehouses
          ?.filter(({ id }) => sender_warehouse_id !== id)
          ?.map(({ id, name }) => ({
            value: id,
            label: name as string,
            props: {
              name
            }
          }))}
      />
    </div>
  );
};

export default ReceivedWarehouse;
