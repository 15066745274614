import React from "react";
import i18n from "i18next";

import { getPopupContainer } from "modules/party/create";
import { ProductFormControl } from "modules/party/create-product";
import { useUnits } from "modules/party/view";

import { Select } from "components";

type Props = {
  isUpdate?: boolean;
  control: ProductFormControl;
};

const Unit: React.FC<Props> = ({ control, isUpdate }) => {
  const { units } = useUnits();

  return (
    <Select
      name="unit_id"
      withoutMessage
      label="Birligi"
      control={control}
      placeholder="Mahsulot birligini tanlang"
      getPopupContainer={getPopupContainer(isUpdate ? "update-product" : "create-product")}
      options={units?.map(units => ({
        key: units?.id,
        value: units?.id,
        label: units?.symbol?.[i18n.language],
        props: {
          name: units?.symbol?.[i18n.language]
        }
      }))}
    />
  );
};

export default Unit;
