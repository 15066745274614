import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PartyViewForm, useCurrencies } from "modules/party/view";

import { Select } from "components";

import styles from "./items.module.scss";

type Props = {
  form: UseFormReturn<PartyViewForm>;
};

const Currency: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const { currencies } = useCurrencies();

  return (
    <Select
      withoutMessage
      withLabel={false}
      suffixIcon={null}
      variant="borderless"
      control={form.control}
      name="product.currency_id"
      className={styles.unit_select}
      placeholder={t("partyView.Tanlang")}
      options={currencies?.map(currency => ({
        key: currency?.id,
        value: currency?.id,
        label: currency?.symbol,
        props: {
          name: currency?.symbol
        }
      }))}
    />
    // <Item
    //   name="currency_id"
    //   className="m-0 h-full w-full"
    //   rules={formRules()}
    //   initialValue={updateProduct?.currency?.id}
    // >
    //   <Select
    //     showSearch
    //     suffixIcon={null}
    //     variant="borderless"
    //     placeholder={t("partyView.Tanlang")}
    //     filterOption={filterOption}
    //     popupMatchSelectWidth={false}
    //     className={styles.unit_select}
    //   >
    //     {currencies?.map(currency => (
    //       <Option key={currency?.id} value={currency?.id} props={{ name: currency?.symbol }}>
    //         {currency?.symbol}
    //       </Option>
    //     ))}
    //   </Select>
    // </Item>
  );
};

export default Currency;
