import React from "react";
import { Form, FormInstance, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { RU } from "features/app/utils/constants/languages";
import QuantityInput from "features/warehouse/components/transfers/transfer-making-drawer/selected-products-table/QuantityInput";
import { TransferBodyModel } from "features/warehouse/utils/models/transferBodyModel";
import { ITransferringResourcesModel } from "features/warehouse/utils/models/transferringResourcesModel";
import { useTranslation } from "react-i18next";

import { cx } from "modules/common";

import { LoadingIndicator } from "components";

import ProductSelect from "./ProductSelect";

import styles from "./selectedProductsTable.module.scss";

const { useWatch } = Form;

type Props = {
  form: FormInstance<TransferBodyModel>;
  isLoading: boolean;
};

const UpdateTable: React.FC<Props> = ({ form, isLoading }) => {
  const { t, i18n } = useTranslation();
  const products = useWatch("products", form) as ITransferringResourcesModel[];

  const onDelete = (record: ITransferringResourcesModel) => {
    const filteredProducts = products?.filter(el => el?.rowId !== record?.rowId);

    form.setFieldValue("products", filteredProducts);
  };

  const columns: ColumnsType<ITransferringResourcesModel> = [
    {
      title: t("warehouse.Resurs turi va nomi"),
      render: (__: any, record: ITransferringResourcesModel, index: number) => (
        <ProductSelect form={form} index={index} rowId={record?.rowId} />
      ),
      width: "45rem",
      key: "resource_name_and_type"
    },
    {
      title: t("warehouse.Miqdori"),
      render: (record: ITransferringResourcesModel) => record?.total_quantity?.toLocaleString(RU),
      align: "center",
      key: "total_quantity",
      width: "5.625rem"
    },
    {
      title: t("warehouse.O'tkazma miqdori"),
      render: (_, record) => (
        <QuantityInput<"update">
          key={`${record?.product?.id}/${record?.unit?.id}`}
          form={form}
          record={record}
          action="update"
          uniqueId={`${record?.product?.id}-${record?.unit?.id}`}
        />
      ),
      align: "center",
      key: "transfer_quantity",
      width: "9.25rem"
    },
    {
      title: t("warehouse.Birligi"),
      render: (record: ITransferringResourcesModel) => record?.unit?.symbol[i18n.language],
      align: "center",
      key: "unit.symbol",
      width: "8.125rem"
    },
    {
      title: "",
      render: record => (
        <span className={styles.delete} onClick={() => onDelete(record)}>
          <DeleteIcon />
        </span>
      ),
      width: "3.25rem"
    }
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      dataSource={products}
      rowClassName={styles.update_row}
      className={cx("footer_table", styles.update_table)}
      locale={{
        emptyText: <TableEmpty />
      }}
      loading={{
        spinning: isLoading,
        indicator: LoadingIndicator
      }}
    />
  );
};

export default UpdateTable;
