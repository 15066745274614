import React from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { isEmptyArr } from "modules/common";

import { PackageIcon } from "components";

import Item from "./item";

const Products: React.FC<{ index: number }> = ({ index }) => {
  const { t } = useTranslation();
  const { products } = useAppSelector(({ warehouseCreateTransfer }) => warehouseCreateTransfer.warehouses[index]);

  if (isEmptyArr(products)) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center gap-2 text-center">
        <PackageIcon />
        <span className="text-lg font-semibold text-gray-900">{t("warehouseTransfer.Resurs mavjud emas")} !</span>
        <span className="text-base font-normal text-gray-400">
          {t("warehouseTransfer.Resurs qidiruvi orqali omborxonaga resurlar jo’nating.")}
        </span>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 overflow-y-auto">
      {products?.map((product, childIndex) => (
        <Item key={`${product?.product_id}-${product?.unit_id}`} {...product} index={childIndex} parentIndex={index} />
      ))}
    </div>
  );
};

export default Products;
