import React from "react";
import { useDispatch } from "react-redux";
import { Switch } from "antd";

import { useAppSelector } from "hooks/redux";

import { setPaymentTypeItem } from "modules/settings/uysot";

type Props = {
  index: number;
};

const PaymentTypeStatus: React.FC<Props> = ({ index }) => {
  const dispatch = useDispatch();
  const { payment_types } = useAppSelector(({ uysot }) => uysot);
  const thisPaymentType = payment_types?.[index];

  const onChange = (e: boolean) => {
    if (thisPaymentType?.payment_type_id)
      dispatch(
        setPaymentTypeItem({
          index,
          key: "status",
          value: e ? "active" : "passive"
        })
      );
  };

  return (
    <div className="flex justify-end">
      <Switch
        onChange={onChange}
        disabled={!thisPaymentType?.payment_type_id}
        checked={thisPaymentType?.status === "active"}
      />
    </div>
  );
};

export default PaymentTypeStatus;
