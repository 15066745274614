import React from "react";
import { Button } from "antd";
import FilterDrawer from "features/app/components/filter-drawer/FilterDrawer";
import FiltersCount from "features/app/components/filter-drawer/filters-count/FiltersCount";
import FilterLinesIcon from "features/warehouse/assets/icons/FilterLinesIcon";
import { useTranslation } from "react-i18next";

const queryStringArr: string[] = [];

const SpentOfResourcesFilterDrawer: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const onOpenFilter = () => {
    setOpen(true);
  };

  const filterButton = (
    <Button onClick={onOpenFilter}>
      <FilterLinesIcon /> {t("works.Filter")}
      <FiltersCount queryStringArr={queryStringArr} />
    </Button>
  );

  return <FilterDrawer open={open} filterButton={filterButton} setOpen={setOpen} />;
};

export default SpentOfResourcesFilterDrawer;
