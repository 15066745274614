export const partyQueryKeys = {
  VIEW: "party-one-view",
  UNITS: "party-units",
  WAREHOUSES: "party-warehouses",
  PROJECTS: "party-projects",
  COMPANY_PERSONS: "party-company-persons",
  PRODUCTS: "party-products",
  CURRENCIES: "party-currencies",
  VAT: "party-vats",
  PAYMENT_TYPES: "party-payment-view"
};
