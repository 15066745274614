import React from "react";

const SendMessageIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.74964 11.25L17.4996 2.50004M8.85596 11.5234L11.0461 17.1551C11.239 17.6512 11.3355 17.8993 11.4745 17.9717C11.595 18.0345 11.7385 18.0346 11.8591 17.9719C11.9982 17.8997 12.0949 17.6517 12.2884 17.1558L17.7804 3.08271C17.9551 2.63506 18.0424 2.41123 17.9946 2.26821C17.9532 2.144 17.8557 2.04653 17.7315 2.00504C17.5885 1.95726 17.3646 2.0446 16.917 2.2193L2.84386 7.71125C2.34796 7.90477 2.10001 8.00153 2.02775 8.14061C1.96512 8.26119 1.9652 8.40473 2.02798 8.52523C2.1004 8.66423 2.34846 8.7607 2.84458 8.95363L8.47625 11.1437C8.57696 11.1829 8.62731 11.2025 8.66972 11.2327C8.7073 11.2595 8.74016 11.2924 8.76697 11.33C8.79721 11.3724 8.8168 11.4227 8.85596 11.5234Z"
      stroke="white"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SendMessageIcon;
