import { isEmptyArr, parseLocaledString, replacePhone } from "modules/common";

import { CompanyPersonForm, CompanyPersonReqPayload } from "../schema";

export function parseReqPayload(values: CompanyPersonForm): CompanyPersonReqPayload {
  const result: CompanyPersonReqPayload = {
    ...(values as unknown as CompanyPersonReqPayload)
  };

  result.phone = replacePhone(values?.phone);

  const balances = values?.balances
    ?.filter(({ start_amount }) => start_amount)
    ?.map(({ currency_id, start_amount }) => ({
      currency_id,
      start_amount: parseLocaledString(start_amount || "0")
    }));

  result.balances = isEmptyArr(balances) ? undefined : balances;

  return result;
}
