import React from "react";
import { RU } from "features/app/utils/constants/languages";

type Props = {
  symbol: string;
  quantity: number;
  receivedQuantity: number;
};

const PopoverContent: React.FC<Props> = ({ symbol, quantity, receivedQuantity }) => (
  <div className="flex flex-col gap-2">
    <div className="flex items-center justify-between gap-6">
      <p className="m-0 text-sm font-medium text-gray-600">Buyurtma</p>
      <p className="m-0 flex items-center gap-1.5 text-sm font-medium text-gray-600">
        <span>{quantity?.toLocaleString(RU)}</span>
        <span>{symbol}</span>
      </p>
    </div>
    <div className="flex items-center justify-between gap-6">
      <p className="m-0 text-sm font-medium text-gray-600">Qabul qilindi</p>
      <p className="m-0 flex items-center gap-1.5 text-sm font-medium text-success-500">
        <span>{receivedQuantity?.toLocaleString(RU)}</span>
        <span>{symbol}</span>
      </p>
    </div>
    <div className="flex items-center justify-between gap-6">
      <p className="m-0 text-sm font-medium text-gray-600">Kutilmoqda</p>
      <p className="m-0 flex items-center gap-1.5 text-sm font-medium text-warning-500">
        <span>{receivedQuantity > quantity ? 0 : (quantity - receivedQuantity)?.toLocaleString(RU)}</span>
        <span>{symbol}</span>
      </p>
    </div>
  </div>
);

export default PopoverContent;
