import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { routeSubmodules } from "features/impost/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { partyReducerActions } from "store/actions";

import { ConfirmationPaymentStatus, usePartyConfirm, usePartyConfirmRejected } from "modules/party/view";

import { ConditionalRender, ModalConfirm } from "components";

type Props = {
  confirmationPayment: ConfirmationPaymentStatus;
};

const Impost: React.FC<Props> = ({ confirmationPayment }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const confirm = usePartyConfirm();
  const rejected = usePartyConfirmRejected();
  const { setPartyView } = partyReducerActions;
  const { actions: actionsFunc } = useRoutePermissions("Monitoring", routeSubmodules);
  const { party_id, updateProduct, ...arg } = useAppSelector(state => state.partyReducer.view);
  const actions = actionsFunc("Partiyalar");

  const onCancel = () => {
    dispatch(
      setPartyView({
        ...arg,
        party_id,
        visible: false
      })
    );
  };

  const onConfirm = () => {
    confirm.mutateAsync({ id: party_id }).then(onCancel);
  };

  const onRejected = () => rejected.mutateAsync({ id: party_id }).then(onCancel);

  return (
    <div className="flex w-full items-center justify-end gap-3">
      <Button onClick={onCancel} disabled={confirm.isLoading || confirm.isLoading}>
        {t("partyView.Yopish")}
      </Button>
      <ConditionalRender if={confirmationPayment === "pending"}>
        <ConditionalRender if={actions?.rejected}>
          <ModalConfirm
            onOk={onRejected}
            cancelText="Yopish"
            okText="Bekor qilish"
            title="Ushbu partiyani bekor qilmoqchimisiz ?"
            description="Eslatib o'tamiz ushbu partiya yaqin soniyalar ichida bekor qilinib yuboriladi."
          >
            <Button className="bg-gray-200" disabled={confirm.isLoading || !!updateProduct}>
              {t("partyView.Bekor qilish")}
            </Button>
          </ModalConfirm>
        </ConditionalRender>
        <ConditionalRender if={actions?.received}>
          <Button
            type="primary"
            onClick={onConfirm}
            loading={confirm.isLoading}
            disabled={rejected.isLoading || !!updateProduct}
          >
            {t("partyView.Tasdiqlash")}
          </Button>
        </ConditionalRender>
      </ConditionalRender>
    </div>
  );
};

export default Impost;
