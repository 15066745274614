import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Empty, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { isEmptyArr } from "modules/common";
import {
  ArrowNarrowRightIcon,
  removePunctuation,
  setCurrencies,
  useCurrencies,
  useUysotCurrencies,
  UysotCurrency
} from "modules/settings/uysot";

import { LoadingIndicator } from "components";

import UysotCurrencyFooter from "./footer";
import CurrencyStatus from "./status";
import UyqurCurrency from "./uyqur-currency";

import styles from "./index.module.scss";

const UysotCurrencyContent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currencies: uyqurCurrencies } = useCurrencies();
  const { currencies, isLoading, isFetching } = useUysotCurrencies();
  const { visible } = useAppSelector(({ uysot }) => uysot);

  const columns: ColumnsType<UysotCurrency> = [
    {
      key: 1,
      title: "Uysot",
      render: (_, { ccy }) => (
        <div className="flex w-full justify-between">
          <span>{ccy}</span>
          <ArrowNarrowRightIcon />
        </div>
      ),
      width: 300
    },
    {
      key: 2,
      title: "Uyqur",
      render: (_, __, index) => <UyqurCurrency index={index} />
    },
    {
      key: 3,
      title: t("uysot.Faollashtirish"),
      render: (_, __, index) => <CurrencyStatus index={index} />
    }
  ];

  useEffect(() => {
    if (!isEmptyArr(currencies) && visible) {
      dispatch(
        setCurrencies(
          currencies?.map(({ ccy, currency, status }) => {
            const uyqurCurrency = uyqurCurrencies?.find(({ symbol }) => {
              const oldSymbol = removePunctuation(symbol?.toUpperCase());
              const newSymbol = oldSymbol === "SOM" ? "UZS" : oldSymbol;

              return newSymbol === ccy.toUpperCase();
            });

            return {
              ccy,
              status,
              currency_id: currency?.id || uyqurCurrency?.id
            };
          })
        )
      );
    }
  }, [visible, currencies]);

  return (
    <div className="flex flex-col gap-4">
      <Table<UysotCurrency>
        rowKey="ccy"
        columns={columns}
        pagination={false}
        dataSource={currencies}
        rootClassName={styles.currency}
        loading={{
          spinning: isLoading || isFetching,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <Empty description="" />
        }}
      />
      <UysotCurrencyFooter />
    </div>
  );
};

export default UysotCurrencyContent;
