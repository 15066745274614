import React from "react";
import { UseFormReturn } from "react-hook-form";
import { routeSubmodules } from "features/impost/utils/constants/routeSubmodules";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { cx } from "modules/common";
import { ImpostColumns, PartyStatus, PartyTable, PartyView, PartyViewForm } from "modules/party/view";

import styles from "./index.module.scss";

type Props = {
  isLoading: boolean;
  status: PartyStatus;
  groupTable?: boolean;
  form: UseFormReturn<PartyViewForm>;
  products: PartyView["warehouse_products"] | undefined;
};

const Index: React.FC<Props> = ({ isLoading, products, status, groupTable, form }) => {
  const { actions: actionsFunc } = useRoutePermissions("Monitoring", routeSubmodules);
  const actions = actionsFunc("Partiyalar");

  return (
    <div className="flex-1">
      <PartyTable
        type="impost"
        data={products}
        loading={isLoading}
        className={cx({
          [styles.group_table]: groupTable
        })}
        columns={ImpostColumns({ form, status, actions })}
      />
    </div>
  );
};

export default Index;
