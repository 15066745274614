import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import Pagination from "features/app/components/pagination/Pagination";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { colors } from "features/app/utils/constants/colors";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { useTranslation } from "react-i18next";

import { cx, RU } from "modules/common";
import { ProcessTableData } from "modules/works";

import { LoadingIndicator } from "components";

import { ProcessViewModal } from "../../modals";

import styles from "./index.module.scss";

type Props = {
  data: PaginationType<ProcessTableData[]>;
  isLoading: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const Bottom: React.FC<Props> = ({ isLoading, data, setPage }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [record, setRecord] = React.useState<ProcessTableData | null>(null);

  const columns: ColumnsType<ProcessTableData> = [
    {
      title: t("works.№"),
      render: (_value: any, _record, index) => index + 1,
      width: 46
    },
    {
      title: t("works.Miqdori"),
      render: (_value: any, record) => (
        <div className="text-sm font-medium text-gray-700">{record?.quantity?.toLocaleString(RU)}</div>
      ),
      width: 172
    },
    {
      title: t("works.Summa"),
      render: (_value: any, record) => (
        <div className="flex items-center gap-1">
          <span className="text-sm font-medium text-gray-700">{record?.total_amount?.toLocaleString(RU)}</span>
          <span className="text-sm font-medium text-gray-400">{record?.currency?.symbol}</span>
        </div>
      ),
      width: 234
    },
    {
      title: t("works.Loyiha"),
      render: (_value: any, record) => (
        <div className="text-sm font-medium text-gray-700">{record?.task?.section?.project?.name}</div>
      ),
      width: 234
    },
    {
      title: t("works.Sana"),
      dataIndex: "created_date",
      width: 124
    },
    {
      title: "",
      render: (_, _record: ProcessTableData) => (
        <div className={styles.arrow}>
          <ArrowNarrowIcon rotate={180} stroke={colors.GRAY_500} />
        </div>
      ),
      width: 70
    }
  ];

  const onRow = (record: ProcessTableData) => ({
    onClick: () => {
      setOpen(true);
      setRecord(record);
    }
  });

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const footer = () => (
    <div className="p-2">
      <Pagination
        paginationProps={{
          total: data.total,
          current: data.current_page,
          onChange: onPageChange
        }}
      />
    </div>
  );

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data?.data}
        footer={data?.total > 10 ? footer : undefined}
        className={cx("footer_table")}
        rowClassName={styles.row}
        pagination={false}
        locale={{
          emptyText: <TableEmpty />
        }}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        onRow={onRow}
      />
      <ProcessViewModal record={record!} visible={open} setVisible={setOpen} />
    </div>
  );
};

export default Bottom;
