export const sliceNames = {
  USER: "user",
  PROJECTS: "projects",
  PAYMENT: "payment",
  WORK_MODAL: "work-modal",
  TASK_PRODUCTS: "task_products",
  TRANSFER_MODAL: "transfer-modal",
  DEFECT_MODAL: "defect-modal",
  DEFECT_VIEW: "defect-view",
  DEFECT_CHAT: "defect-chat",
  WAREHOUSE_TRANSFER: "warehouse-transfer",
  SUPPLY: "supply",
  TRANSFER: "transfer",
  WAREHOUSE_ORDER: "warehouse-order",
  WAREHOUSE_PRODUCTS: "warehouse-products",
  PRODUCTS_TEMPLATE: "products template",
  COMPANY_PERSON: "company-person",
  INVENTORY: "inventory",
  NOTIFICATION: "notification",
  GANTT: "gantt",
  INTEGRATION: "integration",
  IMPOST: "impost",
  SETTINGS: "settings",
  SUPPLY_OFFER: "supply-offer",
  APP: "app",
  ADDRESS: "address",
  BUILDER: "builder",
  LOCALSTORAGE: "localstorage",
  TABLE_WIDGET_CONTENT_MODAL: "table_widget_content_modal",
  PARTY: "party",
  UYSOT: "uysot"
};
