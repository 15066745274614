import React from "react";
import { Switch } from "antd";
import { useTranslation } from "react-i18next";

import { TextEditable } from "components";

type Props = {
  text: string;
  title: string;
  fields: { [key: string]: string }[];
  updateTemplate: (value: string) => void;
  isLoading: boolean;
  index?: number;
  isEditable: boolean;
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
};

const Card: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { isEditable, setIsEditable, ...rest } = props;

  const onOpenEditable = (value: boolean) => {
    setIsEditable(value);
  };

  return (
    <div className="w-full rounded-xl border border-solid border-gray-200">
      <div className="flex items-center justify-between p-5">
        <div className="flex flex-col gap-2">
          <span className="text-base font-semibold text-gray-500">{t("settings.Kontragentga")}</span>
          <span className="text-xs font-medium text-gray-300">{t("settings.Lorem ipsum")}</span>
        </div>
        <Switch onChange={onOpenEditable} value={isEditable} />
      </div>
      {isEditable && (
        <div className="flex items-center justify-center border-b-0 border-l-0 border-r-0 border-t border-solid border-t-gray-200 p-4">
          <TextEditable {...rest} />
        </div>
      )}
    </div>
  );
};

export default Card;
