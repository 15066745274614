import React, { useMemo } from "react";
import { Popover, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { colors } from "features/app/utils/constants/colors";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import { WorkProgressModelById } from "features/projects/utils/models/WorkProgressModel";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";
import { useTranslation } from "react-i18next";

import { cx, RU } from "modules/common";

import ExpandedRow from "./ExpandedRow";

import styles from "../workModalTabs.module.scss";

type Props = {
  tab: string;
  progressesById: WorkProgressModelById[];
  isLoading: boolean;
};

const ProgressesById: React.FC<Props> = ({ progressesById, isLoading, tab }) => {
  const { t, i18n } = useTranslation();
  const baseCurrency = getBaseCurrency();

  const generateFilteredData = useMemo(() => {
    if (tab && tab !== "all") {
      return progressesById?.filter(el => el?.product?.resource?.type === tab);
    }
    return progressesById;
  }, [progressesById, tab]);

  const rowKeys = progressesById?.map(el => el?.id);

  const secondColumns: ColumnsType<WorkProgressModelById> = [
    {
      title: t("works.Nomi"),
      render: (record: WorkProgressModelById) => (
        <div className="resource">
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          {record?.product?.name[i18n.language]?.length > 50 ? (
            <Popover title={record?.product?.name[i18n.language]} overlayClassName={styles.popover}>
              <span className={styles.bold}>{record?.product?.name[i18n.language]?.substring(0, 50)}...</span>
            </Popover>
          ) : (
            <span className={styles.bold}>{record?.product?.name[i18n.language]}</span>
          )}
        </div>
      ),
      width: "507px"
    },
    {
      title: t("works.Bajarilgan ish hajmi"),
      render: (_value: any, record: WorkProgressModelById) => (
        <div className="flex items-center gap-1">
          <span className="text-sm font-medium text-gray-700">{record?.spend_total_quantity}</span>
          <span className="text-sm font-medium text-gray-400">{record?.unit?.symbol[i18n.language]}</span>
        </div>
      ),
      width: "391x"
    },
    {
      title: t("works.Birlik summasi"),
      render: (_value: any, record: WorkProgressModelById) => (
        <span className="text-sm font-medium text-gray-900">
          {record?.spend_amount?.toLocaleString(RU)} {baseCurrency?.symbol}
        </span>
      ),
      width: "296px",
      align: "center"
    },
    {
      title: t("works.Umumiy summasi"),
      render: (_value: any, record: WorkProgressModelById) => (
        <span className="text-sm font-medium text-gray-900">
          {record?.spend_total_amount?.toLocaleString(RU)} {baseCurrency?.symbol}
        </span>
      ),
      width: "297px",
      align: "center"
    },
    {
      title: "",
      render: (record: WorkProgressModelById) => (
        <div className={styles.arrow}>
          <ArrowNarrowIcon rotate={180} stroke={colors.GRAY_500} />
        </div>
      )
    }
  ];

  return (
    <Table<WorkProgressModelById>
      dataSource={generateFilteredData}
      columns={secondColumns}
      rowKey={record => record?.id}
      pagination={false}
      scroll={{ y: 560 }}
      expandable={{
        expandedRowRender: (record, _, __, expanded) => (
          <ExpandedRow
            data={
              record?.product?.resource?.type === "product" ? record?.warehouse_products : record?.task_progress_details
            }
            key={record?.id}
            progress={record}
          />
        ),
        expandIcon: ({ expanded, onExpand, record }) => (
          <ChevronDownIcon
            rotate={expanded}
            onClick={e => {
              e.stopPropagation();

              onExpand(record, e as never);
            }}
            cursor="pointer"
          />
        ),
        defaultExpandAllRows: true,
        defaultExpandedRowKeys: rowKeys
      }}
      locale={{
        emptyText: <TableEmpty />
      }}
      footer={undefined}
      loading={{ spinning: isLoading, indicator: LoadingIndicator }}
      className={cx(styles.second_table)}
    />
  );
};

export default ProgressesById;
