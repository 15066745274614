import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Dropdown, TableColumnsType, Tooltip } from "antd";
import { ItemType } from "antd/lib/menu/interface";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "features/app/assets/icons/DotsVerticalIcon";
import EditIcon from "features/app/assets/icons/EditIcon";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import OrderSortBy from "features/app/components/order-sort-by/OrderSortBy";
import TableSettings from "features/app/components/table-settings/TableSettings";
import TemplateDownload from "features/app/components/template-download/TemplateDownload";
import UnreadMessageAction from "features/app/components/unread-message/UnreadMessageAction";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetMineCashSelect, useGetTableConfigs } from "features/app/service/queries";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { PaymentStatusEnums } from "features/app/utils/enums/paymentStatusEnums";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { GetComponentProps } from "features/app/utils/models/getComponentPropsType";
import { PaymentModel } from "features/app/utils/models/payment/paymentModel";
import PrinterIcon from "features/counterparts/assets/icons/PrinterIcon";
import { counterpartsQueryKeys } from "features/counterparts/utils/constants/counterpartsQueryKeys";
import FileQuestionIcon from "features/payment/assets/icons/FileQuestionIcon";
import FileXIcon from "features/payment/assets/icons/FileXIcon";
import IncomeExpensePrintContent from "features/payment/components/bottom/tables/income-expense/IncomeExpensePrintContent";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";
import { PaymentTypeIcon } from "features/payment/utils/helpers/paymentTypeIcon";
import { paymentIncomeOrExpenseTableDefaultData } from "features/supply/utils/constants/paymentIncomeOrExpenseTableDefaultData";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

import { useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { chatActions } from "store/reducers/chatReducer";
import { paymentReducerActions } from "store/reducers/paymentReducer";

import { Table } from "components";
import useDebounce from "components/use-debounce/use-debounce";

import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../../app/utils/constants/languages";
import { PaymentTabKeys } from "../../../../../../payment/utils/constants/paymentTabKeys";
import { useGetCompanyPersonPayment } from "../../../../../service/queries";
import { CounterpartsPaymentModel } from "../../../../../utils/models/counterpartsPaymentModel";

import styles from "./incomExpenseTable.module.scss";

type Props = {
  defaultTabKey?: string;
  setPaymentDeleteModal: (
    value: React.SetStateAction<{
      visible: boolean;
      id: number;
    }>
  ) => void;
  reactToPrintContent: () => HTMLDivElement | null;
  printComponentRef: React.MutableRefObject<HTMLDivElement | null>;
};

const IncomeExpenseTable: React.FC<Props> = ({
  defaultTabKey,
  reactToPrintContent,
  setPaymentDeleteModal,
  printComponentRef
}) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { data, isLoading } = useGetCompanyPersonPayment(defaultTabKey === PaymentTabKeys.INCOME_EXPENSE);
  const { t } = useTranslation();
  const qc = useQueryClient();
  const params = useParams();
  const { severalSearchParams, queries } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.CHECK_CURRENCY);

  const changeTableConfig = useChangeTableConfig(tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE, true);

  const { data: tableConfigData } = useGetTableConfigs(
    tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE,
    paymentIncomeOrExpenseTableDefaultData
  );
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.PAYMENT]);

  const dispatch = useDispatch();
  const { statistics_type: statisticsType } = queries();
  const [searchParam] = useSearchParams();

  const { data: cashes } = useGetMineCashSelect(!statisticsType || statisticsType === PaymentTabKeys.INCOME_EXPENSE);

  const { incomeOrExpenseView } = useAppSelector(state => state.paymentReducer);
  const oneCashParams = cashes?.length === 1 ? false : searchParam.getAll(queryParamsKeys.CASH_IDS_ARR)?.length !== 1;

  const { setVisible: setVisibleChat } = chatActions;
  const { setIncomeExpenseModal, setIncomeOrExpenseViewVisible, setConversionInfoModal, setTransferHistoryModal } =
    paymentReducerActions;

  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const paymentActions = actions("Kirim-chiqim");

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "To'lov",
    removeAfterPrint: true
  });

  const actionItems = (record: PaymentModel) => {
    const hasEdit = [PaymentTypeEnum.EXPENSE, PaymentTypeEnum.INCOME];

    return [
      {
        ...(hasEdit.includes(record?.type)
          ? {
              key: "1",
              label: (
                <>
                  <EditIcon /> {t("Counterparts.Tahrirlash")}
                </>
              ),
              className: styles.dropdown_item,
              onClick: e => {
                e.domEvent.stopPropagation();

                dispatch(
                  setIncomeExpenseModal({
                    visible: true,
                    defaultStep: 2,
                    defaultData: record,
                    isVisibleMoreInfo: true,
                    currency: record?.currency,
                    // @ts-expect-error
                    cash: record?.cash,
                    // @ts-expect-error
                    payment_type: record?.payment_type,
                    companyPersonId: record?.company_person?.id,
                    id: record?.id,
                    type: record?.type,
                    task: record?.task
                      ? {
                          id: record?.task?.id,
                          name: record?.task?.name,
                          index: record?.task?.place,
                          sectionName: record?.section?.name,
                          projectId: record?.section?.project_id
                        }
                      : undefined
                  })
                );
              }
            }
          : {})
      },
      {
        key: "2",
        label: <UnreadMessageAction count={record?.unread_message_count} />,
        className: styles.dropdown_item,
        onClick: e => {
          e.domEvent.stopPropagation();

          dispatch(
            setVisibleChat({
              visible: true,
              type: "payments",
              objectId: record?.id,
              dataKeys: [counterpartsQueryKeys.PAYMENT, searchParams],
              record
            })
          );
        }
      },
      {
        ...(paymentActions.delete
          ? {
              key: "3",
              label: (
                <>
                  <DeleteIcon /> {t("Counterparts.O’chirish")}
                </>
              ),
              className: styles.dropdown_item,
              onClick: e => {
                e.domEvent.stopPropagation();

                setPaymentDeleteModal({ id: record?.id, visible: true });
              }
            }
          : {})
      },
      {
        ...(record?.status !== PaymentStatusEnums.CANCEL
          ? {
              key: "4",
              label: (
                <>
                  <PrinterIcon /> {t("Counterparts.Chop etish")}
                </>
              ),
              className: styles.dropdown_item,
              onMouseEnter: () => {
                if (record?.id !== incomeOrExpenseView?.record?.id) {
                  dispatch(
                    setIncomeOrExpenseViewVisible({
                      visible: false,
                      record
                    })
                  );
                }
              },
              onClick: e => {
                e.domEvent.stopPropagation();

                handlePrint();
              }
            }
          : {})
      },
      {
        key: "5",
        label: <TemplateDownload object_id={record?.id} location={CustomFieldLocationEnum.PAYMENT} styles={styles} />
      }
    ] as ItemType[];
  };

  const columns: TableColumnsType<CounterpartsPaymentModel> = [
    {
      title: `${t("Counterparts.Turi")}`,
      render: record => PaymentTypeIcon(record?.type, record?.status),
      key: "type",
      width: 80,
      fixed: "left",
      className: "counterparts-payment-type"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_DATE} title={t("Counterparts.Sana")} />,
      dataIndex: "date",
      key: "date",
      width: 130,
      className: "counterparts-payment-date"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_AMOUNT} title={t("Counterparts.Summa")} />,
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => `${record?.amount?.toLocaleString(RU)}  ${record?.currency?.symbol}`,
      width: 220,
      className: "counterparts-payment-amount"
    },
    {
      ...(oneCashParams && {
        title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_CASH_NAME} title={t("Counterparts.Kassa")} />,
        render: (_, record) => record?.cash?.name,
        width: 150,
        key: "cash.name",
        className: "counterparts-payment-cash.name"
      })
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_PROJECT_NAME} title={t("Counterparts.Loyiha")} />,
      render: (_, record) => record?.project?.name || "-",
      key: "project.name",
      width: 220,
      className: "counterparts-payment-project.name"
    },
    {
      title: `${t("Counterparts.To'lov turi")}`,
      render: (_, record) => (
        <div
          className={styles.payment_type}
          style={{
            color: record?.payment_type.color,
            background: `${record?.payment_type.color}20`
          }}
        >
          {record?.payment_type.name}
        </div>
      ),
      width: 220,
      key: "payment_type.name",
      className: "counterparts-payment-payment_type.name"
    },
    {
      title: `${t("Counterparts.Xodim")}`,
      render: (_, record) => <CustomAvatar name={record?.company_person?.name} />,
      width: 100,
      key: "creator.full_name",
      className: "counterparts-payment-creator.full_name"
    }
  ];

  const [tableColumns, setColumns] = useState<ColumnsType<CounterpartsPaymentModel>>([]);

  const footer = () => (
    <Pagination
      paginationProps={{
        total: data?.total,
        current: data?.current_page
      }}
    />
  );

  const onDetailIncomeOrExpense: GetComponentProps<PaymentModel> = (record: PaymentModel) => ({
    onClick: () => {
      if (record?.type === PaymentTypeEnum.EXCHANGE) {
        dispatch(setConversionInfoModal(record));
      } else if (record?.type === PaymentTypeEnum.TRANSFER) {
        dispatch(setTransferHistoryModal(record));
      } else {
        dispatch(
          setIncomeOrExpenseViewVisible({
            visible: true,
            record,
            queryKey: [counterpartsQueryKeys.PAYMENT, searchParams]
          })
        );
      }
    }
  });

  const saveColumns = () => {
    const columnsToSave = tableColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutateAsync({
      key: tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<CounterpartsPaymentModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useEffect(() => {
    if (tableConfigData && customFields) {
      setColumns([
        ...filterColumns({
          columns,
          customFields,
          tableConfig: tableConfigData
        }),
        {
          title: (
            <div className="flex items-center justify-end">
              <TableSettings
                isNeedSize
                locations={[CustomFieldLocationEnum.PAYMENT]}
                defaultData={paymentIncomeOrExpenseTableDefaultData}
                configKey={tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE}
              />
            </div>
          ),
          render: (__: any, record: PaymentModel) => (
            <div className="flex items-center justify-end gap-1">
              {record?.status === PaymentStatusEnums.PASSIVE && (
                <Tooltip title="Qoralama">
                  <span className={styles.passive}>
                    <FileQuestionIcon />
                  </span>
                </Tooltip>
              )}
              {record?.status === PaymentStatusEnums.CANCEL && (
                <Tooltip title="Bekor qilingan">
                  <span className={styles.cancel}>
                    <FileXIcon />
                  </span>
                </Tooltip>
              )}
              <Dropdown
                menu={{ items: actionItems(record).filter(item => item?.key) }}
                trigger={["click", "contextMenu"]}
                overlayStyle={{ zIndex: 1000 }}
              >
                <div
                  style={{ width: 24 }}
                  onClick={e => e.stopPropagation()}
                  className={classNames("d_f ai_c jc_c c_p", {
                    hasBadge: !!record?.unread_message_count
                  })}
                >
                  <DotsVerticalIcon />
                </div>
              </Dropdown>
            </div>
          ),
          fixed: "right",
          width: 100,
          className: "counterparts-payment-action"
        } as never
      ]);
    }
  }, [tableConfigData, customFields]);

  return (
    <>
      <Table
        onChangeColumns={onChangeColumns}
        columns={tableColumns}
        dataSource={data?.data}
        pagination={false}
        footer={footer}
        rowClassName="cursor-pointer"
        rowKey={row => row.id}
        scroll={{ y: "65vh" }}
        onRow={onDetailIncomeOrExpense as unknown as GetComponentProps<CounterpartsPaymentModel>}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      <IncomeExpensePrintContent key="1" ref={printComponentRef} tableColumns={tableColumns as never} />
    </>
  );
};

export default IncomeExpenseTable;
