import React from "react";
import { useDispatch } from "react-redux";
import { useForm, UseFormReturn, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Modal } from "antd";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/reducers/partyReducer";

import { PartyForm } from "modules/party/create";
import {
  Catalog,
  Footer,
  ProductForm,
  productFormSchema,
  Resource,
  SubCatalog,
  Unit
} from "modules/party/create-product";

import { Input } from "components";

type Props = {
  isUpdate?: boolean;
  parentForm: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ parentForm, isUpdate }) => {
  const dispatch = useDispatch();
  const { setPartyCreateProduct } = partyReducerActions;
  const { visible, name: oldName } = useAppSelector(({ partyReducer }) => partyReducer.createProduct);
  const form = useForm<ProductForm>({
    resolver: zodResolver(productFormSchema)
  });
  const { control, setValue } = form;
  const { name } = useWatch({ control });

  const onCancel = () => {
    dispatch(
      setPartyCreateProduct({
        visible: false,
        name,
        fieldName: ""
      })
    );
  };

  const onAfterOpenChange = (open: boolean) => {
    if (!open) {
      form.reset({
        name: "",
        unit_id: undefined,
        code: undefined,
        category_id: undefined,
        resource_id: undefined,
        subcategory_id: undefined
      });
    } else {
      form.reset({
        name: oldName,
        code: undefined,
        unit_id: undefined,
        category_id: undefined,
        resource_id: undefined,
        subcategory_id: undefined
      });
    }
  };

  return (
    <Modal
      centered
      open={visible}
      destroyOnClose
      okText="Saqlash"
      onCancel={onCancel}
      title="Mahsulot yaratish"
      afterOpenChange={onAfterOpenChange}
      footer={<Footer form={form} parentForm={parentForm} />}
    >
      <form id={isUpdate ? "update-product" : "create-product"} className="flex flex-col gap-5 py-3">
        <Input withoutMessage label="Nomi" name="name" control={control} placeholder="Mahsulot nomini kiriting" />
        <Resource control={control} isUpdate={isUpdate} />
        <Unit control={control} isUpdate={isUpdate} />
        <Input label="Mahsulot kodi" name="code" control={control} placeholder="Mahsulot kodini kiriting" />
        <Catalog control={control} setValue={setValue} isUpdate={isUpdate} />
        <SubCatalog control={control} setValue={setValue} isUpdate={isUpdate} />
      </form>
    </Modal>
  );
};

export default Index;
