import { inventoryTableDefaultDataFunc } from "features/inventory/utils/constants/inventoryTableDefaultData";
import { supplyOrdersConfigs } from "features/supply/utils/constants/supplyOrdersConfigs";
import { wareHouseProducts } from "features/supply/utils/constants/warehouseProducts";
import { warehouseExportProductsExcel } from "features/warehouse/utils/constants/warehouseProductTableDefaultData";

import { impostTableDefaultData } from "../../../impost/utils/constants/impostTableDefaultData";
import { estimateTableDefaultData } from "../../../projects/utils/constants/estimateTableDefaultData";
import { paymentIncomeOrExpenseTableDefaultData } from "../../../supply/utils/constants/paymentIncomeOrExpenseTableDefaultData";
import { paymentOrderTableDefaultData } from "../../../supply/utils/constants/paymentOrderTableDefaultData";
import { warehouseOfferTableDefaultData } from "../../../supply/utils/constants/warehouseOfferTableDefaultData";
import { warehouseOrderTableDefaultData } from "../../../supply/utils/constants/warehouseOrderTableDefaultData";
import { UserTableDataModel } from "../models/user/userTableConfigModel";

import { tableConfigKeys } from "./tableConfigKeys";

export const tableData = (key: tableConfigKeys, lng?: string) => {
  const lang = lng || "uz";
  // eslint-disable-next-line no-unused-vars
  const defaultTableData: { [key in tableConfigKeys]?: UserTableDataModel } = {
    [tableConfigKeys.PAYMENT_ORDER_CONFIG]: paymentOrderTableDefaultData,
    [tableConfigKeys.ORDER_CONFIG]: warehouseOrderTableDefaultData,
    [tableConfigKeys.WAREHOUSE_PRODUCT_OFFER_CONFIG]: warehouseOfferTableDefaultData,
    [tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG]: supplyOrdersConfigs(lang),
    [tableConfigKeys.ESTIMATE_CONFIG]: estimateTableDefaultData,
    [tableConfigKeys.IMPOST_ORDER_CONFIG]: impostTableDefaultData,
    [tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE]: paymentIncomeOrExpenseTableDefaultData,
    [tableConfigKeys.WAREHOUSE_PRODUCTS]: wareHouseProducts(lang),
    [tableConfigKeys.INVENTORY]: inventoryTableDefaultDataFunc(lang),
    [tableConfigKeys.WAREHOUSE_SINGLE_PRODUCT]: warehouseExportProductsExcel
  };

  return defaultTableData[key];
};
