import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Collapse, Divider, Modal, Spin } from "antd";
import classNames from "classnames";
import TooltipShortName from "features/app/components/tooltip-short-name/TooltipShortName";
import { colors } from "features/app/utils/constants/colors";
import { RU } from "features/app/utils/constants/languages";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { useAppSelector } from "../../../../../hooks/redux";
import { useQueryParams } from "../../../../../hooks/useQueryParams";
import { paymentReducerActions } from "../../../../../store/reducers/paymentReducer";
import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../app/components/modal-confirm/ModalConfirm";
import { PaymentTypeEnum } from "../../../../app/utils/constants/paymentTypeEnum";
import { PaymentStatusEnums } from "../../../../app/utils/enums/paymentStatusEnums";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import { useImpostPaymentsRecieved, useImpostPaymentsRejected } from "../../../../impost/service/mutations";
import FileQuestionIcon from "../../../assets/icons/FileQuestionIcon";
import FileXIcon from "../../../assets/icons/FileXIcon";
import { useDeletePayment } from "../../../service/mutation";
import { useGetPaymentView } from "../../../service/queries";
import { paymentFilterType } from "../../../utils/constants/paymentFilterType";
import { paymentQueryKeys } from "../../../utils/constants/paymentQueryKeys";
import { PaymentTypeIcon } from "../../../utils/helpers/paymentTypeIcon";

import CustomFields from "./CustomFields";
import { PaymentSourceElement } from "./PaymentSourceElement";

import styles from "./incomeExpenseView.module.scss";

const IncomeExpenseView: React.FC = () => {
  const qc = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { searchParams } = useQueryParams();
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const paymentActions = actions("Kirim-chiqim");
  const isCounterpart = pathname?.includes("detailed-counterpart");
  const { setIncomeOrExpenseViewVisible } = paymentReducerActions;
  const { orderId } = useAppSelector(state => state.paymentReducer.orderDeleteArchive);
  const { visible, record, isNotDelete, queryKey, isDeleteArchive } = useAppSelector(
    state => state.paymentReducer?.incomeOrExpenseView
  );
  const { data, isLoading } = useGetPaymentView({
    id: record?.id,
    enabled: visible,
    isDeleteArchive
  });

  const deletePayment = useDeletePayment();
  const recievedPayment = useImpostPaymentsRecieved();
  const rejectedPayment = useImpostPaymentsRejected();

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      dispatch(
        setIncomeOrExpenseViewVisible({
          visible: false,
          record: undefined
        })
      );
    }
  };

  const onCancel = () => {
    dispatch(
      setIncomeOrExpenseViewVisible({
        visible: false,
        record
      })
    );
  };

  const onAfterConfirm = async () => {
    onCancel();

    if (queryKey) {
      await qc.invalidateQueries(queryKey);
    }
  };

  const onReceivedPayment = () => {
    recievedPayment.mutateAsync({ id: record?.id }).then(onAfterConfirm);
  };

  const onRejectedPayment = () => {
    rejectedPayment.mutateAsync({ id: record?.id }).then(onAfterConfirm);
  };

  const onAfterDelete = async () => {
    if (orderId) {
      await qc.invalidateQueries([paymentQueryKeys.ORDER_DELETE_ARCHIVE, orderId]);
      await qc.invalidateQueries([paymentQueryKeys.PAYMENTS_ORDER_VIEW, searchParams]);
      await qc.invalidateQueries([paymentQueryKeys.PAYMENT_ORDER_VIEW, orderId, isCounterpart]);
    }
  };

  const footer = () => (
    <div className={styles.footer}>
      <div className={styles.footer__left}>
        <ConditionalRender if={record?.status === PaymentStatusEnums.PASSIVE}>
          <Button type="primary" onClick={onReceivedPayment} loading={recievedPayment.isLoading}>
            {t("payment.Tasdiqlash")}
          </Button>
          <Button onClick={onRejectedPayment} loading={rejectedPayment.isLoading}>
            {t("payment.Bekor qilish")}
          </Button>
        </ConditionalRender>
      </div>
      <div className={styles.footer__right}>
        <ConditionalRender if={!isNotDelete && paymentActions.delete}>
          <ModalConfirm
            isDescription
            mutation={deletePayment}
            onAfterClose={onAfterDelete}
            payload={{ id: record?.id }}
            setVisibleParentModal={setIncomeOrExpenseViewVisible}
          >
            <Button>
              <DeleteIcon /> {t("payment.O’chirish")}
            </Button>
          </ModalConfirm>
        </ConditionalRender>
        <Button onClick={onCancel}>{t("payment.Yopish")}</Button>
      </div>
    </div>
  );

  const statusElement = {
    [PaymentStatusEnums.PASSIVE]: (
      <span className={styles.passive}>
        <FileQuestionIcon /> Qoralama
      </span>
    ),
    [PaymentStatusEnums.CANCEL]: (
      <span className={styles.cancel}>
        <FileXIcon /> Bekor qilingan
      </span>
    )
  };

  const title = (
    <div className="flex items-center gap-3">
      {paymentFilterType[record?.type as PaymentTypeEnum]} - {record?.id}{" "}
      {statusElement[record?.status as keyof typeof statusElement] || ""}
    </div>
  );

  const customFieldItems = [
    {
      label: <span className={styles.customField_title}>O’zgaruvchilar</span>,
      key: "1",
      children: (
        <>
          {record?.custom_field_values?.map(item => (
            <div className={styles.content__item} key={item?.id}>
              <p>{item?.custom_field?.name[i18n.language]}</p>
              <CustomFields record={item} payment={record} />
            </div>
          ))}
        </>
      )
    }
  ];

  return (
    <Modal
      centered
      width={679}
      title={title}
      open={visible}
      zIndex={20000}
      destroyOnClose
      footer={footer()}
      onCancel={onCancel}
      afterOpenChange={onAfterOpen}
      className={styles.income_expense_view}
    >
      <Spin spinning={isLoading} indicator={LoadingIndicator}>
        <div className={styles.content}>
          <div className={styles.content__item}>
            <p>{t("payment.To’lov sanasi")}</p>
            <p>{data?.date}</p>
          </div>
          <div className={styles.content__item}>
            <p>{t("payment.Turi")}</p>
            <p>
              {PaymentTypeIcon(data?.type as string, data?.status)}
              {paymentFilterType[data?.type as PaymentTypeEnum]}
            </p>
          </div>
          <div className={styles.content__item}>
            <p>{t("payment.Summa")}</p>
            <p>
              {data?.amount?.toLocaleString(RU)} {data?.currency?.symbol}
            </p>
          </div>
          <ConditionalRender if={data?.currency_amount && data?.currency_amount > 1}>
            <div className={styles.content__item}>
              <p>{t("payment.Valyuta kursi")}</p>
              <p>{data?.currency_amount?.toLocaleString(RU)}</p>
            </div>
          </ConditionalRender>
          <ConditionalRender if={data?.payment_type?.name}>
            <div className={styles.content__item}>
              <p>{t("payment.To’lov turi")}</p>
              <p
                className={styles.payment_type}
                style={{
                  background: `${data?.payment_type?.color}40`
                }}
              >
                <span
                  style={{
                    color: data?.payment_type?.color
                  }}
                >
                  {data?.payment_type?.name}
                </span>
              </p>
            </div>
          </ConditionalRender>
          <ConditionalRender if={data?.cash?.name}>
            <div className={styles.content__item}>
              <p>Kassa</p>
              <p>{data?.cash?.name}</p>
            </div>
          </ConditionalRender>
          <div className={styles.content__item}>
            <p>Loyiha</p>
            <p>{data?.project?.name || "-"}</p>
          </div>
          <ConditionalRender if={data?.section}>
            <div className={styles.content__item}>
              <p>Ish bo'limi</p>
              <p>
                <span>{data?.section?.place}.</span>
                <TooltipShortName title={data?.section?.name} length={20} />
              </p>
            </div>
          </ConditionalRender>
          <ConditionalRender if={data?.task}>
            <div className={styles.content__item}>
              <p>Ish nomi</p>
              <p>
                <span>
                  {data?.section?.place}.{data?.task?.place}
                </span>
                <TooltipShortName title={data?.task?.name} length={30} />
              </p>
            </div>
          </ConditionalRender>
          <div className={styles.content__item}>
            <p>Manba</p>
            <PaymentSourceElement record={data} />
          </div>
          <ConditionalRender if={data?.inventory?.name} else="">
            <div className={styles.content__item}>
              <p>Inventar</p>
              <p>{data?.inventory?.name}</p>
            </div>
          </ConditionalRender>
          <ConditionalRender if={data?.creator?.full_name}>
            <div className={styles.content__item}>
              <p>Xodim</p>
              <p>{data?.creator?.full_name}</p>
            </div>
          </ConditionalRender>
          <ConditionalRender if={data?.description}>
            <div className={styles.content__item}>
              <p>Izoh</p>
              <p>{data?.description ? <TooltipShortName length={40} title={data?.description} /> : "-"}</p>
            </div>
          </ConditionalRender>
          <ConditionalRender if={data?.order_ids && data?.order_ids.length > 0}>
            <div className={styles.content__item}>
              <p>Partiyalar</p>
              <p>
                {data?.order_ids?.map((p, i) => `P - ${p} ${i !== (data?.order_ids?.length || 0) - 1 ? ", " : ""}`)}
              </p>
            </div>
          </ConditionalRender>
          <ConditionalRender if={!isEmptyArr(data?.financials)}>
            <div className="mt-2 flex flex-col gap-3">
              <Divider>{t("payment.Birikkan xarajatlar")}</Divider>
              {data?.financials?.map(finance => (
                <div key={finance?.id} className="box-border flex flex-col gap-3 rounded-xl bg-gray-100 p-3">
                  <div className="flex w-full items-center justify-between">
                    <p className="m-0 text-sm font-normal text-gray-600">{t("payment.Xarajat turi")}</p>
                    <p className="m-0 text-sm font-medium text-gray-700">{finance?.name}</p>
                  </div>
                  <div className="flex w-full items-center justify-between">
                    <p className="m-0 text-sm font-normal text-gray-600">{t("payment.Summa")}</p>
                    <p className="m-0 flex items-center gap-1 text-sm font-medium text-gray-700">
                      <span>{finance?.amount?.toLocaleString(RU)}</span>
                      <span>{data?.currency?.symbol}</span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </ConditionalRender>
          <ConditionalRender if={data?.custom_field_values && data?.custom_field_values?.length > 0}>
            <Collapse
              ghost
              items={customFieldItems}
              className={classNames(styles.customField, "simpleCollapse")}
              expandIcon={({ isActive }) => (
                <SelectSuffixIcon stroke={colors.GRAY_400} placement={isActive ? "bottom" : "right"} />
              )}
            />
          </ConditionalRender>
        </div>
      </Spin>
    </Modal>
  );
};

export default IncomeExpenseView;
