import IntegrationIcon from "features/app/assets/icons/IntegrationIcon";

import { Permission } from "modules/auth";

import { settingsPaths } from "../../../../routes/settings/settingsPaths";
import { settingsRoutes } from "../../../../routes/settings/settingsRoutes";
import ArchiveIcon from "../../../supply/assets/icons/ArchiveIcon";
import AnchorIcon from "../../assets/icons/AnchorIcon";
import CustomFieldIcon from "../../assets/icons/CustomFieldIcon";
import SettingsCashIcon from "../../assets/icons/SettingsCashIcon";
import SettingsEmployeesIcon from "../../assets/icons/SettingsEmployeesIcon";
import SettingsFileIcon from "../../assets/icons/SettingsFileIcon";
import SettingsFinanceIcon from "../../assets/icons/SettingsFinanceIcon";
// import SettingsFinancialIcon from "../../assets/icons/SettingsFinancialIcon";
import SettingsInterfaceIcon from "../../assets/icons/SettingsInterfaceIcon";
import SettingsProductsIcon from "../../assets/icons/SettingsProductsIcon";
import SettingsWarehouseIcon from "../../assets/icons/SettingsWarehouseIcon";
import { RootLinkModel } from "../../utils/models/RootLinkModel";

export const allLinks: RootLinkModel[] = [
  {
    icon: <SettingsProductsIcon />,
    link: settingsPaths.PRODUCTS,
    name: "settings.interface.Kataloglar"
  },
  {
    icon: <SettingsFinanceIcon />,
    link: settingsPaths.FINANCE,
    name: "settings.interface.Moliya"
  },
  {
    icon: <SettingsCashIcon />,
    link: settingsPaths.CASH,
    name: "settings.interface.Kassa"
  },
  {
    icon: <SettingsWarehouseIcon />,
    link: settingsPaths.WAREHOUSE,
    name: "settings.interface.Omborxona"
  },
  {
    icon: <SettingsEmployeesIcon />,
    link: settingsPaths.ADMINS,
    name: "settings.interface.Xodimlar"
  },
  // {
  //   icon: <SettingsFinancialIcon />,
  //   link: settingsPaths.FINANCIAL,
  //   name: "settings.interface.Xarajat va daromad",
  // },
  {
    icon: <CustomFieldIcon />,
    link: settingsPaths.CUSTOM_FIELD,
    name: "settings.interface.O'zgaruvchilar"
  },
  {
    icon: <SettingsFileIcon />,
    link: settingsPaths.TEMPLATE,
    name: "settings.interface.Hujjatlar"
  },
  {
    icon: <AnchorIcon />,
    link: settingsPaths.BUILDERS,
    name: "settings.interface.Quruvchi"
  },
  {
    icon: <ArchiveIcon />,
    link: settingsPaths.ARCHIVE,
    name: "settings.interface.Arxiv"
  },
  {
    icon: <IntegrationIcon />,
    link: settingsPaths.INTEGRATION,
    name: "settings.interface.Integratsiya"
  }

  // {
  //   icon: <SettingsIntegrationIcon />,
  //   link: settingsPaths.INTEGRATION,
  //   name: "Integratsiya",
  // },
  // {
  //   icon: <SettingsInterfaceIcon />,
  //   link: settingsPaths.INTERFACE,
  //   name: "Interfeys",
  // },
  // {
  //   icon: <SettingsBackUpIcon />,
  //   link: settingsPaths.BACK_UP,
  //   name: "Back-up",
  // },
];

// settings.interface.
export const getLinks = ({ permissions }: { permissions?: Permission[] }) => {
  const routes = settingsRoutes({ permissions });
  const links: RootLinkModel[] = allLinks.filter(link => routes.find(route => route.path === link.link));

  links.push({ icon: <SettingsInterfaceIcon />, link: settingsPaths.INTERFACE, name: "settings.interface.Interfeys" });

  return links;
};
