import { useTranslation } from "react-i18next";

import { isEmptyArr } from "modules/common";

import { PartyGroup, PartyView } from "../schema";

export function GroupData(products: PartyView["warehouse_products"] | undefined) {
  const { t } = useTranslation();
  const personProducts: PartyGroup = [];
  let notPersonProducts: PartyGroup[number] = {
    id: undefined,
    name: t("partyView.Belgilanmangan"),
    products: []
  };

  products?.forEach(product => {
    if (product?.company_person?.id) {
      const index = personProducts?.findIndex(person => person?.id === product?.company_person?.id);

      if (index < 0) {
        personProducts.push({
          id: product?.company_person?.id,
          name: product?.company_person?.name,
          products: [product]
        });
      } else {
        const findPerson = { ...personProducts[index] };

        personProducts[index] = {
          id: findPerson?.id,
          name: findPerson?.name,
          products: [...findPerson.products, product]
        };
      }
    } else {
      notPersonProducts = {
        ...notPersonProducts,
        products: [...notPersonProducts.products, product]
      };
    }
  });

  if (!isEmptyArr(notPersonProducts.products)) {
    return [...structuredClone(personProducts), notPersonProducts];
  }

  return structuredClone(personProducts);
}

// export const generatePersonToWarehouseProduct = (personGroup: PartyFormPersonGroupModel[]) => {
//   const newWarehouseProducts: PartyFormProductsModel[] = [];
//
//   personGroup?.forEach(item => {
//     item?.data?.forEach(product => {
//       newWarehouseProducts.push(product);
//     });
//   });
//
//   return newWarehouseProducts;
// };
