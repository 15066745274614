import React from "react";
import { useDispatch } from "react-redux";
import { Modal } from "antd";

import { ProductMarketPrice } from "../../../../components";
import { useAppSelector } from "../../../../hooks/redux";
import { appReducerActions } from "../../../../store/reducers/appReducer";

import styles from "./productMarketPriceModal.module.scss";

const ProductMarketPriceModal: React.FC = () => {
  const dispatch = useDispatch();
  const { setProductMarketPriceModal } = appReducerActions;
  const { visible } = useAppSelector(state => state.appReducer.productMarketPriceModal);

  const onCancel = () => {
    dispatch(
      setProductMarketPriceModal({
        visible: false
      })
    );
  };

  return (
    <Modal
      open={visible}
      title="Mahsulot narx tahlili"
      rootClassName={styles.modal}
      footer={false}
      zIndex={12000}
      onCancel={onCancel}
    >
      <ProductMarketPrice />
    </Modal>
  );
};

export default ProductMarketPriceModal;
