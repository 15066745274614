import React from "react";
import { useDispatch } from "react-redux";
import { UseFormReturn } from "react-hook-form";
import { Button } from "antd";
import PlusIcon from "features/app/assets/icons/PlusIcon";
import { uid } from "uid";

import { partyReducerActions } from "store/reducers/partyReducer";

import { colors } from "modules/common";
import { PartyForm } from "modules/party/create";

type Props = {
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form }) => {
  const dispatch = useDispatch();
  const { setPartyEstimate } = partyReducerActions;

  const onAddProduct = () => {
    form.setValue("warehouse_products", [
      {
        // @ts-ignore
        product_id: null,
        // @ts-ignore
        unit_id: null,
        unit_name: "",
        quantity: "",
        amount: "",
        // @ts-ignore
        warehouse_id: null,
        company_person_id: null,
        company_person_name: "",
        // @ts-ignore
        currency_id: null,
        currency_symbol: "",
        total: "",
        vat_id: null,
        rowId: uid()
      }
    ]);
  };

  const onOpenEstimate = () => {
    dispatch(
      setPartyEstimate({
        visible: true
      })
    );
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-2 text-center">
        <h4 className="m-0 text-base font-semibold text-gray-900">Buyurtmalar tanlanmagan!</h4>
        <p className="m-0 w-[70%] text-sm font-normal text-gray-600">
          Mahsulotlar ro’yxatidan kerakli mahsultolarni tanlab buyurtma berishingiz mumkin
        </p>
        <div className="mt-2 flex items-center gap-4">
          <Button onClick={onOpenEstimate}>
            <PlusIcon />
            Smetadan resurs qo'shish
          </Button>
          <Button type="primary" onClick={onAddProduct}>
            <PlusIcon color={colors.WHITE} />
            Resurs qo'shish
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Index;
