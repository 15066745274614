import React from "react";
import { Col, Row } from "antd";
import classNames from "classnames";
import { RU } from "features/app/utils/constants/languages";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import { WorkProductTypeEnum } from "features/projects/utils/enums/workProductTypeEnum";
import { ProjectReportProductSpendModel } from "features/projects/utils/models/projectModel";
import { useTranslation } from "react-i18next";

import { colors, isEmptyObj } from "modules/common";

import styles from "./index.module.scss";

type Props = {
  statistics: ProjectReportProductSpendModel | undefined;
};

const TaskStatistics: React.FC<Props> = ({ statistics }) => {
  const { t } = useTranslation();
  const baseCurrency = getBaseCurrency();

  const styleOfAmounts = (first_amount: number, second_amount: number) => {
    if (first_amount <= second_amount) return styles.statistics__success;
    return styles.statistics__errro;
  };

  const contentByType = (type?: WorkProductTypeEnum) => {
    const foundOne = statistics?.data?.find(el => el?.type && el?.type === type);

    if (!isEmptyObj(foundOne)) {
      return (
        <React.Fragment key={foundOne?.id}>
          <p>{Math.round(Number(foundOne?.total_amount))?.toLocaleString(RU)} </p>
          <p className={styleOfAmounts(Number(foundOne?.spend_amount), Number(foundOne?.total_amount))}>
            {Math.round(Number(foundOne?.spend_amount))?.toLocaleString(RU)}{" "}
          </p>
        </React.Fragment>
      );
    }
    return (
      <>
        <p>0</p>
        <p className={styleOfAmounts(0, 0)}>0</p>
      </>
    );
  };

  return (
    <Row className={styles.statistics}>
      <Col span={4} className={`${styles.statistics__col_bold} ${styles.statistics__col}`}>
        <p>-</p>
        <p>{t("works.Reja")}</p>
        <p>{t("works.Fakt")}</p>
      </Col>
      <Col span={4} className={classNames(styles.statistics__col, styles.statistics__col_bold)}>
        <p>{t("works.Hammasi")}</p>
        <p>
          {Math.round(Number(statistics?.total_amount))?.toLocaleString(RU)} {baseCurrency?.symbol}
        </p>
        <p className={styleOfAmounts(Number(statistics?.prediction_amount), Number(statistics?.total_amount))}>
          {Math.round(Number(statistics?.spend_amount))?.toLocaleString(RU)} {baseCurrency?.symbol}
        </p>
      </Col>
      <Col span={4} className={styles.statistics__col}>
        <p className={styles.statistics__title}>
          <span
            className={styles.statistics__circle}
            style={{
              background: statistics?.data?.filter(item => item.type === WorkProductTypeEnum.PERSON)[0]?.color
            }}
          />
          {t("works.Inson resursi")}
        </p>
        {contentByType(WorkProductTypeEnum.PERSON)}
      </Col>
      <Col span={4} className={styles.statistics__col}>
        <p className={styles.statistics__title}>
          <span
            className={styles.statistics__circle}
            style={{
              background: statistics?.data?.filter(item => item.type === WorkProductTypeEnum.PRODUCT)[0]?.color
            }}
          />{" "}
          {t("works.Mahsulotlar")}
        </p>
        {contentByType(WorkProductTypeEnum.PRODUCT)}
      </Col>
      <Col span={4} className={styles.statistics__col}>
        <p className={styles.statistics__title}>
          <span
            className={styles.statistics__circle}
            style={{
              background: statistics?.data?.filter(item => item.type === WorkProductTypeEnum.MECHANISM)[0]?.color
            }}
          />{" "}
          {t("works.Mexanizmlar")}
        </p>
        {contentByType(WorkProductTypeEnum.MECHANISM)}
      </Col>
      <Col span={4} className={styles.statistics__col}>
        <p className={styles.statistics__title}>
          <span
            className={styles.statistics__circle}
            style={{
              background: colors.GRAY_500
            }}
          />{" "}
          {t("works.Boshqalar")}
        </p>
        {contentByType()}
      </Col>
    </Row>
  );
};

export default TaskStatistics;
