import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Spin } from "antd";
import i18n from "i18next";

import { useAppSelector } from "hooks/redux";

import { colors } from "modules/common";
import { Catalog, PartyResourceForm, useCatalogs } from "modules/party/resource";

import { FolderIcon, LoadingIndicator, TooltipShortName } from "components";

type Props = {
  form: UseFormReturn<PartyResourceForm>;
};

const Index: React.FC<Props> = ({ form }) => {
  const { visible } = useAppSelector(state => state.partyReducer.resource);
  const { catalogs, isLoading } = useCatalogs({ enabled: visible });

  const onClickCatalog = (record: Catalog) => () => {
    form.setValue("catalog", { id: record?.id, name: record?.name });
  };

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className="flex flex-wrap gap-3">
        {catalogs?.map(catalog => (
          <div
            key={catalog?.id}
            onClick={onClickCatalog(catalog)}
            className="flex w-44 cursor-pointer items-center gap-2 rounded-xl px-2.5 py-2 duration-300 ease-in-out hover:bg-gray-200"
          >
            <FolderIcon fill={catalog?.color || colors.PRIMARY} />
            <div className="flex flex-col gap-1">
              <span className="text-base font-semibold text-gray-600">
                <TooltipShortName arrow={false} placement="top" title={catalog?.name?.[i18n.language]} length={10} />
              </span>
              <span className="text-xs font-medium text-gray-400">{catalog?.products_count || 0} ta shablon</span>
            </div>
          </div>
        ))}
      </div>
    </Spin>
  );
};

export default Index;
