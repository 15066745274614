import React from "react";
import { useDispatch } from "react-redux";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { ChevronDownIcon, setPaymentTypeItem, usePaymentTypes } from "modules/settings/uysot";

type Props = {
  index: number;
};

const UyqurPaymentType: React.FC<Props> = ({ index }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { paymentTypes } = usePaymentTypes({ enabled: true });
  const { payment_types } = useAppSelector(({ uysot }) => uysot);
  const thisState = payment_types?.[index];

  const onChange = (value: number) => {
    dispatch(
      setPaymentTypeItem({
        index,
        value,
        key: "payment_type_id"
      })
    );
  };

  return (
    <Select
      onChange={onChange}
      suffixIcon={<ChevronDownIcon />}
      value={thisState?.payment_type_id}
      placeholder={t("uysot.To'lov turi")}
      labelRender={({ label }) => label || thisState?.type}
      options={paymentTypes?.map(({ id, name, color }) => ({
        value: id,
        label: (
          <div
            className="flex w-max items-center justify-center rounded-2xl px-2 py-0.5 text-xs font-medium"
            style={{
              color,
              background: `${color}20`
            }}
          >
            {name}
          </div>
        )
      }))}
    />
  );
};

export default UyqurPaymentType;
