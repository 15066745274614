import React from "react";
import { Control, UseFormSetValue } from "react-hook-form";

import { Input } from "components";

import { CompanyPersonForm } from "../schema";

type Props = {
  control: Control<CompanyPersonForm>;
  setValue: UseFormSetValue<CompanyPersonForm>;
};

const Phone: React.FC<Props> = ({ setValue, control }) => {
  const formatPhoneNumber = (value: string) => {
    const phone = value.replace(/\D/g, "").replace("998", "").split("");

    return `+998 (${phone[0] || ""}${phone[1] || ""}${phone[2] ? ")" : ""}${phone[2] ? " " : ""}${phone[2] || ""}${phone[3] || ""}${phone[4] || ""}${phone[5] ? "-" : ""}${phone[5] || ""}${phone[6] || ""}${phone[7] ? "-" : ""}${phone[7] || ""}${phone[8] || ""}`;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value);

    setValue("phone", formattedPhone);
  };

  return (
    <Input
      id="phone"
      type="text"
      name="phone"
      label="Telefon"
      withoutMessage
      control={control}
      onChange={onChange}
      autoComplete="off"
      placeholder="+998 (99) 999-99-99"
    />
  );
};

export default Phone;
