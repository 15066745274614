import React from "react";
import { useTranslation } from "react-i18next";

import { CircleBoxIcon } from "components";

const Empty: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center gap-4 text-center">
      <CircleBoxIcon />
      <div className="flex flex-col gap-1">
        <span className="text-base font-semibold text-gray-900">
          {t("warehouseTransfer.O‘tkazma mahsulotlari mavjud emas")} !!!
        </span>
        <span className="text-sm font-normal text-gray-600">
          {t("warehouseTransfer.Omborxona tanlang va o‘tkazma qilishni boshlang")}
        </span>
      </div>
    </div>
  );
};

export default Empty;
