import { useDispatch } from "react-redux";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { warehouseEndPoints } from "features/warehouse/utils/constants/warehouseEndPoints";
import { WarehouseProductModel } from "features/warehouse/utils/models/WarehouseProductModel";

import { useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";

import { supplyActions } from "store/reducers/supplyReducer";

import useGetTableSize from "../../../hooks/useGetTableSize";
import { endPoints } from "../../app/utils/constants/endPoints";
import { queryParamsKeys } from "../../app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "../../app/utils/constants/tableConfigKeys";
import $api from "../../app/utils/helpers/axiosInstance";
import { isEmptyArr } from "../../app/utils/helpers/isEmptyArr";
import { PaginationType } from "../../app/utils/models/PaginationType";
import { ProductSelectModel } from "../../app/utils/models/productSelectModel";
import { impostEndPoints } from "../../impost/utils/constants/impostEndPoints";
import { impostQueryKeys } from "../../impost/utils/constants/impostQueryKeys";
import { supplyEndpoints } from "../utils/constants/supplyEndpoints";
import { supplyQueryNames } from "../utils/constants/supplyQueryNames";
import { tabKeys } from "../utils/constants/tabKeys";
import { AdditionalViewModel } from "../utils/models/additionalModel";
import { AgentModel } from "../utils/models/agentModel";
import { CompanyPersonModel } from "../utils/models/companyPersonModel";
import { OfferHistoryModel } from "../utils/models/offerHistoryModel";
import { OfferModel } from "../utils/models/OfferModel";
import { OrderDetailModel, OrderModel } from "../utils/models/orderModel";
import { ProductModel } from "../utils/models/productModel";
import { ProjectModel, ProjectSectionModel, ProjectTaskProductModel } from "../utils/models/projectModel";
import { WarehouseModel } from "../utils/models/warehouseModel";

export function useGetSupplyProducts() {
  const dispatch = useDispatch();
  const { setSelectOrderProducts } = supplyActions;
  const { selectOrderProducts } = useAppSelector(state => state.supplyReducer);
  const { reqQueryParam, queries, severalSearchParams } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);

  let url = useGetTableSize({
    endpoint: supplyEndpoints.WAREHOUSE_PRODUCT,
    tableConfigKey: tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG
  });

  if (searchParam && searchParam?.length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SIZE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.MIN_DELIVERY_DATE,
      queryParamsKeys.MAX_DELIVERY_DATE,
      queryParamsKeys.SORT_QUANTITY,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.WAREHOUSE_IDS_ARR,
      queryParamsKeys.SORT_DELIVERY_DATE,
      queryParamsKeys.SORT_PROJECT,
      queryParamsKeys.SORT_WAREHOUSE,
      queryParamsKeys.SORT_CUSTOM_FIELDS,
      queryParamsKeys.CUSTOM_FIELDS_ARR
    )}`;
  }

  return useQuery<PaginationType<ProductModel[]>>(
    [supplyQueryNames.WAREHOUSE_PRODUCT, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !queries()[queryParamsKeys.TAB] || queries()[queryParamsKeys.TAB] === tabKeys.ORDERS,
      onSuccess: data => {
        const { data: newData } = data;

        if (!isEmptyArr(selectOrderProducts)) {
          dispatch(
            setSelectOrderProducts(
              selectOrderProducts?.map(item => {
                const findItem = newData?.find(i => i.id === item?.id);

                return findItem || item;
              })
            )
          );
        }
      }
    }
  );
}

export function useGetSupplyProductsCount() {
  const { severalSearchParams, reqQueryParam, queries } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.TAB);
  const { tab } = queries();

  let url = useGetTableSize({
    endpoint: supplyEndpoints.WAREHOUSE_PRODUCT,
    tableConfigKey: tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG
  });

  if (searchParams && searchParams?.length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SIZE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.MIN_DELIVERY_DATE,
      queryParamsKeys.MAX_DELIVERY_DATE,
      queryParamsKeys.SORT_QUANTITY,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.WAREHOUSE_IDS_ARR,
      queryParamsKeys.SORT_DELIVERY_DATE,
      queryParamsKeys.SORT_PROJECT,
      queryParamsKeys.SORT_WAREHOUSE,
      queryParamsKeys.SORT_CUSTOM_FIELDS,
      queryParamsKeys.CUSTOM_FIELDS_ARR
    )}`;
  }

  return useQuery<PaginationType<ProductModel[]>>(
    [supplyQueryNames.WAREHOUSE_PRODUCT_COUNT, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnMount: "always",
      enabled: !tab || tab === tabKeys.ORDERS
    }
  );
}

export function useGetCompanyPersons() {
  const { visiblePartDetail, visiblePartModal } = useAppSelector(state => state.supplyReducer);

  return useQuery<CompanyPersonModel[]>(
    [supplyQueryNames.COMPANY_PERSON],
    async () => {
      const res = await $api.get(supplyEndpoints.COMPANY_PERSON);

      return res.data.data;
    },
    {
      enabled: visiblePartDetail || visiblePartModal,
      refetchOnMount: "always"
    }
  );
}

export function useGetWarehouses() {
  const { visiblePartModal, createFromEstimate, projectId } = useAppSelector(state => state.supplyReducer);

  let url = supplyEndpoints.WAREHOUSES;

  if (projectId && createFromEstimate) {
    url += `?project_id=${projectId}`;
  }

  return useQuery<WarehouseModel[]>(
    [supplyQueryNames.WAREHOUSES, visiblePartModal],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: visiblePartModal,
      refetchOnMount: "always"
    }
  );
}

export function useGetAgents() {
  const { visiblePartDetail, visiblePartModal } = useAppSelector(state => state.supplyReducer);

  return useQuery<AgentModel[]>(
    [supplyQueryNames.AGENTS],
    async () => {
      const res = await $api.get(supplyEndpoints.AGENTS);

      return res.data.data;
    },
    {
      enabled: visiblePartDetail || visiblePartModal,
      staleTime: Infinity,
      refetchOnMount: "always"
    }
  );
}

export function useGetSupplyOrders() {
  const { reqQueryParam, severalSearchParams, queries } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);
  const { tab } = queries();

  let url = useGetTableSize({
    endpoint: supplyEndpoints.ORDERS,
    tableConfigKey: tableConfigKeys.ORDER_CONFIG
  });

  if (searchParam) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.SORT_ID,
      queryParamsKeys.MAX_ORDERED_DATE,
      queryParamsKeys.MIN_ORDERED_DATE,
      queryParamsKeys.MIN_PAYMENT_DATE,
      queryParamsKeys.MAX_PAYMENT_DATE,
      queryParamsKeys.MIN_DELIVERY_DATE,
      queryParamsKeys.MAX_DELIVERY_DATE,
      queryParamsKeys.MIN_AMOUNT,
      queryParamsKeys.MAX_AMOUNT,
      queryParamsKeys.SORT_AGENT,
      queryParamsKeys.AGENT_IDS_ARR,
      queryParamsKeys.SORT_PERCENT,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.SORT_QUANTITY,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.SORT_PAYMENT_DATE,
      queryParamsKeys.WAREHOUSE_IDS_ARR,
      queryParamsKeys.SORT_CUSTOM_FIELDS,
      queryParamsKeys.SORT_PAYMENT_PERCENT,
      queryParamsKeys.PAYMENT_STATUSES_ARR,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR,
      queryParamsKeys.CONFIRMATION_PAYMENTS_ARR,
      queryParamsKeys.CUSTOM_FIELDS_ARR,
      queryParamsKeys.PAYMENT_TYPE_IDS_ARR
    )}`;
  }

  return useQuery<PaginationType<OrderModel[]>>(
    [supplyQueryNames.ORDERS, searchParam],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !tab || tab === tabKeys.PARTIES
    }
  );
}

export function useGetSupplyOrder(id?: number) {
  return useQuery<OrderDetailModel>(
    [supplyQueryNames.ORDER_DETAIL, id],
    async () => {
      const res = await $api.get(`${supplyEndpoints.ORDERS}?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetSupplyAdditional(id: number | null) {
  return useQuery<AdditionalViewModel>(
    [supplyQueryNames.ADDITIONAL, id],
    async () => {
      const res = await $api.get(`${supplyEndpoints.ADDITIONAL}?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetSupplyProjects(enabled?: boolean) {
  const { queries } = useQueryParams();

  return useQuery<ProjectModel[]>(
    [supplyQueryNames.PROJECTS],
    async () => {
      const res = await $api.get(supplyEndpoints.PROJECTS);

      return res.data.data;
    },
    {
      enabled: !queries()[queryParamsKeys.TAB] || queries()[queryParamsKeys.TAB] === tabKeys.ESTIMATES || enabled
    }
  );
}

export function useGetProjectsSelect(argument: { enabled?: boolean; warehouse_id?: number }) {
  let url = supplyEndpoints.PROJECTS_SELECT;
  const { enabled, warehouse_id } = argument;

  if (warehouse_id) {
    url += `?warehouse_id=${warehouse_id}`;
  }

  return useQuery<{ id: number; name: string }[]>(
    [supplyQueryNames.PROJECTS_SELECT, warehouse_id],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!warehouse_id && enabled
    }
  );
}

export function useGetSupplyProject(id?: number) {
  const { queries } = useQueryParams();

  return useQuery<ProjectSectionModel[]>(
    [supplyQueryNames.PROJECT, id],
    async () => {
      const res = await $api.get(`${supplyEndpoints.PROJECTS}?id=${id}`);

      return res.data.data;
    },
    {
      enabled: (!queries()[queryParamsKeys.TAB] || queries()[queryParamsKeys.TAB] === tabKeys.ESTIMATES) && !!id
    }
  );
}

export function useGetProjectProducts(id?: number, enabled?: boolean) {
  const { queries } = useQueryParams();

  return useQuery<ProjectTaskProductModel[]>(
    [supplyQueryNames.TASK_PRODUCT_VIEW, id],
    async () => {
      const res = await $api.get(`${supplyEndpoints.TASK_PRODUCT_VIEW}?task_id=${id}`);

      return res.data.data;
    },
    {
      enabled:
        (!queries()[queryParamsKeys.TAB] || queries()[queryParamsKeys.TAB] === tabKeys.ESTIMATES) && !!id && enabled
    }
  );
}

export function useGetSupplySections(id?: number) {
  return useQuery<ProjectSectionModel[]>(
    [supplyQueryNames.SECTIONS, id],
    async () => {
      const res = await $api.get(`${supplyEndpoints.PROJECTS}?id=${id}`);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!id
    }
  );
}

export function useGetTaskProducts(id?: number, enabled?: boolean) {
  return useQuery<ProjectTaskProductModel[]>(
    [supplyQueryNames.TASK_PRODUCT, id],
    async () => {
      const res = await $api.get(`${supplyEndpoints.TASK_PRODUCT_VIEW}?task_id=${id}`);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!id && enabled
    }
  );
}

export function useGetProductsSelect(search?: string, warehouse_id?: number) {
  return useInfiniteQuery<PaginationType<ProductSelectModel[]>>({
    queryKey: [supplyQueryNames.SELECT_PRODUCTS, search, warehouse_id],
    queryFn: async ({ pageParam = 1 }) => {
      let url = `${endPoints.PRODUCT_SELECT}?paginate=true&page=${pageParam ?? 1}`;

      if (search && !warehouse_id) {
        url += `&search=${search}`;
      }
      if (!search && warehouse_id) {
        url += `&warehouse_id=${warehouse_id}`;
      }
      if (search && warehouse_id) {
        url += `&warehouse_id=${warehouse_id}&search=${search}`;
      }
      const res = await $api.get(url);

      return res.data.data;
    },
    getNextPageParam: (lastPage, page) => {
      if (page.length < lastPage?.data.length) {
        return page.length + 1;
      }
      return undefined;
    },
    staleTime: Infinity
  });
}

export function useGetWarehouseProductsSelect(
  warehouse_id: number | undefined,
  searchText?: string | undefined,
  project_id?: number | undefined
) {
  const { generateSearchParam } = useQueryParams();
  const queryClient = useQueryClient();

  return useInfiniteQuery<PaginationType<WarehouseProductModel[]>>(
    [supplyQueryNames.WAREHOUSE_PRODUCTS_IN_SUPPLY, warehouse_id, searchText, project_id],
    async ({ pageParam = 1 }) => {
      let url = warehouseEndPoints.WAREHOUSE_PRODUCTS;

      const valuesObj = {
        warehouse_id,
        search: searchText,
        project_id
      };

      if (generateSearchParam(valuesObj)) {
        url += `?${generateSearchParam(valuesObj)}&availability=true&page=${pageParam ?? 1}`;
      }
      const res = await $api.get(url);

      return res?.data?.data;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage?.data.length) {
          return page.length + 1;
        }
        return undefined;
      },
      enabled: !!warehouse_id
    }
  );
}

export function useGetOffers(enabled?: boolean) {
  // const dispatch = useDispatch();
  // const { setSelectOffers } = supplyOfferActions;
  // const { selectOffers } = useAppSelector(state => state.supplyOfferReducer);
  const { reqQueryParam, severalSearchParams, queries } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);

  let url = useGetTableSize({
    endpoint: supplyEndpoints.OFFERS,
    tableConfigKey: tableConfigKeys.WAREHOUSE_PRODUCT_OFFER_CONFIG
  });

  if (searchParam && searchParam?.length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.SORT_UNIT,
      queryParamsKeys.SORT_QUANTITY,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.MIN_DELIVERY_DATE,
      queryParamsKeys.MAX_DELIVERY_DATE,
      queryParamsKeys.WAREHOUSE_IDS_ARR,
      queryParamsKeys.SORT_DELIVERY_DATE,
      queryParamsKeys.SORT_CUSTOM_FIELDS,
      queryParamsKeys.CUSTOM_FIELDS_ARR
    )}`;
  }

  return useQuery<PaginationType<OfferModel[]>>(
    [supplyQueryNames.OFFERS, searchParam],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled:
        ((enabled ?? true) && !queries()[queryParamsKeys.TAB]) || queries()[queryParamsKeys.TAB] === tabKeys.OFFERS,
      onSuccess: () => {
        // const { data: newData } = data;
        // takliflarni partiya qilib saqlashda offerlar tozalanib ketmayotganligi uchun commit qilib qoydim
        // if (!isEmptyArr(selectOffers.data)) {
        //   dispatch(
        //     setSelectOffers(
        //       selectOffers?.data?.map(item => {
        //         const findItem = newData?.find(i => i.id === item?.id);
        //         return findItem || item;
        //       })
        //     )
        //   );
        // }
      }
    }
  );
}

export function useGetImpostOffers(enabled?: boolean) {
  const { reqQueryParam, severalSearchParams, queries } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);

  let url = useGetTableSize({
    endpoint: impostEndPoints.OFFERS,
    tableConfigKey: tableConfigKeys.WAREHOUSE_PRODUCT_OFFER_CONFIG
  });

  if (searchParam && searchParam?.length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.MIN_DELIVERY_DATE,
      queryParamsKeys.MAX_DELIVERY_DATE,
      queryParamsKeys.WAREHOUSE_IDS_ARR,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.SORT_CUSTOM_FIELDS,
      queryParamsKeys.CUSTOM_FIELDS_ARR
    )}`;
  }

  return useQuery<PaginationType<OfferModel[]>>(
    [impostQueryKeys.OFFERS, searchParam],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled:
        (enabled ?? true) && (!queries()[queryParamsKeys.TAB] || queries()[queryParamsKeys.TAB] === tabKeys.OFFER)
    }
  );
}

export function useGetOfferHistory() {
  const { productId, unitId, visible } = useAppSelector(state => state.supplyOfferReducer.offerHistory);

  let url = `${supplyEndpoints.OFFER_HISTORY}?${queryParamsKeys?.PRODUCT_ID}=${productId}`;

  if (unitId) {
    url += `&${queryParamsKeys.UNIT_ID}=${unitId}`;
  }

  return useInfiniteQuery<PaginationType<OfferHistoryModel[]>>({
    queryKey: [supplyQueryNames.OFFER_HISTORY, productId, unitId],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await $api.get(`${url}&${queryParamsKeys.PAGE}=${pageParam}`);

      return res.data?.data;
    },
    getNextPageParam: (lastPage, page) => {
      if (page.length < lastPage?.data.length) {
        return page.length + 1;
      }
      return undefined;
    },
    enabled: Boolean(productId) && visible
  });
}
