import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { localeFormatter, parseLocaledString } from "modules/common";
import { PartyViewForm } from "modules/party/view";

import { Input } from "components";

type Props = {
  form: UseFormReturn<PartyViewForm>;
};

const TotalAmount: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseLocaledString(e.target.value);
    const quantity = parseLocaledString(form.getValues("product.quantity"));

    form.setValue("product.total_amount", localeFormatter(String(value)));
    form.setValue("product.amount", localeFormatter(String(value / quantity)));
  };

  return (
    <Input
      withoutMessage
      withLabel={false}
      onChange={onChange}
      control={form.control}
      name="product.total_amount"
      placeholder={t("partyView.Kiriting")}
      className="h-[57px] rounded-none border-0 bg-transparent shadow-none hover:bg-transparent focus:bg-transparent"
    />
    // <Item
    //   label={null}
    //   name="total_amount"
    //   rules={formRules()}
    //   className="m-0 h-full w-full bg-transparent"
    //   initialValue={localeFormatter(String((updateProduct?.amount || 0) * (updateProduct?.quantity || 0)))}
    // >
    //   <Input
    //     className="h-[57px] rounded-none border-0 bg-transparent shadow-none hover:bg-transparent focus:border-0 focus:bg-transparent"
    //     placeholder={t("partyView.Kiriting")}
    //     onChange={onChange}
    //   />
    // </Item>
  );
};

export default TotalAmount;
