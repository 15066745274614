import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Input } from "antd";
import { SearchIcon } from "features/app/assets/icons/SearchIcon";
import { UserSelectModel } from "features/app/utils/models/user/userSelectModel";
import { CompanyPersonModel } from "features/projects/utils/models/workModel";
import { useTranslation } from "react-i18next";

import { AppendedEmployeesFormType, AppendEmployeesForm } from "modules/append-employees";
import { SuccessRes } from "modules/common";

import styles from "./popover-content.module.scss";

type Props = {
  users: UserSelectModel[];
  company_persons: CompanyPersonModel[];
  form: UseFormReturn<AppendedEmployeesFormType>;
  updateEmployees: ({
    user_ids,
    company_person_ids
  }: {
    user_ids: number[];
    company_person_ids: number[];
  }) => Promise<SuccessRes>;
  setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const PopoverContent: React.FC<Props> = ({ company_persons, users, form, updateEmployees, setPopoverOpen }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  return (
    <div className="flex w-[374px] flex-col rounded-xl border border-solid border-white bg-white">
      <div className="border-b border-l-0 border-r-0 border-t-0 border-solid border-b-gray-200 p-1">
        <Input
          placeholder={t("appendEmployees.Qidiruv...")}
          rootClassName={styles.search_input}
          onChange={e => setSearch(e.target.value)}
          value={search}
          prefix={<SearchIcon />}
        />
      </div>
      <AppendEmployeesForm
        search={search}
        users={users}
        company_persons={company_persons}
        form={form}
        updateEmployees={updateEmployees}
        setPopoverOpen={setPopoverOpen}
        setSearch={setSearch}
      />
    </div>
  );
};

export default PopoverContent;
