import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { UseFormReturn } from "react-hook-form";
import { Switch, SwitchProps } from "antd";
import UnreadMessageAction from "features/app/components/unread-message/UnreadMessageAction";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/actions";
import { chatActions } from "store/reducers/chatReducer";

import { groupData, parseGroupData, PartyForm, PartyFormProduct, PartyPersonGroup } from "modules/party/create";
import { partyQueryKeys, PartyView } from "modules/party/view";

import Print from "pages/party/view/title/print";

import { AddCardIcon, GitBranchIcon, LayoutAltIcon, PercentIcon, PrinterIcon, SaleIcon, TruckIcon } from "components";

const Items = ({ party, form }: { party: PartyView; form: UseFormReturn<PartyForm> }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setVisible } = chatActions;
  const printRef = useRef<HTMLDivElement | null>(null);
  const { id, isProcess, isGroup, isDiscount, isVat } = useAppSelector(({ partyReducer }) => partyReducer.update);
  const {
    setPartyUpdateProcess,
    setPartyUpdateGroup,
    setPartyUpdateVat,
    setPartyEstimate,
    setPartyResource,
    setPartyUpdateDiscount
  } = partyReducerActions;

  const onOpenChat = () => {
    dispatch(
      setVisible({
        objectId: id,
        visible: true,
        record: party,
        type: "orders",
        zIndex: 1999999,
        dataType: "object",
        dataKeys: [[partyQueryKeys.VIEW, String(id)]]
      })
    );
  };

  const onGroup: SwitchProps["onChange"] = (checked, event) => {
    event.preventDefault();
    event.stopPropagation();

    const { warehouse_products, company_person_group } = form.getValues();

    if (!isGroup) {
      form.setValue("company_person_group", groupData(warehouse_products as PartyFormProduct[]));
      form.clearErrors("warehouse_products");
    } else {
      form.setValue("warehouse_products", parseGroupData(company_person_group as PartyPersonGroup));
      form.clearErrors("company_person_group");
    }

    dispatch(setPartyUpdateGroup(checked));
  };

  const onProcess: SwitchProps["onChange"] = (checked, event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(setPartyUpdateProcess(checked));
  };

  const onVat: SwitchProps["onChange"] = (checked, event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(setPartyUpdateVat(checked));
  };

  const onDiscount: SwitchProps["onChange"] = (checked, event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!checked) {
      form.setValue("discount", undefined);
    }

    dispatch(setPartyUpdateDiscount(checked));
  };

  const onPrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `P-${id}`,
    removeAfterPrint: false,
    onBeforePrint: () => {},
    onAfterPrint: () => {}
  });

  const onEstimate = () => {
    dispatch(
      setPartyEstimate({
        visible: true
      })
    );
  };

  const onResources = () => {
    dispatch(
      setPartyResource({
        visible: true
      })
    );
  };

  return [
    {
      key: "1",
      label: (
        <label htmlFor="is_party_update_group" className="flex cursor-pointer items-center justify-between gap-9">
          <div className="flex items-center gap-2">
            <GitBranchIcon />
            {t("partyView.Guruhlash")}
          </div>
          <Switch id="is_party_update_group" onChange={onGroup} checked={isGroup} />
        </label>
      )
    },
    {
      key: "2",
      label: (
        <label htmlFor="is_party_update_vat" className="flex cursor-pointer items-center justify-between gap-9">
          <div className="flex items-center gap-2">
            <SaleIcon />
            <span>{t("partyView.NDS qo'shish")}</span>
          </div>
          <Switch id="is_party_update_vat" onChange={onVat} checked={isVat} />
        </label>
      )
    },
    {
      key: "3",
      label: (
        <label htmlFor="is_party_update_process" className="flex cursor-pointer items-center justify-between gap-9">
          <div className="flex items-center gap-2">
            <TruckIcon />
            <span>{t("partyView.Partiya jarayoni")}</span>
          </div>
          <Switch id="is_party_update_process" checked={isProcess} onChange={onProcess} />
        </label>
      )
    },
    {
      key: "4",
      label: (
        <label htmlFor="is_party_update_discount" className="flex cursor-pointer items-center justify-between gap-9">
          <div className="flex items-center gap-2">
            <PercentIcon />
            Chegirma
          </div>
          <Switch id="is_party_update_discount" checked={isDiscount} onChange={onDiscount} />
        </label>
      )
    },
    {
      key: "5",
      label: (
        <>
          <div className="flex items-center gap-2">
            <PrinterIcon />
            <span>{t("partyView.Chop etish")}</span>
          </div>
          <Print ref={printRef} id={id} />
        </>
      ),
      onClick: onPrint
    },
    {
      key: "6",
      label: <UnreadMessageAction count={party?.unread_message_count} />,
      onClick: onOpenChat
    },
    {
      key: "7",
      label: (
        <div className="flex items-center gap-2">
          <LayoutAltIcon />
          <span>Smeta resurslari</span>
        </div>
      ),
      onClick: onEstimate
    },
    {
      key: "8",
      label: (
        <div className="flex items-center gap-2">
          <AddCardIcon />
          <span>Mahsulotlar katalogi</span>
        </div>
      ),
      onClick: onResources
    }
  ];
};

export default Items;
