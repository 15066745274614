import React from "react";
import { Spin } from "antd";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import { useGetSingleWarehouseProductViewStats } from "features/warehouse/service/query";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { cx, RU } from "modules/common";

import { LoadingIndicator } from "components";

import styles from "./top.module.scss";

type Props = {
  stats: { total_quantity: number; total_income_quantity: number; total_expense_quantity: number } | undefined;
  isLoading: boolean;
};
const Top: React.FC<Props> = ({ isLoading, stats }) => {
  const { i18n, t } = useTranslation();
  const { product, visible } = useAppSelector(state => state.warehouseProducts?.resource_history);

  return (
    <div className="flex flex-col gap-5">
      <div
        className={cx(
          "flex rounded-lg border border-solid border-gray-200 bg-white px-[14px] py-[10px]",
          styles.name_container
        )}
      >
        <div className={cx("resource")}>
          <div
            style={{
              borderColor: product?.product?.resource?.color,
              color: product?.product?.resource?.color
            }}
          >
            {product?.product?.resource?.symbol[i18n.language]}
          </div>
          <span className={styles.warehouse_name}>
            <PopoverShortName title={product?.product?.name[i18n.language]} length={35} />
          </span>
        </div>
      </div>
      <div
        className={cx(
          "flex items-center gap-6 rounded-xl border border-solid border-gray-200 bg-white px-5 py-3",
          styles.spinner
        )}
      >
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          <div className="flex w-full items-center gap-6">
            <div className={styles.amount_container}>
              <span className="text-sm font-normal text-gray-500">{t("warehouse.Umumiy miqdori")}</span>
              <span className="text-2xl font-medium text-gray-700">
                {stats?.total_quantity?.toLocaleString(RU)} {product?.unit?.symbol[i18n.language]}
              </span>
            </div>
            <div className={styles.amount_container}>
              <span className="text-sm font-normal text-gray-500">{t("warehouse.Kirim")}</span>
              <span className="text-2xl font-medium text-success-500">
                {" "}
                {stats?.total_income_quantity?.toLocaleString(RU)} {product?.unit?.symbol[i18n.language]}
              </span>
            </div>
            <div className={styles.amount_container}>
              <span className="text-sm font-normal text-gray-500">{t("warehouse.Chiqim")}</span>
              <span className="text-2xl font-medium text-error-500">
                {" "}
                {stats?.total_expense_quantity?.toLocaleString(RU)} {product?.unit?.symbol[i18n.language]}
              </span>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default Top;
