import React from "react";
import { FieldErrors, UseFormReturn } from "react-hook-form";
import { Button, Tabs } from "antd";
import { UserSelectModel } from "features/app/utils/models/user/userSelectModel";
import { CompanyPersonModel } from "features/projects/utils/models/workModel";
import { useTranslation } from "react-i18next";

import { AppendedEmployeesFormType } from "modules/append-employees";
import { SuccessRes } from "modules/common";

import TabContent from "./tab-content";

import styles from "./inde.module.scss";

type Props = {
  search: string;
  users: UserSelectModel[];
  company_persons: CompanyPersonModel[];
  form: UseFormReturn<AppendedEmployeesFormType>;
  updateEmployees: ({
    user_ids,
    company_person_ids
  }: {
    user_ids: number[];
    company_person_ids: number[];
  }) => Promise<SuccessRes>;
  setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
};

const AppendEmployees: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { setPopoverOpen, updateEmployees, setSearch, ...rest } = props;

  const [tab, setTab] = React.useState<"counterparts" | "users">("counterparts");
  const onTabChange = (tab: "counterparts" | "users") => {
    setTab(tab);
  };

  const tabItems = [
    {
      key: "counterparts",
      label: t("appendEmployees.Kontragent"),
      children: <TabContent {...rest} tab={tab} />
    },
    {
      key: "users",
      label: t("appendEmployees.Xodim"),
      children: <TabContent {...rest} tab={tab} />
    }
  ];

  const onSubmit = async (values: AppendedEmployeesFormType) => {
    await updateEmployees(values).then(() => {
      setPopoverOpen(false);
      setTab("counterparts");
      setSearch("");
    });
  };

  const onError = (error: FieldErrors) => {
    console.log(error);
  };

  return (
    <form className="w-full p-0" onSubmit={props.form.handleSubmit(onSubmit, onError)}>
      <Tabs
        items={tabItems}
        onChange={onTabChange as never}
        activeKey={tab}
        tabBarExtraContent={<div className="p-3 text-white">Keraksiz</div>}
        rootClassName={styles.tabs}
      />
      <div className="flex items-center justify-end border-b-0 border-l-0 border-r-0 border-t border-solid border-t-gray-200 p-2">
        <Button htmlType="submit" type="primary" loading={props.form.formState.isSubmitting}>
          {t("appenEmployees.Saqlash")}
        </Button>
      </div>
    </form>
  );
};

export default AppendEmployees;
