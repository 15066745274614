import React, { ReactNode } from "react";
import { UseFormReturn } from "react-hook-form";

import { useAppSelector } from "hooks/redux";

import { PartyViewForm } from "modules/party/view";

import { Amount, Check, CompanyPerson, Currency, Project, Quantity, TotalAmount, Unit, Vat, Warehouse } from "./items";

type Props = {
  id: number;
  children: ReactNode;
  form: UseFormReturn<PartyViewForm>;
  name:
    | "not"
    | "product_id"
    | "unit_id"
    | "quantity"
    | "amount"
    | "warehouse_id"
    | "project_id"
    | "company_person_id"
    | "currency_id"
    | "total_amount"
    | "vat"
    | "progress"
    | "check";
};

const Index: React.FC<Props> = ({ id, form, name, children }) => {
  const { updateProduct } = useAppSelector(state => state.partyReducer.view);

  if (updateProduct && name !== "not" && id === updateProduct?.id) {
    switch (name) {
      case "unit_id":
        return <Unit form={form} />;
      case "quantity":
        return <Quantity form={form} />;
      case "amount":
        return <Amount form={form} />;
      case "warehouse_id":
        return <Warehouse form={form} />;
      case "project_id":
        return <Project form={form} />;
      case "company_person_id":
        return <CompanyPerson form={form} />;
      case "currency_id":
        return <Currency form={form} />;
      case "vat":
        return <Vat form={form} />;
      case "total_amount":
        return <TotalAmount form={form} />;
      case "check":
        return <Check form={form} />;
      default:
        return <>--</>;
    }
  }

  return (
    <div
      title={typeof children === "string" || typeof children === "number" ? (children as string) : ""}
      className="overflow-hidden text-ellipsis whitespace-nowrap px-[0.88rem] py-[0.74rem] text-sm font-medium text-gray-900"
    >
      {children}
    </div>
  );
};

export default Index;
