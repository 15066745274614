import { PartyFormProduct, PartyPersonGroup } from "modules/party/create";

export function parseGroupData(group: PartyPersonGroup) {
  let products: PartyFormProduct[] = [];

  group?.forEach(person => {
    products = [...products, ...(person?.products || [])];
  });

  return products;
}
