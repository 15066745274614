import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tabs, TabsProps } from "antd";
import PartyModal from "features/supply/components/parts/party-modal/PartyModal";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { appReducerActions } from "store/reducers/appReducer";
import { paymentReducerActions } from "store/reducers/paymentReducer";

import { PartyCreate } from "pages/index";
import AdmissionHistory from "pages/party/admission-history";

import ConversionInfoModal from "../app/components/conversion-info-modal/ConversionInfoModal";
import GeneralEditing from "../app/components/general-editing/GeneralEditing";
import TransferHistoryModal from "../app/components/transfer-history-modal/TransferHistoryModal";
import { generalEditKeys } from "../app/utils/constants/generalEditData";
import { PaymentTypeEnum } from "../app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../app/utils/constants/queryParamsKeys";
import CounterpartsModal from "../counterparts/components/counterparts/modal/CounterpartsModal";
import SettingsFinancialModal from "../settings/components/financial/modal/SettingsFinancialModal";
import { FinancialModalPropsModel } from "../settings/utils/models/financialModel";
import { usePartyGeneralEditing } from "../supply/service/mutations";

import CalendarHistory from "./components/bottom/calendar/history/CalendarHistory";
import ExpectedPaymentModal from "./components/bottom/expected-payment-modal/ExpectedPaymentModal";
import TableInformationsModal from "./components/bottom/expected-payment-modal/table-informations-modal/TableInformationsModal";
import ExpectedPaymentView from "./components/bottom/expected-payment-view/ExpectedPaymentView";
import IncomeExpenseView from "./components/bottom/income-expense-view/IncomeExpenseView";
import IncomeOrExpenseModal from "./components/bottom/income-or-expense/IncomeOrExpenseModal";
import PaymentStep from "./components/bottom/inventory-work-payment-view/payment-step/PaymentStep";
import OrderDeleteArchive from "./components/bottom/order-delete-archive/order-delete-archive";
import OrderView from "./components/bottom/order-view/OrderView";
import PayModal from "./components/bottom/pay-modal/PayModal";
import PaymentBottom from "./components/bottom/PaymentBottom";
import PaymentTop from "./components/top/PaymentTop";
import { InfoModalDataModel, PaymentContext, PaymentContextDataState } from "./hooks/PaymentContext";
import { usePaymentGeneralEditing } from "./service/mutation";
import { PaymentTabKeys } from "./utils/constants/paymentTabKeys";
import { routeSubmodules } from "./utils/constants/routeSubmodules";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const { t } = useTranslation();
  const { generalEdit } = useAppSelector(state => state.appReducer);
  const generalPartyUpdate = usePartyGeneralEditing(true);
  const generalPaymentUpdate = usePaymentGeneralEditing();

  const defaultSettingTabs = localStorage.getItem("paymentSettingTabs")
    ? JSON.parse(localStorage.getItem("paymentSettingTabs") || "")
    : null;
  const [paymentContext, setPaymentContext] = useState<PaymentContextDataState>({
    settingTabs: defaultSettingTabs || {
      income_or_expense: true,
      order: true,
      work: true,
      inventory: true,
      calendar: true,
      income: true,
      expense: true
    },
    infoModalData: { visible: false }
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get(queryParamsKeys.TYPE);
  const tabKey = searchParams.get(queryParamsKeys.STATISTICS_TYPE);
  const { setFirstTabKey, setFirstStatisticsType, setSelectIncomeExpense, setSelectOrder } = paymentReducerActions;
  const { setGeneralEdit } = appReducerActions;
  const { submodules, actions } = useRoutePermissions("Kassa", routeSubmodules);
  const partyActions = actions("Buyurtmalarga to'lovlar");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = [
    {
      key: `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.INCOME_EXPENSE}`,
      tabKey: PaymentTabKeys.INCOME_EXPENSE,
      label: `${t("payment.Kirim-Chiqim")}`,
      children: <PaymentBottom />,
      permissionKey: "Kirim-chiqim"
    },
    {
      key: `statistics_type=${PaymentTabKeys.PAYMENT}&type=${PaymentTypeEnum.INCOME}`,
      tabKey: PaymentTypeEnum.INCOME,
      label: `${t("payment.Kirim Shartnoma")}`,
      children: <PaymentBottom />,
      permissionKey: "Kutilayotgan to'lovlar"
    },
    {
      key: `statistics_type=${PaymentTabKeys.PAYMENT}&type=${PaymentTypeEnum.EXPENSE}`,
      tabKey: PaymentTypeEnum.EXPENSE,
      label: `${t("payment.Chiqim Shartnoma")}`,
      children: <PaymentBottom />,
      permissionKey: "Qarzlarim"
    },
    {
      key: `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.ORDER}`,
      tabKey: PaymentTabKeys.ORDER,
      label: `${t("payment.Partiyalar")}`,
      children: <PaymentBottom />,
      permissionKey: "Buyurtmalarga to'lovlar"
    },
    {
      key: `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.WORK}`,
      tabKey: PaymentTabKeys.WORK,
      label: `${t("payment.Ishlar")}`,
      children: <PaymentBottom />,
      permissionKey: "Ishlar uchun to'lovlar"
    },

    {
      key: `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.CALENDAR}`,
      tabKey: PaymentTabKeys.CALENDAR,
      label: `${t("payment.To'lov kalendari")}`,
      children: <PaymentBottom />,
      permissionKey: "To'lov kalendari"
    }
  ];

  const setContext = (value: PaymentContextDataState) => {
    setPaymentContext(prev => ({ ...prev, ...value }));
    if ("settingTabs" in value) {
      const redirect = Object.keys(value?.settingTabs || {})?.reduce(
        (p, key: string) => {
          if (!value?.settingTabs![tabKey as keyof typeof value.settingTabs]) {
            p.open = true;
          }
          if (!p.url && value?.settingTabs![key as keyof typeof value.settingTabs]) {
            p.url = items?.find(item => item?.tabKey === key)?.key || "";
          }
          return p;
        },
        {
          open: false,
          url: ""
        }
      );

      localStorage.setItem("paymentSettingTabs", JSON.stringify(value.settingTabs));

      if (redirect.open && redirect?.url) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        onChangeTab(redirect?.url);
      }
    }
  };

  const paymentItems = useCallback((): TabsProps["items"] => {
    const newItems: TabsProps["items"] = [];

    items.forEach(item => {
      const findItem = submodules?.find(submodule => submodule.key === item.permissionKey);

      if (findItem && findItem?.key) {
        newItems.push(item);
      }
    });

    dispatch(setFirstTabKey(newItems[0]?.key));

    const itemKeyArr = newItems[0]?.key?.split("=");
    // eslint-disable-next-line
    const [first] = itemKeyArr?.[1]?.split("&");
    const second = itemKeyArr[itemKeyArr?.length - 1];

    dispatch(
      setFirstStatisticsType({
        first,
        second
      })
    );

    return newItems?.filter(
      item => paymentContext?.settingTabs![item?.tabKey as keyof typeof paymentContext.settingTabs]
    );
  }, [dispatch, items, paymentContext, setFirstStatisticsType, setFirstTabKey, submodules]);

  const generalEditStates = {
    [`${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.INCOME_EXPENSE}`]: {
      key: generalEditKeys.PAYMENT,
      customFieldKeys: [],
      isView: true
    },
    [`${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.ORDER}`]: {
      key: generalEditKeys.PAYMENT_ORDER,
      customFieldKeys: [],
      isView: true
    }
  };

  const onChangeTab = useCallback(
    (key: string) => {
      if (generalEditStates[key]) {
        dispatch(setGeneralEdit(generalEditStates[key]));
      } else {
        dispatch(
          setGeneralEdit({
            key: generalEditKeys.EMPTY,
            customFieldKeys: [],
            isView: false
          })
        );
      }

      navigate({ search: `${key}` });
    },
    [navigate]
  );

  const activeKey = useMemo(() => {
    if (tabKey) {
      if (type) {
        return `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.PAYMENT}&type=${type}`;
      }
      return `${queryParamsKeys.STATISTICS_TYPE}=${tabKey}`;
    }

    return paymentItems()?.[0].key;
  }, [paymentItems, tabKey, type]);

  useEffect(() => {
    if (!searchParams?.size && paymentContext?.settingTabs) {
      const redirect = Object.keys(paymentContext?.settingTabs)?.reduce(
        (p, key: string) => {
          if (!paymentContext?.settingTabs![tabKey as keyof typeof paymentContext.settingTabs]) {
            p.open = true;
          }
          if (!p.url && paymentContext?.settingTabs![key as keyof typeof paymentContext.settingTabs]) {
            p.url = items?.find(item => item?.tabKey === key)?.key || "";
          }
          return p;
        },
        {
          open: false,
          url: ""
        }
      );

      if (redirect?.open && redirect?.url) {
        onChangeTab(redirect?.url);
      }
    }
  }, [items, onChangeTab, paymentContext, searchParams?.size, tabKey]);

  const setInfoModalData = (value: InfoModalDataModel) => {
    setPaymentContext(prev => ({ ...prev, infoModalData: value }));
  };

  const setFinancialModal = (value: FinancialModalPropsModel) => {
    setPaymentContext(prev => ({ ...prev, financialModal: value }));
  };

  const generalUpdateMutations = {
    [generalEditKeys.PAYMENT]: {
      mutation: generalPaymentUpdate,
      dispatchFunc: setSelectIncomeExpense,
      isDelete: false
    },
    [generalEditKeys.PAYMENT_ORDER]: {
      isDelete: false,
      mutation: generalPartyUpdate,
      dispatchFunc: setSelectOrder
    }
  };

  return (
    <PaymentContext.Provider
      value={{
        ...paymentContext,
        setContext
      }}
    >
      <div className={styles.payment}>
        <Tabs
          items={paymentItems()}
          onChange={onChangeTab}
          activeKey={activeKey}
          tabBarExtraContent={<PaymentTop />}
          destroyInactiveTabPane
        />
        <IncomeExpenseView />
        <PartyModal isPayment />
        <PartyCreate />
        <IncomeOrExpenseModal />
        <CalendarHistory />
        <PayModal />
        <ExpectedPaymentModal />
        <ExpectedPaymentView />
        <PaymentStep />
        <OrderView />
        <ConversionInfoModal />
        <TransferHistoryModal />
        <OrderDeleteArchive actions={partyActions} />
        <CounterpartsModal />
        <AdmissionHistory />
        <TableInformationsModal state={paymentContext?.infoModalData} setState={setInfoModalData} />
        <SettingsFinancialModal modalProps={paymentContext?.financialModal} setModalProps={setFinancialModal} />
      </div>
      <GeneralEditing {...generalUpdateMutations[generalEdit.key as keyof typeof generalUpdateMutations]} />
    </PaymentContext.Provider>
  );
};

export default Index;
