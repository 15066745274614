import React from "react";
import { useTranslation } from "react-i18next";

import { activatingMessagingSystemsActions, useDispatch } from "store";

import { cx } from "modules/common";
import { Images } from "modules/settings/message";

import styles from "./content.module.scss";

const TabContent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setOpenModal } = activatingMessagingSystemsActions;

  const onPlayMobileCardClick = () => {
    dispatch(
      setOpenModal({
        visible: true,
        title: t("works.Play Mobile")
      })
    );
  };
  const onEskizCardClick = () => {
    dispatch(
      setOpenModal({
        visible: true,
        title: t("works.Eskiz")
      })
    );
  };

  return (
    <div className="flex cursor-pointer items-center gap-3">
      <div
        onClick={onPlayMobileCardClick}
        className={cx(
          "0px rgba(16, 24, 40, 0.1); flex h-[235px] flex-1 cursor-pointer items-center justify-center rounded-2xl border border-solid border-gray-300",
          styles.messaging_card
        )}
      >
        <img src={Images.playMobile} alt="playMobile" />
      </div>
      <div
        onClick={onEskizCardClick}
        className={cx(
          "0px rgba(16, 24, 40, 0.1); flex h-[235px] flex-1 cursor-pointer items-center justify-center rounded-2xl border border-solid border-gray-300",
          styles.messaging_card
        )}
      >
        <img src={Images.eskiz} alt="playMobile" />
      </div>
    </div>
  );
};

export default TabContent;
