import React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import { templatesModalActions, useDispatch, useSelector } from "store";

import Content from "./content/content";

import styles from "./templates-modal.module.scss";

const TemplatesModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setOpenModal } = templatesModalActions;
  const [isEditable, setEditable] = React.useState(false);
  const { visible, title } = useSelector(state => state.templatesModalReducer);

  const onCancel = () => {
    dispatch(
      setOpenModal({
        visible: false,
        title: ""
      })
    );
    setEditable(false);
  };

  return (
    <Modal
      centered
      open={visible}
      title={
        <div className="text-[18px] font-semibold text-gray-900">
          {title} {t("settings.shablonlari")}
        </div>
      }
      className={styles.templates_modal}
      onCancel={onCancel}
      footer={false}
    >
      <Content setIsEditable={setEditable} isEditable={isEditable} />
    </Modal>
  );
};

export default TemplatesModal;
