import React from "react";
import { useDispatch } from "react-redux";
import { Switch } from "antd";

import { useAppSelector } from "hooks/redux";

import { setCurrencyItem } from "modules/settings/uysot";

type Props = {
  index: number;
};

const CurrencyStatus: React.FC<Props> = ({ index }) => {
  const dispatch = useDispatch();
  const { currencies } = useAppSelector(({ uysot }) => uysot);
  const thisCurrency = currencies?.[index];

  const onChange = (e: boolean) => {
    if (thisCurrency?.currency_id) {
      dispatch(
        setCurrencyItem({
          index,
          key: "status",
          value: e ? "active" : "passive"
        })
      );
    }
  };

  return (
    <div className="flex justify-end">
      <Switch onChange={onChange} checked={thisCurrency?.status === "active"} disabled={!thisCurrency?.currency_id} />
    </div>
  );
};

export default CurrencyStatus;
