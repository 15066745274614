import React, { useMemo } from "react";
import { UseFieldArrayAppend, UseFormReturn, useWatch } from "react-hook-form";
import { Select } from "antd";

import { isEmptyArr } from "modules/common";
import { PartyForm } from "modules/party/create";
import { useCompanyPersons } from "modules/party/view";

import { ConditionalRender, UserPlusIcon } from "components";

type Props = {
  form: UseFormReturn<PartyForm>;
  append: UseFieldArrayAppend<PartyForm>;
};

const Index: React.FC<Props> = ({ append, form }) => {
  const { company_person_group } = useWatch({
    control: form.control
  });
  const { companyPersons, isLoading } = useCompanyPersons();

  const onChange = (e: number, option: any) => {
    append({
      id: e,
      name: option?.props?.name || "",
      products: []
    });
  };

  const options = useMemo(
    () =>
      [
        {
          id: 0,
          name: "Belgilanmagan",
          person: {
            id: 0,
            name: "Belgilanmagan"
          }
        },
        ...companyPersons
      ]?.filter(person => !company_person_group?.some(group => group.id === person.id)),
    [company_person_group, companyPersons]
  );

  return (
    <ConditionalRender if={!isEmptyArr(options)}>
      <Select
        loading={isLoading}
        className="w-max"
        placeholder={
          <div className="flex w-max items-center gap-2 rounded-xl text-sm font-normal text-gray-600">
            <UserPlusIcon />
            Ta'minotchi qo'shish
          </div>
        }
        labelRender={() => (
          <div className="flex w-max items-center gap-2 rounded-xl text-sm font-normal text-gray-600">
            <UserPlusIcon />
            Ta'minotchi qo'shish
          </div>
        )}
        suffixIcon={null}
        onChange={onChange}
        popupMatchSelectWidth={false}
        options={options?.map(person => ({
          key: person?.id,
          value: person?.id,
          label: person?.name as string,
          props: {
            name: person?.name
          }
        }))}
      />
    </ConditionalRender>
  );
};

export default Index;
