import React from "react";
import { Form, FormInstance, FormListOperation, Input, Select } from "antd";
import { Rule } from "antd/es/form";
import { useTranslation } from "react-i18next";

import CloseIcon from "../../../../../app/assets/icons/CloseIcon";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { formRules } from "../../../../../app/utils/constants/formRules";
import { RU } from "../../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import { cx } from "../../../../../app/utils/helpers/cx";
import { localeFormatter } from "../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import { CurrencyModel } from "../../../../../settings/utils/models/currency/currencyModel";
import { useGetCashMoneyPaymentTypeSelect } from "../../../../service/queries";
import { ExchangeFormModel } from "../../../../utils/models/exchangeFormModel";

import Additions from "./additions/additions";

type Props = {
  index: number;
  form: FormInstance;
  operation: FormListOperation;
};

const { Item, useWatch } = Form;
const { Option } = Select;

const Card: React.FC<Props> = ({ index, form, operation }) => {
  const { t } = useTranslation();
  const cashId = useWatch(["expense_payment", "cash_id"], form);
  const firstCurrency = JSON.parse(useWatch("first_currency") || "{}") as CurrencyModel;
  const paymentTypeId = useWatch(["expense_payment", "payment_types", index, "id"], form);
  const payments = useWatch([
    "expense_payment",
    "payment_types"
  ]) as ExchangeFormModel["expense_payment"]["payment_types"];

  const { data: paymentTypes, isLoading } = useGetCashMoneyPaymentTypeSelect({
    cashId,
    type: PaymentTypeEnum.EXPENSE,
    currency_id: firstCurrency?.id,
    enabled: Boolean(cashId && firstCurrency?.id)
  });

  const onDeletePayment = () => {
    operation.remove(index);
  };

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parseValue = parseLocaledString(e?.target?.value || "0");
    const currencyAmount = parseLocaledString(form.getFieldValue("currency_amount") || "0");

    const total = payments?.reduce((previousValue, currentValue, currentIndex) => {
      if (currentIndex !== index) {
        return parseLocaledString(previousValue || "0") + parseLocaledString(currentValue?.amount || "0");
      }

      return previousValue;
    }, 0);

    form.setFieldValue(["expense_payment", "payment_types", index, "amount"], localeFormatter(e.target?.value));
    form.setFieldValue(
      ["income_payment", "payment_type", "amount"],
      localeFormatter(String((total + parseValue) * currencyAmount))
    );
  };

  const onChangePaymentType = (_: number, option: any) => {
    const currencyAmount = parseLocaledString(form.getFieldValue("currency_amount") || "0");

    const total = payments?.reduce((previousValue, currentValue, currentIndex) => {
      if (currentIndex !== index) {
        return parseLocaledString(previousValue || "0") + parseLocaledString(currentValue?.amount || "0");
      }

      return previousValue;
    }, 0);

    form.setFieldValue(["expense_payment", "payment_types", index, "projects"], []);
    form.setFieldValue(["expense_payment", "payment_types", index, "financials"], []);
    form.setFieldValue(["expense_payment", "payment_types", index, "amount"], undefined);
    form.setFieldValue(["expense_payment", "payment_types", index, "max_amount"], option?.props?.amount);
    form.setFieldValue(["income_payment", "payment_type", "amount"], localeFormatter(String(total * currencyAmount)));
  };

  const amountRule: Rule[] = [
    ...formRules(),
    {
      validator: (_, value) => {
        const parseValue = parseLocaledString(value || "0");
        const financials = form.getFieldValue([
          "expense_payment",
          "payment_types",
          index,
          "financials"
        ]) as ExchangeFormModel["expense_payment"]["payment_types"][number]["financials"];
        const financialsAmount = financials?.reduce(
          (previousValue, currentValue) => previousValue + parseLocaledString(currentValue?.amount || "0"),
          0
        );
        const maxAmount = form.getFieldValue(["expense_payment", "payment_types", index, "max_amount"]) as number;

        if (maxAmount < parseValue + financialsAmount) {
          return Promise.reject(
            `Kiritilgan miqdor ${(maxAmount - financialsAmount)?.toLocaleString(RU)} ${firstCurrency?.symbol} dan kichik bo'lishi zarur`
          );
        }

        return Promise.resolve();
      }
    }
  ];

  return (
    <div className="relative box-border flex flex-col gap-3 rounded-2xl border border-solid border-gray-200 bg-white p-4">
      <ConditionalRender if={index}>
        <div
          onClick={onDeletePayment}
          className="absolute right-4 top-3.5 z-10 flex h-max w-max cursor-pointer items-center justify-center rounded-lg p-0.5 duration-300 ease-in-out hover:bg-gray-200"
        >
          <CloseIcon />
        </div>
      </ConditionalRender>
      <div className="grid grid-cols-2 gap-2">
        <Item className="hidden" name={[index, "max_amount"]} />
        <Item name={[index, "amount"]} label={t("payment.Summa")} rules={amountRule} className="m-0">
          <Input
            rootClassName="h-12"
            disabled={!paymentTypeId}
            onChange={onChangeAmount}
            suffix={firstCurrency?.symbol}
            placeholder={t("payment.Summa")}
          />
        </Item>
        <Item name={[index, "id"]} label={t("payment.To'lov turi")} rules={formRules()} className="m-0">
          <Select
            className="h-12"
            disabled={!cashId}
            loading={isLoading}
            popupMatchSelectWidth={false}
            onChange={onChangePaymentType}
            notFoundContent={<TableEmpty />}
            suffixIcon={<SelectSuffixIcon />}
            placeholder={t("paymnet.To'lov turi")}
          >
            {paymentTypes
              ?.filter(
                ({ id }) => !payments?.some(({ id: payment_type_id }) => id === payment_type_id && id !== paymentTypeId)
              )
              ?.map(paymentType => (
                <Option
                  key={paymentType?.id}
                  value={paymentType?.id}
                  props={{ amount: paymentType?.currencies[0]?.amount || 0 }}
                >
                  <div className="flex flex-col">
                    <div>{paymentType?.name}</div>
                    <div className={cx("flex items-center gap-2", "cash_amount")}>
                      {paymentType?.currencies?.map(item => (
                        <div
                          key={item?.id}
                          className="border-r border-solid border-gray-700 pr-2 text-sm font-normal text-gray-600 last:border-none"
                        >
                          {item?.amount?.toLocaleString(RU)} {item?.symbol}
                        </div>
                      ))}
                    </div>
                  </div>
                </Option>
              ))}
          </Select>
        </Item>
      </div>
      <Additions index={index} form={form} />
    </div>
  );
};

export default Card;
