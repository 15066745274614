import React from "react";
import { Skeleton } from "antd";
import ArrowDownIcon from "features/app/assets/icons/ArrowDownIcon";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import { numberRound } from "features/supply/utils/helpers/numberRound";

import { RU, isEmptyObj } from "modules/common";
import { getConditionalColor, getConditionalStyle, getIcon, makeSvgRotate, useWidget } from "modules/dashboard";
import { useProjectPerArePriceView } from "modules/dashboard/queries/use-project-per-area-price-view";
import { useProjectSalePriceView } from "modules/dashboard/queries/use-project-sale-price";

import CountItem from "./item";
import { useTranslation } from "react-i18next";

const ProjectPerAreaPrice: React.FC = () => {
  const { t } = useTranslation();
  const { id, visible, key } = useWidget();
  const { companyProjectPerAreaPriceView, isLoading: perAreaPriceLoading } = useProjectPerArePriceView({
    enabled: visible,
    id,
    widgetKey: key
  });
  const { companyProjectSalePriceView, isLoading: salePriceLoading } = useProjectSalePriceView({
    enabled: visible,
    id,
    widgetKey: key
  });

  const currency = getBaseCurrency();

  if (key === "project_per_area_price" && perAreaPriceLoading)
    return (
      <div className="flex w-[34.313rem] flex-col gap-2 p-5">
        {Array.from({ length: 4 }).map((__, index) => (
          <Skeleton key={key + index} active />
        ))}
      </div>
    );

  if (key === "project_sale_price" && salePriceLoading)
    return (
      <div className="flex w-[34.313rem] flex-col gap-2 p-5">
        {Array.from({ length: 4 }).map((__, index) => (
          <Skeleton key={key + index} active />
        ))}
      </div>
    );

  return (
    <div className="flex w-[34.313rem] flex-col gap-2 p-5">
      <div className="flex flex-col gap-3 rounded-xl border border-solid border-gray-200 bg-white p-4">
        <div className="flex w-full items-center justify-between">
          <span className="flex-1 text-sm font-normal text-gray-600">{t("dashboard.Umumiy maydon")}</span>
          <span className="flex items-center justify-center gap-1 text-sm font-medium text-black">
            {(!isEmptyObj(companyProjectPerAreaPriceView)
              ? companyProjectPerAreaPriceView
              : companyProjectSalePriceView
            )?.area?.toLocaleString(RU)}{" "}
            <span className="flex items-center justify-center">
              m <sup>2</sup>
            </span>
          </span>
        </div>
        <div className="flex w-full items-center justify-between">
          <span className="flex-1 text-sm font-normal text-gray-600">{t("dashboard.Reja narxi")}</span>
          <span className="flex items-center justify-center gap-1 text-sm font-medium text-black">
            {(!isEmptyObj(companyProjectPerAreaPriceView)
              ? companyProjectPerAreaPriceView
              : companyProjectSalePriceView
            )?.per_area_price?.toLocaleString(RU)}{" "}
            <span className="flex items-center justify-center text-sm font-medium text-gray-400">
              {currency?.symbol}/{" "}
              <span className="flex items-center justify-center">
                m <sup>2</sup>
              </span>
            </span>
          </span>
        </div>
        <div className="flex w-full items-center justify-between">
          <span className="flex-1 text-sm font-normal text-gray-600">{t("dashboard.Bashorat narxi")}</span>
          <span className="flex items-center justify-center gap-1 text-sm font-medium text-black">
            {(!isEmptyObj(companyProjectPerAreaPriceView)
              ? companyProjectPerAreaPriceView
              : companyProjectSalePriceView
            )?.per_area_prediction_price?.toLocaleString(RU)}{" "}
            <span className="flex items-center justify-center text-sm font-medium text-gray-400">
              {currency?.symbol}/{" "}
              <span className="flex items-center justify-center">
                m <sup>2</sup>
              </span>
            </span>
          </span>
        </div>
        <div className="flex w-full items-center justify-between">
          <span>{t("dashboard.Narx o'zgarishi")}</span>
          <div className="flex items-center gap-1">
            <span
              className="flex items-center justify-center gap-1 rounded-2xl px-2 py-1"
              style={{
                backgroundColor: getConditionalStyle(
                  (!isEmptyObj(companyProjectPerAreaPriceView)
                    ? companyProjectPerAreaPriceView
                    : companyProjectSalePriceView
                  ).per_area_percent || 0
                ),
                borderColor: getConditionalStyle(
                  (!isEmptyObj(companyProjectPerAreaPriceView)
                    ? companyProjectPerAreaPriceView
                    : companyProjectSalePriceView
                  ).per_area_percent || 0
                )
              }}
            >
              <span>
                {getIcon(
                  (!isEmptyObj(companyProjectPerAreaPriceView)
                    ? companyProjectPerAreaPriceView
                    : companyProjectSalePriceView
                  )?.per_area_percent || 0,
                  <ArrowDownIcon
                    stroke={getConditionalColor(
                      (!isEmptyObj(companyProjectPerAreaPriceView)
                        ? companyProjectPerAreaPriceView
                        : companyProjectSalePriceView
                      ).per_area_percent!
                    )}
                    width={12}
                    rotate={makeSvgRotate(
                      (!isEmptyObj(companyProjectPerAreaPriceView)
                        ? companyProjectPerAreaPriceView
                        : companyProjectSalePriceView
                      ).per_area_percent!
                    )}
                  />
                )}
              </span>
              <span
                style={{
                  color:
                    getConditionalColor(
                      (!isEmptyObj(companyProjectPerAreaPriceView)
                        ? companyProjectPerAreaPriceView
                        : companyProjectSalePriceView
                      ).per_area_percent || 0
                    ) || "black"
                }}
                className="text-sm font-medium"
              >
                {numberRound(
                  (!isEmptyObj(companyProjectPerAreaPriceView)
                    ? companyProjectPerAreaPriceView
                    : companyProjectSalePriceView
                  ).per_area_percent!
                ) || 0}
                %
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="mb-1 mt-1 flex items-center justify-between gap-[0.313rem]">
        <span className="flex-1 border border-solid border-gray-300" />
        <span className="text-sm font-medium text-gray-500">{t("dashboard.Loyihalar")}</span>
        <span className="flex-1 border border-solid border-gray-300" />
      </div>
      {(!isEmptyObj(companyProjectPerAreaPriceView)
        ? companyProjectPerAreaPriceView
        : companyProjectSalePriceView
      )?.projects?.map(item => <CountItem item={item} currency={currency as never} key={item.id} />)}
    </div>
  );
};

export default ProjectPerAreaPrice;
