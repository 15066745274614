import React from "react";
import { Form, FormInstance, Select, Tooltip } from "antd";
import MagicWandIcon from "features/payment/assets/icons/MagicWandIcon";
import { useOrderSelect } from "features/payment/service/queries";
import { OrderSelect } from "features/payment/utils/models/orderSelectModel";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { colors, isEmptyArr, localeFormatter, parseLocaledString, RU } from "modules/common";
import { ChevronDownIcon } from "modules/settings/uysot";

import { EmptyContent } from "components";

import styles from "./index.module.scss";

type Props = {
  form: FormInstance;
};

const { Item, useWatch } = Form;

const Party: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const orderIds = useWatch("order_ids", form) as number[];
  const amount = parseLocaledString(useWatch("amount", form));
  const companyPersonId = useWatch("company_person_id", form);
  const { currency, defaultData } = useAppSelector(state => state.paymentReducer.income_expense_modal);

  const { orders } = useOrderSelect({ companyPersonId, currencyId: currency?.id });

  if (companyPersonId && !(defaultData?.order_ids && !isEmptyArr(defaultData?.order_ids)) && !isEmptyArr(orders)) {
    const calculatedTotalAmount = (index: number) => {
      const ids = orderIds?.slice(0, index);

      return orders.reduce(
        (prev, current) => {
          const someOrder = ids?.some(id => id === current?.id);

          if (someOrder && prev > 0) {
            return prev - current?.must_pay_amount;
          }

          return prev;
        },
        parseLocaledString(amount || "0")
      );
    };

    const orderStaticData = (id: number, payAmount: number) => {
      const index = orderIds?.findIndex(i => i === id);
      const generateTotal = calculatedTotalAmount(index);

      return {
        percent: (() => {
          if (payAmount > generateTotal && generateTotal > 0) {
            return ((Math.abs(generateTotal) / payAmount) * 100).toFixed(2);
          }

          if (generateTotal >= payAmount) {
            return 100;
          }

          return 0;
        })(),
        bg: (() => {
          if (generateTotal >= payAmount) {
            return colors.SUCCESS_100;
          }

          if (payAmount > generateTotal && generateTotal > 0) {
            return colors.WARNING_100;
          }

          return colors.GRAY_100;
        })(),
        color: (() => {
          if (generateTotal >= payAmount) {
            return colors.SUCCESS_500;
          }

          if (payAmount > generateTotal && generateTotal > 0) {
            return colors.WARNING_500;
          }

          return colors.GRAY_500;
        })()
      };
    };

    const onCalculateAmount = () => {
      const totalAmount = orders.reduce((prev, current) => {
        const someOrder = orderIds?.some(id => id === current?.id);

        return someOrder ? current?.must_pay_amount + prev : prev;
      }, 0);

      form.setFieldValue("amount", localeFormatter(String(totalAmount)));
    };

    return (
      <div className="flex items-end gap-2">
        <Item name="order_ids" className="flex-1" label={t("payment.Partiya")}>
          <Select
            allowClear
            mode="multiple"
            disabled={!companyPersonId}
            rootClassName={styles.party}
            suffixIcon={<ChevronDownIcon />}
            placeholder={t("payment.Tanlang")}
            labelRender={({ title }: any) => {
              const { id, must_pay_amount } = JSON.parse(title || "{}") as OrderSelect;
              const staticData = orderStaticData(id, must_pay_amount);

              return (
                <div
                  className="flex w-max items-center gap-2 rounded-3xl py-1 pl-3 pr-2"
                  style={{ background: staticData?.bg }}
                >
                  <span className="text-sm font-medium" style={{ color: staticData?.color }}>
                    P-{id}
                  </span>
                  <div
                    style={{ background: staticData?.color }}
                    className="flex items-center justify-center rounded-3xl px-2 py-0.5 text-xs font-medium text-white"
                  >
                    {staticData?.percent}%
                  </div>
                </div>
              );
            }}
            notFoundContent={<EmptyContent description={t("payment.Partiyalar mavjud emas")} />}
            options={orders?.map(order => {
              const { id, currency, must_pay_amount } = order;

              return {
                title: JSON.stringify(order),
                value: id,
                label: (
                  <div className="flex flex-col gap-2">
                    <span className="text-sm font-medium text-primary-500">P-{id}</span>
                    <span className="text-sm font-normal text-gray-600">
                      {t("payment.Qoldiq")}: {must_pay_amount?.toLocaleString(RU)} {currency?.symbol}
                    </span>
                  </div>
                ),
                props: order
              };
            })}
          />
        </Item>
        <Tooltip title={t("payment.Umumiy summani shakllantirish")} arrow={false}>
          <div
            onClick={onCalculateAmount}
            className="mb-6 flex cursor-pointer items-center justify-center rounded-md border border-solid border-transparent bg-gray-100 p-3 duration-200 ease-in-out hover:border-primary-500"
          >
            <MagicWandIcon />
          </div>
        </Tooltip>
      </div>
    );
  }

  return null;
};

export default Party;
