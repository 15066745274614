import React from "react";

import { Top } from "./top";

const Works: React.FC = () => (
  <div>
    <Top />
  </div>
);

export default Works;
