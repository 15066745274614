import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Button, Drawer } from "antd";
import { LocalStorage } from "features/app/service/LocalStorage";
import {
  IMPOST_PARTY_COLUMNS,
  PAYMENT_PARTY_COLUMNS,
  SUPPLY_PARTY_COLUMNS,
  WAREHOUSE_PARTY_COLUMNS
} from "features/app/utils/constants/localStorageKeys";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/actions";

import { cx } from "modules/common";
import { defaultColumns, PartyColumn, PartyType } from "modules/party/view";

import ColumnSwitch from "./column-switch";

import styles from "./index.module.scss";

type Props = {
  type: PartyType;
};

const keys = {
  impost: IMPOST_PARTY_COLUMNS,
  supply: SUPPLY_PARTY_COLUMNS,
  warehouse: WAREHOUSE_PARTY_COLUMNS,
  payment: PAYMENT_PARTY_COLUMNS
} as const;

const Index: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation();
  const localKey = keys[type];
  const dispatch = useDispatch();
  const allColumns = defaultColumns[type];
  const [columns, setColumns] = useState<PartyColumn[]>([]);
  const { visible } = useAppSelector(({ partyReducer }) => partyReducer.view.settings);
  const { setPartyViewSettingsVisible, setPartyViewSettingsColumns } = partyReducerActions;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1
      }
    })
  );

  const onClose = () => {
    dispatch(setPartyViewSettingsVisible(false));
  };

  const onOk = () => {
    LocalStorage.set(localKey, columns);

    dispatch(
      setPartyViewSettingsColumns({
        type,
        columns
      })
    );

    onClose();
  };

  const onClear = () => {
    LocalStorage.set(localKey as never, allColumns);

    dispatch(
      setPartyViewSettingsColumns({
        type,
        columns: allColumns
      })
    );

    onClose();
  };

  const footer = (
    <div className="flex items-center justify-between gap-28">
      <Button className={cx("reject-button")} onClick={onClear}>
        Tozalash
      </Button>
      <div className="flex items-center justify-end gap-3">
        <Button onClick={onClose}>{t("partyView.Yopish")}</Button>
        <Button onClick={onOk} type="primary">
          {t("partyView.Saqlash")}
        </Button>
      </div>
    </div>
  );

  const onAfterOpen = (open: boolean) => {
    if (open) {
      const localColumns = LocalStorage.get<PartyColumn[]>(localKey);

      if (localColumns) {
        setColumns(localColumns);
      } else {
        setColumns(allColumns as PartyColumn[]);
      }
    }
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setColumns(oldColumns => {
        const activeIndex = oldColumns.findIndex(i => i.key === active.id);
        const overIndex = oldColumns.findIndex(i => i.key === over?.id);

        return arrayMove(oldColumns, activeIndex, overIndex);
      });
    }
  };

  const sortableItems = () => allColumns?.map(el => el?.key);

  return (
    <Drawer
      open={visible}
      destroyOnClose
      footer={footer}
      onClose={onClose}
      afterOpenChange={onAfterOpen}
      rootClassName={styles.drawer}
      title={t("partyView.Sozlamalar")}
    >
      <div className="flex flex-1 flex-col gap-2">
        <DndContext sensors={sensors} onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]}>
          <SortableContext items={sortableItems()} strategy={verticalListSortingStrategy}>
            {columns?.map(el =>
              !el?.disabled && el?.key ? (
                <ColumnSwitch key={el?.key} column={el} columns={columns} setColumns={setColumns} />
              ) : null
            )}
          </SortableContext>
        </DndContext>
      </div>
    </Drawer>
  );
};

export default Index;
