import React from "react";
import { useDispatch } from "react-redux";
import { Dropdown, DropdownProps } from "antd";
import UnreadMessageDots from "features/app/components/unread-message/UnreadMessageDots";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { warehouseOrderActions } from "store/reducers/warehouseOrderReducer";

import { BaseItems } from "pages/party/view/title/items/base";

import { ClockFastForwardIcon } from "components";

type Props = {
  unreadMessageCount: number;
};

const Warehouse: React.FC<Props> = ({ unreadMessageCount }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setVisibleAdditional } = warehouseOrderActions;
  const { party_id: order_id } = useAppSelector(({ partyReducer }) => partyReducer.view);

  const onOpenAdditional = () => {
    dispatch(
      setVisibleAdditional({
        visible: true,
        order_id,
        zIndex: 111116
      })
    );
  };

  const menu = (): DropdownProps["menu"] => {
    const items = [
      ...BaseItems(),
      {
        key: "6",
        label: (
          <div className="flex items-center gap-2">
            <ClockFastForwardIcon />
            <span>{t("warehouse.Qabul tarixini ko'rish")}</span>
          </div>
        ),
        isView: true,
        onClick: onOpenAdditional
      }
    ];

    return {
      items: items?.filter(item => item?.isView)
    };
  };

  return (
    <Dropdown destroyPopupOnHide arrow={false} placement="bottom" trigger={["click"]} menu={menu()}>
      <div className="flex cursor-pointer items-center justify-center rounded-md border border-solid border-gray-300 bg-gray-50 px-2 py-1.5">
        <UnreadMessageDots count={unreadMessageCount} />
      </div>
    </Dropdown>
  );
};

export default Warehouse;
