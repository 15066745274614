import React, { Dispatch, SetStateAction } from "react";
import { PaginationType } from "features/app/utils/models/PaginationType";

import { WorkProgressModel, WorkProgressModelById } from "../../../../../../utils/models/WorkProgressModel";

import AllProgressesTable from "./all-progresses-table";
import ProgressesById from "./progresses-by-id";

type Props = {
  resources?: PaginationType<WorkProgressModel[]>;
  isLoading: boolean;
  setPage: Dispatch<SetStateAction<number>>;
  tab: string;
  progressId: number;
  progressesById: WorkProgressModelById[];
  isProgressByIdLoading: boolean;
};

const ResourceTable = ({
  resources,
  isLoading,
  setPage,
  tab,
  progressId,
  progressesById,
  isProgressByIdLoading
}: Props) => (
  <>
    {!progressId ? (
      <AllProgressesTable resources={resources!} tab={tab} isLoading={isLoading} setPage={setPage} />
    ) : (
      <ProgressesById tab={tab} progressesById={progressesById} isLoading={isProgressByIdLoading} />
    )}
  </>
);

export default ResourceTable;
