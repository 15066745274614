import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import Pagination from "features/app/components/pagination/Pagination";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { colors } from "features/app/utils/constants/colors";
import { cx } from "features/app/utils/helpers/cx";
import { PaginationType } from "features/app/utils/models/PaginationType";
import DiffPopoverAmount from "features/projects/components/detailed-project/dynamic-estimate/diff-popover/DiffPopoverAmount";
import DiffPopoverQuantity from "features/projects/components/detailed-project/dynamic-estimate/diff-popover/DiffPopoverQuantity";
import DiffPopoverTotalAmount from "features/projects/components/detailed-project/dynamic-estimate/diff-popover/DiffPopoverTotalAmount";
import { DynamicEstimateProductModel } from "features/projects/utils/models/dynamicEstimateModel";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { productSpentWidgetDetailDrawerActions, useDispatch, useSelector } from "store";

import { isEmptyObj, useAmountFixer } from "modules/common";

import styles from "./tab-content.module.scss";

interface IProps {
  data: PaginationType<DynamicEstimateProductModel[]>;
  isLoading: boolean;
}

const TabContent: React.FC<IProps> = ({ data, isLoading }) => {
  const dispatch = useDispatch();
  const amountFixer = useAmountFixer;
  const { setOpenDetailDrawer } = productSpentWidgetDetailDrawerActions;
  const { props } = useSelector(state => state.productSpentWidgetDrawerReducer);
  const { t } = useTranslation();
  const { queries } = useQueryParams();
  const { tab } = queries();
  const columns: ColumnsType = [
    {
      ...(Number(tab?.split("-")[0]) !== 0
        ? {
            title: `${t("project.Nomi")}`,
            render: (record: DynamicEstimateProductModel) => (
              <div className={cx("resource")}>
                <div style={{ borderColor: record?.product?.resource?.color, color: record?.product?.resource?.color }}>
                  {record?.product?.resource?.symbol[i18n.language]}
                </div>
                <span>
                  <PopoverShortName title={record?.product?.name[i18n.language]} length={25} />
                </span>
              </div>
            ),
            width: 500
          }
        : {
            title: `${t("project.Ish nomi va bo'limi")}`,
            render: (record: DynamicEstimateProductModel) => (
              <div className="flex flex-col gap-1">
                <span className="text-sm font-medium text-gray-900">
                  {record?.section?.place}.{record?.place} {record?.name}
                </span>
                <span className="text-xs font-normal text-gray-900">{record?.section?.name}</span>
              </div>
            ),
            width: 400
          })
    },
    {
      title: `${t("project.Birligi")}`,
      render: record => record?.unit?.symbol[i18n.language],
      width: 200,
      align: "center"
    },
    {
      title: (
        <div className="flex flex-col items-center gap-1">
          {t("project.Hajmi")}
          <span className="text-xs font-medium text-gray-500">
            ({t("project.Reja")} | {t("project.Fakt")})
          </span>
        </div>
      ),
      render: (record: DynamicEstimateProductModel) => (
        <div className={cx(styles.amounts)}>
          <span className={styles.amounts__item}>
            {amountFixer(String(record?.quantity))} {props?.data?.currency?.symbol}
          </span>
          <span className={styles.amounts__line} />

          <span className={styles.amounts__item}>
            <DiffPopoverQuantity record={record} classNames="text-sm font-medium" isFeature={false} />
            {props?.data?.currency?.symbol}
          </span>
        </div>
      ),
      width: 350,
      align: "center"
    },
    {
      title: (
        <div className="flex flex-col items-center gap-1">
          {t("project.Birlik summasi")}
          <span className="text-xs font-medium text-gray-500">
            ({t("project.Reja")} | {t("project.Fakt")})
          </span>
        </div>
      ),
      render: (record: DynamicEstimateProductModel) => (
        <div className={cx(styles.amounts)}>
          <span className={styles.amounts__item}>
            {amountFixer(String(record?.amount))} {props?.data?.currency?.symbol}
          </span>
          <span className={styles.amounts__line} />
          <div className={styles.amounts__item}>
            <DiffPopoverAmount record={record} classNames="text-sm font-medium" isFeature={false} />
            {props?.data?.currency?.symbol}
          </div>
        </div>
      ),
      width: 350,
      align: "center"
    },
    {
      title: (
        <div className="flex flex-col items-center gap-1">
          {t("project.Umumiy summasi")}
          <span className="text-xs font-medium text-gray-500">
            ({t("project.Reja")} | {t("project.Fakt")})
          </span>
        </div>
      ),
      render: (record: DynamicEstimateProductModel) => (
        <div className={cx(styles.amounts)}>
          <span className={styles.amounts__item}>
            {amountFixer(String(record?.total_amount))} {props?.data?.currency?.symbol}
          </span>
          <span className={styles.amounts__line} />
          <div className={styles.amounts__item}>
            <DiffPopoverTotalAmount record={record} classNames="text-sm font-medium" isFeature={false} />{" "}
            {props?.data?.currency?.symbol}
          </div>
        </div>
      ),
      width: 350,
      align: "center"
    },
    {
      ...(Number(tab?.split("-")[0]) === 0
        ? {
            title: `${t("project.Loyiha")}`,
            render: (record: DynamicEstimateProductModel) => (
              <div className="text-sm font-normal text-gray-800">{record?.section?.project?.name}</div>
            ),
            width: 250,
            align: "center"
          }
        : {})
    },
    {
      title: "",
      render: (record: DynamicEstimateProductModel) => (
        <div className={styles.arrow}>
          <ArrowNarrowIcon rotate={180} stroke={colors.GRAY_500} />
        </div>
      ),
      align: "center"
    }
  ];

  const onRow = (record: DynamicEstimateProductModel) => ({
    onClick: () => {
      dispatch(
        setOpenDetailDrawer({
          id: record?.id,
          visible: true,
          props: {
            product: record?.product,
            type: tab ? tab?.split("-")[1] : props?.data?.data[0]?.type
          },
          title: Number(tab?.split("-")[0]) === 0 ? t("project.Kassa holati") : t("project.Tarix")
        })
      );
    }
  });

  return (
    <div className="flex flex-col p-5">
      <Table
        bordered
        dataSource={data?.data}
        columns={columns?.filter(el => !isEmptyObj(el))}
        pagination={false}
        className={cx("footer_table", styles.table)}
        rowClassName={styles.table_row}
        onRow={onRow}
        rowKey="id"
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      {data?.total > 10 && (
        <div className={styles.pagination}>
          <Pagination
            paginationProps={{
              total: data.total,
              current: data.current_page
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TabContent;
