import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";

import { parseLocaledString, RU } from "modules/common";

import { CreateTransferState } from "./schema";

const initialState: CreateTransferState = {
  sender_warehouse_id: undefined,
  additional: {
    visible: false,
    id: 0
  },
  visible: false,
  warehouses: [],
  errors: []
};

export const slice = createSlice({
  initialState,
  name: "WAREHOUSE_CREATE_TRANSFER",
  reducers: {
    setVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    setSenderWarehouse: (state, action: PayloadAction<number>) => {
      state.sender_warehouse_id = action.payload;
      state.warehouses = [];
    },
    setAdditional: (state, action: PayloadAction<CreateTransferState["additional"]>) => {
      state.additional = action.payload;
    },
    setWarehouses: (state, action: PayloadAction<{ id: number; name: string }[]>) => {
      state.warehouses = action.payload?.map(({ id, name }) => {
        const oldWarehouse = state.warehouses?.find(({ recieved_warehouse_id }) => id === recieved_warehouse_id);

        if (oldWarehouse) {
          return {
            ...oldWarehouse,
            name,
            recieved_warehouse_id: id
          };
        }

        return {
          name,
          file_ids: [],
          products: [],
          date: undefined,
          description: undefined,
          custom_field_values: [],
          recieved_warehouse_id: id,
          recieved_project_id: undefined
        };
      });
    },
    setAdditionalItem: (
      state,
      action: PayloadAction<{
        id: number;
        key: "date" | "description" | "recieved_project_id";
        value: number | string | undefined;
      }>
    ) => {
      const { id, key, value } = action.payload;
      const index = state.warehouses?.findIndex(({ recieved_warehouse_id }) => recieved_warehouse_id === id);

      state.warehouses[index][key] = value as never;
    },
    setAdditionalFiles: (state, action: PayloadAction<{ value: number[]; index: number }>) => {
      const { value, index } = action.payload;

      state.warehouses[index].file_ids = value;
    },
    setDeleteAdditionalFile: (state, action: PayloadAction<{ warehouseId: number; index: number }>) => {
      const { warehouseId, index } = action.payload;

      const warehouseIndex = state.warehouses?.findIndex(
        ({ recieved_warehouse_id }) => recieved_warehouse_id === warehouseId
      );

      state.warehouses[warehouseIndex].file_ids.splice(index);
    },
    setDeleteWarehouse: (
      state,
      action: PayloadAction<{
        index: number;
        id: number;
      }>
    ) => {
      state.warehouses.splice(action.payload.index, 1);

      if (state.additional.id === action.payload.id) {
        state.additional = {
          visible: false,
          id: 0
        };
      }

      state.errors = state.errors?.filter(({ parentIndex }) => action.payload.index !== parentIndex);
    },
    setAddWarehouseProduct: (
      state,
      action: PayloadAction<CreateTransferState["warehouses"][number]["products"][number] & { index: number }>
    ) => {
      const { product_id: id, index, unit_id: unitId } = action.payload;
      const someProduct = state.warehouses[index]?.products?.some(
        ({ product_id, unit_id }) => product_id === id && unit_id === unitId
      );

      if (!someProduct) {
        state.warehouses[index].products.unshift(action.payload);
      } else {
        message.warning("Ushbu mahsulot qo'shilgan !");
      }
    },
    setDeleteWarehouseProduct: (
      state,
      action: PayloadAction<{
        parentIndex: number;
        index: number;
      }>
    ) => {
      const { parentIndex, index } = action.payload;
      const thisProduct = state.warehouses[parentIndex].products[index];

      state.warehouses[parentIndex].products.splice(index, 1);
      state.errors = state.errors.filter(({ parentIndex: pIndex, productId, unitId }) => {
        if (pIndex === parentIndex) {
          return !(productId === thisProduct?.product_id && unitId === thisProduct?.unit_id);
        }

        return true;
      });
    },
    setQuantityWarehouseProduct: (
      state,
      action: PayloadAction<{
        value: string;
        symbol: string;
        parentIndex: number;
        index: number;
      }>
    ) => {
      const { warehouses, errors } = state;
      const { parentIndex, index, value: formatValue, symbol } = action.payload;
      const { max_quantity, product_id, unit_id } = warehouses[parentIndex].products[index];
      const value = parseLocaledString(formatValue);
      let maxTotal = max_quantity;

      const errorIndex = errors?.findIndex(
        err => err?.parentIndex === parentIndex && err?.productId === product_id && err?.unitId === unit_id
      );

      warehouses?.forEach(({ products }, oldParentIndex) => {
        if (oldParentIndex !== parentIndex) {
          products?.forEach(({ quantity, product_id: productId, unit_id: unitId }) => {
            const someError = state.errors?.some(
              err => err?.parentIndex === oldParentIndex && err?.productId === productId && err?.unitId === unitId
            );

            if (productId === product_id && unitId === unit_id && !someError) {
              maxTotal -= parseLocaledString(quantity || "0");
            }
          });
        }
      });

      warehouses[parentIndex].products[index].quantity = formatValue;

      if (maxTotal >= value) {
        if (typeof errorIndex === "number" && errorIndex > -1) {
          errors?.splice(errorIndex, 1);
        }
      } else {
        message.warning(`Kiritilgan qiymat ${maxTotal?.toLocaleString(RU)} ${symbol} dan oshmasligi zarur`);

        if (typeof errorIndex === "number" && errorIndex < 0) {
          errors?.push({
            parentIndex,
            productId: product_id,
            unitId: unit_id
          });
        }
      }
    },
    setClearAllData: state => {
      state.sender_warehouse_id = undefined;
      state.additional = {
        visible: false,
        id: 0
      };
      state.errors = [];
      state.warehouses = [];
    }
  }
});

export default slice.reducer;
export const {
  setAdditional,
  setSenderWarehouse,
  setVisible,
  setWarehouses,
  setAdditionalFiles,
  setAdditionalItem,
  setDeleteWarehouse,
  setAddWarehouseProduct,
  setDeleteWarehouseProduct,
  setQuantityWarehouseProduct,
  setDeleteAdditionalFile,
  setClearAllData
} = slice.actions;
