import { useQuery } from "@tanstack/react-query";

import $api from "../../../../features/app/utils/helpers/axiosInstance";
import { partyQueryKeys } from "../query-keys";
import { CompanyPerson } from "../schema";

type Query = {
  companyPersons: CompanyPerson[];
};

export function useCompanyPersons() {
  const initialData: Query = {
    companyPersons: []
  };

  const { data = initialData, ...arg } = useQuery<Query>(
    [partyQueryKeys.COMPANY_PERSONS],
    async () => {
      const { data } = await $api.get("company-person/select");

      return { companyPersons: data?.data };
    },
    {
      staleTime: Infinity
    }
  );

  return { ...data, ...arg };
}
