import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "antd";
import dayjs, { Dayjs } from "dayjs";
import FilterDateRange from "features/app/components/filter-drawer/filter-date-range/FilterDateRange";
import FilterSelect from "features/app/components/filter-drawer/filter-select/FilterSelect";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";
import FilterDrawer from "features/app/components/filter-drawer/FilterDrawer";
import FiltersCount from "features/app/components/filter-drawer/filters-count/FiltersCount";
import { useGetSectionSelect, useGetUsersSelect } from "features/app/service/queries";
import { dayjsFormats } from "features/app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { WorkStatusEnum } from "features/app/utils/enums/WorkStatusEnum";
import { useGetCompanyPersonSelect } from "features/counterparts/service/queries";
import BuildingIcon from "features/payment/assets/icons/BuildingIcon";
import UsersIcon from "features/payment/assets/icons/UsersIcon";
import { useGetProjectSelect } from "features/payment/service/queries";
import ListIcon from "features/projects/assets/icons/ListIcon";
import ZapIcon from "features/projects/assets/icons/ZapIcon";
import FilterLinesIcon from "features/warehouse/assets/icons/FilterLinesIcon";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

const queryStringArr: string[] = [
  queryParamsKeys.MIN_DUE_DATE,
  queryParamsKeys.MAX_DUE_DATE,
  queryParamsKeys.SECTION_IDS,
  queryParamsKeys.USER_IDS,
  queryParamsKeys.COMPANY_PERSON_IDS,
  queryParamsKeys.PROJECT_IDS,
  queryParamsKeys.STATUSES
];

const ListOfWorksFilterDrawer: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const { queries } = useQueryParams();
  const { min_due_date, max_due_date } = queries();
  const location = useLocation();

  const [companyPersonSelected, setCompanyPersonSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.COMPANY_PERSON_IDS] as string[] | null
  );
  const [projectSelected, setProjectSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );
  const [userSelected, setUserSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.USER_IDS] as string[] | null
  );
  const [sectionSelected, setSectionSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.SECTION_IDS] as string[] | null
  );
  const [statusSelected, setStatusSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.STATUSES] as string[] | null
  );

  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([
    min_due_date ? dayjs(min_due_date, dayjsFormats.DATE) : null,
    max_due_date ? dayjs(max_due_date, dayjsFormats.DATE) : null
  ]);

  // queries
  const { data: companyPersons } = useGetCompanyPersonSelect(open);
  const { data: projects } = useGetProjectSelect({ enabled: open });
  const { data: users } = useGetUsersSelect(open);
  const { data: sections } = useGetSectionSelect(open);

  // select data
  const statuses = useMemo(
    () => [
      {
        id: WorkStatusEnum.OPEN,
        name: t("works.Ochiq")
      },
      {
        id: WorkStatusEnum.PROCESS,
        name: t("works.Jarayonda")
      },
      {
        id: WorkStatusEnum.COMPLETED,
        name: t("works.Yakunlangan")
      },
      {
        id: WorkStatusEnum.CLOSED,
        name: t("works.Yopilgan")
      }
    ],
    []
  );

  const onOpenFilter = () => {
    setOpen(true);
  };

  const filterButton = (
    <Button onClick={onOpenFilter}>
      <FilterLinesIcon /> {t("works.Filter")}
      <FiltersCount queryStringArr={queryStringArr} />
    </Button>
  );

  return (
    <FilterDrawer
      open={open}
      filterButton={filterButton}
      setOpen={setOpen}
      height="max-content"
      queryStringArr={queryStringArr}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.COMPANY_PERSON_IDS,
          selectedOptions: companyPersonSelected
        },
        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectSelected
        },
        {
          queryKey: queryParamsKeys.USER_IDS,
          selectedOptions: userSelected
        },
        {
          queryKey: queryParamsKeys.SECTION_IDS,
          selectedOptions: sectionSelected
        },
        {
          queryKey: queryParamsKeys.STATUSES,
          selectedOptions: statusSelected
        }
      ]}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_DUE_DATE,
          selectedValue: dateValue && dateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_DUE_DATE,
          selectedValue: dateValue && dateValue[1]
        }
      ]}
      setDateValue={[setDateValue]}
      setSelectedOptionsArr={
        [setCompanyPersonSelected, setProjectSelected, setUserSelected, setSectionSelected, setStatusSelected] as never
      }
    >
      <div className="flex flex-wrap gap-[10px]">
        <FilterTreeSelect
          selectedOptions={projectSelected}
          setSelectedOptions={setProjectSelected}
          placeholder={t("works.Loyiha")}
          data={projects}
          multiple
          icon={<BuildingIcon />}
          showSearch
        />

        <FilterSelect
          selectedOptions={sectionSelected}
          setSelectedOptions={setSectionSelected}
          placeholder={t("works.Bo'lim")}
          data={sections}
          multiple="multiple"
          icon={<ListIcon />}
          showSearch
        />
        <FilterSelect
          selectedOptions={statusSelected}
          setSelectedOptions={setStatusSelected}
          placeholder={t("works.Holati")}
          data={statuses}
          multiple="multiple"
          icon={<ZapIcon />}
          showSearch
        />
        <FilterSelect
          selectedOptions={userSelected}
          setSelectedOptions={setUserSelected}
          placeholder={t("works.Xodim")}
          data={users?.map(el => ({
            ...el,
            name: el?.full_name
          }))}
          multiple="multiple"
          icon={<UsersIcon />}
          showSearch
        />
        <FilterSelect
          selectedOptions={companyPersonSelected}
          setSelectedOptions={setCompanyPersonSelected}
          placeholder={t("works.Kontragent")}
          data={companyPersons}
          multiple="multiple"
          icon={<ZapIcon />}
          showSearch
        />
        <FilterDateRange value={dateValue} setValue={setDateValue} placeholder={t("works.Ish tugash vaqti")} />
      </div>
    </FilterDrawer>
  );
};

export default ListOfWorksFilterDrawer;
