import { PartyForm, PartyFormProduct } from "modules/party/create";

export function groupData(products: PartyFormProduct[]) {
  const person: PartyForm["company_person_group"] = [];

  products?.forEach(product => {
    const index = person?.findIndex(item => item?.id === (product?.company_person_id || 0));

    if (index >= 0) {
      const thisPerson = { ...person[index] };

      person[index] = {
        ...thisPerson,
        products: [...thisPerson.products, product]
      };
    } else if (product?.company_person_id) {
      person.unshift({
        id: product?.company_person_id as number | null,
        name: product?.company_person_name as string | null,
        products: [product]
      });
    } else {
      person.push({
        id: 0,
        name: "Belgilanmagan",
        products: [product]
      });
    }
  });

  return person;
}
