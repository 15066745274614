import React from "react";
import { useDispatch } from "react-redux";
import { Drawer, Spin } from "antd";

import { partyReducerActions } from "store/actions";
import { useSelector } from "store/index";

import { cx } from "modules/common";
import { usePartyView } from "modules/party/view";

import Content from "pages/party/update/content";

import { LoadingIndicator } from "components";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const dispatch = useDispatch();
  const { setPartyUpdateVisible, setPartyUpdateVat } = partyReducerActions;
  const { visible, id, zIndex } = useSelector(({ partyReducer }) => partyReducer.update);
  const { visible: estimate } = useSelector(({ partyReducer }) => partyReducer.estimate);
  const { visible: resource } = useSelector(({ partyReducer }) => partyReducer.resource);
  const { party, isLoading } = usePartyView({ id, enabled: visible });

  const onClose = () => {
    dispatch(
      setPartyUpdateVisible({
        id: undefined,
        visible: false
      })
    );
  };

  const onAfterOpen = (visible: boolean) => {
    if (visible) {
      dispatch(setPartyUpdateVat(party.warehouse_products?.some(({ vat }) => vat?.id)));
    }
  };

  return (
    <Drawer
      width="100vw"
      title={null}
      footer={null}
      open={visible}
      destroyOnClose
      zIndex={zIndex}
      onClose={onClose}
      afterOpenChange={onAfterOpen}
      rootClassName={cx(styles.update, { [styles.update_transform]: estimate || resource })}
    >
      <Spin spinning={isLoading} indicator={LoadingIndicator}>
        <Content isLoading={isLoading} party={party} />
      </Spin>
    </Drawer>
  );
};

export default Index;
