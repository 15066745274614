import React from "react";

import { cx } from "modules/common";
import { PartyStatus, statusData } from "modules/party/view";

type Props = {
  status: PartyStatus;
};

const Index: React.FC<Props> = ({ status }) => {
  const data = statusData?.[status];

  return (
    <div className={cx("flex w-max items-center justify-center gap-1 rounded-2xl px-2 py-1", data?.className)}>
      {data?.icon}
      <span className="text-sm font-medium">{data?.text}</span>
    </div>
  );
};

export default Index;
