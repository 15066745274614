import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { OneTransferViewModel } from "../../features/warehouse/utils/models/oneTransferViewModel";
import { TransferViewModel } from "../../features/warehouse/utils/models/transferViewModel";

interface ExcessDataModel extends OneTransferViewModel {
  transfer_id?: number;
}

interface TransferReducerModel {
  view: {
    visible: boolean;
    data?: TransferViewModel;
  };
  excess: {
    visible: boolean;
    data?: ExcessDataModel;
    isDifference?: boolean;
  };
}

const initialState: TransferReducerModel = {
  view: {
    visible: false,
    data: undefined
  },
  excess: {
    visible: false,
    data: undefined,
    isDifference: false
  }
};

const transferSlice = createSlice({
  name: sliceNames.TRANSFER,
  initialState,
  reducers: {
    setVisibleView: (state, action: PayloadAction<{ visible: boolean; data?: TransferViewModel | undefined }>) => {
      state.view = action.payload;
    },
    setVisibleExcess: (
      state,
      action: PayloadAction<{
        visible: boolean;
        data?: ExcessDataModel;
        isDifference?: boolean;
      }>
    ) => {
      state.excess.visible = action.payload.visible;
      state.excess.data = action.payload.data;
      state.excess.isDifference = action.payload.isDifference;
    }
  }
});

export default transferSlice.reducer;
export const transferActions = transferSlice.actions;
