import { QueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { counterpartsQueryKeys } from "features/counterparts/utils/constants/counterpartsQueryKeys";
import { impostQueryKeys } from "features/impost/utils/constants/impostQueryKeys";
import { paymentQueryKeys } from "features/payment/utils/constants/paymentQueryKeys";
import { supplyQueryNames } from "features/supply/utils/constants/supplyQueryNames";
import { warehouseQueryNames } from "features/warehouse/utils/constants/warehouseQueryNames";
import i18n from "i18next";

import { SuccessRes } from "modules/common";
import { partyQueryKeys } from "modules/party/view";

export const successHandler = (qc: QueryClient) => async (data: SuccessRes, variables: any) => {
  message.success(data.message[i18n.language]);

  // ONE PARTY
  if (variables?.id) {
    await qc.invalidateQueries([partyQueryKeys.VIEW, variables?.id]);
  }

  // SUPPLY
  await qc.invalidateQueries([supplyQueryNames.ORDERS]);
  await qc.invalidateQueries([supplyQueryNames.OFFERS]);
  await qc.invalidateQueries([supplyQueryNames.WAREHOUSE_PRODUCT]);
  await qc.invalidateQueries([supplyQueryNames.WAREHOUSE_PRODUCT_COUNT]);

  // WAREHOUSE
  await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_ORDERS]);

  // PAYMENT
  await qc.invalidateQueries([paymentQueryKeys.CALENDAR]);
  await qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS]);
  await qc.invalidateQueries([paymentQueryKeys.PAYMENTS_ORDER_VIEW]);

  // IMPOST
  await qc.invalidateQueries([impostQueryKeys.PARTS]);

  // COMPANY-PERSON
  await qc.invalidateQueries([counterpartsQueryKeys.ORDER]);
  await qc.invalidateQueries([counterpartsQueryKeys.CALENDAR_VIEW]);
};
