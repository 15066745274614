import React from "react";

import UysotBuildingContent from "./content";
import UysotBuildingFooter from "./footer";
import UysotBuildingTitle from "./title";

const UysotBuilding: React.FC = () => (
  <div className="box-border flex flex-col gap-4 rounded-xl border border-solid border-gray-200 bg-white">
    <UysotBuildingTitle />
    <UysotBuildingContent />
    <UysotBuildingFooter />
  </div>
);

export default UysotBuilding;
