import React, { useState } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { removeNaN } from "features/app/utils/helpers/removeNaN";

import { parsePhone } from "modules/common";
import { getPopupContainer } from "modules/party/create/helpers";

import { AutoComplete } from "components";

import { useCompanyPersonSelect } from "../queries";
import { CompanyPersonForm, CompanyPersonSelect } from "../schema";

type Props = {
  control: Control<CompanyPersonForm>;
  setValue: UseFormSetValue<CompanyPersonForm>;
};

const Tin: React.FC<Props> = ({ control, setValue }) => {
  const [search, setSearch] = useState<string>("");
  const [time, setTime] = useState<NodeJS.Timeout>();
  const { companyPersons } = useCompanyPersonSelect({ search });

  return (
    <AutoComplete
      label="INN"
      name="tin"
      showSearch
      withoutMessage
      control={control}
      notFoundContent={null}
      placeholder="INN kiriting"
      onSelect={(_, option) => {
        const { phone, tin } = structuredClone(option?.props?.person) as CompanyPersonSelect;

        setValue("latitude", undefined);
        setValue("longitude", undefined);
        setValue("tin", String(tin));
        setValue("phone", parsePhone(phone));
      }}
      onChange={value => {
        setValue("tin", removeNaN(String(value)));
      }}
      onSearch={value => {
        clearTimeout(time);

        setTime(
          setTimeout(() => {
            setSearch(value);
          }, 800)
        );
      }}
      getPopupContainer={getPopupContainer("create-company-person")}
      options={companyPersons?.map(person => ({
        key: person?.id,
        label: (
          <div className="flex flex-col gap-1">
            <span className="text-base text-gray-800">{person?.tin}</span>
            <span className="text-sm text-gray-500">{person?.name}</span>
            <span className="text-sm text-gray-500">{person?.phone}</span>
          </div>
        ),
        value: person?.tin,
        props: {
          name: person?.tin,
          person
        }
      }))}
    />
  );
};

export default Tin;
