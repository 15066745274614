import { UserTableDataModel } from "features/app/utils/models/user/userTableConfigModel";

const doneWorksTableConfigData: UserTableDataModel = {
  column: [
    {
      id: "work_name",
      name: "work_name",
      title: "Ish nomi va bo'limi",
      checked: true
    },
    {
      id: "work_section_project_name",
      name: "work_section_project_name",
      title: "Loyiha",
      checked: true
    },
    {
      id: "date",
      name: "date",
      title: "Sana",
      checked: true
    },
    {
      id:"quantity",
      name: "quantity",
      title: "Bajarildi",
      checked: true
    },
    {
      id:"confirmation_payment",
      name: "confirmation_payment",
      title: "Tasdiqlanish",
      checked: true
    },
    {
      id:"confirmation_users",
      name: "confirmation_users",
      title:"Guvohlar",
      checked: true
    },
    {
      id:"creator",
      name: "creator",
      title:"Bajardi",
      checked: true
    }
  ],
  size: 10
};

export default doneWorksTableConfigData;
