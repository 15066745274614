import React from "react";

const XCloseIcon: React.FC<{ width?: number; color?: string }> = ({ width, color }) => (
  <svg width={width ?? "16"} height={width ?? "16"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 4L4 12M4 4L12 12"
      stroke={color ?? "#344054"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default XCloseIcon;
