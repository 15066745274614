import { z } from "zod";

import { productWithUnitSchema } from "../schema";

export const partyFormProductSchema = z.object({
  task_product_id: z.number().optional().nullable(),
  vat_id: z.number().optional().nullable(),
  vat_value: z.number().optional().nullable(),
  vat_amount: z.number().optional().nullable(),
  product_id: z.number(),
  unit_id: z.number(),
  unit_name: z.string(),
  warehouse_id: z.number(),
  project_id: z.number().optional().nullable(),
  currency_id: z.number(),
  currency_symbol: z.string(),
  company_person_id: z.number().optional().nullable(),
  company_person_name: z.string().optional().nullable(),
  quantity: z.string().min(1),
  amount: z.string().min(1),
  total: z.string().min(1),
  rowId: z.string(),
  id: z.number().optional()
});

export const partyFormPersonGroupSchema = z
  .array(
    z.object({
      id: z.number().nullable(),
      name: z.string().nullable(),
      products: z.array(partyFormProductSchema)
    })
  )
  .optional();

export const partyFormCustomFieldValueSchema = z
  .string()
  .or(z.number())
  .or(z.array(z.string().or(z.number())))
  .nullable()
  .optional();

export const partyFormSchema = z.object({
  status: z.enum(["open", "pending", "ordered", "partially_recieved", "recieved", "rejected", "deleted", "buying"]),
  discount: z.string().optional(),
  agent_id: z.number().optional(),
  parse_discount: z.number().optional(),
  discount_currency_id: z.number().optional(),
  discount_currency_symbol: z.string().optional(),
  payment_date: z.string().optional().nullable(),
  delivery_date: z.string().optional().nullable(),
  company_person_group: partyFormPersonGroupSchema,
  check_products: z.array(productWithUnitSchema),
  warehouse_products: z.array(partyFormProductSchema).optional(),
  custom_field_values: z
    .array(
      z.object({
        custom_field_id: z.number().optional(),
        value: partyFormCustomFieldValueSchema
      })
    )
    .optional()
});

export const partyReqProductSchema = z.object({
  id: z.number().optional(),
  task_product_id: z.number().optional().nullable(),
  vat_id: z.number().optional().nullable(),
  product_id: z.number(),
  unit_id: z.number(),
  warehouse_id: z.number(),
  project_id: z.number().optional().nullable(),
  currency_id: z.number(),
  company_person_id: z.number().optional().nullable(),
  quantity: z.number(),
  amount: z.number()
});

export const partyFormReqPayload = z.object({
  status: z.enum(["open", "pending", "ordered", "partially_recieved", "recieved", "rejected", "deleted", "buying"]),
  discount: z.number().optional(),
  agent_id: z.number().optional(),
  discount_currency_id: z.number().optional(),
  payment_date: z.string().optional().nullable(),
  delivery_date: z.string().optional().nullable(),
  warehouse_products: z.array(partyReqProductSchema),
  custom_field_values: z
    .array(
      z.object({
        custom_field_id: z.number().optional(),
        value: z.string().or(z.number()).optional()
      })
    )
    .optional()
});

export const partyFormGroupSchema = partyFormSchema.omit({
  warehouse_products: true,
  check_products: true,
  custom_field_values: true
});

export type PartyForm = z.infer<typeof partyFormSchema>;
export type PartyPersonGroup = z.infer<typeof partyFormPersonGroupSchema>;
export type PartyFormProduct = z.infer<typeof partyFormProductSchema>;
export type PartyReqPayload = z.infer<typeof partyFormReqPayload>;
export type PartyFormCustomFieldValue = z.infer<typeof partyFormCustomFieldValueSchema>;
export type PartyReqProduct = z.infer<typeof partyReqProductSchema>;
export type Total = {
  amount: number;
  currencyId: number;
  currencySymbol: string;
};
