import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { MessageTab } from "modules/settings/message";

import { MessageTabContent, MessageTabs, Templates } from "./content";
import { SystemModal } from "./system-modal";
import { TemplatesModal } from "./templates-modal";

const MessageServiceContent: React.FC = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<MessageTab>("message");

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-3 border-b border-l-0 border-r-0 border-t-0 border-solid border-b-gray-200 pb-5">
        <span className="text-3xl font-semibold text-gray-900">{t("settings.interface.Sms tizimi")}</span>
        <span className="text-base font-medium text-gray-600">
          {t("settings.Bu yerda SMS tizimini ko'rishingiz mumkin")}
        </span>
      </div>
      <div className="flex w-full flex-col gap-3">
        <MessageTabs setCurrentTab={setCurrentTab} currentTab={currentTab} />
        {currentTab === "message" ? <MessageTabContent /> : <Templates />}
        <SystemModal />
        <TemplatesModal />
      </div>
    </div>
  );
};

export default MessageServiceContent;
