import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { UseFormReturn } from "react-hook-form";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/reducers/partyReducer";

import { colors } from "modules/common";
import { ConfirmationPaymentStatus, PartyStatus, PartyType, PartyViewForm, WarehouseProduct } from "modules/party/view";

import { ConditionalRender, XCloseIcon } from "components";

import { Impost, Supply, Warehouse } from "./items";
import Process from "./process";
import Statuses from "./statutes";

type Props = {
  percent: number;
  status: PartyStatus;
  paymentPercent: number;
  unreadMessageCount: number;
  products: WarehouseProduct[];
  form: UseFormReturn<PartyViewForm>;
  confirmationPayment: ConfirmationPaymentStatus;
};

const Index: React.FC<Props> = ({
  form,
  status,
  percent,
  products,
  paymentPercent,
  unreadMessageCount,
  confirmationPayment
}) => {
  const dispatch = useDispatch();
  const { setPartyView } = partyReducerActions;
  const { party_id, type, isProcess, isGroup } = useAppSelector(state => state.partyReducer.view);

  const contents: Record<PartyType, ReactNode> = {
    impost: <Impost unreadMessageCount={unreadMessageCount} status={status} products={products} />,
    warehouse: <Warehouse unreadMessageCount={unreadMessageCount} />,
    supply: <Supply status={status} unreadMessageCount={unreadMessageCount} />,
    payment: <>Payment</>
  };

  const onClose = () => {
    dispatch(
      setPartyView({
        type,
        status,
        isGroup,
        isProcess,
        visible: false,
        isAccept: false
      })
    );

    setTimeout(() => {
      form.reset({
        product: undefined,
        warehouse_products: undefined
      });
    }, 300);
  };

  return (
    <div className="box-border flex w-full items-center justify-between border border-l-0 border-r-0 border-t-0 border-solid border-gray-300 bg-white p-3.5">
      <div className="flex items-center gap-4">
        <h3 className="m-0 text-lg font-semibold text-gray-900">P - {party_id}</h3>
        <ConditionalRender if={isProcess} else={<Statuses status={status} />}>
          <Process
            supplyStatus={status}
            warehouseStatus={percent}
            paymentStatus={paymentPercent}
            impostStatus={confirmationPayment}
          />
        </ConditionalRender>
      </div>
      <div className="flex items-center gap-5">
        {contents[type] || <></>}
        <div
          onClick={onClose}
          className="flex cursor-pointer items-center justify-between rounded-lg p-1.5 duration-300 ease-in-out hover:bg-gray-200"
        >
          <XCloseIcon width={20} color={colors.GRAY_500} />
        </div>
      </div>
    </div>
  );
};

export default Index;
