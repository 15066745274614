import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { Spin } from "antd";
import i18n from "i18next";

import { colors } from "modules/common";
import { PartyResourceForm, SubCatalog, useSubCatalogs } from "modules/party/resource";

import { FolderIcon, LoadingIndicator, TooltipShortName } from "components";

type Props = {
  form: UseFormReturn<PartyResourceForm>;
};

const Index: React.FC<Props> = ({ form }) => {
  const { catalog } = useWatch({ control: form.control });
  const { subCatalogs, isLoading } = useSubCatalogs({ catalogId: catalog?.id });

  const onClickSubCatalog = (subCatalog: SubCatalog) => () => {
    form.setValue("sub_catalog", { id: subCatalog?.id, name: subCatalog?.name });
  };

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className="flex flex-wrap gap-3">
        {subCatalogs?.map(subCatalog => (
          <div
            key={subCatalog?.id}
            onClick={onClickSubCatalog(subCatalog)}
            className="flex w-44 cursor-pointer items-center gap-2 rounded-xl px-2.5 py-2 duration-300 ease-in-out hover:bg-gray-200"
          >
            <FolderIcon fill={subCatalog?.color || colors.PRIMARY} />
            <div className="flex flex-col gap-1">
              <span className="text-base font-semibold text-gray-600">
                <TooltipShortName arrow={false} placement="top" title={subCatalog?.name?.[i18n.language]} length={10} />
              </span>
              <span className="text-xs font-medium text-gray-400">{subCatalog?.products_count || 0} ta shablon</span>
            </div>
          </div>
        ))}
      </div>
    </Spin>
  );
};

export default Index;
