import React from "react";
import { Button, Collapse } from "antd";
import DefectView from "features/inventory/components/defects/bottom/defect-view/DefectView";

import { useAuth } from "modules/auth/hooks";

import MessageIcon from "../../../../../inventory/assets/icons/MessageIcon";
import { ConfirmationStatusIcons } from "../../../../utils/constants/confirmationStatusIcons";
import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { ConfirmationUserStatusEnum } from "../../../../utils/enums/confirmationUserStatusEnum";
import { NotificationModel } from "../../../../utils/models/notificationModel";
import RecievedStatusConfirmationUser from "../../recieved-status-confirmation-user/recievedStatusConfirmationUser";
import RejectStatusConfirmationUser from "../../reject-status-confirmation-user/rejectStatusConfirmationUser";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onOpenChat: (objectId: number, type: string) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const InventoryDefectCreated = ({ record, onOpenChat, setOpen, open }: Props) => {
  const id = useAuth().user?.id;

  const checkToStatus = () => {
    const result = record?.inventory_defect?.confirmation_users?.filter(user => {
      if (user?.user?.id === id && user?.status === ConfirmationUserStatusEnum.RECIEVED) {
        return true;
      }
      return false;
    });

    if (result?.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.description} onClick={() => setOpen(true)}>
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        <span className={styles.name}>{record?.user?.full_name}</span> yaroqsiz mahsulotlarni tasdiqlashni so’radi
      </p>
      <div className={styles.products}>
        <div className="resource">
          <div className={styles.resource}>MEX</div>
          <span className={styles.bold}>{record?.inventory_defect?.inventory?.name}</span>
        </div>
        <div className={styles.products__count}>{record?.inventory_defect?.inventory?.code}</div>
      </div>
      <Collapse
        className={styles.collapse}
        items={[
          {
            key: "1",
            label: "Guvohlar",
            children: (
              <>
                {record?.inventory_defect?.confirmation_users?.map(user => (
                  <div className={styles.collapse__item} key={user.id}>
                    <div className={styles.collapse__item__info}>
                      <img src={user?.user?.image} alt={user?.user?.full_name} className={styles.collapse__item__img} />
                      <p>{user?.user?.full_name}</p>
                    </div>
                    <div>{ConfirmationStatusIcons[user?.status]}</div>
                  </div>
                ))}
              </>
            )
          }
        ]}
        expandIconPosition="end"
      />
      <div className={styles.actions}>
        <Button
          className={styles.actions__chat}
          onClick={() => onOpenChat(record?.inventory_defect?.id, "inventory_defects")}
        >
          <MessageIcon />
        </Button>
        {!(
          record?.inventory_defect?.status === ConfirmationUserStatusEnum.RECIEVED ||
          record?.inventory_defect?.status === ConfirmationUserStatusEnum.REJECTED ||
          checkToStatus()
        ) && <RejectStatusConfirmationUser users={record?.inventory_defect?.confirmation_users} />}
        {!(
          record?.inventory_defect?.status === ConfirmationUserStatusEnum.RECIEVED ||
          record?.inventory_defect?.status === ConfirmationUserStatusEnum.REJECTED ||
          checkToStatus()
        ) && <RecievedStatusConfirmationUser users={record?.inventory_defect?.confirmation_users} />}
      </div>
      <DefectView
        open={open}
        setOpen={setOpen}
        record={
          {
            id: record?.inventory_defect?.id
          } as never
        }
        setId={() => {}}
      />
    </div>
  );
};

export default InventoryDefectCreated;
