import React from "react";

const CircleArrowLeftIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#1E90FF" />
    <path
      d="M10.667 16H21.3337M21.3337 16L17.3337 12M21.3337 16L17.3337 20"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircleArrowLeftIcon;
