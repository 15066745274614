import React from "react";

import { useAppSelector } from "hooks/redux";

import { cx } from "modules/common";

import Top from "./top";
import Warehouses from "./warehouses";

const Left: React.FC = () => {
  const { visible } = useAppSelector(({ warehouseCreateTransfer }) => warehouseCreateTransfer.additional);

  return (
    <div className={cx("box-border flex flex-1 flex-col gap-4 p-5 pb-0", { "w-[50%]": visible, "w-full": !visible })}>
      <Top />
      <div className="box-border h-full w-full overflow-y-auto pb-5">
        <Warehouses />
      </div>
    </div>
  );
};

export default Left;
