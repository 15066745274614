import React from "react";
import { useDispatch } from "react-redux";
import { FieldErrors, UseFormReturn, useWatch } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, message } from "antd";
import $api from "features/app/utils/helpers/axiosInstance";
import { errorHandler } from "features/app/utils/helpers/errorHandler";
import { ErrorRes, SuccessRes } from "features/app/utils/models/responseType";
import { warehouseQueryNames } from "features/warehouse/utils/constants/warehouseQueryNames";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/reducers/partyReducer";

import { isEmptyArr, parseLocaledString } from "modules/common";
import { partyQueryKeys, PartyViewForm, PartyViewWarehousePayload, submitProducts } from "modules/party/view";

import { ConditionalRender } from "components";

type Props = {
  form: UseFormReturn<PartyViewForm>;
};

const Warehouse: React.FC<Props> = ({ form }) => {
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { setPartyView } = partyReducerActions;
  const { view } = useAppSelector(({ partyReducer }) => partyReducer);
  const { warehouse_products } = useWatch({ control: form.control });
  const products = submitProducts(warehouse_products);

  const onClose = () => {
    dispatch(
      setPartyView({
        ...view,
        visible: false,
        isAccept: false
      })
    );

    setTimeout(() => {
      form.reset({
        product: undefined,
        warehouse_products: undefined
      });
    }, 300);
  };

  const received = useMutation<SuccessRes, ErrorRes, PartyViewWarehousePayload>(
    async payload => {
      const { data } = await $api.put("warehouse-product/order-recieved", payload);

      return data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([warehouseQueryNames.WAREHOUSE_ORDERS]);
        await qc.invalidateQueries([warehouseQueryNames.ONE_WAREHOUSE_ORDER]);
        await qc.invalidateQueries([warehouseQueryNames.HISTORY_ORDER]);
        await qc.invalidateQueries([partyQueryKeys.VIEW]);

        message.success(res.message[i18n.language]);
      }
    }
  );

  const onSubmit = async () => {
    await new Promise(onSettled => {
      received
        .mutateAsync(
          {
            id: view!.party_id!,
            warehouse_products:
              products?.map(({ quantity, id }) => ({
                id: id!,
                quantity: parseLocaledString(String(quantity))
              })) || []
          },
          { onSettled }
        )
        .then(onClose);
    });
  };

  const onError = (error: FieldErrors) => {
    console.log("Form reject ERRORS", error);
    console.log("Form reject VALUES", form.getValues());
  };

  return (
    <div className="flex h-full w-full justify-end gap-3">
      <Button onClick={onClose}>Yopish</Button>
      <Button
        type="primary"
        loading={form.formState.isSubmitting}
        onClick={form.handleSubmit(onSubmit, onError)}
        disabled={isEmptyArr(warehouse_products) || !(products?.length && products?.length > 0)}
      >
        <ConditionalRender if={products?.length && products?.length > 0}>
          <div className="box-border flex h-6 w-6 items-center justify-center rounded-full bg-white text-xs font-medium text-primary-500">
            {products?.length}
          </div>
        </ConditionalRender>
        Qabul qilish
      </Button>
    </div>
  );
};

export default Warehouse;
