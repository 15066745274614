import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import { errorHandler, ErrorRes, SuccessRes } from "modules/common";

import $api from "../../../../features/app/utils/helpers/axiosInstance";
import { impostQueryKeys } from "../../../../features/impost/utils/constants/impostQueryKeys";

export function usePartyConfirmRejected() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { id?: number }>(
    async ({ id }) => {
      const res = await $api.put("confirmation-payment/order-rejected", { id });

      return res.data;
    },
    {
      onSuccess: async data => {
        await qc.invalidateQueries([impostQueryKeys.PARTS]);
        await qc.invalidateQueries([impostQueryKeys.PARTS_DETAIL]);
        await qc.invalidateQueries([impostQueryKeys.IMPOST_ALL_COUNTS]);

        message.success(data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}
