import React from "react";
import { Popover } from "antd";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { getFirstLetter } from "features/app/utils/helpers/getFirstLetter";

import { isEmptyArr } from "modules/common";
import { TooltipShortName } from "components";

type Props = {
  users: {
    id: number;
    name: string;
    image: string;
  }[];
};

const Users: React.FC<Props> = ({ users }) => (
  <div className="flex w-full items-center justify-center">
    <Popover
      arrow={false}
      content={
        <div className="flex flex-col gap-2">
          {users?.map(({ image, name, id }) => (
            <div key={id} className="flex items-center gap-2">
              {image ? (
                <img src={image} alt={name} className="h-8 w-8 rounded-full object-cover" />
              ) : (
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 text-sm font-medium text-gray-600">
                  {getFirstLetter(name)}
                </div>
              )}
              <span className="text-sm font-normal text-gray-700">
                <TooltipShortName title={name} length={20} />
              </span>
            </div>
          ))}
        </div>
      }
    >
      <div className="flex">
        {users?.slice(0, 3)?.map(({ id, name, image }) =>
          image ? (
            <img key={id} src={image} alt={name} className="h-8 w-8 rounded-full object-cover" />
          ) : (
            <div
              key={id}
              className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-solid border-white bg-gray-100 text-sm font-medium text-gray-600"
            >
              {getFirstLetter(name)}
            </div>
          )
        )}
        <ConditionalRender if={!isEmptyArr(users?.slice(3))}>
          <div className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-solid border-white bg-gray-100 text-sm font-medium text-gray-600">
            +{users?.slice(3)?.length}
          </div>
        </ConditionalRender>
      </div>
    </Popover>
  </div>
);

export default Users;
