import { RU } from "features/app/utils/constants/languages";
import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";
import { useDispatch } from "react-redux";
import { partyReducerActions } from "store/reducers/partyReducer";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const ConfirmationOrderRecieved = ({ record, onNavigatePage }: Props) => {
  const dispatch = useDispatch();
  const { setPartyView } = partyReducerActions;

  const onOpenPartyView = () => {
    dispatch(
      setPartyView({
        visible: true,
        type: "supply",
        party_id: record?.confirmation_order?.id,
        status: "rejected"
      })
    );
  };

  return (
    <div onClick={onOpenPartyView} className={styles.description}>
      <h3>{notificationTypesTitleValue[record?.type]}</h3>
      <p>
        {record?.user?.full_name} {record?.confirmation_order?.amount?.toLocaleString(RU)}{" "}
        {record?.confirmation_order?.currency?.symbol}{" "}
        <span className={styles.name}>P - {record?.confirmation_order?.id}</span> partiya tasdiqlandi.
      </p>
    </div>
  );
};

export default ConfirmationOrderRecieved;
