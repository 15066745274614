import React from "react";
import { Control, UseFieldArrayRemove, useWatch } from "react-hook-form";
import { Spin, Tooltip } from "antd";

import { cx } from "modules/common";
import { useDeleteProducts } from "modules/party/view";

import { LoadingIndicator, TrashIcon } from "components";

import { PartyForm } from "../../../schema";

type Props = {
  index: number;
  isUpdate?: boolean;
  remove: UseFieldArrayRemove;
  control: Control<PartyForm>;
};

const Delete: React.FC<Props> = ({ index, isUpdate, remove, control }) => {
  const deleteProduct = useDeleteProducts(false);
  const { warehouse_products } = useWatch({ control });

  const onDeleteProduct = (index: number) => () => {
    const id = warehouse_products?.[index]?.id;

    if (isUpdate && id) {
      deleteProduct.mutateAsync([id]).then(() => {
        remove(index);
      });
    } else {
      remove(index);
    }
  };

  return (
    <Spin spinning={deleteProduct.isLoading} indicator={LoadingIndicator}>
      <div className="flex h-full w-full items-center justify-center bg-white">
        <Tooltip title="O'chirish" arrow={false} placement="top">
          <div
            onClick={onDeleteProduct(index)}
            className={cx("mx-2 flex cursor-pointer items-center justify-end", "warehouse-product-delete")}
          >
            <TrashIcon />
          </div>
        </Tooltip>
      </div>
    </Spin>
  );
};

export default Delete;
