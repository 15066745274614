import React from "react";

import { Select } from "components";
import { useTranslation } from "react-i18next";
import { StatusEnums } from "features/app/components/statuses/statusEnums";
import { UseFormReturn } from "react-hook-form";
import { WidgetSettingsForm } from "modules/dashboard";

interface IProps {
  form: UseFormReturn<WidgetSettingsForm>;
}

const Statuses: React.FC<IProps> = ({ form }) => {
  const { t } = useTranslation();

  const selectOptions = [
    {
      label: t("dashboard.Qoralama"),
      value: StatusEnums.OPEN
    },
    {
      label: t("dashboard.Kutilmoqda"),
      value: StatusEnums.PENDING
    },
    {
      label: t("dashboard.Partiya qilingan"),
      value: StatusEnums.ORDERED
    },
    {
      label: t("dashboard.Jarayonda"),
      value: StatusEnums.PARTIALLY_RECIEVED
    },
    {
      label: t("dashboard.Yopilgan"),
      value: StatusEnums.RECIEVED
    },
    {
      label: t("dashboard.Bekor qilingan"),
      value: StatusEnums.REJECTED
    },
    {
      label: t("dashboard.Sotib olinmoqda"),
      value: StatusEnums.BUYING
    }
  ];

  return (
    <Select
      options={selectOptions}
      name="statuses"
      label={t("dashboard.Statuslar")}
      control={form.control}
      allowClear
      mode="multiple"
    />
  );
};

export default Statuses;
