import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { PartImpostModel } from "../../features/impost/utils/models/partImpostModel";
import { ProductModel as ProductDrawerDataModel } from "../../features/settings/utils/models/product/productModel";
import { OrderModel, WarehouseProductModel } from "../../features/supply/utils/models/orderModel";
import { ProductModel } from "../../features/supply/utils/models/productModel";
import { ProjectSectionModel, ProjectTaskProductModel } from "../../features/supply/utils/models/projectModel";

interface InitialState {
  visiblePartModal: boolean;
  partModalData: ProductModel[];
  searchedData: ProductModel[];
  visiblePartDetail: boolean;
  orderData: WarehouseProductModel[];
  searchOrder: WarehouseProductModel[];
  partId: number | null;
  projectId: number;
  projectData: ProjectSectionModel[];
  estimateCheckedProducts: number;
  createFromEstimate: boolean;
  createFromTemplate: boolean;
  partDiffModal: {
    visible: boolean;
    record?: WarehouseProductModel;
  };
  partPrintModal: {
    visible: boolean;
    isNotAmount: boolean;
  };
  partyModal: {
    isPartyProcess: boolean;
    visible: boolean;
    party_id?: number;
    isEditing?: boolean;
    isView?: boolean;
    isTable?: boolean;
    zIndex?: number;
    updateProduct?: {
      condition: boolean;
      index?: string;
    };
    paymentDate?: string;
    tableData?: PartImpostModel;
    selectProducts?: ProductModel[];
  };
  productDrawer: {
    visible: boolean;
    folderId: {
      category_id?: number;
      sub_category_id?: number;
    };
  };
  partyEstimate: {
    visible: boolean;
  };
  orderModal: {
    visible: boolean;
    isTable?: boolean;
    orderId?: number;
    isEdit?: boolean;
    selectProducts?: ProductModel[];
  };
  estimateData: {
    taskIds: number[];
    projectId?: number;
    sectionIds: number[];
    products: ProjectTaskProductModel[];
  };
  productDrawerData: {
    products?: ProductDrawerDataModel[];
    ids?: number[];
  };
  orderEstimate: {
    visible: boolean;
  };
  orderDrawer: {
    visible: boolean;
  };
  partyPrint: {
    partyId?: number;
  };
  selectOrderProducts: ProductModel[];
  createProductModal: {
    visible: boolean;
    index: number | string;
    name: string;
    fieldName?: any;
  };
  selectParties: OrderModel[];
}

const initialState: InitialState = {
  visiblePartModal: false,
  partModalData: [],
  searchedData: [],
  visiblePartDetail: false,
  orderData: [],
  searchOrder: [],
  partId: null,
  projectData: [],
  projectId: 0,
  estimateCheckedProducts: 0,
  createFromEstimate: false,
  createFromTemplate: false,
  partDiffModal: {
    visible: false
  },
  partPrintModal: {
    visible: false,
    isNotAmount: false
  },
  partyModal: {
    isPartyProcess: false,
    visible: false,
    party_id: undefined,
    isEditing: false,
    isView: false,
    isTable: false
  },
  productDrawer: {
    visible: false,
    folderId: {
      category_id: undefined,
      sub_category_id: undefined
    }
  },
  partyEstimate: {
    visible: false
  },
  orderModal: {
    visible: false,
    orderId: undefined,
    isTable: false,
    selectProducts: [],
    isEdit: false
  },
  estimateData: {
    taskIds: [],
    sectionIds: [],
    projectId: undefined,
    products: []
  },
  productDrawerData: {
    ids: [],
    products: []
  },
  orderEstimate: {
    visible: false
  },
  orderDrawer: {
    visible: false
  },
  partyPrint: {},
  selectOrderProducts: [],
  createProductModal: {
    visible: false,
    index: 0,
    name: ""
  },
  selectParties: []
};

const supplySlice = createSlice({
  name: sliceNames.SUPPLY,
  initialState,
  reducers: {
    setPartModalData: (state, action: PayloadAction<ProductModel[]>) => {
      const products: ProductModel[] = action.payload;
      let result: ProductModel[] = [];

      products?.forEach(product => {
        if (
          result?.some(
            resultElem => resultElem?.product.id === product?.product.id // If there are 2 identical products
          )
        ) {
          result = result?.map(resultElem2 =>
            resultElem2.product.id === product?.product.id
              ? {
                  ...resultElem2,
                  expandable: true,
                  quantity: resultElem2?.quantity + product?.quantity, // Add the entire quantity of goods
                  amount: resultElem2.amount !== product.amount ? 0 : resultElem2.amount, // If the unit prices are different, we reset them to zero.
                  data: [
                    ...(resultElem2?.data && resultElem2?.data?.length > 0
                      ? // eslint-disable-next-line no-unsafe-optional-chaining
                        resultElem2?.data
                      : []),
                    product
                  ]
                }
              : resultElem2
          );
        } else {
          result?.push({ ...product, data: [product] });
        }
      });

      state.partModalData = result;
      state.searchedData = [...state.partModalData];
    },
    deleteProducts: (state, action: PayloadAction<number>) => {
      const filterProducts = state.partModalData.filter(product => product.id !== action.payload);

      state.partModalData = filterProducts;
      state.searchedData = [...state.partModalData];
    },
    setIsVisiblePartModal: (state, action: PayloadAction<boolean>) => {
      state.visiblePartModal = action.payload;
    },
    setIsVisiblePartDetail: (state, action: PayloadAction<boolean>) => {
      state.visiblePartDetail = action.payload;
    },
    setPartId: (state, action: PayloadAction<number>) => {
      state.partId = action.payload;
    },
    setProject: (state, action: PayloadAction<ProjectSectionModel[]>) => {
      state.projectData = action.payload;
    },
    setProjectId: (state, action: PayloadAction<number>) => {
      state.projectId = action.payload;
    },
    setTaskProduct: (
      state,
      action: PayloadAction<{
        products: ProjectTaskProductModel[];
        sectionIndex: number;
        taskIndex: number;
      }>
    ) => {
      const { products, sectionIndex, taskIndex } = action.payload;

      if (state.projectData[sectionIndex] && state.projectData[sectionIndex].tasks[taskIndex]) {
        state.projectData[sectionIndex].tasks[taskIndex].task_products = products;
      }
    },
    setChangeTask: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        taskIndex: number;
        status: boolean;
      }>
    ) => {
      const { sectionIndex, taskIndex, status } = action.payload;

      state.projectData[sectionIndex].tasks[taskIndex].checked = status;
      const newData = state.projectData[sectionIndex].tasks[taskIndex].task_products?.map(product => ({
        ...product,
        checked: status
      }));

      state.projectData[sectionIndex].tasks[taskIndex].task_products = newData;
    },
    setChangeProduct: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        taskIndex: number;
        productIndex: number;
        status: boolean;
      }>
    ) => {
      const { sectionIndex, taskIndex, productIndex, status } = action.payload;

      state.projectData[sectionIndex].tasks[taskIndex].task_products[productIndex].checked = status;
    },
    setCheckedProducts: state => {
      let count = 0;
      const result: ProductModel[] = [];

      state.projectData?.forEach(section => {
        section?.tasks?.forEach(task => {
          task?.task_products?.forEach(product => {
            if (product?.checked) {
              count += 1;
              // @ts-ignore
              result.push(product);
            }
          });
        });
      });
      state.estimateCheckedProducts = count;
      state.partModalData = result;
    },
    setResetCheckedProducts: state => {
      const result: ProjectSectionModel[] = state.projectData.map(section => ({
        ...section,
        tasks: section.tasks.map(task => ({
          ...task,
          checked: false,
          task_products: task?.task_products?.map(product => ({ ...product, checked: false }))
        }))
      }));

      state.projectData = result;
    },
    setCreateFromEstimate: (state, action: PayloadAction<boolean>) => {
      state.createFromEstimate = action.payload;
    },
    setCreateFromTemplate: (state, action: PayloadAction<boolean>) => {
      state.createFromTemplate = action.payload;
    },
    setPartDiffModal: (state, action: PayloadAction<{ [key: string]: never }>) => {
      state.partDiffModal.visible = action.payload.visible;
      state.partDiffModal.record = action.payload.record;
    },
    setPartPrintModal: (
      state,
      action: PayloadAction<{
        visible: boolean;
        isNotAmount: boolean;
      }>
    ) => {
      state.partPrintModal.visible = action.payload.visible;
      state.partPrintModal.isNotAmount = action.payload.isNotAmount;
    },
    setPartyModal: (
      state,
      action: PayloadAction<{
        visible: boolean;
        party_id?: number;
        isEditing?: boolean;
        isView?: boolean;
        selectProducts?: ProductModel[];
        tableData?: PartImpostModel;
        paymentDate?: string;
        zIndex?: number;
        updateProduct?: {
          condition: boolean;
          index?: string;
        };
      }>
    ) => {
      state.partyModal.visible = action.payload.visible;
      state.partyModal.party_id = action.payload.party_id;
      state.partyModal.isEditing = action.payload.isEditing;
      state.partyModal.isView = action.payload.isView;
      state.partyModal.tableData = action.payload.tableData;
      state.partyModal.selectProducts = action.payload.selectProducts;
      state.partyModal.paymentDate = action.payload.paymentDate;
      state.partyModal.updateProduct = action.payload.updateProduct;
      state.partyModal.zIndex = action.payload.zIndex;
    },
    setProductDrawer: (state, action: PayloadAction<{ visible: boolean }>) => {
      state.productDrawer.visible = action.payload.visible;
    },
    setProductFolderId: (
      state,
      action: PayloadAction<{
        category_id?: number;
        sub_category_id?: number;
      }>
    ) => {
      state.productDrawer.folderId = action.payload;
    },
    setProductDrawerData: (state, action: PayloadAction<{ ids: number[]; products: ProductDrawerDataModel[] }>) => {
      state.productDrawerData.ids = action.payload.ids;
      state.productDrawerData.products = action.payload.products;
    },
    setPartyEstimate: (state, action: PayloadAction<{ visible: boolean }>) => {
      state.partyEstimate.visible = action.payload.visible;
    },
    setEstimateProjectId: (state, action: PayloadAction<string | undefined>) => {
      state.estimateData.projectId = Number(action.payload);
    },
    setEstimateData: (
      state,
      action: PayloadAction<{
        data: number[] | ProjectTaskProductModel[];
        key: string;
      }>
    ) => {
      // @ts-ignore
      state.estimateData[action.payload.key] = action.payload.data;
    },
    setPartyTableView: (state, action: PayloadAction<boolean>) => {
      state.partyModal.isTable = action.payload;
    },
    setOrderModal: (
      state,
      action: PayloadAction<{
        visible: boolean;
        selectProducts?: ProductModel[];
        isEdit?: boolean;
        orderId?: number;
      }>
    ) => {
      state.orderModal.visible = action.payload.visible;
      state.orderModal.isEdit = action.payload.isEdit;
      state.orderModal.selectProducts = action.payload.selectProducts;
      state.orderModal.orderId = action.payload.orderId;
    },
    setOrderEstimate: (state, action: PayloadAction<{ visible: boolean }>) => {
      state.orderEstimate.visible = action.payload.visible;
    },
    setOrderDrawer: (state, action: PayloadAction<{ visible: boolean }>) => {
      state.orderDrawer.visible = action.payload.visible;
    },
    setOrderTableView: (state, action: PayloadAction<boolean>) => {
      state.orderModal.isTable = action.payload;
    },
    setSelectOrderProducts: (state, action: PayloadAction<ProductModel[]>) => {
      state.selectOrderProducts = action.payload;
    },
    setAddItemSelectOrderProducts: (state, action: PayloadAction<ProductModel>) => {
      const newSelectProducts = [...state.selectOrderProducts];
      const isNotOffer = newSelectProducts?.some(item => item?.id === action.payload?.id);

      if (!isNotOffer) {
        newSelectProducts.push(action.payload);
      }

      state.selectOrderProducts = newSelectProducts;
    },
    setCreateProductModal: (
      state,
      action: PayloadAction<{
        visible: boolean;
        index: number | string;
        name: string;
        fieldName?: any;
      }>
    ) => {
      state.createProductModal.visible = action.payload.visible;
      state.createProductModal.index = action.payload.index;
      state.createProductModal.name = action.payload.name;
      state.createProductModal.fieldName = action.payload.fieldName;
    },
    setSelectParties: (state, action: PayloadAction<OrderModel[]>) => {
      state.selectParties = action.payload;
    },
    setSelectItemParties: (state, action: PayloadAction<OrderModel>) => {
      const newSelectParties = [...state.selectParties];

      const isNotParty = newSelectParties?.some(item => item.id === action.payload.id);

      if (!isNotParty) {
        newSelectParties.push(action.payload);
      }

      state.selectParties = newSelectParties;
    },
    setPartyUpdateProduct: (state, action: PayloadAction<{ condition: boolean; index?: string }>) => {
      state.partyModal.updateProduct = action.payload;
    },
    setIsPartyProcess: (state, action: PayloadAction<{ isPartyProcess: boolean }>) => {
      state.partyModal.isPartyProcess = action.payload.isPartyProcess;
    }
  }
});

export default supplySlice.reducer;
export const supplyActions = supplySlice.actions;
