import { combineReducers } from "@reduxjs/toolkit";

import projectReportModalReducer from "store/reducers/projectReportModalReducer";
import transferMakingReducer from "store/reducers/transferMakingReducer";

import auth from "modules/auth/slice";
import uysot from "modules/settings/uysot/slice";
import warehouseCreateTransfer from "modules/warehouse/create-transfer/slice";

import activatingMessagingSystemsReducer from "./activating-messaging-systems-reducer";
import addressReducer from "./addressReducer";
import appReducer from "./appReducer";
import builderReducer from "./builderReducer";
import chatReducer from "./chatReducer";
import companyPersonReducer from "./companyPersonReducer";
import defectModalReducer from "./defectModalReducer";
import defectViewReducer from "./defectViewReducer";
import fileViewerReducer from "./file-viewer";
import ganttReducer from "./ganttReducer";
import impostReducer from "./impostReducer";
import integrationReducer from "./integrationReducer";
import inventoryReducer from "./inventoryReducer";
import laboratoryDrawerReducer from "./laboratoryDrawerReducer";
import localStorageReducer from "./localStorageReducer";
import notificationReducer from "./notificationReducer";
import partyReducer from "./partyReducer";
import paymentReducer from "./paymentReducer";
import productSpentWidgetDetailDrawerReducer from "./product-spent-widget-detail-drawer-reducer";
import productSpentWidgetDrawerReducer from "./product-spent-widget-drawer-reducer";
import productsTemplate from "./productsTempleteReducer";
import projectsReducer from "./projectsReducer";
import settingsReducer from "./settingsReducer";
import supplyOfferReducer from "./supplyOfferReducer";
import supplyReducer from "./supplyReducer";
import tableWidgetContentModalReducer from "./tableWidgetContentModalReducer";
import templatesModalReducer from "./templates-modal-reducer";
import transferModalDataReducer from "./transferModalReducer";
import transferReducer from "./transferReducer";
import warehouseOrderReducer from "./warehouseOrderReducer";
import warehouseProducts from "./warehouseProductsReducer";
import warehouseTransferReducer from "./warehouseTransferReducer";
import workModalReducer from "./workModalReducer";

const reducer = combineReducers({
  auth,
  projectsReducer,
  paymentReducer,
  workModalReducer,
  transferModalDataReducer,
  defectModalReducer,
  defectViewReducer,
  chatReducer,
  warehouseTransferReducer,
  supplyReducer,
  transferReducer,
  warehouseOrderReducer,
  warehouseProducts,
  productsTemplate,
  companyPersonReducer,
  inventoryReducer,
  notificationReducer,
  ganttReducer,
  impostReducer,
  integrationReducer,
  settingsReducer,
  supplyOfferReducer,
  appReducer,
  addressReducer,
  builderReducer,
  fileViewerReducer,
  localStorageReducer,
  tableWidgetContentModalReducer,
  laboratoryDrawerReducer,
  projectReportModalReducer,
  transferMakingReducer,
  productSpentWidgetDrawerReducer,
  productSpentWidgetDetailDrawerReducer,
  activatingMessagingSystemsReducer,
  templatesModalReducer,
  partyReducer,
  uysot,
  warehouseCreateTransfer
});

export default reducer;

export * from "modules/auth/slice";
export * from "store/reducers/activating-messaging-systems-reducer";
export * from "store/reducers/laboratoryDrawerReducer";
export * from "store/reducers/product-spent-widget-detail-drawer-reducer";
export * from "store/reducers/product-spent-widget-drawer-reducer";
export * from "store/reducers/projectReportModalReducer";
export * from "store/reducers/tableWidgetContentModalReducer";
export * from "store/reducers/templates-modal-reducer";
export * from "store/reducers/transferMakingReducer";
