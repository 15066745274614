import React, { ReactNode } from "react";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";

const Index: React.FC<{
  description?: string;
  icon?: ReactNode;
  style?: React.CSSProperties;
}> = ({ description, style, icon }) => {
  const { t } = useTranslation();

  return icon ? (
    <Empty image={icon} style={style} description={description ?? `${t("products.Ma'lumotlar mavjud emas")}`} />
  ) : (
    <Empty description={description ?? `${t("products.Ma'lumotlar mavjud emas")}`} />
  );
};

export default Index;
