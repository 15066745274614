import React from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "antd";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/actions";

import { Comments } from "modules/comments";

import Left from "./left";

import styles from "./index.module.scss";

const AdmissionHistory: React.FC = () => {
  const dispatch = useDispatch();
  const { setAdmissionHistoryModal } = partyReducerActions;
  const { visible, orderId } = useAppSelector(({ partyReducer }) => partyReducer.admissionHistory);

  const onCancel = () => {
    dispatch(
      setAdmissionHistoryModal({
        visible: false,
        orderId
      })
    );
  };

  return (
    <Modal
      centered
      width={1400}
      open={visible}
      destroyOnClose
      title="Qabul tarixi"
      cancelText="Yopish"
      onCancel={onCancel}
      rootClassName={styles.history}
      footer={
        <div className="flex items-center justify-end">
          <Button onClick={onCancel}>Yopish</Button>
        </div>
      }
    >
      <Left />
      <Comments type="orders" object_id={orderId || 0} />
    </Modal>
  );
};

export default AdmissionHistory;
