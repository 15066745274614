import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { useQueryParams } from "hooks/useQueryParams";

import { queryParamsKeys } from "../../app/utils/constants/queryParamsKeys";
import $api from "../../app/utils/helpers/axiosInstance";
import { PaginationType } from "../../app/utils/models/PaginationType";
import { endpoints } from "../utils/constants/endpoints";
import { projectsQueryParamsKeys } from "../utils/constants/projectsQueryParamsKeys";
import { queryKeys } from "../utils/constants/queryKeys";
import { CompletedWorkModel } from "../utils/models/completedWorkModel";
import { DetailedProjectFolderModel } from "../utils/models/detailedProjectFolderModel";
import { DetailedProjectModel } from "../utils/models/detailedProjectModel";
import {
  DynamicEstimateProductModel,
  DynamicEstimateSectionModel,
  DynamicEstimateTaskModel
} from "../utils/models/dynamicEstimateModel";
import { TaskProgressModel } from "../utils/models/estimateModel";
import { GeneralSearchSectionModel } from "../utils/models/generalSearchSectionModel";
import { GroupCustomFieldModel } from "../utils/models/groupCustomFieldModel";
import { ProjectModel, ProjectReportCashFlowModel, ProjectReportProductSpendModel } from "../utils/models/projectModel";
import { ProjectsViewModel } from "../utils/models/projectsViewModel";
import { ResourcesWorkModel } from "../utils/models/resourcesWorkModel";
import { SectionModel } from "../utils/models/sectionModel";
import { TaskModel } from "../utils/models/taskModel";
import { TaskProductModel } from "../utils/models/taskProductModel";
import { TaskSelectModel } from "../utils/models/taskSelectModel";
import { WorkModel } from "../utils/models/workModel";
import { ProgressDataModel, WorkProgressModel, WorkProgressModelById } from "../utils/models/WorkProgressModel";
import { WorkProgressResourceModel } from "../utils/models/workProgressResourceModel";
import { WorkStatisticsModel } from "../utils/models/workStatisticsModel";

export function useGetProjects(is_archived: boolean, viewType: string | undefined, isNotInFolders: boolean) {
  const url = `${endpoints.PROJECT_VIEW}?is_archived=${is_archived}${
    viewType === "list" ? "&prediction_amount=true" : ""
  }`;

  return useQuery<ProjectsViewModel>(
    [queryKeys.PROJECTS, is_archived, viewType, isNotInFolders],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: isNotInFolders || !!viewType
    }
  );
}

export function useGetDetailedProjectFolder(
  id: string | undefined,
  is_archived: boolean,
  viewType: string | undefined
) {
  return useQuery<DetailedProjectFolderModel>(
    [queryKeys.DETAILED_PROJECT_FOLDER, id, is_archived, viewType],
    async () => {
      const res = await $api.get(
        `${endpoints.PROJECT_FOLDER_VIEW}?${queryParamsKeys.ID}=${id}&is_archived=${is_archived}${
          viewType === "list" ? "&prediction_amount=true" : ""
        }`
      );

      return res.data.data;
    }
  );
}

export function useGetDetailedProject(id: number | undefined) {
  return useQuery<DetailedProjectModel>(
    [queryKeys.DETAILED_PROJECT, id],
    async () => {
      const res = await $api.get(`${endpoints.PROJECT_VIEW}?${queryParamsKeys.ID}=${id}`);

      return res.data.data;
    },
    {
      enabled: Boolean(id)
    }
  );
}

export function useGetSections(projectId: string | undefined) {
  const url = `${endpoints.SECTION_VIEW}?${projectsQueryParamsKeys.PROJECT_ID}=${projectId}`;

  return useQuery<SectionModel[]>(
    [queryKeys.SECTIONS, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: Boolean(projectId)
    }
  );
}

export function useGetGroupCustomField(params: { projectId?: string; customFieldId?: string; type?: string }) {
  let url = `${endpoints.GROUP_CUSTOM_FIELD}?`;
  const { queries, generateSearchParam } = useQueryParams();
  const { general_search } = queries();

  const searchParams = generateSearchParam({
    project_id: params?.projectId,
    custom_field_id: params?.customFieldId,
    type: params?.type,
    general_search
  });

  url += searchParams;

  return useQuery<GroupCustomFieldModel[]>(
    [queryKeys.GROUP_CUSTOM_FIELD, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: Boolean(params.projectId) && !!params.customFieldId
    }
  );
}

export function useGetTasks(enabled?: boolean, section_id?: number) {
  const { queries } = useQueryParams();
  const groupCustomFiledId = queries()?.[queryParamsKeys.ESTIMATE_GROUP];
  let url = `${endpoints.TASK_VIEW}?section_id=${section_id}`;

  if (groupCustomFiledId) {
    url += `&custom_field_id=${groupCustomFiledId}`;
  }

  return useQuery<TaskModel[]>(
    [queryKeys.WORK_PLAN, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      enabled: enabled && !!section_id
    }
  );
}

export function useGetWorks(projectId: number) {
  const { reqQueryParam, searchParams } = useQueryParams();

  let url = `${endpoints.WORK_VIEW}?project_id=${projectId}`;

  if (searchParams && searchParams?.length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR,
      queryParamsKeys.SECTION_IDS_ARR,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.MIN_DATE,
      queryParamsKeys.MAX_DATE
    )}`;
  }

  return useQuery<PaginationType<WorkModel[]>>(
    [queryKeys.WORKS, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnMount: "always",
      enabled: !!projectId
    }
  );
}

export function useGetCompletedWorks(projectId: number) {
  const { reqQueryParam, searchParams } = useQueryParams();

  let url = `${endpoints.TASK_PROGRESS}?project_id=${projectId}`;

  if (searchParams && searchParams?.length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.CREATORS_IDS_ARR,
      queryParamsKeys.MIN_DATE,
      queryParamsKeys.MAX_DATE
    )}`;
  }

  return useQuery<PaginationType<CompletedWorkModel[]>>(
    [queryKeys.COMPLETED_WORKS, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnMount: "always",
      enabled: !!projectId
    }
  );
}

export function useGetResourcesWork(projectId: number) {
  const { reqQueryParam, searchParams } = useQueryParams();

  let url = `${endpoints.TASK_PROGRESS_DETAIL}?project_id=${projectId}`;

  if (searchParams && searchParams?.length > 0) {
    url += `&${reqQueryParam(queryParamsKeys.PAGE, queryParamsKeys.SEARCH, queryParamsKeys.MIN_DATE, queryParamsKeys.MAX_DATE)}`;
  }

  return useQuery<PaginationType<ResourcesWorkModel[]>>(
    [queryKeys.RESOURCES_WORK, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnMount: "always",
      enabled: !!projectId
    }
  );
}

export function useGetWork(enabled: boolean, id?: number) {
  return useQuery<{ task: WorkModel; task_progresses: CompletedWorkModel[] }>(
    [queryKeys.WORKS, id],
    async () => {
      const res = await $api.get(`work/task-view?id=${id}`);

      return res.data.data;
    },
    {
      // staleTime: Infinity,
      refetchOnMount: "always",
      enabled: enabled && !!id
    }
  );
}

// export function useGetWorkSelect() {
//   return useQuery<WorkModel[]>([queryKeys.WORK_SELECT], async () => {
//     const res = await $api.get(endpoints.WORK_SELECT);
//
//     return res.data.data;
//   });
// }

export function useGetTaskProducts(task_id: number, active: boolean, products: TaskProductModel[]) {
  return useQuery<TaskProductModel[]>(
    [queryKeys.TASK_PRODUCTS, task_id],
    async () => {
      const res = await $api.get(`${endpoints.TASK_PRODUCTS}?task_id=${task_id}`);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      enabled: active && (products?.length === 0 || !products)
    }
  );
}

export function useGetWorkStatistics(project_id?: string) {
  return useQuery<WorkStatisticsModel>(
    [queryKeys.WORKS_STATISTICS, project_id],
    async () => {
      const res = await $api.get(`${endpoints.WORK_STATISTICS}?project_id=${project_id}`);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetWorkProgress(
  tab: string,
  page: number,
  enabled?: boolean,
  task_id?: number,
  progressId?: number
) {
  let url = `company-widget/project-by-resource/view?page=${page}&size=10&task_id=${task_id}`;

  if (tab && tab !== "all") {
    url += `&resource_type=${tab}`;
  }
  return useQuery<PaginationType<WorkProgressModel[]>>(
    [queryKeys.WORK_PROGRESS, url, tab, task_id],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: enabled && !!task_id && !progressId
    }
  );
}
export function useGetWorkProgressById(enabled?: boolean, task_progress_id?: number) {
  let url = `work/task-progress-view?id=${task_progress_id}`;

  return useQuery<ProgressDataModel>(
    [queryKeys.WORK_PROGRESS_BY_ID, url, task_progress_id],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: enabled && !!task_progress_id
    }
  );
}

export function useGetWorkProgressResource(
  enabled?: boolean,
  resource_id?: number,
  progress_id?: number,
  taskId?: number
) {
  let url = `${endpoints.WORK_PROGRESS_RESOURCE}?resource_id=${resource_id}`;

  if (taskId && !progress_id) {
    url += `&task_id=${taskId}`;
  }

  if (progress_id && taskId) {
    url += `&progress_id=${progress_id}&task_id=${taskId}`;
  }
  if (progress_id && !taskId) {
    url += `&progress_id=${progress_id}`;
  }

  return useQuery<WorkProgressResourceModel[]>(
    [queryKeys.WORK_PROGRESS_RESOURCE, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: enabled && !!resource_id
    }
  );
}

export function useGetWorkPlan(enabled?: boolean, task_id?: number, section_id?: number) {
  return useQuery<WorkProgressModel[]>(
    [queryKeys.WORK_PLAN, task_id],
    async () => {
      const res = await $api.get(`${endpoints.TASK_VIEW}?id=${task_id}&section_id=${section_id}`);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      enabled: enabled && !!task_id
    }
  );
}

export function useGetDynamicEstimateSection(enabled?: boolean, project_id?: string) {
  const { reqQueryParam, queries } = useQueryParams();
  const { general_search } = queries();

  let url = `${endpoints.DYNAMIC_ESTIMATE_SECTION}?project_id=${project_id}`;

  if (general_search) {
    url += `&${reqQueryParam(queryParamsKeys.GENERAL_SEARCH)}`;
  }

  return useQuery<DynamicEstimateSectionModel[]>(
    [queryKeys.DYNAMIC_ESTIMATE_SECTION, project_id, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      enabled: enabled && !!project_id
    }
  );
}

export function useGetDynamicEstimateTask(enabled?: boolean, section_id?: number) {
  const { reqQueryParam, queries } = useQueryParams();

  let url = `${endpoints.DYNAMIC_ESTIMATE_TASK}?section_id=${section_id}`;

  if (queries()[queryParamsKeys.GENERAL_SEARCH]) {
    url += `&${reqQueryParam(queryParamsKeys.GENERAL_SEARCH)}`;
  }

  return useQuery<DynamicEstimateTaskModel[]>(
    [queryKeys.DYNAMIC_ESTIMATE_TASK, section_id, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      enabled: enabled && !!section_id
    }
  );
}

export function useGetDynamicEstimateProduct(enabled?: boolean, task_id?: number) {
  const { reqQueryParam, queries } = useQueryParams();

  let url = `${endpoints.DYNAMIC_ESTIMATE_PRODUCT}?task_id=${task_id}`;

  if (queries()[queryParamsKeys.GENERAL_SEARCH]) {
    url += `&${reqQueryParam(queryParamsKeys.GENERAL_SEARCH)}`;
  }
  return useQuery<DynamicEstimateProductModel[]>(
    [queryKeys.DYNAMIC_ESTIMATE_PRODUCT, task_id, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      enabled: enabled && !!task_id
    }
  );
}

export function useGetDynamicEstimateTaskProgress(enabled?: boolean, product_id?: number) {
  return useQuery<TaskProgressModel[]>(
    [queryKeys.DYNAMIC_ESTIMATE_TASK_PROGRESS, product_id],
    async () => {
      const res = await $api.get(`${endpoints.DYNAMIC_ESTIMATE_TASK_PROGRESS}?id=${product_id}`);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      enabled: enabled && !!product_id
    }
  );
}

// export function useGetSectionStatistics(project_id?: string) {
//   return useQuery<SectionsStatisticsModel[]>(
//     [queryKeys.SECTIONS_STATISTICS, project_id],
//     async () => {
//       const res = await $api.get(`${endpoints.SECTIONS_STATISTICS}?project_id=${project_id}`);
//
//       return res.data.data;
//     },
//     {
//       staleTime: Infinity,
//       enabled: !!project_id
//     }
//   );
// }

export function useGetGeneralSearchSections(project_id?: number) {
  const { reqQueryParam, queries } = useQueryParams();
  const { general_search, estimate_group } = queries();

  let url = `${endpoints.GENERAL_SEARCH_SECTIONS}?project_id=${project_id}`;

  if (general_search) {
    url += `&${reqQueryParam(queryParamsKeys.GENERAL_SEARCH)}`;
  }

  return useQuery<GeneralSearchSectionModel[]>(
    [queryKeys.GENERAL_SEARCH_SECTIONS, project_id, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      enabled: !!project_id && !!general_search && !estimate_group
    }
  );
}

export function useGetDynamicCustomField({
  projectId,
  customFieldId,
  type
}: {
  projectId?: string;
  customFieldId?: string;
  type?: string;
}) {
  const { queries, generateSearchParam } = useQueryParams();
  const { general_search } = queries();
  const searchParams = generateSearchParam({
    project_id: projectId,
    custom_field_id: customFieldId,
    type,
    general_search
  });

  const url = `${endpoints.DYNAMIC_CUSTOM_FIELD}?${searchParams}`;

  return useQuery<GroupCustomFieldModel[]>(
    [queryKeys.DYNAMIC_GROUP_CUSTOM_FIELD, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!projectId && !!customFieldId
    }
  );
}

export function useTaskSelect({ search }: { search?: string }) {
  return useInfiniteQuery<PaginationType<TaskSelectModel[]>>({
    queryKey: [queryKeys.TASK_SELECT, search],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await $api.get(`${endpoints.TASK_SELECT}?${search ? `search=${search}&` : ""}page=${pageParam}`);

      return res.data?.data;
    },
    getNextPageParam: (lastPage, page) => {
      if (page.length < lastPage?.data.length) {
        return page.length + 1;
      }

      return undefined;
    },
    enabled: !!search
  });
}

export function useGetProjectReportAmount(project_id: number, enabled: boolean = false) {
  return useQuery<ProjectModel>(
    [queryKeys.PROJECT_REPORT_AMOUNT, project_id],
    async () => {
      const res = await $api.get(`${endpoints.PROJECT_REPORT_AMOUNT}?project_id=${project_id}`);

      return res.data.data;
    },
    {
      enabled
    }
  );
}

export function useGetProjectReportCashFlow(project_id: number, enabled: boolean = false) {
  const { reqQueryParam, necessaryQueries } = useQueryParams();
  const searchParams = necessaryQueries(queryParamsKeys.MIN_DATE, queryParamsKeys.MAX_DATE);
  let url = `${endpoints.PROJECT_REPORT_CASH_FLOW}?project_id=${project_id}`;

  if (Object.keys(searchParams).length > 0) {
    url += `&${reqQueryParam(queryParamsKeys.CURRENCY_ID, queryParamsKeys.MIN_DATE, queryParamsKeys.MAX_DATE)}`;
  }
  return useQuery<ProjectReportCashFlowModel>(
    [queryKeys.PROJECT_REPORT_CASH_FLOW, project_id, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled
    }
  );
}

export function useGetProjectReportProductSpend(
  enabled: boolean = false,
  project_id?: number,
  min_date?: string,
  max_date?: string,
  task_id?: number,
  task_progress_id?: number
) {
  let url = `${endpoints.PROJECT_REPORT_PRODUCT_SPEND}`;

  if (project_id) {
    url += `?project_id=${project_id}`;
  }

  if (min_date && min_date?.length > 0 && max_date && max_date?.length > 0) {
    url += `&${queryParamsKeys.MIN_DATE}=${min_date}&${queryParamsKeys.MAX_DATE}=${max_date}`;
  }
  if (task_id && !task_progress_id) {
    url += `?task_id=${task_id}`;
  }
  if ((task_progress_id && task_id) || (task_progress_id && !task_id)) {
    url += `?task_progress_id=${task_progress_id}`;
  }
  return useQuery<ProjectReportProductSpendModel>(
    [queryKeys.PROJECT_REPORT_PRODUCT_SPEND, project_id, min_date, max_date, task_id, task_progress_id],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled
    }
  );
}

export function useGetSectionsSelect({ enabled, search }: { enabled: boolean; search?: string }) {
  return useInfiniteQuery({
    queryKey: [queryKeys.PROJECT_SECTIONS_SELECT, search],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await $api.get(
        `${endpoints.SECTIONS_SELECT}&page=${pageParam}${search ? `&search=${search}` : ""}`
      );

      return data?.data;
    },
    getNextPageParam: (lastPage, page) => {
      if (page.length < lastPage?.data.length) {
        return page.length + 1;
      }

      return undefined;
    },
    staleTime: Infinity,
    enabled: enabled && !!search
  });
}
