import React from "react";
import { Checkbox } from "antd";
import CustomColumnFileIcon from "features/app/assets/icons/CustomColumnFileIcon";
import { useDownloadFile } from "features/app/service/mutation";
import { CustomFieldTypesEnum } from "features/app/utils/enums/customFieldTypesEnum";
import { CustomFieldValueModel } from "features/app/utils/models/customFIeldValueModel";
import { useTranslation } from "react-i18next";

const OrderViewTopCustomField: React.FC<CustomFieldValueModel> = ({
  file,
  value,
  custom_field,
  custom_field_option
}) => {
  const { type } = custom_field;
  const { i18n } = useTranslation();
  const downloadFile = useDownloadFile();

  if (type === CustomFieldTypesEnum.FILE) {
    const onDownloadFile = (id: number, name: string) => {
      downloadFile.mutateAsync({ id, name });
    };

    return (
      <div
        className="flex cursor-pointer items-center gap-1.5 text-sm font-normal text-gray-700"
        onClick={() => onDownloadFile(file?.id, file?.original_name)}
      >
        <CustomColumnFileIcon />
        {file?.original_name}
      </div>
    );
  }

  if (type === CustomFieldTypesEnum.CHECKBOX) {
    return <Checkbox checked />;
  }

  if (type === CustomFieldTypesEnum.SELECT || type === CustomFieldTypesEnum.LABEL) {
    return <div className="text-sm font-normal text-gray-700">{custom_field_option?.name?.[i18n.language]}</div>;
  }

  return <div className="text-sm font-normal text-gray-700">{value}</div>;
};

export default OrderViewTopCustomField;
