import React from "react";
import { UseFormSetValue } from "react-hook-form";
import i18n from "i18next";

import { useAppSelector } from "hooks/redux";

import { getPopupContainer } from "modules/party/create";
import { ProductForm, ProductFormControl } from "modules/party/create-product";
import { useCatalogs } from "modules/party/resource";

import { Select } from "components";

type Props = {
  isUpdate?: boolean;
  control: ProductFormControl;
  setValue: UseFormSetValue<ProductForm>;
};

const Catalog: React.FC<Props> = ({ control, setValue, isUpdate }) => {
  const { visible: enabled } = useAppSelector(({ partyReducer }) => partyReducer.createProduct);
  const { catalogs } = useCatalogs({ enabled });

  const onClear = () => {
    // @ts-ignore
    setValue("category_id", undefined);
  };

  return (
    <Select
      allowClear
      withoutMessage
      label="Katalog"
      control={control}
      name="category_id"
      onClear={onClear}
      placeholder="Mahsulot katalogini tanlang"
      getPopupContainer={getPopupContainer(isUpdate ? "update-product" : "create-product")}
      options={catalogs?.map(catalog => ({
        key: catalog?.id,
        value: catalog?.id,
        label: catalog?.name?.[i18n.language],
        props: {
          name: catalog?.name?.[i18n.language]
        }
      }))}
    />
  );
};

export default Catalog;
