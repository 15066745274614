import { Button } from "antd";
import dayjs, { Dayjs } from "dayjs";
import queryString from "query-string";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "../../../../../hooks/useQueryParams";
import FilterDrawer from "../../../../app/components/filter-drawer/FilterDrawer";
import FilterDateRange from "../../../../app/components/filter-drawer/filter-date-range/FilterDateRange";
import FilterPricesRange from "../../../../app/components/filter-drawer/filter-prices-range/FilterPricesRange";
import FilterSelect from "../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FiltersCount from "../../../../app/components/filter-drawer/filters-count/FiltersCount";
import {
  useGetCompanyPersons,
  useGetCurrenciesSelect,
  useGetTableConfigs,
  useGetUsersSelect,
  useProjectSelect
} from "../../../../app/service/queries";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import { ImpostStatusEnums } from "../../../../app/utils/enums/impostStatusEnums";
import BankNoteIcon from "../../../../payment/assets/icons/BankNoteIcon";
import BuildingIcon from "../../../../payment/assets/icons/BuildingIcon";
import UsersIcon from "../../../../payment/assets/icons/UsersIcon";
import { useGetProjectSelect } from "../../../../payment/service/queries";
import UserIcon from "../../../../projects/assets/icons/UserIcon";
import ZapIcon from "../../../../projects/assets/icons/ZapIcon";
import FilterLinesIcon from "../../../../warehouse/assets/icons/FilterLinesIcon";

import styles from "./filterDrawer.module.scss";
import { UseMutationResult } from "@tanstack/react-query";
import { IExportExcelParam } from "features/app/utils/models/IExportExcelParam";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { inventoryWorksTableDefaultData } from "features/impost/utils/constants/impostTableDefaultData";
import { generateExcelPayload } from "features/app/utils/helpers/generateExcelPayload";
import { parseLocaledString } from "features/app/utils/helpers/parseLocaledString";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";

const queryStringArr = [
  queryParamsKeys.CURRENCY_ID,
  queryParamsKeys.MIN_TOTAL_AMOUNT,
  queryParamsKeys.MAX_TOTAL_AMOUNT,
  queryParamsKeys.MIN_DATE,
  queryParamsKeys.MAX_DATE,
  queryParamsKeys.CONFIRMATION_PAYMENTS,
  queryParamsKeys.PROJECT_IDS,
  queryParamsKeys.USER_IDS,
  queryParamsKeys.COMPANY_PERSON_IDS
];

type Props = {
  exportExcelMutate?: UseMutationResult<any, unknown, any, unknown>;
};

const FilterDrawerWorks: React.FC<Props> = ({ exportExcelMutate }) => {
  const { t } = useTranslation();
  const { queries, necessaryQueries } = useQueryParams();
  const { min_date, max_date } = queries();
  const [open, setOpen] = useState(false);
  const [companyPersonsSelected, setCompanyPersonsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.COMPANY_PERSON_IDS] as string[] | null
  );
  const [projectsSelected, setProjectsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );
  const [currenciesSelected, setCurrenciesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {})[queryParamsKeys.CURRENCY_ID] as string[] | null
  );
  const [usersSelected, setUsersSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.USER_IDS] as string[] | null
  );
  const [startPriceValue, setStartPriceValue] = useState<string>(
    queryString.parse(location.search)[queryParamsKeys.MIN_TOTAL_AMOUNT] as string
  );
  const [endPriceValue, setEndPriceValue] = useState<string>(
    queryString.parse(location.search)[queryParamsKeys.MAX_TOTAL_AMOUNT] as string
  );
  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([
    queries()[queryParamsKeys.MIN_DATE] ? dayjs(queries()[queryParamsKeys.MIN_DATE], dayjsFormats.DATE) : null,
    queries()[queryParamsKeys.MAX_DATE] ? dayjs(queries()[queryParamsKeys.MAX_DATE], dayjsFormats.DATE) : null
  ]);
  const [statusesSelected, setStatusesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.CONFIRMATION_PAYMENTS] as string[] | null
  );
  const { data: projects } = useProjectSelect({ enabled: open });
  const { data: currenciesSelect } = useGetCurrenciesSelect(open);
  const { data: adminsSelect } = useGetUsersSelect(open);
  const { data: counterpartsSelect } = useGetCompanyPersons();
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.MONITORING_WORKS, inventoryWorksTableDefaultData);

  const admins = useMemo(() => {
    const result = adminsSelect?.map(item => {
      return { name: item?.full_name, ...item };
    });

    return result;
  }, [adminsSelect]);

  const counterparts = useMemo(() => {
    const result = counterpartsSelect?.map(item => {
      return { ...item };
    });

    return result;
  }, [counterpartsSelect]);

  const currencies = useMemo(() => {
    const result = currenciesSelect?.map(item => {
      return { name: item?.symbol, id: item.id, image: item.icon };
    });

    return result;
  }, [currenciesSelect]);

  const statusesData = useMemo(() => {
    return [
      {
        id: ImpostStatusEnums.PENDING,
        name: `${t("Monitoring.Kutilmoqda")}`
      },
      {
        id: ImpostStatusEnums.RECIEVED,
        name: `${t("Monitoring.Tasdiqlangan")}`
      },
      {
        id: ImpostStatusEnums.REJECTED,
        name: `${t("Monitoring.Rad etilgan")}`
      }
    ];
  }, []);

  const onOpenFilter = () => {
    setOpen(true);
  };

  const handleExcelExport = () => {
    const data = filterColumns({
      tableConfig: {
        ...tableConfig,
        column: tableConfig?.column?.filter(el => el?.id !== "works-victims") as never,
        size: tableConfig?.size as number
      }
    })
      ?.map(value => ({
        key: String(value?.id),
        name: value?.title
      }))
      ?.filter(item => typeof item?.name !== "object");

    const otherParamsData = necessaryQueries(queryParamsKeys.SEARCH);
    const reqBody = {
      ...otherParamsData,
      columns: data,
      company_person_ids: companyPersonsSelected,
      user_ids: usersSelected,
      project_ids: projectsSelected,
      confirmation_payments: statusesSelected,
      currency_id: currenciesSelected!?.length > 0 ? currenciesSelected![0] : null,
      min_total_amount: Number(parseLocaledString(startPriceValue)),
      max_total_amount: Number(parseLocaledString(endPriceValue)),
      min_date,
      max_date
    };

    if (data) {
      exportExcelMutate?.mutate(generateExcelPayload(reqBody));
    }
  };

  const footerContent = (
    <Button onClick={handleExcelExport} className={styles.downloadBtn} loading={exportExcelMutate?.isLoading}>
      <DownloadIcon /> {t("Monitoring.Yuklab olish")}
    </Button>
  );

  return (
    <FilterDrawer
      open={open}
      setOpen={setOpen}
      filterButton={
        <Button onClick={onOpenFilter}>
          <FilterLinesIcon /> {t("Monitoring.Filter")}
          {<FiltersCount queryStringArr={queryStringArr} />}
        </Button>
      }
      footerContent={footerContent}
      height="max-content"
      queryStringArr={queryStringArr}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.COMPANY_PERSON_IDS,
          selectedOptions: companyPersonsSelected
        },
        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectsSelected
        },
        {
          queryKey: queryParamsKeys.CURRENCY_ID,
          selectedOptions: currenciesSelected
        },
        {
          queryKey: queryParamsKeys.USER_IDS,
          selectedOptions: usersSelected
        },
        {
          queryKey: queryParamsKeys.CONFIRMATION_PAYMENTS,
          selectedOptions: statusesSelected
        }
      ]}
      selectedValueArr={[
        {
          queryKey: queryParamsKeys.MIN_TOTAL_AMOUNT,
          selectedValue: startPriceValue
        },
        {
          queryKey: queryParamsKeys.MAX_TOTAL_AMOUNT,
          selectedValue: endPriceValue
        }
      ]}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_DATE,
          selectedValue: dateValue && dateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_DATE,
          selectedValue: dateValue && dateValue[1]
        }
      ]}
      setDateValue={[setDateValue]}
      setSelectedValueArr={[setStartPriceValue, setEndPriceValue]}
      setSelectedOptionsArr={
        [
          setCompanyPersonsSelected,
          setProjectsSelected,
          setCurrenciesSelected,
          setUsersSelected,
          setStatusesSelected
        ] as never
      }
    >
      <div className={styles.content}>
        <FilterSelect
          selectedOptions={companyPersonsSelected}
          setSelectedOptions={setCompanyPersonsSelected}
          data={counterparts}
          multiple="multiple"
          icon={<UsersIcon />}
          placeholder={t("MainLayoutLinks.Kontragent")}
          showSearch
        />
        <FilterTreeSelect
          selectedOptions={projectsSelected}
          setSelectedOptions={setProjectsSelected}
          data={projects}
          multiple={true}
          icon={<BuildingIcon />}
          placeholder={t("Monitoring.Loyihalar")}
          showSearch
        />
        <FilterSelect
          selectedOptions={statusesSelected}
          setSelectedOptions={setStatusesSelected}
          data={statusesData}
          multiple="multiple"
          icon={<ZapIcon />}
          placeholder={t("Monitoring.Tasdiq holati")}
          showSearch
        />
        <FilterSelect
          selectedOptions={currenciesSelected}
          setSelectedOptions={setCurrenciesSelected}
          data={currencies}
          icon={<BankNoteIcon />}
          placeholder={t("Moliya.Valyuta")}
          isCurrency
          showSearch
        />
        <FilterSelect
          selectedOptions={usersSelected}
          setSelectedOptions={setUsersSelected}
          data={admins}
          multiple="multiple"
          icon={<UserIcon />}
          placeholder={t("Xodimlar.Xodimlar")}
          isPersons
          className={styles.admins__select}
          showSearch
        />
        <FilterPricesRange
          startPriceValue={startPriceValue}
          endPriceValue={endPriceValue}
          setStartPriceValue={setStartPriceValue}
          setEndPriceValue={setEndPriceValue}
          placeholder={t("Monitoring.Umumiy summa")}
        />
        <FilterDateRange value={dateValue} setValue={setDateValue} />
      </div>
    </FilterDrawer>
  );
};

export default FilterDrawerWorks;
