import React, { useState } from "react";

import { useVatBalanceViewData, useWidget } from "modules/dashboard";

import VatBalanceTable from "./vat-balance-table";

const VatBalance: React.FC = () => {
  const { id, visible } = useWidget();
  const [page, setPage] = useState<number>(1);
  const { vatBalanceViewData, isLoading } = useVatBalanceViewData({ id, enabled: visible, page });

  return (
    <div className="p-5">
      <VatBalanceTable data={vatBalanceViewData} setPage={setPage} isLoading={isLoading} />
    </div>
  );
};

export default VatBalance;
