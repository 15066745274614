import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import Pagination from "features/app/components/pagination/Pagination";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import TableSettings from "features/app/components/table-settings/TableSettings";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { RU } from "features/app/utils/constants/languages";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import { cx } from "features/app/utils/helpers/cx";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { parseParamsId } from "features/app/utils/helpers/parseParamsId";
import PaymentSearch from "features/payment/components/top/search/PaymentSearch";
import ActionDots from "features/warehouse/components/warehouse/action-dots/ActionDots";
import FilterDrawerWarehouse from "features/warehouse/components/warehouse/filter-drawer/FilterDrawerWarehouse";
import ResourceInfoDrawer from "features/warehouse/components/warehouse/resource-info-drawer/ResourceInfoDrawer";
import { useWarehouseProductsExportExcel } from "features/warehouse/service/mutation";
import { useGetWarehouseProducts } from "features/warehouse/service/query";
import { routeSubmodules } from "features/warehouse/utils/constants/routeSubmodules";
import { WarehouseProductModel } from "features/warehouse/utils/models/WarehouseProductModel";
import { warehouseProductsTableDefaultDataFunc } from "features/warehouse/utils/models/warehouseProductsTableDefaultData";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { warehouseProductsAction } from "store/reducers/warehouseProductsReducer";

import { Table } from "components";

import useDebounce from "../../../../../components/use-debounce/use-debounce";

import cls from "../warehouse.module.scss";

const WarehouseTable: React.FC = () => {
  const { queries } = useQueryParams();
  const { tab } = queries();
  const { i18n, t } = useTranslation();
  const params = useParams();
  const { id: sub_category_id } = parseParamsId(params?.second_id);
  const { id: category_id } = parseParamsId(params?.id);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const { setResource } = warehouseProductsAction;
  const tableDefaultData = warehouseProductsTableDefaultDataFunc(i18n.language);
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const productActions = actions("Omborxona");
  const warehouseExportProductsExcel = useWarehouseProductsExportExcel();
  const changeTableConfig = useChangeTableConfig(tableConfigKeys.WAREHOUSE_PRODUCTS, true);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.WAREHOUSE]);
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.WAREHOUSE_PRODUCTS, tableDefaultData);
  const {
    data: products,
    isLoading: isLoadingProducts,
    isFetching: isFetchingProducts
  } = useGetWarehouseProducts(category_id, sub_category_id);
  const [renderColumns, setColumns] = useState<ColumnsType<WarehouseProductModel>>([]);
  const baseCurrency = getBaseCurrency();

  const columns: ColumnsType = [
    {
      title: `${t("warehouse.Resurs nomi va turi")}`,
      render: (record: WarehouseProductModel) => {
        const columnWidth = renderColumns?.find(column => column?.className === "resource_name_type")?.width;

        return (
          <div className="resource">
            <div
              style={{
                borderColor: record?.product?.resource?.color,
                color: record?.product?.resource?.color
              }}
            >
              {record?.product?.resource?.symbol[i18n.language]}
            </div>
            <PopoverShortName
              title={record?.product?.name[i18n.language]}
              length={columnWidth ? ((columnWidth as number) + 150) / 10 : 150}
            />
          </div>
        );
      },
      width: 550,
      className: "resource_name_type",
      fixed: "left",
      key: `product.name.${i18n.language}`
    },
    {
      title: `${t("warehouse.Soni")}`,
      dataIndex: "total_quantity",
      render: (totalQuantity: number) => totalQuantity.toLocaleString(RU),
      width: 220,
      className: "total_quantity",
      key: "total_quantity"
    },
    {
      title: `${t("warehouse.Birligi")}`,
      render: (record: WarehouseProductModel) => record.unit?.symbol[i18n.language],
      width: 220,
      className: "unit",
      key: `unit.name.${i18n.language}`
    },
    {
      title: t("warehouse.Summa"),
      render: (_value: any, record: WarehouseProductModel) => (
        <span>
          {record?.total_amount?.toLocaleString(RU)} {baseCurrency?.symbol}
        </span>
      ),
      width: 220,
      className: "total_amount",
      key: "total_amount"
    }
  ];

  const onRowClick = (record: WarehouseProductModel) => {
    dispatch(
      setResource({
        visible: true,
        product: record
      })
    );
  };

  const saveColumns = () => {
    const columnsToSave = renderColumns.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutate({
      key: tableConfigKeys.WAREHOUSE_PRODUCTS,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<WarehouseProductModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useEffect(() => {
    if (tableConfig && customFields) {
      setColumns([
        ...filterColumns({ columns, tableConfig, customFields }),
        {
          title: (
            <TableSettings
              defaultData={tableDefaultData}
              configKey={tableConfigKeys.WAREHOUSE_PRODUCTS}
              locations={[CustomFieldLocationEnum.WAREHOUSE]}
              isNeedSize
            />
          ),
          render: (record: WarehouseProductModel) => <ActionDots record={record} />,
          width: 70,
          className: "action_dots",
          align: "center",
          fixed: "right"
        }
      ]);
    }
  }, [tableConfig, customFields]);

  return (
    <div className={cls.warehouse}>
      <div className={cls.top}>
        <Table<WarehouseProductModel>
          columns={renderColumns}
          onChangeColumns={onChangeColumns}
          title={() => (
            <div className={cx(cls.table_title)}>
              <div className={cls.table_title__content}>
                <div className={cls.right}>
                  <PaymentSearch />
                  <ConditionalRender if={tab === "warehouse" || !tab}>
                    <FilterDrawerWarehouse
                      customFieldEnum={CustomFieldLocationEnum.WAREHOUSE_PRODUCT}
                      tableKey={tableConfigKeys.WAREHOUSE_PRODUCTS}
                      exportExcelMutate={warehouseExportProductsExcel}
                    />
                  </ConditionalRender>
                </div>
              </div>
            </div>
          )}
          dataSource={products?.data}
          onRow={product => ({ onClick: () => onRowClick(product) })}
          pagination={false}
          locale={{ emptyText: <TableEmpty /> }}
          footer={() => (
            <div className={cls.bottom}>
              <Pagination paginationProps={{ total: products?.total }} />
            </div>
          )}
          scroll={{ y: "calc(100vh - 315px)" }}
          rowClassName={cx(productActions.view ? cls.on_row : "pointer-events-none cursor-auto")}
          loading={{
            spinning: isLoadingProducts || isFetchingProducts,
            indicator: LoadingIndicator
          }}
        />
      </div>
      <ResourceInfoDrawer />
    </div>
  );
};

export default WarehouseTable;
