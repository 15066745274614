import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LocalStorage } from "features/app/service/LocalStorage";
import { parseToCrumbName } from "features/app/utils/helpers/parseToCrumbName";
import PartyModal from "features/supply/components/parts/party-modal/PartyModal";
import { WarehouseCategoryViewModel } from "features/warehouse/utils/models/warehouse-category-view-model";
import { useTranslation } from "react-i18next";
import { rootPaths } from "routes/root/rootPaths";

import { useAppSelector } from "hooks/redux";

import { warehouseProductsAction } from "store/reducers/warehouseProductsReducer";

import IncomeExpenseHistoryDrawer from "./income-expense-history-drawer/income-expense-history-drawer";
import ResourceInfoDrawer from "./resource-info-drawer/ResourceInfoDrawer";
import WarehouseTable from "./table/warehouse-table";
import Catalogs from "./catalogs";

const Warehouse: React.FC = () => {
  const { i18n } = useTranslation();
  const catalogActive = LocalStorage.get("is_catalogs_active");
  const statsActive = LocalStorage.get("is_stats_active");
  const { setCatalogsActive, setStatsActive } = warehouseProductsAction;
  const dispatch = useDispatch();
  const {
    // isStatsActive,
    isCatalogsActive
  } = useAppSelector(state => state.warehouseProducts.actions);

  useEffect(() => {
    dispatch(setCatalogsActive(Boolean(catalogActive)));
    dispatch(setStatsActive(Boolean(statsActive)));
  }, [catalogActive, statsActive]);

  return (
    <div className="flex flex-col gap-5">
      {isCatalogsActive && (
        <Catalogs
          storageKey="collapseWarehouseActive"
          key="collapseWarehouseActive"
          folderPath={(record: WarehouseCategoryViewModel) =>
            `${rootPaths.WAREHOUSE}/${parseToCrumbName(record.name[i18n.language as keyof typeof record.name])}-${record.id}`
          }
        />
      )}
      <ResourceInfoDrawer />
      <IncomeExpenseHistoryDrawer />
      <WarehouseTable />
      <PartyModal />
    </div>
  );
};

export default Warehouse;
