import React from "react";

import { useAppSelector } from "hooks/redux";

import { BuildingIcon, CoinsStackedIcon, UysotBuilding } from "modules/settings/uysot";

import { ConditionalRender } from "components";

import HouseCheckbox from "./checkbox";
import HouseStatus from "./status";

type Props = {
  buildings: UysotBuilding["buildings"];
};

const BuildingContentChildren: React.FC<Props> = ({ buildings }) => {
  const { buildings: stateBuildings } = useAppSelector(({ uysot }) => uysot);

  return buildings?.map(({ id, cash, project, name, status }) => {
    const findBuilding = stateBuildings.find(({ building_id }) => building_id === id);

    return (
      <div
        key={id}
        className="box-border flex items-center justify-between border border-l-0 border-r-0 border-t-0 border-solid border-gray-200 px-5 py-3"
      >
        <div className="flex items-center gap-2">
          <HouseCheckbox id={id} name={name} cash={cash} project={project} status={status} />
          <span className="text-base font-semibold text-gray-700">{name}</span>
        </div>
        <div className="flex gap-24">
          <div className="flex items-center gap-2">
            <ConditionalRender
              if={typeof findBuilding?.project_name === "string" ? findBuilding?.project_name : project?.name}
            >
              <div className="box-border flex items-center gap-1.5 rounded-2xl bg-gray-200 px-3 py-0.5 text-sm font-medium text-gray-600">
                <BuildingIcon />
                {findBuilding?.project_name || (project?.name as string)}
              </div>
            </ConditionalRender>
            <ConditionalRender if={typeof findBuilding?.cash_name === "string" ? findBuilding?.cash_name : cash?.name}>
              <div className="box-border flex items-center gap-1.5 rounded-2xl bg-gray-200 px-3 py-0.5 text-sm font-medium text-gray-600">
                <CoinsStackedIcon />
                {findBuilding?.cash_name || (cash?.name as string)}
              </div>
            </ConditionalRender>
          </div>
          <HouseStatus id={id} status={status} name={name} />
        </div>
      </div>
    );
  });
};

export default BuildingContentChildren;
