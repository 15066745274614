import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { useAppSelector } from "../../../hooks/redux";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { queryParamsKeys } from "../../app/utils/constants/queryParamsKeys";
import $api from "../../app/utils/helpers/axiosInstance";
import { parseParamsId } from "../../app/utils/helpers/parseParamsId";
import { PaginationType } from "../../app/utils/models/PaginationType";
import { CurrencyModel } from "../../settings/utils/models/currency/currencyModel";
import { inventoryEndPoints } from "../utils/constants/inventoryEndPoints";
import { inventoryQueryKeys } from "../utils/constants/inventoryQueryKeys";
import { tabKeys } from "../utils/constants/tabKeys";
import { CompanyPersonModel } from "../utils/models/companyPersonModel";
import { DefectModel } from "../utils/models/defectModel";
import { InventoryExpenseModel } from "../utils/models/inventoryExpenseModel";
import { InventoryFolderModel } from "../utils/models/inventoryFolderModel";
import { InventoryDetailModel } from "../utils/models/inventoryModel";
import { InventoryPaymentModel } from "../utils/models/inventoryPaymentModel";
import { InventoryWorkModel } from "../utils/models/inventoryWorkModel";
import { ProjectSelectModel } from "../utils/models/projectSelectModel";
import { RequestModel } from "../utils/models/requestModel";

export function useGetFolders() {
  const { reqQueryParam, searchParams } = useQueryParams();
  let url = inventoryEndPoints.FOLDERS_VIEW;

  if (searchParams && searchParams?.length > 0) {
    url += `?${reqQueryParam(queryParamsKeys.SEARCH, queryParamsKeys.COMPANY_PERSON_IDS_ARR, queryParamsKeys.WORK_STATUS)}`;
  }
  return useQuery<InventoryFolderModel[]>(
    [inventoryQueryKeys.FOLDERS_VIEW, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
}

export function useGetFoldersSelect(isCreateInventory?: number) {
  const { id } = useParams();
  const visible = useAppSelector(state => state.inventoryReducer.inventoryModalData.visible);

  return useQuery<InventoryFolderModel[]>(
    [inventoryQueryKeys.FOLDERS_SELECT],
    async () => {
      const res = await $api.get(inventoryEndPoints.FOLDERS_SELECT);

      if (id && isCreateInventory) {
        return [{ id: null, name: "Papkadan chiqarish" }, ...res.data.data];
      }
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: visible
    }
  );
}

export function useGetFolderInventories() {
  const { reqQueryParam, searchParams } = useQueryParams();
  const params = useParams();
  const { id } = parseParamsId(params?.id);

  let url = inventoryEndPoints.FOLDERS_VIEW;

  if (id) {
    url += `?inventory_folder_id=${id}`;
  }

  if (searchParams && searchParams?.length > 0) {
    url += `&${reqQueryParam(queryParamsKeys.PAGE, queryParamsKeys.SEARCH, queryParamsKeys.COMPANY_PERSON_IDS_ARR, queryParamsKeys.WORK_STATUS)}`;
  }

  return useQuery<PaginationType<InventoryDetailModel[]>>(
    [inventoryQueryKeys.INVENTORY_VIEW, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
}

export function useGetInventories() {
  const { queries, reqQueryParam, searchParams } = useQueryParams();

  let url = inventoryEndPoints.INVENTORIES_VIEW;

  if (searchParams && searchParams?.length > 0) {
    url += `?${reqQueryParam(queryParamsKeys.PAGE, queryParamsKeys.SEARCH, queryParamsKeys.COMPANY_PERSON_IDS_ARR, queryParamsKeys.WORK_STATUS, queryParamsKeys.CUSTOM_FIELDS_ARR)}`;
  }

  const enabled = queries()?.[queryParamsKeys.TAB] === tabKeys.INVENTORIES || !queries()?.[queryParamsKeys.TAB];

  return useQuery<PaginationType<InventoryDetailModel[]>>(
    [inventoryQueryKeys.INVENTORY_VIEW, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled
    }
  );
}

export function useGetInventory() {
  const { visible, inventory_id } = useAppSelector(state => state.inventoryReducer).inventoryDetailModalData;

  return useQuery<InventoryDetailModel>(
    [inventoryQueryKeys.INVENTORY_DETAIL_VIEW, inventory_id],
    async () => {
      const res = await $api.get(`${inventoryEndPoints.INVENTORIES_VIEW}?id=${inventory_id}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: visible && !!inventory_id
    }
  );
}

export function useGetInventoryWork(page: number, enabled?: boolean) {
  const { visible, inventory_id } = useAppSelector(state => state.inventoryReducer).inventoryDetailModalData;

  return useQuery<PaginationType<InventoryWorkModel[]>>(
    [inventoryQueryKeys.INVENTORY_WORK, inventory_id, page],
    async () => {
      const res = await $api.get(
        `${inventoryEndPoints.INVENTORY_WORK}?inventory_id=${inventory_id}&page=${page}&size=5`
      );

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: visible && !!inventory_id && !enabled
    }
  );
}

export function useGetCPInventoryWork(page: number, enabled?: boolean) {
  const { visible, inventory_id, company_person_id } = useAppSelector(
    state => state.inventoryReducer
  ).inventoryDetailModalData;

  return useQuery<PaginationType<InventoryWorkModel[]>>(
    [inventoryQueryKeys.CP_INVENTORY_WORK, inventory_id, page],
    async () => {
      const res = await $api.get(
        `${inventoryEndPoints.CP_INVENTORY_WORK}?inventory_id=${inventory_id}&page=${page}&inventory_company_person_id=${company_person_id}&size=5`
      );

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: visible && !!inventory_id && !!enabled
    }
  );
}

export function useGetCPInventoryPayment(page: number, enabled?: boolean) {
  const { visible, inventory_id, company_person_id } = useAppSelector(
    state => state.inventoryReducer
  ).inventoryDetailModalData;

  return useQuery<PaginationType<InventoryPaymentModel[]>>(
    [inventoryQueryKeys.CP_INVENTORY_PAY, inventory_id, page],
    async () => {
      const res = await $api.get(
        `${inventoryEndPoints.CP_INVENTORY_PAY}?task_progress_detail_inventory_id=${inventory_id}&company_person_id=${company_person_id}&page=${page}&size=7`
      );

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: visible && !!inventory_id && !!enabled
    }
  );
}

export function useGetInventoryPayment(page: number, enabled?: boolean) {
  const { visible, inventory_id } = useAppSelector(state => state.inventoryReducer).inventoryDetailModalData;

  return useQuery<PaginationType<InventoryPaymentModel[]>>(
    [inventoryQueryKeys.INVENTORY_PAY, inventory_id, page],
    async () => {
      const res = await $api.get(
        `${inventoryEndPoints.INVENTORY_PAY}?task_progress_detail_inventory_id=${inventory_id}&page=${page}&size=7`
      );

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: visible && !!inventory_id && !enabled
    }
  );
}

export function useGetInventoryExpense(page: number, enabled?: boolean) {
  const { visible, inventory_id } = useAppSelector(state => state.inventoryReducer).inventoryDetailModalData;

  return useQuery<PaginationType<InventoryExpenseModel[]>>(
    [inventoryQueryKeys.INVENTORY_EXPENSE, inventory_id, page],
    async () => {
      const res = await $api.get(
        `${inventoryEndPoints.INVENTORY_EXPENSE}?inventory_id=${inventory_id}&page=${page}&size=7`
      );

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: visible && !!inventory_id && enabled
    }
  );
}

export function useGetCompanyCurrency(visible: boolean) {
  // const visible = useAppSelector(
  //   (state) => state.inventoryReducer.inventoryModalData.visible
  // );
  return useQuery<CurrencyModel[]>(
    [inventoryQueryKeys.CURRENCY_SELECT],
    async () => {
      const res = await $api.get(inventoryEndPoints.CURRENCY_SELECT);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: visible
    }
  );
}

export function useGetCompanyPersons() {
  const visible = useAppSelector(state => state.inventoryReducer.inventoryModalData.visible);

  return useQuery<CompanyPersonModel[]>(
    [inventoryQueryKeys.COMPANY_PERSONS_SELECT],
    async () => {
      const res = await $api.get(inventoryEndPoints.COMPANY_PERSON_SELECT);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: visible
    }
  );
}

export function useGetRequests(show?: boolean) {
  const { queries, reqQueryParam, searchParams } = useQueryParams();

  let url = inventoryEndPoints.REQUESTS_VIEW;

  if (searchParams && searchParams?.length > 0) {
    url += `?${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.INVENTORY_IDS_ARR,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.CREATORS_IDS_ARR
    )}`;
  }

  const enabled = queries()?.[queryParamsKeys.TAB] === tabKeys.REQUESTS || !queries()?.[queryParamsKeys.TAB] || show;

  return useQuery<PaginationType<RequestModel[]>>(
    [inventoryQueryKeys.REQUESTS_VIEW, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled
    }
  );
}

export function useGetRequestsCount() {
  return useQuery<PaginationType<RequestModel[]>>(
    [inventoryQueryKeys.REQUESTS_VIEW],
    async () => {
      const res = await $api.get(inventoryEndPoints.REQUESTS_VIEW);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: "always"
    }
  );
}

export function useGetProjectsSelect() {
  const { defectModalData, attachModalData } = useAppSelector(state => state.inventoryReducer);

  return useQuery<ProjectSelectModel[]>(
    [inventoryQueryKeys.PROJECTS_SELECT],
    async () => {
      const res = await $api.get(inventoryEndPoints.PROJECTS_SELECT);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: defectModalData.visible || attachModalData.visible
    }
  );
}

export function useGetUnits() {
  const { defectModalData, attachModalData } = useAppSelector(state => state.inventoryReducer);

  return useQuery<ProjectSelectModel[]>(
    [inventoryQueryKeys.PROJECTS_SELECT],
    async () => {
      const res = await $api.get(inventoryEndPoints.PROJECTS_SELECT);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: defectModalData.visible || attachModalData.visible
    }
  );
}

export function useGetDefects() {
  const { queries, reqQueryParam, severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.TAB);
  let url = inventoryEndPoints.DEFECTS_VIEW;

  if (searchParams && searchParams?.length > 0) {
    url += `?${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.MIN_CREATED_AT_DEFECT,
      queryParamsKeys.MAX_CREATED_AT_DEFECT,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.CREATORS_IDS_ARR,
      queryParamsKeys.CONFIRMATION_USER_IDS_ARR,
      queryParamsKeys.INVENTORY_IDS_ARR,
      queryParamsKeys.TYPES_ARR
    )}`;
  }

  const enabled = queries()?.[queryParamsKeys.TAB] === tabKeys.INVALIDS || !queries()?.[queryParamsKeys.TAB];

  return useQuery<PaginationType<DefectModel[]>>(
    [inventoryQueryKeys.DEFECT_VIEW, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled
    }
  );
}

export function useGetDefect(id?: number) {
  const { queries } = useQueryParams();

  const enabled = (queries()?.[queryParamsKeys.TAB] === "invalids" || !queries()?.[queryParamsKeys.TAB]) && !!id;

  return useQuery<DefectModel>(
    [inventoryQueryKeys.DEFECT_DETAIL_VIEW, id],
    async () => {
      const res = await $api.get(`${inventoryEndPoints.DEFECTS_VIEW}?inventory_defect_id=${id}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled
    }
  );
}
