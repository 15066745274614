import React from "react";
import { Skeleton } from "antd";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";

import { useIntegrationsView } from "modules/settings/integration";

import Uysot from "./card";

import styles from "./index.module.scss";

const { Avatar, Button, Input } = Skeleton;

const Content: React.FC = () => {
  const { integrations, isLoading } = useIntegrationsView();

  return (
    <ConditionalRender
      if={!isLoading}
      else={
        <div className="flex gap-2.5">
          <div className={styles.loading}>
            <Avatar active size="large" />
            <Input active size="default" />
            <Button active size="small" />
          </div>
          <div className={styles.loading}>
            <Avatar active size="large" />
            <Input active size="default" />
            <Button active size="small" />
          </div>
        </div>
      }
    >
      <div className="flex w-full gap-2.5">
        {integrations?.map(integration => <Uysot {...integration} key={String(integration.id)} />)}
      </div>
    </ConditionalRender>
  );
};

export default Content;
