import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PartyViewForm, useCompanyPersons } from "modules/party/view";

import { Select } from "components";

import styles from "./items.module.scss";

type Props = {
  form: UseFormReturn<PartyViewForm>;
};

const CompanyPerson: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const { companyPersons } = useCompanyPersons();

  return (
    <Select
      allowClear
      withoutMessage
      withLabel={false}
      suffixIcon={null}
      variant="borderless"
      control={form.control}
      className={styles.unit_select}
      name="product.company_person_id"
      placeholder={t("partyView.Tanlang")}
      options={companyPersons?.map(person => ({
        key: person?.id,
        value: person?.id,
        label: person?.name as string,
        props: {
          name: person?.name
        }
      }))}
    />
    // <Item name="company_person_id" className="m-0 h-full w-full" initialValue={updateProduct?.company_person?.id}>
    //   <Select
    //     showSearch
    //     allowClear
    //     suffixIcon={null}
    //     variant="borderless"
    //     placeholder={t("partyView.Tanlang")}
    //     filterOption={filterOption}
    //     popupMatchSelectWidth={false}
    //     className={styles.unit_select}
    //   >
    //     {companyPersons?.map(person => (
    //       <Option key={person?.id} value={person?.id} props={{ name: person?.name }}>
    //         <TooltipShortName title={person?.name} length={12} />
    //       </Option>
    //     ))}
    //   </Select>
    // </Item>
  );
};

export default CompanyPerson;
