import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { uysotQueryKeys } from "../query-keys";
import { PaymentType } from "../schema";

type Query = {
  paymentTypes: PaymentType[];
};

export function usePaymentTypes({ enabled }: { enabled: boolean }) {
  const initial = {
    paymentTypes: []
  };

  const { data = initial, ...arg } = useQuery<Query>(
    [uysotQueryKeys.PAYMENT_TYPES],
    async () => {
      const { data } = await $api.get("payment-type/select");

      return { paymentTypes: data?.data };
    },
    { enabled }
  );

  return { ...data, ...arg };
}
