import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UseMutationResult } from "@tanstack/react-query";
import { Button } from "antd";
import CustomFieldIcon from "features/app/assets/icons/CustomFieldIcon";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetTableConfigs, useProjectSelect } from "features/app/service/queries";
import { tableData } from "features/app/utils/constants/defaultTableData";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { CustomFieldTypesEnum } from "features/app/utils/enums/customFieldTypesEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { generateExcelPayload } from "features/app/utils/helpers/generateExcelPayload";
import ArrowSquareDownIcon from "features/settings/assets/icons/ArrowSquareDownIcon";
import TagIcon from "features/settings/assets/icons/TagIcon";
import { routeSubmodules } from "features/warehouse/utils/constants/routeSubmodules";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { colors } from "modules/common";

import { ConditionalRender } from "components";

import MainWarehouseIcon from "../../../../app/assets/icons/MainWarehouseIcon";
import FilterSelect from "../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FilterDrawer from "../../../../app/components/filter-drawer/FilterDrawer";
import FiltersCount from "../../../../app/components/filter-drawer/filters-count/FiltersCount";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import BuildingIcon from "../../../../payment/assets/icons/BuildingIcon";
import ZapIcon from "../../../../projects/assets/icons/ZapIcon";
import FilterLinesIcon from "../../../assets/icons/FilterLinesIcon";
import { useGetWarehouseSelect } from "../../../service/query";
import { WarehouseStatusEnums } from "../../../utils/constants/warehouseStatusEnums";

import styles from "./filterDrawer.module.scss";

const queryStringArr = [
  queryParamsKeys.WAREHOUSE_IDS,
  queryParamsKeys.PROJECT_IDS,
  queryParamsKeys.AVAILABILITY,
  queryParamsKeys.CUSTOM_FIELDS
];

export interface IProps {
  customFieldEnum?: CustomFieldLocationEnum;
  tableKey?: tableConfigKeys;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportExcelMutate?: UseMutationResult<any, unknown, any, unknown>;
}

const FilterDrawerWarehouse: React.FC<IProps> = ({ exportExcelMutate, tableKey, customFieldEnum }) => {
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const productActions = actions("Omborxona");
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const { necessaryQueries } = useQueryParams();
  const [warehousesSelected, setWarehousesSelected] = useState<string[] | null>(
    // eslint-disable-next-line no-restricted-globals
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.WAREHOUSE_IDS] as string[] | null
  );
  const tableDefaultData = tableKey ? tableData(tableKey, i18n.language) : undefined;

  const { data: tableConfigData } = useGetTableConfigs(tableKey, tableDefaultData);
  const { data: customFields } = useGetCustomFieldSelect(customFieldEnum ? [customFieldEnum] : []);
  const [haveResourcesSelected, setHaveResourcesSelected] = useState<string[] | null>(
    // eslint-disable-next-line no-restricted-globals
    queryString.parse(location.search, {})[queryParamsKeys.AVAILABILITY] as string[] | null
  );
  const [projectsSelected, setProjectsSelected] = useState<string[] | null>(
    // eslint-disable-next-line no-restricted-globals
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );

  const [customFieldStates, setCustomFieldStates] = useState<{ [key: string]: string[] | null }>({});

  const selectableCustomFields = customFields?.filter(
    customField => customField?.type === "select" || customField?.type === "label"
  );

  const handleCustomFieldChange = (id: string, value: string[] | null) => {
    setCustomFieldStates(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const aggregatedCustomFieldsSelected = useMemo(() => Object.values(customFieldStates).flat(), [customFieldStates]);

  const { data: projects } = useProjectSelect({ enabled: open });
  const { data: warehouses } = useGetWarehouseSelect(false, open);
  const changeTableConfiguration = useChangeTableConfig(tableKey!, true, true);

  const onOpenFilter = () => {
    setOpen(true);
  };

  const handleExcelExport = () => {
    const data = filterColumns({
      customFields,
      tableConfig: tableConfigData
    })
      ?.map(value => ({
        key: String(value?.id),
        name: value?.title
      }))
      ?.filter(item => typeof item?.name !== "object");

    const otherParamsData = necessaryQueries(queryParamsKeys.SEARCH);

    const reqBody = {
      ...otherParamsData,
      columns: data,
      warehouse_ids: warehousesSelected,
      project_ids: projectsSelected,
      availability: haveResourcesSelected ? Boolean(haveResourcesSelected) : null
    };

    if (data) {
      exportExcelMutate?.mutate(generateExcelPayload(reqBody));
    }
  };

  const onAfterSubmit = (filter?: string) => {
    changeTableConfiguration.mutate({
      filter: filter!,
      key: tableKey!
    });
  };

  const footerContent =
    tableKey && productActions.upload ? (
      <>
        <Button onClick={handleExcelExport} className={styles.downloadBtn} loading={exportExcelMutate?.isLoading}>
          <DownloadIcon /> {t("warehouse.Yuklab olish")}
        </Button>
      </>
    ) : (
      <span />
    );
  const haveResources: { id: string; name: string }[] = useMemo(
    () => [
      {
        id: WarehouseStatusEnums.HAVE,
        name: `${t("warehouse.Mavjud")}`
      },
      {
        id: WarehouseStatusEnums.HAVENT,
        name: `${t("warehouse.Mavjud emas")}`
      },
      {
        id: WarehouseStatusEnums.ALL,
        name: `${t("warehouse.Barchasi")}`
      }
    ],
    []
  );

  const customFieldIcons: Record<string, React.ReactNode> = {
    [CustomFieldTypesEnum.SELECT]: <ArrowSquareDownIcon />,
    [CustomFieldTypesEnum.LABEL]: <TagIcon />
  };

  const searchParams = queryString.parse(location.search, { arrayFormat: "bracket" });
  const fieldValues = searchParams[queryParamsKeys.CUSTOM_FIELDS];

  useEffect(() => {
    if (selectableCustomFields && open) {
      const initialCustomFieldStates: { [key: string]: string[] | null } = {};

      selectableCustomFields.forEach(field => {
        if (fieldValues) {
          const valuesArray = Array.isArray(fieldValues) ? fieldValues : [fieldValues];

          initialCustomFieldStates[field.id] = valuesArray.filter(value =>
            field?.custom_field_options?.some(option => String(option.id) === value)
          ) as string[];
        } else {
          initialCustomFieldStates[field.id] = [];
        }
      });

      setCustomFieldStates(initialCustomFieldStates);
    }
  }, [open]);

  useEffect(() => {
    if (tableConfigData?.filter && tableConfigData?.filter?.length > 0) {
      const searchParams = new URLSearchParams(window.location.search);

      const filterParams = new URLSearchParams(tableConfigData.filter);

      filterParams.forEach((value, key) => {
        const existingValues = searchParams.getAll(key);

        if (!existingValues.includes(value)) {
          searchParams.append(key, value);
        }
      });

      const newSearchParams = searchParams.toString();

      navigate({
        search: `?${newSearchParams}`
      });
    }
  }, [tableConfigData, navigate]);

  const parseQueryParams = (key: string) =>
    queryString.parse(window.location.search, { arrayFormat: "bracket" })[key] as string[] | null;

  useEffect(() => {
    setWarehousesSelected(parseQueryParams(queryParamsKeys.WAREHOUSE_IDS));
    setProjectsSelected(parseQueryParams(queryParamsKeys.PROJECT_IDS));
    setHaveResourcesSelected(parseQueryParams(queryParamsKeys.AVAILABILITY));
  }, [window.location.search]);

  return (
    <FilterDrawer
      onAfterClear={onAfterSubmit}
      onAfterSubmit={onAfterSubmit}
      open={open}
      setOpen={setOpen}
      filterButton={
        <Button onClick={onOpenFilter}>
          <FilterLinesIcon /> {t("warehouse.Filter")}
          <FiltersCount queryStringArr={queryStringArr} />
        </Button>
      }
      height="max-content"
      queryStringArr={queryStringArr}
      footerContent={footerContent}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.WAREHOUSE_IDS,
          selectedOptions: warehousesSelected
        },
        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectsSelected
        },
        {
          queryKey: queryParamsKeys.AVAILABILITY,
          selectedOptions: haveResourcesSelected
        },
        {
          queryKey: queryParamsKeys.CUSTOM_FIELDS,
          selectedOptions: aggregatedCustomFieldsSelected as string[]
        }
      ]}
      setSelectedOptionsArr={
        [setWarehousesSelected, setProjectsSelected, setHaveResourcesSelected, setCustomFieldStates] as never
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 text-base font-medium text-gray-800">
            <FilterLinesIcon stroke={colors.GRAY_800} /> {t("warehouse.Umumiy filterlar")}
          </div>
          <div className={styles.content}>
            <FilterTreeSelect
              selectedOptions={projectsSelected}
              setSelectedOptions={setProjectsSelected}
              data={projects}
              multiple={true}
              icon={<BuildingIcon />}
              placeholder={t("warehouse.Loyiha")}
              showSearch
            />
            <FilterSelect
              selectedOptions={warehousesSelected}
              setSelectedOptions={setWarehousesSelected}
              data={warehouses}
              multiple="multiple"
              icon={<MainWarehouseIcon />}
              placeholder={t("warehouse.Omborxona")}
              showSearch
            />
            <FilterSelect
              selectedOptions={haveResourcesSelected}
              setSelectedOptions={setHaveResourcesSelected}
              data={haveResources}
              icon={<ZapIcon />}
              placeholder={t("warehouse.Mahsulot mavjudligi")}
              showSearch
            />
          </div>
        </div>
        <ConditionalRender if={selectableCustomFields?.length && selectableCustomFields?.length > 0}>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 text-base font-medium text-gray-800">
              <CustomFieldIcon stroke={colors.GRAY_800} width={20} /> {t("warehouse.O'zgaruvchi filterlar")}
            </div>
            <div className={styles.content}>
              {selectableCustomFields?.map(el => (
                <FilterSelect
                  key={el.id}
                  selectedOptions={customFieldStates[el.id]}
                  setSelectedOptions={value => handleCustomFieldChange(String(el.id), value)}
                  data={el.custom_field_options as never}
                  multiple="multiple"
                  icon={customFieldIcons[el.type]}
                  placeholder={el.name[i18n.language]}
                  showSearch
                />
              ))}
            </div>
          </div>
        </ConditionalRender>
      </div>
    </FilterDrawer>
  );
};

export default FilterDrawerWarehouse;
