import React from "react";
import { useDispatch } from "react-redux";
import { useForm, UseFormSetValue } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Modal } from "antd";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/actions";

import { PartyForm } from "modules/party/create";
import {
  Additional,
  CompanyPersonForm,
  companyPersonFormSchema,
  Footer,
  Phone,
  Tin
} from "modules/party/create-company-person";

import { Input } from "components";

type Props = {
  isUpdate?: boolean;
  setParentValue: UseFormSetValue<PartyForm>;
};

const Index: React.FC<Props> = ({ setParentValue, isUpdate }) => {
  const dispatch = useDispatch();
  const { setPartyCreateCompanyPerson } = partyReducerActions;
  const { visible, name, fieldName } = useAppSelector(({ partyReducer }) => partyReducer.createCompanyPerson);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting }
  } = useForm<CompanyPersonForm>({
    resolver: zodResolver(companyPersonFormSchema)
  });

  const onCancel = () => {
    dispatch(setPartyCreateCompanyPerson({ visible: false, name: undefined, fieldName }));
  };

  const onAfterOpen = (open: boolean) => {
    if (open) {
      setValue("name", name || "");
    } else {
      reset({
        balances: undefined,
        company_person_folder_id: undefined,
        description: undefined,
        latitude: undefined,
        longitude: undefined,
        name: undefined,
        phone: undefined,
        start_date: undefined,
        tin: undefined,
        type: undefined
      });
    }
  };

  return (
    <Modal
      centered
      open={visible}
      destroyOnClose
      onCancel={onCancel}
      title="Ta'minotchi yaratish"
      afterOpenChange={onAfterOpen}
      footer={<Footer setParentValue={setParentValue} handleSubmit={handleSubmit} isSubmitting={isSubmitting} />}
    >
      <form
        className="box-border flex flex-col gap-4 py-3"
        id={isUpdate ? "update-create-company-person" : "create-company-person"}
      >
        <Tin control={control} setValue={setValue} />
        <Input withoutMessage label="Nomi" name="name" control={control} placeholder="Ta'minotchi nomi" />
        <Phone control={control} setValue={setValue} />
        <Additional control={control} setValue={setValue} />
      </form>
    </Modal>
  );
};

export default Index;
