import { PropsWithChildren } from "react";
import { Button, Drawer, Form } from "antd";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "../../../../hooks/useQueryParams";
import { useRoutePermissions } from "../../../../hooks/useRoutePermissions";
import RefreshIcon from "../../../payment/assets/icons/RefreshIcon";
import { routeSubmodules } from "../../../settings/utils/constants/routeSubmodules";
import { useChangeTableConfig, useDeleteTableConfig } from "../../service/mutation";
import { TABLE } from "../../utils/constants/localStorageKeys";
import { queryParamsKeys } from "../../utils/constants/queryParamsKeys";
import { tableConfigKeys } from "../../utils/constants/tableConfigKeys";
import { CustomFieldSelectModel } from "../../utils/models/customFieldSelectModel";
import { UserTableConfigModel, UserTableDataModel } from "../../utils/models/user/userTableConfigModel";
import ConditionalRender from "../conditional-render/ConditionalRender";

import ColumnConfig from "./column-config/ColumnConfig";
import SizeConfig from "./size-config/SizeConfig";

import styles from "./tableConfig.module.scss";

const { useForm } = Form;

type Props = {
  open: boolean;
  title?: string;
  width?: number;
  isNeedSize?: boolean;
  data?: UserTableDataModel;
  onAfterClear?: () => void;
  onAfterSubmit?: () => void;
  tableConfigKey: tableConfigKeys;
  defaultData?: UserTableDataModel;
  setOpen: (open: boolean) => void;
  placement?: "top" | "right" | "bottom" | "left";
  customFields?: CustomFieldSelectModel[];
  smetaContainer?: HTMLElement;
};

const TableConfig = ({
  open,
  data,
  width,
  title,
  setOpen,
  children,
  placement,
  defaultData,
  onAfterClear,
  onAfterSubmit,
  tableConfigKey,
  isNeedSize = false,
  customFields,
  smetaContainer
}: PropsWithChildren<Props>) => {
  const [form] = useForm();
  const { appends } = useQueryParams();
  const deleteTableConfig = useDeleteTableConfig();
  const changeTableConfig = useChangeTableConfig(tableConfigKey);
  const routePermissions = useRoutePermissions("Sozlamalar", routeSubmodules);
  const actions = routePermissions.actions("O'zgaruvchilar");
  const { t } = useTranslation();

  const onCancel = () => {
    setOpen(false);
  };

  const onReset = () => {
    const top = defaultData?.column
      ?.filter(el => data?.column?.some(item => el?.id === item?.id))
      .reduce((acc: Record<string, any>, curr, index) => {
        acc[curr?.name] = { check: curr?.checked, place: index + 1 };
        return acc;
      }, {});

    const bottom = data?.column
      ?.filter(el => customFields?.some(item => el?.id === item?.id))
      .reduce((acc: Record<string, any>, curr, index) => {
        acc[curr?.name] = { check: false, place: index + 1 };
        return acc;
      }, {});

    deleteTableConfig
      ?.mutateAsync(tableConfigKey)
      .then(() => {
        form.setFieldsValue({
          column: {
            top,
            bottom
          }
        });
        onAfterClear && onAfterClear();
      })
      .finally(onCancel);
  };

  const onOk = () => form.submit();

  const generateColumnData = (columns: Record<string, { check: boolean; place: number }>) =>
    Object.entries(columns || {})
      .map(([key, value]) => ({
        id: data?.column?.find(item => item?.name === key)?.id,
        name: key,
        title: data?.column?.find(item => item?.name === key)?.title,
        checked: value?.check,
        place: value?.place
      }))
      ?.sort((a, b) => a?.place - b?.place);

  const onFinish = (values: Record<string, unknown>) => {
    const fieldColumn = values.column as {
      top: Record<string, { check: boolean; place: number }>;
      bottom: Record<string, { check: boolean; place: number }>;
    };

    const column = [...generateColumnData(fieldColumn?.top), ...generateColumnData(fieldColumn?.bottom)];

    if (values?.size) {
      const allTableSizes = JSON.parse(localStorage.getItem(TABLE) || "{}");

      appends([
        {
          key: queryParamsKeys.SIZE,
          value: values?.size as string
        },
        {
          key: queryParamsKeys.PAGE,
          value: 1
        }
      ]);
      localStorage.setItem(TABLE, JSON.stringify({ ...allTableSizes, [tableConfigKey]: values.size }));
    }

    const reqData: UserTableConfigModel = {
      key: tableConfigKey,
      data: JSON.stringify({ column, size: values?.size })
    };

    changeTableConfig
      .mutateAsync(reqData)
      .then(onAfterSubmit && onAfterSubmit)
      .then(onCancel);
  };

  const footer = (
    <div className={styles.footer}>
      <Button onClick={onReset} className={styles.refresh} loading={deleteTableConfig.isLoading}>
        <RefreshIcon /> {t("tableSettings.Tozalash")}
      </Button>
      <Button type="primary" onClick={onOk} loading={changeTableConfig.isLoading}>
        {t("tableSettings.Saqlash")}
      </Button>
    </div>
  );

  return (
    <>
      {children}
      <Drawer
        open={open}
        footer={footer}
        onClose={onCancel}
        width={width ?? 462}
        className={styles.drawer}
        placement={placement ?? "right"}
        title={title ?? t("tableSettings.Jadval sozlash")}
        getContainer={() => smetaContainer!}
      >
        <Form onFinish={onFinish} form={form} className={styles.form}>
          <div className={styles.top}>
            <ColumnConfig
              form={form}
              actions={actions}
              columnData={data?.column}
              defaultData={defaultData}
              tableConfigKey={tableConfigKey}
              customFields={customFields}
            />
          </div>
          <ConditionalRender if={isNeedSize}>
            <SizeConfig form={form} defaultSize={data?.size} />
          </ConditionalRender>
        </Form>
      </Drawer>
    </>
  );
};

export default TableConfig;
