import React from "react";
import { useDispatch } from "react-redux";
import { Popover } from "antd";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import { cx } from "features/app/utils/helpers/cx";
import { ProductModel } from "features/app/utils/models/notificationModel";
import { ProductInfoMarketPrice } from "features/supply/utils/models/orderModel";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { appReducerActions } from "store/reducers/appReducer";
import { supplyActions } from "store/reducers/supplyReducer";
import { warehouseProductsAction } from "store/reducers/warehouseProductsReducer";

import { RU } from "modules/common";

import { ArrowUpIcon, TrendDownIcon } from "pages/dashboard/components/icons";

import styles from "../resourceInfoDrawer.module.scss";
import ProductMarketPriceModal from "features/app/components/product-market-price-modal/ProductMarketPriceModal";

type Props = {
  single_product: (ProductModel & { market_price: ProductInfoMarketPrice }) | undefined;
};

const Top: React.FC<Props> = ({ single_product }) => {
  const { i18n, t } = useTranslation();
  const { product } = useAppSelector(state => state.warehouseProducts?.resource);
  const dispatch = useDispatch();
  const { setResourceHistory } = warehouseProductsAction;
  const { setPartyModal } = supplyActions;
  const { setProductMarketPrice, setProductMarketPriceModal } = appReducerActions;
  const onOpenIncomeExpenseHistory = () => {
    dispatch(
      setResourceHistory({
        visible: true,
        product,
        order_id: single_product?.market_price?.order_id
      })
    );
  };

  const onOpenMarketPrice = () => {
    dispatch(
      setProductMarketPrice({
        productId: product?.product?.id,
        unitId: product?.unit?.id,
        defaultProduct: structuredClone(product?.product) as never,
        defaultUnit: structuredClone(product?.unit) as never
      })
    );

    dispatch(
      setProductMarketPriceModal({
        visible: true
      })
    );
  };

  const onOpenPartyView = () => {
    dispatch(
      setPartyModal({
        visible: true,
        isView: true,
        party_id: single_product?.market_price?.order_id,
        zIndex: 20000
      })
    );
  };

  const content = (
    <div className="flex flex-col gap-3">
      <div className="flex items-center justify-between gap-10">
        <span className="text-sm font-medium text-gray-500">{t("warehouse.Oxirgi kirim")}</span>
        <div className="flex items-center gap-1">
          <span className="text-sm font-semibold text-gray-700">
            {single_product?.market_price?.amount?.toLocaleString(RU) || t("warehouse.Mavjud emas")}
          </span>
          <span className="text-sm font-medium text-gray-400">{single_product?.market_price?.currency?.symbol}</span>
        </div>
      </div>
      <ConditionalRender if={single_product?.market_price?.date}>
        <div className="flex items-center justify-between gap-10">
          <span className="text-sm font-medium text-gray-500">{t("warehouse.Kirim sanasi")}</span>
          <span className="text-sm font-semibold text-gray-700">{single_product?.market_price?.date}</span>
        </div>
      </ConditionalRender>
      <ConditionalRender if={single_product?.market_price?.order_id}>
        <div className="flex items-center justify-between gap-10">
          <span className="text-sm font-medium text-gray-500">{t("warehouse.Partiya")}</span>
          <span
            className="cursor-pointer text-sm font-semibold text-primary-500 hover:underline"
            onClick={onOpenPartyView}
          >
            P-{single_product?.market_price?.order_id}
          </span>
        </div>
      </ConditionalRender>
      <div className="flex items-center justify-between gap-10">
        <span className="text-sm font-medium text-gray-500">{t("warehouse.Narx o'zgarishi")}</span>
        <div className="flex items-center gap-2">
          <div
            className={cx("flex items-center gap-1 rounded-xl px-2 py-1 text-sm font-semibold text-gray-500", {
              "bg-success-50 text-success-500":
                single_product?.market_price?.change_amount_percent &&
                single_product?.market_price?.change_amount_percent < 0,
              "bg-error-50 text-error-500":
                single_product?.market_price?.change_amount_percent &&
                single_product?.market_price?.change_amount_percent > 0
            })}
          >
            <ConditionalRender if={single_product?.market_price?.change_amount_percent}>
              <ArrowUpIcon
                isRotate={
                  !!(
                    single_product?.market_price?.change_amount_percent &&
                    single_product?.market_price?.change_amount_percent > 0
                  )
                }
              />
            </ConditionalRender>
            <span>{Math.abs(single_product?.market_price?.change_amount_percent || 0)} %</span>
          </div>
          <div
            onClick={onOpenMarketPrice}
            className="flex cursor-pointer items-center justify-center rounded-lg bg-gray-100 p-1"
          >
            <TrendDownIcon />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.resource_name_container}>
      <div className={cx("resource")}>
        <div
          style={{
            borderColor: product?.product?.resource?.color,
            color: product?.product?.resource?.color
          }}
        >
          {product?.product?.resource?.symbol[i18n.language]}
        </div>
        <span className={styles.warehouse_name}>
          <PopoverShortName title={product?.product?.name[i18n.language]} length={35} />
        </span>
      </div>
      <div className="flex w-full flex-col gap-4">
        <div className={styles.container} onClick={onOpenIncomeExpenseHistory}>
          <span className="text-[15px] font-medium text-gray-600">{t("warehouse.Umumiy miqdori")}</span>
          <div className={cx(styles.total_amount, "flex items-center gap-4")}>
            <div>
              <span className="text-base font-medium text-gray-700">{product?.total_quantity?.toLocaleString(RU)}</span>{" "}
              {product?.unit?.symbol[i18n.language]}
            </div>
            <span className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-lg bg-gray-100 p-2">
              <TrendDownIcon />
            </span>
          </div>
        </div>
        <Popover arrow={false} content={content}>
          <div className={styles.container}>
            <span className="text-[15px] font-medium text-gray-600">{t("warehouse.Kirim narxi")}</span>
            <div className={cx(styles.total_amount, "flex items-center gap-4")}>
              <div>
                <span className="text-base font-medium text-gray-700">
                  {single_product?.market_price?.amount?.toLocaleString(RU)}
                </span>{" "}
                {single_product?.market_price?.currency?.symbol}
              </div>
              <div
                className={cx("flex items-center gap-1 rounded-xl px-2 py-1 text-sm font-semibold text-gray-500", {
                  "bg-success-50 text-success-500":
                    single_product?.market_price?.change_amount_percent &&
                    single_product?.market_price?.change_amount_percent < 0,
                  "bg-error-50 text-error-500":
                    single_product?.market_price?.change_amount_percent &&
                    single_product?.market_price?.change_amount_percent > 0
                })}
              >
                <ConditionalRender if={single_product?.market_price?.change_amount_percent}>
                  <ArrowUpIcon
                    isRotate={
                      !!(
                        single_product?.market_price?.change_amount_percent &&
                        single_product?.market_price?.change_amount_percent > 0
                      )
                    }
                  />
                </ConditionalRender>
                <span>{Math.abs(single_product?.market_price?.change_amount_percent || 0)} %</span>
              </div>
            </div>
          </div>
        </Popover>
      </div>
      <ProductMarketPriceModal />
    </div>
  );
};

export default Top;
