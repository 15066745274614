import { z } from "zod";

import {
  currencySchema,
  customFieldValueSchema,
  idAndNameSchema,
  productSchema,
  unitSchema,
  userSchema
} from "modules/common";

import { confirmationPaymentSchema, statusSchema, typeSchema, WidgetKeys, widgetKeys } from "./schema";

// widget
export const projectCalendarWidgetSchema = z.object({
  date: z.string(),
  progress_count: z.number().nullable()
});

export const finalExpensesWidgetSchema = z.object({
  amount: z.number().nullable(),
  currency: currencySchema,
  date: z.string().nullable(),
  id: z.number().nullable(),
  type: typeSchema.nullable(),
  related: z.number().nullable(),
  user: userSchema.nullable(),
  financial: idAndNameSchema.nullable(),
  company_person: z
    .object({
      id: z.number(),
      type: z.string(),
      name: z.string(),
      description: z.string(),
      start_date: z.string(),
      status: statusSchema,
      person: z
        .object({
          id: z.number(),
          name: z.string(),
          phone: z.string()
        })
        .nullable()
    })
    .nullable(),
  status: statusSchema,
  cash: idAndNameSchema.nullable(),
  project: idAndNameSchema.nullable(),
  unread_message_count: z.number().nullable(),
  inventory: idAndNameSchema.merge(
    z.object({
      code: z.number().nullable()
    })
  ),
  order_ids: z.array(z.number().nullable()),
  task: idAndNameSchema.merge(
    z.object({
      place: z.number()
    })
  ),
  section: idAndNameSchema.merge(
    z.object({
      place: z.number(),
      project_id: z.number()
    })
  ),
  custom_field_values: customFieldValueSchema.nullable(),
  description: z.string().nullable()
});

export const activeDeliverersWidgetSchema = idAndNameSchema.merge(
  z.object({
    total_amount: z.number().nullable()
  })
);

export const paymentBarWidgetSchema = z.object({
  total_amount: z.number().nullable(),
  difference_percentage: z.number().nullable(),
  count_of_payments: z.number().nullable(),
  payment_types: z.array(
    idAndNameSchema.merge(
      z.object({
        color: z.string(),
        total_amount: z.number().nullable(),
        count_of_payments: z.number().nullable()
      })
    )
  )
});

export const paymentLineWidgetSchema = z.object({
  month: z.string(),
  payment_types: z.array(
    idAndNameSchema.merge(
      z.object({
        color: z.string(),
        total_amount: z.number().nullable(),
        count_of_payments: z.number().nullable()
      })
    )
  ),
  count_of_payments: z.number().optional().nullable()
});

// widget view
export const projectActivitySchema = z.object({
  id: z.number(),
  user: userSchema,
  section: z.object({
    id: z.number(),
    name: z.string()
  }),
  place: z.number(),
  date: z.string(),
  task: z.object({
    id: z.number(),
    name: z.string(),
    place: z.number()
  }),
  unit: unitSchema,
  quantity: z.number()
});

export const projectCountProjectsSchema = z.object({
  id: z.number().optional().nullable(),
  area: z.number().nullable(),
  currency: currencySchema.nullable(),
  per_area_percent: z.number().nullable(),
  per_area_price: z.number().nullable(),
  profit: z.number().nullable(),
  prediction_total_amount: z.number().nullable(),
  spend_total_amount: z.number().nullable(),
  total_amount: z.number().nullable(),
  name: z.string().nullable(),
  per_area_prediction_price: z.number().nullable(),
  process: z.number().nullable()
});

export const projectSalePriceSchema = projectCountProjectsSchema.omit({ name: true, id: true }).merge(
  z.object({
    projects: z.array(projectCountProjectsSchema)
  })
);

export const paymentExpenseSchema = confirmationPaymentSchema.omit({ project: true, unread_message_count: true });

export const activeCompanyPersonSchema = z.object({
  id: z.number().optional(),
  created_at: z.string().optional().nullable(),
  delivery_date: z.string().optional().nullable(),
  payment_date: z.string().optional().nullable(),
  status: statusSchema.optional().nullable(),
  agent: z
    .object({
      id: z.number(),
      full_name: z.string().optional().nullable(),
      image: z.string().optional().nullable()
    })
    .optional()
    .nullable(),
  creator: z
    .object({
      id: z.number(),
      full_name: z.string().optional().nullable(),
      image: z.string().optional().nullable()
    })
    .optional()
    .nullable(),
  currency: currencySchema.optional().nullable(),
  debt_amount: z.number().optional().nullable(),
  total_amount: z.number().optional().nullable(),
  calculated_amount: z.number().optional().nullable(),
  unread_message_count: z.number().optional().nullable(),
  custom_field_values: customFieldValueSchema.optional().nullable(),
  percent: z.number().optional().nullable(),
  payment_percent: z.number().optional().nullable()
});

export const productPriceAnalyzeSchema = z.object({
  id: z.number().nullable(),
  amount: z.number().nullable().optional(),
  change_amount_percent: z.number().nullable().optional(),
  date: z.string().nullable().optional(),
  currency: currencySchema,
  product: productSchema,
  unit: unitSchema
});

export const warehouseAmountSchema = z.object({
  id: z.number().nullable(),
  product: productSchema.optional(),
  total_amount: z.number().nullable().optional(),
  total_quantity: z.number().nullable().optional(),
  currency: currencySchema.nullable().optional(),
  unit: unitSchema.nullable().optional()
});
export const projectByResourceViewDataTabCount = z.object({
  product: z.number(),
  person: z.number(),
  mechanism: z.number(),
  another: z.number()
});

export const projectByResourceDetailViewSchema = z.object({
  id: z.number(),
  quantity: z.number(),
  currency: currencySchema,
  inventory: z
    .object({
      id: z.number(),
      name: z.string(),
      code: z.string(),
      service_amount: z.number(),
      company_person: z.object({
        id: z.number(),
        full_name: z.string(),
        phone: z.string()
      })
    })
    .optional(),
  company_person: z
    .object({
      id: z.number(),
      full_name: z.string(),
      phone: z.string()
    })
    .optional(),
  warehouse: z.object({
    id: z.number(),
    name: z.string(),
    mine: z.boolean()
  }),
  total_amount: z.number().optional()
});

export const vatBalanceViewSchema = z.object({
  id: z.number(),
  status: statusSchema.optional(),
  ordered_date: z.string().optional(),
  vat_amount: z.number().optional(),
  currency: currencySchema.optional()
});

// widget data
export type ProjectCalendarWidget = z.infer<typeof projectCalendarWidgetSchema>;
export type FinalExpensesWidget = z.infer<typeof finalExpensesWidgetSchema>;
export type PaymentBarWidget = z.infer<typeof paymentBarWidgetSchema>;
export type ActiveDeliverersWidget = z.infer<typeof activeDeliverersWidgetSchema>;
export type PaymentLineWidget = z.infer<typeof paymentLineWidgetSchema>;
export type ProductPriceAnalyze = z.infer<typeof productPriceAnalyzeSchema>;

// widget view data
export type ProjectActivity = z.infer<typeof projectActivitySchema>;
export type ProjectCountProjects = z.infer<typeof projectCountProjectsSchema>;
export type ProjectSalePriceView = z.infer<typeof projectSalePriceSchema>;
export type PaymentExpense = z.infer<typeof paymentExpenseSchema>;
export type ExpensePaymentByMonth = z.infer<typeof paymentExpenseSchema>;
export type ActiveCompanyPersonView = z.infer<typeof activeCompanyPersonSchema>;
export type WarehouseAmountView = z.infer<typeof warehouseAmountSchema>;
export type ProjectByResourceViewDataTabCount = z.infer<typeof projectByResourceViewDataTabCount>;
export type ProjectByResourceDetailViewData = z.infer<typeof projectByResourceDetailViewSchema>;
export type VatBalanceViewData = z.infer<typeof vatBalanceViewSchema>;
// table data by widget key
export type TableDataTypeWidgetKey = {
  product_price_analyze: ProductPriceAnalyze;
};
