import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { partyQueryKeys, PartyView } from "modules/party/view";

type Query = {
  party: PartyView;
};

interface Params extends Pick<UseQueryOptions, "enabled"> {
  id?: number;
}

export function usePartyView({ id, enabled }: Params) {
  const initialData: Query = {
    party: {} as never
  };

  const { data = initialData, ...arg } = useQuery<Query>(
    [partyQueryKeys.VIEW, id],
    async () => {
      const { data } = await $api.get(`supply/order-view?id=${id}`);

      return { party: data?.data };
    },
    {
      enabled: enabled && !!id
    }
  );

  return { ...data, ...arg };
}
