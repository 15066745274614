import { UseFieldArrayAppend } from "react-hook-form";
import { localeFormatter } from "features/app/utils/helpers/localeFormatter";
import i18n from "i18next";
import { uid } from "uid";

import { PartyForm } from "modules/party/create";
import { PartyEstimateForm } from "modules/party/estimate";

export function appendedProducts(
  products: PartyEstimateForm["products"],
  appendCheck: UseFieldArrayAppend<PartyForm, "check_products">,
  append: UseFieldArrayAppend<PartyForm, "warehouse_products">,
  currency_id?: number,
  currency_symbol?: string,
  project_id?: number | string
) {
  products?.forEach(p => {
    appendCheck({
      code: p?.product?.code,
      id: p.product?.id,
      name: p?.product?.name,
      resource: p?.product?.resource,
      unit: p?.unit
    });

    append({
      task_product_id: p.id,
      product_id: p?.product?.id,
      unit_id: p?.unit?.id,
      unit_name: p?.unit?.symbol?.[i18n.language] || "",
      quantity: localeFormatter(p?.quantity ? String(p?.quantity) : "0"),
      amount: localeFormatter(String(p?.amount)),
      // @ts-ignore
      warehouse_id: null,
      project_id: Number(project_id),
      company_person_id: null,
      company_person_name: undefined,
      vat_id: null,
      vat_value: undefined,
      vat_amount: undefined,
      // @ts-ignore
      currency_id: currency_id || 1,
      // @ts-ignore
      currency_symbol: currency_symbol || "",
      total: localeFormatter(String(p?.quantity * p?.amount)),
      rowId: uid()
    });
  });
}

export function parseAppendedProducts(
  products: PartyEstimateForm["products"],
  appendCheck: UseFieldArrayAppend<PartyForm, "check_products">,
  currency_id?: number,
  currency_symbol?: string,
  project_id?: number | string
) {
  return products?.map(p => {
    appendCheck({
      code: p?.product?.code,
      id: p.product?.id,
      name: p?.product?.name,
      resource: p?.product?.resource,
      unit: p?.unit
    });

    return {
      task_product_id: p.id,
      product_id: p?.product?.id,
      unit_id: p?.unit?.id,
      unit_name: p?.unit?.symbol?.[i18n.language] || "",
      quantity: localeFormatter(p?.quantity ? String(p?.quantity) : "0"),
      amount: localeFormatter(String(p?.amount)),
      // @ts-ignore
      warehouse_id: null,
      project_id: Number(project_id),
      company_person_id: null,
      company_person_name: undefined,
      vat_id: null,
      vat_value: undefined,
      vat_amount: undefined,
      // @ts-ignore
      currency_id: currency_id || 1,
      // @ts-ignore
      currency_symbol: currency_symbol || "",
      total: localeFormatter(String(p?.quantity * p?.amount)),
      rowId: uid()
    };
  });
}
