import React from "react";

import Left from "./left";
import Right from "./right";

const Content: React.FC = () => (
  <div className="box-border flex w-full flex-1 overflow-hidden">
    <Left />
    <Right />
  </div>
);

export default Content;
