import React from "react";
import { UseFormReturn } from "react-hook-form";
import { routeSubmodules } from "features/supply/utils/constants/routeSubmodules";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { cx } from "modules/common";
import { PartyStatus, PartyTable, PartyView, PartyViewForm, SupplyColumns } from "modules/party/view";

import styles from "./index.module.scss";

type Props = {
  groupTable?: boolean;
  isLoading: boolean;
  status: PartyStatus;
  form: UseFormReturn<PartyViewForm>;
  products: PartyView["warehouse_products"] | undefined;
};

const Index: React.FC<Props> = ({ products, isLoading, status, groupTable, form }) => {
  const { actions: actionsFunc } = useRoutePermissions("Ta'minot", routeSubmodules);
  const actions = actionsFunc("Partiyalar");
  
  return (
    <div className="flex-1">
      <PartyTable
        type="supply"
        data={products}
        loading={isLoading}
        className={cx({
          [styles.group_table]: groupTable
        })}
        columns={SupplyColumns({ form, status, actions })}
      />
    </div>
  );
};

export default Index;
