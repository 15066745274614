import { ReactNode } from "react";
import { ColumnType as BaseColumnType } from "antd/es/table/interface";
import { z } from "zod";

import { currencySchema, customFieldValueSchema, productSchema, unitSchema, userSchema } from "modules/common";

export const partyTypeSchema = z.enum(["supply", "impost", "warehouse", "payment"]);

export const confirmationPaymentStatusSchema = z.enum(["pending", "recieved", "rejected"]);

export const partyStatusSchema = z.enum([
  "open",
  "pending",
  "ordered",
  "partially_recieved",
  "recieved",
  "rejected",
  "deleted",
  "buying"
]);

export const companyPersonTypeSchema = z.enum(["supplier", "client", "team", "worker"]);

export const companyPersonStatusSchema = z.enum(["active", "passive", "blocked", "deleted"]);

export const idAndNameStringSchema = z.object({
  id: z.number(),
  name: z.string()
});

export const companyPersonSchema = z.object({
  id: z.number(),
  name: z.string(),
  type: companyPersonTypeSchema,
  status: companyPersonStatusSchema,
  description: z.string().nullable(),
  start_date: z.string(),
  person: z.object({
    id: z.number(),
    name: z.string(),
    phone: z.string()
  })
});

export const paymentTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
  color: z.string()
});

export const warehouseProductStatusSchema = z.enum([
  "open",
  "passive",
  "pending",
  "ordered",
  "partially_recieved",
  "recieved",
  "rejected",
  "deleted"
]);

export const warehouseProductOfferStatusSchema = z.enum(["open", "recieved", "rejected"]);

export const vatSchema = z.object({
  id: z.number(),
  value: z.number(),
  basic: z.boolean()
});

export const warehouseProductSchema = z.object({
  id: z.number(),
  product: productSchema,
  unit: unitSchema,
  company_person: companyPersonSchema,
  currency: currencySchema,
  warehouse: idAndNameStringSchema,
  project: idAndNameStringSchema,
  vat: vatSchema.nullable(),
  status: warehouseProductStatusSchema,
  paid_amount: z.number(),
  calculated_amount: z.number(),
  must_pay_amount: z.number(),
  quantity: z.number(),
  initial_quantity: z.number(),
  expected_quantity: z.number(),
  amount: z.number(),
  total_amount: z.number(),
  recieved_quantity: z.number(),
  warehouse_product_offers: z.array(
    z.object({
      id: z.number(),
      amount: z.number(),
      name: z.string().nullable(),
      vat: z.boolean(),
      phone: z.string(),
      description: z.string().nullable(),
      rejected_description: z.string().nullable(),
      warehouse_product_id: z.number(),
      company_person: companyPersonSchema,
      currency: currencySchema,
      payment_type: paymentTypeSchema,
      status: warehouseProductOfferStatusSchema,
      created_date: z.string()
    })
  ),
  change_amount_percent: z.number(),
  warehouse_total_quantity: z.number(),
  market_price: z.number(),
  market_price_date: z.string(),
  market_price_order_id: z.number(),
  market_price_currency: currencySchema,
  unread_message_count: z.number()
});

export const partyViewSchema = z.object({
  id: z.number(),
  confirmation_payment: confirmationPaymentStatusSchema,
  delivery_date: z.string().optional(),
  payment_date: z.string().optional(),
  ordered_date: z.string(),
  status: partyStatusSchema,
  company_person: companyPersonSchema,
  currency: currencySchema,
  agent: userSchema.optional(),
  percent: z.number(),
  payment_percent: z.number(),
  warehouse_products: z.array(warehouseProductSchema),
  unread_message_count: z.number(),
  custom_field_values: z.array(customFieldValueSchema),
  company_person_payment_types: z.array(
    z.object({
      company_person_id: z.number(),
      id: z.number(),
      payment_type: paymentTypeSchema
    })
  )
});

export const partyGroupTypeSchema = z.array(
  z.object({
    name: z.string(),
    id: z.number().optional(),
    products: z.array(warehouseProductSchema)
  })
);

export const productUpdateSchema = z.object({
  id: z.number(),
  product_id: z.number(),
  unit_id: z.number(),
  warehouse_id: z.number(),
  project_id: z.number().optional().nullable(),
  quantity: z.string().min(1),
  currency_id: z.number().optional(),
  amount: z.string().min(1),
  company_person_id: z.number().optional().nullable(),
  total_amount: z.string().min(1),
  vat_id: z.number().optional().nullable()
});

export const partyViewFormSchema = z.object({
  product: productUpdateSchema.optional(),
  warehouse_products: z
    .array(
      z.object({
        id: z.number().optional(),
        quantity: z.string().optional()
      })
    )
    .optional()
});

export const partyViewWarehousePayloadSchema = z.object({
  id: z.number(),
  warehouse_products: z.array(
    z.object({
      id: z.number(),
      quantity: z.number()
    })
  )
});

export const partyColumn = z.object({
  key: z.number(),
  title: z.string(),
  checked: z.boolean(),
  disabled: z.boolean().optional(),
  order: z.number(),
  width: z.number(),
  minWidth: z.number().optional()
});

export type PartyView = z.infer<typeof partyViewSchema>;
export type PartyType = z.infer<typeof partyTypeSchema>;
export type WarehouseProductOfferStatus = z.infer<typeof warehouseProductOfferStatusSchema>;
export type PartyStatus = z.infer<typeof partyStatusSchema>;
export type ConfirmationPaymentStatus = z.infer<typeof confirmationPaymentStatusSchema>;
export type WarehouseProduct = z.infer<typeof warehouseProductSchema>;
export type PartyGroup = z.infer<typeof partyGroupTypeSchema>;
export type CompanyPerson = z.infer<typeof companyPersonSchema>;
export type ProductUpdate = z.infer<typeof productUpdateSchema>;
export type Vat = z.infer<typeof vatSchema>;
export type IdAndNameString = z.infer<typeof idAndNameStringSchema>;
export type PartyViewForm = z.infer<typeof partyViewFormSchema>;
export type PartyViewWarehousePayload = z.infer<typeof partyViewWarehousePayloadSchema>;
export type PartyColumn = z.infer<typeof partyColumn>;
export type PaymentType = z.infer<typeof paymentTypeSchema>;
export type PartyColumnsType<TRecord extends object = any> = Array<
  BaseColumnType<TRecord> & {
    minWidth?: number;
  }
>;
export type NewPartyColumnsType = Array<
  {
    key: string | number;
    title: ReactNode;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    className?: string;
    active?: boolean;
    hidden?: boolean;
    fixed?: "left" | "right";
    checked?: boolean;
  } & (
    | { dataKey: keyof WarehouseProduct }
    | { render: (value: WarehouseProduct, record: WarehouseProduct, index: number) => ReactNode }
  )
>;
