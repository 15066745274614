import React from "react";

const CornerUpLeft = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.50065 11.6654L3.33398 7.4987M3.33398 7.4987L7.50065 3.33203M3.33398 7.4987H8.66732C11.4676 7.4987 12.8677 7.4987 13.9373 8.04367C14.8781 8.52303 15.643 9.28794 16.1223 10.2287C16.6673 11.2983 16.6673 12.6984 16.6673 15.4987V16.6654"
      stroke="#344054"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CornerUpLeft;
