import React from "react";

import { isEmptyArr, User } from "modules/common";
import { PartyView, WarehouseProduct } from "modules/party/view";

import { ConditionalRender } from "components";

import CustomFields from "./custom-fields";
import Top from "./top";
import TotalInfo from "./total-info";

type Props = {
  agent?: User;
  deliveryDate?: string;
  paymentDate?: string;
  products: WarehouseProduct[];
  customFields: PartyView["custom_field_values"];
};

const Index: React.FC<Props> = ({ agent, deliveryDate, paymentDate, products, customFields }) => (
  <div className="box-border flex h-full w-[24.75rem] flex-col justify-between gap-4 border border-b-0 border-r-0 border-t-0 border-solid border-gray-300 bg-white p-4">
    <div className="flex flex-1 flex-col gap-4 overflow-auto">
      <Top agent={agent} deliveryDate={deliveryDate} paymentDate={paymentDate} />
      <ConditionalRender if={!isEmptyArr(customFields)}>
        <CustomFields customFields={customFields} />
      </ConditionalRender>
    </div>
    <TotalInfo products={products} />
  </div>
);

export default Index;
