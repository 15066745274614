import { ReactNode } from "react";
import { Checkbox, TreeSelect } from "antd";
import classNames from "classnames";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import { ProjectSelectModel } from "features/app/utils/models/projectSelectModel";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";

import SelectSuffixIcon from "../../../assets/icons/SelectSuffixIcon";
import { getFirstLetter } from "../../../utils/helpers/getFirstLetter";
import TableEmpty from "../../table-empty/TableEmpty";

import styles from "./filter-tree-select.module.scss";

export interface FilterSelectModel {
  id: number | string;
  name: string;
  image?: string;
  icon?: JSX.Element;
}

type Props = {
  selectedOptions: string[] | null;
  setSelectedOptions: (selected: string[] | null) => void;
  multiple?: boolean;
  data?: ProjectSelectModel[];
  icon?: ReactNode;
  placeholder?: string;
  showSearch?: boolean;
  isPersons?: boolean;
  isCurrency?: boolean;
  className?: string;
};

const FilterTreeSelect = ({
  data,
  selectedOptions,
  setSelectedOptions,
  multiple,
  icon,
  placeholder,
  showSearch,
  isPersons,
  isCurrency,
  className
}: Props) => {
  const onChange = (values: string[]) => {
    setSelectedOptions(values);
  };

  const generateTreeLabel = (name: string, id: number | string, image?: string, icon?: JSX.Element) => {
    if (multiple) {
      return (
        <>
          {isPersons || isCurrency ? (
            <div className={styles.users}>
              <div className={`${styles.name} ${isCurrency ? styles.currency : ""}`}>
                {image ? (
                  <img src={image} alt={name} />
                ) : (
                  <span className={styles.non_image}>{name && getFirstLetter(name)}</span>
                )}{" "}
                {name}
              </div>
              <Checkbox
                checked={selectedOptions?.map(el => (typeof el === "string" ? Number(el) : el)).includes(id as number)}
              />
            </div>
          ) : (
            <div className={styles.option}>
              {name}
              <Checkbox
                checked={selectedOptions?.map(el => (typeof el === "string" ? Number(el) : el)).includes(id as number)}
              />
            </div>
          )}
        </>
      );
    }

    return (
      <>
        {isPersons || isCurrency ? (
          <div className={styles.users}>
            <div className={`${styles.name} ${isCurrency ? styles.currency : ""}`}>
              <img src={image} alt={name} /> {name}
            </div>
          </div>
        ) : (
          <div className={styles.option}>
            {name} {icon && icon}
          </div>
        )}
      </>
    );
  };

  return (
    <TreeSelect
      // @ts-ignore
      switcherIcon={({ expanded }) => <ChevronDownIcon rotate={expanded} />}
      multiple={multiple}
      allowClear
      maxTagCount={0}
      maxTagPlaceholder={values => (
        <>
          <div className={styles.select__tags}>
            {icon} {placeholder} ({values.length})
          </div>
        </>
      )}
      className={classNames(styles.select, className, "filter-custom-select")}
      placeholder={
        <div className={styles.placeholder}>
          {icon} {placeholder}
        </div>
      }
      onChange={onChange}
      showSearch={!!showSearch}
      popupMatchSelectWidth={false}
      value={(selectedOptions?.map(el => Number(el)) as never) || undefined}
      suffixIcon={<SelectSuffixIcon />}
      notFoundContent={<TableEmpty />}
      treeData={data?.map(project => ({
        value: project?.id,
        label: generateTreeLabel(project?.name, project?.id, undefined, undefined),
        props: {
          name: project?.name
        },
        children: project?.projects?.map(child => ({
          value: child?.id,
          label: generateTreeLabel(child?.name, child?.id, undefined, undefined),
          props: {
            name: child?.name
          }
        }))
      }))}
      filterTreeNode={selectFilterOption as any}
    />
  );
};

export default FilterTreeSelect;
