import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Checkbox, Dropdown, DropdownProps, Popover, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { TableRowSelection } from "antd/es/table/interface";
import { ColumnsType } from "antd/lib/table";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useTranslation } from "react-i18next";

import { Table } from "components";

import { useRoutePermissions } from "../../../../hooks/useRoutePermissions";
import { supplyActions } from "../../../../store/reducers/supplyReducer";
import { warehouseProductsAction } from "../../../../store/reducers/warehouseProductsReducer";
import DeleteIcon from "../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../app/assets/icons/EditIcon";
import PlusIcon from "../../../app/assets/icons/PlusIcon";
import { DateStatus } from "../../../app/components/date-status/DateStatus";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../app/components/modal-confirm/ModalConfirm";
import NotUserImage from "../../../app/components/not-image/NotUserImage";
import OrderSortBy from "../../../app/components/order-sort-by/OrderSortBy";
import Pagination from "../../../app/components/pagination/Pagination";
import { PageKeys, StatusEnums } from "../../../app/components/statuses/statusEnums";
import Statuses from "../../../app/components/statuses/Statuses";
import TableEmpty from "../../../app/components/table-empty/TableEmpty";
import TableSettings from "../../../app/components/table-settings/TableSettings";
import { useGetCustomFieldSelect, useGetTableConfigs } from "../../../app/service/queries";
import { RU } from "../../../app/utils/constants/languages";
import { queryParamsKeys } from "../../../app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../app/utils/enums/customFieldLocationEnum";
import { cx } from "../../../app/utils/helpers/cx";
import { filterColumns } from "../../../app/utils/helpers/filterColumns";
import { sliceText } from "../../../app/utils/helpers/sliceText";
import CopyIcon from "../../../payment/assets/icons/CopyIcon";
import PaymentSearch from "../../../payment/components/top/search/PaymentSearch";
import TaskProductsTemplate from "../../../projects/components/detailed-project/static-estimate/task/template/TaskProductsTemplate";
import OrderModal from "../../../supply/components/orders/order-modal/OrderModal";
import {
  warehouseOrdersTableDefaultDataFunc,
  warehouseProductTableDefaultData
} from "../../../supply/utils/constants/warehouseProductTableDefaultData";
import { ProductModel } from "../../../supply/utils/models/productModel";
import { useDeleteReservation, useExportWarehouseOrderExcel } from "../../service/mutation";
import { useGetWarehouseReservation } from "../../service/query";
import { routeSubmodules } from "../../utils/constants/routeSubmodules";
import { WarehouseReservation } from "../../utils/models/warehouseReservation";

import FilterDrawerWarehouseProduct from "./filter-drawer/FilterDrawerWarehouseProduct";

import styles from "./warehouseProduct.module.scss";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import useDebounce from "components/use-debounce/use-debounce";

// const useDebounce = (callback: () => void, delay: number) => {
//   const timeoutRef = useRef<NodeJS.Timeout | null>(null);

//   const debouncedCallback = () => {
//     if (timeoutRef.current) {
//       clearTimeout(timeoutRef.current);
//     }
//     timeoutRef.current = setTimeout(() => {
//       callback();
//     }, delay);
//   };

//   useEffect(
//     () => () => {
//       if (timeoutRef.current) {
//         clearTimeout(timeoutRef.current);
//       }
//     },
//     []
//   );

//   return debouncedCallback;
// };

const WarehouseProducts: React.FC = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { setOrderModal } = supplyActions;
  const { setReservationImportData } = warehouseProductsAction;
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const productActions = actions("Buyurtmalar");
  const deleteReservation = useDeleteReservation();
  const { data, isLoading } = useGetWarehouseReservation();
  const [isClickedRow, setIsClickedRow] = useState<boolean>(false);
  const [selectProducts, setSelectProducts] = useState<WarehouseReservation[]>([]);
  const exportExcel = useExportWarehouseOrderExcel();

  const changeTableConfig = useChangeTableConfig(tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG, true);

  const { data: tableConfig } = useGetTableConfigs(
    tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG,
    warehouseProductTableDefaultData
  );

  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.WAREHOUSE_PRODUCT]);

  const onAllProductChange = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      data?.data?.forEach((item: WarehouseReservation) => {
        setSelectProducts([...selectProducts, item]);
      });
    } else {
      setSelectProducts([]);
      setIsClickedRow(false);
    }
  };

  const onDeleteProducts = (ids?: number[]) => () => {
    const orderIds = ids || selectProducts?.map(item => item?.id);

    return deleteReservation.mutateAsync(orderIds);
  };

  const onOpenWarehouseProductUpdate = (record: WarehouseReservation) => () => {
    dispatch(
      setOrderModal({
        visible: true,
        isEdit: true,
        selectProducts: [record as never]
      })
    );
  };

  const menuItems = (record: WarehouseReservation): DropdownProps["menu"] => {
    const allItems = [
      {
        key: "1",
        label: (
          <div className="flex items-center gap-2">
            <EditIcon />
            {t("warehouse.Tahrirlash")}
          </div>
        ),
        onClick: onOpenWarehouseProductUpdate(record),
        view: (record.status === StatusEnums.PASSIVE || record.status === StatusEnums.OPEN) && productActions?.update
      },
      {
        key: "2",
        label: (
          <ModalConfirm onOk={onDeleteProducts([record?.id])}>
            <div className="flex items-center gap-2">
              <DeleteIcon />
              {t("warehouse.O'chirish")}
            </div>
          </ModalConfirm>
        ),
        view: productActions.delete
      }
    ];

    return {
      items: allItems.filter(item => item.view)
    };
  };

  const sectionName = (record: WarehouseReservation) => {
    const { task, section } = record;

    return (
      <div className={styles.work}>
        <h5 className="m-0">
          {section?.name ? (
            <>
              <span>
                {section?.place}.{task?.place}
              </span>
              <span>
                {section?.name?.length > 16 ? (
                  <Popover title={section?.name} overlayClassName={styles.popover}>
                    {sliceText(section?.name, 16)}
                  </Popover>
                ) : (
                  section?.name
                )}
              </span>
            </>
          ) : (
            "-"
          )}
        </h5>
        {task?.name && (
          <span>
            {task?.name?.length > 16 ? (
              <Popover title={task?.name} overlayClassName={styles.popover}>
                {sliceText(task?.name, 16)}
              </Popover>
            ) : (
              task?.name
            )}
          </span>
        )}
      </div>
    );
  };

  const [renderColumn, setColumns] = useState<ColumnsType<WarehouseReservation>>([]);

  const columns: ColumnsType<WarehouseReservation> = [
    {
      title: `${t("warehouse.Resurs turi va nomi")}`,
      render: (record: ProductModel) => {
        const columnWidth = renderColumn?.find(column => column?.className === "product")?.width;

        return (
          <div className={cx("resource")}>
            <div
              style={{
                borderColor: record?.product?.resource?.color,
                color: record?.product?.resource?.color
              }}
            >
              {record?.product?.resource?.symbol[i18n.language]}
            </div>
            <PopoverShortName
              title={record?.product?.name[i18n.language]}
              length={((columnWidth as number) + 150) / 10}
            />
          </div>
        );
      },
      width: 450,
      fixed: "left",
      className: "product"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_ORDER} title={t("warehouse.Partiya")} />,
      render: record => (record?.order_id ? `P-${record?.order_id}` : "-"),
      width: 100,
      // key: "party",
      className: "party"
    },
    {
      width: 200,
      key: "work_name",
      render: sectionName,
      title: `${t("warehouse.Ish nomi va bo'limi")}`,
      className: "work_name"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_STATUS} title={t("warehouse.Holati")} />,
      render: (record: WarehouseReservation) => (
        <Statuses statusKey={record.status as StatusEnums} pageKey={PageKeys.ORDER} />
      ),
      width: 250,
      key: "status",
      className: "status"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_UNIT} title={t("warehouse.Birligi")} />,
      render: (record: ProductModel) => record?.unit?.symbol[i18n.language],
      key: "unit",
      width: 150,
      className: "unit"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_QUANTITY} title={t("warehouse.Soni")} />,
      render: (record: ProductModel) => record?.quantity?.toLocaleString(RU) ?? 0,
      key: "quantity",
      width: 150,
      className: "quantity"
    },
    {
      title: (
        <OrderSortBy queryParamKey={queryParamsKeys.SORT_DELIVERY_DATE} title={t("warehouse.Kerak bo'lish sanasi")} />
      ),
      render: (record: WarehouseReservation) => (
        <DateStatus status={record.status} delivery_date={record.delivery_date} />
      ),
      key: "delivery_date",
      width: 200,
      className: "delivery_date"
    },
    {
      key: "created_at",
      width: 170,
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_CREATED_AT} title={t("warehouse.Yaratilgan sana")} />,
      dataIndex: "created_at",
      className: "created_at"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_WAREHOUSE} title={t("warehouse.Omborxona")} />,
      render: (record: ProductModel) => <span className={styles.bold}>{record?.warehouse?.name}</span>,
      key: "warehouse.name",
      className: "warehouse",
      width: 200
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_PROJECT} title={t("warehouse.Loyiha")} />,
      render: (record: ProductModel) => (
        <div className={styles.project}>
          <div className={cx("project_name")}>{record?.project?.name}</div>
        </div>
      ),
      key: "project.name",
      width: 200,
      className: "project"
    },
    {
      title: `${t("warehouse.Xodim")}`,
      render: (_, record) =>
        record?.creator?.image ? (
          <Tooltip
            placement="top"
            className={styles.user}
            overlayClassName={styles.popover}
            title={record?.creator?.full_name}
          >
            <img src={record?.creator?.image} className={styles.user__image} alt={record?.creator?.full_name} />
          </Tooltip>
        ) : (
          <NotUserImage name={record?.creator?.full_name} isTooltip width={26} />
        ),
      key: "creator.full_name",
      width: 150,
      className: "creator"
    }
  ];

  const onOpenReservationModal = () => {
    dispatch(
      setOrderModal({
        visible: true,
        selectProducts: selectProducts as never[]
      })
    );

    setSelectProducts([]);
    setIsClickedRow(false);
  };

  const onAfterDeleteProducts = () => {
    setIsClickedRow(false);
    setSelectProducts([]);
  };

  const title = () => (
    <div className={styles.title}>
      <div className={styles.title__top}>
        <div className={styles.left}>
          {productActions.create && (
            <Button onClick={onOpenReservationModal}>
              {isClickedRow ? <CopyIcon /> : <PlusIcon />}
              {isClickedRow ? t("warehouse.Nusxa olish") : t("warehouse.Buyurtma berish")}
            </Button>
          )}
          {isClickedRow && (
            <ModalConfirm
              onOk={onDeleteProducts()}
              title={t("warehouse.Buyurtmalarni o'chirish")}
              onAfterClose={onAfterDeleteProducts}
            >
              <Button>
                <DeleteIcon /> {t("warehouse.O'chirish")}
              </Button>
            </ModalConfirm>
          )}
        </div>
        <div className={styles.line} />
        <div className={styles.right}>
          <PaymentSearch />
          <FilterDrawerWarehouseProduct
            customFieldEnum={CustomFieldLocationEnum.WAREHOUSE_PRODUCT}
            exportExcelMutate={exportExcel}
          />
        </div>
      </div>
      {isClickedRow && (
        <div className={styles.custom_header}>
          <div className={styles.custom_header__left}>
            <Checkbox onChange={onAllProductChange} checked={selectProducts.length > 0} />
            <span>
              {t("warehouse.Belgilandi")}: {selectProducts.length} {t("warehouse.ta")}
            </span>
          </div>
        </div>
      )}
    </div>
  );

  const onSelectRow = (value: WarehouseReservation) => {
    const someProduct = selectProducts?.some(item => item.id === value.id);
    let newSelectProducts = [...selectProducts];

    if (!someProduct) newSelectProducts.push(value);
    else {
      newSelectProducts = newSelectProducts?.filter(item => item.id !== value?.id);
    }
    setSelectProducts(newSelectProducts);
    setIsClickedRow(newSelectProducts.length > 0);
  };

  const onSelectedAll = (_: boolean, selectedRows: WarehouseReservation[]) => {
    setSelectProducts(selectedRows);
    setIsClickedRow(selectedRows.length > 0);
  };

  const rowSelection: TableRowSelection<WarehouseReservation> | undefined = {
    type: "checkbox",
    selectedRowKeys: selectProducts?.map(item => item.id),
    onSelect: onSelectRow,
    onSelectAll: onSelectedAll
  };

  const saveColumns = () => {
    const columnsToSave = renderColumn?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutate({
      key: tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<WarehouseReservation>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useEffect(() => {
    if (tableConfig && customFields) {
      setColumns([
        ...filterColumns({
          columns: columns as never,
          tableConfig,
          customFields
        }),
        {
          title: (
            <TableSettings
              isNeedSize
              defaultData={warehouseOrdersTableDefaultDataFunc(i18n.language)}
              configKey={tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG}
              locations={[CustomFieldLocationEnum.WAREHOUSE_PRODUCT]}
            />
          ),
          render: (record: WarehouseReservation) => (
            <Dropdown menu={menuItems(record)} trigger={["click"]}>
              <div className="flex cursor-pointer items-center justify-center">
                <DotsVerticalIcon />
              </div>
            </Dropdown>
          ),
          fixed: "right",
          width: 70,
          className: "action-dots"
        }
      ]);
    }
  }, [tableConfig, customFields]);

  return (
    <div className={styles.warehouse_product}>
      <div className={styles.top}>
        <Table<WarehouseReservation>
          title={title}
          // footer={footer}
          pagination={false}
          onChangeColumns={onChangeColumns}
          dataSource={data?.data}
          rowKey={row => row.id}
          showHeader={!isClickedRow}
          rowSelection={rowSelection}
          locale={{ emptyText: <TableEmpty /> }}
          loading={{ spinning: isLoading, indicator: LoadingIndicator }}
          scroll={{ y: "calc(100vh - 19.063rem)" }}
          columns={renderColumn}
        />
      </div>
      <div className={styles.bottom}>
        <Pagination
          paginationProps={{ total: data?.total, current: data?.current_page }}
          configKey={tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG}
        />
      </div>
      <OrderModal isWarehouse />
      <TaskProductsTemplate action={setReservationImportData} />
    </div>
  );
};

export default WarehouseProducts;
