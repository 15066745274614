import React from "react";
import BaseTextArea, { TextAreaProps } from "antd/lib/input/TextArea";
import { cx } from "features/app/utils/helpers/cx";

import Wrapper, { BaseProps } from "./wrapper";

interface InputProps extends BaseProps, TextAreaProps {
  name: string;
  loading?: boolean;
  type?: "number" | "text";
}

const Input: React.FC<InputProps> = ({
  label,
  rules,
  control,
  disabled,
  onChange,
  withLabel,
  name = "",
  labelClassName,
  errorClassName,
  withoutMessage,
  loading = false,
  wrapperClassName,
  ...args
}) => {
  label = label || (withLabel ? args.placeholder : null);

  return (
    <Wrapper
      {...{ control, name, label, labelClassName, withoutMessage, disabled, errorClassName, wrapperClassName, rules }}
    >
      {(field, error) => (
        <BaseTextArea
          {...field}
          {...args}
          id={name}
          value={field.value}
          status={error ? "error" : undefined}
          onChange={onChange || field.onChange}
          className={cx("input", args.className)}
          disabled={disabled || field.disabled || loading}
        />
      )}
    </Wrapper>
  );
};

export default Input;
