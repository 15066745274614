import { useDispatch } from "react-redux";

import { paymentReducerActions } from "store/reducers/paymentReducer";

import { RU } from "../../../../utils/constants/languages";
import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const PaymentTransferCreated = ({ record, onNavigatePage }: Props) => {
  const dispatch = useDispatch();
  const { setTransferHistoryModal } = paymentReducerActions;

  const onOpenPaymentView = () => {
    dispatch(
      setTransferHistoryModal({
        id: record?.payment?.id,
        isDeleted: record?.payment?.status === "deleted"
      } as never)
    );
  };

  return (
    <div onClick={onOpenPaymentView} className={styles.description}>
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.payment?.expense_cash?.name} sizga{" "}
        <span className={styles.name}>
          {record?.payment?.amount?.toLocaleString(RU)} {record?.payment?.currency?.symbol}
        </span>{" "}
        miqdorda pul mablag’i o’tkazdi.
      </p>
    </div>
  );
};

export default PaymentTransferCreated;
