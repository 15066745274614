import React from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";

import { useAuth } from "modules/auth/hooks";
import { localeFormatter } from "modules/common";
import { getPopupContainer } from "modules/party/create/helpers";

import { ConditionalRender, Input, PlusCircleIcon, Select, TrashIcon } from "components";

import { CompanyPersonForm } from "../../schema";

type Props = {
  control: Control<CompanyPersonForm>;
  setValue: UseFormSetValue<CompanyPersonForm>;
};

const Balance: React.FC<Props> = ({ control, setValue }) => {
  const { currencies } = useAuth();
  const { balances } = useWatch({ control });

  const secondCurrencyId = (currencyId?: number) => currencies?.find(({ id }) => id !== currencyId)?.id;

  const onAppend = () => {
    setValue(`balances.${1}`, {
      currency_id: secondCurrencyId(balances?.[0]?.currency_id),
      start_amount: undefined
    });
  };

  const onAmount = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;

    setValue(`balances.${index}.start_amount`, localeFormatter(value));
  };

  const onCurrency = (e: number, index: number) => {
    setValue(`balances.${index}.currency_id`, e);
    if (balances?.[1]) setValue(`balances.${index === 0 ? 1 : 0}.currency_id`, secondCurrencyId(e));
  };

  const onRemove = (index: number) => () => {
    setValue(
      "balances",
      balances?.filter((_, i) => i !== index)
    );
  };

  return (
    <div className="flex flex-col gap-1.5">
      <label>Kontragent balansi</label>
      {balances?.map((_, index) => (
        <div key={index} className="flex w-full items-center gap-2">
          <Input
            withLabel={false}
            control={control}
            placeholder="Summa"
            onChange={e => onAmount(e, index)}
            name={`balances.${index}.start_amount`}
          />
          <Select
            placeholder="UZS"
            control={control}
            withLabel={false}
            showSearch={false}
            wrapperClassName="w-[40%]"
            onChange={e => onCurrency(e, index)}
            getPopupContainer={getPopupContainer("create-company-person")}
            name={`balances.${index}.currency_id`}
            options={currencies?.map(currency => ({
              key: currency?.id,
              value: currency?.id,
              label: (
                <div className="flex items-center gap-1">
                  <img src={currency?.icon} className="h-6 w-6 object-contain" alt={currency?.symbol} />
                  <span>{currency?.symbol}</span>
                </div>
              )
            }))}
          />
          <ConditionalRender if={index > 0}>
            <div onClick={onRemove(index)} className="flex w-max cursor-pointer items-center justify-end">
              <TrashIcon />
            </div>
          </ConditionalRender>
        </div>
      ))}
      <ConditionalRender if={!balances?.[1]}>
        <div
          onClick={onAppend}
          className="flex w-max cursor-pointer items-center gap-2 text-sm font-medium text-primary-500"
        >
          <PlusCircleIcon />
          <span>Sarmoya</span>
        </div>
      </ConditionalRender>
    </div>
  );
};

export default Balance;
