import React from "react";

const LinkIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75 12.75H5.25C3.17893 12.75 1.5 11.0711 1.5 9C1.5 6.92893 3.17893 5.25 5.25 5.25H6.75M11.25 12.75H12.75C14.8211 12.75 16.5 11.0711 16.5 9C16.5 6.92893 14.8211 5.25 12.75 5.25H11.25M5.25 9L12.75 9"
      stroke="#1E90FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LinkIcon;
