import React from "react";

const CheckIcon: React.FC<{ width?: number; color?: string }> = ({ width, color }) => (
  <svg width={width ?? "20"} height={width ?? "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6663 5L7.49967 14.1667L3.33301 10"
      stroke={color ?? "#1E90FF"}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckIcon;
