import React from "react";
import { useDispatch } from "react-redux";
import { Spin, Tooltip } from "antd";
import PlusIcon from "features/app/assets/icons/PlusIcon";
import { StatusEnums } from "features/app/components/statuses/statusEnums";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { companyPersonActions } from "store/reducers/companyPersonReducer";

import { colors, cx, getFirstName, isEmptyObj, RU } from "modules/common";
import { partyQueryKeys, PartyView, useOfferReceived, useOfferRejected } from "modules/party/view";

import {
  CheckCircleIcon,
  ConditionalRender,
  CreditCardIcon,
  LoadingIndicator,
  ModalConfirm,
  PencilIcon,
  PhoneCallIcon,
  XCircleIcon
} from "components";

import RejectIcon from "../../../../../../assets/icons/RejectIcon";

import styles from "./content.module.scss";

type Props = {
  actions: { [key: string]: boolean };
  offers: PartyView["warehouse_products"][number]["warehouse_product_offers"] | undefined;
};

const Content: React.FC<Props> = ({ offers, actions }) => {
  const { t } = useTranslation();
  const { party_id } = useAppSelector(state => state.partyReducer.view);
  const received = useOfferReceived();
  const rejected = useOfferRejected();
  const dispatch = useDispatch();
  const { setVisibleModal } = companyPersonActions;

  const onReceived = (id: number) => () => {
    received.mutate(id);
  };

  const onOpenCounterpartCreateModal = (
    item: PartyView["warehouse_products"][number]["warehouse_product_offers"][number]
  ) => {
    dispatch(
      setVisibleModal({
        visible: true,
        initialData: {
          name: item.name!,
          phone: item.phone
        },
        offer_id: item.id,
        requestKey: [partyQueryKeys.VIEW, party_id] as never
      })
    );
  };

  const statusBackgroundColors = {
    [StatusEnums.RECIEVED]: "#12B76A",
    [StatusEnums.REJECTED]: "#F04438"
  };

  const isAnyOfOffersReceived = offers?.some(el => el?.status === StatusEnums.RECIEVED);

  return (
    <Spin spinning={received.isLoading} indicator={LoadingIndicator}>
      <div className="flex flex-col gap-3">
        {offers?.map(item => {
          const { id, name, company_person, amount, currency, vat, phone, payment_type, description, status } = item;

          return (
            <div key={id} className={cx("flex flex-col gap-2", styles.container)}>
              <div className="flex items-center justify-between gap-12">
                <div className="flex items-center gap-2">
                  <div className={styles.avatar_container}>
                    {isEmptyObj(company_person) && status === "recieved" && (
                      <Tooltip title={t("party.Kontragent qo'shish")}>
                        <div className={styles.plus} onClick={() => onOpenCounterpartCreateModal(item)}>
                          <PlusIcon color={colors.WHITE} />
                        </div>
                      </Tooltip>
                    )}
                    <div
                      className="flex h-9 w-9 items-center justify-center rounded-full bg-gray-200 text-sm font-medium text-white"
                      style={{
                        background: statusBackgroundColors[status as keyof typeof statusBackgroundColors]
                      }}
                    >
                      {getFirstName(name || company_person?.name)}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <h5 className="m-0 text-xs font-medium text-gray-900">{name || company_person?.name}</h5>
                    <div className="flex items-center gap-2">
                      <p className="m-0 flex items-center gap-2 text-xs font-medium text-gray-600">
                        <span>{amount?.toLocaleString(RU)}</span>
                        <span>{currency?.symbol}</span>
                      </p>
                      <ConditionalRender if={vat}>
                        <div className="rounded-md bg-primary-500 px-2 py-0.5 text-white">NDS</div>
                      </ConditionalRender>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <ConditionalRender if={actions?.rejectedOffer}>
                    <ModalConfirm
                      payload={{ id }}
                      cancelText="Ortga"
                      okText="Rad etish"
                      mutation={rejected}
                      isDescription={true}
                      title="Taklifni rad qilish"
                      requestKey="rejected_description"
                      description="Ushbu taklifni nega rad qilmoqchisiz ?"
                      placeholder="Sababni kiriting"
                      icon={<RejectIcon />}
                      isNotLabel={true}
                    >
                      <div
                        className={cx(
                          "flex cursor-pointer items-center rounded-md border border-solid border-gray-200 p-2",
                          {
                            "duration-300 ease-in-out hover:border-error-500": status !== "rejected",
                            "pointer-events-none": status === "rejected" || received.isLoading
                          }
                        )}
                      >
                        <XCircleIcon stroke={status === "rejected" ? colors.ERROR_500 : colors.GRAY_300} />
                      </div>
                    </ModalConfirm>
                  </ConditionalRender>
                  <ConditionalRender if={actions?.receivedOffer}>
                    <div
                      onClick={onReceived(id)}
                      className={cx(
                        "flex cursor-pointer items-center rounded-md border border-solid border-gray-200 p-2",
                        {
                          "duration-300 ease-in-out hover:border-success-500": status !== "recieved",
                          "pointer-events-none": status === "recieved" || rejected.isLoading || isAnyOfOffersReceived
                        }
                      )}
                    >
                      <CheckCircleIcon stroke={status === "recieved" ? colors.SUCCESS_500 : colors.GRAY_300} />
                    </div>
                  </ConditionalRender>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <ConditionalRender if={payment_type}>
                  <div className="flex items-center gap-2 text-xs font-medium text-gray-600">
                    <CreditCardIcon />
                    <span>{payment_type?.name}</span>
                  </div>
                </ConditionalRender>
                <ConditionalRender if={phone}>
                  <div className="flex items-center gap-2 text-xs font-medium text-gray-600">
                    <PhoneCallIcon />
                    <span>{phone}</span>
                  </div>
                </ConditionalRender>
                <ConditionalRender if={description}>
                  <div className="flex items-center gap-2 text-xs font-medium text-gray-600">
                    <PencilIcon />
                    <span>{description}</span>
                  </div>
                </ConditionalRender>
              </div>
            </div>
          );
        })}
      </div>
    </Spin>
  );
};

export default Content;
