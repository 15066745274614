import React from "react";

import { useAppSelector } from "hooks/redux";

import UysotBuilding from "./building";
import UysotCurrency from "./currency";
import UysotDate from "./date";
import UysotPaymentType from "./payment-type";

const content: Record<number, React.ReactNode> = {
  1: <UysotCurrency />,
  2: <UysotPaymentType />,
  3: <UysotBuilding />,
  4: <UysotDate />
};

const Left: React.FC = () => {
  const { step } = useAppSelector(({ uysot }) => uysot);

  return <div className="box-border h-full flex-1 overflow-y-auto bg-gray-50 p-5">{content[step]}</div>;
};

export default Left;
