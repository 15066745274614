import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PartyViewForm, useWarehouses } from "modules/party/view";

import { Select } from "components";

import styles from "./items.module.scss";

type Props = {
  form: UseFormReturn<PartyViewForm>;
};

const Warehouse: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const { warehouses } = useWarehouses();

  return (
    <Select
      withoutMessage
      withLabel={false}
      suffixIcon={null}
      variant="borderless"
      control={form.control}
      name="product.warehouse_id"
      className={styles.unit_select}
      placeholder={t("partyView.Tanlang")}
      options={warehouses?.map(warehouse => ({
        key: warehouse?.id,
        value: warehouse?.id,
        label: warehouse?.name as string,
        props: {
          name: warehouse?.name
        }
      }))}
    />
    // <Item
    //   name="warehouse_id"
    //   className="m-0 h-full w-full"
    //   rules={formRules()}
    //   initialValue={updateProduct?.warehouse?.id}
    // >
    //   <Select
    //     showSearch
    //     suffixIcon={null}
    //     variant="borderless"
    //     placeholder={t("partyView.Tanlang")}
    //     filterOption={filterOption}
    //     popupMatchSelectWidth={false}
    //     className={styles.unit_select}
    //   >
    //     {warehouses?.map(warehouse => (
    //       <Option key={warehouse?.id} value={warehouse?.id} props={{ name: warehouse?.name }}>
    //         {warehouse?.name as string}
    //       </Option>
    //     ))}
    //   </Select>
    // </Item>
  );
};

export default Warehouse;
