import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Switch, SwitchProps } from "antd";
import UnreadMessageAction from "features/app/components/unread-message/UnreadMessageAction";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { impostQueryKeys } from "features/impost/utils/constants/impostQueryKeys";
import { supplyQueryNames } from "features/supply/utils/constants/supplyQueryNames";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

import { useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";

import { chatActions, partyReducerActions } from "store/actions";

import { partyQueryKeys, PartyView } from "modules/party/view";

import { GitBranchIcon, PrinterIcon, TruckIcon } from "components";

import Print from "../print";

export const BaseItems = () => {
  const dispatch = useDispatch();
  const { setVisible } = chatActions;
  const { severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.TAB);
  const printRef = useRef<HTMLDivElement | null>(null);
  const { setPartyViewProcess, setPartyViewGroup } = partyReducerActions;
  const { isProcess, party_id, isGroup, type } = useAppSelector(state => state.partyReducer.view);
  const qc = useQueryClient();
  const { t } = useTranslation();

  const onSwitchProcess: SwitchProps["onChange"] = (checked, event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(setPartyViewProcess(checked));
  };
  const queryKey = [partyQueryKeys.VIEW, party_id as unknown as string];

  const viewData = qc.getQueryData(queryKey) as { party: PartyView };
  const outerDataQueryKeys: Record<typeof type, string[]> = {
    impost: [impostQueryKeys.PARTS, searchParams],
    supply: [supplyQueryNames.ORDERS, searchParams],
    payment: [""],
    warehouse: [""]
  };

  const onOpenChat = () => {
    dispatch(
      setVisible({
        visible: true,
        type: "orders",
        dataKeys: [queryKey, outerDataQueryKeys[type]],
        objectId: party_id,
        record: viewData?.party,
        dataType: "object"
      })
    );
  };

  const onGroup: SwitchProps["onChange"] = (checked, event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(setPartyViewGroup(checked));
  };

  const onPrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `P-${party_id}`,
    removeAfterPrint: true,
    onBeforePrint: () => {},
    onAfterPrint: () => {}
  });

  return [
    {
      key: "1",
      label: (
        <label htmlFor="is_party_group" className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <GitBranchIcon />
            {t("partyView.Guruhlash")}
          </div>
          <Switch id="is_party_group" checked={isGroup} onChange={onGroup} />
        </label>
      ),
      isView: true
    },
    {
      key: "7",
      label: (
        <label htmlFor="is_party_process" className="flex items-center gap-9">
          <div className="flex items-center gap-2">
            <TruckIcon />
            <span>{t("partyView.Partiya jarayoni")}</span>
          </div>
          <Switch id="is_party_process" checked={isProcess} onChange={onSwitchProcess} />
        </label>
      ),
      isView: true
    },
    {
      key: "2",
      label: (
        <>
          <div className="flex items-center gap-2">
            <PrinterIcon />
            <span>{t("partyView.Chop etish")}</span>
          </div>
          <Print ref={printRef} id={party_id} />
        </>
      ),
      onClick: onPrint,
      isView: true
    },
    {
      key: "3",
      label: <UnreadMessageAction count={viewData?.party?.unread_message_count} />,
      onClick: onOpenChat,
      isView: true
    }
  ];
};
