import React from "react";
import { useDispatch } from "react-redux";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

import { useAppSelector } from "hooks/redux";

import { setCheckedBuildingItem, UysotBuilding } from "modules/settings/uysot";

const HouseCheckbox: React.FC<UysotBuilding["buildings"][number]> = building => {
  const dispatch = useDispatch();
  const { id, cash, project, status, name } = building;
  const { checked_buildings } = useAppSelector(({ uysot }) => uysot);

  const onChange = (e: CheckboxChangeEvent) => {
    dispatch(
      setCheckedBuildingItem({
        building: {
          name,
          status,
          building_id: id,
          cash_id: cash?.id,
          project_id: project?.id
        },
        check: e.target.checked
      })
    );
  };

  const checked = checked_buildings.some(({ building_id }) => building_id === id);

  return <Checkbox checked={checked} onChange={onChange} />;
};

export default HouseCheckbox;
