import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import i18n from "i18next";

import { PartyResourceForm, ResourceSearch } from "modules/party/resource";

import CreateProduct from "pages/party/resource/content/top/create-product";

import { ConditionalRender, SmallFolderIcon } from "components";

type Props = {
  form: UseFormReturn<PartyResourceForm>;
};

const Index: React.FC<Props> = ({ form }) => {
  const { catalog, sub_catalog } = useWatch({ control: form.control });

  const onClickBreadCrumb = (type: "catalog" | "empty") => () => {
    if (type === "empty") {
      form.setValue("catalog", undefined);
      form.setValue("sub_catalog", undefined);
    }

    if (type === "catalog") {
      form.setValue("sub_catalog", undefined);
    }
  };

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center gap-2">
        <span className="cursor-pointer" onClick={onClickBreadCrumb("empty")}>
          <SmallFolderIcon />
        </span>
        <ConditionalRender if={catalog}>
          <div className="flex items-center gap-2">
            <span className="text-sm font-semibold text-gray-400">-</span>
            <div
              onClick={onClickBreadCrumb("catalog")}
              className="flex cursor-pointer items-center rounded-lg bg-gray-200 px-2 py-1 text-sm font-semibold text-gray-600"
            >
              {catalog?.name?.[i18n.language]}
            </div>
          </div>
        </ConditionalRender>
        <ConditionalRender if={sub_catalog}>
          <div className="flex items-center gap-2">
            <span className="text-sm font-semibold text-gray-400">-</span>
            <div className="flex items-center rounded-lg bg-gray-200 px-2 py-1 text-sm font-semibold text-gray-600">
              {sub_catalog?.name?.[i18n.language]}
            </div>
          </div>
        </ConditionalRender>
      </div>
      <div className="flex items-center gap-2">
        <ConditionalRender if={catalog || sub_catalog}>
          <CreateProduct parentForm={form} />
        </ConditionalRender>
        <ResourceSearch form={form} />
      </div>
    </div>
  );
};

export default Index;
