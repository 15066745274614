import React from "react";
import { useDispatch } from "react-redux";
import { Popover } from "antd";

import { appReducerActions, supplyActions } from "store/actions";

import { colors, cx, RU } from "modules/common";
import { PartyView } from "modules/party/view";

import { ArrowUpIcon, ConditionalRender, TrendDownIcon } from "components";

type Props = {
  item: PartyView["warehouse_products"][number];
};

const Amount: React.FC<Props> = ({ item }) => {
  const {
    market_price,
    market_price_currency,
    market_price_date,
    market_price_order_id,
    change_amount_percent,
    amount,
    product,
    unit
  } = item;
  const dispatch = useDispatch();
  const { setProductMarketPrice, setProductMarketPriceModal } = appReducerActions;
  const { setPartyModal } = supplyActions;
  const notFound = <div className="text-sm font-medium text-gray-400">Mavjud emas</div>;

  const onOpenMarketPrice = () => {
    dispatch(
      setProductMarketPrice({
        productId: product?.id,
        unitId: unit?.id,
        defaultProduct: structuredClone(product),
        defaultUnit: structuredClone(unit)
      })
    );

    dispatch(
      setProductMarketPriceModal({
        visible: true
      })
    );
  };

  const onOpenPartyView = () => {
    dispatch(
      setPartyModal({
        visible: true,
        isView: true,
        party_id: market_price_order_id,
      })
    );
  };

  return (
    <div className="flex h-full items-center">
      <Popover
        arrow={false}
        placement="top"
        content={
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-between gap-10">
              <span className="text-sm font-medium text-gray-500">Oxirgi kirim</span>
              <ConditionalRender if={market_price} else={notFound}>
                <div className="flex items-center gap-1">
                  <span className="text-sm font-semibold text-gray-700">{market_price?.toLocaleString(RU)}</span>
                  <span className="text-sm font-medium text-gray-400">{market_price_currency?.symbol}</span>
                </div>
              </ConditionalRender>
            </div>
            <div className="flex items-center justify-between gap-10">
              <span className="text-sm font-medium text-gray-500">Kirim sanasi</span>
              <ConditionalRender if={market_price_date} else={notFound}>
                <span className="text-sm font-semibold text-gray-700">{market_price_date}</span>
              </ConditionalRender>
            </div>
            <div className="flex items-center justify-between gap-10">
              <span className="text-sm font-medium text-gray-500">Partiya</span>
              <ConditionalRender if={market_price_order_id} else={notFound}>
                <span
                  className="cursor-pointer text-sm font-semibold text-primary-500 hover:underline"
                  onClick={onOpenPartyView}
                >
                  P-{market_price_order_id}
                </span>
              </ConditionalRender>
            </div>
            <div className="flex items-center justify-between gap-10">
              <span className="text-sm font-medium text-gray-500">Narx o'zgarishi</span>
              <div className="flex items-center gap-1">
                <div
                  className={cx("flex items-center gap-1 rounded-2xl px-2 py-0.5 text-sm font-semibold text-gray-500", {
                    "bg-success-50 text-success-500": change_amount_percent && change_amount_percent < 0,
                    "bg-error-50 text-error-500": change_amount_percent && change_amount_percent > 0,
                    "bg-gray-50 text-gray-500": !change_amount_percent
                  })}
                >
                  <ConditionalRender if={change_amount_percent}>
                    <ArrowUpIcon
                      rotate={change_amount_percent > 0 ? 180 : 0}
                      stroke={change_amount_percent > 0 ? colors.ERROR_500 : colors.SUCCESS_500}
                    />
                  </ConditionalRender>
                  <span>{Math.abs(change_amount_percent)} %</span>
                </div>
                <div
                  onClick={onOpenMarketPrice}
                  className="flex cursor-pointer items-center justify-center rounded-lg bg-gray-100 p-1"
                >
                  <TrendDownIcon />
                </div>
              </div>
            </div>
          </div>
        }
      >
        <div
          className={cx("cursor-pointer rounded-md px-2 py-1 text-sm font-medium", {
            "bg-success-50 text-success-500": change_amount_percent && change_amount_percent < 0,
            "bg-error-50 text-error-500": change_amount_percent && change_amount_percent > 0,
            "bg-gray-100 text-gray-600": !change_amount_percent
          })}
        >
          {amount?.toLocaleString(RU)}
        </div>
      </Popover>
    </div>
  );
};

export default Amount;
