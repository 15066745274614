import { PartyFormProduct, Total } from "modules/party/create";

export function vatAmount(products: PartyFormProduct[]) {
  const total: Total[] = [];

  products?.forEach(product => {
    if (product?.currency_id && product?.total && product?.vat_id) {
      const findCurrencyIndex = total?.findIndex(i => i?.currencyId === product?.currency_id);

      if (findCurrencyIndex < 0) {
        total.push({
          amount: product?.vat_amount || 0,
          currencyId: product?.currency_id || 0,
          currencySymbol: product?.currency_symbol || ""
        });
      } else {
        total[findCurrencyIndex] = {
          ...total[findCurrencyIndex],
          amount: (total[findCurrencyIndex]?.amount || 0) + (product?.vat_amount || 0)
        };
      }
    }
  });

  return total;
}
