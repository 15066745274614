import React from "react";
import { UseFormReturn } from "react-hook-form";
import { CustomFieldSelectModel } from "features/app/utils/models/customFieldSelectModel";
import i18n from "i18next";

import { PartyForm } from "modules/party/create";

import { Checkbox as BaseCheckbox } from "components";

type Props = {
  index: number;
  field: CustomFieldSelectModel;
  form: UseFormReturn<PartyForm>;
};

const Checkbox: React.FC<Props> = ({ form, index, field }) => {
  const { name } = field;

  return (
    <BaseCheckbox
      placeholder=""
      withoutMessage
      control={form.control}
      label={name?.[i18n.language]}
      name={`custom_field_values.${index}.value`}
    />
  );
};

export default Checkbox;
