import React from "react";
import { WorkStatusEnum } from "features/app/utils/enums/WorkStatusEnum";
import { useTranslation } from "react-i18next";

import { statusKeys } from "./status-keys";

import styles from "./index.module.scss";

type Props = {
  status: WorkStatusEnum;
};
const WorkStatuses: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  const statusName = statusKeys[status];

  return <div className={styles[status]}>{t(`works.${statusName}`)}</div>;
};

export default WorkStatuses;
