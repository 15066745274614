import React from "react";
import { useDispatch } from "react-redux";
import { UseFormReturn } from "react-hook-form";
import { Dropdown, DropdownProps } from "antd";
import { useTranslation } from "react-i18next";

import { AllSubmodules } from "hooks/useRoutePermissions";

import { partyReducerActions } from "store/actions";

import { isEmptyArr, localeFormatter } from "modules/common";
import { PartyViewForm, useDeleteProducts, WarehouseProduct } from "modules/party/view";

import { ConditionalRender, DotsVerticalIcon, EditIcon, ModalConfirm, TrashIcon } from "components";

import CreateOffer from "./create-offer";
import UpdateOffer from "./update-offer";

type Props = {
  item: WarehouseProduct;
  form: UseFormReturn<PartyViewForm>;
  actions: AllSubmodules["actions"][number];
};

const Index: React.FC<Props> = ({ item, actions, form }) => {
  const { id, amount, quantity, unit, total_amount, warehouse, project, company_person, vat, product, currency } = item;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deleteProduct = useDeleteProducts();
  const { setPartyViewUpdateProduct } = partyReducerActions;

  const onDelete = () => deleteProduct.mutateAsync([id]);

  const onUpdate = () => {
    dispatch(setPartyViewUpdateProduct(item));

    form.setValue("product", {
      id,
      vat_id: vat?.id,
      unit_id: unit?.id,
      product_id: product?.id,
      project_id: project?.id,
      currency_id: currency?.id,
      warehouse_id: warehouse?.id,
      company_person_id: company_person?.id,
      amount: localeFormatter(String(amount)),
      quantity: localeFormatter(String(quantity)),
      total_amount: localeFormatter(String(total_amount))
    });
  };

  const menu = (): DropdownProps["menu"] => {
    const items = [
      {
        key: "1",
        label: (
          <div className="flex items-center gap-2">
            <EditIcon />
            {t("partyView.Tahrirlash")}
          </div>
        ),
        onClick: onUpdate,
        view: actions?.updateProduct
      },
      {
        key: "2",
        label: <CreateOffer item={item} />,
        view: isEmptyArr(item?.warehouse_product_offers) && actions?.createOffer
      },
      {
        key: "3",
        label: <UpdateOffer item={item} />,
        view: !isEmptyArr(item?.warehouse_product_offers) && actions?.updateOffer
      },
      {
        key: "4",
        label: (
          <ModalConfirm title="Ushbu mahsulotni o'chirmoqchimisiz ?" onOk={onDelete}>
            <div className="flex items-center gap-2">
              <TrashIcon />
              {t("partyView.O'chirish")}
            </div>
          </ModalConfirm>
        ),
        view: !item?.recieved_quantity && actions?.deleteProduct
      }
    ];

    return {
      items: items?.filter(item => item?.view)
    };
  };

  return (
    <ConditionalRender if={!isEmptyArr(menu()?.items)}>
      <Dropdown trigger={["click"]} menu={menu()}>
        <div className="flex w-full cursor-pointer items-center justify-end">
          <DotsVerticalIcon />
        </div>
      </Dropdown>
    </ConditionalRender>
  );
};

export default Index;
