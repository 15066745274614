import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { cx } from "modules/common";
import { setCheckedBuildingsClear, setStep } from "modules/settings/uysot";

import styles from "./index.module.scss";

const Right: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { step } = useAppSelector(({ uysot }) => uysot);

  const onChange = (e: number) => () => {
    dispatch(setStep(e));
    dispatch(setCheckedBuildingsClear());
  };

  const items = [
    {
      id: 1,
      title: t("uysot.Valyutalarni sozlang"),
      description: t("uysot.To'lov valyutalarini mosligini tekshiring")
    },
    {
      id: 2,
      title: t("uysot.To'lov turlarini sozlang"),
      description: t("uysot.To'lov turlarini o'zaro bog'lang")
    },
    {
      id: 3,
      title: t("uysot.Loyihalarni sozlang"),
      description: t("uysot.Loyihalarni  o'zaro bog'lang va kassaga biriktiring")
    },
    {
      id: 4,
      title: t("uysot.Integratsiya jarayoni"),
      description: t("uysot.Integratsiya boshlanish sanasini kiriting")
    }
  ];

  return (
    <div className="box-border w-[28rem] border border-b-0 border-r-0 border-t-0 border-solid border-gray-200 bg-white px-4 py-6">
      <div className={styles.right}>
        {items.map(({ title, description, id }) => (
          <div
            key={id}
            className={cx(styles.right__container, {
              [styles.right__active]: step === id,
              [styles.right__waiting]: step < id,
              [styles.right__success]: step > id
            })}
            onClick={onChange(id)}
          >
            <div className={styles.right__container__icon}>
              <div className={styles.right__container__icon__step}>{id}</div>
              {id !== 4 && <div className={styles.right__container__icon__line} />}
            </div>
            <div className={styles.right__container__text}>
              <p>{title}</p>
              <span>{description}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Right;
