import React from "react";
import { Dropdown } from "antd";
import DotsVerticalIcon from "features/app/assets/icons/DotsVerticalIcon";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { useTranslation } from "react-i18next";

import { DownloadReportModal } from "components";
import { useExportProjectExcel } from "features/projects/service/mutations";
import { useSelector } from "store/index";

const Actions: React.FC = () => {
  const { t } = useTranslation();
  const exportExcel = useExportProjectExcel();
  const { data } = useSelector(state => state.projectReportModalReducer);

  const items = [
    {
      key: "1",
      label: (
        <DownloadReportModal
          mutation={exportExcel as never}
          payload={{
            id: data?.id,
            name: `${data?.name} hisoboti`
          }}
        >
          <div className="flex items-center gap-3">
            <DownloadIcon /> {t("projectReportModal.Yuklab olish")}
          </div>
        </DownloadReportModal>
      ),
      isView: true,
      permissionKey: ""
    }
  ];

  return (
    <Dropdown
      menu={{ items: items?.filter(el => el.isView) }}
      dropdownRender={(child: React.ReactNode) => <div onClick={e => e.stopPropagation()}>{child}</div>}
      trigger={["click"]}
    >
      <div className="flex cursor-pointer items-center justify-center rounded-lg border border-solid border-gray-200 bg-gray-50 px-2 py-[6px]">
        <DotsVerticalIcon />
      </div>
    </Dropdown>
  );
};

export default Actions;
