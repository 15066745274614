import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { CompletedWorkModel } from "features/projects/utils/models/completedWorkModel";

import { useQueryParams } from "hooks/useQueryParams";
import useGetTableSize from "hooks/useGetTableSize";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";

interface Query {
  taskProgress: PaginationType<CompletedWorkModel[]>;
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  id?: number;
}

export function useTaskProgressView({ enabled = false, id }: Params) {
  const initialValue: Query = { taskProgress: { data: [], total: 0, current_page: 1 } };

  let url = useGetTableSize({
    endpoint: "work/task-progress-view",
    tableConfigKey: tableConfigKeys.DONE_WORKS
  });

  const { queries, severalSearchParams } = useQueryParams();
  const { statistics_type } = queries();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE);

  if (id && !searchParams) {
    url += `&id=${id}`;
  }

  if (searchParams && searchParams?.length && !id) {
    url += `&${searchParams}`;
  }

  if (id && searchParams && searchParams?.length > 0) {
    url += `&id=${id}&${searchParams}`;
  }

  const { data = initialValue, ...args } = useQuery<Query>(
    ["taskProgress", searchParams, id],
    async () => {
      const {
        data: { data }
      } = await $api.get(url);

      return { taskProgress: data };
    },
    { enabled: enabled && statistics_type === "task_progress" }
  );

  return { ...data, ...args };
}
