import React from "react";

interface IProps {
  placement?: "left" | "right" | "top" | "bottom";
  stroke?: string;
}

const SelectSuffixIcon: React.FC<IProps> = props => {
  const { placement = "bottom" } = props;

  const placementRotate = {
    top: "rotate(180deg)",
    bottom: "",
    left: "rotate(90deg)",
    right: "rotate(-90deg)"
  };

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: placementRotate[placement], transition: "0.3s ease" }}
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke={props.stroke || "#667085"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SelectSuffixIcon;
