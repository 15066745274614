import React from "react";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { colors } from "features/app/utils/constants/colors";
import { SingleProductIncomeExpenseHistoryViewModel } from "features/app/utils/models/notificationModel";
import WorkModalTabs from "features/projects/components/detailed-project/work/modal/tabs/WorkModalTabs";
import DefectView from "features/warehouse/components/defect/defect-view/DefectView";
import LaboratoryDrawer from "features/warehouse/components/labaratory/laboratory-drawer/LaboratoryDrawer";
import TransferView from "features/warehouse/components/transfers/transfer-view/TransferView";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { defectViewActions } from "store/reducers/defectViewReducer";
import { laboratoryDrawerActions } from "store/reducers/laboratoryDrawerReducer";
import { partyReducerActions } from "store/reducers/partyReducer";
import { transferActions } from "store/reducers/transferReducer";

import { cx, RU, TooltipShortName } from "modules/common";

import { PartyView } from "pages/index";

import { LoadingIndicator } from "components";

import styles from "./bottom.module.scss";

type Props = {
  data: SingleProductIncomeExpenseHistoryViewModel[] | undefined;
  isLoading: boolean;
};

const Bottom: React.FC<Props> = ({ isLoading, data }) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [taskProgressId, setTaskProgressId] = React.useState<number | null>(null);
  const dispatch = useDispatch();
  const { product } = useAppSelector(state => state.warehouseProducts?.resource_history);

  const { setPartyView } = partyReducerActions;
  const { setOpenDrawer } = laboratoryDrawerActions;
  const { setVisibleView } = transferActions;
  const { setDefectView } = defectViewActions;

  const getSourceContent = (record: SingleProductIncomeExpenseHistoryViewModel) => {
    if (record?.order_id) return `P-${record?.order_id}`;
    if (record?.laboratory_id) return `L-${record?.laboratory_id}`;
    if (record?.warehouse_defect_id) return `D-${record?.warehouse_defect_id}`;
    if (record?.warehouse_transfer_id) return `T-${record?.warehouse_transfer_id}`;
    return `W-${record?.task_id}`;
  };

  const getQuantityStyle = (record: SingleProductIncomeExpenseHistoryViewModel) => {
    if (record?.type === "income")
      return {
        symbol: "+",
        className: "text-success-500"
      };
    return {
      symbol: "-",
      className: "text-error-500"
    };
  };

  const columns: ColumnsType<SingleProductIncomeExpenseHistoryViewModel> = [
    {
      title: t("warehouse.Miqdori"),
      render: (_value: any, record) => (
        <div className={getQuantityStyle(record)?.className}>
          {getQuantityStyle(record)?.symbol} {record?.total_initial_quantity?.toLocaleString(RU)}{" "}
          {product?.unit?.symbol[i18n.language]}
        </div>
      ),
      width: 250
    },
    {
      title: t("warehouse.Sana"),
      dataIndex: "created_date"
    },
    {
      title: t("warehouse.Manba"),
      render: (_value: any, record) => <div>{getSourceContent(record)}</div>,
      width: 120
    },
    {
      title: t("warehouse.Omborxona"),
      render: (_value: any, record) => (
        <div>
          <TooltipShortName title={record?.warehouse?.name} length={15} />
        </div>
      ),
      width: 250
    },
    {
      title: t("warehouse.Loyiha"),
      render: (_value: any, record) => (
        <div>
          <TooltipShortName title={record?.project?.name} length={15} />
        </div>
      ),
      width: 250
    },
    {
      title: "",
      render: (_value: any, record) => (
        <div className={styles.arrow}>
          <ArrowNarrowIcon rotate={180} stroke={colors.GRAY_500} />
        </div>
      ),
      width: 70
    }
  ];

  const onRow = (record: SingleProductIncomeExpenseHistoryViewModel) => ({
    onClick: () => {
      if (record?.order_id) {
        dispatch(
          setPartyView({
            visible: true,
            party_id: record?.order_id,
            status: "rejected",
            type: "supply"
          })
        );
      }
      if (record?.laboratory_id) {
        dispatch(
          setOpenDrawer({
            visible: true,
            id: record?.laboratory_id,
            isViewing: true
          })
        );
      }
      if (record?.warehouse_transfer_id) {
        dispatch(
          setVisibleView({
            visible: true,
            data: {
              ...record,
              id: record?.warehouse_transfer_id
            } as never
          })
        );
      }
      if (record?.task_id && record?.task_progress_id) {
        setOpen(true);
        setTaskProgressId(record?.task_progress_id);
      }
      if (record?.warehouse_defect_id) {
        dispatch(
          setDefectView({
            data: {
              ...record,
              id: record?.warehouse_defect_id
            } as never,
            visible: true
          })
        );
      }
    }
  });

  return (
    <div className="flex flex-col gap-2">
      <span className="text-sm font-medium text-gray-700">{t("warehouse.Kirim-chiqim ma'lumoti")}</span>
      <Table<SingleProductIncomeExpenseHistoryViewModel>
        columns={columns}
        dataSource={data}
        className={cx("footer_table")}
        rowClassName={styles.row}
        pagination={false}
        locale={{
          emptyText: <TableEmpty />
        }}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        onRow={onRow}
      />
      <PartyView />
      <LaboratoryDrawer />
      <TransferView />
      <WorkModalTabs open={open} setOpen={setOpen} progressId={taskProgressId!} />
      <DefectView />
    </div>
  );
};

export default Bottom;
