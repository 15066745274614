import React from "react";

const PackageIcon = () => (
  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.4999 14.5554L24.4999 23.9999M24.4999 23.9999L7.49993 14.5554M24.4999 23.9999L24.5 42.9999M42.5 32.117V15.8828C42.5 15.1975 42.5 14.8549 42.399 14.5493C42.3097 14.2789 42.1637 14.0308 41.9707 13.8214C41.7526 13.5847 41.4531 13.4183 40.8541 13.0855L26.0541 4.86329C25.4869 4.54817 25.2032 4.39062 24.9029 4.32884C24.6371 4.27417 24.3629 4.27417 24.0971 4.32884C23.7968 4.39062 23.5132 4.54817 22.9459 4.86329L8.14594 13.0855C7.54689 13.4183 7.24737 13.5847 7.02927 13.8214C6.83632 14.0308 6.69029 14.2789 6.60097 14.5493C6.5 14.8549 6.5 15.1975 6.5 15.8828V32.117C6.5 32.8023 6.5 33.145 6.60097 33.4506C6.69029 33.7209 6.83632 33.9691 7.02927 34.1785C7.24737 34.4151 7.5469 34.5815 8.14594 34.9143L22.9459 43.1366C23.5132 43.4517 23.7968 43.6092 24.0971 43.671C24.3629 43.7257 24.6371 43.7257 24.9029 43.671C25.2032 43.6092 25.4869 43.4517 26.0541 43.1366L40.8541 34.9143C41.4531 34.5815 41.7526 34.4151 41.9707 34.1785C42.1637 33.9691 42.3097 33.7209 42.399 33.4506C42.5 33.145 42.5 32.8023 42.5 32.117Z"
      stroke="#98A2B3"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M33.5 19L15.5 9" stroke="#98A2B3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default PackageIcon;
