import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { FieldErrors, useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { useAppSelector } from "hooks/redux";

import { supplyActions, supplyOfferActions } from "store/actions";
import { partyReducerActions } from "store/reducers/partyReducer";

import { errorHandler, ErrorRes, isEmptyArr, parseLocaledString, SuccessRes } from "modules/common";
import {
  parseGroupPayload,
  parsePayload,
  parseSelectProducts,
  PartyForm,
  partyFormGroupSchema,
  partyFormSchema,
  PartyReqPayload
} from "modules/party/create";
import { parseCustomFields } from "modules/party/create/helpers/parse-custom-fields";
import { successHandler } from "modules/party/create/helpers/success-handler";

type Props = {
  children: (form: UseFormReturn<PartyForm>) => ReactNode;
};

const Index: React.FC<Props> = ({ children }) => {
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const { setPartyCreate } = partyReducerActions;
  const { setClearSelectOffers } = supplyOfferActions;
  const { setSelectOrderProducts } = supplyActions;
  const { selectOrderProducts } = useAppSelector(({ supplyReducer }) => supplyReducer);
  const { isDiscount, isGroup, type } = useAppSelector(state => state.partyReducer.create);
  const { data: selectOfferProducts } = useAppSelector(state => state?.supplyOfferReducer?.selectOffers);

  const form = useForm<PartyForm>({
    resolver: zodResolver(
      isGroup ? partyFormGroupSchema : partyFormSchema.omit({ check_products: true, custom_field_values: true })
    ),
    defaultValues: {
      warehouse_products: parseSelectProducts(
        (() => {
          if (!isEmptyArr(selectOrderProducts)) {
            return { products: selectOrderProducts, type: "warehouse-product" };
          }

          if (!isEmptyArr(selectOfferProducts)) {
            return { products: selectOfferProducts, type: "offer" };
          }

          return { products: [], type: "empty" };
        })()
      ),
      status: type === "warehouse" ? "pending" : "ordered",
      company_person_group: [],
      check_products: [],
      agent_id: undefined,
      delivery_date: undefined,
      payment_date: undefined,
      custom_field_values: []
    },
    mode: "onChange",
    criteriaMode: "all"
  });

  const createParty = useMutation<SuccessRes, ErrorRes, PartyReqPayload>(
    async payload => {
      const { data } = await $api.post("supply/order-create", payload);

      return data;
    },
    {
      onError: errorHandler,
      onSuccess: successHandler(qc)
    }
  );

  const onSubmit = async (values: PartyForm) => {
    await new Promise(onSettled => {
      const payload: PartyReqPayload = {
        discount: isDiscount ? parseLocaledString(values?.discount || "") : undefined,
        discount_currency_id: isDiscount ? values?.discount_currency_id : undefined,
        agent_id: values?.agent_id,
        status: values?.status,
        delivery_date: values?.delivery_date,
        payment_date: values?.payment_date,
        warehouse_products: isGroup
          ? parseGroupPayload(values?.company_person_group)
          : parsePayload(values?.warehouse_products || []),
        custom_field_values: parseCustomFields(form.getValues("custom_field_values"))
      };

      createParty
        .mutateAsync(payload, {
          onSettled
        })
        .then(() => {
          dispatch(
            setPartyCreate({
              type,
              isVat: false,
              isGroup: false,
              visible: false,
              isDiscount: false
            })
          );

          dispatch(setClearSelectOffers());
          dispatch(setSelectOrderProducts([]));
        });
    });
  };

  const onError = (error: FieldErrors) => {
    console.log("Create Form ERROR", error);
    console.log("Create Form VALUES", form.getValues());

    if (!(isGroup && error.warehouse_products)) {
      return new Error(error.root?.message);
    }

    return null;
  };

  return (
    <form className="h-full" onSubmit={form.handleSubmit(onSubmit, onError)}>
      {children(form)}
    </form>
  );
};

export default Index;
