import React, { ReactNode, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Popover } from "antd";
import { ColumnsType } from "antd/es/table";
import { DateStatus } from "features/app/components/date-status/DateStatus";
import DateStatusWithPercent from "features/app/components/date-status-with-percent/DateStatusWithPercent";
import { LocalStorage } from "features/app/service/LocalStorage";
import { useChangeTableConfig } from "features/app/service/mutation";
import CornerUpLeftIcon from "features/projects/assets/icons/CornerUpLeftIcon";
import ClockFastForwardIcon from "features/supply/assets/icons/ClockFastForwardIcon";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { partyReducerActions } from "store/reducers/partyReducer";
import { paymentReducerActions } from "store/reducers/paymentReducer";

import useDebounce from "components/use-debounce/use-debounce";

import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { chatActions } from "../../../../../store/reducers/chatReducer";
import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import EditIcon from "../../../../app/assets/icons/EditIcon";
import ModalConfirm from "../../../../app/components/modal-confirm/ModalConfirm";
import OrderSortBy from "../../../../app/components/order-sort-by/OrderSortBy";
import { PageKeys } from "../../../../app/components/statuses/statusEnums";
import Statuses from "../../../../app/components/statuses/Statuses";
import TableSettings from "../../../../app/components/table-settings/TableSettings";
import TemplateDownload from "../../../../app/components/template-download/TemplateDownload";
import UnreadMessageAction from "../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import { useGetCustomFieldSelect, useGetTableConfigs } from "../../../../app/service/queries";
import { ImpostStatusIcons } from "../../../../app/utils/constants/importStatusIcons";
import { RU } from "../../../../app/utils/constants/languages";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "../../../../app/utils/constants/tableConfigKeys";
import { ChatEnum } from "../../../../app/utils/enums/chatEnum";
import { CustomFieldLocationEnum } from "../../../../app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "../../../../app/utils/helpers/filterColumns";
import PrinterIcon from "../../../../counterparts/assets/icons/PrinterIcon";
import { PaymentOrderModel } from "../../../../payment/utils/models/paymentOrderModel";
import EyeIcon from "../../../../projects/assets/icons/EyeIcon";
import { useDeletePart, useRefundPart } from "../../../service/mutations";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";
import { supplyQueryNames } from "../../../utils/constants/supplyQueryNames";
import { warehouseOrderTableDefaultData } from "../../../utils/constants/warehouseOrderTableDefaultData";
import { StatusEnums } from "../../../utils/enums/statusEnums";
import {
  orderPaymentGenerateStatus,
  orderPaymentPercentStatus,
  orderPaymentStatus
} from "../../../utils/helpers/orderPaymentFunc";
import { OrderModel } from "../../../utils/models/orderModel";

import styles from "./parts.module.scss";

type Props = {
  renderColumns: ColumnsType<OrderModel>;
  setColumns: React.Dispatch<React.SetStateAction<ColumnsType<OrderModel>>>;
};

const PartyColumns = ({ renderColumns, setColumns }: Props) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const { severalSearchParams } = useQueryParams();
  const { actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const partyActions = actions("Partiyalar");
  const { t } = useTranslation();
  const deleteParty = useDeletePart();
  const refundParty = useRefundPart();
  const { setVisible } = chatActions;
  const { setOrderDeleteArchive } = paymentReducerActions;
  const { setPartyView, setPartyUpdateVisible, setAdmissionHistoryModal } = partyReducerActions;

  const changeTableConfig = useChangeTableConfig(tableConfigKeys.ORDER_CONFIG, true);

  const { data: tableConfigData } = useGetTableConfigs(tableConfigKeys.ORDER_CONFIG, warehouseOrderTableDefaultData);

  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.ORDER]);

  const notTableElem = <div className="flex">-</div>;

  const paymentPopoverTitle = (amount: number, debtAmount: number, currency: string) => (
    <div className={styles.popover__payment}>
      <div className={styles.popover__item}>
        <p>{t("Supply.To’langan summa")}</p>
        <p>
          {(amount - debtAmount)?.toLocaleString(RU)} {currency}
        </p>
      </div>
      <div className={styles.popover__item}>
        <p>{t("Supply.Qoldiq")}</p>
        <p>
          {debtAmount?.toLocaleString(RU)} {currency}
        </p>
      </div>
    </div>
  );

  const totalAmount = (record: OrderModel) => {
    const generateStatus = orderPaymentGenerateStatus(record.amount, record.debt_amount);
    const amountText = `${record?.amount?.toLocaleString()} ${record?.currency?.symbol}`;

    return generateStatus === orderPaymentStatus.PARTLY ? (
      <Popover
        title={paymentPopoverTitle(record?.amount, record?.debt_amount, record?.currency?.symbol)}
        overlayClassName={styles.popover}
      >
        {amountText}
      </Popover>
    ) : (
      amountText
    );
  };

  const columns: ColumnsType<OrderModel> = [
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_ID} title={t("Supply.Partiya")} />,
      render: (record: OrderModel) => <span>P-{record?.id}</span>,
      fixed: "left",
      key: "id",
      width: 100,
      className: "supply-parts"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_DELIVERY_DATE} title={t("Supply.Yetkazilish sanasi")} />,
      render: (record: OrderModel) => <DateStatus delivery_date={record?.delivery_date} status={record?.status} />,
      key: "new_delivery_date",
      width: 150,
      className: "supply-delivery_date"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_PAYMENT_DATE} title={t("Supply.To'lov sanasi")} />,
      render: (record: OrderModel) => (
        <DateStatusWithPercent date={record?.payment_date} percent={record?.payment_percent || 0} />
      ),
      key: "new_payment_date",
      width: 170,
      className: "supply-payment_date"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_ORDERED_DATE} title={t("Supply.Buyurtma  sanasi")} />,
      render: (record: PaymentOrderModel) => record?.ordered_date,
      key: "new_ordered_date",
      width: 150
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_AGENT} title={t("Supply.Vositachi")} />,
      render: (record: OrderModel) => record?.agent?.full_name ?? notTableElem,
      key: "agent",
      width: 250,
      className: "supply-agent"
    },
    {
      title: `${t("Supply.Umumiy narxi")}`,
      render: (record: OrderModel) => totalAmount(record),
      key: "total_amount",
      width: 200,
      className: "supply-total_amount"
    },
    {
      title: `${t("Supply.Xarajat summa")}`,
      render: (_, record) => (record?.total_expense_amount || 0).toLocaleString(RU),
      key: "total_expense_amount",
      width: 120,
      className: "supply-total_expense_amount"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_PERCENT} title={t("Supply.Jarayon")} />,
      render: (record: OrderModel) => <>{record?.percent} %</>,
      key: "percent",
      width: 120,
      className: "supply-percent"
    },
    {
      title: `${t("Supply.Holati")}`,
      render: (record: OrderModel) => <Statuses statusKey={record?.status} pageKey={PageKeys.PARTY} />,
      key: "status",
      width: 170,
      className: "supply-status"
    },
    {
      title: <OrderSortBy title={t("Supply.To'lov holati")} queryParamKey={queryParamsKeys.SORT_PAYMENT_PERCENT} />,
      render: (record: OrderModel) => {
        const status = orderPaymentPercentStatus(record.payment_percent);

        return (
          <div className={`${styles.amount}`}>
            {status === orderPaymentStatus.PARTLY ? (
              <Popover
                title={paymentPopoverTitle(record?.amount, record?.debt_amount, record?.currency?.symbol)}
                overlayClassName={styles.popover}
              >
                {record?.payment_percent || 0} %
              </Popover>
            ) : (
              `${record?.payment_percent || 0} %`
            )}
          </div>
        );
      },
      key: "payment_status",
      width: 120,
      className: "supply-payment_status"
    },
    {
      title: <div className="flex justify-center">{t("Supply.Tasdiqlash")}</div>,
      render: (_, record) => (
        <div className="flex justify-center">{ImpostStatusIcons[record?.confirmation_payment]}</div>
      ),
      key: "confirmation_payment",
      width: 120,
      className: "supply-confirmation_payment"
    }
  ];

  const stopPropagation = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const dropdownRender = (originNode: ReactNode) => <div onClick={stopPropagation}>{originNode}</div>;

  const onOpenPartyModal = (record: OrderModel) => () => {
    dispatch(
      setPartyView({
        visible: true,
        type: "supply",
        party_id: record?.id,
        status: record?.status as never,
        isProcess: !!LocalStorage.get("is_party_process")
      })
    );
  };

  const onEdit = (party_id: number) => () => {
    dispatch(
      setPartyUpdateVisible({
        id: party_id,
        visible: true
      })
    );
  };

  const onOpenChat = (record: OrderModel) => () => {
    dispatch(
      setVisible({
        visible: true,
        type: ChatEnum.ORDERS,
        objectId: record?.id,
        dataKeys: [supplyQueryNames.ORDERS, severalSearchParams(queryParamsKeys.TAB)],
        record
      })
    );
  };

  const onDeleteParty = (partyId: number) => deleteParty.mutateAsync(partyId);
  const onRefundParty = (partyId: number) => () => refundParty.mutate(partyId);

  const onOpenOrderDeleteArchive = (orderId: number) => () => {
    dispatch(
      setOrderDeleteArchive({
        orderId,
        visible: true
      })
    );
  };

  const onOpenAdmissionHistory = (orderId: number) => () => {
    dispatch(
      setAdmissionHistoryModal({
        visible: true,
        orderId
      })
    );
  };

  const dropdownItems = (record: OrderModel) => {
    const items = [
      {
        key: "1",
        label: (
          <div className={styles.dropdown_item}>
            <EyeIcon /> {t("Supply.Ko'rish")}
          </div>
        ),
        onClick: onOpenPartyModal(record),
        permissionKey: "view",
        isView: true
      },
      {
        key: "3",
        label: (
          <div className={styles.dropdown_item}>
            <UnreadMessageAction count={record?.unread_message_count} />
          </div>
        ),
        onClick: onOpenChat(record),
        isView: true,
        permissionKey: "chatView"
      },
      {
        key: "4",
        label: (
          <div className={styles.dropdown_item}>
            <PrinterIcon /> {t("Supply.Chop etish")}
          </div>
        ),
        isView: true,
        permissionKey: "print"
      },
      {
        key: "6",
        label: (
          <div className={styles.dropdown_item}>
            <EditIcon /> {t("Supply.Tahrirlash")}
          </div>
        ),
        onClick: onEdit(record?.id),
        permissionKey: "edit",
        isView:
          record?.status === StatusEnums.OPEN ||
          record?.status === StatusEnums.ORDERED ||
          record?.status === StatusEnums.PENDING ||
          record?.status === StatusEnums.BUYING
      },
      {
        key: "7",
        label: (
          <ModalConfirm title="Ushbu partiyani o'chirmoqchimisiz ?" onOk={() => onDeleteParty(record.id)}>
            <div className={styles.dropdown_item}>
              <DeleteIcon /> {t("Supply.O'chirish")}
            </div>
          </ModalConfirm>
        ),
        permissionKey: "delete",
        isView: true
      },
      {
        key: "8",
        label: <TemplateDownload object_id={record.id} location={CustomFieldLocationEnum.ORDER} styles={styles} />,
        isView: true,
        permissionKey: "upload"
      },
      {
        key: "9",
        label: (
          <div className={styles.dropdown_item}>
            <CornerUpLeftIcon /> {t("Supply.Partiyani ochish")}
          </div>
        ),
        permissionKey: "open",
        onClick: onRefundParty(record?.id),
        isView: record?.status === StatusEnums.RECIEVED
      },
      {
        key: "10",
        label: (
          <div className={styles.dropdown_item}>
            <ClockFastForwardIcon /> {t("Supply.To'lovlar tarixi")}
          </div>
        ),
        permissionKey: "orderHistory",
        onClick: onOpenOrderDeleteArchive(record?.id),
        isView: true
      },
      {
        key: "11",
        label: (
          <div className={styles.dropdown_item}>
            <ClockFastForwardIcon />
            {t("Supply.Qabul tarixi")}
          </div>
        ),
        isView: true,
        permission: "admissionHistory",
        onClick: onOpenAdmissionHistory(record?.id)
      }
    ];

    return items?.filter(item => {
      if (item.permissionKey) return partyActions?.[item.permissionKey as never] && item.isView;
      return item.isView;
    });
  };

  const saveColumns = () => {
    const columnsToSave = renderColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutateAsync({
      key: tableConfigKeys.ORDER_CONFIG,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<OrderModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useEffect(() => {
    if (tableConfigData && customFields) {
      setColumns([
        ...filterColumns({
          columns: columns as never,
          customFields,
          tableConfig: tableConfigData
        }),
        {
          title: (
            <TableSettings
              isNeedSize
              configKey={tableConfigKeys.ORDER_CONFIG}
              locations={[CustomFieldLocationEnum.ORDER]}
              defaultData={warehouseOrderTableDefaultData}
            />
          ),
          render: (record: OrderModel) => (
            <Dropdown
              trigger={["click"]}
              dropdownRender={dropdownRender}
              menu={{
                items: dropdownItems(record!)
              }}
            >
              <UnreadMessageDots onClick={stopPropagation} count={record?.unread_message_count} />
            </Dropdown>
          ),
          className: "c_p",
          fixed: "right",
          width: 75
        }
      ]);
    }
  }, [tableConfigData, customFields]);

  return { columns: renderColumns, onChangeColumns };
};

export default PartyColumns;
