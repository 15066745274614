import React, { useEffect } from "react";
import { FieldErrors, useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { message } from "antd";

import { cx, isEmptyArr, isEmptyValue, User as UserType } from "modules/common";
import {
  useUpdateWidget,
  useWidget,
  WidgetSettingsForm,
  widgetSettingsSchema,
  widgetStaticData
} from "modules/dashboard";
import Statuses from "modules/dashboard/forms/settings/statuses";

import { UpdateWidget } from "../schema";

import CompanyPersonType from "./company-person-type";
import Content from "./content";
import Date from "./date";
import Limit from "./limit";
import Project from "./project";
import Segments from "./segments";
import User from "./user";

interface Props {
  children: (props: UseFormReturn<WidgetSettingsForm>) => React.ReactNode;
  users: UserType[];
  setUsers: React.Dispatch<React.SetStateAction<UserType[]>>;
}

const Settings = ({ children, setUsers, users }: Props) => {
  const {
    id,
    key,
    settingsProps,
    widget,
    settingsVisible,
    methods: { setSettings }
  } = useWidget();
  const { settings } = widgetStaticData[key];
  const {
    isNotLimit,
    isNotDate,
    isNotProject,
    form,
    isCompanyPersonType,
    isRequiredProject,
    segments,
    isPartyStatuses
  } = settings;

  const formInstance = useForm<WidgetSettingsForm>({
    resolver: zodResolver(widgetSettingsSchema),
    defaultValues: settingsProps
      ? { ...settingsProps, special_date: settingsProps?.min_date ? "special" : settingsProps?.special_date }
      : {}
  });

  const mutation = useUpdateWidget();

  const onSubmit = async (values: WidgetSettingsForm) => {
    const { special_date, max_date, min_date, ...arg } = Object.entries(values)
      .filter(([key, value]) => !isEmptyValue(value) && (key as keyof WidgetSettingsForm) !== "user_ids")
      .reduce((result: Record<string, string | number | string[] | number[] | null>, [key, value]) => {
        result[key] = value;
        return result;
      }, {});

    let filter = { ...arg };

    if (special_date && special_date !== "special") {
      filter = { ...filter, special_date };
    } else if (max_date || min_date) {
      filter = { ...arg, max_date, min_date };
    }

    let body: UpdateWidget = { filter, id, user_ids: values?.user_ids };

    if (widget?.chart_type === "calendar" && special_date && special_date !== "special") {
      if ((special_date as string)?.includes("month")) {
        body = {
          ...body,
          props: JSON.stringify({ ...JSON.parse(widget?.props!), w: 14, minW: 14, h: 30, minH: 30 })
        };
      } else {
        body = {
          ...body,
          props: JSON.stringify({ ...JSON.parse(widget?.props!), w: 28, minW: 28, h: 35, minH: 35 })
        };
      }
    }

    if (widget?.chart_type === "calendar" && !special_date && !min_date && !max_date) {
      body = {
        ...body,
        props: JSON.stringify({ ...JSON.parse(widget?.props!), w: 28, minW: 28, h: 35, minH: 35 })
      };
    }

    if (isRequiredProject && isEmptyValue(body?.filter?.project_id || body?.filter?.project_ids)) {
      message.warning("Loyiha tanlanishi zarur");
    } else {
      await new Promise(onSettled => {
        mutation.mutate(body, {
          onSettled,
          onSuccess: () => {
            setSettings({
              id,
              key,
              settingsVisible: false,
              visible: false
            });
          }
        });
      });
    }
  };

  const onError = (error: FieldErrors) => {
    console.log(error);
  };

  useEffect(() => {
    formInstance.reset(
      settingsProps
        ? { ...settingsProps, special_date: settingsProps?.min_date ? "special" : settingsProps?.special_date }
        : {}
    );
  }, [settingsVisible, formInstance, settingsProps]);

  return (
    <form className="flex flex-col gap-5" onSubmit={formInstance.handleSubmit(onSubmit, onError)}>
      {!isNotProject && <Project form={formInstance} />}
      {isCompanyPersonType && <CompanyPersonType form={formInstance} />}
      {isPartyStatuses && <Statuses form={formInstance} />}
      {form?.map(item => (
        <Content
          {...item}
          key={Math.random()}
          formInstance={formInstance}
          control={formInstance.control}
          name={item.name as keyof WidgetSettingsForm}
          forDisabled={item.forDisabled}
        />
      ))}
      {!isEmptyArr(segments) && <Segments form={formInstance} control={formInstance.control} />}
      {!isNotDate && (
        <div className="flex w-full items-center gap-5">
          <Date form={formInstance} rootClassName={cx(isNotLimit ? "flex-1" : "w-[22.188rem]")} />
          {!isNotLimit && <Limit form={formInstance} />}
        </div>
      )}
      <User form={formInstance} setUsers={setUsers} users={users} />
      {children(formInstance)}
    </form>
  );
};

export default Settings;
