import { localeFormatter } from "features/app/utils/helpers/localeFormatter";
import i18n from "i18next";
import { uid } from "uid";

import { PartyForm } from "modules/party/create";
import { PartyView } from "modules/party/view";

export function parseProductsToForm(products: PartyView["warehouse_products"]): PartyForm["warehouse_products"] {
  return products?.map(product => ({
    id: product?.id,
    product_id: product?.product?.id,
    unit_id: product?.unit?.id,
    unit_name: product?.unit?.symbol?.[i18n.language],
    quantity: localeFormatter(String(product?.quantity)),
    amount: localeFormatter(String(product?.amount)),
    rowId: uid(),
    warehouse_id: product?.warehouse?.id,
    project_id: product?.project?.id,
    vat_id: product?.vat?.id,
    vat_value: product?.vat?.value,
    vat_amount: ((product?.vat?.value || 0) / 100) * (product?.quantity * product?.amount),
    company_person_id: product?.company_person?.id,
    company_person_name: product?.company_person?.name,
    currency_id: product?.currency?.id,
    currency_symbol: product?.currency?.symbol,
    total: localeFormatter(String(product?.quantity * product?.amount))
  }));
}
