import { parseLocaledString } from "modules/common";
import { PartyFormProduct } from "modules/party/create";

export function totalUnits(products: PartyFormProduct[]) {
  return Object.entries(
    products?.reduce<Record<string, number>>((prev, current) => {
      const quantity = current?.quantity || "0";
      const symbol = current?.unit_name || "ta belgilanmagan";

      return { ...prev, [symbol]: (Number(prev[symbol]) || 0) + parseLocaledString(quantity) };
    }, {}) || {}
  );
}
