import React from "react";

const FeatureIcon: React.FC = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="2" width="32" height="32" rx="16" fill="#D1E9FF" />
    <rect x="2" y="2" width="32" height="32" rx="16" stroke="#EFF8FF" strokeWidth="4" />
    <path
      d="M19.3327 11.513V14.2666C19.3327 14.64 19.3327 14.8267 19.4053 14.9693C19.4693 15.0947 19.5712 15.1967 19.6967 15.2607C19.8393 15.3333 20.026 15.3333 20.3993 15.3333H23.153M23.3327 16.6588V21.4666C23.3327 22.5868 23.3327 23.1468 23.1147 23.5746C22.9229 23.951 22.617 24.2569 22.2407 24.4487C21.8128 24.6666 21.2528 24.6666 20.1327 24.6666H15.866C14.7459 24.6666 14.1859 24.6666 13.758 24.4487C13.3817 24.2569 13.0757 23.951 12.884 23.5746C12.666 23.1468 12.666 22.5868 12.666 21.4666V14.5333C12.666 13.4132 12.666 12.8532 12.884 12.4253C13.0757 12.049 13.3817 11.743 13.758 11.5513C14.1859 11.3333 14.7459 11.3333 15.866 11.3333H18.0072C18.4964 11.3333 18.741 11.3333 18.9711 11.3886C19.1752 11.4376 19.3703 11.5184 19.5493 11.628C19.7511 11.7517 19.924 11.9247 20.2699 12.2706L22.3954 14.3961C22.7413 14.742 22.9143 14.9149 23.038 15.1167C23.1476 15.2957 23.2284 15.4908 23.2774 15.6948C23.3327 15.925 23.3327 16.1696 23.3327 16.6588Z"
      stroke="#1E90FF"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FeatureIcon;
