import React from "react";
import { UseFormReturn } from "react-hook-form";
import ProductMarketPriceModal from "features/app/components/product-market-price-modal/ProductMarketPriceModal";
import ResourceInfoDrawer from "features/warehouse/components/warehouse/resource-info-drawer/ResourceInfoDrawer";

import { useAppSelector } from "hooks/redux";

import { PartyViewForm, usePartyView } from "modules/party/view";

import { Settings } from "../../other/settings";
import Right from "../../right";

import Wrapper from "./wrapper";

type Props = {
  form: UseFormReturn<PartyViewForm>;
};

const Index: React.FC<Props> = ({ form }) => {
  const { party_id, visible, status } = useAppSelector(({ partyReducer }) => partyReducer.view);
  const {
    party: { warehouse_products, agent, payment_date, delivery_date, custom_field_values },
    isLoading,
    isFetching
  } = usePartyView({ enabled: visible, id: party_id });

  return (
    <div className="flex h-full">
      <div className="box-border h-full flex-1 overflow-auto p-5">
        <Wrapper form={form} status={status} isLoading={isLoading || isFetching} products={warehouse_products} />
        <ProductMarketPriceModal />
        <ResourceInfoDrawer />
        <Settings type="impost" />
      </div>
      <Right
        agent={agent}
        paymentDate={payment_date}
        deliveryDate={delivery_date}
        products={warehouse_products}
        customFields={custom_field_values}
      />
    </div>
  );
};

export default Index;
