import { Badge } from "antd";
import { useTranslation } from "react-i18next";

import MessageTextSquareIcon from "../../../payment/assets/icons/MessageTextSquareIcon";

import styles from "./unreadMessage.module.scss";

type Props = {
  count?: number;
};

const UnreadMessageAction = ({ count }: Props) => {
  const { t } = useTranslation();
  
  return (
    <div className={styles.unread_message_action}>
      <div className={styles.left}>
        <MessageTextSquareIcon /> {t("unreadMessage.Chat")}
      </div>
      <Badge count={count} overflowCount={9} />
    </div>
  );
};

export default UnreadMessageAction;
