import React from "react";
import { Control } from "react-hook-form";
import { DefaultOptionType } from "antd/es/select";
import { useTranslation } from "react-i18next";

import { getPopupContainer } from "modules/party/create/helpers";

import { Select } from "components";

import { CompanyPersonForm } from "../../schema";

type Props = {
  control: Control<CompanyPersonForm>;
};

const Type: React.FC<Props> = ({ control }) => {
  const { t } = useTranslation();

  const options: DefaultOptionType[] = [
    {
      label: `${t("Counterparts.Ta'minotchi")}`,
      value: "supplier"
    },
    {
      label: `${t("Counterparts.Ishchi")}`,
      value: "worker"
    }
  ];

  return (
    <Select
      name="type"
      label="Turi"
      withoutMessage
      control={control}
      options={options}
      showSearch={false}
      placeholder="Ta'minotchi turi"
      getPopupContainer={getPopupContainer("create-company-person")}
    />
  );
};

export default Type;
