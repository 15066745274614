import React from "react";
import { UseFormReturn } from "react-hook-form";

import { PartyForm, RightTop } from "modules/party/create";

import CustomFields from "pages/party/create/content/right/custom-fields";
import Total from "pages/party/create/content/right/total";

type Props = {
  isUpdate?: boolean;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, isUpdate }) => (
  <div
    id={isUpdate ? "party-right-content-update" : "party-right-content"}
    className="box-border flex h-full w-[25rem] flex-col justify-between overflow-auto border border-b-0 border-r-0 border-t-0 border-solid border-gray-300 bg-white p-4 pb-0"
  >
    <div className="flex flex-col gap-4">
      <RightTop isUpdate={isUpdate} form={form} />
      <CustomFields isUpdate={isUpdate} form={form} />
    </div>
    <div className="sticky bottom-0 box-border flex flex-col gap-3 bg-white py-4">
      <Total form={form} />
    </div>
  </div>
);

export default Index;
