import React, { Dispatch, SetStateAction } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Spin } from "antd";
import PackageCheckIcon from "features/app/assets/icons/PackageCheckIcon";
import { percentAndDateMark } from "features/app/utils/helpers/percentAndDateMark";
import { percentAndDateStyle } from "features/app/utils/helpers/percentAndDateStyle";
import PaymentDatePicker from "features/payment/components/bottom/info/left/payment-date-picker/PaymentDatePicker";
import CustomWidgetTooltip from "features/projects/components/project-report-modal/product-spent-widget/custom-widget-tooltip/custom-widget-tooltip";
import { ProjectReportProductSpendModel } from "features/projects/utils/models/projectModel";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { colors, cx, RU, useAmountFixer } from "modules/common";

import { LoadingIndicator } from "components";

import CustomProgressBar from "../custom-progress-bar/custom-progress-bar";

import styles from "./product-spent-widget.module.scss";

type Props = {
  data: ProjectReportProductSpendModel | undefined;
  isLoading: boolean;
  baseCurrency: {
    symbol: string;
    id: number;
    amount: number;
    type: "base" | "second";
    icon: string;
    updated_at: number;
    name?: Record<string, string> | undefined;
  };
  setDateRange?: Dispatch<SetStateAction<{ min_date: string; max_date: string }>>;
  dateRange?: { min_date: string; max_date: string };
  isNotInProjects?: boolean;
  onOpenWidgetDrawer?: (id: string) => void;
};

const ProductSpentWidget: React.FC<Props> = ({
  baseCurrency,
  data: productSpendData,
  isLoading: isProductSpendDataLoading,
  setDateRange,
  dateRange,
  isNotInProjects,
  onOpenWidgetDrawer
}) => {
  const progressColors: Record<string, Record<string, string>> = {
    product: {
      factColor: colors.SUCCESS_500,
      predictColor: colors.SUCCESS_100,
      planColor: colors.SUCCESS_700
    },
    mechanism: {
      factColor: "#F63D68",
      predictColor: "#FFE4E8",
      planColor: "#C01048"
    },
    person: {
      factColor: "#4E5BA6",
      predictColor: "#d0d4e9",
      planColor: "#363F72"
    }
  };
  const amountFixer = useAmountFixer;
  const { t } = useTranslation();
  const chartData =
    productSpendData && productSpendData?.data?.length > 0
      ? productSpendData?.data?.map(el => ({
          id: el?.name[i18next.language],
          label: el?.name[i18next.language],
          value: el?.spend_amount > 0 ? el?.spend_amount : true,
          plan: el?.total_amount,
          fact_amount: el?.spend_amount,
          predict_amount: el?.prediction_amount,
          color: el?.type ? progressColors[el?.type]?.factColor : colors.GRAY_500,
          predict_color: el?.type ? progressColors[el?.type]?.predictColor : colors.GRAY_300,
          percent: el?.percent,
          plan_color: el?.type ? progressColors[el?.type]?.planColor : colors.GRAY_700,
          itemId: el?.id,
          itemType: el?.type
        }))
      : [
          {
            id: "",
            label: "",
            value: true,
            plan: 0,
            plan_color: "",
            fact_amount: 0,
            predict_amount: 0,
            color: colors.GRAY_500,
            predict_color: "",
            percent: 0,
            itemId: 0,
            itemType: ""
          }
        ];

  const chartColors = chartData?.map(el => el.color);

  return (
    <Spin spinning={isProductSpendDataLoading} indicator={LoadingIndicator}>
      <div
        className={cx("flex flex-col gap-5 rounded-xl", !isNotInProjects && "border border-solid border-gray-200 p-5")}
      >
        {!isNotInProjects && (
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <PackageCheckIcon />
              <span className="text-lg font-medium text-gray-700">{t("project.Mahsulot sarfi")}</span>
            </div>
            <PaymentDatePicker setDateRange={setDateRange} className={styles.datePicker} dateRange={dateRange} />
          </div>
        )}
        <div className="flex gap-4">
          <div className="flex h-[262px] w-[30%] flex-col gap-5">
            <div className="h-[68%]">
              <ResponsivePie
                data={chartData as never}
                fit={true}
                padAngle={0.5}
                cornerRadius={0}
                innerRadius={0.6}
                colors={chartColors}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                valueFormat={value => (typeof value === "number" ? value?.toLocaleString(RU) : "0")}
              />
            </div>
            <div className="flex flex-col items-center gap-2">
              <div className="flex items-center gap-1">
                <span className="text-2xl font-medium text-gray-800">
                  {useAmountFixer(String(productSpendData?.spend_amount))}
                </span>
                <span className="mt-[6.5px] text-sm font-normal text-gray-400">
                  {productSpendData?.currency?.symbol || baseCurrency?.symbol}
                </span>
              </div>
              <div className="flex items-center gap-1">
                <CustomWidgetTooltip
                  name={t("project.Loyiha qiymati")}
                  factAmount={productSpendData ? productSpendData?.spend_amount : 0}
                  planAmount={productSpendData ? productSpendData?.total_amount : 0}
                  predictionAmount={productSpendData ? productSpendData?.prediction_amount : 0}
                  factColor=""
                  planColor=""
                  predictColor=""
                >
                  <span
                    className={cx(
                      "w-max cursor-pointer rounded-2xl border border-solid px-[3px] py-[3px] text-xs font-medium",
                      percentAndDateStyle(Number(productSpendData?.percent))
                    )}
                  >
                    {percentAndDateMark(Number(productSpendData?.percent))}
                    {Math.abs(Number(productSpendData?.percent))}%
                  </span>
                </CustomWidgetTooltip>
                <span className="text-sm font-normal text-gray-400">{t("project.statik smetaga nisbatan")}</span>
              </div>
            </div>
          </div>
          <div className="flex w-4/5 flex-col gap-11">
            {chartData?.map(item => (
              <CustomWidgetTooltip
                key={item?.id}
                name={item?.label}
                factAmount={item?.fact_amount}
                planAmount={item?.plan}
                predictionAmount={item?.predict_amount}
                factColor={item?.color}
                planColor={item?.plan_color}
                predictColor={item?.predict_color}
              >
                <div
                  className="flex cursor-pointer flex-col"
                  onClick={() =>
                    onOpenWidgetDrawer
                      ? onOpenWidgetDrawer(`${item?.itemId}-${item?.itemType === null ? "another" : item?.itemType}`)
                      : {}
                  }
                  key={item?.id + item?.color}
                >
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center gap-1">
                      <span
                        className={cx("h-4 w-4 rounded")}
                        style={{ backgroundColor: item?.color, borderColor: item?.color }}
                      />
                      <span className="text-sm font-semibold text-gray-700">{item?.label}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span
                        className={cx(
                          "flex w-max items-center justify-center rounded-2xl border border-solid px-[3px] py-[3px] text-xs font-medium",
                          percentAndDateStyle(item?.percent)
                        )}
                      >
                        {percentAndDateMark(item?.percent)}
                        {Math.abs(item?.percent)}%
                      </span>
                      <span className="text-sm font-semibold text-gray-400">
                        {amountFixer(typeof item?.value === "number" ? String(item?.value) : "0")}{" "}
                        {productSpendData?.currency?.symbol || baseCurrency?.symbol}
                      </span>
                    </div>
                  </div>

                  <CustomProgressBar
                    color={item?.color}
                    fact={item?.fact_amount}
                    plan={item?.plan}
                    predictAmount={item?.predict_amount}
                    predictColor={item?.predict_color}
                    planColor={item?.plan_color}
                  />
                </div>
              </CustomWidgetTooltip>
            ))}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ProductSpentWidget;
