import { UseFormReturn } from "react-hook-form";
import { z } from "zod";

export const productFormSchema = z.object({
  name: z.string().min(3),
  unit_id: z.number(),
  resource_id: z.number(),
  code: z.string().optional(),
  category_id: z.number(),
  subcategory_id: z.number().optional()
});

export const productReqPayloadSchema = productFormSchema.omit({ name: true }).merge(
  z.object({
    name: z.record(z.string())
  })
);

export const languageSchema = z.object({
  id: z.number(),
  name: z.string(),
  status: z.string(),
  icon: z.string(),
  symbol: z.string()
});

export type ProductForm = z.infer<typeof productFormSchema>;
export type ProductReqPayload = z.infer<typeof productReqPayloadSchema>;
export type ProductFormControl = UseFormReturn<ProductForm>["control"];
export type Language = z.infer<typeof languageSchema>;
