import React, { useMemo } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { Checkbox } from "antd";

import { PartyEstimateForm, PartyEstimateProduct } from "modules/party/estimate";

type Props = {
  product: PartyEstimateProduct;
  form: UseFormReturn<PartyEstimateForm>;
};

const Index: React.FC<Props> = ({ product, form }) => {
  const { products } = useWatch({ control: form.control });

  // const onChange: CheckboxProps["onChange"] = e => {
  //   if (e.target.checked) {
  //     append({ ...product, taskId });
  //   } else {
  //     const index = products?.findIndex(p => p?.id === product?.id);

  //     remove(index);
  //   }
  // };

  const checked = useMemo(() => products?.some(p => p?.id === product?.id), [products]);

  return <Checkbox checked={checked} />;
};

export default Index;
