import { isEmptyArr } from "modules/common";

import { ProductWithUnit } from "../schema";

export function generateProducts(data: ProductWithUnit[], checkProducts?: ProductWithUnit[]) {
  let result: ProductWithUnit[] = [];

  if (!isEmptyArr(checkProducts)) {
    result = [...data];

    checkProducts?.forEach(product => {
      const isNotProduct = !result?.some(p => p?.id === product?.id);

      if (isNotProduct) {
        result.unshift(product);
      }
    });

    return result;
  }

  return data;
}
