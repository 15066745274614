import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { partyResourceQueryKeys, SubCatalog } from "modules/party/resource";

type Query = {
  subCatalogs: SubCatalog[];
};

type Props = {
  catalogId: number | undefined;
};

export function useSubCatalogs({ catalogId }: Props) {
  const initial = {
    subCatalogs: []
  };

  const { data = initial, ...arg } = useQuery<Query>(
    [partyResourceQueryKeys.SUB_CATALOGS, catalogId],
    async () => {
      const { data } = await $api.get(`category/view/subcategory?category_id=${catalogId}`);

      return { subCatalogs: data?.data };
    },
    {
      enabled: !!catalogId
    }
  );

  return { ...data, ...arg };
}
