import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import $api from "features/app/utils/helpers/axiosInstance";

import { useQueryParams } from "hooks/useQueryParams";

interface Query {
  worksStatistics: Record<string, number>;
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  projectId: number;
}

export function useWorksStatistics({ enabled = false, projectId }: Params) {
  const initialValue: Query = { worksStatistics: {} };
  let url = "work/statistics";

  const { severalSearchParams, queries } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.SIZE, queryParamsKeys.PAGE, queryParamsKeys.GROUP_BY);

  if (queries().statistics_type === "task" && projectId && !searchParams) {
    url += `?project_id=${projectId}`;
  }
  if (queries().statistics_type === "task" && projectId && searchParams && searchParams?.length > 0) {
    url += `?project_id=${projectId}&${searchParams}`;
  }
  if (queries().statistics_type !== "task" && projectId && searchParams && searchParams?.length > 0) {
    url += `?${searchParams}`;
  }

  if (searchParams && searchParams.length > 0 && !projectId) {
    url += `?${searchParams}`;
  }

  const { data = initialValue, ...args } = useQuery<Query>(
    ["works_statistics", searchParams, projectId],
    async () => {
      const {
        data: { data }
      } = await $api.get(url);

      return { worksStatistics: data };
    },
    { enabled: enabled && Boolean(queries().statistics_type) }
  );

  return { ...data, ...args };
}
