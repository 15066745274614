type RouteSubmodules = [
  {
    key: "Ishlar ro'yxati";
    actions: [];
  },
  {
    key: "Bajarilgan ishlar";
    actions: [];
  },
  {
    key: "Bajarilgan ishlar";
    actions: [];
  },
  {
    key: "Jarayon";
    actions: [];
  }
];

const routeSubmodules: RouteSubmodules = [
  {
    key: "Ishlar ro'yxati",
    actions: []
  },
  {
    key: "Bajarilgan ishlar",
    actions: []
  },
  {
    key: "Bajarilgan ishlar",
    actions: []
  },
  {
    key: "Jarayon",
    actions: []
  }
];

export default routeSubmodules;
