import React, { ReactNode } from "react";
import { UseFormReturn } from "react-hook-form";

import { useAppSelector } from "hooks/redux";

import { ConfirmationPaymentStatus, PartyStatus, PartyType, PartyView, PartyViewForm } from "modules/party/view";

import Impost from "./impost";
import Supply from "./supply";
import Warehouse from "./warehouse";

type Props = {
  party: PartyView;
  status: PartyStatus;
  form: UseFormReturn<PartyViewForm>;
  confirmationPayment: ConfirmationPaymentStatus;
};

const Index: React.FC<Props> = ({ status, form, confirmationPayment, party }) => {
  const { type } = useAppSelector(state => state.partyReducer.view);

  const contents: Record<PartyType, ReactNode> = {
    supply: <Supply status={status} party={party} />,
    impost: <Impost confirmationPayment={confirmationPayment} />,
    warehouse: <Warehouse form={form} />,
    payment: <></>
  };

  return (
    <div className="box-border flex w-full items-center border border-b-0 border-l-0 border-r-0 border-solid border-gray-300 bg-white p-3.5">
      {contents[type] || <></>}
    </div>
  );
};

export default Index;
