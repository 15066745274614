import React from "react";
import { useParams } from "react-router-dom";
import { parseParamsId } from "features/app/utils/helpers/parseParamsId";
import { parseToCrumbName } from "features/app/utils/helpers/parseToCrumbName";
import { WarehouseCategoryViewModel } from "features/warehouse/utils/models/warehouse-category-view-model";
import { useTranslation } from "react-i18next";
import { rootPaths } from "routes/root/rootPaths";

import Catalogs from "../catalogs";
import WarehouseTable from "../table/warehouse-table";

const DetailedCatalogs: React.FC = () => {
  const params = useParams();
  const { id, name } = parseParamsId(params?.id);
  const { i18n } = useTranslation();

  return (
    <div className="flex flex-col gap-5 p-4">
      <Catalogs
        storageKey="collapseWarehouseSubCatalogActive"
        key="collapseWarehouseSubCatalogActive"
        folderPath={(record: WarehouseCategoryViewModel) =>
          `${rootPaths.WAREHOUSE}/${name}-${id}/${parseToCrumbName(record?.name[i18n.language as keyof typeof record.name])}-${record?.id}`
        }
      />
      <WarehouseTable />
    </div>
  );
};

export default DetailedCatalogs;
