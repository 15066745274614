import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";
import { cx } from "modules/common";
import { WidgetProvider } from "modules/dashboard";

import Drawer from "./components/drawer/drawer";
import Modal from "./components/modal/modal";
import Settings from "./components/settings/settings";
import { DetailedWidgetDrawer } from "./components/widgets/detailed-drawer";
import { WidgetDrawer } from "./components/widgets/drawer";
import Widgets from "./components/widgets/widgets";

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <WidgetProvider>
      <div className="box-border flex flex-col gap-6 px-6 py-4">
        <div className="flex w-full justify-between">
          <div className="flex flex-col">
            <h1 className="m-0 text-2xl font-semibold text-gray-700">
              {t("dashboard.Salom")} {user?.full_name || "User"} 👋🏻
            </h1>
            <p className="m-0 text-sm font-normal text-gray-400">
              {t("dashboard.Xush kelibsiz! Keling ishga qaytamiz")}
            </p>
          </div>
          <Drawer />
        </div>
        <Widgets />
      </div>
      <WidgetDrawer />
      <DetailedWidgetDrawer />
      <Modal />
      <Settings />
    </WidgetProvider>
  );
};

export default Dashboard;
