import React, { useState } from "react";
import { useFieldArray, UseFormReturn, useWatch } from "react-hook-form";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import Pagination from "features/app/components/pagination/Pagination";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import i18n from "i18next";

import { cx, isEmptyArr } from "modules/common";
import { ProductWithUnit } from "modules/party/create";
import { CheckAll, CheckResource, PartyResourceForm, useResourceProducts } from "modules/party/resource";

import { ConditionalRender, LoadingIndicator } from "components";

type Props = {
  form: UseFormReturn<PartyResourceForm>;
};

const Index: React.FC<Props> = ({ form }) => {
  const [page, setPage] = useState(1);
  const { search, sub_catalog, catalog } = useWatch({ control: form.control });
  const { remove, append } = useFieldArray({ keyName: "unique", control: form.control, name: "products" });
  const { products, total, isLoading } = useResourceProducts({
    category_id: catalog?.id,
    subcategory_id: sub_catalog?.id,
    search,
    page
  });

  const columns: ColumnsType<ProductWithUnit> = [
    {
      title: <CheckAll form={form} tableProducts={products} />,
      render: (_, record) => (
        <ConditionalRender if={!isEmptyArr(products)} else={<span />}>
          <CheckResource form={form} record={record} append={append} remove={remove} />
        </ConditionalRender>
      ),
      width: 1
    },
    {
      title: "Resurs turi va nomi",
      render: (_, record) => (
        <div className="flex items-center gap-3">
          <div
            className="h-max rounded border border-solid px-2 py-0.5"
            style={{
              color: record?.resource?.color,
              borderColor: record?.resource?.color
            }}
          >
            {record?.resource?.symbol?.[i18n.language]}
          </div>
          <span className="w-full text-ellipsis text-sm font-medium text-gray-900">
            {record?.name?.[i18n.language]}
          </span>
        </div>
      )
    },
    {
      title: "Birligi",
      render: (_, record) => record?.unit?.symbol?.[i18n.language]
    },
    {
      title: "Kodi",
      dataIndex: "code",
      render: value => value || "--"
    }
  ];

  return (
    <Table<ProductWithUnit>
      rowKey="id"
      columns={columns}
      pagination={false}
      dataSource={products}
      className={cx("footer_table")}
      locale={{
        emptyText: <TableEmpty />
      }}
      loading={{
        spinning: isLoading,
        indicator: LoadingIndicator
      }}
      footer={() => (
        <Pagination
          paginationProps={{
            rootClassName: "p-4",
            total,
            current: page,
            onChange: e => setPage(e)
          }}
        />
      )}
    />
  );
};

export default Index;
