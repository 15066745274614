import React from "react";
import { useDispatch } from "react-redux";
import { UseFormReturn } from "react-hook-form";
import { Button } from "antd";
import { localeFormatter } from "features/app/utils/helpers/localeFormatter";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/reducers/partyReducer";

import { PartyViewForm, usePartyView } from "modules/party/view";

import Wrapper from "pages/party/view/content/items/warehouse/wrapper";
import { Settings } from "pages/party/view/content/other/settings";
import Right from "pages/party/view/content/right";

import { ConditionalRender, PuzzleIcon, XCloseIcon } from "components";

type Props = {
  form: UseFormReturn<PartyViewForm>;
};

const Warehouse: React.FC<Props> = ({ form }) => {
  const dispatch = useDispatch();
  const { setPartyViewAccept } = partyReducerActions;
  const { party_id, visible, isAccept } = useAppSelector(({ partyReducer }) => partyReducer.view);
  const {
    isLoading,
    isFetching,
    party: {
      agent,
      status,
      payment_date,
      delivery_date,
      warehouse_products,
      custom_field_values,
      company_person_payment_types
    }
  } = usePartyView({ enabled: visible, id: party_id });

  const onAccept = () => {
    dispatch(setPartyViewAccept(!isAccept));

    if (isAccept) form.clearErrors("warehouse_products");

    form.setValue(
      "warehouse_products",
      !isAccept
        ? warehouse_products?.map(({ id, quantity, recieved_quantity }) => ({
            id,
            quantity: recieved_quantity > quantity ? "0" : localeFormatter(String(quantity - recieved_quantity))
          }))
        : undefined
    );
  };

  const checkAccept = !(status === "rejected" || status === "recieved" || status === "pending" || status === "open");

  return (
    <div className="flex h-full">
      <div className="box-border flex flex-1 flex-col gap-3 overflow-auto p-5">
        <ConditionalRender if={checkAccept}>
          <Button className="w-max font-medium" onClick={onAccept} disabled={isLoading || isFetching}>
            {isAccept ? <XCloseIcon /> : <PuzzleIcon />}
            Qabul qilish
          </Button>
        </ConditionalRender>
        <Wrapper
          form={form}
          status={status}
          products={warehouse_products}
          isLoading={isLoading || isFetching}
          personPaymentTypes={company_person_payment_types}
        />
        <Settings type="warehouse" />
      </div>
      <Right
        agent={agent}
        paymentDate={payment_date}
        deliveryDate={delivery_date}
        products={warehouse_products}
        customFields={custom_field_values}
      />
    </div>
  );
};

export default Warehouse;
