import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dropdown } from "antd";
import { GetRowKey } from "antd/es/table/interface";
import { ItemType } from "antd/lib/menu/interface";
import { ColumnsType } from "antd/lib/table";
import { DateStatus } from "features/app/components/date-status/DateStatus";
import { useChangeTableConfig } from "features/app/service/mutation";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import Index from "features/warehouse/components/orders/warehouse-party-view";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { chatActions } from "store/reducers/chatReducer";
import { partyReducerActions } from "store/reducers/partyReducer";

import { PartyCreate, PartyUpdate, PartyView } from "pages/index";
import AdmissionHistory from "pages/party/admission-history";

import { ClockFastForwardIcon, Table } from "components";
import useDebounce from "components/use-debounce/use-debounce";

import DeleteIcon from "../../../app/assets/icons/DeleteIcon";
import EditIcon from "../../../app/assets/icons/EditIcon";
import PlusIcon from "../../../app/assets/icons/PlusIcon";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../app/components/modal-confirm/ModalConfirm";
import Pagination from "../../../app/components/pagination/Pagination";
import { PageKeys } from "../../../app/components/statuses/statusEnums";
import Statuses from "../../../app/components/statuses/Statuses";
import TableEmpty from "../../../app/components/table-empty/TableEmpty";
import TableSettings from "../../../app/components/table-settings/TableSettings";
import TemplateDownload from "../../../app/components/template-download/TemplateDownload";
import UnreadMessageAction from "../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../app/components/unread-message/UnreadMessageDots";
import { useGetCustomFieldSelect, useGetTableConfigs } from "../../../app/service/queries";
import { ImpostStatusIcons } from "../../../app/utils/constants/importStatusIcons";
import { tableConfigKeys } from "../../../app/utils/constants/tableConfigKeys";
import { ChatEnum } from "../../../app/utils/enums/chatEnum";
import { CustomFieldLocationEnum } from "../../../app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "../../../app/utils/helpers/filterColumns";
import { GetComponentProps } from "../../../app/utils/models/getComponentPropsType";
import PaymentSearch from "../../../payment/components/top/search/PaymentSearch";
import PartyModal from "../../../supply/components/parts/party-modal/PartyModal";
import { useDeleteOrder } from "../../service/mutation";
import { useGetOrders } from "../../service/query";
import { OrderEnum } from "../../utils/constants/OrderEnum";
import { orderTableDefaultData } from "../../utils/constants/orderTableDefaultData";
import { routeSubmodules } from "../../utils/constants/routeSubmodules";
import { warehouseQueryNames } from "../../utils/constants/warehouseQueryNames";
import { WarehouseOrderModel } from "../../utils/models/warehouseOrderModel";

import AdditionalModal from "./additional-modal/AdditionalModal";
import FilterDrawerOrders from "./filter-drawer/FilterDrawerOrders";

import styles from "./orders.module.scss";

const Orders: React.FC = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const { severalSearchParams } = useQueryParams();
  const { t } = useTranslation();
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const orderActions = actions("Partiyalar");
  const { data, isLoading } = useGetOrders();
  const deleteOrder = useDeleteOrder();
  const { setVisible } = chatActions;
  const { setPartyCreateVisible, setPartyUpdateVisible, setPartyView, setAdmissionHistoryModal } = partyReducerActions;

  const onOpenChat = (record: WarehouseOrderModel) => {
    dispatch(
      setVisible({
        visible: true,
        type: ChatEnum.ORDERS,
        objectId: record?.id,
        dataKeys: [warehouseQueryNames.TRANSFER_VIEW, severalSearchParams(queryParamsKeys.TAB)],
        record
      })
    );
  };

  const onDeleteParty = (id: number) => deleteOrder.mutateAsync(id);

  const onUpdateParty = (id: number) => () => {
    dispatch(
      setPartyUpdateVisible({
        id,
        visible: true
      })
    );
  };

  const onOpenAdmissionHistory = (orderId: number) => () => {
    dispatch(
      setAdmissionHistoryModal({
        visible: true,
        orderId
      })
    );
  };

  const dropdownItems = (record: WarehouseOrderModel): ItemType[] => {
    const items: ItemType[] = [
      {
        key: "6",
        label: (
          <div className={styles.dropdown_item} onClick={onOpenAdmissionHistory(record?.id)}>
            <ClockFastForwardIcon />
            {t("Supply.Qabul tarixi")}
          </div>
        ),
        onClick: e => {
          e.domEvent.stopPropagation();
        }
      }
    ];

    if (orderActions?.chatView) {
      items?.push(
        {
          key: "1",
          label: (
            <div className={styles.dropdown_item} onClick={() => onOpenChat(record)}>
              <UnreadMessageAction count={record?.unread_message_count} />
            </div>
          ),
          onClick: e => {
            e.domEvent.stopPropagation();
          }
        },
        {
          key: "4",
          label: <TemplateDownload object_id={record.id} location={CustomFieldLocationEnum.ORDER} styles={styles} />
        }
      );
    }

    if (record?.status === OrderEnum.PENDING && orderActions?.update) {
      items.push({
        key: "2",
        label: (
          <div className={styles.dropdown_item} onClick={onUpdateParty(record.id)}>
            <EditIcon /> {t("warehouse.Tahrirlash")}
          </div>
        ),
        onClick: e => {
          e.domEvent.stopPropagation();
        }
      });
    }

    if (record?.status === OrderEnum.PENDING && orderActions?.delete) {
      items.push({
        key: "3",
        label: (
          <ModalConfirm onOk={() => onDeleteParty(record.id)} title="Partiyani o'chirmoqchisiz ?">
            <div className={styles.dropdown_item}>
              <DeleteIcon /> {t("warehouse.O'chirish")}
            </div>
          </ModalConfirm>
        ),
        onClick: e => {
          e.domEvent.stopPropagation();
        }
      });
    }

    return items as ItemType[];
  };

  const columns: ColumnsType<WarehouseOrderModel> = [
    {
      title: `${t("warehouse.Partiya")}`,
      dataIndex: "id",
      render: value => `P-${value}`,
      width: 100,
      className: "party"
    },
    {
      title: `${t("warehouse.Buyurtma sanasi")}`,
      render: (record: WarehouseOrderModel) => record?.ordered_date ?? "-",
      key: "new_ordered_date",
      width: 200,
      className: "ordered_date"
    },
    {
      title: `${t("warehouse.Yetkazilish sanasi")}`,
      render: (record: WarehouseOrderModel) => (
        <DateStatus delivery_date={record?.delivery_date} status={record?.status} />
      ),
      key: "delivery_date",
      width: 200,
      className: "delivery_date"
    },
    {
      title: `${t("warehouse.To'lov sanasi")}`,
      render: (record: WarehouseOrderModel) => (
        <DateStatus delivery_date={record?.payment_date} status={record?.status} />
      ),
      key: "payment_date",
      width: 200,
      className: "payment_date"
    },
    {
      title: `${t("warehouse.Vositachi")}`,
      render: (record: WarehouseOrderModel) => record?.agent?.full_name || "-",
      key: "agent",
      width: 200,
      className: "agent"
    },
    {
      title: `${t("warehouse.Jarayon")}`,
      render: (_, record) => (
        <div className={styles.percent}>
          <p>{record?.percent}%</p>
          <div className={styles.percent__progress}>
            <div style={{ width: `${record?.percent <= 100 ? record?.percent : 100}%` }} />
            <div />
          </div>
        </div>
      ),
      width: 200,
      key: "process",
      className: "process"
    },
    {
      title: `${t("warehouse.Holati")}`,
      render: record => <Statuses statusKey={record?.status} pageKey={PageKeys.PARTY} />,
      key: "status",
      width: 200,
      className: "status"
    },
    {
      title: <div className="flex justify-center">{t("warehouse.Tasdiqlash")}</div>,
      render: (_, record) => (
        <div className="flex justify-center">{ImpostStatusIcons[record?.confirmation_payment]}</div>
      ),
      key: "confirmation_payment",
      width: 100,
      className: "confirmation_payment"
    }
  ];

  const onCreateParty = () => {
    dispatch(
      setPartyCreateVisible({
        visible: true,
        type: "warehouse"
      })
    );
  };

  const title = () => (
    <div className={styles.title}>
      {orderActions?.create && (
        <Button onClick={onCreateParty}>
          <PlusIcon /> {t("warehouse.Partiya yaratish")}
        </Button>
      )}
      <div className={styles.title__line} />
      <PaymentSearch />
      <FilterDrawerOrders />
    </div>
  );

  const onRow: GetComponentProps<WarehouseOrderModel> | undefined = record => ({
    onClick: () => {
      orderActions?.create &&
        dispatch(
          setPartyView({
            visible: true,
            type: "warehouse",
            status: record?.status,
            party_id: record?.id,
            unread_message_count: record?.unread_message_count
          })
        );
    }
  });

  const rowKey: string | GetRowKey<WarehouseOrderModel> | undefined = row => row.id;
  const [renderColumns, setColumns] = useState<ColumnsType<WarehouseOrderModel>>([]);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.ORDER]);
  const changeTableConfig = useChangeTableConfig(tableConfigKeys.WAREHOUSE_ORDER_CONFIG, true);
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.WAREHOUSE_ORDER_CONFIG, orderTableDefaultData);

  const saveColumns = () => {
    const columnsToSave = renderColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutate({
      key: tableConfigKeys.WAREHOUSE_ORDER_CONFIG,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<WarehouseOrderModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useEffect(() => {
    if (tableConfig?.column?.length && tableConfig?.column?.length > 0 && customFields) {
      setColumns([
        ...filterColumns({
          columns: columns as any,
          tableConfig,
          customFields
        }),
        {
          title: (
            <TableSettings
              defaultData={orderTableDefaultData}
              configKey={tableConfigKeys.WAREHOUSE_ORDER_CONFIG}
              locations={[CustomFieldLocationEnum.ORDER]}
              isNeedSize
            />
          ),
          render: (record: WarehouseOrderModel) => (
            <Dropdown trigger={["click"]} menu={{ items: dropdownItems(record)! }} placement="bottom">
              <UnreadMessageDots count={record?.unread_message_count} onClick={e => e.stopPropagation()} />
            </Dropdown>
          ),
          width: 70,
          fixed: "right",
          className: "action-dots"
        }
      ]);
    }
  }, [tableConfig, customFields]);

  return (
    <div className={styles.order_table}>
      <div className={styles.top}>
        <Table<WarehouseOrderModel>
          title={title}
          onRow={onRow}
          rowKey={rowKey}
          pagination={false}
          onChangeColumns={onChangeColumns}
          dataSource={data?.data}
          columns={renderColumns as ColumnsType<WarehouseOrderModel>}
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{
            spinning: isLoading,
            indicator: LoadingIndicator
          }}
          scroll={{ y: "calc(100vh - 315px)" }}
        />
      </div>
      <div className={styles.bottom}>
        <Pagination
          paginationProps={{
            current: data?.current_page,
            total: data?.total
          }}
          configKey={tableConfigKeys.WAREHOUSE_ORDER_CONFIG}
        />
      </div>
      <PartyModal isWarehouse />
      <PartyCreate />
      <PartyUpdate />
      <PartyView />
      <Index />
      <AdditionalModal />
      <AdmissionHistory />
    </div>
  );
};

export default Orders;
