import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { Resource } from "modules/common";
import { partyResourceQueryKeys } from "modules/party/resource";

type Query = {
  resources: Resource[];
};

export function useResources() {
  const initial = {
    resources: []
  };

  const { data = initial, ...arg } = useQuery<Query>([partyResourceQueryKeys.ALL_RESOURCES], async () => {
    const { data } = await $api.get("resource/select");

    return { resources: data?.data };
  });

  return { ...data, ...arg };
}
