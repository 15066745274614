import WorksTabEnum from "./worksTabEnums";

const tabItems = (children: React.ReactNode) => [
  {
    key: WorksTabEnum.LIST_OF_WORKS,
    label: "Ishlar ro'yxati",
    children,
    permissionKey: "Ishlar ro'yxati"
  },
  {
    key: WorksTabEnum.PROGRESSES,
    label: "Progreslar",
    children,
    permissionKey: "Bajarilgan ishlar"
  },
  // {
  //   key: WorksTabEnums.SPENT_OF_RESOURCES,
  //   label: "Resurslar sarfi",
  //   children,
  //   permissionKey: ""
  // }
  {
    key: WorksTabEnum.PROCESS,
    label: "Jarayon",
    children,
    permissionKey: "Jarayon"
  }
];

export default tabItems;
