import React, { useState } from "react";
import { Collapse, Spin } from "antd";
import { CollapseProps } from "antd/lib";
import { colors } from "features/app/utils/constants/colors";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";

import { useQueryParams } from "hooks/useQueryParams";

import { rootPaths } from "../../../../../../routes/root/rootPaths";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import FolderCard from "../../../../../app/components/folder-card/FolderCard";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { parseToCrumbName } from "../../../../../app/utils/helpers/parseToCrumbName";
import {
  useCreateCounterpartsFolder,
  useDeleteCounterpartsFolder,
  useUpdateCounterpartsFolder
} from "../../../../service/mutation";
import { CounterpartsCompanyPersonModel } from "../../../../utils/models/counterpartsCompanyPersonModel";
import { CounterpartsFolderModel } from "../../../../utils/models/counterpartsFolderModel";

import CounterpartsNewFolder from "./folder/CounterpartsNewFolder";

import styles from "./counterpartsFolders.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  isLoading: boolean;
  isCreating: boolean;
  folderActions: Record<string, boolean>;
  folders: CounterpartsFolderModel[] | undefined;
  persons: CounterpartsCompanyPersonModel[] | undefined;
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
};

const CounterpartsFolders: React.FC<Props> = ({
  folders,
  persons,
  isLoading,
  isCreating,
  folderActions,
  setIsCreating
}) => {
  const createFolder = useCreateCounterpartsFolder();
  const updateFolder = useUpdateCounterpartsFolder();
  const deleteFolder = useDeleteCounterpartsFolder();
  const { t } = useTranslation();

  const collapseActive = localStorage.getItem("collapseCounterpartsActive");

  const { searchParams, reqQueryParam } = useQueryParams();

  const folderPath = (item: CounterpartsFolderModel): string => {
    let path = `${rootPaths.COUNTERPARTS}/${parseToCrumbName(item.name)}-${item.id}`;

    if (searchParams && searchParams?.length > 0) {
      path += `${searchParams}`;
    }
    return path;
  };

  const collapseChildren = (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.folders}>
        <ConditionalRender if={!isEmptyArr(folders) && folderActions.view}>
          {folders?.map(item => (
            <FolderCard
              id={item.id}
              key={item.id}
              name={item.name}
              color={item.color}
              path={folderPath(item)}
              updateMutation={updateFolder}
              deleteMutation={deleteFolder}
              count={item.company_persons_count}
            />
          ))}
        </ConditionalRender>
        <ConditionalRender if={isCreating || createFolder.isLoading}>
          <FolderCard isCreating={isCreating} setIsCreating={setIsCreating} createMutation={createFolder} />
        </ConditionalRender>
        <ConditionalRender if={!isCreating}>
          <CounterpartsNewFolder setIsCreating={setIsCreating} />
        </ConditionalRender>
      </div>
    </Spin>
  );

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <h4 className={styles.folders__title}>{t("Counterparts.Papkalar")}</h4>,
      children: collapseChildren
    }
  ];

  const onCollapseChange = (e: string | string[]) => {
    if (e.length === 1) {
      localStorage.setItem("collapseCounterpartsActive", e[0]);
    } else {
      localStorage.setItem("collapseCounterpartsActive", e[1]);
    }
  };

  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <div className="flex w-7 items-center justify-center rounded-lg border border-solid border-gray-100 bg-gray-100 p-1.5">
          <ChevronDownIcon rotate={isActive} stroke={colors.GRAY_800} width={16} />
        </div>
      )}
      items={items}
      onChange={onCollapseChange}
      rootClassName={styles.folder_collapse}
      defaultActiveKey={collapseActive!}
    />
  );
};

export default CounterpartsFolders;
