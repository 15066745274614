import React, { ReactNode } from "react";
import { useFieldArray, UseFormReturn, useWatch } from "react-hook-form";
import { Col, Row, Spin } from "antd";
import SelectNotContentIcon from "features/app/assets/icons/SelectNotContentIcon";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import i18n from "i18next";

import { isEmptyArr, RU } from "modules/common";
import { PartyEstimateForm, PartyEstimateProduct, ProductCheck, usePartyProducts } from "modules/party/estimate";

import { ConditionalRender, LoadingIndicator } from "components";

type Props = {
  form: UseFormReturn<PartyEstimateForm>;
  taskId: number | string;
};

const ProductItemWrapper = ({ children }: { children: ReactNode }) => (
  <span className="text-base font-normal text-gray-600">{children}</span>
);

const Products: React.FC<Props> = ({ taskId, form }) => {
  const { products, isLoading } = usePartyProducts({ taskId });
  const { products: formProducts } = useWatch({ control: form.control });
  const { append, remove } = useFieldArray({ keyName: "unique", control: form.control, name: "products" });

  const onProduct = (product: PartyEstimateProduct) => () => {
    const index = formProducts?.findIndex(p => p?.id === product?.id);

    if (index! < 0) {
      append({ ...product, taskId });
    } else {
      remove(index);
    }
  };

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <ConditionalRender
        if={!isEmptyArr(products)}
        else={
          <div className="flex w-full items-center justify-center gap-4">
            <TableEmpty description=" " icon={<SelectNotContentIcon />} />
          </div>
        }
      >
        {products?.map(product => (
          <Row
            key={product?.id}
            onClick={onProduct(product)}
            className="box-border cursor-pointer bg-gray-100 px-4 py-3 duration-300 ease-in-out hover:bg-primary-100"
          >
            <Col span={12}>
              <div className="flex items-center gap-2">
                <ProductCheck product={product} form={form} />
                <div className="flex items-center gap-2 text-base font-normal text-gray-600">
                  <div
                    className="flex rounded border border-solid px-1 py-0.5 text-xs font-medium"
                    style={{
                      color: product?.product?.resource?.color,
                      borderColor: product?.product?.resource?.color
                    }}
                  >
                    {product?.product?.resource?.symbol?.[i18n.language]}
                  </div>
                  <PopoverShortName arrow={false} title={product?.product?.name?.[i18n.language]} length={34} />
                </div>
              </div>
            </Col>
            <Col span={3} />
            <Col span={3}>
              <ProductItemWrapper>{product?.quantity}</ProductItemWrapper>
            </Col>
            <Col span={3}>
              <ProductItemWrapper>{product?.unit?.symbol?.[i18n.language]}</ProductItemWrapper>
            </Col>
            <Col span={3}>
              <ProductItemWrapper>{product?.amount?.toLocaleString(RU)}</ProductItemWrapper>
            </Col>
          </Row>
        ))}
      </ConditionalRender>
    </Spin>
  );
};

export default Products;
