import React from "react";

import WarehouseTable from "../table/warehouse-table";

const SubCatalogs: React.FC = () => (
  <div className="p-4">
    <WarehouseTable />
  </div>
);

export default SubCatalogs;
