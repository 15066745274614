import { OfferModel } from "features/supply/utils/models/OfferModel";
import { ProductModel } from "features/supply/utils/models/productModel";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { isEmptyArr, localeFormatter } from "modules/common";

import { PartyForm } from "../form";

export function parseSelectProducts({
  type,
  products
}:
  | { products?: ProductModel[]; type: "warehouse-product" }
  | { products?: OfferModel[]; type: "offer" }
  | { products: []; type: "empty" }): PartyForm["warehouse_products"] {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { i18n } = useTranslation();

  if (isEmptyArr(products)) {
    return [];
  }

  if (type === "offer") {
    // @ts-ignore
    return products?.map(({ id, product, unit, quantity, amount, warehouse, currency, project, company_person }) => ({
      id,
      rowId: uid(),
      product_id: product?.id,
      unit_id: unit?.id,
      unit_name: unit?.symbol?.[i18n.language],
      quantity: localeFormatter(String(quantity)),
      amount: localeFormatter(String(amount)),
      warehouse_id: warehouse?.id,
      currency_id: currency?.id,
      currency_symbol: currency?.symbol,
      company_person_id: company_person?.id,
      company_person_name: company_person?.name,
      project_id: project?.id,
      total: localeFormatter(String((quantity || 0) * (amount || 0)))
    }));
  }

  // @ts-ignore
  return products?.map(({ amount, product, quantity, unit, company_person, currency, project, warehouse, id }) => ({
    id,
    rowId: uid(),
    product_id: product?.id,
    unit_id: unit?.id,
    unit_name: unit?.symbol?.[i18n.language],
    quantity: localeFormatter(String(quantity)),
    amount: localeFormatter(String(amount)),
    warehouse_id: warehouse?.id,
    currency_id: currency?.id,
    currency_symbol: currency?.symbol,
    company_person_id: company_person?.id,
    company_person_name: company_person?.name,
    project_id: project?.id,
    total: localeFormatter(String((quantity || 0) * (amount || 0)))
  }));
}
