import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { Agent, partyCreateQueryKeys } from "modules/party/create";

type Query = {
  agents: Agent[];
};

type Props = {
  enabled: boolean;
  notMe?: boolean;
};

export function useAgents({ enabled, notMe = false }: Props) {
  const initialData = {
    agents: []
  };

  const { data = initialData, ...arg } = useQuery<Query>(
    [partyCreateQueryKeys.AGENTS],
    async () => {
      const { data } = await $api.get(`admin/select?not_me=${notMe}`);

      return { agents: data?.data };
    },
    { enabled }
  );

  return { ...data, ...arg };
}
