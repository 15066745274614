import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalProps } from "antd";
import { ChatEnum } from "features/app/utils/enums/chatEnum";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { RootState } from "store/index";
import { warehouseOrderActions } from "store/reducers/warehouseOrderReducer";

import Comments from "../../../../app/components/comments/Comments";

import AdditionalLeft from "./left/AdditionalLeft";

import styles from "./additionalModal.module.scss";

const AdditionalModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setVisibleAdditional } = warehouseOrderActions;
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { visible, data, order_id, zIndex } = useAppSelector(
    (state: RootState) => state.warehouseOrderReducer.additional
  );

  const onCancel = () => {
    dispatch(
      setVisibleAdditional({
        data,
        visible: false,
        order_id
      })
    );
  };

  const onAfterCancel: ModalProps["afterOpenChange"] = open => {
    if (!open) {
      dispatch(
        setVisibleAdditional({
          data: undefined,
          visible: false,
          order_id: undefined
        })
      );

      setSelectedIds([]);
    }
  };

  const footer = <Button onClick={onCancel}>{t("warehouse.Yopish")}</Button>;

  return (
    <Modal
      centered
      open={visible}
      footer={footer}
      width="77.4rem"
      zIndex={zIndex}
      onCancel={onCancel}
      className={styles.additional}
      afterOpenChange={onAfterCancel}
      title={order_id ? t("warehouse.Qabul tarixi") : t("warehouse.Mahsulot qabul qilish")}
    >
      <div className={styles.content}>
        <div className={styles.left}>
          <AdditionalLeft selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
        </div>
        <div className={styles.right}>
          <Comments objectId={data?.id || order_id} type={data?.id ? ChatEnum.WAREHOUSE_PRODUCTS : ChatEnum.ORDERS} />
        </div>
      </div>
    </Modal>
  );
};

export default AdditionalModal;
