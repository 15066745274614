import { useDispatch } from "react-redux";

import { transferActions } from "store/reducers/transferReducer";

import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const WarehouseTransferRejected = ({ record, onNavigatePage }: Props) => {
  const dispatch = useDispatch();
  const { setVisibleView } = transferActions;

  const onOpenTransferView = () => {
    dispatch(
      setVisibleView({
        visible: true,
        data: record?.warehouse_transfer as never
      })
    );
  };

  return (
    <div onClick={onOpenTransferView} className={styles.description}>
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.warehouse_transfer?.recieved_warehouse?.name} sizning{" "}
        <span className={styles.name}>T - {record?.warehouse_transfer?.id}</span> raqamli o’tkazmani qabul qilmadi.
      </p>
    </div>
  );
};

export default WarehouseTransferRejected;
