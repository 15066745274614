import React, { useEffect } from "react";
import { useFieldArray, UseFormReturn, useWatch } from "react-hook-form";

import { useAppSelector } from "hooks/redux";

import { isEmptyArr } from "modules/common";
import { PartyForm } from "modules/party/create";

import { PartyCreateCompanyPerson, PartyCreateProduct, PartyEstimate, PartyResource } from "pages/index";
import Empty from "pages/party/create/content/left/empty";
import Group from "pages/party/create/content/left/group";
import Initial from "pages/party/create/content/left/initial";

import { ConditionalRender } from "components";

type Props = {
  isUpdate?: boolean;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, isUpdate }) => {
  const { warehouse_products } = useWatch({ control: form.control });
  const { isGroup: isGroupUpdate } = useAppSelector(({ partyReducer }) => partyReducer.update);
  const { isGroup: isGroupCreate, visible } = useAppSelector(({ partyReducer }) => partyReducer.create);
  const { append: appendCheck } = useFieldArray({
    keyName: "unique",
    control: form.control,
    name: "check_products"
  });
  const { remove, append, fields } = useFieldArray({
    keyName: "unique",
    control: form.control,
    name: "warehouse_products"
  });
  const {
    fields: groupFields,
    append: groupAppend,
    remove: groupRemove,
    update: groupUpdate
  } = useFieldArray({
    keyName: "group_id",
    control: form.control,
    name: "company_person_group"
  });

  useEffect(() => {
    if (!visible && !isUpdate) {
      form.reset({
        status: "ordered",
        company_person_group: [],
        warehouse_products: [],
        custom_field_values: [],
        check_products: [],
        payment_date: undefined,
        agent_id: undefined,
        delivery_date: undefined,
        discount: undefined
      });
    }
  }, [visible]);

  return (
    <div
      className="box-border flex-1 overflow-auto p-5"
      id={isUpdate ? "party-left-content-update" : "party-left-content"}
    >
      <ConditionalRender else={<Empty form={form} />} if={!isEmptyArr(warehouse_products) || !isEmptyArr(fields)}>
        <ConditionalRender
          if={isGroupCreate || isGroupUpdate}
          else={<Initial fields={fields} form={form} append={append} remove={remove} isUpdate={isUpdate} />}
        >
          <Group
            form={form}
            isUpdate={isUpdate}
            append={groupAppend}
            fields={groupFields}
            remove={groupRemove}
            update={groupUpdate}
          />
        </ConditionalRender>
      </ConditionalRender>
      <PartyResource append={append} appendCheck={appendCheck} />
      <PartyCreateProduct parentForm={form} isUpdate={isUpdate} />
      <PartyCreateCompanyPerson setParentValue={form.setValue} isUpdate={isUpdate} />
      <PartyEstimate
        append={append}
        parentForm={form}
        isUpdate={isUpdate}
        appendCheck={appendCheck}
        groupAppend={groupAppend}
      />
    </div>
  );
};

export default Index;
