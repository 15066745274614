import React from "react";
import { UseFormSetValue, useWatch } from "react-hook-form";
import i18n from "i18next";

import { isEmptyArr } from "modules/common";
import { getPopupContainer } from "modules/party/create";
import { ProductForm, ProductFormControl } from "modules/party/create-product";
import { useSubCatalogs } from "modules/party/resource";

import { Select } from "components";

type Props = {
  isUpdate?: boolean;
  control: ProductFormControl;
  setValue: UseFormSetValue<ProductForm>;
};

const SubCatalog: React.FC<Props> = ({ control, setValue, isUpdate }) => {
  const { category_id: catalogId } = useWatch({ control });
  const { subCatalogs } = useSubCatalogs({ catalogId });

  if (isEmptyArr(subCatalogs) || !catalogId) {
    return null;
  }

  const onClear = () => {
    setValue("subcategory_id", undefined);
  };

  return (
    <Select
      allowClear
      control={control}
      onClear={onClear}
      name="subcategory_id"
      label="Ichki katalog"
      placeholder="Mahsulotning ichki katalogini tanlang"
      getPopupContainer={getPopupContainer(isUpdate ? "update-product" : "create-product")}
      options={subCatalogs?.map(catalog => ({
        key: catalog?.id,
        value: catalog?.id,
        label: catalog?.name?.[i18n.language],
        props: {
          name: catalog?.name?.[i18n.language]
        }
      }))}
    />
  );
};

export default SubCatalog;
