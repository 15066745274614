import React from "react";

const UploadFileIcon: React.FC = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="2" width="32" height="32" rx="16" fill="#D1E9FF" />
    <rect x="2" y="2" width="32" height="32" rx="16" stroke="#EFF8FF" strokeWidth="4" />
    <path
      d="M19.3332 11.5131V14.2668C19.3332 14.6402 19.3332 14.8269 19.4058 14.9695C19.4697 15.0949 19.5717 15.1969 19.6972 15.2608C19.8398 15.3335 20.0265 15.3335 20.3998 15.3335H23.1535M23.3332 16.659V21.4668C23.3332 22.5869 23.3332 23.147 23.1152 23.5748C22.9234 23.9511 22.6175 24.2571 22.2412 24.4488C21.8133 24.6668 21.2533 24.6668 20.1332 24.6668H15.8665C14.7464 24.6668 14.1863 24.6668 13.7585 24.4488C13.3822 24.2571 13.0762 23.9511 12.8845 23.5748C12.6665 23.147 12.6665 22.5869 12.6665 21.4668V14.5335C12.6665 13.4134 12.6665 12.8533 12.8845 12.4255C13.0762 12.0492 13.3822 11.7432 13.7585 11.5515C14.1863 11.3335 14.7464 11.3335 15.8665 11.3335H18.0077C18.4969 11.3335 18.7415 11.3335 18.9716 11.3888C19.1757 11.4377 19.3708 11.5186 19.5497 11.6282C19.7516 11.7519 19.9245 11.9249 20.2704 12.2708L22.3959 14.3962C22.7418 14.7421 22.9148 14.9151 23.0385 15.1169C23.1481 15.2959 23.2289 15.491 23.2779 15.695C23.3332 15.9252 23.3332 16.1698 23.3332 16.659Z"
      stroke="#1E90FF"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UploadFileIcon;
